import { listItemTextClasses, MenuItem, styled } from '@mui/material'

import Box from 'components/Common/Box'

import { boxShadow, colors, FONT_WEIGHT } from 'styles/theme'

export const StyledContainer = styled(Box)({
  backgroundColor: colors.commonWhite,
  boxShadow,
  height: 'auto',
  maxHeight: '383px',
  marginTop: '0.5rem',
  padding: '1rem',
  width: '300px',
})

export const StyledMenuItem = styled(MenuItem)({
  ':hover': {
    backgroundColor: 'unset',
  },
  [`& .${listItemTextClasses.primary}`]: {
    color: colors.gray,
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.REGULAR,
    paddingLeft: '1rem',
  },
})
