import BasicInfo from 'components/CarSettings/Trim/Creation/BasicInfo'
import Measurement from 'components/CarSettings/Trim/Creation/Measurement'
import Mileage from 'components/CarSettings/Trim/Creation/Mileage'
import Settings from 'components/CarSettings/Trim/Creation/Settings'
import Spacing from 'components/CarSettings/Trim/Creation/Spacing'
import Summary from 'components/CarSettings/Trim/Creation/Summary'
import { StepperModel } from 'components/General/Stepper'

import { VehicleInformationSelectOptions } from 'models/services/inventory/detail'
import { StepperTextType } from 'models/text/General'
import { PictureOption } from 'models/trim'
import { TrimCreationAction, TrimCreationModel } from 'models/trimCreation'

export type TrimCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  brands: PictureOption[]
  vehicleSelectOptions: VehicleInformationSelectOptions
  submitLoading: boolean
  trimData: TrimCreationModel
  updateTrimData: (action: TrimCreationAction) => void
}

export const INITIAL_TRIM_CREATION_STEPS: Array<React.FC<TrimCreationProps>> = [
  BasicInfo,
  Settings,
  Spacing,
  Measurement,
  Mileage,
  Summary,
]

export const createTrimStepperItems = (
  text: StepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep,
    },
    { label: text.secondStep },
    {
      label: text.thirdStep,
    },
    { label: text.fourthStep },
    { label: text.fifthStep },
    { label: text.sixthStep },
  ]

  return stepperItems
}

export const isIncluded = (addedYears: number[], years: number[]) => {
  if (years.length > 1) {
    if (addedYears.length > 1) {
      if (addedYears[0] >= years[0] && addedYears[1] <= years[1]) {
        return true
      }
      const outcome = years.some((year) => {
        if (year >= addedYears[0] && year <= addedYears[1]) return true
        return false
      })
      return outcome
    }
    if (addedYears[0] >= years[0] && addedYears[0] <= years[1]) {
      return true
    }
    return false
  }

  if (addedYears.length > 1) {
    if (years[0] >= addedYears[0] && years[0] <= addedYears[1]) return true
  }
  if (addedYears[0] === years[0]) {
    return true
  }
  return false
}

export const getRangesAsString = (datage: number[][]) => {
  const dataStr: string[] = []
  for (const value of Object.values(datage)) {
    if (value.length > 1) {
      dataStr.push(`${value[0]} - ${value[1]}`)
    } else dataStr.push(`${value}`)
  }
  return dataStr.join(', ')
}
