import React from 'react'
import AddIcon from '@mui/icons-material/Add'

import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import { Image } from 'components/Common/Carousel'

import {
  maxAllowedSizePerFileInKb,
  maxAllowedTotalSizeInKb,
} from 'constants/fileSizes'

import { colors, FONT_WEIGHT } from 'styles/theme'

type ImageFilesInputProps = {
  handleFileStateChange: (files: File[]) => void
  size?: 'small' | 'medium' | 'large'
  buttonText: string
  disabled?: boolean
  id?: string
  /**
   * Function to update error status if a picture file was more than 3MB (maxAllowedSizePerFileInKb constant)
   * or if the total size is more than 20MB (maxAllowedTotalSizeInKb)
   */
  setFileSizeError?: (error: boolean, type?: 'totalSize') => void

  imagesList: Image[]

  testId?: string
}

const ImageFilesInput = ({
  handleFileStateChange,
  size,
  buttonText,
  disabled,
  id = 'image-files-input',
  setFileSizeError,
  imagesList,
  testId,
}: ImageFilesInputProps) => {
  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    const newFiles = files && files.length > 0 ? files : null

    if (newFiles) {
      let totalFileSize = 0
      let sizeError = false
      let multiSizeError = false
      const newFilesList: File[] = []

      Array.from(newFiles).forEach((file) => {
        /**
         * Explanation: Here, we check if the file that is trying to be saved at the moment has an adequate size (< 3mb),
         * then, we check if it fits in the current files list, because the maximum size is 20mb.
         * If it does, then we push it in the newFilesList array.
         * If not, then the corresponding error is rendered. Please, check the Photos component in Vehicle Creation for reference
         */
        if (file.size <= maxAllowedSizePerFileInKb) {
          if (
            file.size + totalFileSize <= maxAllowedTotalSizeInKb &&
            newFiles.length + imagesList.length <= 20
          ) {
            totalFileSize = file.size + totalFileSize

            newFilesList.push(file)
          } else {
            multiSizeError = true
          }
        } else {
          sizeError = true
        }
      })

      if (sizeError && setFileSizeError) {
        setFileSizeError(true)
      }

      if (multiSizeError && setFileSizeError) {
        setFileSizeError(true, 'totalSize')
      }

      if (!sizeError && !multiSizeError && setFileSizeError) {
        setFileSizeError(false)
        setFileSizeError(false, 'totalSize')
        handleFileStateChange(newFilesList)
      }
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Button
        variant="text"
        role="button"
        sx={{
          fontWeight: FONT_WEIGHT.MEDIUM,
          border: `2px solid ${
            !disabled ? colors.blue : colors.gray
          } !important`,
          padding: '0px !important',
          width: 'auto',
        }}
        size={size}
        disabled={disabled}
        testId={testId ? `${testId}-button` : undefined}
      >
        <label
          htmlFor={id}
          style={{
            cursor: 'pointer',
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            margin: '0 10px',
          }}
        >
          <AddIcon />
          {buttonText}
        </label>
      </Button>
      <input
        type="file"
        name="input"
        id={id}
        accept="image/*"
        style={{ display: 'none' }}
        onChange={imageHandler}
        disabled={disabled}
        multiple
        data-cy={testId ? `${testId}-input` : undefined}
      />
    </Box>
  )
}

export default ImageFilesInput
