import React, { FunctionComponent } from 'react'
import { InputBaseComponentProps, Slider } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'

import ButtonContainer from 'components/Template/Creation/ButtonContainer'

import { VEHICLE_CREATION_OPTION } from 'constants/Inventory/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { SixthStepModel } from 'models/services/PreInventory/creation'
import { VehicleCreationProps } from 'utils/Inventory/creation'

import {
  BoxContainer,
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

import NumberValueInput, {
  maxValue,
  minValue,
} from '../../Common/NumberValueInput'

type FormikKeys =
  | 'cylinders'
  | 'fuelCapacity'
  | 'horsePower'
  | 'horsePowerRpm'
  | 'mpg'
  | 'mpgCity'
  | 'mpgHgw'
  | 'torque'
  | 'torqueRpm'

const initialValue: SixthStepModel = {
  cylinders: 1,
  fuelCapacity: 1,
  horsePower: 1,
  horsePowerRpm: 1,
  mpg: 1,
  mpgCity: 1,
  mpgHgw: 1,
  torque: 1,
  torqueRpm: 1,
}

const VehiclePower = ({
  vehicleData,
  updateVehicleData,
  handleSecondaryBack,
  handleSecondaryContinue,
}: VehicleCreationProps) => {
  const {
    text: {
      subSteps: { sixthStep: translation },
    },
  } = useTranslation(textFiles.VEHICLE_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const initialData = vehicleData.vehicleInformation.sixthStep || initialValue

  const validationSchema = yup.object().shape({
    cylinders: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    fuelCapacity: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    horsePower: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    horsePowerRpm: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    mpg: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    mpgCity: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    mpgHgw: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    torque: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    torqueRpm: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
  })

  const formik = useFormik<SixthStepModel>({
    initialValues: initialData,
    validationSchema,
    onSubmit: (values) => {
      updateVehicleData({
        type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION,
        payload: {
          ...vehicleData.vehicleInformation,
          sixthStep: values,
        },
      })
      handleSecondaryContinue()
    },
  })

  const handleSliderChange = (value: number | number[], field: FormikKeys) => {
    formik.setFieldValue(field, Number(value))
  }

  const handleBlur = (field: FormikKeys) => {
    if (formik.values[field] > maxValue) {
      formik.setFieldValue(field, maxValue)
    } else if (!formik.values[field]) formik.setFieldValue(field, minValue)
  }

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <StyledTextFieldContainer title={translation.mpgLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.mpg}
                onChange={(e, value) => {
                  handleSliderChange(value, 'mpg')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="mpg"
                value={formik.values.mpg}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('mpg')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.mpg && formik.errors.mpg ? (
              <StyledErrorMessage text={formik.errors.mpg} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.cityMpgLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.mpgCity}
                onChange={(e, value) => {
                  handleSliderChange(value, 'mpgCity')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="mpgCity"
                value={formik.values.mpgCity}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('mpgCity')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.mpgCity && formik.errors.mpgCity ? (
              <StyledErrorMessage text={formik.errors.mpgCity} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.highwayMpgLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.mpgHgw}
                onChange={(e, value) => {
                  handleSliderChange(value, 'mpgHgw')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="mpgHgw"
                value={formik.values.mpgHgw}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('mpgHgw')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.mpgHgw && formik.errors.mpgHgw ? (
              <StyledErrorMessage text={formik.errors.mpgHgw} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.fuelCapacityLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.fuelCapacity}
                onChange={(e, value) => {
                  handleSliderChange(value, 'fuelCapacity')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="fuelCapacity"
                value={formik.values.fuelCapacity}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('fuelCapacity')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.fuelCapacity && formik.errors.fuelCapacity ? (
              <StyledErrorMessage text={formik.errors.fuelCapacity} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.cylindersLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.cylinders}
                onChange={(e, value) => {
                  handleSliderChange(value, 'cylinders')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="cylinders"
                value={formik.values.cylinders}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('cylinders')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.cylinders && formik.errors.cylinders ? (
              <StyledErrorMessage text={formik.errors.cylinders} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.torqueLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.torque}
                onChange={(e, value) => {
                  handleSliderChange(value, 'torque')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="torque"
                value={formik.values.torque}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('torque')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.torque && formik.errors.torque ? (
              <StyledErrorMessage text={formik.errors.torque} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.torqueRpmLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.torqueRpm}
                onChange={(e, value) => {
                  handleSliderChange(value, 'torqueRpm')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="torqueRpm"
                value={formik.values.torqueRpm}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('torqueRpm')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.torqueRpm && formik.errors.torqueRpm ? (
              <StyledErrorMessage text={formik.errors.torqueRpm} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.horsePowerLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.horsePower}
                onChange={(e, value) => {
                  handleSliderChange(value, 'horsePower')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="horsePower"
                value={formik.values.horsePower}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('horsePower')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.horsePower && formik.errors.horsePower ? (
              <StyledErrorMessage text={formik.errors.horsePower} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.horsePowerRpmLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.horsePowerRpm}
                onChange={(e, value) => {
                  handleSliderChange(value, 'horsePowerRpm')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="horsePowerRpm"
                value={formik.values.horsePowerRpm}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('horsePowerRpm')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.horsePowerRpm && formik.errors.horsePowerRpm ? (
              <StyledErrorMessage text={formik.errors.horsePowerRpm} />
            ) : null}
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonContainer
        previousFunction={handleSecondaryBack}
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default VehiclePower
