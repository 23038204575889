import { Color } from 'models/services/car'

import { BaseEntity, BaseIdEntity, GenericListData } from '../base'
import { ExtendedStatus } from '../status'

export enum CarProviderEnum {
  CLIENT,
  CURBO_SPOT,
  DEALER,
}

export type Car = {
  mainPicture: string
  createdAt: string
  listingPrice: number
  slug: string
  visible: boolean
  certified: boolean
  year: number
  mileage: number
  provider: CarProviderEnum
  status: ExtendedStatus
  curboSpot: BaseEntity
  driveTrain: BaseEntity
  features: BaseEntity[]
  exteriorColor: Color
  interiorColor: Color
  dealer: BaseEntity
  fuelType: BaseEntity
  transmission: BaseEntity
  brand: BaseEntity
  carModel: BaseEntity
  countryVersion: BaseEntity
  categories: BaseEntity[]
  bodyStyle: BaseEntity
  trimLevel: BaseEntity & { year: number; slug: string }
} & BaseIdEntity

export type ListInventory = {
  listInventoryCars: GenericListData<Car[]>
}
