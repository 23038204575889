import {
  MenuItem,
  menuItemClasses,
  Pagination,
  paginationClasses,
  styled,
} from '@mui/material'

import Box from 'components/Common/Box'

import { boxShadow, colors } from 'styles/theme'

export const StyledMenuItem = styled(MenuItem)({
  [`&.${menuItemClasses.root}`]: {
    color: colors.gray,
    fontSize: '12px',
    lineHeight: '16px',
    paddingLeft: '8px',
    paddingRight: '24px',
  },
  [`&.${menuItemClasses.selected}`]: {
    color: colors.black,
  },
})

export const StyledFooterContainer = styled(Box)({
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: '15px',
  paddingRight: '15px',
  height: '57px',
  alignItems: 'center',
  borderRadius: '6px',
  [`&.${menuItemClasses.root}`]: {
    color: colors.gray,
    fontSize: '12px',
    lineHeight: '16px',
    paddingLeft: '8px',
    paddingRight: '24px',
  },
  [`&.${menuItemClasses.selected}`]: {
    color: colors.black,
  },
})

export const StyledPagination = styled(Pagination)({
  '& .MuiPaginationItem-icon': {
    fontSize: '1.5rem!important',
  },
  [`& .${paginationClasses.ul}`]: {
    backgroundColor: colors.background,
    borderRadius: '4px',
    boxShadow,
    height: '35px',
    'li:first-of-type': {
      color: colors.background,
      borderRight: `1px solid ${colors.border}`,
      height: '50%',
    },

    'li:last-child': {
      color: colors.background,
      borderLeft: `1px solid ${colors.border}`,
      height: '50%',
    },
    '.Mui-selected::after': {
      content: '""',
      display: 'block',
      width: ' 14px',
      height: '2px',
      borderRadius: ' 6px',
      background: colors.blue,
    },

    '.Mui-selected': {
      flexDirection: 'column',
      paddingTop: '2px',
      backgroundColor: 'transparent',
    },

    '.MuiPaginationItem-previousNext': {
      color: colors.placeholderGray,
      marginTop: '-5px',
    },
  },
})
