import { StepperModel } from 'components/General/Stepper'
import Information from 'components/UserManagement/Creation/Information'
import Roles from 'components/UserManagement/Creation/Roles'
import Summary from 'components/UserManagement/Creation/Summary'

import { Option } from 'models/Select'
import { ThreeStepperTextType } from 'models/text/General'
import {
  RolesOption,
  UserCreationAction,
  UserCreationModel,
} from 'models/userCreation'

export type UserCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  userData: UserCreationModel
  updateUserData: (action: UserCreationAction) => void
  handleSubmit: () => void
  submitLoading: boolean
  roleOptions: RolesOption[]
  areaOptions: Option[]
}

export const USER_CREATION_STEPS: Array<React.FC<UserCreationProps>> = [
  Information,
  Roles,
  Summary,
]

export const createStateStepperItems = (
  text: ThreeStepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep,
    },
    { label: text.secondStep },
    { label: text.thirdStep },
  ]

  return stepperItems
}
