/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent } from 'react'
import NumberFormat from 'react-number-format'
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra'
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle'
import BuildIcon from '@mui/icons-material/Build'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CarRepairIcon from '@mui/icons-material/CarRepair'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import SpaceBarIcon from '@mui/icons-material/SpaceBar'
import SpeedIcon from '@mui/icons-material/Speed'
import StraightenIcon from '@mui/icons-material/Straighten'
import { InputBaseComponentProps } from '@mui/material'

import { NameInformation } from 'models/car'
import { Option } from 'models/Select'
import { TrimDetailDataType } from 'models/services/CarSettings/detail'
import { PictureOption } from 'models/trim'
import { TrimCreationModel } from 'models/trimCreation'

export const miniLogo =
  'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e9/MINI_logo.svg/2560px-MINI_logo.svg.png'

export enum TRIM_CREATION_OPTION {
  UPDATE_BASIC_INFORMATION = 'updateBasicInformation',
  UPDATE_SETTINGS_INFORMATION = 'updateSettingsInformation',
  UPDATE_SPACING_INFORMATION = 'updateSpacingInformation',
  UPDATE_MEASUREMENT_INFORMATION = 'updateMeasurementInformation',
  UPDATE_MILEAGE_INFORMATION = 'updateMileageInformation',
}

export enum TRIM_OPTION {
  BASIC_INFORMATION = 'basicInformation',
  SETTINGS_INFORMATION = 'settingsInformation',
  SPACING_INFORMATION = 'spacingInformation',
  MEASUREMENT_INFORMATION = 'measurementInformation',
  MILEAGE_INFORMATION = 'mileageInformation',
}

export enum INFORMATION_OPTION {
  STATUS = 'status',
  TRIM = 'trim',
  BRAND = 'brand',
  MODEL = 'model',
  DATAGE = 'datage',
}

export enum SETTINGS_OPTION {
  TRANSMISSION = 'transmission',
  BODY_STYLE = 'bodyStyle',
  DRIVE_TRAIN = 'driveTrain',
  FUEL_TYPE = 'fuelType',
  FEATURES = 'features',
}

export enum SPACING_OPTION {
  FRONT_LEG_ROOM = 'frontLegRoom',
  BACK_LEG_ROOM = 'backLegRoom',
  FRONT_HEAD_ROOM = 'frontHeadRoom',
  BACK_HEAD_ROOM = 'backHeadRoom',
  ENGINE_DISPLACEMENT = 'engineDisplacement',
  CARGO_DISPLACEMENT = 'cargoCapacity',
  CARGO_WEIGHT_DISPLACEMENT = 'cargoWeightCapacity',
}

export enum MEASUREMENT_OPTION {
  LENGTH = 'length',
  WIDTH = 'width',
  HEIGHT = 'height',
  SEATS = 'seats',
  DOORS = 'doors',
}

export enum MILEAGE_OPTION {
  MPG = 'mpg',
  CITY_MPG = 'cityMpg',
  HIGHWAY_MPG = 'highwayMpg',
  FUEL_CAPACITY = 'fuelCapacity',
  CYLINDERS = 'cylinders',
  TORQUE = 'torque',
  TORQUE_RPM = 'torqueRpm',
  HORSE_POWER = 'horsePower',
  HORSE_POWER_RPM = 'horsePowerRpm',
}

export const summaryItems = (
  trimData: TrimCreationModel,
  translation: any,
  options: Record<string, any>
) => {
  const {
    basicInformation,
    settingsInformation,
    spacingInformation,
    measurementInformation,
    mileageInformation,
  } = trimData
  const {
    information,
    settings: settingsText,
    spacing,
    measurement,
    mileage,
  } = translation
  const {
    brands: brandOps,
    vehicleSelectOptions: {
      transmissions: tranOps,
      bodyStyles: bodyOps,
      driveTrains: driveOps,
      fuelTypes: fuelOps,
    },
  } = options

  const renderOption = (ops: Option[], value: string): string => {
    return ops.find((op) => op.value === value)!.name
  }

  const renderModel = (
    brds: PictureOption[],
    myBrand: string,
    myModel: string
  ): string => {
    const currentBrand = brds.find((brd) => brd.value === myBrand)
    return currentBrand!.models.find((mod) => mod.value === myModel)!.name
  }

  return {
    infoArr: [
      {
        id: 1,
        title: information.trim,
        text: basicInformation.trim,
        icon: <BuildIcon />,
      },
      {
        id: 2,
        title: information.brand,
        text: renderOption(brandOps, basicInformation.brand),
        icon: <DirectionsCarIcon />,
      },
      {
        id: 3,
        title: information.model,
        text: renderModel(
          brandOps,
          basicInformation.brand,
          basicInformation.model
        ),
        icon: <DirectionsCarIcon />,
      },
    ],
    settArr: [
      {
        id: 1,
        title: settingsText.transmission,
        text: renderOption(tranOps, settingsInformation.transmission),
        icon: <BuildIcon />,
      },
      {
        id: 2,
        title: settingsText.bodyStyle,
        text: renderOption(bodyOps, settingsInformation.bodyStyle),
        icon: <AirportShuttleIcon />,
      },
      {
        id: 3,
        title: settingsText.driveTrain,
        text: renderOption(driveOps, settingsInformation.driveTrain),
        icon: <CarRepairIcon />,
      },
      {
        id: 4,
        title: settingsText.fuelType,
        text: renderOption(fuelOps, settingsInformation.fuelType),
        icon: <LocalGasStationIcon />,
      },
    ],
    spArr: [
      {
        id: 1,
        title: spacing.frontLegRoom,
        text: spacingInformation.frontLegRoom,
        icon: <AirlineSeatReclineExtraIcon />,
      },
      {
        id: 2,
        title: spacing.backLegRoom,
        text: spacingInformation.backLegRoom,
        icon: <AirlineSeatReclineExtraIcon />,
      },
      {
        id: 3,
        title: spacing.frontHeadRoom,
        text: spacingInformation.frontHeadRoom,
        icon: <StraightenIcon />,
      },
      {
        id: 4,
        title: spacing.backHeadRoom,
        text: spacingInformation.backHeadRoom,
        icon: <StraightenIcon />,
      },
      {
        id: 5,
        title: spacing.engineDisplacement,
        text: spacingInformation.engineDisplacement,
        icon: <DirectionsCarIcon />,
      },
      {
        id: 6,
        title: spacing.cargoCapacity,
        text: spacingInformation.cargoCapacity,
        icon: <SpaceBarIcon />,
      },
      {
        id: 7,
        title: spacing.cargoWeightCapacity,
        text: spacingInformation.cargoWeightCapacity,
        icon: <DirectionsCarIcon />,
      },
    ],
    meArr: [
      {
        id: 1,
        icon: <StraightenIcon />,
        title: measurement.length,
        text: measurementInformation.length,
      },
      {
        id: 2,
        icon: <StraightenIcon />,
        title: measurement.height,
        text: measurementInformation.height,
      },
      {
        id: 3,
        icon: <StraightenIcon />,
        title: measurement.width,
        text: measurementInformation.width,
      },
      {
        id: 4,
        icon: <DirectionsCarIcon />,
        title: measurement.doors,
        text: measurementInformation.doors,
      },
      {
        id: 5,
        icon: <AirlineSeatReclineExtraIcon />,
        title: measurement.seats,
        text: measurementInformation.seats,
      },
    ],
    mileArr: [
      {
        id: 1,
        icon: <DirectionsCarIcon />,
        title: mileage.mpg,
        text: mileageInformation.mpg,
      },
      {
        id: 2,
        icon: <DirectionsCarIcon />,
        title: mileage.cityMpg,
        text: mileageInformation.cityMpg,
      },
      {
        id: 3,
        icon: <DirectionsCarIcon />,
        title: mileage.highwayMpg,
        text: mileageInformation.highwayMpg,
      },
      {
        id: 4,
        icon: <DirectionsCarIcon />,
        title: mileage.fuelCapacity,
        text: mileageInformation.fuelCapacity,
      },
      {
        id: 5,
        icon: <DirectionsCarIcon />,
        title: mileage.cylinders,
        text: mileageInformation.cylinders,
      },
      {
        id: 6,
        icon: <DirectionsCarIcon />,
        title: mileage.torque,
        text: mileageInformation.torque,
      },
      {
        id: 7,
        icon: <SpeedIcon />,
        title: mileage.torqueRpm,
        text: mileageInformation.torqueRpm,
      },
      {
        id: 8,
        icon: <SpeedIcon />,
        title: mileage.horsePower,
        text: mileageInformation.horsePower,
      },
      {
        id: 9,
        icon: <SpeedIcon />,
        title: mileage.horsePowerRpm,
        text: mileageInformation.horsePowerRpm,
      },
    ],
  }
}

export interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

export const NumberFormatCustom = React.forwardRef<
  NumberFormat<string>,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      format="####"
      isNumericString
    />
  )
})

export const getTrimInformation = (
  translation: Record<string, string>,
  brandsOps: PictureOption[],
  information: TrimDetailDataType
): NameInformation[] => {
  const { model, name, year } = information
  const { brand } = model

  const currentBrand = brandsOps.find((item) => item.value === brand.value)
  const newModels: Option[] = currentBrand!.models

  return [
    {
      id: 0,
      icon: <DirectionsCarIcon />,
      label: translation.brandLabel,
      name: brand.value,
      options: brandsOps,
    },
    {
      id: 1,
      icon: <DirectionsCarIcon />,
      label: translation.modelLabel,
      name: model.value,
      options: newModels,
    },
    {
      id: 2,
      icon: <BuildIcon />,
      label: translation.trimLabel,
      name,
    },
    {
      id: 3,
      icon: <CalendarTodayIcon />,
      label: translation.datageLabel,
      name: String(year),
      inputProps: {
        inputComponent:
          NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
      },
    },
  ]
}

export const getTrimSettings = (
  settingsText: Record<string, string>,
  backendOptions: Record<string, Option[]>,
  information: TrimDetailDataType
): NameInformation[] => {
  const { transmission, bodyStyle, driveTrain, fuelType } = information

  const { transmissions, bodyStyles, driveTrains, fuelTypes } = backendOptions

  return [
    {
      id: 1,
      icon: <BuildIcon />,
      label: settingsText.transmissionLabel,
      name: transmission.value,
      options: transmissions,
    },
    {
      id: 2,
      icon: <AirportShuttleIcon />,
      label: settingsText.bodyStyleLabel,
      name: bodyStyle.value,
      options: bodyStyles,
    },
    {
      id: 3,
      icon: <CarRepairIcon />,
      label: settingsText.driveTrainLabel,
      name: driveTrain.value,
      options: driveTrains,
    },
    {
      id: 4,
      icon: <LocalGasStationIcon />,
      label: settingsText.fuelTypeLabel,
      name: fuelType.value,
      options: fuelTypes,
    },
  ]
}

export const getTrimSpacing = (
  metering: Record<string, string>,
  measurement: Record<string, string>,
  information: TrimDetailDataType
): Record<string, NameInformation[]> => {
  const {
    frontLegRoom,
    backLegRoom,
    frontHeadRoom,
    backHeadRoom,
    cargoCapacity,
    cargo,
    length,
    height,
    width,
    doors,
    seats,
    engineDisplacement,
  } = information

  const centimeterUnit = 'cm'

  return {
    spacing: [
      {
        id: 1,
        icon: <AirlineSeatReclineExtraIcon />,
        label: metering.frontLegLabel,
        name: frontLegRoom,
        unit: centimeterUnit,
      },
      {
        id: 2,
        icon: <AirlineSeatReclineExtraIcon />,
        label: metering.backLegLabel,
        name: backLegRoom,
        unit: centimeterUnit,
      },
      {
        id: 3,
        icon: <StraightenIcon />,
        label: metering.frontHeadLabel,
        name: frontHeadRoom,
        unit: centimeterUnit,
      },
      {
        id: 4,
        icon: <StraightenIcon />,
        label: metering.backHeadLabel,
        name: backHeadRoom,
        unit: centimeterUnit,
      },
      {
        id: 5,
        icon: <SpaceBarIcon />,
        label: metering.cargoLabel,
        name: cargoCapacity,
        unit: 'cm³',
      },
      {
        id: 6,
        icon: <DirectionsCarIcon />,
        label: metering.weightLabel,
        name: cargo,
        unit: 'kg',
      },
    ],
    measurement: [
      {
        id: 1,
        icon: <StraightenIcon />,
        label: measurement.lengthLabel,
        name: length,
        unit: centimeterUnit,
      },
      {
        id: 2,
        icon: <StraightenIcon />,
        label: measurement.heightLabel,
        name: height,
        unit: centimeterUnit,
      },
      {
        id: 3,
        icon: <StraightenIcon />,
        label: measurement.widthLabel,
        name: width,
        unit: centimeterUnit,
      },
      {
        id: 4,
        icon: <DirectionsCarIcon />,
        label: measurement.doorLabel,
        name: doors,
      },
      {
        id: 5,
        icon: <AirlineSeatReclineExtraIcon />,
        label: measurement.seatLabel,
        name: seats,
      },
      {
        id: 6,
        icon: <DirectionsCarIcon />,
        label: measurement.engineLabel,
        name: engineDisplacement,
        unit: centimeterUnit,
      },
    ],
  }
}

export const getTrimMileage = (
  torque: Record<string, string>,
  mileage: Record<string, string>,
  milesLabel: string,
  information: TrimDetailDataType
): Record<string, NameInformation[]> => {
  const {
    torque: torqueInfo,
    torqueRpm,
    cylinders,
    horsePower,
    horsePowerRpm,
    fuelCapacity,
    mpg,
    mpgHgw,
    mpgCity,
  } = information

  return {
    torque: [
      {
        id: 1,
        icon: <DirectionsCarIcon />,
        label: torque.torqueLabel,
        name: torqueInfo,
        unit: '(nm)',
      },
      {
        id: 2,
        icon: <DirectionsCarIcon />,
        label: torque.rpmLabel,
        name: torqueRpm,
      },
      {
        id: 3,
        icon: <DirectionsCarIcon />,
        label: torque.cylindersLabel,
        name: cylinders,
      },
      {
        id: 4,
        icon: <DirectionsCarIcon />,
        label: torque.powerLabel,
        name: horsePower,
      },
      {
        id: 5,
        icon: <DirectionsCarIcon />,
        label: torque.powerRpmLabel,
        name: horsePowerRpm,
        unit: 'rpm',
      },
      {
        id: 6,
        icon: <DirectionsCarIcon />,
        label: torque.fuelLabel,
        name: fuelCapacity,
        unit: 'g',
      },
    ],
    mileage: [
      {
        id: 1,
        icon: <SpeedIcon />,
        label: mileage.mileageLabel,
        name: mpg,
        unit: milesLabel,
      },
      {
        id: 2,
        icon: <SpeedIcon />,
        label: mileage.highwayLabel,
        name: mpgHgw,
        unit: milesLabel,
      },
      {
        id: 3,
        icon: <SpeedIcon />,
        label: mileage.cityLabel,
        name: mpgCity,
        unit: milesLabel,
      },
    ],
  }
}
