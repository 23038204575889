import React from 'react'

import { DataProps } from 'components/Common/Charts/PieChart'
import Container from 'components/Common/Container'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import CarsInspectedCard from '../CarsInspectedCard'
import InspectorProgressChart from '../InspectorProgressChart'

import { StyledBox } from './style'

export type InspectorDataType = {
  name: string
  carsInspected: number
  ranking: number
}

export type pieChartColorType = 'green' | 'yellow' | 'red'

const getPieChartColor = (percentage: string) => {
  const percentageNumber = parseFloat(percentage)
  if (percentageNumber >= 80) return 'green'
  if (percentageNumber >= 70 && percentageNumber < 80) return 'yellow'
  return 'red'
}

export type CommissionsDetailProps = {
  inspectorData: InspectorDataType
  pieChartData: DataProps[]
  percentage: string
  carsInspected: number
  carsInspectedObjective: number
}

const CommissionsDetail = ({
  inspectorData,
  pieChartData,
  percentage,
  carsInspected,
  carsInspectedObjective,
}: CommissionsDetailProps) => {
  const pieChartColor: pieChartColorType = getPieChartColor(percentage)
  const { text } = useTranslation(textFiles.INSPECTOR_DETAIL)
  return (
    <StyledBox>
      <Container
        title={text.commissions.commissionsDetail.carsUploadedTitle}
        description={text.commissions.commissionsDetail.carsUploadedDescription}
        sx={{ padding: '0rem 2rem' }}
      >
        <CarsInspectedCard inspectorData={inspectorData} />
      </Container>
      <Container
        title={text.commissions.commissionsDetail.currentMonthTitle}
        description={text.commissions.commissionsDetail.currentMonthDescription}
        sx={{ marginTop: '30px' }}
      >
        <InspectorProgressChart
          pieChartColor={pieChartColor}
          carsInspected={carsInspected}
          carsInspectedObjective={carsInspectedObjective}
          pieChartData={pieChartData}
          percentage={percentage}
        />
      </Container>
    </StyledBox>
  )
}

export default CommissionsDetail
