import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import ErrorPage from 'pages/ErrorPage'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import ResolutionErrorImage from 'images/resolutionError.svg'

export const ResolutionErrorPage = () => {
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { text } = useTranslation(textFiles.ERROR)

  const handleGoBack = () => {
    history.goBack()
  }
  const handleOpenHelpModal = () => {
    setIsModalOpen(true)
  }
  const handleCloseHelpModal = () => {
    setIsModalOpen(false)
  }

  return (
    <ErrorPage
      text={text.resolution}
      rightButtonProps={{ startIcon: <HelpOutlineIcon /> }}
      handleLeftButtonClick={handleGoBack}
      handleRightButtonClick={handleOpenHelpModal}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseHelpModal}
      imageSrc={ResolutionErrorImage}
    />
  )
}

export default ResolutionErrorPage
