import { ENTITY_CREATION_OPTION } from 'constants/blackList'
import {
  EntityCreationAction,
  EntityCreationModel,
} from 'models/blackListCreation'

const entityCreationReducer = (
  state: EntityCreationModel,
  action: EntityCreationAction
): EntityCreationModel => {
  switch (action.type) {
    case ENTITY_CREATION_OPTION.UPDATE_ENTITY_INFORMATION:
      return { ...state, entityInformation: action.payload }

    default:
      return state
  }
}

export default entityCreationReducer
