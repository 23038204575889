import React from 'react'
import { Card, Typography } from '@mui/material'

import { BaseEntity } from 'models/services/base'

import { colors } from 'styles/theme'

import { StyledList } from './style'

type RolesToolTipProps = {
  roles: BaseEntity[]
}

const RolesTooltip = ({ roles }: RolesToolTipProps) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        paddingRight: '20px',
      }}
    >
      <StyledList>
        {roles.map((role) => (
          <li key={role.id}>
            <Typography color={colors.black} margin="0.3rem" variant="body2">
              {role.name}
            </Typography>
          </li>
        ))}
      </StyledList>
    </Card>
  )
}

export default RolesTooltip
