export const AUTH_ROUTE = '/auth'
export const ACCOUNT_ROUTE = '/my-account'
export const INSPECTION_ROUTE = '/inspections'
export const INSPECTOR_ROUTE = '/inspectors'
export const TEMPLATE_ROUTE = '/templates'
export const INVENTORY_ROUTE = '/inventory'
export const MARKETING_ROUTE = '/marketing'

export const CAR_SETTINGS_ROUTE = '/car-settings'
export const OPERATION_ROUTE = '/operations'
export const OUT_OF_SPOT = `${OPERATION_ROUTE}/out-of-spot`
export const STATE_ROUTE = `${OPERATION_ROUTE}/states`
export const CITY_ROUTE = `${OPERATION_ROUTE}/cities`
export const CURBO_SPOT_ROUTE = `${OPERATION_ROUTE}/curbo-spots`
export const DEALER_ROUTE = `${OPERATION_ROUTE}/dealers`
export const BLACK_LIST_ROUTE = `${OPERATION_ROUTE}/black-list`
export const GENERAL_SETTINGS_ROUTE = '/general-settings'
export const USER_MANAGEMENT_ROUTE = `${GENERAL_SETTINGS_ROUTE}/user-management`
export const COLOR_ROUTE = `${CAR_SETTINGS_ROUTE}/color`
export const BRAND_ROUTE = `${CAR_SETTINGS_ROUTE}/brand`
export const COUNTRY_VERSION_ROUTE = `${CAR_SETTINGS_ROUTE}/country-version`
export const FEATURE_ROUTE = `${CAR_SETTINGS_ROUTE}/feature`
export const CATEGORY_ROUTE = `${CAR_SETTINGS_ROUTE}/category`
export const TRANSMISSION_ROUTE = `${CAR_SETTINGS_ROUTE}/transmission`
export const DRIVE_TRAIN_ROUTE = `${CAR_SETTINGS_ROUTE}/drive-train`
export const FUEL_TYPE_ROUTE = `${CAR_SETTINGS_ROUTE}/fuel-type`
export const BODY_STYLE_ROUTE = `${CAR_SETTINGS_ROUTE}/body-style`
export const MODEL_ROUTE = `${CAR_SETTINGS_ROUTE}/model`
export const TRIM_ROUTE = `${CAR_SETTINGS_ROUTE}/trim-level`

export const NOTIFICATION_ROUTE = `${MARKETING_ROUTE}/notifications`
export const OFFER_ROUTE = `${MARKETING_ROUTE}/offers`

export const INSPECTION_SUB_ROUTES = {
  INSPECTION_LISTING: `${INSPECTION_ROUTE}/listing`,
  INSPECTION_DETAIL: `${INSPECTION_ROUTE}/detail/:inspectionId/:section?`,
  INSPECTION_CREATION: `${INSPECTION_ROUTE}/creation`,
  INSPECTION_DASHBOARD: `${INSPECTION_ROUTE}/dashboard`,
}

export const INSPECTOR_SUB_ROUTES = {
  INSPECTOR_DETAIL: `${INSPECTOR_ROUTE}/detail/:inspectorId/:section?`,
  INSPECTOR_DASHBOARD: `${INSPECTOR_ROUTE}/dashboard`,
  INSPECTOR_LISTING: `${INSPECTOR_ROUTE}/listing`,
  INSPECTOR_CALENDAR: `${INSPECTOR_ROUTE}/calendar`,
}

export const TEMPLATE_SUB_ROUTES = {
  TEMPLATE_DETAIL: `${TEMPLATE_ROUTE}/detail/:templateId/:step?`,
  TEMPLATE_LISTING: `${TEMPLATE_ROUTE}/listing`,
  TEMPLATE_CREATION: `${TEMPLATE_ROUTE}/creation`,
}

export const CITY_SUB_ROUTES = {
  CITY_LISTING: `${CITY_ROUTE}/listing`,
  CITY_CREATION: `${CITY_ROUTE}/creation`,
  CITY_DETAIL: `${CITY_ROUTE}/detail/:cityId`,
}

export const STATE_SUB_ROUTES = {
  STATE_LISTING: `${STATE_ROUTE}/listing`,
  STATE_DETAIL: `${STATE_ROUTE}/detail/:stateId`,
  STATE_CREATION: `${STATE_ROUTE}/creation`,
}

export const CURBO_SPOT_SUB_ROUTES = {
  CURBO_SPOT_LISTING: `${CURBO_SPOT_ROUTE}/listing`,
  CURBO_SPOT_DETAIL: `${CURBO_SPOT_ROUTE}/detail/:curboSpotId/:section?`,
  CURBO_SPOT_CREATION: `${CURBO_SPOT_ROUTE}/creation`,
}

export const DEALER_SUB_ROUTES = {
  DEALER_LISTING: `${DEALER_ROUTE}/listing`,
  DEALER_DETAIL: `${DEALER_ROUTE}/detail/:dealerId/:section?`,
  DEALER_CREATION: `${DEALER_ROUTE}/creation`,
}

export const BLACK_LIST_SUB_ROUTES = {
  BLACK_LIST_LISTING: `${BLACK_LIST_ROUTE}/listing`,
  BLACK_LIST_DETAIL: `${BLACK_LIST_ROUTE}/detail/:entityType/:entityId`,
  BLACK_LIST_CREATION: `${BLACK_LIST_ROUTE}/creation/:entity`,
}

export const INVENTORY_SUB_ROUTES = {
  PRE_INVENTORY_LISTING: `${INVENTORY_ROUTE}/pre-inventory/listing`,
  INVENTORY_LISTING: `${INVENTORY_ROUTE}/listing`,
  INVENTORY_DETAIL: `${INVENTORY_ROUTE}/detail/:carId/:section?`,
  INVENTORY_CREATION: `${INVENTORY_ROUTE}/creation`,
}

export const OPERATION_SUB_ROUTES = {
  ...STATE_SUB_ROUTES,
  ...CITY_SUB_ROUTES,
  ...CURBO_SPOT_SUB_ROUTES,
  ...DEALER_SUB_ROUTES,
  ...BLACK_LIST_SUB_ROUTES,
  OUT_OF_SPOT,
}

export const CAR_SETTINGS_SUB_ROUTES = {
  CAR_SETTINGS_MENU: `${CAR_SETTINGS_ROUTE}/menu`,
  CAR_SETTINGS_LISTING: `${CAR_SETTINGS_ROUTE}/:setting/listing`,
  BRAND_LISTING: `${BRAND_ROUTE}/listing`,
  MODEL_LISTING: `${MODEL_ROUTE}/listing`,
  COUNTRY_VERSION_LISTING: `${COUNTRY_VERSION_ROUTE}/listing`,
  FEATURE_LISTING: `${FEATURE_ROUTE}/listing`,
  CATEGORY_LISTING: `${CATEGORY_ROUTE}/listing`,
  TRANSMISSION_LISTING: `${TRANSMISSION_ROUTE}/listing`,
  DRIVE_TRAIN_LISTING: `${DRIVE_TRAIN_ROUTE}/listing`,
  FUEL_TYPE_LISTING: `${FUEL_TYPE_ROUTE}/listing`,
  BODY_STYLE_LISTING: `${BODY_STYLE_ROUTE}/listing`,
  COLOR_LISTING: `${COLOR_ROUTE}/listing`,
  TRIM_LISTING: `${TRIM_ROUTE}/listing`,
  TRIM_CREATION: `${TRIM_ROUTE}/creation`,
  BRAND_CREATION: `${BRAND_ROUTE}/creation`,
  MODEL_CREATION: `${MODEL_ROUTE}/creation`,
  COUNTRY_VERSION_CREATION: `${COUNTRY_VERSION_ROUTE}/creation`,
  FEATURE_CREATION: `${FEATURE_ROUTE}/creation`,
  CATEGORY_CREATION: `${CATEGORY_ROUTE}/creation`,
  COLOR_CREATION: `${COLOR_ROUTE}/creation`,
  TRANSMISSION_CREATION: `${TRANSMISSION_ROUTE}/creation`,
  DRIVE_TRAIN_CREATION: `${DRIVE_TRAIN_ROUTE}/creation`,
  FUEL_TYPE_CREATION: `${FUEL_TYPE_ROUTE}/creation`,
  BODY_STYLE_CREATION: `${BODY_STYLE_ROUTE}/creation`,
  CATEGORY_DETAIL: `${CATEGORY_ROUTE}/detail/:carSettingsId`,
  BRAND_DETAIL: `${BRAND_ROUTE}/detail/:carSettingsId`,
  MODEL_DETAIL: `${MODEL_ROUTE}/detail/:carSettingsId`,
  COLOR_DETAIL: `${COLOR_ROUTE}/detail/:carSettingsId`,
  COUNTRY_VERSION_DETAIL: `${COUNTRY_VERSION_ROUTE}/detail/:carSettingsId`,
  FEATURE_DETAIL: `${FEATURE_ROUTE}/detail/:carSettingsId`,
  BODY_STYLE_DETAIL: `${BODY_STYLE_ROUTE}/detail/:carSettingsId`,
  TRANSMISSION_DETAIL: `${TRANSMISSION_ROUTE}/detail/:carSettingsId`,
  DRIVE_TRAIN_DETAIL: `${DRIVE_TRAIN_ROUTE}/detail/:carSettingsId`,
  FUEL_TYPE_DETAIL: `${FUEL_TYPE_ROUTE}/detail/:carSettingsId`,
  TRIM_DETAIL: `${TRIM_ROUTE}/detail/:carSettingsId/:section?`,
}

export const GENERAL_SETTINGS_SUB_ROUTES = {
  ABSENCE_MOTIVES: `${GENERAL_SETTINGS_ROUTE}/absence-motives`,
  REJECTION_MOTIVES: `${GENERAL_SETTINGS_ROUTE}/rejection-motives`,
  ANSWERS_CONFIGURATION: `${GENERAL_SETTINGS_ROUTE}/answers-configuration`,
  HOURS_CREATION: `${GENERAL_SETTINGS_ROUTE}/hours-creation`,
  USER_MANAGEMENT_LISTING: `${USER_MANAGEMENT_ROUTE}/listing`,
  USER_MANAGEMENT_DETAIL: `${USER_MANAGEMENT_ROUTE}/detail/:userId`,
  USER_MANAGEMENT_CREATION: `${USER_MANAGEMENT_ROUTE}/creation`,
}

export const ERROR_SUBROUTES = {
  RESOLUTION_ERROR: '/203',
  UNAUTHORIZED_ERROR: '/403',
  NOT_FOUND_ERROR: '/404',
}

export const NOTIFICATIONS_SUB_ROUTES = {
  NOTIFICATION_LISTING: `${NOTIFICATION_ROUTE}/listing`,
  NOTIFICATION_DETAIL: `${NOTIFICATION_ROUTE}/detail/:notificationId/:section?`,
  NOTIFICATION_CREATION: `${NOTIFICATION_ROUTE}/creation`,
}

export const OFFERS_SUB_ROUTES = {
  OFFERS_LISTING: `${OFFER_ROUTE}/listing`,
  OFFERS_DETAIL: `${OFFER_ROUTE}/detail/:offerId/:section?`,
  OFFERS_CREATION: `${OFFER_ROUTE}/creation`,
}

export const MARKETING_SUB_ROUTES = {
  ...NOTIFICATIONS_SUB_ROUTES,
  ...OFFERS_SUB_ROUTES,
}

export const routes = {
  LOGIN: `${AUTH_ROUTE}/login`,
  FORGOT_PASSWORD: `${AUTH_ROUTE}/forgot-password`,
  RESET_PASSWORD: `${AUTH_ROUTE}/reset-password/:token`,
  THEMING: '/theming',
  HOME: '/',
  ACCOUNT_SETTINGS: `${ACCOUNT_ROUTE}/settings`,
  ...ERROR_SUBROUTES,
  ...INSPECTION_SUB_ROUTES,
  ...INSPECTOR_SUB_ROUTES,
  ...TEMPLATE_SUB_ROUTES,
  ...CITY_SUB_ROUTES,
  ...STATE_SUB_ROUTES,
  ...DEALER_SUB_ROUTES,
  ...CAR_SETTINGS_SUB_ROUTES,
  ...GENERAL_SETTINGS_SUB_ROUTES,
  ...OPERATION_SUB_ROUTES,
  ...INVENTORY_SUB_ROUTES,
  ...MARKETING_SUB_ROUTES,
}
