import React from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'

import Box from 'components/Common/Box'

import {
  cypressCancelButton,
  cypressEditButton,
  cypressSaveButton,
} from 'constants/cypress'

import { colors } from 'styles/theme'

import { StyledIconButton } from './style'

type EditContainerProps = {
  edit: boolean
  handleEdit: () => void
  handleCancelEdit: () => void
  handleSaveEdit: () => void
  boxStyles?: React.CSSProperties
  loading?: boolean
  isEditable?: boolean
}

const EditContainer = ({
  edit,
  handleCancelEdit,
  handleSaveEdit,
  handleEdit,
  boxStyles,
  loading,
  isEditable,
}: EditContainerProps) => {
  if (!isEditable) return null

  return (
    <Box display="flex" justifyContent="flex-end" sx={boxStyles}>
      {!edit ? (
        <StyledIconButton
          disabled={loading}
          onClick={handleEdit}
          data-cy={cypressEditButton}
        >
          <EditIcon sx={{ color: colors.placeholderGray, fontSize: 16 }} />
        </StyledIconButton>
      ) : (
        <>
          <StyledIconButton
            disabled={loading}
            onClick={handleSaveEdit}
            data-cy={cypressSaveButton}
          >
            <SaveIcon sx={{ color: colors.placeholderGray, fontSize: 16 }} />
          </StyledIconButton>
          <StyledIconButton
            onClick={handleCancelEdit}
            sx={{ marginLeft: '0.25rem' }}
            disabled={loading}
            data-cy={cypressCancelButton}
          >
            <ClearIcon
              sx={{
                color: colors.placeholderGray,
                fontSize: 16,
              }}
            />
          </StyledIconButton>
        </>
      )}
    </Box>
  )
}

export default EditContainer
