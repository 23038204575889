import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import ButtonContainer from 'components/CarSettings/Common/Creation/ButtonContainer'
import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'
import { TranslationContainer } from 'components/CarSettings/style'
import ErrorMessage from 'components/Common/ErrorMessage'
import Translation from 'components/General/Translation'
import { TranslationItemType } from 'components/General/Translation/TranslationListItem'

import { DRIVE_TRAIN_CREATION_OPTION } from 'constants/driveTrain'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { DriveTrain } from 'models/car'
import { PublicationStatus } from 'models/status'
import { TranslationChange } from 'models/translations'
import { sleep } from 'utils/BasicUtil'
import { DriveTrainCreationProps } from 'utils/CarSettings/DriveTrain/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const DriveTrainInfo = ({
  handleContinue,
  driveTrainData,
  updateDriveTrainData,
}: DriveTrainCreationProps) => {
  const { driveTrainInformation } = driveTrainData
  const [driveTrainStatus, setDriveTrainStatus] = useState<boolean>(
    driveTrainInformation.status === PublicationStatus.PUBLISHED
  )
  const [stepInfo] = useState<DriveTrain>(driveTrainInformation)
  const [addedTranslations, setAddedTranslations] = useState<
    TranslationItemType[]
  >(driveTrainInformation.translations)

  const { text: translation } = useTranslation(textFiles.DRIVE_TRAIN_CREATION)
  const { information } = translation

  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const handleChangeTranslation = (
    translations: TranslationItemType[],
    type: TranslationChange
  ) => {
    sessionStorage.setItem('type', type)
    setAddedTranslations(translations)
  }

  const validationSchema = yup.object({
    name: yup.string().required(validationText.fieldRequired),
  })

  const formik = useFormik<Partial<DriveTrain>>({
    initialValues: {
      name: driveTrainInformation.name,
    },
    validationSchema,
    onSubmit: async (values) => {
      await sleep(1000)
      updateDriveTrainData({
        type: DRIVE_TRAIN_CREATION_OPTION.UPDATE_DRIVE_TRAIN_INFORMATION,
        payload: {
          ...stepInfo,
          name: values.name as string,
          translations: addedTranslations,
          status: driveTrainStatus
            ? PublicationStatus.PUBLISHED
            : PublicationStatus.UNPUBLISHED,
        },
      })

      handleContinue()
    },
  })

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <ToggleContainer
            title={information.isPublished}
            description={information.isPublishedDescription}
            status={driveTrainStatus}
            handleStatus={setDriveTrainStatus}
            testId="published"
          />
          <StyledTextFieldContainer
            title={information.name}
            description={information.nameDescription}
          >
            <StyledTextField
              variant="outlined"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder={information.placeholder}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
            {formik.touched.name && formik.errors.name ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.name}
              />
            ) : null}
          </StyledTextFieldContainer>
          <TranslationContainer>
            <Translation
              addedTranslations={addedTranslations}
              handleChangeTranslation={handleChangeTranslation}
              translationDescription={information.translationsDescription}
            />
          </TranslationContainer>
        </div>
      </StyledForm>
      <ButtonContainer
        isFirstStep
        confirmButtonType="submit"
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default DriveTrainInfo
