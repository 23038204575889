import { BREAK_POINTS } from 'styles/breakpoints'

export const contentStyle = {
  width: '70%',
  height: '90%',
  gridColumn: '2',
  gridRow: '1',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    width: '90%',
  },
}
