import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { BackContainer } from 'components/Common/BackContainer'
import BodyContainerWithHeader from 'components/Common/BodyContainerWithHeader'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { STATE_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { CreateStateInput } from 'models/services/operations/state'
import { State, StateCreationModel } from 'models/stateCreation'
import { PublicationStatus } from 'models/status'
import { validateGraphQLErrorCode } from 'utils/error'
import {
  createStateStepperItems,
  STATE_CREATION_STEPS as steps,
} from 'utils/State/creation'

import { CREATE_STATE } from 'graphQL/Operations/State/Creation/mutation'

import { StyledBox } from 'styles/operation/creation'

const initialStateInformation: State = {
  id: '',
  name: '',
  createdAt: new Date(),
  status: PublicationStatus.PUBLISHED,
  address: undefined,
}

const initialData: StateCreationModel = {
  stateInformation: initialStateInformation,
}

const StateCreation = () => {
  const [stateData, setStateData] = useState<StateCreationModel>(initialData)

  const [currentStep, setCurrentStep] = useState<number>(0)

  const history = useHistory()
  const { show } = useNotification()

  const { text } = useTranslation(textFiles.STATE_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const {
    title,

    processDescription,
    stepper: { firstStep, secondStep, thirdStep },
  } = text

  const stateStepperText = {
    firstStep,
    secondStep,
    thirdStep,
  }

  const stepperItems = createStateStepperItems(stateStepperText)
  const [createState, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateStateInput>
  >(CREATE_STATE, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
      history.push(STATE_SUB_ROUTES.STATE_LISTING)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
        })
    },
  })

  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }

  const updateStateData = (newState: StateCreationModel) => {
    setStateData(newState)
  }

  const handleSubmit = useCallback(() => {
    const {
      stateInformation: { address, name, status },
    } = stateData

    if (address)
      createState({
        variables: {
          input: {
            latitude: address.lat,
            longitude: address.lng,
            name,
            status,
          },
        },
      })
  }, [createState, stateData])

  return (
    <StyledBox>
      <BackContainer />
      <BodyContainerWithHeader
        title={title}
        subtitle={processDescription}
        currentStep={currentStep}
        stepperItems={stepperItems}
      >
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          stateData,
          updateStateData,
          handleSubmit,
          submitLoading,
        })}
      </BodyContainerWithHeader>
    </StyledBox>
  )
}

export default StateCreation
