import { formControlClasses, styled } from '@mui/material'

import Container from 'components/Common/Container'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledTextFieldContainer = styled(Container)({
  padding: 0,
  borderRadius: 0,
  '> p': {
    fontSize: '12px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
})

export const StyledButton = styled('label')({
  width: '144px',
  height: '35px',
  borderRadius: '4px',
  border: `2px solid ${colors.blue}`,
  color: colors.blue,
  fontSize: '14px',
  fontWeight: FONT_WEIGHT.BOLD,
  padding: '0px 12px',
  display: 'inline-flex',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  outline: 0,
  margin: 0,
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  textDecoration: 'none',
  textTransform: 'none',
  lineHeight: '1.75',
  alignItems: 'center',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  backgroundColor: colors.commonWhite,
  boxShadow: 'none',
})
