import React from 'react'
import { SelectChangeEvent } from '@mui/material'

import { ItemProps } from '../ItemContainer'
import { StyledSelect, StyledTextField, StyledTextFieldBox } from '../style'

type SelectProps = Omit<ItemProps, 'edit'>

const EditField = ({
  item,
  handleInfo,
  index,
  handleOptionsChange,
}: SelectProps) => {
  if (item.options) {
    return (
      <StyledSelect
        defaultValue={item.options[0].value}
        options={item.options}
        variant="standard"
        value={item.name || ''}
        onChange={(e: SelectChangeEvent<unknown>) => {
          handleInfo((prevState) => {
            const infoItems = [...prevState]
            const infoItem = {
              ...infoItems[index],

              name: e.target.value as string,
            }
            infoItems[index] = infoItem

            if (index === 2 && handleOptionsChange) {
              const newStateName = handleOptionsChange(
                String(infoItems[2].name)
              )

              infoItems[3].name = newStateName
            }

            return infoItems
          })
        }}
        sx={{ fontSize: 12, height: 40 }}
      />
    )
  }

  return (
    <>
      {!item.inverted ? (
        <StyledTextField
          placeholder={item.label}
          variant="standard"
          value={item.name || ''}
          onChange={(e) => {
            handleInfo((prevState) => {
              const infoItems = [...prevState]
              const infoItem = {
                ...infoItems[index],
                name:
                  typeof item.name === 'number'
                    ? Number(e.target.value)
                    : e.target.value,
              }
              infoItems[index] = infoItem
              return infoItems
            })
          }}
          InputProps={item.inputProps}
          type={typeof item.name === 'number' ? 'number' : 'text'}
        />
      ) : (
        <StyledTextFieldBox
          placeholder={item.label}
          variant="outlined"
          multiline
          rows={5}
          value={item.name || ''}
          onChange={(e) => {
            handleInfo((prevState) => {
              const infoItems = [...prevState]
              const infoItem = {
                ...infoItems[index],
                name: e.target.value,
              }
              infoItems[index] = infoItem
              return infoItems
            })
          }}
          inputProps={{
            maxLength: 120,
          }}
          type={typeof item.name === 'number' ? 'number' : 'text'}
        />
      )}
    </>
  )
}
export default EditField
