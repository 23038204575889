import { Slider } from '@mui/material'
import { styled } from '@mui/material/styles'

import { colors } from 'styles/theme'

export const StyledSlider = styled(Slider)({
  bottom: '7px',
  borderRadius: '2px',
  padding: '0px',
  '& .Mui-disabled': {
    color: colors.commonWhite,
  },
  '& .MuiSlider-thumb': {
    border: `3px solid ${colors.blue}`,
  },
  '& .MuiSlider-track': {
    color: colors.blue,
  },
  '& .MuiSlider-rail': {
    color: colors.subtitleGray,
    opacity: 1,
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: colors.blue,
  },
  '& .MuiSlider-valueLabelOpen': {
    transform: 'translateY(100%) scale(1) !important',
  },

  '& .MuiSlider-valueLabel:before': {
    bottom: '25px',
  },
})
