import { Box, styled } from '@mui/material'

import { colors } from 'styles/theme'

export const ProcessNumberContainer = styled(Box)({
  alignItems: 'center',
  backgroundColor: colors.backgroundGray,
  borderRadius: '7px',
  display: 'flex',
  height: '32px',
  justifyContent: 'center',
  width: '42px',
})
