import React, { useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { Location } from 'history'

import ConfirmationModal, {
  ConfirmationModalProps,
} from 'components/General/ConfirmationModal'

type RouteGuardProps = Pick<
  ConfirmationModalProps,
  'headerIcon' | 'onConfirmHandler' | 'text' | 'confirmIcon'
> & {
  when?: boolean | undefined
  navigate: (path: string) => void
}

/*
  This component is taken from: 
  https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
  The only minor change is the shouldBlockNavigation func since we are blocking any kind of route change. The use case is when a user tries to leave a page without saving some changes or completing an action 
  we alert the user to save their progress or finish the process.
*/

const RouteLeavingGuard = ({
  when,
  navigate,
  headerIcon,
  onConfirmHandler,
  text,
  confirmIcon,
}: RouteGuardProps) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const handleCancel = () => {
    setModalVisible(false)
  }
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation) {
      setModalVisible(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }
  const handleConfirmNavigationClick = () => {
    onConfirmHandler()
    setModalVisible(false)
    setConfirmedNavigation(true)
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname)
    }
  }, [confirmedNavigation, lastLocation, navigate])

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmationModal
        open={modalVisible}
        text={text}
        headerIcon={headerIcon}
        confirmIcon={confirmIcon}
        onConfirmHandler={handleConfirmNavigationClick}
        onCancelHandler={handleCancel}
      />
    </>
  )
}

export default RouteLeavingGuard
