import { gql } from '@apollo/client'

export const GET_STATES = gql`
  query getStates($input: FilterInput) {
    data: getStates(input: $input) {
      value: id
      name
      cities {
        value: id
        name
      }
    }
  }
`
