import React from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload'

import Button from 'components/Common/Button'

import { DownloadBox } from './styles'

type DownloadButtonProps = {
  downloadReport: () => Promise<void>
  downloadLoading: boolean
  text: string
}

const DownloadButton = ({
  downloadReport,
  downloadLoading,
  text,
}: DownloadButtonProps) => (
  <DownloadBox>
    <Button
      startIcon={<FileDownloadIcon />}
      buttonType="primary"
      onClick={downloadReport}
      disabled={downloadLoading}
      testId="download-button"
    >
      {text}
    </Button>
  </DownloadBox>
)

export default DownloadButton
