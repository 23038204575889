import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'

import { BackContainer } from 'components/Common/BackContainer'
import BodyContainerWithHeader from 'components/Common/BodyContainerWithHeader'
import LoadingAnimation from 'components/Common/LoadingAnimation'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { CITY_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import { City, CityCreationModel } from 'models/cityCreation'
import { Option } from 'models/Select'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { CreateCityInput } from 'models/services/operations/city'
import { PublicationStatus } from 'models/status'
import {
  CITY_CREATION_STEPS as steps,
  createCityStepperItems,
} from 'utils/City/creation'
import { validateGraphQLErrorCode } from 'utils/error'

import { GET_STATES } from 'graphQL/Common/State/queries'
import { CREATE_CITY } from 'graphQL/Operations/City/Creation/mutation'

import { StyledBox } from 'styles/operation/creation'

const initialCityInformationState: City = {
  id: '',
  name: '',
  createdAt: new Date(),
  status: PublicationStatus.PUBLISHED,
  state: '',
  address: undefined,
}

const initialData: CityCreationModel = {
  cityInformation: initialCityInformationState,
}

const CityCreation = () => {
  const [cityData, setCityData] = useState<CityCreationModel>(initialData)
  const [states, setStates] = useState<Option[]>([])

  const [currentStep, setCurrentStep] = useState<number>(0)

  const history = useHistory()
  const { show } = useNotification()

  const { text } = useTranslation(textFiles.CITY_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const {
    title,
    processDescription,
    stepper: { firstStep, secondStep, thirdStep },
  } = text

  const cityStepperText = {
    firstStep,
    secondStep,
    thirdStep,
  }

  const stepperItems = createCityStepperItems(cityStepperText)

  const { loading: stateLoading } = useQuery<GenericData<Option[]>>(
    GET_STATES,
    {
      onCompleted(response) {
        setStates(response.data)
      },
    }
  )

  const [createCity, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateCityInput>
  >(CREATE_CITY, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
      history.push(CITY_SUB_ROUTES.CITY_LISTING)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
        })
    },
  })

  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }

  const updateCityData = (newCity: CityCreationModel) => {
    setCityData(newCity)
  }

  const handleSubmit = useCallback(() => {
    const {
      cityInformation: { address, state, status, name },
    } = cityData

    if (address)
      createCity({
        variables: {
          input: {
            latitude: address.lat,
            longitude: address.lng,
            name,
            status,
            state,
          },
        },
      })
  }, [cityData, createCity])

  if (stateLoading) return <LoadingAnimation showAnimation={stateLoading} />

  return (
    <StyledBox>
      <BackContainer />
      <BodyContainerWithHeader
        title={title}
        subtitle={processDescription}
        currentStep={currentStep}
        stepperItems={stepperItems}
      >
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          cityData,
          updateCityData,
          handleSubmit,
          submitLoading,
          states,
        })}
      </BodyContainerWithHeader>
    </StyledBox>
  )
}

export default CityCreation
