// simulating async submit

import { SidebarItem } from 'components/General/Sidebar'

import { ENVIRONMENT } from 'constants/environment'
import { BackOfficeAllowedRoles, UserRoles } from 'models/role'

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))
/**
 * Check if atleast one
 * of the roles of the user is inside
 * the permitted roles passed to it.
 */
export const grantPermissions = (
  clientRoles: UserRoles[],
  permittedRoles: UserRoles[],
  hideInProduction?: boolean
) => {
  if (hideInProduction && ENVIRONMENT === 'production') return false

  return clientRoles.some((role) => permittedRoles.includes(role))
}

/*
 * Check if the user roles are even allowed to access
 * the BackOffice
 */
export const grantAccess = (clientRoles: UserRoles[]) => {
  return clientRoles.some((role) => BackOfficeAllowedRoles.includes(role))
}

/**
 * This is a recursive function which does the following,
 * if the element is 'private' (has allowedRoles prop)
 * it checks wether the current user has permission to
 * access it if it doesn't it goes to the next element
 * of the list otherwise it adds it. We keep repeating
 * the same proccess until we have iterating throught all
 * the structure.
 *
 * Aditioanlly, if the item has hideInProduction as `true` and
 * the current environment is production, it will hide the item
 * even if `allowedRoles` is not defined
 */

export const filterSidebarItems = (
  items: SidebarItem[],
  userRoles: UserRoles[]
): SidebarItem[] => {
  const filteredItems: SidebarItem[] = []
  items.forEach((item: SidebarItem) => {
    if (
      item.allowedRoles ||
      (item.hideInProduction && ENVIRONMENT === 'production')
    ) {
      const itemAllowedRoles = item.allowedRoles || []
      if (
        !grantPermissions(userRoles, itemAllowedRoles, item.hideInProduction)
      ) {
        return
      }
    }
    if (item.children) {
      filteredItems.push({
        ...item,
        children: filterSidebarItems(item.children, userRoles),
      })
    } else {
      filteredItems.push(item)
    }
  })
  return filteredItems
}

/*
 * Function to format names with names and last names
 */
export const formatFullName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined
) => {
  if (firstName && lastName) return `${firstName} ${lastName}`
  if (!firstName && lastName) return `${lastName}`
  if (firstName && !lastName) return `${firstName}`
  return 'N/A'
}

/*
 * Function to remove duplicates from array of objects
 */
export const uniqByReduce = <T>(array: T[]): T[] => {
  return array.reduce((acc: T[], cur: T) => {
    if (!acc.includes(cur)) {
      acc.push(cur)
    }
    return acc
  }, [])
}
