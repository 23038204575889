import { gql } from '@apollo/client'

export const LIST_BLACK_LISTED_BRANDS = gql`
  query listBlackListedBrands($input: FilterInput) {
    listData: listBlacklistedBrands(input: $input) {
      count
      data {
        id
        name
        comment
        createdAt
        blacklistStatus
        blackListedAt
      }
    }
  }
`

export const LIST_BLACK_LISTED_MODELS = gql`
  query listBlacklistedModels($input: FilterInput) {
    listData: listBlacklistedModels(input: $input) {
      count
      data {
        id
        name
        comment
        createdAt
        blacklistStatus
        blackListedAt
        brand {
          name
        }
      }
    }
  }
`

export const LIST_BLACK_LISTED_TRIM_LEVELS = gql`
  query listBlacklistedTrimLevels($input: FilterInput) {
    listData: listBlacklistedTrimLevels(input: $input) {
      count
      data {
        id
        name
        comment
        createdAt
        blacklistStatus
        blackListedAt
        model {
          name
          brand {
            name
          }
        }
        year
      }
    }
  }
`

export const GET_BLACK_LIST_BRANDS = gql`
  query getBlacklistBrands($input: FilterInput) {
    data: getBlacklistBrands(input: $input) {
      value: id
      name
    }
  }
`
export const GET_BLACK_LIST_MODELS = gql`
  query getBlacklistModels($input: FilterInput) {
    data: getBlacklistModels(input: $input) {
      value: id
      name
    }
  }
`
export const GET_BLACK_LIST_TRIM_LEVELS = gql`
  query getBlacklistTrimLevels($input: FilterInput!) {
    data: getBlacklistTrimLevels(input: $input) {
      value: id
      name
    }
  }
`
