import { styled } from '@mui/material'

import Box from 'components/Common/Box'

import { colors } from 'styles/theme'

export const StyledContainer = styled(Box)({
  alignItems: 'flex-end',
  display: 'flex',
  borderBottom: `1px solid ${colors.gray}`,
})
