import { calendarPickerClasses } from '@mui/lab'
import { styled } from '@mui/material'

import { colors } from 'styles/theme'

export const StyledDatePickerContainer = styled('div')({
  width: '100%',

  '& > div': {
    backgroundColor: 'transparent',
  },
  '& > div > div > div': {
    margin: '0 auto',
  },
  [`& .${calendarPickerClasses.viewTransitionContainer}`]: {
    '& > div': {
      '> div:nth-of-type(2)': {
        minHeight: '200px',
      },
    },
  },
  [`& .${calendarPickerClasses.root}`]: {
    margin: 0,
  },
  [`& .${calendarPickerClasses.root} > div:nth-of-type(1)`]: {
    '& > div:nth-of-type(1)': {
      position: 'absolute',
      marginLeft: '75px',
    },
    '> div:nth-of-type(2)': {
      width: '100%',
      justifyContent: 'space-between',
      paddingRight: '10px',
    },
  },
  '& .Mui-selected:hover': {
    backgroundColor: colors.blue,
    color: `${colors.commonWhite} !important`,
  },
})
