import { FilterInputType } from 'components/Inspection/Dashboard/FilterCard'

import { CurboSpot } from 'models/curboSpot'
import { Provider } from 'models/services/provider'
import { ExtendedStatus } from 'models/services/status'

export type FilterEntryNameType = { name: string }

export type FilterEntryStringType = {
  type: 'string'
} & FilterEntryNameType

export type FilterEntryVariableType = {
  type: FilterInputType
} & FilterEntryNameType

export type FiltersByCriteriaType = {
  filtersButton: string
  fieldOrEntryOptions: {
    name: FilterEntryStringType
    lastName: FilterEntryStringType
    status: FilterEntryStringType
    id: FilterEntryStringType
    curboSpot: FilterEntryStringType
    province: FilterEntryStringType
    templateName: FilterEntryStringType
    version: FilterEntryStringType
    providerName: FilterEntryStringType
    providerType: FilterEntryStringType
    stepsNumber: FilterEntryStringType
    [key: string]: FilterEntryVariableType
  }
  operatorOptions: {
    equals: string
    is: string
    startsWith: string
    endsWith: string
    contains: string
    [key: string]: string
  }
  titles: {
    addFilter: string
    currentFilters: string
  }
  buttons: {
    addFilter: string
    applyFilters: string
    clear: string
    cancel: string
  }
  statusOptions?: {
    pending: string
    scheduled: string
    inProcess: string
    approved: string
    rejected: string
    forRepair: string
    aborted: string
    active: string
    inactive: string
    [key: string]: string
  }
  provinceOptions?: {
    santoDomingo: string
    puertoPlata: string
    laRomana: string
    pedernales: string
    [key: string]: string
  }
  curboSpotOptions?: {
    santoDomingo: string
    santiago: string
    [key: string]: string
  }
  providerOptions?: {
    [key: string]: string
  }
  missingValue: {
    label: string
  }
}

/**
 *
 * @param text This is the translated text object for all fields,
 *  as declared in the inspectionDashboard.json file
 * @returns Returns all of the fields with the translated text
 */
export type FilteringOption = {
  disabled: boolean
  name: string
  value: string
}

type FilteringInputOptions = Pick<FilteringOption, 'disabled' | 'value'> &
  FilterEntryVariableType

export const getFilteringOptions = (
  text: FiltersByCriteriaType,
  statuses?: ExtendedStatus[],
  curboSpots?: CurboSpot[],
  providerList?: Provider[]
) => {
  const {
    filtersButton,
    fieldOrEntryOptions: fieldOrEntryOptionsText,
    operatorOptions: operatorOptionsText,
    provinceOptions: provinceOptionsText,
  } = text

  const fieldOrEntryOptions: FilteringInputOptions[] = []
  const operatorOptions: FilteringOption[] = []
  const provinceOptions: FilteringOption[] = []
  const statusOptions: FilteringOption[] = []
  const providerOptions: FilteringOption[] = []
  const curboSpotOptions: FilteringOption[] = []

  Object.keys(fieldOrEntryOptionsText).forEach((key: string) => {
    fieldOrEntryOptions.push({
      disabled: false,
      value: key,
      name: fieldOrEntryOptionsText[key].name,
      type: fieldOrEntryOptionsText[key].type,
    })
  })

  Object.keys(operatorOptionsText).forEach((key: string) => {
    operatorOptions.push({
      disabled: false,
      name: operatorOptionsText[key],
      value: key,
    })
  })

  if (provinceOptionsText) {
    Object.keys(provinceOptionsText).forEach((key: string) => {
      provinceOptions.push({
        disabled: false,
        name: provinceOptionsText[key],
        value: key,
      })
    })
  }

  if (statuses) {
    statuses.forEach((status) => {
      statusOptions.push({
        disabled: false,
        name: status.name,
        value: status.slug,
      })
    })
  }

  if (curboSpots && curboSpots.length > 0) {
    curboSpots.forEach((curboSpot) => {
      curboSpotOptions.push({
        disabled: false,
        name: curboSpot.name,
        value: curboSpot.id,
      })
    })
  }

  if (providerList && providerList.length > 0) {
    providerList.forEach((provider) => {
      providerOptions.push({
        disabled: false,
        name: provider.name,
        value: provider.id,
      })
    })
  }

  const singleIsOperatorOption = [
    {
      disabled: false,
      name: text.operatorOptions.in,
      value: 'in',
    },
  ]

  return {
    fieldOrEntryOptions,
    operatorOptions,
    provinceOptions,
    singleIsOperatorOption,
    statusOptions,
    curboSpotOptions,
    filtersButton,
    providerOptions,
  }
}
