import { GMAP_KEY } from 'constants/environment'
import { GoogleMapsAddress } from 'models/map'

export const deg2rad = (deg: number): number => deg * (Math.PI / 180)

export const getDistanceFromLatLngInKm = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const earthRadius = 6371 // Km
  const latDistance = deg2rad(lat2 - lat1)
  const lngDistance = deg2rad(lon2 - lon1)

  const haversine1 =
    Math.sin(latDistance / 2) * Math.sin(latDistance / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(lngDistance / 2) *
      Math.sin(lngDistance / 2)

  const haversine2 =
    2 * Math.atan2(Math.sqrt(haversine1), Math.sqrt(1 - haversine1))

  const kmDistance = earthRadius * haversine2
  return kmDistance
}

export const getAddressFromLatLng = async (lat: number, lng: number) => {
  const results = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GMAP_KEY}`
  )

  const resultsJson = await results.json()
  const address: GoogleMapsAddress = resultsJson.results[0]
  return address
}

export const mapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
}
