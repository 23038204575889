import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { SelectChangeEvent } from '@mui/material'
import {
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid'

import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import Table from 'components/Common/Table'
import Tooltip from 'components/Common/Table/Tooltip'
import Title from 'components/Common/Title'
import { Filter } from 'components/Inspection/Dashboard/FilterCard'
import DateFilter from 'components/Inspection/Listing/DateFilter'
import FieldFilter from 'components/Inspection/Listing/FieldFilter'
import FilterByCriteria from 'components/Listing/FilterByCriteria'

import { CityColumnField } from 'constants/city'
import { cypressAddButton } from 'constants/cypress'
import { FilteringOption } from 'constants/Inspection/filterByCriteria'
import { routes } from 'constants/routes'
import {
  commonListColumns,
  defaultSortModel,
  selectItems,
} from 'constants/table'
import { textFiles } from 'constants/textFiles'
import { UrlParamNames } from 'constants/urlQuery'
import useLocale from 'hooks/useLocale'
import useNotification from 'hooks/useNotification'
import useQueryState from 'hooks/useQueryState'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { CalendarRangeType } from 'models/date'
import { UserRoles } from 'models/role'
import { Option } from 'models/Select'
import {
  BaseIdEntity,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
  ListingFilterType,
} from 'models/services/base'
import { ListCities } from 'models/services/operations/city'
import { PublicationStatusMap } from 'models/status'
import {
  createFieldSelectItems,
  createFieldSelectLabels,
  defaultFields,
} from 'utils/City/listing'
import { getIsoDate } from 'utils/date'
import {
  cleanFilters,
  formatOptionsIntoFilteringOptions,
  generateFilterInput,
} from 'utils/filters'
import { buildDetailRoute } from 'utils/routes'
import {
  serializeFields,
  serializeFilters,
  serializePage,
  serializePageSize,
  serializeRange,
  serializeSortModel,
} from 'utils/serializers'
import { getCarSettingsStatusList } from 'utils/status'
import { verifyParam } from 'utils/verifyUrlData'

import { GET_STATES } from 'graphQL/Common/State/queries'
import { DELETE_CITY } from 'graphQL/Operations/City/Listing/mutations'
import { LIST_CITIES } from 'graphQL/Operations/City/Listing/queries'

type CustomFilterOption = {
  state: FilteringOption[]
}

const CityListingPage = () => {
  const { show } = useNotification()
  const { validateAllowedRoles } = useUser()
  const location = useLocation()
  const { search } = location
  const defaultSortedFields = [...defaultFields].sort()
  const [selectedLanguage] = useLocale()
  const history = useHistory()
  const { text } = useTranslation(textFiles.CITY_LISTING)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const fieldSelectItems = createFieldSelectItems(text.fieldSelect)
  const fieldSelectLabels = createFieldSelectLabels(text.fieldSelect)

  const [selectedFields, setSelectedFields] = useQueryState<string[]>(
    UrlParamNames.FIELDS,
    (verifyParam(UrlParamNames.FIELDS, search) as string[]) ||
      defaultSortedFields,
    serializeFields,
    defaultSortedFields
  )

  const [filtersList, setFiltersList] = useQueryState<Filter[]>(
    UrlParamNames.FILTERS,
    (verifyParam(UrlParamNames.FILTERS, search) as Filter[]) || [],
    serializeFilters
  )

  const [dateRange, setDateRange] = useQueryState<CalendarRangeType>(
    UrlParamNames.DATE,
    (verifyParam(UrlParamNames.DATE, search) as CalendarRangeType) || [],
    serializeRange
  )

  const [pageSize, setPageSize] = useQueryState<number>(
    UrlParamNames.LIMIT,
    (verifyParam(UrlParamNames.LIMIT, search) as number) || 10,
    serializePageSize,
    10
  )
  const [page, setPage] = useQueryState<number>(
    UrlParamNames.PAGE,
    (verifyParam(UrlParamNames.PAGE, search) as number) || 1,
    serializePage
  )

  const [sortModel, setSortModel] = useQueryState<GridSortModel>(
    UrlParamNames.SORT,
    (verifyParam(UrlParamNames.SORT, search) as GridSortModel) ||
      defaultSortModel,
    serializeSortModel,
    defaultSortModel
  )
  const [filterInput, setFiltersInput] = useState<ListingFilterType>(
    generateFilterInput(filtersList)
  )

  const [deleteItem, setDeleteItem] = useState<GridRowId | null>(null)
  const [data, setData] = useState<GridRowsProp>([])
  const [cityCount, setCityCount] = useState<number>(0)

  const [customFilterOptions, setCustomFilterOptions] =
    useState<CustomFilterOption>({ state: [] })

  const { fromDate, toDate } = useMemo(() => {
    return {
      fromDate: getIsoDate(dateRange.fromDate),
      toDate: getIsoDate(dateRange.toDate),
    }
  }, [dateRange])

  const deleteCity = useCallback((id: GridRowId) => {
    setDeleteItem(id)
  }, [])

  const editCity = useCallback(
    (id: GridCellValue) => {
      history.push(buildDetailRoute(id, routes.CITY_DETAIL))
    },
    [history]
  )

  const getPageCount = () => {
    return Math.ceil(cityCount / pageSize)
  }

  const createCity = () => {
    history.push(routes.CITY_CREATION)
  }
  const statusList = getCarSettingsStatusList(generalText)

  const {
    id: idColumn,
    isoDate: dateColumn,
    status: statusColumn,
    standard: standardColumn,
    actions: actionsColumn,
  } = commonListColumns(
    PublicationStatusMap,
    selectedLanguage,
    generalText,
    editCity,
    validateAllowedRoles([UserRoles.ADMIN, UserRoles.OPERATION_SUPERVISOR])
      ? deleteCity
      : undefined,
    statusList
  )

  const [columns, setColumns] = useState<GridColDef[]>([
    { ...idColumn, hide: !selectedFields.includes(idColumn.field) },
    {
      ...standardColumn,
      field: CityColumnField.NAME,
      hide: !selectedFields.includes(CityColumnField.NAME),
      headerName: text.table.name,
      renderCell: (params: GridRenderCellParams) => {
        const { name } = params.row
        return <Tooltip>{name}</Tooltip>
      },
    },
    {
      ...standardColumn,
      field: CityColumnField.STATE,
      hide: !selectedFields.includes(CityColumnField.STATE),
      headerName: text.table.state,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { state } = params.row
        return <Tooltip>{state ? state.name : ''}</Tooltip>
      },
    },
    {
      ...dateColumn,
      field: CityColumnField.CREATED_AT,
      hide: !selectedFields.includes(CityColumnField.CREATED_AT),
      headerName: text.table.createdAt,
    },
    {
      ...statusColumn,
      sortable: true,
      headerName: text.table.statusHeader,
      hide: !selectedFields.includes(statusColumn.field),
    },
    { ...actionsColumn, hide: !selectedFields.includes(actionsColumn.field) },
  ])

  const { loading: citiesLoading, refetch: refetchCities } = useQuery<
    ListCities,
    FilterInputVariable
  >(LIST_CITIES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        limit: pageSize,
        start: (page - 1) * pageSize,
        where: {
          createdAt_gte: fromDate,
          createdAt_lte: toDate,
          ...cleanFilters(filterInput),
        },
        sort:
          sortModel.length > 0 && sortModel[0].sort
            ? {
                [sortModel[0].field]: sortModel[0].sort,
              }
            : undefined,
      },
    },
    onCompleted(res) {
      setData(res.listCities.data)
      setCityCount(res.listCities.count)
    },
    onError() {
      show({
        updatedSeverity: 'error',
      })
    },
  })

  const { loading: statesLoading } = useQuery<GenericData<Option[]>>(
    GET_STATES,
    {
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
        },
      },
      onCompleted(response) {
        setCustomFilterOptions((prevCustomFilter) => {
          return {
            ...prevCustomFilter,
            state: formatOptionsIntoFilteringOptions(response.data),
          }
        })
      },
    }
  )

  const handleRefetchCities = async () => {
    try {
      const response = await refetchCities()

      if (response) {
        const cities = response.data.listCities
        if (cities.data.length === 0 && cities.count > 0) {
          setPage(1)
        }
      }
      show({
        updatedSeverity: 'success',
        message: generalText.notificationText.deleteSuccess,
      })
    } catch {
      show({
        updatedSeverity: 'error',
      })
    }
  }

  const [removeCity] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<string>
  >(DELETE_CITY, {
    onCompleted() {
      handleRefetchCities()
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: generalText.notificationText.deleteFail,
      })
    },
  })

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
  }

  const handleRemoveCity = async (id: string) => {
    removeCity({
      variables: {
        input: id,
      },
    })
  }

  const handleFiltersList = (
    newFiltersList: Filter[],
    newFilterInput: ListingFilterType
  ) => {
    setFiltersList(newFiltersList)
    setFiltersInput(newFilterInput)
  }

  const handleSelectItem = (valueInput: string) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        return column.field === valueInput
          ? { ...column, hide: !column.hide }
          : column
      })
    )
    setSelectedFields((prevFields) => {
      if (prevFields.includes(valueInput)) {
        return prevFields.filter((field) => field !== valueInput)
      }
      return [...prevFields, valueInput]
    })
  }

  const handleResetDefault = () => {
    setSelectedFields(defaultFields)
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = defaultFields.some(
          (defaultField) => column.field === defaultField
        )
        return { ...column, hide: !fieldExist }
      })
    )
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangePageSize = (event: SelectChangeEvent<number>) => {
    setPageSize(event.target.value as number)
  }

  const handleChangeDateRange = (newDateRange: CalendarRangeType) => {
    setDateRange(newDateRange)
    setPage(1)
  }

  if (statesLoading) return <LoadingAnimation showAnimation={statesLoading} />

  return (
    <Box width="100%">
      <Title
        icon={<PlaylistAddCheckIcon />}
        subtitle={`${cityCount} ${text.description}`}
        title={text.title}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        marginTop="2rem"
        width="100%"
      >
        <Button
          startIcon={<AddIcon />}
          size="medium"
          onClick={createCity}
          data-cy={cypressAddButton}
        >
          {text.addButton}
        </Button>

        <Box display="flex">
          {/* <Box marginRight="1rem" width="370px">
            <StyledTextField
              icon={<SearchIcon fontSize="small" />}
              placeholder={text.searchPlaceholder}
              fullWidth
              value={searchValue}
              onChange={handleSearchChange}
            />
          </Box> */}
          <Box marginRight="1rem">
            <FilterByCriteria
              filtersList={filtersList}
              handleFiltersList={handleFiltersList}
              file={textFiles.CITY_LISTING}
              filterInput={filterInput}
              statusList={statusList}
              options={customFilterOptions}
              staticFields={['status', 'state']}
            />
          </Box>
          <Box marginRight="1rem">
            <FieldFilter
              handleSelectItem={handleSelectItem}
              items={fieldSelectItems}
              selectedValues={selectedFields}
              handleResetDefault={handleResetDefault}
            />
          </Box>
          <Box>
            <DateFilter
              dateRange={dateRange}
              handleChangeDateRange={handleChangeDateRange}
              title={text.dateRangeTitle}
            />
          </Box>
        </Box>
      </Box>
      <Table
        columns={columns}
        dateRange={dateRange}
        // searchValue={searchValue}
        data={data}
        currentPage={page}
        onPageChange={handleChangePage}
        onSelectChange={handleChangePageSize}
        pageSize={pageSize}
        selectItems={selectItems}
        pageCount={getPageCount()}
        filtersList={filtersList}
        fields={selectedFields}
        fieldLabels={fieldSelectLabels}
        deletedItemId={deleteItem}
        setDeleteItemId={setDeleteItem}
        route={routes.CITY_DETAIL}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
        loading={citiesLoading}
        handleRemoveItem={handleRemoveCity}
        hideDownloadModal
        checkboxSelection={false}
      />
    </Box>
  )
}
export default CityListingPage
