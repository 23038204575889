import { FUEL_TYPE_CREATION_OPTION } from 'constants/fuelType'
import {
  FuelTypeCreationAction,
  FuelTypeCreationModel,
} from 'models/fuelTypeCreation'

const fuelTypeCreationReducer = (
  state: FuelTypeCreationModel,
  action: FuelTypeCreationAction
): FuelTypeCreationModel => {
  switch (action.type) {
    case FUEL_TYPE_CREATION_OPTION.UPDATE_FUEL_TYPE_INFORMATION:
      return { ...state, fuelTypeInformation: action.payload }

    default:
      return state
  }
}

export default fuelTypeCreationReducer
