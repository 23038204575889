import React from 'react'
import StaticDatePicker from '@mui/lab/StaticDatePicker'
import TextField from '@mui/material/TextField'

import { renderWeekPickerDay } from 'utils/CalendarUtils'

import { StyledDatePickerContainer } from './style'

export type CalendarType = {
  /**
   * Date of the calendar
   */
  date: Date | null
  /**
   * If `true`, user will not be allowed to interact with the Calendar
   */
  disabled?: boolean
  /**
   * Function fired when date changes
   */
  handleCalendarDate: (newDate: Date | null) => void
  /**
   * Maximum allowed date for the calendar to be clicked
   */
  maxDate?: Date
  /**
   * Minimum allowed date for the calendar to be clicked
   */
  minDate?: Date
  /**
   * Disable specific date
   */
  shouldDisableDate?: (date: Date) => boolean
  /**
   * Callback fired when date is accepted
   */
  onAccept?: () => void
}

const Calendar = ({
  date,
  disabled = false,
  handleCalendarDate,
  minDate,
  maxDate,
  shouldDisableDate,
  onAccept,
}: CalendarType) => {
  return (
    <StyledDatePickerContainer>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        openTo="day"
        value={date}
        onChange={handleCalendarDate}
        onAccept={onAccept}
        renderInput={(params) => <TextField {...params} />}
        renderDay={renderWeekPickerDay}
        showDaysOutsideCurrentMonth
        minDate={minDate}
        maxDate={maxDate}
        views={['day']}
        disabled={disabled}
        shouldDisableDate={shouldDisableDate}
      />
    </StyledDatePickerContainer>
  )
}

export default Calendar
