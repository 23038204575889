import { Hour } from 'models/hours'

export const initialCreationHour: Hour = {
  name: ':',
  am: true,
  slug: '0000',
  continentalTime: 1000,
  id: '0',
  visible: true,
}
