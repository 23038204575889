import { styled } from '@mui/material'

import Box from 'components/Common/Box'

import { BREAK_POINTS } from 'styles/breakpoints'

export const VehicleBox = styled(Box)({
  maxHeight: 680,
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '33% 33% 25%',
  columnGap: '30px',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '45% 45%',
  },
})

export const GridBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  gridAutoFlow: 'column',
  columnGap: '0px',
  overflowY: 'auto',
  width: '100%',
  height: 'auto',
  maxHeight: 280,
  marginBottom: '1rem',
})
