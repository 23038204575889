import { styled } from '@mui/material'

import Box from 'components/Common/Box'

export const TranslationContainer = styled(Box)({
  width: '100% !important',
  '> div': {
    padding: 0,
    width: 'auto',
  },
})
