import { AutocompleteItem } from 'components/Common/Autocomplete'

import {
  CarProviderEnum,
  VehicleCreationDataType,
} from 'models/services/PreInventory/creation'

export const providerOptions: AutocompleteItem[] = [
  {
    id: CarProviderEnum.DEALER,
    name: 'Dealer',
  },
  {
    id: CarProviderEnum.CURBO_SPOT,
    name: 'Curbo Spot',
  },
]
export const initialData: VehicleCreationDataType = {
  vehicleInformation: {
    firstStep: {
      make: null,
      model: null,
      vehicleYear: null,
      trimLevel: null,
      status: null,
      currentModelList: [],
      currentTrimLevelList: [],
      currentYearList: [],
    },
    secondStep: {
      mileage: '0',
      // category: [], NEED TO CONFIRM WITH BACKEND TEAM IF THIS FIELD IS STRING OR [STRING]
      categories: [],
      interiorColor: '',
      exteriorColor: '',
      vinNumber: '',
      chassisNumber: '',
      countryVersion: '',
      licensePlate: '',
    },
    thirdStep: null,
    fourthStep: null,
    fifthStep: null,
    sixthStep: null,
  },
  providerInformation: {
    dealer: null,
    providerOption: providerOptions[0],
    curboSpot: null,
  },
  vehiclePrice: {
    basePrice: 1,
    fee: 0,
    licensePlate: 0,
    transfer: 0,
    warranty: 0,
    repairCost: 0,
    customPrice: false,
  },
  vehiclePhotos: {
    thumbnailPicture: '',
    thumbnail: new File([''], 'filename'),
    interiorPictures: [new File([''], 'filename')],
    interiorPictureImages: [{ url: '', id: '' }],
    exteriorPictures: [new File([''], 'filename')],
    exteriorPictureImages: [{ url: '', id: '' }],
  },
}

export enum VEHICLE_CREATION_OPTION {
  UPDATE_VEHICLE_INFORMATION = 'updateVehicleInformation',
  UPDATE_PROVIDER_INFORMATION = 'updateProviderInformation',
  UPDATE_VEHICLE_PRICE = 'updateVehiclePrice',
  UPDATE_VEHICLE_PHOTOS = 'updateVehiclePhotos',
}

export const PHOTOGRAPHY_MANUAL_LINK =
  'https://drive.google.com/open?id=18JR8Eg0ygE8N-0zyc58VOuUfT_7_ri2W'
