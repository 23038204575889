import { INSPECTION_CREATION_OPTION } from 'constants/Inspection/creation'
import {
  InspectionCreationAction,
  InspectionCreationModel,
} from 'models/inspectionCreation'

const inspectionCreationReducer = (
  state: InspectionCreationModel,
  action: InspectionCreationAction
): InspectionCreationModel => {
  switch (action.type) {
    case INSPECTION_CREATION_OPTION.UPDATE_DEALER_INFORMATION:
      return { ...state, dealerInformation: action.payload }

    case INSPECTION_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION: {
      const vehicleInfo = [...state.vehicleInformation]
      const { payload, index } = action
      vehicleInfo.splice(index, 1, payload)
      const slicedInfo = vehicleInfo.slice(
        0,
        state.dealerInformation.inspectionAmount
      )

      return { ...state, vehicleInformation: slicedInfo }
    }

    case INSPECTION_CREATION_OPTION.UPDATE_SCHEDULING_INFORMATION:
      return { ...state, schedulingInformation: action.payload }

    default:
      return state
  }
}

export default inspectionCreationReducer
