import { gql } from '@apollo/client'

export const GET_TEMPLATE_BY_ID = gql`
  query getTemplateById($input: ID!) {
    data: getTemplateById(id: $input) {
      id
      name
      provider {
        id
        name
      }
      status
      version
      visible
      steps {
        checkpoints {
          description
          failureReason {
            id
            name
          }
          id
          label
          questions {
            answers {
              id
            }
            id
            isPictureRequired
            isRequired
            label
            value
          }
        }
        id
        label
      }
    }
  }
`
