import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import DeleteIcon from '@mui/icons-material/Delete'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import { SelectChangeEvent } from '@mui/material'
import {
  GridCellParams,
  GridColDef,
  GridRowId,
  GridRowsProp,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid'

import TableFooter from 'components/Common/TableFooter'
import ConfirmationModal, {
  ModalText,
} from 'components/General/ConfirmationModal'
import { Filter } from 'components/Inspection/Dashboard/FilterCard'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { CurboSpot } from 'models/curboSpot'
import { CalendarRangeType } from 'models/date'
import { SelectItem } from 'models/Select'
import { buildDetailRoute } from 'utils/routes'

import LoadingAnimation from '../LoadingAnimation'

import NoResults from './NoResults'
import { StyledDataGrid, StyledDeleteButton, StyledSection } from './style'

type TableProps = {
  /**
   * Columns passed needed for MUI DataGrid
   */
  columns: GridColDef[]
  /**
   * Data rows passed needed for MUI DataGrid
   */
  data: GridRowsProp

  /**
   * Page currently being displayed
   */
  currentPage: number
  /**
   * Function to manage the change of the page
   */
  onPageChange: (event: React.ChangeEvent<unknown>, newPage: number) => void
  /**
   * Rows per page
   */
  pageSize: number
  /**
   ** Function to manage the change of the rowsPerPage
   */
  onSelectChange: (event: SelectChangeEvent<number>) => void
  /**
   * Array of items being used for the select
   */
  selectItems: SelectItem[]
  /**
   * Number of elements(entries) of the table
   */
  pageCount: number
  /**
   * String contained in the searchbar input
   */
  searchValue?: string
  /**
   * Pair of dates selected as filter
   */
  dateRange?: CalendarRangeType
  /**
   * Array containing each of the filters selected by the user
   */
  filtersList: Filter[]
  /**
   * Fields list as selected in the inspection listing bar
   */
  fields: string[]
  /**
   * Array containing the fields Labels
   */
  fieldLabels: Record<string, string>
  /**
   * Idx of item to be deleted
   */
  deletedItemId: GridRowId | null
  /**
   ** Function to manage the change of the deletedItemId
   */
  setDeleteItemId: Dispatch<SetStateAction<GridRowId | null>>
  /**
   * String need to construct route for detail page
   */
  route: string
  /**
   * Build your own route to detail through a function
   */
  buildCustomDetail?: (id: GridRowId) => void

  /**
   * Optional string for different text file
   */
  file?: string

  /**
   * Filters by criteria translations file for the Download Modal
   */
  filtersFile?: string

  /**
   * Sorting model
   */
  sortModel?: GridSortModel
  /**
   * Function that triggers when model is sorted
   */
  handleSortModelChange?: (sortModel: GridSortModel) => void
  /**
   * If the data is loading or not
   */
  loading?: boolean
  /**
   * Parameter used for changing the row's height
   */
  rowHeight?: number
  /**
   * Function to remove the item
   */
  handleRemoveItem?: (id: string) => void
  /**
   * Function to remove the item
   */
  handleRemoveMultipleItems?: (ids: string[]) => void

  /**
   * If `true` it will hide the button that shows the download report modal
   */
  hideDownloadModal?: boolean

  /**
   * If `true`, the grid get a first column with a checkbox that allows to select rows.
   * @default
   * true
   */
  checkboxSelection?: boolean
  /**
   * CurboSpots
   */
  curboSpots?: CurboSpot[]

  /**
   * State to be passed through URL params, so that it can be read through useLocation.
   */
  urlState?: Record<string, string | boolean>
}

const Table = ({
  columns,
  data,
  currentPage,
  onPageChange,
  pageSize,
  onSelectChange,
  selectItems,
  pageCount,
  filtersList,
  dateRange,
  searchValue,
  fields,
  fieldLabels,
  route,
  setDeleteItemId,
  deletedItemId,
  file = textFiles.INSPECTION_LISTING,
  filtersFile,
  sortModel,
  handleSortModelChange,
  loading,
  rowHeight,
  handleRemoveItem,
  handleRemoveMultipleItems,
  hideDownloadModal,
  checkboxSelection = true,
  curboSpots,
  urlState,
  buildCustomDetail,
}: TableProps) => {
  const history = useHistory()
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
  const [openModal, setOpenModal] = useState<boolean>(false)

  const { text } = useTranslation(file)

  const handleBulkDelete = () => {
    if (deletedItemId && handleRemoveItem) {
      handleRemoveItem(deletedItemId as string)
      setDeleteItemId(null)
    } else {
      if (handleRemoveMultipleItems)
        handleRemoveMultipleItems(selectionModel as string[])
      setSelectionModel([])
    }
    setOpenModal(false)
  }

  const handleCancelDelete = () => {
    setOpenModal(false)
    if (!deletedItemId) {
      setSelectionModel([])
    }
    setDeleteItemId(null)
  }

  useEffect(() => {
    if (deletedItemId) {
      setOpenModal(true)
    }
  }, [deletedItemId])

  const modalText: ModalText = {
    header: deletedItemId
      ? text.modalText.replace('%d', deletedItemId)
      : text.modalBulkText.replace('%d', selectionModel.length),
    confirmButton: deletedItemId
      ? text.modalDeleteButton
      : text.modalDeleteBulkButton,
    cancelButton: text.modalCancelButton,
  }

  return (
    <>
      <div style={{ height: 675, width: '100%' }}>
        {checkboxSelection && (
          <StyledSection>
            {selectionModel.length > 0 ? (
              <>
                {selectionModel.length} {text.selectedRecords}
                {' - '}
                <StyledDeleteButton onClick={() => setOpenModal(true)}>
                  {text.bulkDeleteButton}
                  <DeleteIcon />
                </StyledDeleteButton>
              </>
            ) : null}
          </StyledSection>
        )}
        <StyledDataGrid
          rows={data}
          columns={columns}
          checkboxSelection={checkboxSelection}
          disableColumnMenu
          rowHeight={rowHeight || 50}
          pageSize={pageSize}
          rowsPerPageOptions={selectItems.map((item) => {
            return item.value as number
          })}
          hideFooter
          editMode="row"
          onCellClick={(params: GridCellParams) => {
            if (params.field !== 'actions') {
              if (buildCustomDetail) {
                buildCustomDetail(params.row.id)
              } else
                history.push(buildDetailRoute(params.row.id, route), urlState)
            }
          }}
          components={{
            NoRowsOverlay: () => <NoResults />,
            NoResultsOverlay: () => <NoResults />,
            ColumnSortedAscendingIcon: () => <ArrowDropUpIcon />,
            ColumnSortedDescendingIcon: () => <ArrowDropDownIcon />,
            ColumnUnsortedIcon: () => <ImportExportIcon />,
            LoadingOverlay: () => <LoadingAnimation showAnimation={loading} />,
          }}
          onSelectionModelChange={(newSelectionModel) => {
            if (checkboxSelection) setSelectionModel(newSelectionModel)
          }}
          selectionModel={selectionModel}
          onSortModelChange={handleSortModelChange}
          sortModel={sortModel}
          sortingMode="server"
          loading={loading}
        />
      </div>
      {pageCount ? (
        <TableFooter
          pageCount={pageCount}
          dateRange={dateRange}
          searchTerm={searchValue}
          currentPage={currentPage}
          onPageChange={onPageChange}
          onSelectChange={onSelectChange}
          pageSize={pageSize}
          selectItems={selectItems}
          rowLabelText={text.rowLabelText}
          filtersList={filtersList}
          fields={fields}
          fieldLabels={fieldLabels}
          filtersFile={filtersFile}
          hideDownloadModal={hideDownloadModal}
          curboSpots={curboSpots}
        />
      ) : null}

      <ConfirmationModal
        open={openModal}
        onConfirmHandler={handleBulkDelete}
        onCancelHandler={handleCancelDelete}
        text={modalText}
      />
    </>
  )
}

export default Table
