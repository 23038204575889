export enum TemplateColumnField {
  ID = 'id',
  TEMPLATE_NAME = 'templateName',
  VERSION = 'version',
  PROVIDER = 'provider',
  STEPS = 'stepsNumber',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  ACTIONS = 'actions',
}

export const draftHash = '#draft'
