/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { DocumentNode } from '@apollo/client'
import {
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid'

import PublicationStatusTag from 'components/CarSettings/Common/PublicationStatusTag'
import Avatar from 'components/Common/Avatar'
import ColorBox from 'components/Common/ColorBox'
import Tooltip from 'components/Common/Table/Tooltip'

import { commonColumnsText, commonListColumns } from 'constants/table'
import { textFiles } from 'constants/textFiles'
import { Language } from 'models/language'
import { SelectItem } from 'models/Select'
import { ExtendedStatus } from 'models/services/status'
import { PublicationStatus, PublicationStatusMap } from 'models/status'
import {
  baseDefaultFields,
  createBaseFieldSelectItems,
  createBaseFieldSelectLabels,
  createExtendedBaseFieldSelectItems,
  createExtendedBaseFieldSelectLabels,
  extendedDefaultFields,
} from 'utils/CarSettings/Base/listing'
import {
  brandDefaultFields,
  createBrandFieldSelectItems,
  createBrandFieldSelectLabels,
} from 'utils/CarSettings/Brand/listing'
import {
  colordDefaultFields,
  createColorFieldSelectItems,
  createColorFieldSelectLabels,
} from 'utils/CarSettings/Color/listing'
import {
  createModelFieldSelectItems,
  createModelFieldSelectLabels,
  ModelDefaultFields,
} from 'utils/CarSettings/Model/listing'
import {
  createTrimFieldSelectItems,
  createTrimFieldSelectLabels,
  TrimDefaultFields,
} from 'utils/CarSettings/Trim/listing'

import {
  LIST_BODY_STYLES,
  LIST_BRANDS,
  LIST_CATEGORIES,
  LIST_COLORS,
  LIST_COUNTRY_VERSIONS,
  LIST_DRIVE_TRAINS,
  LIST_FEATURES,
  LIST_FUEL_TYPES,
  LIST_MODELS,
  LIST_TRANSMISSIONS,
  LIST_TRIM_LEVELS,
} from 'graphQL/CarSettings/Listing/queries'

import {
  BaseColumnField,
  BrandColumnField,
  ColorColumnField,
  ModelColumnField,
  TrimColumnField,
} from './columnField'

export type CarSettingsKey =
  | 'brand'
  | 'model'
  | 'country-version'
  | 'feature'
  | 'transmission'
  | 'drive-train'
  | 'fuel-type'
  | 'category'
  | 'body-style'
  | 'color'
  | 'trim-level'

type CarSettingsFeatures = {
  textFile: string
  selectFields: string[]
  createFieldSelectItems: (text: any) => SelectItem[]
  createFieldSelectLabels: (text: any) => Record<string, string>
  query: DocumentNode
}

export const carSettingsListingFields: Record<
  CarSettingsKey,
  CarSettingsFeatures
> = {
  brand: {
    textFile: textFiles.BRAND_LISTING,
    selectFields: brandDefaultFields,
    createFieldSelectItems: createBrandFieldSelectItems,
    createFieldSelectLabels: createBrandFieldSelectLabels,
    query: LIST_BRANDS,
  },
  model: {
    textFile: textFiles.MODEL_LISTING,
    selectFields: ModelDefaultFields,
    createFieldSelectItems: createModelFieldSelectItems,
    createFieldSelectLabels: createModelFieldSelectLabels,
    query: LIST_MODELS,
  },
  'country-version': {
    textFile: textFiles.COUNTRY_VERSION_LISTING,
    selectFields: baseDefaultFields,
    createFieldSelectItems: createBaseFieldSelectItems,
    createFieldSelectLabels: createBaseFieldSelectLabels,
    query: LIST_COUNTRY_VERSIONS,
  },
  feature: {
    textFile: textFiles.FEATURE_LISTING,
    selectFields: baseDefaultFields,
    createFieldSelectItems: createBaseFieldSelectItems,
    createFieldSelectLabels: createBaseFieldSelectLabels,
    query: LIST_FEATURES,
  },
  transmission: {
    textFile: textFiles.TRANSMISSION_LISTING,
    selectFields: baseDefaultFields,
    createFieldSelectItems: createBaseFieldSelectItems,
    createFieldSelectLabels: createBaseFieldSelectLabels,
    query: LIST_TRANSMISSIONS,
  },
  'drive-train': {
    textFile: textFiles.DRIVE_TRAIN_LISTING,
    selectFields: baseDefaultFields,
    createFieldSelectItems: createBaseFieldSelectItems,
    createFieldSelectLabels: createBaseFieldSelectLabels,
    query: LIST_DRIVE_TRAINS,
  },
  'fuel-type': {
    textFile: textFiles.FUEL_TYPE_LISTING,
    selectFields: baseDefaultFields,
    createFieldSelectItems: createBaseFieldSelectItems,
    createFieldSelectLabels: createBaseFieldSelectLabels,
    query: LIST_FUEL_TYPES,
  },
  category: {
    textFile: textFiles.CATEGORY_LISTING,
    selectFields: extendedDefaultFields,
    createFieldSelectItems: createExtendedBaseFieldSelectItems,
    createFieldSelectLabels: createExtendedBaseFieldSelectLabels,
    query: LIST_CATEGORIES,
  },
  'body-style': {
    textFile: textFiles.BODY_STYLE_LISTING,
    selectFields: extendedDefaultFields,
    createFieldSelectItems: createExtendedBaseFieldSelectItems,
    createFieldSelectLabels: createExtendedBaseFieldSelectLabels,
    query: LIST_BODY_STYLES,
  },
  color: {
    textFile: textFiles.COLOR_LISTING,
    selectFields: colordDefaultFields,
    createFieldSelectItems: createColorFieldSelectItems,
    createFieldSelectLabels: createColorFieldSelectLabels,
    query: LIST_COLORS,
  },
  'trim-level': {
    textFile: textFiles.TRIM_LISTING,
    selectFields: TrimDefaultFields,
    createFieldSelectItems: createTrimFieldSelectItems,
    createFieldSelectLabels: createTrimFieldSelectLabels,
    query: LIST_TRIM_LEVELS,
  },
}

export const createCarSettingsColumns = (
  route: CarSettingsKey,
  statusModel: typeof PublicationStatusMap,
  selectedLanguage: Language,
  text: commonColumnsText,
  tableText: any,
  handleEdit?: (id: GridCellValue) => void,
  statusList?: ExtendedStatus[],
  selectedFields?: string[]
  // handleDelete?: (id: GridRowId) => void
): GridColDef[] => {
  const {
    id: idColumn,
    isoDate: dateColumn,
    status: statusColumn,
    standard: standardColumn,
    actions: actionsColumn,
    picture: pictureColumn,
  } = commonListColumns(
    statusModel,
    selectedLanguage,
    text,
    handleEdit,
    undefined,
    statusList
    // handleDelete
  )

  const publicationStatusColumn: GridColDef = {
    ...statusColumn,
    sortable: true,
    hide: !selectedFields?.includes(statusColumn.field),
    renderCell: (params: GridRenderCellParams) => {
      const { status } = params.row
      const isPublished = status === PublicationStatus.PUBLISHED

      return (
        <PublicationStatusTag
          published={isPublished}
          text={status ? text.status[status] : ''}
        />
      )
    },
  }
  const pictureHideColumn: GridColDef = {
    ...pictureColumn,
    hide: !selectedFields?.includes(pictureColumn.field),
  }

  const idHideColumn: GridColDef = {
    ...idColumn,
    hide: !selectedFields?.includes(idColumn.field),
  }
  const dateHideColumn: GridColDef = {
    ...dateColumn,
    field: BaseColumnField.CREATED_AT,
    hide: !selectedFields?.includes(BaseColumnField.CREATED_AT),
    headerName: tableText.createdAt,
  }

  const actionsHideColumn: GridColDef = {
    ...actionsColumn,
    hide: !selectedFields?.includes(actionsColumn.field),
  }

  const baseNameHideColumn: GridColDef = {
    ...standardColumn,
    field: BaseColumnField.NAME,
    hide: !selectedFields?.includes(BaseColumnField.NAME),
    headerName: tableText.name,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip>{params.row.name}</Tooltip>
    ),
  }

  const columns: Record<CarSettingsKey, GridColDef[]> = {
    brand: [
      pictureHideColumn,
      idHideColumn,
      {
        ...standardColumn,
        field: BrandColumnField.NAME,
        hide: !selectedFields?.includes(BrandColumnField.NAME),
        headerName: tableText.name,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip>{params.row.name}</Tooltip>
        ),
      },
      dateHideColumn,
      publicationStatusColumn,
      actionsHideColumn,
    ],
    model: [
      {
        ...pictureColumn,
        hide: !selectedFields?.includes(pictureColumn.field),
        renderCell: (params: GridRenderCellParams) => {
          const { brand } = params.row
          return (
            <Avatar
              image={brand && brand.picture}
              alt={params.row.inspectorName}
              style={{
                width: '40px',
                height: '40px',
              }}
            />
          )
        },
      },
      idHideColumn,
      {
        ...standardColumn,
        field: ModelColumnField.BRAND_NAME,
        hide: !selectedFields?.includes(ModelColumnField.BRAND_NAME),
        headerName: tableText.brandName,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const { brand } = params.row
          return <Tooltip>{brand ? brand.name : ''}</Tooltip>
        },
      },
      {
        ...standardColumn,
        hide: !selectedFields?.includes(ModelColumnField.NAME),
        field: ModelColumnField.NAME,
        headerName: tableText.name,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip>{params.row.name}</Tooltip>
        ),
      },
      dateHideColumn,
      publicationStatusColumn,
      actionsHideColumn,
    ],
    'country-version': [
      idHideColumn,
      baseNameHideColumn,
      dateHideColumn,
      publicationStatusColumn,
      actionsHideColumn,
    ],
    feature: [
      idHideColumn,
      baseNameHideColumn,
      dateHideColumn,
      publicationStatusColumn,
      actionsHideColumn,
    ],
    transmission: [
      idHideColumn,
      baseNameHideColumn,
      dateHideColumn,
      publicationStatusColumn,
      actionsHideColumn,
    ],
    'drive-train': [
      idHideColumn,
      baseNameHideColumn,
      dateHideColumn,
      publicationStatusColumn,
      actionsHideColumn,
    ],
    'fuel-type': [
      idHideColumn,
      baseNameHideColumn,
      dateHideColumn,
      publicationStatusColumn,
      actionsHideColumn,
    ],
    category: [
      pictureHideColumn,
      idHideColumn,
      baseNameHideColumn,
      dateHideColumn,
      publicationStatusColumn,
      actionsHideColumn,
    ],
    'body-style': [
      pictureHideColumn,
      idHideColumn,
      baseNameHideColumn,
      dateHideColumn,
      publicationStatusColumn,
      actionsHideColumn,
    ],
    color: [
      {
        ...standardColumn,
        field: ColorColumnField.COLOR,
        headerName: tableText.color,
        sortable: false,
        hide: !selectedFields?.includes(ColorColumnField.COLOR),
        renderCell: (params: GridRenderCellParams) => (
          <ColorBox hexCode={params.row.hexCode} />
        ),
      },
      idHideColumn,
      {
        ...standardColumn,
        field: ColorColumnField.NAME,
        headerName: tableText.name,
        hide: !selectedFields?.includes(ColorColumnField.NAME),
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip>{params.row.name}</Tooltip>
        ),
      },
      {
        ...standardColumn,
        field: ColorColumnField.HEX_CODE,
        headerName: tableText.hexCode,
        hide: !selectedFields?.includes(ColorColumnField.HEX_CODE),

        renderCell: (params: GridRenderCellParams) => (
          <Tooltip>{params.row.hexCode}</Tooltip>
        ),
      },
      {
        ...dateColumn,
        field: ColorColumnField.CREATED_AT,
        hide: !selectedFields?.includes(ColorColumnField.CREATED_AT),

        headerName: tableText.createdAt,
      },
      publicationStatusColumn,
      actionsHideColumn,
    ],
    'trim-level': [
      {
        ...idColumn,
        sortable: false,
        hide: !selectedFields?.includes(idColumn.field),
      },
      {
        ...standardColumn,
        field: TrimColumnField.NAME,
        headerName: tableText.name,
        sortable: false,
        hide: !selectedFields?.includes(TrimColumnField.NAME),
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip>{params.row.name}</Tooltip>
        ),
      },
      {
        ...standardColumn,
        field: TrimColumnField.CAR_NAME,
        headerName: tableText.carName,
        sortable: false,
        hide: !selectedFields?.includes(TrimColumnField.CAR_NAME),
        renderCell: (params: GridRenderCellParams) => {
          const { model } = params.row
          return (
            <Tooltip>
              {model ? `${model.brand.name} ${model.name}` : ''}
            </Tooltip>
          )
        },
      },
      {
        ...standardColumn,
        sortable: false,
        field: TrimColumnField.YEAR,
        headerName: tableText.year,
        hide: !selectedFields?.includes(TrimColumnField.YEAR),
      },
      {
        ...dateColumn,
        sortable: false,
        field: TrimColumnField.CREATED_AT,
        headerName: tableText.createdAt,
        hide: !selectedFields?.includes(TrimColumnField.CREATED_AT),
      },
      {
        ...publicationStatusColumn,
        sortable: false,
      },
      actionsHideColumn,
    ],
  }

  return columns[route]
}

export const trimStaticFields = ['carModel', 'bodyStyle', 'features', 'status']
