import { gql } from '@apollo/client'

export const LIST_DEALERS = gql`
  query listDealers($input: FilterInput!) {
    listDealers(input: $input) {
      count
      data {
        id
        name
        dealerContacts {
          name: agent
        }
        phone: telephoneNumber
        city {
          name
          state {
            name
          }
        }
        createdAt
        status
      }
    }
  }
`
