import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import ButtonContainer from 'components/CarSettings/Common/Creation/ButtonContainer'
import Box from 'components/Common/Box'
import LoadingAnimation from 'components/Common/LoadingAnimation'

import { ENTITY_NOT_FOUND_ERROR } from 'constants/error'
import { CURBO_SPOT_CREATION_OPTION } from 'constants/Operation/curboSpot'
import {
  arrayOfNamedDays,
  emptyCheckedWeekCalendar,
  initialOutOfSpotState,
} from 'constants/Operation/outOfSpot'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import {
  CheckedWeekCalendar,
  CheckHourType,
  DaysPair,
  ScheduleType,
} from 'models/outOfSpot'
import { FilterInputVariable, GenericData } from 'models/services/base'
import { HourTimeType } from 'models/services/curboSpot'
import { SpotCreationProps } from 'utils/CurboSpot/creation'

import {
  GET_INSPECTION_HOURS,
  GET_TEST_DRIVE_HOURS,
} from 'graphQL/Operations/OutOfSpot/queries'

import { ToggleType } from '../../Detail/Configuration'
import ConfigurationItem from '../../Detail/Configuration/ConfigurationItem'

import { StyledBox, StyledContainer } from './style'

const Schedule = ({
  spotData,
  handleBack,
  handleContinue,
  updateSpotData,
}: SpotCreationProps) => {
  const { spotSchedule } = spotData
  const { schedules } = spotSchedule
  const [toggleInformation, setToggleInformation] = useState<
    Record<ScheduleType, ToggleType>
  >({
    testDrive: {
      support: spotSchedule.supportsTestDrive,
      offSiteSupport: spotSchedule.supportsOffSiteTestDrive,
    },
    inspections: {
      support: spotSchedule.supportsInspection,
      offSiteSupport: spotSchedule.supportsOffSiteInspection,
    },
  })

  const [selectedDay, setSelectedDay] = useState<DaysPair>({
    testDrive: 'monday',
    inspections: 'monday',
  })

  const [inspectionCheckedHours, setInspectionCheckedHours] =
    useState<CheckedWeekCalendar>(emptyCheckedWeekCalendar)
  const [testDriveCheckedHours, setTestDriveCheckedHours] =
    useState<CheckedWeekCalendar>(emptyCheckedWeekCalendar)

  const { show } = useNotification()

  const { text } = useTranslation(textFiles.CURBO_SPOT_CREATION)

  const {
    schedule: { testDrive, inspection },
  } = text

  /* getCheckedWeek:
  1. We create an empty object that will hold the hours for each day of the week.
  2. We loop through the array of named days.
  3. For each day, we map the hours array to a new array of checkHourType objects.
  4. We check if the schedule has a value for the day. If it does, we set the checked property to true.
  5. If it doesn't, we set the checked property to false.
  6. We return the new object. */
  const getCheckedWeek = (hoursArray: HourTimeType[], type: ScheduleType) => {
    const weekHours = { ...emptyCheckedWeekCalendar }
    arrayOfNamedDays.forEach((day) => {
      weekHours[day] = hoursArray.map((hour) => {
        return {
          ...hour,
          checked:
            !!schedules[type][day].find(
              (foundHour) => foundHour.value === hour.value
            ) || false,
        } as CheckHourType
      })
    })
    return weekHours
  }

  const { loading: inspectionsHoursLoading } = useQuery<
    GenericData<HourTimeType[]>,
    FilterInputVariable
  >(GET_INSPECTION_HOURS, {
    variables: {
      input: {
        sort: {
          continentalTime: 'asc',
        },
      },
    },
    onCompleted: (response) => {
      const responseHours = response.data
      setInspectionCheckedHours(getCheckedWeek(responseHours, 'inspections'))
    },
    onError: () => {
      show({
        updatedSeverity: 'error',
        message: ENTITY_NOT_FOUND_ERROR,
      })
    },
  })

  const { loading: testDriveHoursLoading } = useQuery<
    GenericData<HourTimeType[]>,
    FilterInputVariable
  >(GET_TEST_DRIVE_HOURS, {
    variables: {
      input: {
        sort: {
          continentalTime: 'asc',
        },
      },
    },
    onCompleted: (response) => {
      const responseHours = response.data
      setTestDriveCheckedHours(getCheckedWeek(responseHours, 'testDrive'))
    },
    onError: () => {
      show({
        updatedSeverity: 'error',
        message: ENTITY_NOT_FOUND_ERROR,
      })
    },
  })

  const handleChangeCheckedHours = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: ScheduleType
  ) => {
    const selectedType =
      type === 'testDrive' ? testDriveCheckedHours : inspectionCheckedHours
    const selectedHour = selectedType[selectedDay[type]]
    const newValue = {
      ...selectedType,
      [selectedDay[type]]: selectedHour.map((hour) => {
        return {
          ...hour,
          checked: hour.value === e.target.value ? !hour.checked : hour.checked,
        }
      }),
    }

    if (type === 'testDrive') {
      setTestDriveCheckedHours(newValue)
    } else {
      setInspectionCheckedHours(newValue)
    }
  }

  const handleSubmit = () => {
    const { testDrive: testDriveSupport, inspections: inspectionSupport } =
      toggleInformation
    const testDriveArray = { ...initialOutOfSpotState.testDrive }
    const inspectionsArray = { ...initialOutOfSpotState.inspections }
    arrayOfNamedDays.forEach((day) => {
      testDriveArray[day] = [
        ...testDriveCheckedHours[day]
          .filter((foundHour) => foundHour.checked)
          .map((item) => ({
            name: item.name,
            value: item.value,
            am: item.am,
          })),
      ]
      inspectionsArray[day] = [
        ...inspectionCheckedHours[day]
          .filter((foundHour) => foundHour.checked)
          .map((item) => ({
            name: item.name,
            value: item.value,
            am: item.am,
          })),
      ]
    })
    updateSpotData({
      type: CURBO_SPOT_CREATION_OPTION.UPDATE_CURBO_SPOT_SCHEDULE,
      payload: {
        supportsTestDrive: testDriveSupport.support,
        supportsOffSiteTestDrive: testDriveSupport.offSiteSupport,
        supportsInspection: inspectionSupport.support,
        supportsOffSiteInspection: inspectionSupport.offSiteSupport,
        schedules: {
          inspections: inspectionsArray,
          testDrive: testDriveArray,
        },
      },
    })
    handleContinue()
  }

  const hourContainerSize =
    testDriveCheckedHours[selectedDay.testDrive].length >
    inspectionCheckedHours[selectedDay.inspections].length
      ? Math.ceil(testDriveCheckedHours[selectedDay.testDrive].length / 2)
      : Math.ceil(inspectionCheckedHours[selectedDay.inspections].length / 2)

  const isLoading = inspectionsHoursLoading || testDriveHoursLoading

  return (
    <StyledBox>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="flex-start"
      >
        <StyledContainer
          sx={{
            gridTemplateColumns: isLoading ? '100%' : '45% 45%',
            columnGap: isLoading ? 0 : '30px',
          }}
        >
          {isLoading ? (
            <LoadingAnimation showAnimation={isLoading} />
          ) : (
            <>
              <ConfigurationItem
                toggleInformation={toggleInformation.testDrive}
                setToggleInformation={setToggleInformation}
                hours={testDriveCheckedHours[selectedDay.testDrive]}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                handleChangeCheckbox={handleChangeCheckedHours}
                translation={testDrive}
                type="testDrive"
                hourContainerSize={hourContainerSize}
                testId="test-drive"
              />
              <ConfigurationItem
                toggleInformation={toggleInformation.inspections}
                setToggleInformation={setToggleInformation}
                hours={inspectionCheckedHours[selectedDay.inspections]}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                handleChangeCheckbox={handleChangeCheckedHours}
                translation={inspection}
                type="inspections"
                hourContainerSize={hourContainerSize}
                testId="inspections"
              />
            </>
          )}
        </StyledContainer>
        <ButtonContainer
          previousFunction={handleBack}
          nextFunction={handleSubmit}
          confirmButtonType="button"
          textType="next"
        />
      </Box>
    </StyledBox>
  )
}

export default Schedule
