import { gql } from '@apollo/client'

export const UPDATE_TRANSMISSION = gql`
  mutation updateTransmission($input: UpdateTransmissionInput!) {
    data: updateTransmission(input: $input) {
      id
      name
    }
  }
`
