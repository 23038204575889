import { gql } from '@apollo/client'

export const GET_VEHICLE_INFORMATION_SELECT_OPTIONS = gql`
  query getVehicleInformationSelectOptions($input: FilterInput) {
    categories: getCategories(input: $input) {
      value: id
      name
    }
    transmissions: getTransmissions(input: $input) {
      value: id
      name
    }
    fuelTypes: getFuelTypes(input: $input) {
      value: id
      name
    }
    bodyStyles: getBodyStyles(input: $input) {
      value: id
      name
    }
    driveTrains: getDriveTrains(input: $input) {
      value: id
      name
    }
    features: getFeatures(input: $input) {
      value: id
      name
    }
  }
`
