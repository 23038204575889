import { gql } from '@apollo/client'

export const GET_BODY_STYLE_BY_ID = gql`
  query getBodyStyleById($input: ID!) {
    data: getBodyStyleById(id: $input) {
      icon
      name
      status
      nameTranslations {
        en
        es
      }
    }
  }
`
