import React, { useState } from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import {
  ClickAwayListener,
  List,
  ListItem,
  MenuItem,
  Popper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'

import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import { ArrowIcon } from 'components/Common/Select/style'
import FilterCard, { Filter } from 'components/Inspection/Dashboard/FilterCard'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { CurboSpot } from 'models/curboSpot'
import { CalendarRangeType } from 'models/date'

import { boxShadow, colors } from 'styles/theme'

import DateRangeRow from './DateRangeRow'
import {
  FiltersBackground,
  RowTitle,
  StyledReportContainer,
  StyledRowBox,
} from './style'

type DownloadReportProps = {
  /**
   ** Self descriptive prop for showing Filters
   */
  filters: Filter[]
  /**
   * Pair of dates selected as filter
   */
  dateRange?: CalendarRangeType
  /**
   * Search term as written in the listing search bar
   */
  searchTerm?: string
  /**
   ** Format for the file name
   */
  reportName: { date: string; processName: string }
  /**
   ** How many data rows are to be downloaded
   */
  entries: number
  /**
   * Fields list as selected in the inspection listing bar
   */
  fields: string[]
  /**
   * Array containing the fields Labels
   */
  fieldLabels: Record<string, string>
  /**
   * CurboSpots
   */
  curboSpots?: CurboSpot[]
  /**
   * Optional string for different text file
   */
  file?: string
}

const DownloadModal = ({
  filters,
  dateRange,
  searchTerm = '',
  reportName,
  entries,
  fields,
  fieldLabels,
  curboSpots,
  file = textFiles.INSPECTION_LISTING,
}: DownloadReportProps) => {
  const { text } = useTranslation(textFiles.DOWNLOAD_REPORT)
  const { download: translation } = text

  const [fileType, setfileType] = useState<string>('csv')
  const [selectOpen, setSelectOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const fieldsArray: string[] = []
  filters.forEach((field) => {
    if (!fieldsArray.includes(field.fieldOrEntry))
      fieldsArray.push(field.fieldOrEntry)
  })

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleFileTypeChange = (event: SelectChangeEvent) => {
    setfileType(event.target.value as string)
  }

  const handleFileTypeOpen = () => {
    setSelectOpen(true)
  }
  const handleFileTypeClose = () => {
    setSelectOpen(false)
  }

  return (
    <Box marginLeft="10px">
      <Button
        id="button-download"
        size="small"
        sx={{ boxShadow }}
        startIcon={<DownloadIcon />}
        onClick={handleClick}
      >
        {translation.downloadButton}
      </Button>
      <Popper
        id="button-download"
        open={open}
        anchorEl={anchorEl}
        placement="top-end"
        style={{ paddingBottom: '0px' }}
      >
        <ClickAwayListener
          onClickAway={() => {
            if (selectOpen) return
            handleClose()
          }}
        >
          <Box>
            <StyledReportContainer
              id="popper-content"
              title={translation.title}
              description={translation.description}
            >
              <List
                disablePadding
                sx={{
                  'li::before': {
                    content: '"•"',
                    color: colors.blue,
                    fontWeight: 'bold',
                    display: 'inline-block',
                    width: '1em',
                  },
                }}
              >
                <Box>
                  <ListItem>
                    <RowTitle paragraph={false} variant="body1">
                      {translation.report}:
                    </RowTitle>
                  </ListItem>
                  <StyledRowBox>
                    <Typography variant="body2">
                      {`CBO_${reportName.processName}_${reportName.date}`}
                    </Typography>
                  </StyledRowBox>
                </Box>
                {dateRange?.fromDate && dateRange?.toDate ? (
                  <Box>
                    <ListItem>
                      <RowTitle paragraph={false} variant="body1">
                        {translation.date}:
                      </RowTitle>
                    </ListItem>
                    <DateRangeRow {...dateRange} />
                  </Box>
                ) : null}
                {searchTerm === '' ? null : (
                  <Box>
                    <ListItem>
                      <RowTitle paragraph={false} variant="body1">
                        {translation.search}:
                      </RowTitle>
                    </ListItem>
                    <StyledRowBox>
                      <Typography variant="body2">
                        {`"${searchTerm}"`}
                      </Typography>
                    </StyledRowBox>
                  </Box>
                )}
                {filters.length === 0 ? null : (
                  <Box>
                    <ListItem>
                      <RowTitle paragraph={false} variant="body1">
                        {translation.filters}:
                      </RowTitle>
                    </ListItem>
                    <StyledRowBox>
                      <FiltersBackground>
                        {filters.map((filter) => (
                          <FilterCard
                            key={`field-${filter.fieldOrEntry}-${filter.operator}-${filter.valueToCompare}`}
                            viewOnly
                            file={file}
                            filter={filter}
                            curboSpots={curboSpots}
                          />
                        ))}
                      </FiltersBackground>
                    </StyledRowBox>
                  </Box>
                )}
                {fields.length === 0 ? null : (
                  <Box>
                    <ListItem>
                      <RowTitle paragraph={false} variant="body1">
                        {translation.fields}:
                      </RowTitle>
                    </ListItem>
                    <StyledRowBox>
                      <FiltersBackground>
                        {fields.map((fieldItem) => (
                          <FilterCard
                            file={file}
                            key={fieldItem}
                            viewOnly
                            field={fieldLabels[fieldItem]}
                            curboSpots={curboSpots}
                          />
                        ))}
                      </FiltersBackground>
                    </StyledRowBox>
                  </Box>
                )}
                <Box>
                  <ListItem>
                    <RowTitle paragraph={false} variant="body1">
                      {translation.entries}:
                    </RowTitle>
                  </ListItem>
                  <StyledRowBox>
                    <Typography variant="body2">{entries}</Typography>
                  </StyledRowBox>
                </Box>
                <Box>
                  <ListItem>
                    <RowTitle paragraph={false} variant="body1">
                      {translation.fileType}:
                    </RowTitle>
                  </ListItem>
                  <StyledRowBox>
                    <Select
                      labelId="file-type-select-label"
                      id="file-type-select"
                      value={fileType}
                      onChange={handleFileTypeChange}
                      displayEmpty
                      MenuProps={{
                        disableScrollLock: true,
                        TransitionProps: {
                          onExited: handleFileTypeClose,
                        },
                      }}
                      onOpen={handleFileTypeOpen}
                      IconComponent={ArrowIcon}
                      sx={{
                        width: '97px',
                        height: '38px',
                        color: colors.border,
                        backgroundColor: colors.commonWhite,
                        borderColor: 'transparent',
                      }}
                    >
                      <MenuItem value="csv">
                        <RowTitle variant="body2">csv</RowTitle>
                      </MenuItem>
                      <MenuItem value="xlsx">
                        <RowTitle variant="body2">xlsx</RowTitle>
                      </MenuItem>
                      <MenuItem value="pdf">
                        <RowTitle variant="body2">pdf</RowTitle>
                      </MenuItem>
                    </Select>
                  </StyledRowBox>
                </Box>
                <StyledRowBox marginTop="1.2rem">
                  <Button
                    id="button-generate"
                    size="small"
                    sx={{ boxShadow, marginBottom: '55px' }}
                    startIcon={<DownloadIcon />}
                    onClick={handleClick}
                  >
                    {translation.generateReport}
                  </Button>
                </StyledRowBox>
              </List>
            </StyledReportContainer>
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  )
}

export default DownloadModal
