import { accordionSummaryClasses, styled } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import Box from 'components/Common/Box'
import Container from 'components/Common/Container'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledAccordion = styled(Accordion)({
  width: 200,
  '&:not(:last-child)': {
    borderBottom: `1px solid ${colors.commonWhite}`,
  },
  '&:before': {
    display: 'none',
  },
  svg: {
    color: colors.blue,
    marginRight: '0.5rem',
  },
  boxShadow: 'none',
})

export const StyledAccordionDetail = styled(AccordionDetails)({
  display: 'flex',
  color: colors.placeholderGray,
  fontSize: 14,
  fontWeight: FONT_WEIGHT.MEDIUM,
  padding: 8,
})

export const StyledAccordionSummary = styled(AccordionSummary)({
  display: 'flex',
  justifyContent: 'flex-start',
  padding: 0,
  maxHeight: 145,
  [`.${accordionSummaryClasses.expandIconWrapper}`]: {
    marginRight: 10,
  },
  [`.${accordionSummaryClasses.content}`]: {
    margin: 0,
    p: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      fontSize: 16,
      color: colors.black,
      fontWeight: FONT_WEIGHT.BOLD,
    },
  },
})

export const GridBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '100%',
  maxHeight: 120,
  overflowY: 'auto',
})

export const StyledCollapseItem = styled(Container)({
  maxHeight: 145,
  color: colors.placeholderGray,
  borderRadius: 0,
  padding: 0,
  marginBottom: '5px',
  marginRight: 5,
  '& > div:nth-of-type(2)': {
    margin: 0,
  },
  ul: {
    paddingLeft: '35px',
    fontSize: 14,
    fontWeight: FONT_WEIGHT.MEDIUM,
    margin: '5px 0',
    li: {
      '&::marker': {
        fontSize: 16,
        color: colors.blue,
      },
      span: {
        color: colors.placeholderGray,
      },
      marginBottom: 0,
    },
  },
})
