import { TRIM_CREATION_OPTION } from 'constants/trim'
import { TrimCreationAction, TrimCreationModel } from 'models/trimCreation'

const trimCreationReducer = (
  state: TrimCreationModel,
  action: TrimCreationAction
): TrimCreationModel => {
  switch (action.type) {
    case TRIM_CREATION_OPTION.UPDATE_BASIC_INFORMATION:
      return { ...state, basicInformation: action.payload }

    case TRIM_CREATION_OPTION.UPDATE_SETTINGS_INFORMATION:
      return { ...state, settingsInformation: action.payload }

    case TRIM_CREATION_OPTION.UPDATE_SPACING_INFORMATION:
      return { ...state, spacingInformation: action.payload }

    case TRIM_CREATION_OPTION.UPDATE_MEASUREMENT_INFORMATION:
      return { ...state, measurementInformation: action.payload }

    case TRIM_CREATION_OPTION.UPDATE_MILEAGE_INFORMATION:
      return { ...state, mileageInformation: action.payload }

    default:
      return state
  }
}

export default trimCreationReducer
