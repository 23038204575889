import { gql } from '@apollo/client'

export const GET_DEALER_BY_ID = gql`
  query getDealerById($input: ID!) {
    data: getDealerById(id: $input) {
      id
      mainPicture
      pictures
      name
      status
      phone: telephoneNumber
      address
      city {
        id
        name
        state {
          id
          name
          cities {
            value: id
            label: name
          }
        }
      }
      dealerContacts {
        name: agent
        phone: telephoneNumber
        email
      }
      carsCount
      latitude
      longitude
    }
  }
`

export const GET_CITIES = gql`
  query getCities {
    data: getCities {
      value: id
      name
      state {
        id
        name
      }
    }
  }
`
