import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'

import Skeleton from 'components/CarSettings/Common/Creation/Summary/Skeleton'
import SummaryItem from 'components/Common/SummaryItem'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { ModelCreationProps } from 'utils/CarSettings/Model/creation'

import { SectionContainer } from 'styles/creation'

const ModelSummary = ({
  handleBack,
  modelData,
  handleSubmit,
  submitLoading,
  brands,
}: ModelCreationProps) => {
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { modelInformation } = modelData
  const { text } = useTranslation(textFiles.MODEL_CREATION)
  const {
    summary: { title, description, name, status, brand },
  } = text

  return (
    <Skeleton
      previousFunction={handleBack}
      nextFunction={handleSubmit}
      title={title}
      description={description}
      submitLoading={submitLoading}
    >
      <SectionContainer>
        <SummaryItem
          title={name}
          text={modelInformation.name}
          icon={<DirectionsCarIcon />}
        />

        <SummaryItem
          title={status}
          text={generalText.status[modelInformation.status]}
          icon={<CheckCircleIcon />}
        />

        <SummaryItem
          title={brand}
          text={
            brands.filter(
              (item) => item.value === modelInformation.brandName
            )[0].name
          }
          icon={<DirectionsCarIcon />}
        />
      </SectionContainer>
    </Skeleton>
  )
}

export default ModelSummary
