import React from 'react'
import { Route, Switch } from 'react-router-dom'

import BlackListCreationPage from 'pages/Operation/BlackList/Creation'
import BlackListDetailPage from 'pages/Operation/BlackList/Detail'
import BlackListListingPage from 'pages/Operation/BlackList/Listing'
import CityCreationPage from 'pages/Operation/City/Creation'
import CityDetailPage from 'pages/Operation/City/Detail'
import CityListingPage from 'pages/Operation/City/Listing'
import CurboSpotCreationPage from 'pages/Operation/CurboSpot/Creation'
import CurboSpotDetailPage from 'pages/Operation/CurboSpot/Detail'
import CurboSpotListingPage from 'pages/Operation/CurboSpot/Listing'
import DealerCreationPage from 'pages/Operation/Dealer/Creation'
import DealerDetailPage from 'pages/Operation/Dealer/Detail'
import DealerListingPage from 'pages/Operation/Dealer/Listing'
import OutOfSpotPage from 'pages/Operation/OutOfSpot'
import StateCreationPage from 'pages/Operation/State/Creation'
import StateDetailPage from 'pages/Operation/State/Detail'
import StateListingPage from 'pages/Operation/State/Listing'

import { OPERATION_SUB_ROUTES } from 'constants/routes'

export const OperationRouter = () => (
  <Switch>
    <Route
      exact
      path={OPERATION_SUB_ROUTES.STATE_LISTING}
      component={StateListingPage}
    />
    <Route
      exact
      path={OPERATION_SUB_ROUTES.DEALER_LISTING}
      component={DealerListingPage}
    />
    <Route
      exact
      path={OPERATION_SUB_ROUTES.CITY_LISTING}
      component={CityListingPage}
    />
    <Route
      exact
      path={OPERATION_SUB_ROUTES.BLACK_LIST_LISTING}
      component={BlackListListingPage}
    />
    <Route
      exact
      path={OPERATION_SUB_ROUTES.BLACK_LIST_CREATION}
      component={BlackListCreationPage}
    />
    <Route
      exact
      path={OPERATION_SUB_ROUTES.CURBO_SPOT_CREATION}
      component={CurboSpotCreationPage}
    />
    <Route
      exact
      path={OPERATION_SUB_ROUTES.CURBO_SPOT_DETAIL}
      component={CurboSpotDetailPage}
    />
    <Route
      exact
      path={OPERATION_SUB_ROUTES.OUT_OF_SPOT}
      component={OutOfSpotPage}
    />
    <Route
      exact
      path={OPERATION_SUB_ROUTES.CURBO_SPOT_LISTING}
      component={CurboSpotListingPage}
    />

    <Route
      exact
      path={OPERATION_SUB_ROUTES.STATE_CREATION}
      component={StateCreationPage}
    />
    <Route
      exact
      path={OPERATION_SUB_ROUTES.CITY_CREATION}
      component={CityCreationPage}
    />
    <Route
      exact
      path={OPERATION_SUB_ROUTES.DEALER_CREATION}
      component={DealerCreationPage}
    />

    <Route
      exact
      path={OPERATION_SUB_ROUTES.STATE_DETAIL}
      component={StateDetailPage}
    />
    <Route
      exact
      path={OPERATION_SUB_ROUTES.CITY_DETAIL}
      component={CityDetailPage}
    />
    <Route
      exact
      path={OPERATION_SUB_ROUTES.DEALER_DETAIL}
      component={DealerDetailPage}
    />
    <Route
      exact
      path={OPERATION_SUB_ROUTES.BLACK_LIST_DETAIL}
      component={BlackListDetailPage}
    />
  </Switch>
)
