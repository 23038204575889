import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PersonIcon from '@mui/icons-material/Person'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { isPast, parseISO } from 'date-fns'

import Avatar from 'components/Common/Avatar'
import Box from 'components/Common/Box'
import {
  InformationCard,
  InfoRowType,
} from 'components/Common/InformationCard/InformationCard'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import { ContactContainer } from 'components/Inspection/Detail/Client/ContactContainer'
import AbsenceManager, { LocalAbsenceType } from 'components/Inspector/Absence'

import { unknownCurboSpotId } from 'constants/inspector'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import {
  FilterInput,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import {
  InspectorAbsence,
  InspectorOptionSelect,
  InspectorPersonalInformation,
  UpdateInspectorInput,
} from 'models/services/inspector/detail'
import { formatFullName } from 'utils/BasicUtil'

import { GET_CURBO_SPOTS } from 'graphQL/Common/Dealer/queries'
import { UPDATE_INSPECTOR } from 'graphQL/Inspector/Detail/mutations'
import { GET_INSPECTOR_ABSENCES } from 'graphQL/Inspector/Detail/queries'

import { colors } from 'styles/theme'

import {
  BlueCarIcon,
  contentStyle,
  ImageBox,
  PersonBox,
  StyledBox,
  StyledContainer,
} from './style'

export type ClientInfo = {
  name: string
  address: string
  phone: string
  email: string
}

const sortAbsences = (absences: LocalAbsenceType[]) => {
  const orderedAbsences = absences.sort(
    (absence1, absence2) =>
      Number(parseISO(absence1.startDate)) -
      Number(parseISO(absence2.startDate))
  )
  const activeAbsences = orderedAbsences.filter(
    (item) => !isPast(parseISO(item.endDate))
  )
  const pastAbsences = orderedAbsences.filter((item) =>
    isPast(parseISO(item.endDate))
  )
  return {
    active: activeAbsences,
    past: pastAbsences,
  }
}

export type PersonalInfoProps = {
  inspectorData: InspectorPersonalInformation
  handleInspectorData: (data: InspectorPersonalInformation) => void
}

const PersonalInfoTab = ({
  inspectorData,
  handleInspectorData,
}: PersonalInfoProps) => {
  const { show } = useNotification()
  const { text } = useTranslation(textFiles.INSPECTOR_DETAIL)

  const { personal: translation } = text

  const [activeAbsences, setActiveAbsences] = useState<LocalAbsenceType[]>([])
  const [pastAbsences, setPastAbsences] = useState<LocalAbsenceType[]>([])
  const [curboSpot, setCurboSpot] = useState<InspectorOptionSelect>(
    inspectorData.curboSpot
  )

  const getString = (input: null | string) => {
    return input === null ? 'N/A' : input
  }

  const informationRows: InfoRowType[] = [
    {
      icon: <PersonIcon />,
      title: formatFullName(inspectorData.name, inspectorData.lastName),
      subtitle: translation.personalName,
    },
    {
      icon: <LocationOnIcon />,
      title: getString(inspectorData.address),
      subtitle: translation.personalAddress,
    },
  ]

  const { data: curboSpotsList, loading: loadingCurboSpots } = useQuery<
    GenericData<InspectorOptionSelect[]>
  >(GET_CURBO_SPOTS, {
    onError() {
      show({
        updatedSeverity: 'error',
      })
    },
  })

  const { loading: loadingAbsences, refetch: refetchAbsences } = useQuery<
    GenericData<InspectorAbsence[]>,
    GenericInputVariable<FilterInput>
  >(GET_INSPECTOR_ABSENCES, {
    variables: { input: { start: 0, where: { inspector: inspectorData.id } } },
    onCompleted(response) {
      const dataAbsence: LocalAbsenceType[] = response.data.map(
        (absence, index) => {
          return {
            key: index,
            id: absence.id,
            startDate: absence.startDate,
            endDate: absence.endDate,
            reason: absence.reason,
          }
        }
      )
      const absences = sortAbsences(dataAbsence)

      setActiveAbsences(absences.active)
      setPastAbsences(absences.past)
    },
  })

  const [updateInspector, { loading: mutationLoading }] = useMutation<
    GenericData<InspectorPersonalInformation>,
    GenericInputVariable<UpdateInspectorInput>
  >(UPDATE_INSPECTOR, {
    onCompleted({ data }) {
      handleInspectorData(data)
    },
    onError(error) {
      const errorText: string =
        error.graphQLErrors && error.graphQLErrors.length > 0
          ? error.graphQLErrors[0].message
          : error.message
      show({
        updatedSeverity: 'error',
        message: errorText,
      })
    },
  })

  const handleCurboSpotChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value === 'null') {
      setCurboSpot({ id: event.target.value, name: translation.noCurboSpot })
    }
    const selected = curboSpotsList?.data.find(
      (spot) => spot.id === event.target.value
    ) as InspectorOptionSelect
    setCurboSpot(selected as InspectorOptionSelect)

    updateInspector({
      variables: {
        input: {
          where: { id: inspectorData.id },
          data: { curboSpot: (selected as InspectorOptionSelect).id },
        },
      },
    })
  }

  return (
    <PersonBox>
      <StyledContainer
        title={translation.personalTitle}
        description={translation.personalSubtitle}
        contentStyle={{ border: 'none', paddingLeft: '0px' }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          columnGap="15px"
        >
          <Box display="flex" flexDirection="column" width="50%">
            <ImageBox>
              <Avatar
                height="330px"
                width="336px"
                variant="rounded"
                image={inspectorData.profilePicture}
              />
            </ImageBox>
            <StyledContainer
              title={translation.curboSpot}
              sx={{
                marginTop: '18px',
                paddingLeft: '0px !important',
                width: '362px',
              }}
            >
              {loadingCurboSpots || mutationLoading ? (
                <LoadingAnimation
                  showAnimation={loadingCurboSpots || mutationLoading}
                />
              ) : (
                <Select
                  value={curboSpot === null ? unknownCurboSpotId : curboSpot.id}
                  onChange={handleCurboSpotChange}
                  displayEmpty
                  MenuProps={{ disableScrollLock: true }}
                  sx={{
                    width: '100%',
                    color: `${colors.blue}`,
                  }}
                  startAdornment={<BlueCarIcon />}
                >
                  {curboSpot === null && (
                    <MenuItem
                      key={unknownCurboSpotId}
                      value={unknownCurboSpotId}
                      disabled
                    >
                      {translation.noCurboSpot}
                    </MenuItem>
                  )}

                  {curboSpotsList?.data.map((spot) => {
                    return (
                      <MenuItem key={spot.id} value={spot.id}>
                        {spot.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              )}
            </StyledContainer>
          </Box>
          <Box display="flex" flexDirection="column" width="50%">
            <StyledBox>
              <InformationCard rows={informationRows} />
            </StyledBox>
            <ContactContainer
              phone={getString(inspectorData.telephoneNumber)}
              email={inspectorData.email}
              contentSx={{
                marginTop: '18px',
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
              }}
            />
          </Box>
        </Box>
      </StyledContainer>
      <StyledContainer
        title={translation.absenceTitle}
        description={translation.absenceSubtitle}
        contentStyle={contentStyle}
        width="fit-content"
      >
        {loadingAbsences ? (
          <LoadingAnimation showAnimation={loadingAbsences} />
        ) : (
          <AbsenceManager
            inspectorId={inspectorData.id}
            activeAbsences={activeAbsences}
            pastAbsences={pastAbsences}
            refreshFunction={refetchAbsences}
          />
        )}
      </StyledContainer>
    </PersonBox>
  )
}

export default PersonalInfoTab
