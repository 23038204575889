import React, { useState } from 'react'

import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'
import Box from 'components/Common/Box'
import Container from 'components/Common/Container'
import LocationSection from 'components/Common/Location'

// import HistoryLog from 'components/Common/HistoryLog'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Address } from 'models/map'
import { CurboSpotDetailDataType } from 'models/services/operations/curboSpot'

import { StyledContainer } from './style'

type Props = {
  information: CurboSpotDetailDataType
  id: string
  handleSave: (spotData: CurboSpotDetailDataType) => Promise<boolean>
  loading: boolean
}

const Location = ({ information, id, handleSave, loading }: Props) => {
  const { name, latitude, longitude, address: spotAddress } = information

  const { text } = useTranslation(textFiles.CURBO_SPOT_DETAIL)
  const { curboSpotLocation: translation } = text

  const initialAddress = {
    id,
    name,
    lat: latitude,
    lng: longitude,
    address: spotAddress,
  }

  const [error, setError] = useState<boolean>(false)
  const [address, setAddress] = useState<Address | undefined>(initialAddress)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)

  const resetState = () => {
    setAddress(initialAddress)
    setHasUnsavedChanges(false)
  }

  const handleAddressChange = (newAddress: Address | undefined) => {
    if (newAddress === undefined) {
      setError(true)
    } else {
      setAddress(newAddress)
    }
    setHasUnsavedChanges(true)
  }

  const handleSaveChanges = async () => {
    if (address === undefined) {
      setError(true)
    } else {
      const isUpdateSuccesful = await handleSave({
        ...information,
        latitude: address?.lat,
        longitude: address?.lng,
        address: address.address,
      })
      if (isUpdateSuccesful) {
        setHasUnsavedChanges(false)
      }
    }
  }

  return (
    <StyledContainer>
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <Container
          title={translation.map}
          description={translation.mapDescription}
          width="834px"
          padding="0px !important"
        >
          <LocationSection
            translation={translation}
            showError={error}
            address={address}
            handleAddressChange={handleAddressChange}
            containerStyling={{
              width: '95%',
            }}
            mapHeight="393px"
            hasCoordinatePicker
            curboSpots={[]}
            circleCenter={
              address ? { lat: address.lat, lng: address.lng } : undefined
            }
          />
        </Container>
      </Box>
      <SaveChangesBlock
        handleSaveChanges={handleSaveChanges}
        resetState={resetState}
        submitLoading={loading}
        shouldRender={hasUnsavedChanges}
      />
      {/* <Box width="834px">
        <HistoryLog
          height="200px"
          dateFormat="time"
          hasPadding={!!(width && width <= BREAK_POINTS.XL)}
          sx={{ padding: '0px !important', paddingY: '1rem !important' }}
        />
      </Box> */}
    </StyledContainer>
  )
}
export default Location
