import {
  formControlClasses,
  sliderClasses,
  styled,
  TextField as MUITextField,
  typographyClasses,
} from '@mui/material'

import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import Container from 'components/Common/Container'
import Select from 'components/Common/Select'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const CheckpointContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'flex-start',
  width: '100%',
  [`.${typographyClasses.body1}`]: {
    fontSize: '14px !important',
  },
})

export const StyledIndex = styled('section')({
  fontSize: 14,
  color: colors.blue,
  fontWeight: FONT_WEIGHT.BOLD,
  marginRight: 6,
})

export const CheckpointTitle = styled(Box)({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '45% 45%',
  columnGap: '30px',
  rowGap: '20px',
})

export const QuestionContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'flex-start',
  width: '100%',
})

export const StyledTextFieldContainer = styled(Container)({
  padding: 0,
  borderRadius: 0,
  marginBottom: 20,

  '> p': {
    fontSize: '12px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
  '> div:nth-of-type(2)': {
    position: 'static',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [`.${sliderClasses.rail}`]: {
      height: 10,
      color: colors.gray,
    },
    [`.${sliderClasses.track}`]: {
      height: 10,
    },
  },
})

export const SelectContainer = styled(Container)({
  padding: 0,
  borderRadius: 0,
  marginBottom: 20,

  '> p': {
    fontSize: '12px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
})

export const StyledTextField = styled(MUITextField)({
  border: `1px solid ${colors.lightGray}`,
  borderRadius: '4px',
  opacity: 1,
  height: '38px',
  '& div': {
    color: colors.placeholderGray,
    fontWeight: FONT_WEIGHT.MEDIUM,
    height: '100%',
    fontSize: '12px',
  },
  '& div fieldset': {
    border: 'none',
  },
  input: {
    height: '5px',
    borderRadius: '4px',
  },
})

export const StyledSelect = styled(Select)({
  fontSize: '12px',
})

export const StyledButton = styled(Button)({
  height: '30px !important',
  fontWeight: `${FONT_WEIGHT.MEDIUM} !important`,
})

export const IconButton = styled('div')({
  height: 59,
  display: 'flex',
  alignItems: 'flex-end',
  cursor: 'pointer',
  position: 'absolute',
  right: 0,
  top: -30,
})
