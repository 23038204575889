import React, { FunctionComponent, useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  InputAdornment,
  InputBaseComponentProps,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'

import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import NumberValueInput from 'components/Inventory/Common/NumberValueInput'
import ButtonContainer from 'components/Template/Creation/ButtonContainer'

import { VEHICLE_CREATION_OPTION } from 'constants/Inventory/creation'
import { textFiles } from 'constants/textFiles'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import { GenericData } from 'models/services/base'
import { Price as PriceModel, PriceConfig } from 'models/services/car'
import { VehicleCreationProps } from 'utils/Inventory/creation'

import { GET_PRICE_CONFIG } from 'graphQL/Inventory/Creation/queries'

import {
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

export const calculateListingPrice = (values: PriceModel) => {
  return values.basePrice + values.fee + values.repairCost
}

export const calculateTotalPrice = (values: PriceModel) => {
  const { basePrice, fee, licensePlate, repairCost, transfer, warranty } =
    values

  return basePrice + fee + licensePlate + repairCost + transfer + warranty
}

const initialPriceConfig: PriceConfig = {
  licensePlate: 1,
  repairCost: 1,
  warranty: 1,
}

const Price = ({
  vehicleData,
  updateVehicleData,
  handleBack,
  handleContinue,
}: VehicleCreationProps) => {
  const {
    text: { vehiclePricing: translation },
  } = useTranslation(textFiles.VEHICLE_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const [priceConfig, setPriceConfig] =
    useState<PriceConfig>(initialPriceConfig)

  const appSetting = useSetting()[2]
  const currency = appSetting ? appSetting.currency : null
  const priceCurrency = currency ? `${currency.code}` : ''

  const validationSchema = yup.object().shape({
    basePrice: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    transfer: yup
      .number()
      .required(validationText.fieldRequired)
      .min(0, validationText.greaterOrEqualThanZero),
    fee: yup
      .number()
      .required(validationText.fieldRequired)
      .min(0, validationText.greaterOrEqualThanZero),
    licensePlate: yup
      .number()
      .required(validationText.fieldRequired)
      .min(0, validationText.greaterOrEqualThanZero),
    warranty: yup
      .number()
      .required(validationText.fieldRequired)
      .min(0, validationText.greaterOrEqualThanZero),
    repairCost: yup
      .number()
      .required(validationText.fieldRequired)
      .min(0, validationText.greaterOrEqualThanZero),
  })

  const formik = useFormik<PriceModel>({
    initialValues: vehicleData.vehiclePrice,
    validationSchema,
    onSubmit: (values) => {
      updateVehicleData({
        type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_PRICE,
        payload: values,
      })
      handleContinue()
    },
  })

  const { loading: priceConfigLoading } = useQuery<GenericData<PriceConfig>>(
    GET_PRICE_CONFIG,
    {
      onCompleted(response) {
        const { data } = response
        setPriceConfig(data)
        if (!formik.values.customPrice) {
          formik.setValues({
            ...formik.values,
            licensePlate: data.licensePlate,
            warranty: data.warranty,
            repairCost: data.repairCost,
          })
        }
      },
    }
  )

  const handleToggleChange = (checked: boolean) => {
    formik.setValues({
      ...formik.values,
      customPrice: checked,
      ...(!checked && {
        licensePlate: priceConfig.licensePlate,
        warranty: priceConfig.warranty,
        repairCost: priceConfig.repairCost,
      }),
    })
  }

  if (priceConfigLoading) return <LoadingAnimation showAnimation />

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <StyledTextFieldContainer title={translation.basePriceLabel}>
            <StyledTextField
              variant="outlined"
              name="basePrice"
              value={formik.values.basePrice}
              placeholder={translation.basePriceLabel}
              InputProps={{
                inputComponent:
                  NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2">{priceCurrency}</Typography>
                  </InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.basePrice)}
            />
            {formik.errors.basePrice ? (
              <StyledErrorMessage text={formik.errors.basePrice} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.curboCertificationLabel}>
            <StyledTextField
              variant="outlined"
              name="fee"
              value={formik.values.fee}
              placeholder={translation.curboCertificationLabel}
              InputProps={{
                inputComponent:
                  NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2">{priceCurrency}</Typography>
                  </InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.fee && Boolean(formik.errors.fee)}
            />
            {formik.errors.fee ? (
              <StyledErrorMessage text={formik.errors.fee} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.transferCostLabel}>
            <StyledTextField
              variant="outlined"
              name="transfer"
              value={formik.values.transfer}
              placeholder={translation.transferCostLabel}
              InputProps={{
                inputComponent:
                  NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2">{priceCurrency}</Typography>
                  </InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.transfer)}
            />
            {formik.errors.transfer ? (
              <StyledErrorMessage text={formik.errors.transfer} />
            ) : null}
          </StyledTextFieldContainer>
          <ToggleContainer
            status={formik.values.customPrice}
            title={translation.personalizationLabel}
            description=""
            handleStatus={handleToggleChange}
            testId="price-customization"
          />
          <StyledTextFieldContainer title={translation.warrantyPriceLabel}>
            <StyledTextField
              variant="outlined"
              name="warranty"
              value={formik.values.warranty}
              placeholder={translation.warrantyPriceLabel}
              InputProps={{
                inputComponent:
                  NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2">{priceCurrency}</Typography>
                  </InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.warranty)}
              disabled={!formik.values.customPrice}
            />
            {formik.errors.warranty ? (
              <StyledErrorMessage text={formik.errors.warranty} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.licensePlateLabel}>
            <StyledTextField
              variant="outlined"
              name="licensePlate"
              value={formik.values.licensePlate}
              placeholder={translation.licensePlateLabel}
              InputProps={{
                inputComponent:
                  NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2">{priceCurrency}</Typography>
                  </InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.licensePlate)}
              disabled={!formik.values.customPrice}
            />
            {formik.errors.licensePlate ? (
              <StyledErrorMessage text={formik.errors.licensePlate} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.repairCostsLabel}>
            <StyledTextField
              variant="outlined"
              name="repairCost"
              value={formik.values.repairCost}
              placeholder={translation.repairCostsLabel}
              InputProps={{
                inputComponent:
                  NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2">{priceCurrency}</Typography>
                  </InputAdornment>
                ),
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.repairCost)}
              disabled={!formik.values.customPrice}
            />
            {formik.errors.repairCost ? (
              <StyledErrorMessage text={formik.errors.repairCost} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.listingPriceLabel}>
            <StyledTextField
              variant="outlined"
              value={calculateListingPrice(formik.values)}
              placeholder={translation.listingPriceLabel}
              InputProps={{
                inputComponent:
                  NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2">{priceCurrency}</Typography>
                  </InputAdornment>
                ),
              }}
              disabled
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.totalPriceLabel}>
            <StyledTextField
              variant="outlined"
              value={calculateTotalPrice(formik.values)}
              placeholder={translation.totalPriceLabel}
              InputProps={{
                inputComponent:
                  NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2">{priceCurrency}</Typography>
                  </InputAdornment>
                ),
              }}
              disabled
            />
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonContainer
        previousFunction={handleBack}
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default Price
