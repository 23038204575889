import React from 'react'
import { Box, Typography } from '@mui/material'
import { LinearProgressProps } from '@mui/material/LinearProgress'

import { CustomBarBuilder, typographyStyle } from './style'

type ProgressBarProps = {
  /**
   ** progress% state number
   */
  progress: number

  /**
   ** white secondary background state bool
   */
  isWhiteBackground?: boolean

  /**
   ** width is an optional prop for progressBar width.
   ** you can pass either a integer which parses to px
   ** or a string with the unit. ex: "20px" "20rem"
   */
  width?: number | string
} & LinearProgressProps

const ProgressBar = ({
  progress,
  width = '100%',
  isWhiteBackground = false,
}: ProgressBarProps) => {
  const CustomProgressBar = CustomBarBuilder(isWhiteBackground)
  const fixedProgress = parseFloat(progress.toFixed(2))

  return (
    <Box position="relative" width={width}>
      <CustomProgressBar variant="determinate" value={fixedProgress} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography sx={typographyStyle(fixedProgress)} variant="subtitle2">
          {fixedProgress}%
        </Typography>
      </Box>
    </Box>
  )
}

export default ProgressBar
