import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { SelectChangeEvent } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'

import ButtonContainer from 'components/CarSettings/Common/Creation/ButtonContainer'
import ErrorMessage from 'components/Common/ErrorMessage'
import Select from 'components/Common/Select'

import { ENTITY_CREATION_OPTION, getEntityTypes } from 'constants/blackList'
import {
  BLACK_LIST_STATUS,
  BlackListEntityKey,
} from 'constants/Operation/blackList'
import { BLACK_LIST_ROUTE } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { BlackEntity } from 'models/blackListCreation'
import { Option } from 'models/Select'
import { FilterInputVariable, GenericData } from 'models/services/base'
import { EntityCreationProps } from 'utils/CarSettings/BlackList/creation'

import {
  GET_BLACK_LIST_BRANDS,
  GET_BLACK_LIST_MODELS,
  GET_BLACK_LIST_TRIM_LEVELS,
} from 'graphQL/Operations/BlackList/Listing/queries'

import {
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const EntityInfo = ({
  handleContinue,
  entityData,
  updateEntityData,
}: EntityCreationProps) => {
  const { entityInformation } = entityData

  const [brands, setBrands] = useState<Option[]>([])
  const [models, setModels] = useState<Option[]>([])
  const [trims, setTrims] = useState<Option[]>([])

  const { entity } = useParams<{ entity: string }>()
  const history = useHistory()

  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { text: translation } = useTranslation(textFiles.BLACK_LIST_CREATION)
  const { information, typeLabels } = translation
  const {
    text: { filterByCriteria: generalText },
  } = useTranslation(textFiles.GENERAL)

  const types = getEntityTypes(typeLabels)

  const [getBrands, { loading: brandLoading, called: brandsCalled }] =
    useLazyQuery<GenericData<Option[]>, FilterInputVariable>(
      GET_BLACK_LIST_BRANDS,
      {
        variables: {
          input: {
            sort: {
              name: 'asc',
            },
            where: {
              blacklistStatus: BLACK_LIST_STATUS.INACTIVE,
            },
          },
        },
        onCompleted(response) {
          setBrands(response.data)
        },
      }
    )

  const [getModels, { loading: modelLoading, called: modelsCalled }] =
    useLazyQuery<GenericData<Option[]>, FilterInputVariable>(
      GET_BLACK_LIST_MODELS,
      {
        variables: {
          input: {
            sort: {
              name: 'asc',
            },
            where: {
              blacklistStatus: BLACK_LIST_STATUS.INACTIVE,
            },
          },
        },
        onCompleted(response) {
          setModels(response.data)
        },
      }
    )

  const [getTrims, { loading: trimLoading, called: trimsCalled }] =
    useLazyQuery<GenericData<Option[]>, FilterInputVariable>(
      GET_BLACK_LIST_TRIM_LEVELS,
      {
        variables: {
          input: {
            sort: {
              name: 'asc',
            },
            where: {
              blacklistStatus: BLACK_LIST_STATUS.INACTIVE,
            },
          },
        },
        onCompleted(response) {
          setTrims(response.data)
        },
      }
    )

  const fieldOptions = {
    make: brands,
    model: models,
    trim: trims,
  }

  const optionsLoading = brandLoading || modelLoading || trimLoading

  const validationSchema = yup.object({
    type: yup.string(),
    name: yup.string().required(validationText.fieldRequired),
    comments: yup.string(),
  })

  const formik = useFormik<Partial<BlackEntity>>({
    initialValues: {
      type: entityInformation.type || entity,
      name: entityInformation.name,
      comments: entityInformation.comments,
    },
    validationSchema,
    onSubmit: async (values) => {
      updateEntityData({
        type: ENTITY_CREATION_OPTION.UPDATE_ENTITY_INFORMATION,
        payload: {
          type: values.type as string,
          name: values.name as string,
          comments: values.comments as string,
          options: fieldOptions[values.type as BlackListEntityKey],
        },
      })

      handleContinue()
    },
  })

  const handleSelect = (event: SelectChangeEvent<unknown>, map: string) => {
    const {
      target: { value },
    } = event
    if (map === 'type') {
      formik.setFieldValue('name', '')
      formik.setFieldTouched('name', false)
      history.push(
        `${BLACK_LIST_ROUTE}/creation/${value as BlackListEntityKey}`
      )
    }
    formik.setFieldValue(map, value as string)
    formik.setFieldTouched(map, false)
  }

  useEffect(() => {
    switch (formik.values.type as BlackListEntityKey) {
      case 'make':
        if (!brandsCalled) {
          getBrands()
        }
        break
      case 'model':
        if (!modelsCalled) {
          getModels()
        }
        break
      case 'trim':
        if (!trimsCalled) {
          getTrims()
        }
        break
      default:
        break
    }
  }, [
    brandsCalled,
    formik.values.type,
    getBrands,
    getModels,
    getTrims,
    modelsCalled,
    trimsCalled,
  ])

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <StyledTextFieldContainer
            title={information.type}
            description={information.typeDescription}
            contentStyle={{
              flexDirection: 'column',
            }}
          >
            <Select
              options={types}
              variant="outlined"
              name="type"
              value={formik.values.type}
              label={information.type}
              onChange={(e) => handleSelect(e, 'type')}
              sx={{ fontSize: 12, height: 40 }}
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={information.name}
            description={information.nameDescription}
            contentStyle={{
              flexDirection: 'column',
            }}
          >
            <Select
              options={fieldOptions[formik.values.type as BlackListEntityKey]}
              variant="outlined"
              name="name"
              value={formik.values.name}
              label={optionsLoading ? generalText.loading : information.name}
              onChange={(e) => handleSelect(e, 'name')}
              sx={{ fontSize: 12, height: 40 }}
            />
            {formik.touched.name && formik.errors.name ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.name}
              />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={information.comments}
            description={information.commentsDescription}
          >
            <StyledTextField
              variant="outlined"
              name="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              sx={{ height: 113 }}
              rows={5}
              multiline
            />
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonContainer
        isFirstStep
        confirmButtonType="submit"
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default EntityInfo
