import { Checkpoint, QuestionListType } from 'models/services/template/detail'

export const defaultQuestion: QuestionListType = {
  answers: [],
  id: '',
  isPictureRequired: false,
  isRequired: true,
  label: '',
  value: 10,
  isNewQuestion: true,
}

export const defaultCheckpoint: Checkpoint = {
  description: '',
  id: '',
  label: '',
  failureReason: {
    id: '',
    name: '',
  },
  questions: [
    {
      ...defaultQuestion,
    },
  ],
  isNewCheckpoint: true,
}
