import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import InputAdornment from '@mui/material/InputAdornment'

import Stripe from 'components/Answers/Creation/Stripe'

import {
  DUPLICATE_KEY_ERROR,
  REJECTED_DELETE,
  REJECTED_UPDATE,
} from 'constants/error'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import { Answer, AnswerCreationInput } from 'models/services/answers'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { UpdateGenericInput } from 'models/services/CarSettings/detail'
import { AnswerCreationProps } from 'utils/Answers'
import { validateGraphQLErrorCode } from 'utils/error'

import {
  CREATE_ANSWER,
  DELETE_ANSWER,
  UPDATE_ANSWER,
} from 'graphQL/Answers/mutations'
import { GET_ANSWERS } from 'graphQL/Answers/queries'

import { colors } from 'styles/theme'

import {
  AddSection,
  ContentBox,
  LabelStripe,
  StripeContainer,
  StyledButton,
  StyledContainer,
  StyledForm,
  StyledTextField,
} from './style'

const AnswersCreation = ({ answers }: AnswerCreationProps) => {
  const [editMode, setEditMode] = useState<boolean>(false)
  const [answersArr, setAnswersArr] = useState<Answer[]>(answers)

  const [singleAnswer, setSingleAnswer] = useState<Answer>({
    id: '',
    name: '',
    doesntApply: false,
    correctAnswer: false,
  })
  const { text: translation } = useTranslation(textFiles.GENERAL)
  const { answers: answerText } = translation
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { show } = useNotification()

  const [createAnswer] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<AnswerCreationInput>
  >(CREATE_ANSWER, {
    onCompleted(response) {
      show({
        updatedSeverity: 'success',
        message: answerText.createSuccess,
      })
      setAnswersArr((prevState) => {
        const newAnswers = [...prevState]
        newAnswers.push({
          id: response.data.id as string,
          name: singleAnswer.name,
          doesntApply: singleAnswer.doesntApply,
          correctAnswer: singleAnswer.correctAnswer,
        })
        return newAnswers
      })
      setSingleAnswer({
        id: '',
        name: '',
        doesntApply: false,
        correctAnswer: false,
      })
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
          message: answerText.createFail,
        })
    },
    refetchQueries: [GET_ANSWERS],
  })

  const [updateAnswer] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<UpdateGenericInput<AnswerCreationInput>>
  >(UPDATE_ANSWER, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: answerText.updateSuccess,
      })
    },
    onError(error) {
      const { errorExists, message } = validateGraphQLErrorCode(
        error,
        REJECTED_UPDATE
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message,
        })
      } else
        show({
          updatedSeverity: 'error',
          message: answerText.updateFail,
        })
    },
    refetchQueries: [GET_ANSWERS],
  })

  const [deleteAnswer] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<string>
  >(DELETE_ANSWER, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: answerText.deleteSuccess,
      })
    },
    onError(error) {
      const { errorExists, message } = validateGraphQLErrorCode(
        error,
        REJECTED_DELETE
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message,
        })
      } else
        show({
          updatedSeverity: 'error',
          message: answerText.deleteFail,
        })
    },
    refetchQueries: [GET_ANSWERS],
  })

  const handleAdding = () => {
    if (singleAnswer.name !== '') {
      createAnswer({
        variables: {
          input: {
            label: singleAnswer.name,
            doesntApply: singleAnswer.doesntApply,
            correctAnswer: singleAnswer.correctAnswer,
          },
        },
      })
    }
  }

  const handleEditItem = async (answer: Answer) => {
    const { id, name, correctAnswer, doesntApply } = answer
    try {
      const response = await updateAnswer({
        variables: {
          input: {
            where: {
              id: id as string,
            },
            data: {
              label: name,
              correctAnswer,
              doesntApply,
            },
          },
        },
      })
      if (response && response.data) {
        setAnswersArr((prevState) => {
          const newAnswers = prevState.map((ans) => {
            if (ans.id === id) {
              return { ...ans, name, correctAnswer, doesntApply }
            }
            return ans
          })
          return newAnswers
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleConfirmRemove = async (id: string | number) => {
    try {
      const response = await deleteAnswer({
        variables: {
          input: id as string,
        },
      })
      if (response && response.data) {
        setAnswersArr((prevState) => {
          const newAnswers = prevState.filter((ans) => ans.id !== id)
          return newAnswers
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <StyledContainer>
      <StyledForm>
        <ContentBox>
          <StripeContainer>
            {answersArr.length !== 0 ? (
              <LabelStripe>
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: '10px',
                  }}
                >
                  {answerText.correctLabel}
                </span>
                <span>{answerText.applyLabel}</span>
              </LabelStripe>
            ) : null}
            {answersArr.map((answerItem) => (
              <Stripe
                key={answerItem.id}
                answer={answerItem}
                handleRemove={handleConfirmRemove}
                handleEditItem={handleEditItem}
                handleEditMode={setEditMode}
              />
            ))}
          </StripeContainer>
          <AddSection>
            <StyledTextField
              variant="outlined"
              name="name"
              placeholder={answerText.placeholder}
              value={singleAnswer.name}
              sx={{
                width: '510px !important',
                input: {
                  paddingLeft: '0px',
                },
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSingleAnswer({ ...singleAnswer, name: e.target.value })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PlaylistAddIcon
                      sx={{ color: colors.placeholderGray, fontSize: 18 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <StyledButton
              startIcon={<AddIcon />}
              size="small"
              role="button"
              variant="text"
              disabled={editMode}
              onClick={handleAdding}
              sx={{
                border: `2px solid ${
                  editMode ? 'unset' : colors.blue
                } !important`,
                backgroundColor: editMode ? colors.lightGray : 'unset',
              }}
            >
              {answerText.addButton}
            </StyledButton>
          </AddSection>
        </ContentBox>
      </StyledForm>
    </StyledContainer>
  )
}

export default AnswersCreation
