import React, { useState } from 'react'
import { Card } from '@mui/material'
import Popper from '@mui/material/Popper'

import Box from 'components/Common/Box'

type Props = {
  children: JSX.Element | string
  customPopper?: React.ReactNode
}

const Tooltip = ({ children, customPopper }: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }
  const maintainPopover = () => {
    setAnchorEl(anchorEl)
    setOpen(true)
  }
  return (
    <>
      <Box
        width="98%"
        height="100%"
        onMouseEnter={(e) => {
          handleOpen(e)
        }}
        onMouseLeave={handleClose}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </Box>
      <Popper
        id="info-popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        onMouseEnter={maintainPopover}
        onMouseLeave={handleClose}
      >
        {customPopper || (
          <Card
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              height: '45px',
              padding: '0 15px',
              fontSize: '14px',
            }}
          >
            {children}
          </Card>
        )}
      </Popper>
    </>
  )
}

export default Tooltip
