import { BRAND_CREATION_OPTION } from 'constants/brand'
import { BrandCreationAction, BrandCreationModel } from 'models/brandCreation'

const brandCreationReducer = (
  state: BrandCreationModel,
  action: BrandCreationAction
): BrandCreationModel => {
  switch (action.type) {
    case BRAND_CREATION_OPTION.UPDATE_BRAND_INFORMATION:
      return { ...state, brandInformation: action.payload }

    default:
      return state
  }
}

export default brandCreationReducer
