import React, { useState } from 'react'

import { textFiles } from 'constants/textFiles'
import { MEASUREMENT_OPTION, TRIM_CREATION_OPTION } from 'constants/trim'
import useTranslation from 'hooks/useTranslation'
import { MeasurementInformationModel } from 'models/trimCreation'
import { TrimCreationProps } from 'utils/CarSettings/Trim/creation'

import Skeleton, { ExtendedSliderProps } from '../Common/Skeleton'

const Measurement = ({
  handleBack,
  handleContinue,
  trimData,
  updateTrimData,
}: TrimCreationProps) => {
  const { measurementInformation } = trimData
  const [info, setInfo] = useState<MeasurementInformationModel>(
    measurementInformation
  )
  const { text: translation } = useTranslation(textFiles.TRIM_CREATION)

  const { measurement } = translation

  const handleBlur = (map: MEASUREMENT_OPTION) => {
    if (info[map] > 10) {
      setInfo({ ...info, [map]: 10 })
    }
    if (info[map] < 1) {
      setInfo({ ...info, [map]: 1 })
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    map: MEASUREMENT_OPTION
  ) => {
    setInfo({
      ...info,
      [map]: Number(event.target.value),
    })
  }

  const handleSubmit = () => {
    updateTrimData({
      type: TRIM_CREATION_OPTION.UPDATE_MEASUREMENT_INFORMATION,
      payload: {
        length: info.length,
        width: info.width,
        height: info.height,
        seats: info.seats,
        doors: info.doors,
      },
    })
    handleContinue()
  }

  const SLIDER_ITEMS: ExtendedSliderProps[] = [
    {
      id: 1,
      title: measurement.length,
      name: MEASUREMENT_OPTION.LENGTH,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, length: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, MEASUREMENT_OPTION.LENGTH),
      handleBlur: () => handleBlur(MEASUREMENT_OPTION.LENGTH),
      max: 10,
    },
    {
      id: 2,
      title: measurement.width,
      name: MEASUREMENT_OPTION.WIDTH,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, width: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, MEASUREMENT_OPTION.WIDTH),
      handleBlur: () => handleBlur(MEASUREMENT_OPTION.WIDTH),
      max: 10,
    },
    {
      id: 3,
      title: measurement.height,
      name: MEASUREMENT_OPTION.HEIGHT,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, height: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, MEASUREMENT_OPTION.HEIGHT),
      handleBlur: () => handleBlur(MEASUREMENT_OPTION.HEIGHT),
      max: 10,
    },
    {
      id: 4,
      title: measurement.seats,
      name: MEASUREMENT_OPTION.SEATS,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, seats: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, MEASUREMENT_OPTION.SEATS),
      handleBlur: () => handleBlur(MEASUREMENT_OPTION.SEATS),
      max: 15,
    },
    {
      id: 5,
      title: measurement.doors,
      name: MEASUREMENT_OPTION.DOORS,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, doors: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, MEASUREMENT_OPTION.DOORS),
      handleBlur: () => handleBlur(MEASUREMENT_OPTION.DOORS),
      max: 10,
    },
  ]

  return (
    <Skeleton
      sliderItems={SLIDER_ITEMS}
      handleBack={handleBack}
      handleNext={handleSubmit}
    />
  )
}

export default Measurement
