import { CATEGORY_CREATION_OPTION } from 'constants/category'
import {
  CategoryCreationAction,
  CategoryCreationModel,
} from 'models/categoryCreation'

const categoryCreationReducer = (
  state: CategoryCreationModel,
  action: CategoryCreationAction
): CategoryCreationModel => {
  switch (action.type) {
    case CATEGORY_CREATION_OPTION.UPDATE_CATEGORY_INFORMATION:
      return { ...state, categoryInformation: action.payload }

    default:
      return state
  }
}

export default categoryCreationReducer
