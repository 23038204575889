import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import ButtonContainer from 'components/CarSettings/Common/Creation/ButtonContainer'
import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'
import { TranslationContainer } from 'components/CarSettings/style'
import ErrorMessage from 'components/Common/ErrorMessage'
import Translation from 'components/General/Translation'
import { TranslationItemType } from 'components/General/Translation/TranslationListItem'

import { textFiles } from 'constants/textFiles'
import { TRANSMISSION_CREATION_OPTION } from 'constants/transmission'
import useTranslation from 'hooks/useTranslation'
import { Transmission } from 'models/car'
import { PublicationStatus } from 'models/status'
import { TranslationChange } from 'models/translations'
import { sleep } from 'utils/BasicUtil'
import { TransmissionCreationProps } from 'utils/CarSettings/Transmission/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const TransmissionInfo = ({
  handleContinue,
  transmissionData,
  updateTransmissionData,
}: TransmissionCreationProps) => {
  const { transmissionInformation } = transmissionData
  const [transmissionStatus, setTransmissionStatus] = useState<boolean>(
    transmissionInformation.status === PublicationStatus.PUBLISHED
  )
  const [stepInfo] = useState<Transmission>(transmissionInformation)
  const [addedTranslations, setAddedTranslations] = useState<
    TranslationItemType[]
  >(transmissionInformation.translations)

  const { text: translation } = useTranslation(textFiles.TRANSMISSION_CREATION)
  const { information } = translation

  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const handleChangeTranslation = (
    translations: TranslationItemType[],
    type: TranslationChange
  ) => {
    sessionStorage.setItem('type', type)
    setAddedTranslations(translations)
  }

  const validationSchema = yup.object({
    name: yup.string().required(validationText.fieldRequired),
  })

  const formik = useFormik<Partial<Transmission>>({
    initialValues: {
      name: transmissionInformation.name,
    },
    validationSchema,
    onSubmit: async (values) => {
      await sleep(1000)
      updateTransmissionData({
        type: TRANSMISSION_CREATION_OPTION.UPDATE_TRANSMISSION_INFORMATION,
        payload: {
          ...stepInfo,
          name: values.name as string,
          translations: addedTranslations,
          status: transmissionStatus
            ? PublicationStatus.PUBLISHED
            : PublicationStatus.UNPUBLISHED,
        },
      })

      handleContinue()
    },
  })

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <ToggleContainer
            title={information.isPublished}
            description={information.isPublishedDescription}
            status={transmissionStatus}
            handleStatus={setTransmissionStatus}
            testId="published"
          />
          <StyledTextFieldContainer
            title={information.name}
            description={information.nameDescription}
          >
            <StyledTextField
              variant="outlined"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder={information.placeholder}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
            {formik.touched.name && formik.errors.name ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.name}
              />
            ) : null}
          </StyledTextFieldContainer>
          <TranslationContainer>
            <Translation
              addedTranslations={addedTranslations}
              handleChangeTranslation={handleChangeTranslation}
              translationDescription={information.translationsDescription}
            />
          </TranslationContainer>
        </div>
      </StyledForm>
      <ButtonContainer
        isFirstStep
        confirmButtonType="submit"
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default TransmissionInfo
