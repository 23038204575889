import React from 'react'

import { InspectorDataType } from 'components/Inspector/Detail/CommissionsDetail'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { HighlightedSpan, StyledBox, StyledCardBackground } from './style'

type CarsInspectedCarProps = {
  inspectorData: InspectorDataType
}

const CarsInspectedCard = ({ inspectorData }: CarsInspectedCarProps) => {
  const { name, carsInspected, ranking } = inspectorData
  const { text } = useTranslation(textFiles.INSPECTOR_DETAIL)
  const { toThisDay, hasUploaded, carsToPlatform, position, spotInRanking } =
    text.commissions.carsInspectedCard

  return (
    <StyledBox>
      <StyledCardBackground>
        {toThisDay} <HighlightedSpan>{name}</HighlightedSpan> {hasUploaded}{' '}
        <HighlightedSpan>{carsInspected}</HighlightedSpan> {carsToPlatform}{' '}
        <HighlightedSpan>
          {ranking}
          {position}
        </HighlightedSpan>{' '}
        {spotInRanking}
      </StyledCardBackground>
    </StyledBox>
  )
}

export default CarsInspectedCard
