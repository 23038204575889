import { Card, styled } from '@mui/material'

import { boxShadow } from 'styles/theme'

export const StyledCard = styled(Card)({
  borderRadius: '6px',
  width: '100%',
  height: '342px',
  boxShadow,
  cursor: 'pointer',
  transition: 'all',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'ease-in-out',
  ':hover': { transform: 'scale(1.05)' },
})
