import { GridCellValue } from '@mui/x-data-grid'

import { InspectorColumnField } from 'constants/inspector'
import { routes } from 'constants/routes'
import { SelectItem } from 'models/Select'
import { FieldSelectTextType } from 'models/text/InspectorListing'

export const createFieldSelectItems = (
  text: FieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.picture,
      value: InspectorColumnField.PICTURE,
    },
    {
      label: text.id,
      value: InspectorColumnField.ID,
    },
    {
      label: text.name,
      value: InspectorColumnField.INSPECTOR_NAME,
    },
    {
      label: text.curboSpot,
      value: InspectorColumnField.CURBO_SPOT,
    },
    {
      label: text.province,
      value: InspectorColumnField.PROVINCE,
    },
    {
      label: text.createdAt,
      value: InspectorColumnField.CREATED_AT,
    },
    {
      label: text.status,
      value: InspectorColumnField.STATUS,
    },
    {
      label: text.actions,
      value: InspectorColumnField.ACTIONS,
    },
  ]
}

export const createFieldSelectLabels = (
  text: FieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    picture: text.picture,
    name: text.name,
    curboSpot: text.curboSpot,
    createdAt: text.createdAt,
    status: text.status,
    actions: text.actions,
    province: text.province,
  }
}

export const defaultFields = [
  InspectorColumnField.PICTURE,
  InspectorColumnField.ID,
  InspectorColumnField.INSPECTOR_NAME,
  InspectorColumnField.CURBO_SPOT,
  InspectorColumnField.PROVINCE,
  InspectorColumnField.CREATED_AT,
  InspectorColumnField.STATUS,
  InspectorColumnField.ACTIONS,
]

export const buildInspectionRoute = (id: GridCellValue) => {
  const route = routes.INSPECTION_DETAIL
  return `${route.substring(0, route.indexOf(':'))}${id}`
}
