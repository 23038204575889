import React from 'react'

import Box from 'components/Common/Box'

import useUser from 'hooks/useUser'
import { UserRoles } from 'models/role'
import { grantPermissions } from 'utils/BasicUtil'

import ModuleCard from './ModuleCard'

export type PointerType = {
  id: number
  pointer: string
}

export type ModuleInfoType = {
  id: number
  name: string
  icon: JSX.Element
  subtitle: string
  pointers: PointerType[]
  route: string
  allowedRoles: UserRoles[]
  hideInProduction?: boolean
}

export type GeneralViewProps = {
  modules: ModuleInfoType[]
}

export const GeneralViewContainer = ({ modules }: GeneralViewProps) => {
  const { userRoles } = useUser()

  const allowedModules = modules.filter((module) =>
    grantPermissions(userRoles, module.allowedRoles, module.hideInProduction)
  )

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      gap="24px 21px"
      flexWrap="wrap"
    >
      {allowedModules.map((allowedModule) => (
        <ModuleCard key={allowedModule.id} moduleInfo={allowedModule} />
      ))}
    </Box>
  )
}
