import { gql } from '@apollo/client'

export const UPDATE_BODY_STYLE = gql`
  mutation updateBodyStyle($input: UpdateBodyStyleInput!) {
    data: updateBodyStyle(input: $input) {
      id
      name
    }
  }
`
