import { gql } from '@apollo/client'

export const UPDATE_INSPECTOR = gql`
  mutation updateInspectorData($input: UpdateInspectorInput!) {
    data: updateInspector(input: $input) {
      address
      email
      id
      lastName
      name
      profilePicture
      telephoneNumber
      profilePicture
      curboSpot {
        id
        name
        slug
      }
    }
  }
`

export const CREATE_ABSENCE = gql`
  mutation generateAbsence($input: CreateAbsenceInput!) {
    createAbsence(input: $input) {
      id
    }
  }
`
export const END_ABSENCE = gql`
  mutation endAbsence($input: ID!) {
    endAbsence(id: $input) {
      id
    }
  }
`

export const UPDATE_ABSENCE = gql`
  mutation updateAbsence($input: UpdateAbsenceInput!) {
    updateAbsence(input: $input) {
      id
    }
  }
`
