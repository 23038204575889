import React from 'react'
import { Typography } from '@mui/material'

import { InspectorStatsBox, StyledBoxContainer } from './style'
import { StatisticsTypeProps } from '.'

const InspectionsTotal = ({ value, color, label }: StatisticsTypeProps) => (
  <StyledBoxContainer>
    <InspectorStatsBox
      alignItems="center"
      display="flex"
      justifyContent="center"
      width="80%"
      paddingTop="0px"
    >
      <Typography textAlign="center" variant="h1" color={color} fontSize="54px">
        {value}
      </Typography>

      {label && (
        <Typography variant="body2" marginTop="-5px" marginBottom="0px">
          {label}
        </Typography>
      )}
    </InspectorStatsBox>
  </StyledBoxContainer>
)

export default InspectionsTotal
