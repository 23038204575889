import { gql } from '@apollo/client'

export const UPDATE_CURBO_SPOT = gql`
  mutation updateCurboSpot($input: UpdateCurboSpotInput!) {
    data: updateCurboSpot(input: $input) {
      id
    }
  }
`

export const CREATE_TEST_DRIVE_WEEK_CALENDAR = gql`
  mutation createTestDriveWeekCalendar(
    $input: CreateTestDriveWeekCalendarInput!
  ) {
    data: createTestDriveWeekCalendar(input: $input) {
      id
    }
  }
`

export const CREATE_INSPECTIONS_WEEK_CALENDAR = gql`
  mutation createInspectionWeekCalendar(
    $input: CreateInspectionWeekCalendarInput!
  ) {
    data: createInspectionWeekCalendar(input: $input) {
      id
    }
  }
`
