export const DUPLICATE_KEY_ERROR = 'DUPLICATE_KEY'
export const DUPLICATE_EMAIL_ERROR = 'DUPLICATE_EMAIL'
export const ENTITY_NOT_FOUND_ERROR = 'ENTITY_NOT_FOUND'
export const REJECTED_DELETE = 'REJECTED_DELETE'
export const REJECTED_UPDATE = 'REJECTED_UPDATE'
export const TOKEN_ERRORS = {
  EXPIRED_TOKEN: 'EXPIRED_TOKEN',
  MALFORMED_TOKEN: 'MALFORMED_TOKEN',
  UNAUTHORIZED_ROLE: 'UNAUTHORIZED_ROLE',
}
export const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS'
