import { styled } from '@mui/material'

import { colors } from 'styles/theme'

export const StyledDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.commonWhite,
  minHeight: '150px',
  height: 'auto',
  padding: '0.25em',
  width: '426px',

  '> div': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '85%',
    width: '90%',
    'p:first-of-type': {
      marginBottom: '0.5em',
    },
  },

  section: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontSize: '12px',
    span: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '5px',
      width: '100%',
      height: '35px',
      color: colors.gray,
      p: {
        width: '70%',
        display: 'flex',
        margin: '0',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
    },
  },
})
