import React from 'react'
import {
  Select,
  SelectChangeEvent,
  selectClasses,
  Typography,
} from '@mui/material'

import Box from 'components/Common/Box'

import { SelectItem } from 'models/Select'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { StyledMenuItem } from './style'

export type RowSelectProps = {
  /**
   * Rows per page
   */
  pageSize: number
  /**
   * Array of items being used for the select
   */
  selectItems: SelectItem[]
  /**
   ** Function to manage the change of the rowsPerPage
   */
  onSelectChange: (event: SelectChangeEvent<number>) => void
  /**
   ** Text to display next to select row size select
   */
  rowLabelText: string
}

export const RowFooterSelect = ({
  onSelectChange,
  pageSize,
  selectItems,
  rowLabelText,
}: RowSelectProps) => (
  <Box display="flex" alignItems="center">
    <Select
      onChange={onSelectChange}
      value={pageSize}
      sx={{
        backgroundColor: colors.background,
        boxShadow: 1,
        '> fieldset': {
          border: 'none',
        },
        [`& .${selectClasses.select}`]: {
          color: colors.black,
          fontSize: '14px',
          fontWeight: FONT_WEIGHT.REGULAR,
          padding: '4px 32px 4px 8px',
        },
      }}
      data-cy="page-size-select"
    >
      {selectItems.map((item) => (
        <StyledMenuItem
          key={item.value}
          value={item.value}
          data-cy={`page-size-${item.value}-select-item`}
        >
          {item.label}
        </StyledMenuItem>
      ))}
    </Select>
    <Typography
      color={colors.lightBrown}
      marginLeft="15px"
      variant="caption"
      fontSize="14px"
      fontWeight={FONT_WEIGHT.MEDIUM}
    >
      {rowLabelText}
    </Typography>
  </Box>
)
