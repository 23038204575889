import { gql } from '@apollo/client'

export const DELETE_INSPECTION = gql`
  mutation deleteInspection($input: ID!) {
    response: deleteInspection(id: $input) {
      id
    }
  }
`

export const DELETE_INSPECTIONS = gql`
  mutation deleteInspections($input: [ID!]!) {
    response: deleteInspections(ids: $input) {
      id
    }
  }
`
