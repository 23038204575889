import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useLazyQuery, useQuery } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { SelectChangeEvent, Typography } from '@mui/material'
import {
  GridCellValue,
  GridColDef,
  GridRowId,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid'

import CarSettingDateRangeFilter from 'components/CarSettings/Common/CarSettingDateRangeFilter'
import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import Table from 'components/Common/Table'
import Title from 'components/Common/Title'
import { Filter } from 'components/Inspection/Dashboard/FilterCard'
import FieldFilter from 'components/Inspection/Listing/FieldFilter'
import FilterByCriteria from 'components/Listing/FilterByCriteria'

import {
  CarSettingsKey,
  carSettingsListingFields,
  createCarSettingsColumns,
  trimStaticFields,
} from 'constants/CarSettings/listing'
import { cypressAddButton, cypressBackLink } from 'constants/cypress'
import { FilteringOption } from 'constants/Inspection/filterByCriteria'
import { CAR_SETTINGS_ROUTE, CAR_SETTINGS_SUB_ROUTES } from 'constants/routes'
import { StatusStyles } from 'constants/status'
import { defaultStatusSortModel, selectItems } from 'constants/table'
import { textFiles } from 'constants/textFiles'
import { UrlParamNames } from 'constants/urlQuery'
import useLocale from 'hooks/useLocale'
import useQueryState from 'hooks/useQueryState'
import useTranslation from 'hooks/useTranslation'
import { CalendarRangeType, DateRangeCalendarTabType } from 'models/date'
import {
  FilterInputVariable,
  GenericData,
  ListingFilterType,
} from 'models/services/base'
import { ListSettingType } from 'models/services/CarSettings/listing'
import { ExtendedStatus } from 'models/services/status'
import { PublicationStatus, PublicationStatusMap } from 'models/status'
import { getIsoDate } from 'utils/date'
import { cleanFilters, generateFilterInput } from 'utils/filters'
import { buildDetailRoute } from 'utils/routes'
import {
  serializeFields,
  serializeFilters,
  serializePage,
  serializePageSize,
  serializeRange,
  serializeSortModel,
} from 'utils/serializers'
import { verifyParam } from 'utils/verifyUrlData'

import {
  GET_BODY_STYLES,
  GET_FEATURES,
  GET_MODELS,
} from 'graphQL/Operations/Trim/Detail/queries'

import { StyledLink } from 'styles/inspection/listing'
import { colors } from 'styles/theme'

type CarSettingsListingPageProps = {
  route: CarSettingsKey
}

const CarSettingsListingPage = ({ route }: CarSettingsListingPageProps) => {
  const {
    createFieldSelectItems,
    createFieldSelectLabels,
    selectFields,
    textFile,
    query,
  } = carSettingsListingFields[route]
  const detailRoute = `${CAR_SETTINGS_ROUTE}/${route}/detail/:carSettingsId`
  const location = useLocation()
  const { search } = location

  const defaultSortedFields = [...selectFields].sort()

  const [selectedFields, setSelectedFields] = useQueryState<string[]>(
    UrlParamNames.FIELDS,
    (verifyParam(UrlParamNames.FIELDS, search) as string[]) ||
      defaultSortedFields,
    serializeFields,
    defaultSortedFields
  )

  const [filtersList, setFiltersList] = useQueryState<Filter[]>(
    UrlParamNames.FILTERS,
    (verifyParam(UrlParamNames.FILTERS, search) as Filter[]) || [],
    serializeFilters
  )

  const [dateRange, setDateRange] = useQueryState<CalendarRangeType>(
    UrlParamNames.DATE,
    (verifyParam(UrlParamNames.DATE, search) as CalendarRangeType) || [],
    serializeRange
  )

  const [pageSize, setPageSize] = useQueryState<number>(
    UrlParamNames.LIMIT,
    (verifyParam(UrlParamNames.LIMIT, search) as number) || 10,
    serializePageSize,
    10
  )
  const [page, setPage] = useQueryState<number>(
    UrlParamNames.PAGE,
    (verifyParam(UrlParamNames.PAGE, search) as number) || 1,
    serializePage
  )

  const [sortModel, setSortModel] = useQueryState<GridSortModel>(
    UrlParamNames.SORT,
    (verifyParam(UrlParamNames.SORT, search) as GridSortModel) ||
      defaultStatusSortModel,
    serializeSortModel,
    defaultStatusSortModel
  )

  const [calendarTabValue, setCalendarTabValue] = useState<
    DateRangeCalendarTabType | boolean
  >(DateRangeCalendarTabType.ALL)
  const [filterInput, setFiltersInput] = useState<ListingFilterType>(
    generateFilterInput(filtersList)
  )

  const [selectedLanguage] = useLocale()
  const history = useHistory()

  const [deleteItem, setDeleteItem] = useState<GridRowId | null>(null)
  const { text } = useTranslation(textFile)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const [data, setData] = useState<GridRowsProp>([])
  const fieldSelectItems = createFieldSelectItems(text.fieldSelect)
  const fieldSelectLabels = createFieldSelectLabels(text.fieldSelect)
  const [listCount, setListCount] = useState<number>(0)
  const [featuresList, setFeaturesList] = useState<FilteringOption[]>([])
  const [modelsList, setModelsList] = useState<FilteringOption[]>([])
  const [bodyStyleList, setBodyStyleList] = useState<FilteringOption[]>([])

  const statusList: ExtendedStatus[] = useMemo(
    () => [
      {
        backgroundColor:
          StatusStyles[PublicationStatus.PUBLISHED].backgroundColor,
        textColor: StatusStyles[PublicationStatus.PUBLISHED].color,
        id: PublicationStatus.PUBLISHED,
        slug: PublicationStatus.PUBLISHED,
        description: generalText.status.carSettingPublishedDescription,
        name: generalText.status[PublicationStatus.PUBLISHED],
      },
      {
        backgroundColor:
          StatusStyles[PublicationStatus.UNPUBLISHED].backgroundColor,
        textColor: StatusStyles[PublicationStatus.UNPUBLISHED].color,
        id: PublicationStatus.UNPUBLISHED,
        slug: PublicationStatus.UNPUBLISHED,
        description: generalText.status.carSettingUnpublishedDescription,
        name: generalText.status[PublicationStatus.UNPUBLISHED],
      },
    ],
    [generalText]
  )

  const { fromDate, toDate } = useMemo(() => {
    return {
      fromDate: getIsoDate(dateRange.fromDate),
      toDate: getIsoDate(dateRange.toDate),
    }
  }, [dateRange])

  const { loading } = useQuery<ListSettingType, FilterInputVariable>(query, {
    onCompleted(response) {
      const { count, data: currentData } = response.listData
      setListCount(count)
      setData(currentData)
    },
    variables: {
      input: {
        limit: pageSize,
        start: (page - 1) * pageSize,
        where: {
          createdAt_gte: fromDate,
          createdAt_lte: toDate,
          ...cleanFilters(filterInput),
        },
        sort:
          sortModel.length > 0 && sortModel[0].sort
            ? {
                [sortModel[0].field]: sortModel[0].sort,
              }
            : undefined,
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  // const deleteCarSetting = React.useCallback((id: GridRowId) => {
  //   setDeleteItem(id)
  // }, [])

  const editCarSetting = React.useCallback(
    (id: GridCellValue) => {
      history.push(buildDetailRoute(id, detailRoute))
    },
    [history, detailRoute]
  )

  const createCarSetting = () => {
    history.push(`${CAR_SETTINGS_ROUTE}/${route}/creation`)
  }

  const [columns, setColumns] = useState<GridColDef[]>([])

  const handleFiltersList = (
    newFiltersList: Filter[],
    newFilterInput: ListingFilterType
  ) => {
    setFiltersList(newFiltersList)
    setFiltersInput(newFilterInput)
    setPage(1)
  }

  const handleSelectItem = (valueInput: string) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        return column.field === valueInput
          ? { ...column, hide: !column.hide }
          : column
      })
    )
    setSelectedFields((prevFields) => {
      if (prevFields.includes(valueInput)) {
        return prevFields.filter((field) => field !== valueInput)
      }
      return [...prevFields, valueInput]
    })
  }

  const handleResetDefault = () => {
    setSelectedFields(selectFields)
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = selectFields.some(
          (selectField) => column.field === selectField
        )
        return { ...column, hide: !fieldExist }
      })
    )
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangePageSize = (event: SelectChangeEvent<number>) => {
    setPageSize(event.target.value as number)
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
  }

  const getPageCount = () => {
    return Math.ceil(listCount / pageSize)
  }

  const handleChangeDateRange = (newDateRange: CalendarRangeType) => {
    setDateRange(newDateRange)
    setPage(1)
  }

  const handleCalendarTabChange = (newValue: DateRangeCalendarTabType) => {
    setCalendarTabValue(newValue)
  }

  useEffect(() => {
    setColumns(
      createCarSettingsColumns(
        route,
        PublicationStatusMap,
        selectedLanguage,
        generalText,
        text.table,
        editCarSetting,
        statusList,
        selectedFields
      )
    )
  }, [
    editCarSetting,
    generalText,
    route,
    selectedFields,
    selectedLanguage,
    statusList,
    text.table,
  ])

  const [getFeatures, { loading: featuresLoading, called: featuresCalled }] =
    useLazyQuery<GenericData<FilteringOption[]>>(GET_FEATURES, {
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
        },
      },
      onCompleted(response) {
        if (response.data) {
          setFeaturesList(response.data)
        }
      },
    })

  const [
    getBodyStyles,
    { loading: bodyStylesLoading, called: bodyStylesCalled },
  ] = useLazyQuery<GenericData<FilteringOption[]>>(GET_BODY_STYLES, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      if (response.data) {
        setBodyStyleList(response.data)
      }
    },
  })

  const [getModels, { loading: modelsLoading, called: modelsCalled }] =
    useLazyQuery<GenericData<FilteringOption[]>>(GET_MODELS, {
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
        },
      },
      onCompleted(response) {
        if (response.data) {
          setModelsList(response.data)
        }
      },
    })

  const handleFieldEntrySelect = (field: string) => {
    switch (field) {
      case 'carModel':
        if (!modelsCalled) {
          getModels()
        }
        break
      case 'bodyStyle':
        if (!bodyStylesCalled) {
          getBodyStyles()
        }
        break
      case 'features':
        if (!featuresCalled) {
          getFeatures()
        }
        break
      default:
        break
    }
  }

  const filtersLoading = modelsLoading || bodyStylesLoading || featuresLoading

  return (
    <Box width="100%">
      <StyledLink
        testId={cypressBackLink}
        to={CAR_SETTINGS_SUB_ROUTES.CAR_SETTINGS_MENU}
      >
        <Typography
          variant="h3"
          color={colors.blue}
        >{`< ${generalText.buttons.back}`}</Typography>
      </StyledLink>
      <Title
        icon={<PlaylistAddCheckIcon />}
        subtitle={`${listCount} ${text.description}`}
        title={text.title}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        marginTop="2rem"
        width="100%"
      >
        <Button
          startIcon={<AddIcon />}
          size="medium"
          onClick={createCarSetting}
          data-cy={cypressAddButton}
        >
          {text.addButton}
        </Button>

        <Box display="flex">
          <Box marginRight="1rem">
            <FilterByCriteria
              filtersList={filtersList}
              handleFiltersList={handleFiltersList}
              file={textFile}
              filterInput={filterInput}
              statusList={statusList}
              staticFields={trimStaticFields}
              handleFieldEntrySelect={handleFieldEntrySelect}
              filterTypes={
                route === 'trim-level' ? { year: 'number' } : undefined
              }
              loadingSelect={filtersLoading}
              options={{
                carModel: modelsList,
                bodyStyle: bodyStyleList,
                features: featuresList,
              }}
            />
          </Box>
          <Box marginRight="1rem">
            <FieldFilter
              handleSelectItem={handleSelectItem}
              items={fieldSelectItems}
              selectedValues={selectedFields}
              handleResetDefault={handleResetDefault}
            />
          </Box>
          <Box>
            <CarSettingDateRangeFilter
              dateRange={dateRange}
              handleChangeDateRange={handleChangeDateRange}
              title={text.dateRangeTitle}
              calendarTabValue={calendarTabValue}
              handleCalendarTabChange={handleCalendarTabChange}
            />
          </Box>
        </Box>
      </Box>
      <Table
        columns={columns}
        data={data}
        currentPage={page}
        onPageChange={handleChangePage}
        onSelectChange={handleChangePageSize}
        pageSize={pageSize}
        selectItems={selectItems}
        pageCount={getPageCount()}
        filtersList={filtersList}
        fields={selectedFields}
        fieldLabels={fieldSelectLabels}
        deletedItemId={deleteItem}
        setDeleteItemId={setDeleteItem}
        route={detailRoute}
        filtersFile={textFile}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
        loading={loading}
        checkboxSelection={false}
        hideDownloadModal
      />
    </Box>
  )
}
export default CarSettingsListingPage
