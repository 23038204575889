import React from 'react'
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material'

import { StyledCheckboxIcon, StyledLabel } from './style'

type CheckboxProps = {
  /**
   *  Whether the checkbox is ticked or not.
   */
  checked: boolean

  /**
   *  Text that goes along with the checkbox
   */
  label: string

  /**
   *  Cypress test ID for the entire label
   */
  testId?: string
} & MuiCheckboxProps

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  label,
  disableRipple = true,
  testId,
  ...props
}) => (
  <StyledLabel
    control={
      <MuiCheckbox
        checked={checked}
        disableRipple={disableRipple}
        icon={<StyledCheckboxIcon />}
        {...props}
      />
    }
    label={label}
    data-cy={testId}
  />
)

export default Checkbox
