import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import InspectionCreationPage from 'pages/Inspection/Creation'
import InspectionDashboardPage from 'pages/Inspection/Dashboard/Dashboard'
import InspectionDetailPage from 'pages/Inspection/Detail'
import InspectionListingPage from 'pages/Inspection/Listing'

import RoleRoute from 'components/Auth/RoleRoute'

import { INSPECTION_SUB_ROUTES } from 'constants/routes'
import { UserRoles } from 'models/role'

export const InspectionRouter = () => (
  <Switch>
    <RoleRoute
      allowedRoles={[UserRoles.ADMIN, UserRoles.INSPECTOR_SUPERVISOR]}
      path={INSPECTION_SUB_ROUTES.INSPECTION_DASHBOARD}
    >
      <Route
        exact
        path={INSPECTION_SUB_ROUTES.INSPECTION_DASHBOARD}
        component={InspectionDashboardPage}
      />
    </RoleRoute>
    <Route
      exact
      path={INSPECTION_SUB_ROUTES.INSPECTION_LISTING}
      component={InspectionListingPage}
    />
    <Route
      path={INSPECTION_SUB_ROUTES.INSPECTION_DETAIL}
      component={InspectionDetailPage}
    />
    <Route
      path={INSPECTION_SUB_ROUTES.INSPECTION_CREATION}
      component={InspectionCreationPage}
    />
    <Redirect to={INSPECTION_SUB_ROUTES.INSPECTION_LISTING} />
  </Switch>
)
