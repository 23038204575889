import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import {
  cypressNextStepButton,
  cypressPreviousStepButton,
  cypressSubmitButton,
} from 'constants/cypress'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { StyledButton, StyledButtonContainer } from '../style'

type ButtonContainerProps = {
  isFirstStep?: boolean
  previousFunction?: () => void
  nextFunction?: () => void
  confirmButtonType?: 'button' | 'submit' | 'reset'
  textType?: 'next' | 'finish'
  loading?: boolean
  additionalButtonStyles?: React.CSSProperties
  /**
   * Optional button used to submit the form, in case the buttonContainer is placed outside the form.
   */
  formId?: string
}

/*
This component is used in any of the information step of a setting creation process, 
as a container for necessary buttons
*/

const ButtonContainer = ({
  isFirstStep = false,
  previousFunction,
  nextFunction,
  confirmButtonType = 'button',
  textType = 'next',
  loading,
  additionalButtonStyles,
  formId,
}: ButtonContainerProps) => {
  const { text } = useTranslation(textFiles.BRAND_CREATION)
  const { buttons } = text

  return (
    <StyledButtonContainer sx={{ ...additionalButtonStyles }}>
      {isFirstStep ? null : (
        <StyledButton
          variant="text"
          onClick={previousFunction}
          disabled={loading}
          data-cy={cypressPreviousStepButton}
        >
          {buttons.previousStep}
        </StyledButton>
      )}
      <StyledButton
        startIcon={<ArrowForwardIosIcon />}
        size="small"
        type={confirmButtonType}
        role="button"
        onClick={nextFunction}
        disabled={loading}
        data-cy={
          !isFirstStep && textType === 'finish'
            ? cypressSubmitButton
            : cypressNextStepButton
        }
        form={formId}
      >
        {isFirstStep ? buttons.next : buttons[textType]}
      </StyledButton>
    </StyledButtonContainer>
  )
}

export default ButtonContainer
