import React, { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'

import BarChart from 'components/Common/Charts/BarChart'
import CountCard from 'components/Common/CountCard'
import TimeFilter from 'components/Common/TimeFilter'

import { createInspectionApprovementStructure } from 'constants/dashboard'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { CalendarRangeType } from 'models/date'
import {
  FilterByDateRangeVariable,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import {
  GraphApprovementType,
  InspectionDistributionBySourceAndInspector,
} from 'models/services/inspection/dashboard'
import { Stats } from 'models/services/inspector/detail'
import { getIsoDate } from 'utils/date'

import {
  GET_INSPECTIONS_DISTRIBUTION_BY_SOURCE_AND_INSPECTOR,
  GET_INSPECTOR_STATS,
} from 'graphQL/Inspector/Detail/queries'

import { BREAK_POINTS } from 'styles/breakpoints'

import {
  CardContent,
  CardLayout,
  Content,
  LargeBoxBorder,
  ResponsiveCardContainer,
  StyledBox,
  StyledContainer,
} from './style'

type ReponseType = {
  inspector: string
} & FilterByDateRangeVariable

type Props = {
  id: string
}

const Performance = ({ id }: Props) => {
  const [inspections, setInspections] = useState<Stats>()
  const [inspectionsDistribution, setInspectionDistribution] = useState<
    GraphApprovementType[]
  >([])
  const { show } = useNotification()

  const { text } = useTranslation(textFiles.INSPECTION_DASHBOARD)
  const { text: translation } = useTranslation(textFiles.INSPECTOR_DETAIL)

  const { performance: performanceText } = translation

  const { width: windowWidth } = useWindowDimensions()

  const isLayoutXL = windowWidth && windowWidth >= BREAK_POINTS.XL

  const [dateRange, setDateRange] = useState<CalendarRangeType>({
    fromDate: new Date(new Date().setHours(0, 0, 0, 0)),
    toDate: new Date(new Date().setHours(0, 0, 0, 0)),
  })
  const inspectionApprovementStructure = createInspectionApprovementStructure(
    text.inspections.barChartGraphs.inspectionApprovementText
  )

  const { fromDate, toDate } = useMemo(() => {
    return {
      fromDate: getIsoDate(dateRange.fromDate),
      toDate: getIsoDate(dateRange.toDate),
    }
  }, [dateRange])

  const handleChangeDateRange = (newDateRange: CalendarRangeType) => {
    setDateRange(newDateRange)
  }

  useQuery<GenericData<Stats>, GenericInputVariable<string>>(
    GET_INSPECTOR_STATS,
    {
      variables: {
        input: id,
      },
      onCompleted(response) {
        setInspections(response.data)
      },
      onError() {
        show({
          updatedSeverity: 'error',
        })
      },
    }
  )

  const { loading: approvedInspectionByDataLoading } = useQuery<
    InspectionDistributionBySourceAndInspector,
    GenericInputVariable<ReponseType>
  >(GET_INSPECTIONS_DISTRIBUTION_BY_SOURCE_AND_INSPECTOR, {
    variables: {
      input: {
        from: fromDate,
        inspector: id,
        to: toDate,
      },
    },
    onCompleted(response) {
      setInspectionDistribution(
        response.getInspectionDistributionBySourceAndInspector.data
      )
    },
    onError() {
      show({
        updatedSeverity: 'error',
      })
    },
  })

  return (
    <Content
      title={performanceText.title}
      description={performanceText.description}
      contentStyle={{ marginTop: 0 }}
    >
      <TimeFilter
        handleChangeDateRange={handleChangeDateRange}
        dateRange={dateRange}
      />
      <section>
        <ResponsiveCardContainer>
          <CardLayout>
            <CountCard
              count={inspections ? inspections.total : 0}
              name={performanceText.status.total}
              boxStyle={CardContent}
            />
            <CountCard
              count={inspections ? inspections.scheduled : 0}
              name={performanceText.status.pending}
              boxStyle={CardContent}
            />
            <CountCard
              count={inspections ? inspections.approved : 0}
              name={performanceText.status.approved}
              boxStyle={CardContent}
            />
            <CountCard
              count={inspections ? inspections.forRepair : 0}
              name={performanceText.status.forRepair}
              boxStyle={CardContent}
            />

            {isLayoutXL ? null : (
              <>
                <CountCard
                  count={inspections ? inspections.rejected : 0}
                  name={performanceText.status.rejected}
                  boxStyle={CardContent}
                />

                <CountCard
                  count={inspections ? inspections.aborted : 0}
                  name={performanceText.status.cancelled}
                  boxStyle={CardContent}
                />
              </>
            )}
          </CardLayout>

          {isLayoutXL ? (
            <CardLayout>
              <CountCard
                count={inspections ? inspections.rejected : 0}
                name={performanceText.status.rejected}
                boxStyle={CardContent}
              />

              <CountCard
                count={inspections ? inspections.aborted : 0}
                name={performanceText.status.cancelled}
                boxStyle={CardContent}
              />
            </CardLayout>
          ) : null}
        </ResponsiveCardContainer>

        <LargeBoxBorder>
          <StyledBox>
            <StyledContainer
              height={440}
              loading={approvedInspectionByDataLoading}
              isEmpty={!inspectionsDistribution.length}
              description={
                text.inspections.barChartGraphs.basedOnSource.description
              }
              title={text.inspections.barChartGraphs.basedOnSource.title}
            >
              <BarChart
                data={inspectionsDistribution}
                barsStructure={inspectionApprovementStructure}
                layout="horizontal"
                size={{ width: '100%', height: 390 }}
                hasLegend
              />
            </StyledContainer>
          </StyledBox>
        </LargeBoxBorder>
      </section>
    </Content>
  )
}

export default Performance
