import React, { FunctionComponent, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useLazyQuery } from '@apollo/client'
import { InputBaseComponentProps, Typography } from '@mui/material'
import Slider from '@mui/material/Slider'

import Autocomplete, { AutocompleteItem } from 'components/Common/Autocomplete'
import ButtonContainer from 'components/Template/Creation/ButtonContainer'

import { maxMileage, unknownTrimLevelId } from 'constants/car'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { VehicleInformationModel } from 'models/inspection'
import {
  BaseEntity,
  FilterInput,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { Color, ModelTrimYears } from 'models/services/car'
import { InspectionCreationProps } from 'utils/Inspection/creation'

import { GET_INSPECTION_MODELS } from 'graphQL/Common/CarFeature/queries'
import {
  GET_INSPECTION_TRIM_LEVELS,
  GET_INSPECTION_TRIM_YEARS,
} from 'graphQL/Inspection/Creation/queries'

import {
  BoxContainer,
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
  StyledTitleSection,
} from 'styles/creation'
import { colors, FONT_WEIGHT } from 'styles/theme'

import ColorBox from './ColorBox'

type VehicleInfoError = {
  brand: boolean
  model: boolean
  year: boolean
  trim: boolean
  exteriorColor: boolean
  interiorColor: boolean
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom = React.forwardRef<NumberFormat<number>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        thousandSeparator
        isNumericString
        allowNegative={false}
      />
    )
  }
)

const initialErrors: VehicleInfoError = {
  brand: false,
  model: false,
  year: false,
  trim: false,
  exteriorColor: false,
  interiorColor: false,
}

const initialValue: VehicleInformationModel = {
  vehicleBrand: null,
  vehicleModel: null,
  vehicleYear: null,
  vehicleTrimLevel: null,
  vehicleMileage: 100000,
  exteriorColor: null,
  interiorColor: null,
  currentModelList: [],
  currentYearList: [],
  currentTrimLevelList: [],
  id: 0,
}

const VehicleInformation = ({
  currentSecondaryStep,
  handleSecondaryBack,
  handleSecondaryContinue,
  inspectionData,
  brands,
  colorList,
}: InspectionCreationProps) => {
  const [vehicleInfo, setVehicleInfo] =
    useState<VehicleInformationModel>(initialValue)

  const [models, setModels] = useState<BaseEntity[]>(
    vehicleInfo.currentModelList
  )
  const [years, setYears] = useState<BaseEntity[]>(vehicleInfo.currentYearList)
  const [trimLevels, setTrimLevels] = useState<BaseEntity[]>(
    vehicleInfo.currentTrimLevelList
  )

  const [errors, setErrors] = useState<VehicleInfoError>(initialErrors)

  const { text: translation } = useTranslation(textFiles.INSPECTION_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { vehicleInformation } = translation

  const [fetchModels, { loading: modelsLoading }] = useLazyQuery<
    GenericData<BaseEntity[]>,
    GenericInputVariable<FilterInput>
  >(GET_INSPECTION_MODELS, {
    onCompleted(response) {
      setModels(response.data)
    },
  })

  const [fetchYears, { loading: yearsLoading }] = useLazyQuery<
    GenericData<ModelTrimYears>,
    GenericInputVariable<string>
  >(GET_INSPECTION_TRIM_YEARS, {
    onCompleted(response) {
      setYears(
        response.data.trimYears.map((trimYear) => {
          return {
            name: String(trimYear),
            id: trimYear,
          }
        })
      )
    },
  })

  const [fetchTrims, { loading: trimsLoading }] = useLazyQuery<
    GenericData<BaseEntity[]>,
    GenericInputVariable<FilterInput>
  >(GET_INSPECTION_TRIM_LEVELS, {
    onCompleted(response) {
      const newTrims = [
        ...response.data,
        {
          id: unknownTrimLevelId,
          name: vehicleInformation.unknownTrimLevel,
        },
      ]
      setTrimLevels(newTrims)
    },
  })

  const handleSelectBrand = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        vehicleBrand: value,
        vehicleModel: null,
        vehicleYear: null,
        vehicleTrimLevel: null,
      }
    })
    fetchModels({
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
          where: {
            brand_eq: value.id,
          },
        },
      },
    })
  }

  const handleSelectModel = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        vehicleModel: value,
        vehicleYear: null,
        vehicleTrimLevel: null,
      }
    })
    fetchYears({
      variables: {
        input: value.id as string,
      },
    })
  }

  const handleSelectYear = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        vehicleYear: value,
        vehicleTrimLevel: null,
      }
    })
    fetchTrims({
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
          where: {
            year: value.id,
            carModel: vehicleInfo.vehicleModel?.id,
          },
        },
      },
    })
  }

  const handleSelectTrim = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        vehicleTrimLevel: value,
      }
    })
  }

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setVehicleInfo({ ...vehicleInfo, vehicleMileage: newValue as number })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVehicleInfo({
      ...vehicleInfo,
      vehicleMileage: Number(event.target.value),
    })
  }

  const handleBlur = () => {
    if (vehicleInfo.vehicleMileage > maxMileage) {
      setVehicleInfo({ ...vehicleInfo, vehicleMileage: maxMileage })
    }
  }

  const handleSubmit = () => {
    const {
      vehicleBrand,
      vehicleModel,
      vehicleYear,
      vehicleTrimLevel,
      exteriorColor,
      interiorColor,
    } = vehicleInfo

    if (
      !vehicleBrand ||
      !vehicleModel ||
      !vehicleYear ||
      !vehicleTrimLevel ||
      !exteriorColor ||
      !interiorColor
    ) {
      setErrors({
        brand: !vehicleBrand && true,
        model: !vehicleModel && true,
        year: !vehicleYear && true,
        trim: !vehicleTrimLevel && true,
        exteriorColor: !exteriorColor && true,
        interiorColor: !interiorColor && true,
      })
      return
    }
    setErrors(initialErrors)
    handleSecondaryContinue({
      ...vehicleInfo,
      currentModelList: models,
      currentYearList: years,
      currentTrimLevelList: trimLevels,
      id: currentSecondaryStep,
    })
  }

  const handlePrev = () => {
    handleSecondaryBack({
      ...vehicleInfo,
      currentModelList: models,
      currentYearList: years,
      currentTrimLevelList: trimLevels,
      id: currentSecondaryStep,
    })
  }

  useEffect(() => {
    if (
      typeof inspectionData.vehicleInformation[currentSecondaryStep] ===
      'undefined'
    ) {
      setVehicleInfo(initialValue)
    } else {
      const { currentModelList, currentYearList, currentTrimLevelList } =
        inspectionData.vehicleInformation[currentSecondaryStep]
      setVehicleInfo(inspectionData.vehicleInformation[currentSecondaryStep])
      setModels(currentModelList)
      setYears(currentYearList)
      setTrimLevels(currentTrimLevelList)
    }
  }, [currentSecondaryStep, inspectionData.vehicleInformation])

  return (
    <StyledContainer>
      <StyledTitleSection>
        <div>{currentSecondaryStep + 1}</div>
        <Typography
          variant="h5"
          color={colors.black}
          fontWeight={FONT_WEIGHT.BOLD}
        >
          {vehicleInformation.title.replace('%d', currentSecondaryStep + 1)}
        </Typography>
        <Typography
          variant="subtitle1"
          color={colors.placeholderGray}
          fontWeight={FONT_WEIGHT.MEDIUM}
        >
          {vehicleInformation.description}
        </Typography>
      </StyledTitleSection>
      <StyledForm sx={{ minHeight: '200px' }}>
        <div>
          <StyledTextFieldContainer title={vehicleInformation.vehicleBrand}>
            <Autocomplete
              options={brands}
              onChangeHandler={handleSelectBrand}
              placeholder={vehicleInformation.vehicleBrand}
              value={vehicleInfo.vehicleBrand}
              disablePortal={false}
              testId="brands-autocomplete"
            />
            {errors.brand && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.vehicleModel}>
            <Autocomplete
              options={models}
              onChangeHandler={handleSelectModel}
              placeholder={vehicleInformation.vehicleModel}
              value={vehicleInfo.vehicleModel}
              disabled={modelsLoading || !vehicleInfo.vehicleBrand}
              disablePortal={false}
              testId="models-autocomplete"
            />
            {errors.model && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.vehicleYear}>
            <Autocomplete
              options={years}
              onChangeHandler={handleSelectYear}
              placeholder={vehicleInformation.vehicleYear}
              value={vehicleInfo.vehicleYear}
              disabled={yearsLoading || !vehicleInfo.vehicleModel}
              disablePortal={false}
              testId="years-autocomplete"
            />
            {errors.year && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.vehicleTrimLevel}>
            <Autocomplete
              options={trimLevels}
              onChangeHandler={handleSelectTrim}
              placeholder={vehicleInformation.vehicleTrimLevel}
              value={vehicleInfo.vehicleTrimLevel}
              disabled={trimsLoading || !vehicleInfo.vehicleYear}
              disablePortal={false}
              testId="trims-autocomplete"
            />
            {errors.trim && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.exteriorColor}>
            <BoxContainer>
              <Autocomplete
                options={colorList}
                onChangeHandler={(value) => {
                  setVehicleInfo((prevInfo) => {
                    return {
                      ...prevInfo,
                      exteriorColor: value as Color,
                    }
                  })
                }}
                placeholder={vehicleInformation.exteriorColor}
                value={vehicleInfo.exteriorColor}
                disablePortal={false}
                testId="exterior-colors-autocomplete"
              />
              <ColorBox code={vehicleInfo.exteriorColor?.hexCode} />
            </BoxContainer>
            {errors.exteriorColor && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.interiorColor}>
            <BoxContainer>
              <Autocomplete
                options={colorList}
                onChangeHandler={(value) => {
                  setVehicleInfo((prevInfo) => {
                    return {
                      ...prevInfo,
                      interiorColor: value as Color,
                    }
                  })
                }}
                placeholder={vehicleInformation.interiorColor}
                value={vehicleInfo.interiorColor}
                disablePortal={false}
                testId="interior-colors-autocomplete"
              />
              <ColorBox code={vehicleInfo.interiorColor?.hexCode} />
            </BoxContainer>
            {errors.interiorColor && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={vehicleInformation.vehicleMileage}>
            <BoxContainer>
              <Slider
                value={vehicleInfo.vehicleMileage}
                onChange={handleSliderChange}
                max={maxMileage}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '70%',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="vehicleMileage"
                value={vehicleInfo.vehicleMileage}
                onChange={handleInputChange}
                onBlur={handleBlur}
                InputProps={{
                  inputComponent:
                    NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonContainer
        previousFunction={handlePrev}
        nextFunction={handleSubmit}
      />
    </StyledContainer>
  )
}

export default VehicleInformation
