import React, { useCallback, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { uploadImageService } from 'services/uploadImage'

import { BackContainer } from 'components/Common/BackContainer'
import BodyContainerWithHeader from 'components/Common/BodyContainerWithHeader'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { CAR_SETTINGS_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import { BrandCreationAction, BrandCreationModel } from 'models/brandCreation'
import { Brand } from 'models/car'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { CreateBrandInputType } from 'models/services/CarSettings/creation'
import { PublicationStatus } from 'models/status'
import brandCreationReducer from 'reducers/brandCreationReducer'
import {
  createBrandStepperItems,
  INITIAL_BRAND_CREATION_STEPS as steps,
} from 'utils/CarSettings/Brand/creation'
import { validateGraphQLErrorCode } from 'utils/error'

import { CREATE_BRAND } from 'graphQL/Operations/Brand/Creation/mutations'

import { StyledBox } from 'styles/operation/creation'

const initialBrandInformationState: Brand = {
  createdAt: new Date(),
  id: '',
  name: '',
  picture: '',
  pictureFile: null,
  status: PublicationStatus.PUBLISHED,
}

const initialData: BrandCreationModel = {
  brandInformation: initialBrandInformationState,
}

const BrandCreationPage = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)

  const [brandData, dispatch] = useReducer(brandCreationReducer, initialData)
  const { brandInformation } = brandData

  const { text } = useTranslation(textFiles.BRAND_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { stepper } = text

  const stepperItems = createBrandStepperItems(stepper)

  const history = useHistory()
  const { show } = useNotification()

  const updateBrandData = (action: BrandCreationAction) => {
    dispatch(action)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }
  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const [createBrand, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateBrandInputType>
  >(CREATE_BRAND, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
      history.push(CAR_SETTINGS_SUB_ROUTES.BRAND_LISTING)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )

      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
        })
    },
  })

  const handleSubmit = useCallback(async () => {
    const { pictureFile } = brandInformation

    try {
      const response = pictureFile
        ? await uploadImageService(pictureFile)
        : undefined
      createBrand({
        variables: {
          input: {
            name: brandInformation.name,
            icon: response ? response.data : undefined,
          },
        },
      })
    } catch (e) {
      show({
        updatedSeverity: 'error',
        message: generalText.notificationText.uploadError,
      })
    }
  }, [brandInformation, createBrand, generalText, show])

  return (
    <StyledBox>
      <BackContainer />
      <BodyContainerWithHeader
        title={text.title}
        subtitle={text.processDescription}
        stepperItems={stepperItems}
        currentStep={currentStep}
      >
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          handleSubmit,
          brandData,
          submitLoading,
          updateBrandData,
        })}
      </BodyContainerWithHeader>
    </StyledBox>
  )
}

export default BrandCreationPage
