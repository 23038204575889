import React, { useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { Typography } from '@mui/material'

import { DetailSubHeader } from 'components/CarSettings/Common/Detail/DetailSubHeader'
import StatusChangePopper from 'components/CarSettings/Common/Detail/StatusChangePopper'
import ModelInformation from 'components/CarSettings/Model/Detail/ModelInformation'
import Box from 'components/Common/Box'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import Tag from 'components/Common/Tag'
import DetailNavTab, { NavBarItem } from 'components/General/DetailNavTab'
import TabPanel from 'components/Inspection/Detail/TabPanel'

import { CAR_SETTINGS_SUB_ROUTES, routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import { Trim } from 'models/car'
import { Option } from 'models/Select'
import {
  BaseIdEntity,
  BaseListData,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import {
  ModelDetailDataType,
  UpdateGenericInput,
  UpdateModelDataType,
} from 'models/services/CarSettings/detail'
import { PublicationStatus } from 'models/status'
import { generateTabItems } from 'utils/tabs'

import { GET_BRANDS } from 'graphQL/Operations/Model/Creation/queries'
import {
  PUBLISH_MODEL,
  UNPUBLISH_MODEL,
  UPDATE_MODEL,
} from 'graphQL/Operations/Model/Detail/mutation'
import {
  GET_MODEL_BY_ID,
  LIST_COMPACT_TRIM_LEVELS,
} from 'graphQL/Operations/Model/Detail/queries'

import { ContentContainer, Layout, StyledLink } from 'styles/inspection/detail'
import { colors } from 'styles/theme'

export type PictureOption = {
  icon?: string | null
} & Option

const pageSize = 10

const ModelDetailPage = () => {
  const { carSettingsId } = useParams<{ carSettingsId: string }>()
  const [tab] = useState<number>(0)
  const [status, setStatus] = useState<PublicationStatus>(
    PublicationStatus.UNPUBLISHED
  )
  const [model, setModel] = useState<ModelDetailDataType | null>(null)
  const [brandOptions, setBrandOptions] = useState<PictureOption[]>([])

  const { text } = useTranslation(textFiles.MODEL_DETAIL)
  const { general: translation } = text
  const { show } = useNotification()

  const [apolloError, setApolloError] = useState<ApolloError | null>(null)
  const [page, setPage] = useState<number>(1)
  const [trims, setTrims] = useState<Trim[]>([])
  const [totalTrimCount, setTotalTrimCount] = useState<number>(0)

  const { loading: modelLoading } = useQuery<
    GenericData<ModelDetailDataType>,
    GenericInputVariable<string>
  >(GET_MODEL_BY_ID, {
    variables: {
      input: carSettingsId,
    },
    onError(error) {
      setApolloError(error)
    },
    onCompleted(response) {
      setModel(response.data)
      setStatus(response.data.status)
    },
  })

  const { loading: brandsLoading } = useQuery<GenericData<PictureOption[]>>(
    GET_BRANDS,
    {
      onCompleted(response) {
        setBrandOptions(response.data)
      },
    }
  )

  const [updateModel, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<UpdateGenericInput<UpdateModelDataType>>
  >(UPDATE_MODEL, {
    onCompleted() {
      show({ updatedSeverity: 'success', message: translation.updateSuccess })
    },
    onError() {
      show({ updatedSeverity: 'error', message: translation.updateFail })
    },
    refetchQueries: [GET_MODEL_BY_ID],
  })

  const { loading: trimsLoading } = useQuery<
    BaseListData<Trim[]>,
    FilterInputVariable
  >(LIST_COMPACT_TRIM_LEVELS, {
    variables: {
      input: {
        limit: pageSize,
        start: (page - 1) * pageSize,
        where: {
          carModel: carSettingsId,
        },
      },
    },
    onCompleted(response) {
      setTotalTrimCount(response.listData.count)
      setTrims(response.listData.data)
    },
  })

  const [publishModel, { loading: publishModelLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<string>
  >(PUBLISH_MODEL)

  const [unpublishModel, { loading: unpublishModelLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<string>
  >(UNPUBLISH_MODEL)

  const updateStatusMutations = {
    [PublicationStatus.PUBLISHED]: publishModel,
    [PublicationStatus.UNPUBLISHED]: unpublishModel,
  }

  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })

  const handleUpdateModel = (newModel: UpdateModelDataType) => {
    updateModel({
      variables: {
        input: {
          where: { id: carSettingsId },
          data: newModel,
        },
      },
    })
  }

  const handlePublishModel = (newStatus: PublicationStatus) => {
    updateStatusMutations[newStatus]({
      variables: {
        input: carSettingsId,
      },
      onError() {
        show({
          updatedSeverity: 'error',
          message: translation.updateFail,
        })
      },
      onCompleted() {
        show({
          updatedSeverity: 'success',
          message: translation.updateSuccess,
        })
        setStatus(newStatus)
      },
      refetchQueries: [GET_MODEL_BY_ID],
    })
  }

  const handleStatusChange = (newStatus: PublicationStatus) => {
    if (newStatus !== status) {
      handlePublishModel(newStatus)
      setStatus(newStatus)
    }
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const getPageCount = () => {
    return Math.ceil(totalTrimCount / pageSize)
  }

  const isUpdating =
    submitLoading || publishModelLoading || unpublishModelLoading

  if (modelLoading || brandsLoading)
    return <LoadingAnimation showAnimation={modelLoading || brandsLoading} />

  if (apolloError) return <Redirect push to={routes.NOT_FOUND_ERROR} />

  return model && brandOptions.length > 0 ? (
    <Layout>
      <StyledLink to={CAR_SETTINGS_SUB_ROUTES.MODEL_LISTING}>
        <Typography
          variant="h3"
          color={colors.blue}
        >{`< ${translation.backButton}`}</Typography>
      </StyledLink>
      <Box display="flex" alignItems="center" paddingTop="0.5rem">
        <Typography variant="h3" color={colors.black} marginRight="1rem">
          {translation.title} #{carSettingsId}
        </Typography>
        <Tag
          backgroundColor={isUpdating ? colors.gray : undefined}
          color={isUpdating ? colors.black : undefined}
          status={status}
        />
        <StatusChangePopper
          loading={isUpdating}
          status={status}
          setStatus={handleStatusChange}
        />
      </Box>
      <DetailSubHeader text={translation.description} />
      <DetailNavTab tab={tab} items={initialItems} />
      <ContentContainer>
        <TabPanel value={tab} index={0}>
          <ModelInformation
            brands={brandOptions}
            handleUpdateModel={handleUpdateModel}
            loading={isUpdating}
            model={model}
            handlePageChange={handleChangePage}
            pageCount={getPageCount()}
            totalCount={totalTrimCount}
            trimList={trims}
            actualPage={page}
            trimsLoading={trimsLoading}
            pageSize={pageSize}
          />
        </TabPanel>
      </ContentContainer>
    </Layout>
  ) : null
}

export default ModelDetailPage
