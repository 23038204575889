import { gql } from '@apollo/client'

export const UPDATE_BRAND = gql`
  mutation updateBrand($input: UpdateBrandInput!) {
    data: updateBrand(input: $input) {
      name
      id
    }
  }
`

export const PUBLISH_BRAND = gql`
  mutation publishBrand($input: ID!) {
    data: publishBrand(id: $input) {
      id
    }
  }
`

export const UNPUBLISH_BRAND = gql`
  mutation unpublishBrand($input: ID!) {
    data: unpublishBrand(id: $input) {
      id
    }
  }
`
