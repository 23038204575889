import React from 'react'

import Box from 'components/Common/Box'

import { FONT_WEIGHT } from 'styles/theme'

type StatusTagProps = {
  backgroundColor: string
  color: string
}

const StatusTag: React.FC<StatusTagProps> = ({
  backgroundColor,
  color,
  children,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor,
        color,
        width: '85px',
        height: '25px',
        borderRadius: '2px',
        opacity: '1',
        fontSize: '12px',
        fontWeight: FONT_WEIGHT.MEDIUM,
      }}
    >
      {children}
    </Box>
  )
}

export default StatusTag
