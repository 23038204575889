import React from 'react'

import { ListBullet, ListIcon } from 'components/Inspector/Absence/style'

type CardProps = {
  text: string
}

const CardTitle = ({ text }: CardProps) => (
  <>
    <ListIcon>
      <ListBullet />
    </ListIcon>
    {text}
  </>
)

export default CardTitle
