import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)({
  display: 'flex',
  width: '100%',
  height: '48px',
  marginBottom: '.5rem',
})

export const StyledText = styled('section')({
  paddingLeft: '1rem',
  marginRight: '1rem',
  maxWidth: '484px',
  width: '75%',
})

export const StyledHour = styled('section')({
  height: '48px',
  maxWidth: '45px',
  display: 'flex',
  alignItems: 'flex-end',
})
