import React from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { BoxProps, Typography } from '@mui/material'

import { colors } from 'styles/theme'

import { StyledContainer } from './style'

type ErrorMessageProps = {
  /**
   * If the error message has an icon accompanied with it
   */
  hasIcon?: boolean

  /**
   * Message to display
   */
  text: string
} & BoxProps

/**
 * A text to display when an error happens, contains an icon that can be disabled
 */
const ErrorMessage: React.FC<ErrorMessageProps> = ({
  hasIcon = true,
  text,
  ...props
}: ErrorMessageProps) => (
  <StyledContainer {...props}>
    {hasIcon ? <InfoIcon color="error" fontSize="small" /> : null}
    <Typography color={colors.lightRed} marginLeft="8px" variant="subtitle1">
      {text}
    </Typography>
  </StyledContainer>
)

export default ErrorMessage
