import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'

/*
 * This hook is used to manage the query state of the application, and it is used to
 * get and manage the state of the query params in the url.
 * Being able to take as props the parameter name, the default value, an initial state value and a serializer
 * function to format the value of the state into a string with its designated param and value for showing it in the url.
 * Based in this Blog: https://everttimberg.io/blog/custom-react-hook-query-state/
 */
export default function useQueryState<S>(
  paramName: string,
  initialState: S | (() => S),
  serialize:
    | ((val: S, def?: S) => string | number | null | undefined)
    | undefined = undefined,
  defaultValue?: S
): [S, React.Dispatch<React.SetStateAction<S>>] {
  const history = useHistory()
  const { pathname, search, hash } = useLocation()
  const queryParams = useMemo(() => qs.parse(search), [search])
  const parsedValue = qs.parse(search, { parseNumbers: true })[
    paramName
  ] as unknown as typeof initialState as S

  const initialStateValue = serialize
    ? (serialize(parsedValue, defaultValue) as unknown)
    : parsedValue

  const [stateValue, setState] = useState<S>(
    (initialStateValue as S | (() => S)) || initialState
  )

  useEffect(() => {
    const typedState =
      typeof stateValue === 'number' ? Number(stateValue) : String(stateValue)

    const serializedValue = serialize
      ? serialize(stateValue, defaultValue)
      : typedState

    if (queryParams[paramName] !== serializedValue) {
      // To avoid infinite loops caused by history.replace (which triggers the history object to change)
      // Check to see if our tag is going to change and only update the query param if that is true

      const updatedQueryParams = {
        ...queryParams,
      }
      if (
        serializedValue !== null &&
        serializedValue !== undefined &&
        ((typeof serializedValue === 'string' &&
          serializedValue.length !== 0) ||
          (typeof serializedValue === 'number' && serializedValue >= 0))
      ) {
        updatedQueryParams[paramName] = String(serializedValue)
      } else {
        delete updatedQueryParams[paramName]
      }

      const newQuery = qs.stringify(updatedQueryParams, { encode: false })

      history.replace({
        pathname,
        search: newQuery,
        hash,
      })
    }
  }, [
    stateValue,
    history,
    pathname,
    queryParams,
    serialize,
    hash,
    paramName,
    defaultValue,
    initialState,
  ])

  return [stateValue, setState]
}
