/* eslint-disable  @typescript-eslint/no-explicit-any */
// Since the react-google-maps api doesn't have all necessary types I added that line for some cases
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Typography } from '@mui/material'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from '@react-google-maps/api'

import Box from 'components/Common/Box'
import LoadingAnimation from 'components/Common/LoadingAnimation'

import { GMAP_KEY } from 'constants/environment'
import { libraries } from 'constants/map'
import { CurboSpot } from 'models/curboSpot'
import { Address, Coordinate } from 'models/map'
import { getAddressFromLatLng, mapOptions } from 'utils/mapUtils'

import bringIcon from 'images/curbo-bring.png'
import spotIcon from 'images/curbo-spot.png'

// gmap variables

export const initialCircleCenter: Coordinate = {
  lat: 18.486057,
  lng: -69.931213,
}

type MapProps = {
  /**
   * Initial coordinates of the map when first render
   */
  circleCenter?: Coordinate
  /**
   * If buttons are disabled or not
   */
  disabled?: boolean
  /**
   * Curbo Spots to Display
   */
  curboSpots: CurboSpot[]

  address: Address | undefined

  setAddress: React.Dispatch<Address>

  setIsLoaded?: React.Dispatch<boolean>

  border?: 'round' | 'square'
}

const Map = ({
  disabled,
  circleCenter = initialCircleCenter,
  curboSpots,
  address,
  setAddress,
  setIsLoaded,
  border = 'square',
}: MapProps) => {
  const [selectedInfoWindow, setSelectedInfoWindow] = useState<Address | null>(
    null
  )
  const mapRef = useRef<any>(null)

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GMAP_KEY!,
    libraries,
  })

  const onMapLoad = useCallback(
    (map) => {
      mapRef.current = map
      if (setIsLoaded !== undefined) setIsLoaded(true)
    },
    [setIsLoaded]
  )

  const handleSetBringItMarker = useCallback(
    async (event: google.maps.MapMouseEvent) => {
      const lat = event.latLng!.lat()
      const lng = event.latLng!.lng()

      const fetchAddress = await getAddressFromLatLng(lat, lng)
      if (fetchAddress) {
        setAddress({
          id: fetchAddress.place_id,
          lat,
          lng,
          address: fetchAddress.formatted_address,
          name: fetchAddress.address_components[0].short_name,
        })
      }
    },
    [setAddress]
  )

  useEffect(() => {
    if (mapRef.current && address) {
      mapRef.current.panTo({
        lat: address.lat,
        lng: address.lng,
      })
      mapRef.current.setZoom(18)
    }
  }, [address])

  return (
    <Box width="100%" height="100%">
      {loadError ? <h1>Error {loadError.message}</h1> : null}

      {isLoaded && !loadError ? (
        <GoogleMap
          mapContainerStyle={{
            width: '100%',
            height: '100%',
            borderRadius: border === 'square' ? undefined : '10px',
          }}
          options={mapOptions}
          onLoad={onMapLoad}
          onClick={(event) =>
            !disabled ? handleSetBringItMarker(event) : null
          }
          zoom={12}
          center={circleCenter}
          id="google-map"
        >
          {address && !address.originFromSpot ? (
            <Marker
              key={address.id}
              position={{
                lat: Number(address.lat),
                lng: Number(address.lng),
              }}
              draggable={!disabled}
              onDragEnd={(event) => handleSetBringItMarker(event)}
              icon={{
                url: bringIcon,
                origin: new (window as any).google.maps.Point(0, 0),
                anchor: new (window as any).google.maps.Point(15, 15),
                scaledSize: new (window as any).google.maps.Size(30, 40),
              }}
            />
          ) : null}

          {curboSpots.map((spot: CurboSpot) => (
            <Marker
              key={`curbo-spot-marker-${spot.id}-${spot.latitude}`}
              position={{
                lat: Number(spot.latitude),
                lng: Number(spot.longitude),
              }}
              icon={{
                url: spotIcon,
                origin: new (window as any).google.maps.Point(0, 0),
                anchor: new (window as any).google.maps.Point(15, 15),
                scaledSize: new (window as any).google.maps.Size(30, 40),
              }}
              onClick={() => {
                const {
                  address: locationAddress,
                  latitude: lat,
                  longitude: lng,
                  name,
                  id,
                } = spot

                setSelectedInfoWindow({
                  lat,
                  lng,
                  address: locationAddress,
                  name,
                  id,
                })
                setAddress({
                  lat,
                  lng,
                  address: locationAddress,
                  name,
                  id,
                  originFromSpot: true,
                })
              }}
            />
          ))}

          {selectedInfoWindow ? (
            <InfoWindow
              position={{
                lat: Number(selectedInfoWindow.lat),
                lng: Number(selectedInfoWindow.lng),
              }}
            >
              <Box>
                <Typography variant="h4" marginBottom="0.5rem">
                  {selectedInfoWindow.name}
                </Typography>
                <Typography variant="body2">
                  {selectedInfoWindow.address}
                </Typography>
              </Box>
            </InfoWindow>
          ) : null}
        </GoogleMap>
      ) : (
        <LoadingAnimation showAnimation />
      )}
    </Box>
  )
}

export default Map
