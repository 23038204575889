import React, { useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { Divider, Typography } from '@mui/material'
import { uploadImageService } from 'services/uploadImage'

import BrandInformation from 'components/CarSettings/Brand/Detail/Information'
import StatusChangePopper from 'components/CarSettings/Common/Detail/StatusChangePopper'
import Box from 'components/Common/Box'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import Tag from 'components/Common/Tag'
import DetailNavTab, { NavBarItem } from 'components/General/DetailNavTab'
import TabPanel from 'components/Inspection/Detail/TabPanel'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { CAR_SETTINGS_SUB_ROUTES, routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import {
  BaseEntity,
  BaseIdEntity,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import {
  BrandDetailDataType,
  ListModelType,
  UpdateGenericInput,
  UpdateGenericInputType,
} from 'models/services/CarSettings/detail'
import { PublicationStatus } from 'models/status'
import { validateGraphQLErrorCode } from 'utils/error'
import { generateTabItems } from 'utils/tabs'

import { LIST_MODELS } from 'graphQL/CarSettings/Listing/queries'
import {
  PUBLISH_BRAND,
  UNPUBLISH_BRAND,
  UPDATE_BRAND,
} from 'graphQL/Operations/Brand/Detail/mutations'
import { GET_BRAND_BY_ID } from 'graphQL/Operations/Brand/Detail/queries'

import { ContentContainer, Layout, StyledLink } from 'styles/inspection/detail'
import { colors } from 'styles/theme'

const pageSize = 10

const BrandDetailPage = () => {
  const { carSettingsId } = useParams<{ carSettingsId: string }>()

  const { text } = useTranslation(textFiles.BRAND_DETAIL)
  const { general: translation } = text
  const { notificationText } = text
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const [tab] = useState<number>(0)
  const [status, setStatus] = useState<PublicationStatus>(
    PublicationStatus.UNPUBLISHED
  )
  const [brandData, setBrandData] = useState<BrandDetailDataType | null>(null)
  const [apolloError, setApolloError] = useState<ApolloError | null>(null)
  const [page, setPage] = useState<number>(1)
  const [models, setModels] = useState<BaseEntity[]>([])
  const [totalModelCount, setTotalModelCount] = useState<number>(0)

  const { show } = useNotification()

  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })

  const { loading: loadingBrandData } = useQuery<
    GenericData<BrandDetailDataType>,
    GenericInputVariable<string>
  >(GET_BRAND_BY_ID, {
    variables: {
      input: carSettingsId,
    },
    onCompleted(queryData) {
      setBrandData(queryData.data)
      setStatus(queryData.data.status)
    },
    onError(error) {
      show({
        updatedSeverity: 'error',
        message: generalText.notificationText.error,
      })
      setApolloError(error)
    },
  })

  const [updateBrand, { loading: submitLoading }] = useMutation<
    GenericData<BaseEntity>,
    GenericInputVariable<UpdateGenericInput<UpdateGenericInputType>>
  >(UPDATE_BRAND, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: notificationText.updateSuccess,
      })
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: translation.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
          message: notificationText.updateFail,
        })
    },
    refetchQueries: [GET_BRAND_BY_ID],
  })

  const { loading: modelsLoading } = useQuery<
    ListModelType,
    FilterInputVariable
  >(LIST_MODELS, {
    variables: {
      input: {
        limit: pageSize,
        start: (page - 1) * pageSize,
        where: {
          brand: carSettingsId,
        },
      },
    },
    onCompleted(response) {
      setTotalModelCount(response.listData.count)
      setModels(response.listData.data)
    },
  })

  const [publishBrand, { loading: publishBrandLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<string>
  >(PUBLISH_BRAND)

  const [unpublishBrand, { loading: unpublishBrandLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<string>
  >(UNPUBLISH_BRAND)

  const updateStatusMutations = {
    [PublicationStatus.PUBLISHED]: publishBrand,
    [PublicationStatus.UNPUBLISHED]: unpublishBrand,
  }

  const handlePublishBrand = (newStatus: PublicationStatus) => {
    updateStatusMutations[newStatus]({
      variables: {
        input: carSettingsId,
      },
      onError() {
        show({
          updatedSeverity: 'error',
          message: notificationText.updateFail,
        })
      },
      onCompleted() {
        show({
          updatedSeverity: 'success',
          message: notificationText.updateSuccess,
        })
        setStatus(newStatus)
      },
      refetchQueries: [GET_BRAND_BY_ID],
    })
  }

  const handleStatusChange = (newStatus: PublicationStatus) => {
    if (newStatus !== status) {
      handlePublishBrand(newStatus)
    }
  }

  const handleSave = async (
    name: string | undefined,
    fileState: File | null,
    hasChangedPicture: boolean
  ) => {
    try {
      const image =
        hasChangedPicture && fileState
          ? await uploadImageService(fileState)
          : undefined
      const response = await updateBrand({
        variables: {
          input: {
            where: {
              id: carSettingsId,
            },
            data: {
              name,
              icon: image && image.data,
            },
          },
        },
      })
      if (response.errors) return false
      return true
    } catch {
      show({
        updatedSeverity: 'error',
        message: generalText.notificationText.uploadError,
      })
      return false
    }
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const getPageCount = () => {
    return Math.ceil(totalModelCount / pageSize)
  }

  const isUpdateLoading =
    submitLoading || publishBrandLoading || unpublishBrandLoading

  if (loadingBrandData) return <LoadingAnimation showAnimation />

  if (apolloError) return <Redirect push to={routes.NOT_FOUND_ERROR} />

  return brandData ? (
    <Layout>
      <StyledLink to={CAR_SETTINGS_SUB_ROUTES.BRAND_LISTING}>
        <Typography
          variant="h3"
          color={colors.blue}
        >{`< ${translation.backButton}`}</Typography>
      </StyledLink>
      <Box display="flex" alignItems="center" paddingTop="0.5rem">
        <Typography variant="h3" color={colors.black} marginRight="1rem">
          {translation.title} #{carSettingsId}
        </Typography>
        <Tag status={status} />
        <StatusChangePopper
          status={status}
          setStatus={handleStatusChange}
          loading={isUpdateLoading}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        padding="0.75rem 0.25rem"
        width="20%"
      >
        <PlaylistAddCheckIcon fontSize="medium" sx={{ color: colors.gray }} />
        <Typography variant="body2" color={colors.gray} marginLeft="0.5rem">
          {translation.description}
        </Typography>
      </Box>
      <Divider
        sx={{
          display: 'flex',
          flexDirection: 'row',
          borderColor: colors.gray,
        }}
      />
      <DetailNavTab tab={tab} items={initialItems} />
      <ContentContainer>
        <TabPanel value={tab} index={0}>
          <BrandInformation
            brandData={brandData}
            submitLoading={isUpdateLoading}
            handleSave={handleSave}
            handlePageChange={handleChangePage}
            pageCount={getPageCount()}
            totalCount={totalModelCount}
            modelList={models}
            actualPage={page}
            modelsLoading={modelsLoading}
            pageSize={pageSize}
          />
        </TabPanel>
      </ContentContainer>
    </Layout>
  ) : null
}

export default BrandDetailPage
