import { styled } from '@mui/material'

import Box from 'components/Common/Box'

import { BREAK_POINTS } from 'styles/breakpoints'

export const GridBox = styled(Box)({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '33% 33% 33%',
  columnGap: '20px',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '50% 50%',
    columnGap: '10px',
    rowGap: '20px',
  },
})

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
})
