import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useLazyQuery, useQuery } from '@apollo/client'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { SelectChangeEvent } from '@mui/material'
import {
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid'

import Avatar from 'components/Common/Avatar'
import Box from 'components/Common/Box'
import Table from 'components/Common/Table'
import Tooltip from 'components/Common/Table/Tooltip'
import BiggerFontTooltip from 'components/Common/Table/Tooltip/BiggerFontTooltip'
import { TabObjects } from 'components/Common/Tabs'
import Title from 'components/Common/Title'
import { Filter } from 'components/Inspection/Dashboard/FilterCard'
import DateRangeFilter from 'components/Inspection/Listing/DateFilter'
import FieldFilter from 'components/Inspection/Listing/FieldFilter'
import CarTrimPopover from 'components/Inventory/Common/CarTrimPopover'
import CertifiedTag from 'components/Inventory/Common/CertifiedTag'
import FilterByCriteria from 'components/Listing/FilterByCriteria'

import { FilteringOption } from 'constants/Inspection/filterByCriteria'
import {
  packagingHash,
  PreInventoryColumnField,
  preInventoryStaticFields,
} from 'constants/preinventory'
import { routes } from 'constants/routes'
import {
  commonListColumns,
  defaultSortModel,
  selectItems,
} from 'constants/table'
import { textFiles } from 'constants/textFiles'
import { UrlParamNames } from 'constants/urlQuery'
import useLocale from 'hooks/useLocale'
import useNotification from 'hooks/useNotification'
import useQueryState from 'hooks/useQueryState'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { CurboSpot } from 'models/curboSpot'
import { CalendarRangeType } from 'models/date'
import { UserRoles } from 'models/role'
import {
  FilterInputVariable,
  GenericData,
  ListingFilterType,
} from 'models/services/base'
import {
  CarProviderEnum,
  ListInventory,
} from 'models/services/inventory/listing'
import { ExtendedStatus } from 'models/services/status'
import { InventoryStatusMap } from 'models/status'
import { getIsoDate } from 'utils/date'
import {
  checkSearchEmptiness,
  cleanFilters,
  generateFilterInput,
} from 'utils/filters'
import {
  addCommas,
  formatFiltersWithCertification,
  formatInventories,
} from 'utils/Inventory/listing'
import {
  createFieldSelectItems,
  createFieldSelectLabels,
  defaultFields,
} from 'utils/PreInventory/listing'
import { buildDetailRoute } from 'utils/routes'
import {
  serializeFields,
  serializeFilters,
  serializePage,
  serializePageSize,
  serializeRange,
  serializeSearch,
  serializeSortModel,
} from 'utils/serializers'
import { verifyParam } from 'utils/verifyUrlData'

import {
  GET_DEALERS,
  GET_PUBLISHED_CURBO_SPOTS,
} from 'graphQL/Common/Dealer/queries'
import {
  GET_CAR_STATUSES,
  LIST_INVENTORY,
} from 'graphQL/Inventory/Listing/queries'
import { GET_BRANDS } from 'graphQL/Operations/Model/Creation/queries'
import {
  GET_BODY_STYLES,
  GET_CATEGORIES,
  GET_COLORS,
  GET_COUNTRY_VERSIONS,
  GET_DRIVE_TRAINS,
  GET_FEATURES,
  GET_FUEL_TYPES,
  GET_MODELS,
  GET_TRANSMISSIONS,
} from 'graphQL/Operations/Trim/Detail/queries'

import { StyledTextField } from 'styles/inspection/listing'

const PreInventoryListingPage = () => {
  const { validateAllowedRoles } = useUser()
  const admin = validateAllowedRoles([
    UserRoles.ADMIN,
    UserRoles.INVENTORY_SUPERVISOR,
  ])
  const { show } = useNotification()

  const [selectedLanguage] = useLocale()
  const appSetting = useSetting()[2]
  const currency = appSetting ? appSetting.currency : null
  const priceCurrency = currency ? `${currency.code}` : ''
  const history = useHistory()
  const { text } = useTranslation(textFiles.PRE_INVENTORY_LISTING)
  const { providers } = text
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const location = useLocation()
  const { search } = location

  const defaultSortedFields = [...defaultFields].sort()

  const [tabValue, setTabValue] = useState<string | number>(
    location && location.hash === packagingHash ? 1 : 0
  )
  const [selectedFields, setSelectedFields] = useQueryState<string[]>(
    UrlParamNames.FIELDS,
    (verifyParam(UrlParamNames.FIELDS, search) as string[]) ||
      defaultSortedFields,
    serializeFields,
    defaultSortedFields
  )

  const [filtersList, setFiltersList] = useQueryState<Filter[]>(
    UrlParamNames.FILTERS,
    (verifyParam(UrlParamNames.FILTERS, search) as Filter[]) || [],
    serializeFilters
  )

  const [dateRange, setDateRange] = useQueryState<CalendarRangeType>(
    UrlParamNames.DATE,
    (verifyParam(UrlParamNames.DATE, search) as CalendarRangeType) || [],
    serializeRange
  )

  const [searchValue, setSearchValue] = useQueryState<string | undefined>(
    UrlParamNames.SEARCH,
    verifyParam(UrlParamNames.SEARCH, search) as string,
    serializeSearch
  )

  const [searchInput, setSearchInput] = useState<string>(searchValue || '')

  const [pageSize, setPageSize] = useQueryState<number>(
    UrlParamNames.LIMIT,
    (verifyParam(UrlParamNames.LIMIT, search) as number) || 10,
    serializePageSize,
    10
  )
  const [page, setPage] = useQueryState<number>(
    UrlParamNames.PAGE,
    (verifyParam(UrlParamNames.PAGE, search) as number) || 1,
    serializePage
  )

  const [sortModel, setSortModel] = useQueryState<GridSortModel>(
    UrlParamNames.SORT,
    (verifyParam(UrlParamNames.SORT, search) as GridSortModel) ||
      defaultSortModel,
    serializeSortModel,
    defaultSortModel
  )
  const [filterInput, setFiltersInput] = useState<ListingFilterType>(
    generateFilterInput(filtersList)
  )

  const [inventoryQuantity, setInventoryQuantity] = useState<number>(0)
  const [inventoryData, setInventoryData] = useState<GridRowsProp>([])
  const [deleteItem, setDeleteItem] = useState<GridRowId | null>(null)
  const [curboSpots, setCurboSpots] = useState<CurboSpot[]>([])
  const [statusList, setStatusList] = useState<ExtendedStatus[]>([])
  const [colorsList, setColorsList] = useState<FilteringOption[]>([])
  const [modelsList, setModelsList] = useState<FilteringOption[]>([])
  const [brandsList, setBrandsList] = useState<FilteringOption[]>([])
  const [bodyStyleList, setBodyStyleList] = useState<FilteringOption[]>([])
  const [countryVersionList, setCountryVersionList] = useState<
    FilteringOption[]
  >([])
  const [fuelTypeList, setFuelTypeList] = useState<FilteringOption[]>([])
  const [transmissionList, setTransmissionList] = useState<FilteringOption[]>(
    []
  )
  const [driveTrainList, setDriveTrainList] = useState<FilteringOption[]>([])
  const [dealerList, setDealerList] = useState<FilteringOption[]>([])
  const [categoryList, setCategoryList] = useState<FilteringOption[]>([])
  const [featuresList, setFeaturesList] = useState<FilteringOption[]>([])

  const { fromDate, toDate } = useMemo(() => {
    return {
      fromDate: getIsoDate(dateRange.fromDate),
      toDate: getIsoDate(dateRange.toDate),
    }
  }, [dateRange])

  const providersList: FilteringOption[] = Object.values(CarProviderEnum)
    .filter((providerType) => typeof providerType !== 'number')
    .map((providerType) => ({
      name: text.providers[providerType],
      value: String(providerType).replace('_', '-').toLowerCase(),
      disabled: false,
    }))

  const getStatuses = tabValue === 0 ? ['pending'] : ['to-review']

  const tabs: TabObjects[] = [
    {
      id: 1,
      item: {
        label: text.pendingTab,
        value: 0,
        badge: 0,
      },
    },
    {
      id: 2,
      item: {
        label: text.packagingTab,
        value: 1,
        badge: 0,
      },
    },
  ]

  const vehicleTypes: FilteringOption[] = [
    {
      name: generalText.status.certified,
      value: 'certified',
      disabled: false,
    },
    {
      name: generalText.status.notCertified,
      value: 'notCertified',
      disabled: false,
    },
  ]

  const fieldSelectItems = createFieldSelectItems(text.fieldSelect)

  const fieldSelectLabels = createFieldSelectLabels(text.fieldSelect)

  const getPageCount = () => {
    return Math.ceil(inventoryQuantity / pageSize)
  }
  const handleShowError = () => {
    show({
      updatedSeverity: 'error',
      message: generalText.notificationText.error,
    })
  }

  const handleFiltersList = (
    newFiltersList: Filter[],
    newFilterInput: ListingFilterType
  ) => {
    setFiltersList(newFiltersList)
    setFiltersInput(newFilterInput)
    setPage(1)
  }

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchInput(event.target.value)
  }

  const handleTabChange = (newValue: string | number) => {
    if (newValue === 1) history.replace({ ...location, hash: packagingHash })
    else history.replace({ ...location, hash: '#' })
    setPage(1)
  }

  const { loading: statusLoading, data: statusResponse } = useQuery<
    GenericData<ExtendedStatus[]>,
    FilterInputVariable
  >(GET_CAR_STATUSES, {
    variables: {
      input: {
        where: {
          slug_in: getStatuses,
        },
      },
    },
  })

  const { loading: carsLoading } = useQuery<ListInventory, FilterInputVariable>(
    LIST_INVENTORY,
    {
      variables: {
        input: {
          limit: pageSize,
          start: (page - 1) * pageSize,
          where: {
            text_search: searchValue,
            createdAt_gte: fromDate,
            createdAt_lte: toDate,
            status_in: getStatuses,
            ...cleanFilters(formatFiltersWithCertification(filterInput)),
          },
          sort:
            sortModel.length > 0 && sortModel[0].sort
              ? {
                  [sortModel[0].field]: sortModel[0].sort,
                }
              : undefined,
        },
      },
      onCompleted(response) {
        const { data, count } = response.listInventoryCars
        setInventoryQuantity(count)
        setInventoryData(formatInventories(data))
      },
      onError() {
        handleShowError()
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const [
    getCurboSpots,
    { loading: curboSpotsLoading, called: curboSpotsCalled },
  ] = useLazyQuery<GenericData<CurboSpot[]>>(GET_PUBLISHED_CURBO_SPOTS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setCurboSpots(response.data)
    },
  })

  const [getColors, { loading: colorsLoading, called: colorsCalled }] =
    useLazyQuery<GenericData<FilteringOption[]>>(GET_COLORS, {
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
        },
      },
      onCompleted(response) {
        setColorsList(response.data)
      },
    })

  const [getModels, { loading: modelsLoading, called: modelsCalled }] =
    useLazyQuery<GenericData<FilteringOption[]>>(GET_MODELS, {
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
        },
      },
      onCompleted(response) {
        setModelsList(response.data)
      },
    })

  const [getBrands, { loading: brandsLoading, called: brandsCalled }] =
    useLazyQuery<GenericData<FilteringOption[]>>(GET_BRANDS, {
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
        },
      },
      onCompleted(response) {
        setBrandsList(response.data)
      },
    })

  const [
    getBodyStyles,
    { loading: bodyStylesLoading, called: bodyStylesCalled },
  ] = useLazyQuery<GenericData<FilteringOption[]>>(GET_BODY_STYLES, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setBodyStyleList(response.data)
    },
  })

  const [
    getCountryVersions,
    { loading: countryVersionLoading, called: countryVersionCalled },
  ] = useLazyQuery<GenericData<FilteringOption[]>>(GET_COUNTRY_VERSIONS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setCountryVersionList(response.data)
    },
  })

  const [getFuelTypes, { loading: fuelTypesLoading, called: fuelTypesCalled }] =
    useLazyQuery<GenericData<FilteringOption[]>>(GET_FUEL_TYPES, {
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
        },
      },
      onCompleted(response) {
        setFuelTypeList(response.data)
      },
    })

  const [
    getTransmissions,
    { loading: transmissionsLoading, called: transmissionsCalled },
  ] = useLazyQuery<GenericData<FilteringOption[]>>(GET_TRANSMISSIONS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setTransmissionList(response.data)
    },
  })
  const [
    getDriveTrains,
    { loading: driveTrainsLoading, called: driveTrainsCalled },
  ] = useLazyQuery<GenericData<FilteringOption[]>>(GET_DRIVE_TRAINS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setDriveTrainList(response.data)
    },
  })
  const [getDealers, { loading: dealersLoading, called: dealersCalled }] =
    useLazyQuery<GenericData<FilteringOption[]>>(GET_DEALERS, {
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
        },
      },
      onCompleted(response) {
        setDealerList(response.data)
      },
    })
  const [
    getCategories,
    { loading: categoriesLoading, called: categoriesCalled },
  ] = useLazyQuery<GenericData<FilteringOption[]>>(GET_CATEGORIES, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setCategoryList(response.data)
    },
  })
  const [getFeatures, { loading: featuresLoading, called: featuresCalled }] =
    useLazyQuery<GenericData<FilteringOption[]>>(GET_FEATURES, {
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
        },
      },
      onCompleted(response) {
        setFeaturesList(response.data)
      },
    })

  const handleEditInventory = React.useCallback(
    (id: GridCellValue) => {
      history.push(buildDetailRoute(id, routes.INVENTORY_DETAIL))
    },
    [history]
  )
  // const handleDeleteInventory = React.useCallback(
  //   (id: GridCellValue) => {
  //     history.push(buildDetailRoute(id, routes.TEMPLATE_DETAIL))
  //   },
  //   [history]
  // )

  const createColumns = useCallback(
    (statuses?: ExtendedStatus[], fields?: string[]) => {
      const hiddenField = (field: string) => {
        return fields && !fields.includes(field)
      }
      const {
        id: idColumn,
        isoDate: dateColumn,
        picture: pictureColumn,
        status: statusColumn,
        provider: providerColumn,
        standard: standardColumn,
        verticalActions: actionsColumn,
      } = commonListColumns(
        InventoryStatusMap,
        selectedLanguage,
        text,
        handleEditInventory,
        undefined,
        statuses
      )
      return [
        { ...idColumn, hide: hiddenField('id') },
        {
          ...standardColumn,
          field: PreInventoryColumnField.SLUG,
          headerName: text.table.slug,
          hide: hiddenField('slug'),
          flex: 2,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => (
            <Tooltip>{params.row.slug}</Tooltip>
          ),
        },
        {
          ...pictureColumn,
          hide: hiddenField('picture'),
          renderCell: (params: GridRenderCellParams) => {
            return (
              <Avatar
                image={params.row.picture}
                alt="C"
                height={88}
                width={88}
                style={{
                  width: '88px',
                  height: '88px',
                }}
                variant="rounded"
              />
            )
          },
        },
        {
          ...standardColumn,
          field: PreInventoryColumnField.VEHICLE,
          headerName: text.table.vehicle,
          flex: 2,
          width: 200,
          sortable: true,
          hide: hiddenField('brand'),
          renderCell: (params: GridRenderCellParams) => (
            <BiggerFontTooltip text={params.row.vehicle} />
          ),
        },
        {
          ...standardColumn,
          field: PreInventoryColumnField.TRIM,
          headerName: text.table.trim,
          flex: 2,
          sortable: true,
          hide: hiddenField('trimLevel'),
          renderCell: (params: GridRenderCellParams) => {
            return (
              <CarTrimPopover
                trim={params.row.trim as string}
                bodyStyle={params.row.trimBox.bodyStyle}
                category={params.row.trimBox.category}
                exteriorColor={params.row.trimBox.exteriorColor}
                interiorColor={params.row.trimBox.interiorColor}
                countryVersion={params.row.trimBox.countryVersion}
                fuelType={params.row.trimBox.fuelType}
                curboSpot={params.row.trimBox.curboSpot}
              />
            )
          },
        },
        {
          ...providerColumn,
          flex: 1,
          hide: hiddenField('provider'),
          renderCell: (params: GridRenderCellParams) => (
            <BiggerFontTooltip
              text={providers[params.row.provider.toUpperCase()]}
            />
          ),
        },
        {
          ...standardColumn,
          field: PreInventoryColumnField.PRICE,
          headerName: text.table.price,
          flex: 1,
          sortable: true,
          hide: hiddenField('listingPrice'),
          renderCell: (params: GridRenderCellParams) => (
            <BiggerFontTooltip
              text={`${priceCurrency} ${addCommas(
                parseInt(params.row.price, 10)
              )}`}
            />
          ),
        },
        {
          ...standardColumn,
          field: PreInventoryColumnField.MILEAGE,
          headerName: text.table.mileage,
          flex: 1,
          sortable: true,
          hide: hiddenField('mileage'),
          renderCell: (params: GridRenderCellParams) => (
            <BiggerFontTooltip
              text={`${addCommas(params.row.mileage)} ${text.miles}`}
            />
          ),
        },
        {
          ...dateColumn,
          hide: hiddenField('createdAt'),
          field: PreInventoryColumnField.CREATED_AT,
          headerName: text.table.createdAt,
        },
        {
          ...standardColumn,
          field: PreInventoryColumnField.CERTIFIED,
          headerName: text.table.certified,
          hide: !admin || hiddenField('certified'),
          flex: 1,
          sortable: true,
          renderCell: (params: GridRenderCellParams) => (
            <CertifiedTag certified={params.row.certified} />
          ),
        },
        { ...statusColumn, hide: hiddenField('status') },
        { ...actionsColumn, hide: hiddenField('actions') },
      ]
    },
    [
      selectedLanguage,
      text,
      handleEditInventory,
      admin,
      providers,
      priceCurrency,
    ]
  )

  const [columns, setColumns] = useState<GridColDef[]>(
    createColumns(statusList)
  )

  const handleSelectItem = (valueInput: string) => {
    setSelectedFields((prevFields) => {
      if (prevFields.includes(valueInput)) {
        return prevFields.filter((field) => field !== valueInput)
      }
      return [...prevFields, valueInput]
    })
  }

  const handleResetDefault = () => {
    setSelectedFields(defaultFields)
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = defaultFields.some(
          (defaultField) => column.field === defaultField
        )
        return { ...column, hide: !fieldExist }
      })
    )
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangePageSize = (event: SelectChangeEvent<number>) => {
    setPageSize(event.target.value as number)
  }

  const handleChangeDateRange = (newDateRange: CalendarRangeType) => {
    setDateRange(newDateRange)
    setPage(1)
  }

  const handleFieldEntrySelect = (field: string) => {
    switch (field) {
      case 'curboSpot':
        if (!curboSpotsCalled) {
          getCurboSpots()
        }
        break
      case 'brand':
        if (!brandsCalled) {
          getBrands()
        }
        break
      case 'carModel':
        if (!modelsCalled) {
          getModels()
        }
        break
      case 'exteriorColor':
        if (!colorsCalled) {
          getColors()
        }
        break
      case 'bodyStyle':
        if (!bodyStylesCalled) {
          getBodyStyles()
        }
        break
      case 'countryVersion':
        if (!countryVersionCalled) {
          getCountryVersions()
        }
        break
      case 'fuelType':
        if (!fuelTypesCalled) {
          getFuelTypes()
        }
        break
      case 'transmission':
        if (!transmissionsCalled) {
          getTransmissions()
        }
        break
      case 'driveTrain':
        if (!driveTrainsCalled) {
          getDriveTrains()
        }
        break
      case 'dealer':
        if (!dealersCalled) {
          getDealers()
        }
        break
      case 'categories':
        if (!categoriesCalled) {
          getCategories()
        }
        break
      case 'features':
        if (!featuresCalled) {
          getFeatures()
        }
        break
      default:
        break
    }
  }

  const submitSearchValue = () => {
    setPage(1)
    const newValue = checkSearchEmptiness(searchInput)
    setSearchValue(newValue)
  }

  const filtersLoading =
    curboSpotsLoading ||
    brandsLoading ||
    modelsLoading ||
    colorsLoading ||
    bodyStylesLoading ||
    countryVersionLoading ||
    fuelTypesLoading ||
    transmissionsLoading ||
    driveTrainsLoading ||
    dealersLoading ||
    categoriesLoading ||
    featuresLoading

  useEffect(() => {
    if (statusResponse?.data) {
      const formattedStatuses = statusResponse.data.map((status) => ({
        ...status,
        slug: status.slug.toLowerCase().replace('_', '-'),
      }))
      // Set columns but only change status header
      setColumns(createColumns(formattedStatuses, selectedFields))
      setStatusList(formattedStatuses)
    }
  }, [statusResponse, createColumns, selectedFields])

  useEffect(() => {
    if (location && location.hash === packagingHash) {
      setTabValue(1)
    } else {
      setTabValue(0)
    }
  }, [location])

  return (
    <Box width="100%">
      <Title
        title={text.title}
        subtitle={`${inventoryQuantity} ${
          tabValue === 0 ? text.description : text.descriptionPackaging
        }`}
        icon={<PlaylistAddCheckIcon />}
        handleTabChange={handleTabChange}
        tabs={admin ? tabs : tabs.slice(0, -1)}
        tabValue={tabValue}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="right"
        marginTop="2rem"
        width="100%"
      >
        <Box display="flex">
          <Box marginRight="1rem" width="450px">
            <StyledTextField
              placeholder={text.searchPlaceholder}
              fullWidth
              value={searchInput}
              onChange={handleSearchChange}
              submitFunction={submitSearchValue}
              name="search"
            />
          </Box>
          <Box marginRight="1rem">
            <FilterByCriteria
              filtersList={filtersList}
              handleFiltersList={handleFiltersList}
              filterTypes={{
                year: 'number',
              }}
              file={textFiles.PRE_INVENTORY_LISTING}
              filterInput={filterInput}
              curboSpots={curboSpots}
              staticFields={preInventoryStaticFields}
              handleFieldEntrySelect={handleFieldEntrySelect}
              loadingSelect={filtersLoading}
              options={{
                exteriorColor: colorsList,
                carModel: modelsList,
                brand: brandsList,
                bodyStyle: bodyStyleList,
                countryVersion: countryVersionList,
                fuelType: fuelTypeList,
                transmission: transmissionList,
                driveTrain: driveTrainList,
                dealer: dealerList,
                categories: categoryList,
                features: featuresList,
                certified: vehicleTypes,
                provider: providersList,
              }}
              // Still not working, no status shown in backend
              statusList={statusList}
            />
          </Box>
          <Box marginRight="1rem">
            <FieldFilter
              handleSelectItem={handleSelectItem}
              items={fieldSelectItems}
              selectedValues={selectedFields}
              handleResetDefault={handleResetDefault}
            />
          </Box>
          <Box>
            <DateRangeFilter
              dateRange={dateRange}
              handleChangeDateRange={handleChangeDateRange}
              title={text.dateRangeTitle}
              maxDate={null}
            />
          </Box>
        </Box>
      </Box>
      <Table
        searchValue={searchValue}
        rowHeight={110}
        columns={columns}
        dateRange={dateRange}
        data={inventoryData}
        currentPage={page}
        onPageChange={handleChangePage}
        onSelectChange={handleChangePageSize}
        pageSize={pageSize}
        selectItems={selectItems}
        pageCount={getPageCount()}
        loading={carsLoading || statusLoading}
        route={routes.INVENTORY_DETAIL}
        filtersList={filtersList}
        deletedItemId={deleteItem}
        setDeleteItemId={setDeleteItem}
        fields={selectedFields}
        fieldLabels={fieldSelectLabels}
        file={textFiles.PRE_INVENTORY_LISTING}
        filtersFile={textFiles.PRE_INVENTORY_LISTING}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
        hideDownloadModal
        checkboxSelection={false}
        // checkboxSelection={admin}
      />
    </Box>
  )
}

export default PreInventoryListingPage
