import { styled } from '@mui/material'

import Button from 'components/Common/Button'
import ErrorMessage from 'components/Common/ErrorMessage'

const StyledErrorMessage = styled(ErrorMessage)({
  alignSelf: 'flex-start',
  position: 'absolute',
})

const StyledSendButton = styled(Button)({
  width: '531px',
})

export { StyledErrorMessage, StyledSendButton }
