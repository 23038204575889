import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'

import Avatar from 'components/Common/Avatar'
import Collapse from 'components/Common/Collapse'

import { routes } from 'constants/routes'
// import { placeholderNotifications } from 'constants/sidebar'
import useSetting from 'hooks/useSetting'
import useUser from 'hooks/useUser'
import { UserRoles } from 'models/role'

import Logo from 'images/LogoCurbo.svg'

import { colors } from 'styles/theme'

import Flag from '../CountryMenu/Flag'

// import NotificationsWidget from './NotificationsWidget'
import SettingsWidget from './SettingsWidget'
import {
  Category,
  StyledBadge,
  StyledDrawer,
  StyledImage,
  UserName,
  UserRole,
  UserSection,
  UserText,
} from './styles'

export type SidebarItem = {
  // Icon to be rendered with the item
  icon?: React.ReactNode
  name: string
  key: string
  children?: SidebarItem[]
  onClick?: () => void
  /**
   *  url to push the user,
   * */
  url?: string
  /*
   * Array which includes the allowed roles to see this item
   */
  allowedRoles?: UserRoles[]

  hideInProduction?: boolean
}

type SidebarProps = {
  items: SidebarItem[]
}

const Sidebar: React.FC<SidebarProps> = ({ items }: SidebarProps) => {
  const { user } = useUser()
  const location = useLocation()
  const currentUrlPage = location.pathname

  const [currentCountry] = useSetting()
  const [activeIndexes, setActiveIndexes] = useState<number[]>([])

  const handleListItemClick = (index: number) => {
    if (activeIndexes.includes(index)) {
      setActiveIndexes(activeIndexes.filter((curIndex) => curIndex !== index))
    } else {
      setActiveIndexes((prevIndexes) => [...prevIndexes, index])
    }
  }

  useEffect(() => {
    const foundKey = items.find(
      (item) =>
        currentUrlPage ===
        item.children?.find((child) => child.url === currentUrlPage)?.url
    )?.key
    if (foundKey) {
      const tempselectedKey =
        foundKey ||
        (currentUrlPage.includes('car-settings') && items[1].key) ||
        (currentUrlPage.includes('inspections') && items[0].key) ||
        (currentUrlPage.includes('inspectors') && items[0].key) ||
        (currentUrlPage.includes('operations') && items[1].key) ||
        (currentUrlPage.includes('inventory') && items[3].key)

      const tempitemsSelectedIndex = items.findIndex(
        (item) => item.key === tempselectedKey
      )
      setActiveIndexes([tempitemsSelectedIndex])
    }
  }, [currentUrlPage, items])

  return (
    <StyledDrawer variant="permanent">
      <Link to={routes.HOME} style={{ textDecoration: 'none' }}>
        <StyledImage alt="Curbo Logo" src={Logo} />
      </Link>
      <List
        sx={{
          width: '100%',
          overflow: 'hidden auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          bgcolor: colors.commonWhite,
          padding: 0,
          textAlign: 'center',
          [`&::-webkit-scrollbar`]: {
            width: 0,
            height: 0,
          },
        }}
      >
        {items.map((item, index) => {
          const { children, name, key } = item
          return (
            <React.Fragment key={key}>
              <ListItemButton
                disableRipple
                onClick={() => handleListItemClick(index)}
                data-cy={`${item.key}-sidebar-item`}
              >
                <Category>{name}</Category>
                {children && children.length > 0 ? (
                  <>
                    {activeIndexes.includes(index) ? (
                      <ExpandLess sx={{ color: colors.gray }} />
                    ) : (
                      <ExpandMore sx={{ color: colors.gray }} />
                    )}
                  </>
                ) : null}
              </ListItemButton>
              {children && children.length > 0 ? (
                <Collapse
                  expand={activeIndexes.includes(index)}
                  items={children.map((child) => {
                    return { ...child, selected: activeIndexes.includes(index) }
                  })}
                  highlightOnUrl
                />
              ) : null}
            </React.Fragment>
          )
        })}
      </List>
      <UserSection>
        <StyledBadge
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            currentCountry ? <Flag image={currentCountry.flag} /> : null
          }
        >
          <Avatar
            image={user?.profilePicture}
            alt="User Image placeholder"
            variant="circular"
            height={68}
            width={68}
          />
        </StyledBadge>
        <UserText>
          <UserName>
            {user?.name} {user?.lastName}
          </UserName>
          <UserRole>{user?.roles[0].name}</UserRole>
        </UserText>
        <SettingsWidget />
        {/* <NotificationsWidget notifications={placeholderNotifications} /> */}
      </UserSection>
    </StyledDrawer>
  )
}

export default Sidebar
