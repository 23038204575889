import { Card, styled, Typography } from '@mui/material'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledDescription = styled(Typography)({
  color: colors.gray,
})

export const TimeStamp = styled(Typography)({
  color: colors.black,
  fontWeight: FONT_WEIGHT.BOLD,
})

export const ScrollCard = styled(Card)({
  scrollbarWidth: 'thin',
  overflowY: 'scroll',
  '& ul': { padding: 0 },
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: colors.blue,
    borderRadius: '5px',
  },
  borderRadius: '10px',
  border: `1px solid ${colors.lightGray}`,
  overflow: 'auto',
})
