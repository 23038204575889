import React from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'

import { Question } from 'models/services/inspection/detail'

import { colors } from 'styles/theme'

export const selectColor = (score: [number, number]) => {
  const percentage = Math.floor((score[0] / score[1]) * 100)
  let returnColor = colors.green
  if (percentage < 80) returnColor = colors.yellow
  if (percentage < 50) returnColor = colors.lightRed

  return returnColor
}

export const selectQuestionColor = (question: Question) => {
  const { answer } = question
  if (answer) {
    if (answer.doesntApply) return colors.yellow

    if (answer.correctAnswer) return colors.green
    return colors.lightRed
  }

  return colors.green
}

export const CheckpointIcon = (
  score: [number, number],
  selectedColor: string
) => {
  const [numerator, denominator] = score
  const percentage = Math.floor((numerator / denominator) * 100)
  let returnIcon = (
    <CheckCircleIcon sx={{ color: selectedColor, marginRight: '0.25rem' }} />
  )

  if (percentage < 80)
    returnIcon = (
      <RemoveCircleIcon sx={{ color: selectedColor, marginRight: '0.25rem' }} />
    )
  if (percentage < 50)
    returnIcon = (
      <CancelIcon sx={{ color: selectedColor, marginRight: '0.25rem' }} />
    )
  return returnIcon
}
