import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'

import { BackContainer } from 'components/Common/BackContainer'
import BodyContainerWithHeader from 'components/Common/BodyContainerWithHeader'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { CAR_SETTINGS_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import { Model } from 'models/car'
import { ModelCreationModel } from 'models/modelCreation'
import { Option } from 'models/Select'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { CreateModelInputType } from 'models/services/CarSettings/creation'
import { PublicationStatus } from 'models/status'
import {
  createModelStepperItems,
  MODEL_CREATION_STEPS as steps,
} from 'utils/CarSettings/Model/creation'
import { validateGraphQLErrorCode } from 'utils/error'

import { CREATE_MODEL } from 'graphQL/Operations/Model/Creation/mutations'
import { GET_BRANDS } from 'graphQL/Operations/Model/Creation/queries'

import { StyledBox } from 'styles/operation/creation'

const ModelCreation = () => {
  const { text } = useTranslation(textFiles.MODEL_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [brandOptions, setBrandOptions] = useState<Option[]>([])

  const history = useHistory()
  const { show } = useNotification()

  const {
    stepper: { firstStep, secondStep },
    title,
    processDescription,
  } = text

  const initialModelInformationState: Model = {
    id: '',
    brandName: '',
    name: '',
    picture: '',
    createdAt: new Date(),
    status: PublicationStatus.PUBLISHED,
  }

  const initialData: ModelCreationModel = {
    modelInformation: initialModelInformationState,
  }

  const [modelData, setModelData] = useState<ModelCreationModel>(initialData)
  const { modelInformation } = modelData

  const modelStepperText = {
    firstStep,
    secondStep,
  }
  const stepperItems = createModelStepperItems(modelStepperText)

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }

  const updateModelData = (newModel: ModelCreationModel) => {
    setModelData(newModel)
  }

  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const [createModel, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateModelInputType>
  >(CREATE_MODEL, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
      history.push(CAR_SETTINGS_SUB_ROUTES.MODEL_LISTING)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
        })
    },
  })

  useQuery<GenericData<Option[]>>(GET_BRANDS, {
    onCompleted(response) {
      setBrandOptions(response.data)
      setModelData((prevState) => {
        const newState = { ...prevState }
        const newModel = {
          ...newState.modelInformation,
          brandName: response.data[0].value as string,
        }
        newState.modelInformation = newModel
        return newState
      })
    },
  })

  const handleSubmit = useCallback(() => {
    createModel({
      variables: {
        input: {
          brand: modelInformation.brandName,
          name: modelInformation.name,
          status: modelInformation.status
            ? PublicationStatus.PUBLISHED
            : PublicationStatus.UNPUBLISHED,
        },
      },
    })
  }, [modelInformation, createModel])

  return (
    <StyledBox>
      <BackContainer />
      <BodyContainerWithHeader
        title={title}
        subtitle={processDescription}
        stepperItems={stepperItems}
        currentStep={currentStep}
      >
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          handleSubmit,
          modelData,
          submitLoading,
          brands: brandOptions,
          updateModelData,
        })}
      </BodyContainerWithHeader>
    </StyledBox>
  )
}

export default ModelCreation
