import React, { useState } from 'react'

import NameContainer from 'components/CarSettings/Common/Detail/NameContainer'
import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'
import {
  GridBox,
  StyledContainer,
} from 'components/CarSettings/Trim/Detail/Settings/style'
import Box from 'components/Common/Box'

import { textFiles } from 'constants/textFiles'
import { getTrimSpacing } from 'constants/trim'
import useTranslation from 'hooks/useTranslation'
import { NameInformation } from 'models/car'
import { TrimDetailDataType } from 'models/services/CarSettings/detail'

type Props = {
  information: TrimDetailDataType
  handleSave: (data: TrimDetailDataType) => Promise<boolean>
  submitLoading: boolean
}

const Spacing = ({ information, handleSave, submitLoading }: Props) => {
  const { text } = useTranslation(textFiles.TRIM_DETAIL)
  const { spacing: translation } = text

  const initialNameInformation = getTrimSpacing(
    translation.metering,
    translation.measurement,
    information
  )

  const [spacing, setSpacing] = useState<NameInformation[]>(
    initialNameInformation.spacing
  )
  const [measurement, setMeasurement] = useState<NameInformation[]>(
    initialNameInformation.measurement
  )
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)

  const resetState = () => {
    setSpacing(initialNameInformation.spacing)
    setMeasurement(initialNameInformation.measurement)
    setHasUnsavedChanges(false)
  }

  const handleChangeSpacing = (newInformation: NameInformation[]) => {
    setSpacing(newInformation)
    setHasUnsavedChanges(true)
  }

  const handleChangeMeasurement = (newInformation: NameInformation[]) => {
    setMeasurement(newInformation)
    setHasUnsavedChanges(true)
  }

  const handleSaveChanges = async () => {
    const [
      frontLegRoom,
      backLegRoom,
      frontHeadRoom,
      backHeadRoom,
      cargoCapacity,
      cargo,
    ] = (spacing as Array<{ name: number }>).map((space) => space.name)
    const [length, height, width, doors, seats, engineDisplacement] = (
      measurement as Array<{ name: number }>
    ).map((measure) => measure.name)
    const isUpdateSuccesful = await handleSave({
      ...information,
      frontLegRoom,
      backLegRoom,
      frontHeadRoom,
      backHeadRoom,
      cargoCapacity,
      cargo,
      length,
      height,
      width,
      doors,
      seats,
      engineDisplacement,
    })
    if (isUpdateSuccesful) {
      setHasUnsavedChanges(false)
    }
  }

  return (
    <StyledContainer>
      <GridBox>
        <NameContainer
          title={translation.metering.title}
          description={translation.metering.description}
          containerSx={{
            padding: '0px !important',
            paddingTop: '1rem !important',
            maxWidth: '399px',
          }}
          information={spacing}
          handleSetInformation={handleChangeSpacing}
          loading={submitLoading}
        />
        <NameContainer
          title={translation.measurement.title}
          description={translation.measurement.description}
          height="540px"
          containerSx={{
            padding: '0px !important',
            paddingTop: '1rem !important',
            maxWidth: '399px',
          }}
          information={measurement}
          handleSetInformation={handleChangeMeasurement}
          loading={submitLoading}
        />
      </GridBox>
      <Box paddingTop="1rem">
        <SaveChangesBlock
          handleSaveChanges={handleSaveChanges}
          resetState={resetState}
          submitLoading={submitLoading}
          shouldRender={hasUnsavedChanges}
        />
      </Box>
    </StyledContainer>
  )
}

export default Spacing
