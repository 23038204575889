import React from 'react'
import NumberFormat from 'react-number-format'

export const maxValue = 10000
export const minValue = 1

interface CustomProps {
  onChange: (event: {
    target: { name: string; value: number | undefined }
  }) => void
  name: string
}

const NumberValueInput = React.forwardRef<NumberFormat<string>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.floatValue,
            },
          })
        }}
        isNumericString
        allowLeadingZeros={false}
        allowNegative={false}
        thousandSeparator
        max={maxValue}
        min={minValue}
      />
    )
  }
)

export default NumberValueInput
