import SquareIcon from '@mui/icons-material/Square'
import { CSSObject, Divider, styled, Typography } from '@mui/material'

import Button from 'components/Common/Button'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledDivider = styled(Divider)({
  textAlign: 'left',
  '&::before': {
    width: 0,
  },
  span: {
    paddingLeft: 0,
  },
  width: 'inherit',
})

export const RoleTitle = styled(Typography)({
  fontWeight: FONT_WEIGHT.BOLD,
  alignSelf: 'center',
  color: colors.gray,
})

export const StyledSquareIcon = styled(SquareIcon)({
  color: colors.blue,
  borderRadius: '50px',
  marginRight: '8px',
  fontSize: 12,
})

export const AddNewRoleButton = styled(Button)({
  marginTop: '20px',
  width: 'fit-content',
  height: 'fit-content !important',
  paddingLeft: '0px !important',
})

export const flexStyle: CSSObject = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}

export const boxStyle: CSSObject = {
  height: '25px',
  border: 'none',
  borderRadius: '0px',
  borderBottom: `2px solid ${colors.gray}`,
  // hover does nothing
  '&:hover': {
    border: 'none',
    borderBottom: `2px solid ${colors.gray}`,
  },
}
