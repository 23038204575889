import React, { FunctionComponent, useEffect, useState } from 'react'
import { InputBaseComponentProps, Typography } from '@mui/material'

import Box from 'components/Common/Box'
import Checkbox from 'components/Common/Checkbox'
import ErrorMessage from 'components/Common/ErrorMessage'

import { DATAGE_LIMIT } from 'constants/date'
import { textFiles } from 'constants/textFiles'
import { NumberFormatCustom } from 'constants/trim'
import useTranslation from 'hooks/useTranslation'
import { DateRange } from 'models/trim'
import { isIncluded } from 'utils/CarSettings/Trim/creation'

import { colors, FONT_WEIGHT } from 'styles/theme'

import {
  StyledContainer,
  StyledTextField,
  StyledTextFieldContainer,
} from './style'

type Props = {
  data: number[][]
  errorMessage: string
  handleSetRange: React.Dispatch<React.SetStateAction<number[]>>
  handleSetError: React.Dispatch<React.SetStateAction<string>>
}

const TimePicker = ({
  data,
  errorMessage,
  handleSetError,
  handleSetRange,
}: Props) => {
  const LIMIT = Number(DATAGE_LIMIT)

  const [singleYear, setSingleYear] = useState<string>('')
  const [range, setRange] = useState<DateRange>({ from: '', to: '' })
  const [isSingle, setIsSingle] = useState<boolean>(false)

  const { text: translation } = useTranslation(textFiles.TRIM_CREATION)
  const {
    information: { timePicker },
  } = translation

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSingle(event.target.checked)
    handleSetError('')
    handleSetRange([])
    setSingleYear('')
    setRange({ from: '', to: '' })
  }

  useEffect(() => {
    if (!isSingle) {
      if (range.from.length === 4 && range.to.length === 4) {
        const nFrom = Number(range.from)
        const nTo = Number(range.to)
        if (!data.some((item) => isIncluded(item, [nFrom, nTo])))
          if (nFrom < nTo && nTo < LIMIT) {
            handleSetRange((prevState) => {
              const newState = [...prevState]
              newState.splice(0, 1, nFrom)
              newState.splice(1, 1, nTo)
              return newState
            })
            handleSetError('')
          } else handleSetError(timePicker.invalid)
        else handleSetError(timePicker.included)
      }
    } else if (singleYear.length === 4) {
      const single = Number(singleYear)
      if (single < LIMIT) {
        if (!data.some((item) => isIncluded(item, [single]))) {
          handleSetRange((prevState) => {
            const newState = [...prevState]
            newState.splice(0, 1, single)
            return newState
          })
          handleSetError('')
        } else handleSetError(timePicker.included)
      } else handleSetError(timePicker.greaterThan.replace('%d', LIMIT))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, singleYear])

  return (
    <StyledContainer>
      <Box>
        {isSingle ? (
          <StyledTextFieldContainer
            title={timePicker.year}
            display="flex"
            justifyContent="space-between"
            width="230px"
            marginLeft="20px"
            marginTop="10px"
          >
            <StyledTextField
              variant="filled"
              name="year"
              value={singleYear}
              onChange={(e) => setSingleYear(e.target.value)}
              placeholder={`${LIMIT - 1}`}
              InputProps={{
                inputComponent:
                  NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
              }}
            />
          </StyledTextFieldContainer>
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            width="230px"
            marginLeft="20px"
            marginTop="10px"
          >
            <StyledTextFieldContainer title={timePicker.from}>
              <StyledTextField
                variant="filled"
                name="from"
                value={range.from}
                onChange={(e) =>
                  setRange({ ...range, from: e.target.value as string })
                }
                placeholder="1900"
                InputProps={{
                  inputComponent:
                    NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
              />
            </StyledTextFieldContainer>
            <Box display="flex" alignItems="flex-end" paddingBottom="5px">
              <Typography
                variant="h5"
                fontWeight={FONT_WEIGHT.BOLD}
                color={colors.blue}
              >
                -
              </Typography>
            </Box>
            <StyledTextFieldContainer title={timePicker.to}>
              <StyledTextField
                variant="filled"
                name="to"
                value={range.to}
                onChange={(e) =>
                  setRange({ ...range, to: e.target.value as string })
                }
                placeholder={`${LIMIT - 1}`}
                InputProps={{
                  inputComponent:
                    NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
              />
            </StyledTextFieldContainer>
          </Box>
        )}
        {errorMessage.length > 0 && (
          <ErrorMessage
            sx={{
              alignSelf: 'flex-start',
              position: 'static',
              marginTop: '0.25rem',
              marginLeft: '20px',
            }}
            text={errorMessage}
          />
        )}
      </Box>
      <Box width="300px" marginLeft="20px">
        <Checkbox
          checked={isSingle}
          label={timePicker.singleYear}
          onChange={handleChange}
          size="small"
        />
      </Box>
    </StyledContainer>
  )
}

export default TimePicker
