type CreateCheckpointInput = {
  label: string
  description: string
  failureReason: string | number
  questions: CreateQuestionInput[]
}

type CreateStepInput = {
  label: string
  checkpoints: CreateCheckpointInput[]
}

type CreateQuestionInput = {
  label: string
  answers: string[]
  isPictureRequired: boolean
  isRequired: boolean
  value: number
}

export enum TemplateStatus {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
}

export type CreateTemplateInputType = {
  name: string
  provider?: string
  status: TemplateStatus
  steps: CreateStepInput[]
}
