import { gql } from '@apollo/client'

export const UPDATE_MODEL = gql`
  mutation updateModel($input: UpdateModelInput!) {
    data: updateModel(input: $input) {
      id
      slug
    }
  }
`

export const PUBLISH_MODEL = gql`
  mutation publishModel($input: ID!) {
    data: publishModel(id: $input) {
      id
    }
  }
`

export const UNPUBLISH_MODEL = gql`
  mutation unpublishModel($input: ID!) {
    data: unpublishModel(id: $input) {
      id
    }
  }
`
