import { CityOption } from 'pages/Operation/Dealer/Creation'

import { StepperModel } from 'components/General/Stepper'
import DealerInfo from 'components/Operations/Dealer/Creation/DealerInfo'
import DealerLocation from 'components/Operations/Dealer/Creation/DealerLocation'
import DealerSummary from 'components/Operations/Dealer/Creation/DealerSummary'

import { DealerCreationAction, DealerCreationModel } from 'models/dealer'
import { ThreeStepperTextType } from 'models/text/General'

export type DealerCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  dealerData: DealerCreationModel
  updateDealerData: (action: DealerCreationAction) => void
  handleSubmit: () => void
  submitLoading: boolean
  cities: CityOption[]
}

export const DEALER_CREATION_STEPS: Array<React.FC<DealerCreationProps>> = [
  DealerInfo,
  DealerLocation,
  DealerSummary,
]

export const createStateStepperItems = (
  text: ThreeStepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep,
    },
    { label: text.secondStep },
    { label: text.thirdStep },
  ]

  return stepperItems
}
