import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ErrorIcon from '@mui/icons-material/Error'
import { Typography } from '@mui/material'

import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import Modal from 'components/Common/Modal'
import {
  BottomContainer,
  StyledBox,
} from 'components/Inventory/Detail/ErrorModal/style'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { PictureOption } from 'models/trim'
import { BasicInformationModel } from 'models/trimCreation'

import { colors, FONT_WEIGHT } from 'styles/theme'

export type InventoryErrorModalProps = {
  open: boolean
  onConfirmHandler: () => void
  trim: BasicInformationModel
  brands: PictureOption[]
  errorMessage: string
}

const InventoryErrorModal = ({
  open,
  onConfirmHandler,
  trim,
  brands,
  errorMessage,
}: InventoryErrorModalProps) => {
  const { text } = useTranslation(textFiles.TRIM_CREATION)
  const {
    modal: { header },
    information: { trim: trimLabel, brand, model, datage },
  } = text

  const renderBrand = (ops: PictureOption[], value: string): string => {
    return ops.find((op) => op.value === value)!.name
  }

  const renderModel = (
    brds: PictureOption[],
    myBrand: string,
    myModel: string
  ): string => {
    const currentBrand = brds.find((brd) => brd.value === myBrand)
    return currentBrand!.models.find((mod) => mod.value === myModel)!.name
  }

  const renderTrims = () => {
    const stringYear = errorMessage.split('Value: ')[1].split(',')
    return stringYear
  }

  return open && errorMessage.length > 0 ? (
    <Modal
      open={open}
      size="customDimension"
      customDimension={{
        width: 562,
        height: 385,
      }}
    >
      <StyledBox>
        <ErrorIcon />
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          width="90%"
          marginTop="1rem"
        >
          <Typography
            color={colors.black}
            fontWeight={FONT_WEIGHT.SEMI_BOLD}
            variant="body1"
            width="348px"
            textAlign="center"
          >
            {header}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          maxWidth="90%"
          minWidth="50%"
          marginTop="1rem"
        >
          <Typography
            color={colors.black}
            fontWeight={FONT_WEIGHT.SEMI_BOLD}
            variant="body2"
          >
            {trimLabel}: {trim.trim}
          </Typography>
          <Typography
            color={colors.black}
            fontWeight={FONT_WEIGHT.SEMI_BOLD}
            variant="body2"
          >
            {brand}: {renderBrand(brands, trim.brand)}
          </Typography>
          <Typography
            color={colors.black}
            fontWeight={FONT_WEIGHT.SEMI_BOLD}
            variant="body2"
          >
            {model}: {renderModel(brands, trim.brand, trim.model)}
          </Typography>
          <Typography
            color={colors.black}
            fontWeight={FONT_WEIGHT.SEMI_BOLD}
            variant="body2"
          >
            {datage}:
            <Box
              sx={{
                overflowY: 'auto',
                maxHeight: '75px',
                ul: {
                  display: 'grid',
                  gridTemplateColumns: '33% 33% 33%',
                },
                li: {
                  '&::marker': {
                    color: colors.blue,
                  },
                },
              }}
            >
              <ul>
                {renderTrims().map((item) => (
                  <li key={item}>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </Box>
          </Typography>
        </Box>
        <BottomContainer>
          <Button
            size="small"
            style={{
              width: 'auto',
              minWidth: 159,
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
            }}
            onClick={onConfirmHandler}
          >
            <ArrowBackIosIcon />
            {text.errorGoBackButton}
          </Button>
        </BottomContainer>
      </StyledBox>
    </Modal>
  ) : null
}

export default InventoryErrorModal
