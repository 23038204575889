import { USER_CREATION_OPTION } from 'constants/UserManagement/creation'
import { UserCreationAction, UserCreationModel } from 'models/userCreation'

const userCreationReducer = (
  state: UserCreationModel,
  action: UserCreationAction
): UserCreationModel => {
  switch (action.type) {
    case USER_CREATION_OPTION.UPDATE_USER_INFORMATION:
      return { ...state, userInformation: action.payload }

    case USER_CREATION_OPTION.UPDATE_USER_ROLES:
      return { ...state, userRoles: action.payload }

    default:
      return state
  }
}

export default userCreationReducer
