import React from 'react'
import { Typography } from '@mui/material'

import Box from 'components/Common/Box'

import { BASE_STEP_WIDTH } from 'constants/style'

import { colors } from 'styles/theme'

import { StyledSlider } from './style'

type StepProgressBarProps = {
  currentStep: number
  totalSteps: number
  active?: boolean
  label?: string
  height?: string | number
  width?: string | number
}

const StepProgressBar = ({
  currentStep,
  totalSteps,
  active,
  label,
  height = '10px',
  width = BASE_STEP_WIDTH,
}: StepProgressBarProps) => {
  return (
    <Box>
      {label ? (
        <Typography
          color={active ? colors.blue : colors.subtitleGray}
          variant="body2"
        >
          {label}
        </Typography>
      ) : null}

      <StyledSlider
        aria-label="step-progress-bar"
        valueLabelDisplay="on"
        disabled
        value={parseFloat(((currentStep / totalSteps) * 100).toFixed(0))}
        valueLabelFormat={(x) => `${x}%`}
        max={100}
        sx={{
          width,
          height,
        }}
      />
    </Box>
  )
}

export default StepProgressBar
