import { Option } from 'models/Select'

export enum BrandColumnField {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  ACTIONS = 'actions',
  PICTURE = 'picture',
}

export const brandCountry: Option[] = [
  {
    name: 'USA',
    value: 'USA',
  },
  {
    name: 'Germany',
    value: 'Germany',
  },
  {
    name: 'Japan',
    value: 'Japan',
  },
]

export enum BRAND_CREATION_OPTION {
  UPDATE_BRAND_INFORMATION = 'updateBrandInformation',
}

export const DEFAULT_IMAGE_URL = {
  data: 'https://curbo-assets.nyc3.digitaloceanspaces.com/inspection/1651854141115-11dda403-2da7-462b-b7e7-4f543f0a8c04.png',
}
