import { styled } from '@mui/material'

import Container from 'components/Common/Container'

import { BREAK_POINTS } from 'styles/breakpoints'

export const StyledContainer = styled(Container)({
  width: '100%',
  [`@media only screen and (min-width: ${BREAK_POINTS.XXL}px)`]: {
    width: '44%',
  },

  '& > div:nth-of-type(2)': {
    overflowY: 'auto',
    padding: '0 1.5rem',
  },
})
