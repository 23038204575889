import { styled } from '@mui/material'

import { colors } from 'styles/theme'

export const ColorBoxContainer = styled('section')({
  width: '30%',
  height: 38,
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  div: {
    width: '60%',
    height: 38,
    border: `1px solid ${colors.lightGray}`,
    borderRadius: '4px',
  },
})
