import { gql } from '@apollo/client'

export const UPDATE_TEMPLATE = gql`
  mutation updateTemplate($input: UpdateTemplateInput!) {
    data: updateTemplate(input: $input) {
      name
      id
      createdAt
    }
  }
`
export const PUBLISH_TEMPLATE = gql`
  mutation publishTemplate($input: ID!) {
    data: publishTemplate(id: $input) {
      id
    }
  }
`
