import { SidebarItem } from 'components/General/Sidebar'

export const isHighlighted = (
  children: SidebarItem[],
  currentUrlPage: string
) => {
  const childrenMatchesUrl = children.find(
    (element) => element.url === currentUrlPage
  )
  if (childrenMatchesUrl) return true

  return false
}
