import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import CallIcon from '@mui/icons-material/Call'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MailIcon from '@mui/icons-material/Mail'
import MapIcon from '@mui/icons-material/Map'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import PersonIcon from '@mui/icons-material/Person'
import { Typography } from '@mui/material'

import AvatarItem from 'components/CarSettings/Common/Creation/Summary/AvatarItem'
import Skeleton from 'components/CarSettings/Common/Creation/Summary/Skeleton'
import Box from 'components/Common/Box'
import SummaryItem from 'components/Common/SummaryItem'
import Map from 'components/General/Map'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Address } from 'models/map'
import { DealerCreationProps } from 'utils/Dealer/creation'

import { elementStyle } from 'styles/creation'
import { colors, FONT_WEIGHT } from 'styles/theme'

import { Divider, RepresentativeNumberBox } from '../DealerInfo/style'

const DealerSummary = ({
  dealerData,
  handleBack,
  submitLoading,
  handleSubmit,
  cities,
}: DealerCreationProps) => {
  const { dealerInformation, dealerLocation } = dealerData
  const { text } = useTranslation(textFiles.DEALER_CREATION)
  const {
    information: {
      dealerName,
      dealerPhone,
      cityName,
      stateName,
      representativeName,
      representativePhone,
      representativeEmail,
      thumbnail,
      representativeNumber,
    },
    summary: { addressLabel, title, description, status, map },
  } = text

  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const [, setApiLoaded] = useState<boolean>(false)
  const [address, setAddress] = useState<Address | undefined>(
    dealerLocation.address
  )

  const cityOption = cities.find(
    (city) => city.value === dealerInformation.city
  )
  const city = cityOption ? cityOption.name : dealerInformation.city

  return (
    <Skeleton
      previousFunction={handleBack}
      title={title}
      description={description}
      nextFunction={handleSubmit}
      submitLoading={submitLoading}
      gridContainerStyles={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
      containerStyles={{ height: '100%' }}
      formStyles={{
        height: '100%',
      }}
      bodyStyles={{
        height: '100%',
        overflowY: 'inherit',
      }}
    >
      <SummaryItem
        title={dealerName}
        text={dealerInformation.name}
        icon={<DirectionsCarIcon />}
        width={200}
        elementStyle={elementStyle}
      />

      <SummaryItem
        title={stateName}
        text={dealerInformation.state}
        icon={<MapIcon />}
        width={200}
        elementStyle={elementStyle}
      />

      <SummaryItem
        title={cityName}
        text={city}
        icon={<MapOutlinedIcon />}
        width={200}
        elementStyle={elementStyle}
      />

      <SummaryItem
        title={status}
        text={generalText.status.active}
        icon={<CheckCircleIcon />}
        width={200}
        elementStyle={elementStyle}
      />

      <SummaryItem
        title={addressLabel}
        text={address ? address.address : ''}
        icon={<LocationOnIcon />}
        width={200}
        elementStyle={elementStyle}
      />

      <SummaryItem
        title={dealerPhone}
        text={
          <NumberFormat
            value={dealerInformation.phoneNumber}
            format="+1 (###) ### ####"
            isNumericString
            displayType="text"
          />
        }
        icon={<CallIcon />}
        width={200}
        elementStyle={elementStyle}
      />

      {dealerInformation.picture ? (
        <AvatarItem
          title={thumbnail}
          picture={dealerInformation.picture}
          width={200}
          containerStyles={{
            gridColumnStart: 3,
            gridRowStart: 2,
            gridRowEnd: 4,
            marginBottom: '1em',
          }}
          imageHeight={145}
          imageWidth={145}
        />
      ) : null}

      {dealerInformation.dealerContacts.map((dealerContact, index) => (
        // disabled because these fields are static and dinamically created
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          sx={{
            display: 'flex',
            width: ' 100%',
            flexDirection: 'column',
          }}
        >
          <RepresentativeNumberBox>
            <Typography
              color={colors.altBlack}
              fontWeight={FONT_WEIGHT.MEDIUM}
              variant="subtitle1"
            >
              {`#${index + 1} ${representativeNumber}`}
            </Typography>
            <Divider />
          </RepresentativeNumberBox>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
            }}
          >
            <SummaryItem
              title={representativePhone}
              icon={<CallIcon />}
              width={200}
              elementStyle={elementStyle}
              text={
                <NumberFormat
                  value={dealerContact.telephoneNumber}
                  format="+1 (###) ### ####"
                  isNumericString
                  displayType="text"
                />
              }
            />
            <SummaryItem
              title={representativeName}
              text={dealerContact.agent}
              icon={<PersonIcon />}
              width={200}
              elementStyle={elementStyle}
            />
            <SummaryItem
              title={representativeEmail}
              text={dealerContact.email || 'N/A'}
              icon={<MailIcon />}
              width={200}
              elementStyle={{ ...elementStyle, textTransform: 'none' }}
            />
          </Box>
        </Box>
      ))}

      <Box width="100%" height="353px" sx={{ marginBottom: '5rem' }}>
        <Typography variant="body1" marginBottom="12px">
          {map}
        </Typography>
        <Map
          disabled
          setIsLoaded={setApiLoaded}
          curboSpots={[]}
          address={address}
          setAddress={setAddress}
          circleCenter={
            address ? { lat: address.lat, lng: address.lng } : undefined
          }
        />
      </Box>
    </Skeleton>
  )
}

export default DealerSummary
