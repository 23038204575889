import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'
import Box from 'components/Common/Box'
import LoadingAnimation from 'components/Common/LoadingAnimation'

import { ENTITY_NOT_FOUND_ERROR } from 'constants/error'
import {
  arrayOfNamedDays,
  emptyCheckedWeekCalendar,
} from 'constants/Operation/outOfSpot'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import {
  CheckedWeekCalendar,
  CheckHourType,
  DaysPair,
  HoursPair,
  OutOfSpotModel,
  ScheduleType,
} from 'models/outOfSpot'
import { FilterInputVariable, GenericData } from 'models/services/base'
import { HourTimeType } from 'models/services/curboSpot'
import { CurboSpotDetailDataType } from 'models/services/operations/curboSpot'

import {
  GET_INSPECTION_HOURS,
  GET_TEST_DRIVE_HOURS,
} from 'graphQL/Operations/OutOfSpot/queries'

import ConfigurationItem from './ConfigurationItem'
import { VehicleBox } from './style'

type Props = {
  information: CurboSpotDetailDataType
  schedules: OutOfSpotModel
  configurationLoading: Record<string, boolean>
  handleSave: (spotData: CurboSpotDetailDataType) => Promise<boolean>
  updateSchedules: (newSchedules: HoursPair) => Promise<boolean>
}

export type ToggleType = {
  support: boolean
  offSiteSupport: boolean
}

const Configuration = ({
  information,
  schedules,
  configurationLoading,
  handleSave,
  updateSchedules,
}: Props) => {
  const {
    supportsTestDrive,
    supportsInspection,
    supportsOffSiteTestDrive,
    supportsOffSiteInspection,
  } = information

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)

  const [toggleInformation, setToggleInformation] = useState<
    Record<ScheduleType, ToggleType>
  >({
    testDrive: {
      support: supportsTestDrive,
      offSiteSupport: supportsOffSiteTestDrive,
    },
    inspections: {
      support: supportsInspection,
      offSiteSupport: supportsOffSiteInspection,
    },
  })

  const [selectedDay, setSelectedDay] = useState<DaysPair>({
    testDrive: 'monday',
    inspections: 'monday',
  })

  const [inspectionCheckedHours, setInspectionCheckedHours] =
    useState<CheckedWeekCalendar>(emptyCheckedWeekCalendar)
  const [testDriveCheckedHours, setTestDriveCheckedHours] =
    useState<CheckedWeekCalendar>(emptyCheckedWeekCalendar)

  const { show } = useNotification()

  const { text } = useTranslation(textFiles.CURBO_SPOT_DETAIL)
  const {
    curboSpotConfiguration: { testDrive, inspection },
  } = text

  /* getCheckedWeek:
  1. We create an empty object that will hold the hours for each day of the week.
  2. We loop through the array of named days.
  3. For each day, we map the hours array to a new array of checkHourType objects.
  4. We check if the schedule has a value for the day. If it does, we set the checked property to true.
  5. If it doesn't, we set the checked property to false.
  6. We return the new object. */
  const getCheckedWeek = (hoursArray: HourTimeType[], type: ScheduleType) => {
    const weekHours = { ...emptyCheckedWeekCalendar }
    arrayOfNamedDays.forEach((day) => {
      weekHours[day] = hoursArray.map((hour) => {
        return {
          ...hour,
          checked:
            !!schedules[type][day].find(
              (foundHour) => foundHour.value === hour.value
            ) || false,
        } as CheckHourType
      })
    })
    return weekHours
  }

  const { data: inspectionHours, loading: inspectionsHoursLoading } = useQuery<
    GenericData<HourTimeType[]>,
    FilterInputVariable
  >(GET_INSPECTION_HOURS, {
    variables: {
      input: {
        sort: {
          continentalTime: 'asc',
        },
      },
    },
    onCompleted: (response) => {
      const responseHours = response.data
      setInspectionCheckedHours(getCheckedWeek(responseHours, 'inspections'))
    },
    onError: () => {
      show({
        updatedSeverity: 'error',
        message: ENTITY_NOT_FOUND_ERROR,
      })
    },
  })

  const { data: testDriveHours, loading: testDriveHoursLoading } = useQuery<
    GenericData<HourTimeType[]>,
    FilterInputVariable
  >(GET_TEST_DRIVE_HOURS, {
    variables: {
      input: {
        sort: {
          continentalTime: 'asc',
        },
      },
    },
    onCompleted: (response) => {
      const responseHours = response.data
      setTestDriveCheckedHours(getCheckedWeek(responseHours, 'testDrive'))
    },
    onError: () => {
      show({
        updatedSeverity: 'error',
        message: ENTITY_NOT_FOUND_ERROR,
      })
    },
  })

  const handleResetChanges = () => {
    setTestDriveCheckedHours(getCheckedWeek(testDriveHours!.data, 'testDrive'))
    setInspectionCheckedHours(
      getCheckedWeek(inspectionHours!.data, 'inspections')
    )
    setToggleInformation({
      testDrive: {
        support: supportsTestDrive,
        offSiteSupport: supportsOffSiteTestDrive,
      },
      inspections: {
        support: supportsInspection,
        offSiteSupport: supportsOffSiteInspection,
      },
    })
    setHasUnsavedChanges(false)
  }

  const handleUpdateSchedules = async () => {
    const { testDrive: testDriveSupport, inspections: inspectionSupport } =
      toggleInformation
    const testDriveArray = { ...emptyCheckedWeekCalendar }
    const inspectionsArray = { ...emptyCheckedWeekCalendar }
    arrayOfNamedDays.forEach((day) => {
      testDriveArray[day] = [
        ...testDriveCheckedHours[day].filter((foundHour) => foundHour.checked),
      ]
      inspectionsArray[day] = [
        ...inspectionCheckedHours[day].filter((foundHour) => foundHour.checked),
      ]
    })
    const isUpdateSuccesful = await handleSave({
      ...information,
      supportsTestDrive: testDriveSupport.support,
      supportsOffSiteTestDrive: testDriveSupport.offSiteSupport,
      supportsInspection: inspectionSupport.support,
      supportsOffSiteInspection: inspectionSupport.offSiteSupport,
    })
    const isCalendarUpdateSuccesful = await updateSchedules({
      testDrive: testDriveArray,
      inspections: inspectionsArray,
    })

    if (isUpdateSuccesful && isCalendarUpdateSuccesful) {
      setHasUnsavedChanges(false)
    }
  }

  const handleChangeCheckedHours = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: ScheduleType
  ) => {
    const selectedType =
      type === 'testDrive' ? testDriveCheckedHours : inspectionCheckedHours

    const selectedHour = selectedType[selectedDay[type]]

    const newValue = {
      ...selectedType,
      [selectedDay[type]]: selectedHour.map((hour) => {
        return {
          ...hour,
          checked: hour.value === e.target.value ? !hour.checked : hour.checked,
        }
      }),
    }

    if (type === 'testDrive') {
      setTestDriveCheckedHours(newValue)
    } else {
      setInspectionCheckedHours(newValue)
    }
    setHasUnsavedChanges(true)
  }

  const hourContainerSize =
    testDriveCheckedHours[selectedDay.testDrive].length >
    inspectionCheckedHours[selectedDay.inspections].length
      ? Math.ceil(testDriveCheckedHours[selectedDay.testDrive].length / 2)
      : Math.ceil(inspectionCheckedHours[selectedDay.inspections].length / 2)

  const isLoading = inspectionsHoursLoading || testDriveHoursLoading

  if (isLoading) return <LoadingAnimation showAnimation={isLoading} />

  return (
    <Box>
      <VehicleBox>
        <ConfigurationItem
          toggleInformation={toggleInformation.testDrive}
          setToggleInformation={setToggleInformation}
          hours={testDriveCheckedHours[selectedDay.testDrive]}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          handleChangeCheckbox={handleChangeCheckedHours}
          setHasUnsavedChanges={setHasUnsavedChanges}
          translation={testDrive}
          type="testDrive"
          hourContainerSize={hourContainerSize}
        />
        <ConfigurationItem
          toggleInformation={toggleInformation.inspections}
          setToggleInformation={setToggleInformation}
          hours={inspectionCheckedHours[selectedDay.inspections]}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          handleChangeCheckbox={handleChangeCheckedHours}
          setHasUnsavedChanges={setHasUnsavedChanges}
          translation={inspection}
          type="inspections"
          hourContainerSize={hourContainerSize}
        />
      </VehicleBox>
      <SaveChangesBlock
        handleSaveChanges={handleUpdateSchedules}
        shouldRender={hasUnsavedChanges}
        submitLoading={
          configurationLoading.submitLoading ||
          configurationLoading.calendarLoading
        }
        resetState={handleResetChanges}
      />
    </Box>
  )
}

export default Configuration
