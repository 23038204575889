import React from 'react'

import StatusTag from 'components/Common/StatusTag'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { colors } from 'styles/theme'

type CertifiedTagProps = {
  certified: boolean
}

const CertifiedTag = ({ certified }: CertifiedTagProps) => {
  const {
    text: { status: translation },
  } = useTranslation(textFiles.GENERAL)

  return (
    <StatusTag
      backgroundColor={certified ? colors.cyan : colors.lightGreen}
      color={certified ? colors.aquaBlue : colors.green}
    >
      {certified ? translation.certified : translation.notCertified}
    </StatusTag>
  )
}

export default CertifiedTag
