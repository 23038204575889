import {
  formControlClasses,
  sliderClasses,
  styled,
  TextField as MUITextField,
} from '@mui/material'

import Container from 'components/Common/Container'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledTextFieldContainer = styled(Container)({
  padding: 0,
  borderRadius: 0,
  '> p': {
    fontSize: '12px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
  '> div:nth-of-type(2)': {
    position: 'static',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [`.${sliderClasses.rail}`]: {
      height: 10,
      color: colors.gray,
    },
    [`.${sliderClasses.track}`]: {
      height: 10,
    },
  },
})

export const StyledTextField = styled(MUITextField)({
  border: `1px solid ${colors.lightGray}`,
  borderRadius: '4px',
  opacity: 1,
  height: '38px',
  '& div': {
    color: colors.placeholderGray,
    fontWeight: FONT_WEIGHT.MEDIUM,
    height: '100%',
    fontSize: '12px',
  },
  '& div fieldset': {
    border: 'none',
  },
})
