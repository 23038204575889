import { formControlClasses, styled, Typography } from '@mui/material'

import Container from 'components/Common/Container'
import Select from 'components/Common/Select'

import { colors } from 'styles/theme'

export const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  padding: '2rem 0',
  height: '444px',
  border: `1px solid ${colors.border}`,
  borderRadius: '8px',
  backgroundColor: colors.commonWhite,
})

export const StyledForm = styled('form')({
  borderRadius: '0px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  height: '383px',
  width: '100%',
  '> div': {
    height: '330px',
    width: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    section: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '97%',
      columnGap: '40px',
      rowGap: '20px',
    },

    '> div': {
      width: '100%',
      padding: '0',
    },
  },
})

export const FormContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
})

export const StyledTextFieldContainer = styled(Container)({
  width: '350px',
  padding: 0,
  borderRadius: 0,
  marginLeft: '3rem',
  marginRight: '3rem',
  marginBottom: '20px',
  '> p': {
    fontSize: '14px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
})

export const StyledSelect = styled(Select)({
  fontSize: 12,
  height: 40,
  marginTop: '1rem',
})

export const FormTitle = styled(Typography)({
  paddingTop: '1rem',
})
