import React from 'react'
import { Box, Typography } from '@mui/material'

import Calendar from 'components/Common/Calendar'

import { CalendarRangeType } from 'models/date'

import { colors } from 'styles/theme'

import { StyledCalendarRange } from './style'

type CalendarRangeProps = {
  /**
   *If `true`, user will not be allowed to interact with both Calendars
   */
  disableRangeCalendar?: boolean
  /**
   * Date of the from calendar. Cannot be higher than `toCalendarDate`
   */
  dateRange: CalendarRangeType
  /**
   * Function fired when date changes in the to calendar
   */
  handleChangeCalendarRange: (newDateRange: CalendarRangeType) => void
  /**
   * Minimum allowed date for the calendar to be clicked, this will be passed to the from calendar
   */
  minDate?: Date
  /**
   * Maximum allowed date for the calendar to be clicked, this will be passed to the to calendar
   */
  maxDate?: Date

  /**
   * Label that appears at the top of the from  calendar
   */
  fromLabel?: string
  /**
   * Label that appears at the top of the to  calendar
   */
  toLabel?: string
}

const CalendarRange = ({
  disableRangeCalendar = false,
  dateRange,
  handleChangeCalendarRange,
  minDate,
  maxDate,
  fromLabel,
  toLabel,
}: CalendarRangeProps) => {
  const changeFromDate = (newDate: Date | null) => {
    handleChangeCalendarRange({
      fromDate: newDate,
      toDate: dateRange.toDate,
    })
  }

  const changeToDate = (newDate: Date | null) => {
    handleChangeCalendarRange({
      fromDate: dateRange.fromDate,
      toDate: newDate,
    })
  }

  return (
    <StyledCalendarRange>
      <Box sx={{ marginRight: '30px' }}>
        {fromLabel ? (
          <Typography color={colors.black} variant="body2">
            {fromLabel}
          </Typography>
        ) : null}
        <Calendar
          date={dateRange.fromDate}
          handleCalendarDate={changeFromDate}
          minDate={minDate}
          maxDate={dateRange.toDate || undefined}
          disabled={disableRangeCalendar}
        />
      </Box>
      <Box>
        {toLabel ? (
          <Typography color={colors.black} variant="body2">
            {toLabel}
          </Typography>
        ) : null}
        <Calendar
          date={dateRange.toDate}
          handleCalendarDate={changeToDate}
          maxDate={maxDate}
          minDate={dateRange.fromDate || undefined}
          disabled={disableRangeCalendar}
        />
      </Box>
    </StyledCalendarRange>
  )
}

export default CalendarRange
