import React, { ReactNode } from 'react'
import { Typography } from '@mui/material'

import ButtonContainer from 'components/CarSettings/Common/Creation/ButtonContainer'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { GridContainer, StyledContainer, StyledForm } from '../style'

type Props = {
  title: string
  description: string
  submitLoading?: boolean
  isFirstStep?: boolean
  previousFunction?: () => void
  nextFunction?: () => void
  confirmButtonType?: 'button' | 'submit' | 'reset'
  children: ReactNode
  textType?: 'next' | 'finish'
  containerStyles?: React.CSSProperties
  formStyles?: React.CSSProperties
  bodyStyles?: React.CSSProperties
  gridContainerStyles?: React.CSSProperties
}

/*
This component is used in any of the summary step of a setting creation process, 
as a skeleton structure that contains everything needed for holding the rest of components
*/

const Skeleton: React.FC<Props> = ({
  title,
  description,
  submitLoading,
  isFirstStep = false,
  previousFunction,
  nextFunction,
  confirmButtonType = 'button',
  children,
  textType = 'finish',
  containerStyles,
  formStyles = { minHeight: '380px' },
  bodyStyles,
  gridContainerStyles = {
    display: 'flex',
    width: '95%',
  },
}: Props) => {
  return (
    <StyledContainer sx={{ ...containerStyles }}>
      <StyledForm sx={{ ...formStyles }}>
        <div style={{ ...bodyStyles }}>
          <Typography
            variant="h5"
            color={colors.black}
            fontWeight={FONT_WEIGHT.BOLD}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color={colors.placeholderGray}
            fontWeight={FONT_WEIGHT.MEDIUM}
          >
            {description}
          </Typography>
          <GridContainer sx={gridContainerStyles}>{children}</GridContainer>
        </div>
        <br />
        <ButtonContainer
          isFirstStep={isFirstStep}
          previousFunction={previousFunction}
          nextFunction={nextFunction}
          confirmButtonType={confirmButtonType}
          textType={textType}
          loading={submitLoading}
        />
      </StyledForm>
    </StyledContainer>
  )
}

export default Skeleton
