import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import SaveIcon from '@mui/icons-material/Save'
import {
  ClickAwayListener,
  Fade,
  ListItem,
  MenuItem,
  Popper,
  Select,
  SelectChangeEvent,
} from '@mui/material'

import Button from 'components/Common/Button'
import Container from 'components/Common/Container'
import ExtendedCalendarRange from 'components/General/ExtendedCalendarRange'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { CalendarRangeType, DateRangeCalendarTabType } from 'models/date'
import {
  CreateAbsenceInput,
  InspectorOptionSelect,
  UpdateAbsenceInput,
} from 'models/services/inspector/detail'
import {
  formatDateLocalizedTime,
  getDateRangeString,
  getRangeFromAbsence,
} from 'utils/date'

import { BREAK_POINTS } from 'styles/breakpoints'

import {
  AbsenceEditor,
  AbsenceSelectStyle,
  CalendarPopper,
  ClickText,
  ListBullet,
  ListContent,
  ListIcon,
  ListText,
} from './style'
import { LocalAbsenceType } from '.'

type PopperProps = {
  open: boolean
  handleClose: () => void
  selectedAbsence: LocalAbsenceType
  edit: boolean
  editingStatus: boolean
  handleEditingStatus: (status: boolean) => void
  anchor: HTMLElement | null
  addNewAbsence: (selAbsence: CreateAbsenceInput) => void
  editAbsence: (selAbsence: UpdateAbsenceInput) => void
  endAbsence: (id: string) => void
  inspectorId: string
  reasons: InspectorOptionSelect[]
}

const AbsencePopper = ({
  open,
  handleClose,
  selectedAbsence,
  editingStatus,
  handleEditingStatus,
  anchor,
  edit,
  endAbsence,
  editAbsence,
  addNewAbsence,
  inspectorId,
  reasons,
}: PopperProps) => {
  const { text } = useTranslation(textFiles.INSPECTOR_DETAIL)
  const { personal: translation } = text
  const { absenceManager } = translation

  const { width: windowWidth } = useWindowDimensions()

  const [absenceMotive, setAbsenceMotive] = useState<string>(
    selectedAbsence.reason ? selectedAbsence.reason.id : ''
  )
  const [dateRange, setDateRange] = useState<CalendarRangeType>(
    getRangeFromAbsence(selectedAbsence)
  )

  const handleChangeDateRange = (newDateRange: CalendarRangeType) => {
    setDateRange(newDateRange)
  }

  const [calendarOpen, setCalendarOpen] = useState<boolean>(false)
  const [calendarAnchorEl, setCalendarAnchorEl] = useState<HTMLElement | null>(
    null
  )
  const [calendarTabValue, setCalendarTabValue] = useState<
    DateRangeCalendarTabType | boolean
  >(DateRangeCalendarTabType.CUSTOM)

  const getReasonId = () => {
    const foundReason = reasons.find((reason) => reason.id === absenceMotive)
    if (foundReason) {
      return foundReason.id
    }
    return ''
  }

  const handleChangeAbsence = (event: SelectChangeEvent) => {
    setAbsenceMotive(event.target.value)
  }
  const handleCalendarState = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCalendarAnchorEl(event.currentTarget)
    setCalendarOpen((prev) => !prev)
    handleEditingStatus(!editingStatus)
  }

  const handleCalendarClose = () => {
    setCalendarOpen(false)
    handleEditingStatus(false)
  }

  const handleCalendarTabChange = (newValue: DateRangeCalendarTabType) => {
    setCalendarTabValue(newValue)
  }

  const handleSaveAbsence = () => {
    if (selectedAbsence) {
      if (edit) {
        const resultAbsence: UpdateAbsenceInput = {
          where: { id: selectedAbsence.id },
          data: {
            reason: getReasonId(),
            startDate: formatDateLocalizedTime(
              dateRange.fromDate!,
              'yyyy-MM-dd'
            ),
            endDate: formatDateLocalizedTime(dateRange.toDate!, 'yyyy-MM-dd'),
          },
        }
        editAbsence(resultAbsence)
      } else {
        const resultAbsence: CreateAbsenceInput = {
          inspector: inspectorId,
          startDate: formatDateLocalizedTime(dateRange.fromDate!, 'yyyy-MM-dd'),
          endDate: formatDateLocalizedTime(dateRange.toDate!, 'yyyy-MM-dd'),
          reason: getReasonId(),
        }
        addNewAbsence(resultAbsence)
      }
    }

    handleClose()
  }

  const handleEndAbsence = () => {
    endAbsence(selectedAbsence.id)
    handleClose()
  }

  const maxDate = () => {
    const date = new Date()
    date.setFullYear(date.getFullYear() + 1)
    return date
  }

  const getPopperPlacementPosition = (width: number) => {
    if (width <= BREAK_POINTS.XL) return 'right'
    if (edit) return 'left-start'
    return 'right-end'
  }

  const popperPlacementPosition = getPopperPlacementPosition(windowWidth || 0)

  return (
    <>
      <Popper
        open={open}
        anchorEl={anchor}
        placement={popperPlacementPosition}
        style={{ zIndex: 3 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <AbsenceEditor>
            <Container
              title={edit ? absenceManager.edit : absenceManager.record}
              description={`${absenceManager.subtitle}.`}
              margin="1rem"
            >
              <ListItem>
                <ListIcon>
                  <ListBullet />
                </ListIcon>
                <ListText variant="body2">{absenceManager.motive}</ListText>
              </ListItem>
              <ListContent>
                <Select
                  value={absenceMotive}
                  onOpen={() => handleEditingStatus(true)}
                  onClose={() => handleEditingStatus(false)}
                  onChange={handleChangeAbsence}
                  displayEmpty
                  sx={{ ...AbsenceSelectStyle }}
                  inputProps={{
                    'aria-label': 'Without label',
                    padding: '10px',
                  }}
                  MenuProps={{ disableScrollLock: true }}
                >
                  {reasons.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </ListContent>
              <ListItem>
                <ListIcon>
                  <ListBullet />
                </ListIcon>
                <ListText variant="body2">{`${
                  absenceManager.timeFrame
                }: ${getDateRangeString(dateRange)}`}</ListText>
              </ListItem>
              <ListContent>
                <Button
                  size="small"
                  onClick={handleCalendarState}
                  sx={{
                    textAlign: 'center',
                    height: '23px !important',
                    paddingX: '5px !important',
                    borderRadius: '5px !important',
                  }}
                >
                  {absenceManager.set}
                  <KeyboardArrowDownIcon fontSize="inherit" />
                </Button>
              </ListContent>
              {edit ? (
                <ListItem>
                  <ListIcon>
                    <ListBullet />
                  </ListIcon>
                  <ListText variant="body2">
                    {`${absenceManager.editCan}`}
                  </ListText>
                  <ClickText
                    onClick={handleEndAbsence}
                    variant="button"
                  >{` ${absenceManager.editEnd}.`}</ClickText>
                </ListItem>
              ) : null}
              <Button
                size="small"
                startIcon={<SaveIcon />}
                sx={{ marginTop: '1rem' }}
                onClick={calendarOpen ? handleCalendarClose : handleSaveAbsence}
              >
                {absenceManager.save}
              </Button>
            </Container>
          </AbsenceEditor>
        </ClickAwayListener>
      </Popper>
      <Popper
        open={calendarOpen}
        anchorEl={calendarAnchorEl}
        placement="bottom-end"
        transition
        style={{ zIndex: 1201 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleCalendarClose}>
            <Fade {...TransitionProps} timeout={350}>
              <CalendarPopper>
                <ExtendedCalendarRange
                  dateRange={dateRange}
                  handleChangeDateRange={handleChangeDateRange}
                  tabValue={calendarTabValue}
                  handleTabChange={handleCalendarTabChange}
                  maxDate={maxDate()}
                  extendedFields
                  omitFields={{ ALL: true }}
                />
              </CalendarPopper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  )
}

export default AbsencePopper
