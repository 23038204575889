import { CURBO_SPOT_CREATION_OPTION } from 'constants/Operation/curboSpot'
import {
  SpotCreationAction,
  SpotCreationModel,
} from 'models/services/operations/curboSpot'

const curboSpotCreationReducer = (
  state: SpotCreationModel,
  action: SpotCreationAction
): SpotCreationModel => {
  switch (action.type) {
    case CURBO_SPOT_CREATION_OPTION.UPDATE_CURBO_SPOT_INFORMATION:
      return { ...state, spotInformation: action.payload }

    case CURBO_SPOT_CREATION_OPTION.UPDATE_CURBO_SPOT_LOCATION:
      return { ...state, spotLocation: action.payload }

    case CURBO_SPOT_CREATION_OPTION.UPDATE_CURBO_SPOT_SCHEDULE:
      return { ...state, spotSchedule: action.payload }

    default:
      return state
  }
}

export default curboSpotCreationReducer
