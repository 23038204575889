import { styled } from '@mui/material'

import Box from 'components/Common/Box'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors } from 'styles/theme'

export const StyledBox = styled(Box)({
  backgroundColor: colors.commonWhite,
  border: `1px solid ${colors.border}`,
  borderRadius: '10px',
  height: '313px',
  textAlign: 'left',
  padding: '18px',
  width: 'auto',
})

export const GridBox = styled(Box)({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '33% 33% 33%',
  columnGap: '15px',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '50% 50% !important',
    columnGap: '10px',
    rowGap: '0px',
  },
})

export const SettingBox = styled(Box)({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '35% 60%',
  columnGap: '15px',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '100% !important',
    columnGap: '10px',
    rowGap: '0px',
  },
})

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
})
