import { gql } from '@apollo/client'

export const CREATE_ABSENCE_REASON = gql`
  mutation createAbsenceReason($input: CreateAbsenceReasonInput!) {
    data: createAbsenceReason(input: $input) {
      id
    }
  }
`

export const UPDATE_ABSENCE_REASON = gql`
  mutation updateAbsenceReason($input: UpdateAbsenceReasonInput!) {
    data: updateAbsenceReason(input: $input) {
      id
    }
  }
`

export const DELETE_ABSENCE_REASON = gql`
  mutation deleteAbsenceReason($input: ID!) {
    data: deleteAbsenceReason(id: $input) {
      id
    }
  }
`

export const CREATE_REJECTION_MOTIVE = gql`
  mutation createInspectionFailureReason(
    $input: CreateInspectionFailureReasonInput!
  ) {
    data: createInspectionFailureReason(input: $input) {
      id
    }
  }
`

export const UPDATE_REJECTION_MOTIVE = gql`
  mutation updateInspectionFailureReason(
    $input: UpdateInspectionFailureReasonInput!
  ) {
    data: updateInspectionFailureReason(input: $input) {
      id
    }
  }
`

export const DELETE_REJECTION_MOTIVE = gql`
  mutation deleteInspectionFailureReason($input: ID!) {
    data: deleteInspectionFailureReason(id: $input) {
      id
    }
  }
`
