import React from 'react'

// import { Typography } from '@mui/material'
import { textFiles } from 'constants/textFiles'
// import useLocale from 'hooks/useLocale'
import useTranslation from 'hooks/useTranslation'
import { Activities, Activity as ActivityModel } from 'models/Activity'
import { RecentActivitiesTextType } from 'models/text/Dashboard'

// import { formatDateLocalizedTime } from 'utils/date'
// import { colors, FONT_WEIGHT } from 'styles/theme'
import Activity from './Activity'
import { StyledContainer } from './style'

// type DescriptionProps = {
//   date: string
//   text: string
// }

// const Description = ({ date, text }: DescriptionProps): JSX.Element => {
//   return (
//     <>
//       {date} -{' '}
//       <Typography
//         variant="body2"
//         component="span"
//         style={{ color: colors.blue, fontWeight: FONT_WEIGHT.SEMI_BOLD }}
//       >
//         {text}
//       </Typography>
//     </>
//   )
// }

const Inbox = ({ activities }: Activities) => {
  const { text } = useTranslation(textFiles.INSPECTION_DASHBOARD)
  const { recentActivities }: { recentActivities: RecentActivitiesTextType } =
    text
  // const [selectedLanguage] = useLocale()
  // const currentDate = formatDateLocalizedTime(
  //   new Date(),
  //   'PPP',
  //   selectedLanguage.code
  // )
  return (
    <StyledContainer
      title={recentActivities.title}
      // description={
      //   <Description date={currentDate} text={recentActivities.description} />
      // }
      // extraText={recentActivities.sideTitle}
      height={437}
      isEmpty
    >
      {activities.map((activity: ActivityModel) => (
        <Activity
          key={activity.id}
          img={activity.img}
          name={activity.name}
          description={activity.description}
          hour={activity.hour}
        />
      ))}
    </StyledContainer>
  )
}

export default Inbox
