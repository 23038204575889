import React from 'react'
import { Typography } from '@mui/material'

import Box from 'components/Common/Box'
import Calendar from 'components/Common/Calendar'

import useLocale from 'hooks/useLocale'
import { formatDateLocalizedTime } from 'utils/date'

import { colors, FONT_WEIGHT } from 'styles/theme'

type DatePickerProps = {
  translation: Record<string, string>
  // Necessary for it to run, but should cast the event target value when being used
  onChange: (date: Date | null) => void
  value: Date | null
  /**
   * If `true`, user will not be allowed to interact with the Calendar
   */
  disabled?: boolean
  /**
   * Disable specific dates
   */
  shouldDisableDate?: (date: Date) => boolean
  /**
   * Callback fired when date is accepted
   */
  onAccept?: () => void
  /**
   * Tells the calendar if the minDate should be in the current day, otherwise starts the next day
   */
  startSameDay?: boolean
}

const DatePicker = ({
  disabled,
  translation,
  startSameDay = false,
  onChange,
  value,
  shouldDisableDate,
  onAccept,
}: DatePickerProps) => {
  const [selectedLanguage] = useLocale()
  const minimumDate = !startSameDay
    ? new Date(Date.now() + 24 * 60 * 60 * 1000)
    : new Date()
  return (
    <Box marginBottom="1em">
      <Typography variant="body1">{translation.dateTitle}</Typography>
      <Typography variant="subtitle1" marginBottom="1em">
        {translation.dateSubtitle}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding="1.25rem 0 0 0"
        border={`1px solid ${colors.border}`}
        borderRadius="10px"
        width="320px"
        position="relative"
        maxHeight="340px"
      >
        <Typography
          position="absolute"
          variant="body2"
          color="primary"
          fontWeight={FONT_WEIGHT.BOLD}
          height="20px"
          top="5px"
        >
          {value
            ? formatDateLocalizedTime(value, 'P', selectedLanguage.code)
            : ''}
        </Typography>
        <Calendar
          date={value}
          handleCalendarDate={onChange}
          minDate={disabled ? undefined : minimumDate}
          disabled={disabled}
          shouldDisableDate={shouldDisableDate}
          onAccept={onAccept}
        />
      </Box>
    </Box>
  )
}

export default DatePicker
