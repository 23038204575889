import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ListAltIcon from '@mui/icons-material/ListAlt'

import Skeleton from 'components/CarSettings/Common/Creation/Summary/Skeleton'
import TranslationItem from 'components/CarSettings/Common/Creation/Summary/TranslationItem'
import SummaryItem from 'components/Common/SummaryItem'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { FeatureCreationProps } from 'utils/CarSettings/Feature/creation'

import { SectionContainer } from 'styles/creation'

const Summary = ({
  handleBack,
  featureData,
  handleSubmit,
  submitLoading,
}: FeatureCreationProps) => {
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { text } = useTranslation(textFiles.FEATURE_CREATION)
  const { summary } = text
  const { featureInformation } = featureData
  const { translations } = featureInformation

  return (
    <Skeleton
      previousFunction={handleBack}
      nextFunction={handleSubmit}
      title={summary.title}
      description={summary.description}
      submitLoading={submitLoading}
    >
      <SectionContainer>
        <SummaryItem
          title={summary.name}
          text={featureInformation.name}
          icon={<ListAltIcon />}
        />
        <SummaryItem
          title={summary.status}
          text={generalText.status[featureInformation.status]}
          icon={<CheckCircleIcon />}
        />
        {translations.length > 0 ? (
          <TranslationItem translations={featureInformation.translations} />
        ) : null}
      </SectionContainer>
    </Skeleton>
  )
}

export default Summary
