import {
  inputBaseClasses,
  selectClasses,
  styled,
  TextField as MUITextField,
} from '@mui/material'

import Box from 'components/Common/Box'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const Stripe = styled('section')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
})

export const StyledTextField = styled(MUITextField)({
  border: `1px solid ${colors.lightGray}`,
  borderRadius: '4px',
  opacity: 1,
  height: '38px',
  '& div': {
    color: colors.placeholderGray,
    fontWeight: FONT_WEIGHT.MEDIUM,
    height: '100%',
    fontSize: '12px',
  },
  '& div fieldset': {
    border: 'none',
  },
  input: {
    height: '5px',
    borderRadius: '4px',
  },
})

export const StyledCounterContainer = styled(Box)({
  height: 59,
  width: '10%',
})

export const StyledCounterField = styled(MUITextField)({
  height: '38px',
  borderRadius: '4px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.placeholderGray,
  div: {
    height: '100%',
    fontSize: '12px',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },

  input: {
    height: '38px',
    fontSize: '12px',
    padding: '0 0 0 14px',
    '&:-internal-autofill-selected': {
      backgroundColor: colors.commonWhite,
    },
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
})

export const CounterDiv = styled('div')({
  width: '27px',
  height: '38px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  position: 'relative',
  left: 22,
  top: -38,
})

export const CounterButton = styled('div')({
  width: '100%',
  height: '19px',
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: colors.placeholderGray,
  borderLeft: `1px solid ${colors.lightGray}`,
  cursor: 'pointer',
})

export const IconButton = styled('div')({
  height: 59,
  display: 'flex',
  alignItems: 'flex-end',
  cursor: 'pointer',
})
