import Summary from 'components/CarSettings/Transmission/Creation/Summary'
import TransmissionInfo from 'components/CarSettings/Transmission/Creation/TransmissionInfo'
import { StepperModel } from 'components/General/Stepper'

import { StepperTextType } from 'models/text/General'
import {
  TransmissionCreationAction,
  TransmissionCreationModel,
} from 'models/transmissionCreation'

export type TransmissionCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  submitLoading: boolean
  transmissionData: TransmissionCreationModel
  updateTransmissionData: (action: TransmissionCreationAction) => void
}

export const INITIAL_TRANSMISSION_CREATION_STEPS: Array<
  React.FC<TransmissionCreationProps>
> = [TransmissionInfo, Summary]

export const createTransmissionStepperItems = (
  text: StepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep,
    },
    { label: text.secondStep },
  ]

  return stepperItems
}
