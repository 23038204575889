import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { SelectChangeEvent } from '@mui/material'
import {
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid'

import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import Table from 'components/Common/Table'
import Tooltip from 'components/Common/Table/Tooltip'
import { TabObjects } from 'components/Common/Tabs'
import Title from 'components/Common/Title'
import { Filter } from 'components/Inspection/Dashboard/FilterCard'
import DateFilter from 'components/Inspection/Listing/DateFilter'
import FieldFilter from 'components/Inspection/Listing/FieldFilter'
import FilterByCriteria from 'components/Listing/FilterByCriteria'

import { cypressAddButton } from 'constants/cypress'
import { inactiveHash, OfferColumnField } from 'constants/Marketing/offer'
import { routes } from 'constants/routes'
import { ACTIVE_STATUS, INACTIVE_STATUS } from 'constants/status'
import {
  commonListColumns,
  defaultSortModel,
  selectItems,
} from 'constants/table'
import { textFiles } from 'constants/textFiles'
import { UrlParamNames } from 'constants/urlQuery'
import useLocale from 'hooks/useLocale'
import useNotification from 'hooks/useNotification'
import useQueryState from 'hooks/useQueryState'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { CalendarRangeType } from 'models/date'
import { UserRoles } from 'models/role'
import {
  BaseIdEntity,
  BaseListData,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
  ListingFilterType,
} from 'models/services/base'
import { Offer } from 'models/services/marketing/offer'
import { ExtendedStatus } from 'models/services/status'
import { InspectionStatusMap } from 'models/status'
import { getIsoDate } from 'utils/date'
import {
  checkSearchEmptiness,
  cleanFilters,
  generateFilterInput,
} from 'utils/filters'
import {
  createFieldSelectItems,
  createFieldSelectLabels,
  defaultFields,
} from 'utils/Marketing/Offer/listing'
import { buildDetailRoute } from 'utils/routes'
import {
  serializeFields,
  serializeFilters,
  serializePage,
  serializePageSize,
  serializeRange,
  serializeSearch,
  serializeSortModel,
} from 'utils/serializers'
import { verifyParam } from 'utils/verifyUrlData'

import { DELETE_OFFER } from 'graphQL/Marketing/Offer/Listing/mutations'
import {
  GET_OFFER_STATUSES,
  LIST_OFFERS,
} from 'graphQL/Marketing/Offer/Listing/queries'

import { StyledTextField } from 'styles/inspection/listing'

const OfferListing = () => {
  const { show } = useNotification()
  const location = useLocation()
  const { validateAllowedRoles } = useUser()
  const isSupervisor = validateAllowedRoles([
    UserRoles.ADMIN,
    UserRoles.MARKETING_SPECIALIST,
  ])
  const { search, hash } = location

  const defaultSortedFields = [...defaultFields].sort()

  const [tabValue, setTabValue] = useState<string | number>(
    location.hash === inactiveHash ? INACTIVE_STATUS : ACTIVE_STATUS
  )

  const [selectedFields, setSelectedFields] = useQueryState<string[]>(
    UrlParamNames.FIELDS,
    (verifyParam(UrlParamNames.FIELDS, search) as string[]) ||
      defaultSortedFields,
    serializeFields,
    defaultSortedFields
  )

  const [filtersList, setFiltersList] = useQueryState<Filter[]>(
    UrlParamNames.FILTERS,
    (verifyParam(UrlParamNames.FILTERS, search) as Filter[]) || [],
    serializeFilters
  )

  const [dateRange, setDateRange] = useQueryState<CalendarRangeType>(
    UrlParamNames.DATE,
    (verifyParam(UrlParamNames.DATE, search) as CalendarRangeType) || [],
    serializeRange
  )

  const [searchValue, setSearchValue] = useQueryState<string | undefined>(
    UrlParamNames.SEARCH,
    verifyParam(UrlParamNames.SEARCH, search) as string,
    serializeSearch
  )

  const [pageSize, setPageSize] = useQueryState<number>(
    UrlParamNames.LIMIT,
    (verifyParam(UrlParamNames.LIMIT, search) as number) || 10,
    serializePageSize
  )
  const [page, setPage] = useQueryState<number>(
    UrlParamNames.PAGE,
    (verifyParam(UrlParamNames.PAGE, search) as number) || 1,
    serializePage
  )

  const [sortModel, setSortModel] = useQueryState<GridSortModel>(
    UrlParamNames.SORT,
    (verifyParam(UrlParamNames.SORT, search) as GridSortModel) ||
      defaultSortModel,
    serializeSortModel,
    defaultSortModel
  )
  const [filterInput, setFiltersInput] = useState<ListingFilterType>(
    generateFilterInput(filtersList)
  )
  const [searchInput, setSearchInput] = useState<string>(searchValue || '')

  const [selectedLanguage] = useLocale()
  const history = useHistory()

  const [deleteItem, setDeleteItem] = useState<GridRowId | null>(null)
  const { text } = useTranslation(textFiles.OFFER_LISTING)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const [data, setData] = useState<GridRowsProp>([])
  const [offerCount, setOfferCount] = useState<number>(0)

  const [status, setStatus] = useState<ExtendedStatus[]>([])

  const fieldSelectItems = createFieldSelectItems(text.fieldSelect)
  const fieldSelectLabels = createFieldSelectLabels(text.fieldSelect)

  const { fromDate, toDate } = useMemo(() => {
    return {
      fromDate: getIsoDate(dateRange.fromDate, 'date'),
      toDate: getIsoDate(dateRange.toDate, 'date'),
    }
  }, [dateRange])

  const { loading: offersLoading, refetch: refetchOffers } = useQuery<
    BaseListData<Offer[]>,
    FilterInputVariable
  >(LIST_OFFERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        limit: Number(pageSize),
        start: (page - 1) * Number(pageSize),
        where: {
          text_search: searchValue,
          createdAt_gte: fromDate,
          createdAt_lte: toDate,
          ...(tabValue === INACTIVE_STATUS
            ? { status_eq: INACTIVE_STATUS }
            : { status_ne: INACTIVE_STATUS }),
          ...cleanFilters(filterInput),
        },
        sort:
          sortModel.length > 0 && sortModel[0].sort
            ? {
                [sortModel[0].field]: sortModel[0].sort,
              }
            : undefined,
      },
    },
    onCompleted(response) {
      const { count, data: offerData } = response.listData
      setData(offerData)
      setOfferCount(count)
    },
    onError() {
      show({
        updatedSeverity: 'error',
      })
    },
  })

  const { data: statusData, loading: statusLoading } = useQuery<
    GenericData<ExtendedStatus[]>,
    FilterInputVariable
  >(GET_OFFER_STATUSES, {
    variables: {
      input: {
        sort: {
          slug: 'asc',
        },
      },
    },
  })

  const handleShowError = () => {
    show({
      updatedSeverity: 'error',
      message: generalText.notificationText.deleteFail,
    })
  }

  const handleRefetchOffers = async () => {
    try {
      const response = await refetchOffers()

      if (response) {
        const offers = response.data.listData
        if (offers.data.length === 0 && offers.count > 0) {
          setPage(1)
        }
      }
      show({
        updatedSeverity: 'success',
        message: generalText.notificationText.deleteSuccess,
      })
    } catch {
      handleShowError()
    }
  }

  const [removeOffers] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<string>
  >(DELETE_OFFER, {
    onCompleted() {
      handleRefetchOffers()
    },
    onError() {
      handleShowError()
    },
  })

  const deleteOffer = React.useCallback((id: GridRowId) => {
    setDeleteItem(id)
  }, [])

  const editOffer = React.useCallback(
    (id: GridCellValue) => {
      history.push(buildDetailRoute(id, routes.OFFERS_DETAIL))
    },
    [history]
  )

  const createOffer = () => {
    history.push(routes.OFFERS_CREATION)
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
  }

  const handleRemoveOffer = async (id: string) => {
    removeOffers({
      variables: {
        input: id,
      },
    })
  }

  const createColumns = useCallback(
    (statuses?: ExtendedStatus[]) => {
      const {
        id: idColumn,
        statusBackend: statusColumn,
        standard: standardColumn,
        actions: actionsColumn,
        isoDate: dateColumn,
      } = commonListColumns(
        InspectionStatusMap,
        selectedLanguage,
        generalText,
        editOffer,
        isSupervisor && tabValue === INACTIVE_STATUS ? deleteOffer : undefined,
        statuses
      )

      return [
        { ...idColumn, hide: !selectedFields.includes(idColumn.field) },
        {
          ...standardColumn,
          field: OfferColumnField.LABEL,
          headerName: text.table.label,
          hide: !selectedFields.includes(OfferColumnField.LABEL),
          sortable: false,
          flex: 2,
          renderCell: (params: GridRenderCellParams) => {
            return <Tooltip>{params.row.label}</Tooltip>
          },
        },
        {
          ...dateColumn,
          field: OfferColumnField.CREATED_AT,
          hide: !selectedFields.includes(OfferColumnField.CREATED_AT),
          headerName: text.table.createdAt,
        },
        {
          ...statusColumn,
          hide: !selectedFields.includes(statusColumn.field),
          sortable: false,
        },
        {
          ...actionsColumn,
          hide: !selectedFields.includes(actionsColumn.field),
        },
      ]
    },
    [
      selectedLanguage,
      generalText,
      editOffer,
      isSupervisor,
      deleteOffer,
      selectedFields,
      text.table,
      tabValue,
    ]
  )

  const [columns, setColumns] = useState<GridColDef[]>(createColumns())

  const tabs: TabObjects[] = [
    {
      id: 1,
      item: {
        label: text.activeTab,
        value: ACTIVE_STATUS,
      },
    },
    {
      id: 2,
      item: {
        label: text.inactiveTab,
        value: INACTIVE_STATUS,
      },
    },
  ]

  const handleFiltersList = (
    newFiltersList: Filter[],
    newFilterInput: ListingFilterType
  ) => {
    setFiltersList(newFiltersList)
    setFiltersInput(newFilterInput)
    setPage(1)
  }

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchInput(event.target.value)
  }

  const submitSearchValue = () => {
    setPage(1)
    const newValue = checkSearchEmptiness(searchInput)
    setSearchValue(newValue)
  }

  const handleTabChange = (newValue: string | number) => {
    if (newValue === INACTIVE_STATUS)
      history.replace({ ...location, hash: inactiveHash })
    else history.replace({ ...location, hash: '#' })
    setPage(1)
  }

  const handleSelectItem = (valueInput: string) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        return column.field === valueInput
          ? { ...column, hide: !column.hide }
          : column
      })
    )
    setSelectedFields((prevFields) => {
      if (prevFields.includes(valueInput)) {
        return prevFields.filter((field) => field !== valueInput)
      }
      return [...prevFields, valueInput]
    })
  }

  const handleResetDefault = () => {
    setSelectedFields(defaultFields)
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = defaultFields.some(
          (defaultField) => column.field === defaultField
        )
        return { ...column, hide: !fieldExist }
      })
    )
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangePageSize = (event: SelectChangeEvent<number>) => {
    setPageSize(event.target.value as number)
  }

  const handleChangeDateRange = (newDateRange: CalendarRangeType) => {
    setDateRange(newDateRange)
    setPage(1)
  }

  const getPageCount = () => {
    return Math.ceil(offerCount / pageSize)
  }

  useEffect(() => {
    if (statusData) {
      const formattedStatuses = statusData.data.map((currentStatus) => ({
        ...currentStatus,
        slug: currentStatus.slug.toLowerCase().replace('_', '-'),
      }))
      setColumns(createColumns(formattedStatuses))
      setStatus(formattedStatuses)
    }
  }, [statusData, createColumns])

  useEffect(() => {
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = selectedFields.some(
          (defaultField) => column.field === defaultField
        )
        return { ...column, hide: !fieldExist }
      })
    )
  }, [createColumns, selectedFields])

  useEffect(() => {
    if (hash && hash === inactiveHash) {
      setTabValue(INACTIVE_STATUS)
    } else {
      setTabValue(ACTIVE_STATUS)
    }
  }, [hash])

  return (
    <Box width="100%">
      <Title
        icon={<PlaylistAddCheckIcon />}
        handleTabChange={handleTabChange}
        subtitle={`${offerCount} ${text.description}`}
        tabs={tabs}
        tabValue={tabValue}
        title={text.title}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        marginTop="2rem"
        width="100%"
      >
        <Button
          startIcon={<AddIcon />}
          size="medium"
          onClick={createOffer}
          style={{ minWidth: '160px', marginRight: '20px' }}
          data-cy={cypressAddButton}
        >
          {text.newOfferButton}
        </Button>

        <Box display="flex" flex="1 0.8 auto" justifyContent="flex-end">
          <Box marginRight="1rem" flex="0 1 430px">
            <StyledTextField
              placeholder={text.searchPlaceholder}
              fullWidth
              value={searchInput}
              onChange={handleSearchChange}
              submitFunction={submitSearchValue}
              name="search"
            />
          </Box>
          <Box marginRight="1rem">
            <FilterByCriteria
              filtersList={filtersList}
              handleFiltersList={handleFiltersList}
              file={textFiles.OFFER_LISTING}
              filterInput={filterInput}
              statusList={status}
            />
          </Box>
          <Box marginRight="1rem">
            <FieldFilter
              handleSelectItem={handleSelectItem}
              items={fieldSelectItems}
              selectedValues={selectedFields}
              handleResetDefault={handleResetDefault}
            />
          </Box>
          <Box>
            <DateFilter
              dateRange={dateRange}
              handleChangeDateRange={handleChangeDateRange}
              title={text.dateRangeTitle}
              maxDate={null}
            />
          </Box>
        </Box>
      </Box>
      <Table
        columns={columns}
        dateRange={dateRange}
        searchValue={searchValue}
        data={data}
        currentPage={page}
        onPageChange={handleChangePage}
        onSelectChange={handleChangePageSize}
        pageSize={Number(pageSize)}
        selectItems={selectItems}
        pageCount={getPageCount()}
        filtersList={filtersList}
        fields={selectedFields}
        fieldLabels={fieldSelectLabels}
        deletedItemId={deleteItem}
        setDeleteItemId={setDeleteItem}
        route={routes.OFFERS_DETAIL}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
        loading={offersLoading || statusLoading}
        handleRemoveItem={handleRemoveOffer}
        hideDownloadModal
        checkboxSelection={false}
      />
    </Box>
  )
}
export default OfferListing
