import React from 'react'
import {
  Box,
  Card,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  TypographyProps,
} from '@mui/material'

import ColorBox from 'components/Common/ColorBox'
import Tooltip from 'components/Common/Table/Tooltip'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { colors, FONT_WEIGHT } from 'styles/theme'

import CardTitle from './CardTitle'

const secondaryProps: TypographyProps = {
  color: colors.gray,
  fontWeight: FONT_WEIGHT.MEDIUM,
  paddingLeft: '1.6rem',
}

export type CarTrimPopoverProps = {
  trim: string
  bodyStyle?: string
  category?: string
  fuelType?: string
  exteriorColor?: string
  interiorColor?: string
  countryVersion?: string
  curboSpot?: string
}

const CarTrimPopover = ({
  trim,
  bodyStyle,
  category,
  fuelType,
  exteriorColor,
  interiorColor,
  countryVersion,
  curboSpot,
}: CarTrimPopoverProps) => {
  const { text } = useTranslation(textFiles.INVENTORY_LISTING)
  const titles = text.filtersByCriteria.fieldOrEntryOptions

  return (
    <Tooltip
      customPopper={
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '23px 25px',
            width: '418px',
            height: 'fit-content',
            fontSize: '14px',
          }}
        >
          <Typography>{trim}</Typography>
          <Grid display="flex" spacing={1} container>
            <Grid>
              <List>
                {bodyStyle && (
                  <ListItem>
                    <ListItemText
                      secondaryTypographyProps={secondaryProps}
                      primary={<CardTitle text={titles.bodyStyle} />}
                      secondary={bodyStyle}
                    />
                  </ListItem>
                )}
                {category && (
                  <ListItem>
                    <ListItemText
                      secondaryTypographyProps={secondaryProps}
                      primary={<CardTitle text={titles.categories} />}
                      secondary={category}
                    />
                  </ListItem>
                )}

                {fuelType && (
                  <ListItem>
                    <ListItemText
                      secondaryTypographyProps={secondaryProps}
                      primary={<CardTitle text={titles.fuelType} />}
                      secondary={fuelType}
                    />
                  </ListItem>
                )}
                {exteriorColor && (
                  <ListItem>
                    <Box display="flex" flexDirection="column">
                      <ListItemText
                        primaryTypographyProps={{
                          fontWeight: FONT_WEIGHT.BOLD,
                        }}
                        primary={<CardTitle text={titles.exteriorColor} />}
                      />
                      <Box paddingLeft="1.6rem">
                        <ColorBox
                          height={46}
                          width={46}
                          hexCode={exteriorColor}
                        />
                      </Box>
                    </Box>
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid>
              <List>
                {countryVersion && (
                  <ListItem>
                    <ListItemText
                      secondaryTypographyProps={secondaryProps}
                      primary={<CardTitle text={titles.countryVersion} />}
                      secondary={countryVersion}
                    />
                  </ListItem>
                )}

                {curboSpot && (
                  <ListItem>
                    <ListItemText
                      secondaryTypographyProps={secondaryProps}
                      primary={<CardTitle text={titles.curboSpot} />}
                      secondary={curboSpot}
                    />
                  </ListItem>
                )}
                {interiorColor && (
                  <ListItem>
                    <Box display="flex" flexDirection="column">
                      <ListItemText
                        primaryTypographyProps={{
                          fontWeight: FONT_WEIGHT.BOLD,
                        }}
                        primary={<CardTitle text={titles.interiorColor} />}
                      />
                      <Box paddingLeft="1.6rem">
                        <ColorBox
                          height={46}
                          width={46}
                          hexCode={interiorColor}
                        />
                      </Box>
                    </Box>
                  </ListItem>
                )}
              </List>
            </Grid>
          </Grid>
        </Card>
      }
    >
      {trim}
    </Tooltip>
  )
}
export default CarTrimPopover
