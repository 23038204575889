import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import { uploadImageService } from 'services/uploadImage'

import { DetailSubHeader } from 'components/CarSettings/Common/Detail/DetailSubHeader'
import StatusChangePopper from 'components/CarSettings/Common/Detail/StatusChangePopper'
import Box from 'components/Common/Box'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import Tag from 'components/Common/Tag'
import DetailNavTab, { NavBarItem } from 'components/General/DetailNavTab'
import TabPanel from 'components/Inspection/Detail/TabPanel'
import Configuration from 'components/Operations/CurboSpot/Detail/Configuration'
import Information from 'components/Operations/CurboSpot/Detail/Information'
import Location from 'components/Operations/CurboSpot/Detail/Location'

import { DUPLICATE_KEY_ERROR, ENTITY_NOT_FOUND_ERROR } from 'constants/error'
import {
  arrayOfNamedDays,
  emptyInputWeekCalendar,
  initialOutOfSpotState,
} from 'constants/Operation/outOfSpot'
import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import {
  HoursPair,
  OutOfSpotModel,
  OutOfSpotUpdateResponse,
  PairUpdateOutOfSpotInputType,
  ScheduleResponse,
} from 'models/outOfSpot'
import { Option } from 'models/Select'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { SpotWeekCalendar } from 'models/services/curboSpot'
import {
  CreateCurboSpotWeekCalendarInput,
  CurboSpotDetailDataType,
  UpdateCurboSpotInput,
} from 'models/services/operations/curboSpot'
import { PublicationStatus } from 'models/status'
import { validateGraphQLErrorCode } from 'utils/error'
import { generateTabItems } from 'utils/tabs'

import { GET_INSPECTION_WEEK_CALENDAR_BY_CURBO_SPOT_ID } from 'graphQL/Common/Dealer/queries'
import { GET_STATES } from 'graphQL/Common/State/queries'
import {
  CREATE_INSPECTIONS_WEEK_CALENDAR,
  CREATE_TEST_DRIVE_WEEK_CALENDAR,
  UPDATE_CURBO_SPOT,
} from 'graphQL/Operations/CurboSpot/Detail/mutations'
import { GET_CURBO_SPOT_BY_ID } from 'graphQL/Operations/CurboSpot/Detail/queries'
import { UPDATE_TEST_DRIVE_AND_INSPECTIONS_WEEK_CALENDAR } from 'graphQL/Operations/OutOfSpot/mutations'
import { GET_TEST_DRIVE_WEEK_CALENDAR_BY_CURBO_SPOT_ID } from 'graphQL/Operations/OutOfSpot/queries'

import { ContentContainer, Layout, StyledLink } from 'styles/inspection/detail'
import { colors } from 'styles/theme'

export type StateOption = {
  cities: Option[]
} & Option

const CurboSpotDetailPage = () => {
  const { curboSpotId } = useParams<{ curboSpotId: string }>()
  const { text } = useTranslation(textFiles.CURBO_SPOT_DETAIL)
  const { general: translation } = text
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { show } = useNotification()

  const [tab, setTab] = useState<number>(0)
  const history = useHistory()

  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })
  const [status, setStatus] = useState<PublicationStatus>(
    PublicationStatus.UNPUBLISHED
  )
  const [curboSpotData, setCurboSpotData] =
    useState<CurboSpotDetailDataType | null>(null)
  const [inspectionSchedules, setInspectionSchedules] =
    useState<ScheduleResponse>(initialOutOfSpotState.inspections)
  const [testDriveSchedules, setTestDriveSchedules] =
    useState<ScheduleResponse>(initialOutOfSpotState.testDrive)

  const [testDriveWeekCalendarId, setTestDriveWeekCalendarId] =
    useState<string>('')

  const [inspectionWeekCalendarId, setInspectionWeekCalendarId] =
    useState<string>('')

  const [states, setStates] = useState<StateOption[]>([])

  const [apolloError, setApolloError] = useState<ApolloError | null>(null)

  const getWeekData = (weekData: SpotWeekCalendar) => {
    const week = { ...weekData }
    const {
      id,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    } = week

    return {
      responseId: id,
      responseWeekCalendar: {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
      },
    }
  }

  const { loading: curboSpotLoading } = useQuery<
    GenericData<CurboSpotDetailDataType>,
    GenericInputVariable<string>
  >(GET_CURBO_SPOT_BY_ID, {
    variables: {
      input: curboSpotId,
    },
    onError(error) {
      setApolloError(error)
    },
    onCompleted(response) {
      setCurboSpotData(response.data)
      setStatus(response.data.status)
    },
  })

  const { loading: stateLoading } = useQuery<GenericData<StateOption[]>>(
    GET_STATES,
    {
      onCompleted(response) {
        setStates(
          response.data.filter((stateRes) => stateRes.cities.length > 0)
        )
      },
    }
  )

  const { loading: inspectionLoading } = useQuery<
    GenericData<SpotWeekCalendar>,
    GenericInputVariable<string>
  >(GET_INSPECTION_WEEK_CALENDAR_BY_CURBO_SPOT_ID, {
    variables: {
      input: curboSpotId,
    },
    onCompleted(response) {
      const { responseId, responseWeekCalendar } = getWeekData(response.data)
      setInspectionSchedules(responseWeekCalendar)
      setInspectionWeekCalendarId(responseId)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        ENTITY_NOT_FOUND_ERROR
      )
      if (errorExists) {
        setInspectionSchedules(initialOutOfSpotState.inspections)
      }
    },
  })

  const { loading: testDriveLoading } = useQuery<
    GenericData<SpotWeekCalendar>,
    GenericInputVariable<string>
  >(GET_TEST_DRIVE_WEEK_CALENDAR_BY_CURBO_SPOT_ID, {
    variables: {
      input: curboSpotId,
    },
    onCompleted(response) {
      const { responseId, responseWeekCalendar } = getWeekData(response.data)
      setTestDriveWeekCalendarId(responseId)
      setTestDriveSchedules(responseWeekCalendar)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        ENTITY_NOT_FOUND_ERROR
      )
      if (errorExists) {
        setTestDriveSchedules(initialOutOfSpotState.testDrive)
      }
    },
  })

  const [updateCurboSpot, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<UpdateCurboSpotInput>
  >(UPDATE_CURBO_SPOT, {
    onCompleted() {
      show({ updatedSeverity: 'success', message: translation.updateSuccess })
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
          message: translation.updateFail,
        })
    },
    refetchQueries: [GET_CURBO_SPOT_BY_ID],
  })

  const [
    updateInspectionAndTestDriveWeekCalendar,
    { loading: calendarLoading },
  ] = useMutation<
    GenericData<OutOfSpotUpdateResponse>,
    PairUpdateOutOfSpotInputType
  >(UPDATE_TEST_DRIVE_AND_INSPECTIONS_WEEK_CALENDAR, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: translation.updateSuccess,
      })
    },
    onError(error) {
      console.error(error)
    },
  })

  const [createTestDriveWeekCalendar, { loading: createTestDriveLoading }] =
    useMutation<
      GenericData<string>,
      GenericInputVariable<CreateCurboSpotWeekCalendarInput>
    >(CREATE_TEST_DRIVE_WEEK_CALENDAR, {
      onCompleted() {
        show({
          updatedSeverity: 'success',
          message: translation.updateSuccess,
        })
      },
      onError() {
        show({
          updatedSeverity: 'error',
          message: translation.updateFail,
        })
      },
      refetchQueries: [GET_TEST_DRIVE_WEEK_CALENDAR_BY_CURBO_SPOT_ID],
    })

  const [createInspectionWeekCalendar, { loading: createInspectionLoading }] =
    useMutation<
      GenericData<string>,
      GenericInputVariable<CreateCurboSpotWeekCalendarInput>
    >(CREATE_INSPECTIONS_WEEK_CALENDAR, {
      onCompleted() {
        show({
          updatedSeverity: 'success',
          message: translation.updateSuccess,
        })
      },
      onError() {
        show({
          updatedSeverity: 'error',
          message: translation.updateFail,
        })
      },
      refetchQueries: [GET_INSPECTION_WEEK_CALENDAR_BY_CURBO_SPOT_ID],
    })

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTab(value)
    history.replace(`#${initialItems[value].url}`)
  }

  const handleStatusChange = (newStatus: PublicationStatus) => {
    if (newStatus !== status) {
      updateCurboSpot({
        variables: {
          input: {
            where: {
              id: curboSpotId,
            },
            data: {
              status: newStatus,
            },
          },
        },
      })
      setStatus(newStatus)
    }
  }

  const handleSave = async (spotData: CurboSpotDetailDataType) => {
    const {
      address,
      city,
      latitude,
      longitude,
      name,
      telephoneNumber,
      supportsTestDrive,
      supportsInspection,
      supportsOffSiteTestDrive,
      supportsOffSiteInspection,
      hasChangedPicture,
      fileState,
    } = spotData
    try {
      const image =
        hasChangedPicture && fileState
          ? await uploadImageService(fileState)
          : undefined
      const response = await updateCurboSpot({
        variables: {
          input: {
            where: {
              id: curboSpotId,
            },
            data: {
              address,
              city: city.id as string,
              latitude,
              longitude,
              mainPicture: image && image.data,
              name,
              telephoneNumber,
              supportsTestDrive,
              supportsInspection,
              supportsOffSiteTestDrive,
              supportsOffSiteInspection,
            },
          },
        },
      })

      if (response.errors) return false
      return true
    } catch {
      show({
        updatedSeverity: 'error',
        message: generalText.notificationText.uploadError,
      })
      return false
    }
  }

  const updateSchedules = async (newSchedules: HoursPair) => {
    const testDriveSchedule = { ...emptyInputWeekCalendar }
    const inspectionsSchedule = { ...emptyInputWeekCalendar }
    arrayOfNamedDays.forEach((day) => {
      inspectionsSchedule[day] = newSchedules.inspections[day]
        .filter((h) => h.checked)
        .map((h) => h.value)
      testDriveSchedule[day] = newSchedules.testDrive[day]
        .filter((h) => h.checked)
        .map((h) => h.value)
    })

    try {
      const response = await updateInspectionAndTestDriveWeekCalendar({
        variables: {
          testDrive: {
            where: {
              id: testDriveWeekCalendarId,
            },
            data: {
              ...testDriveSchedule,
            },
          },
          inspections: {
            where: {
              id: inspectionWeekCalendarId,
            },
            data: {
              ...inspectionsSchedule,
            },
          },
        },
      })
      setTestDriveSchedules(newSchedules.testDrive)
      setInspectionSchedules(newSchedules.inspections)

      if (response.errors) {
        try {
          if (testDriveWeekCalendarId.length === 0) {
            createTestDriveWeekCalendar({
              variables: {
                input: {
                  curboSpot: curboSpotId,
                  ...testDriveSchedule,
                },
              },
            })
            setTestDriveSchedules(newSchedules.testDrive)
          }
          if (inspectionWeekCalendarId.length === 0) {
            createInspectionWeekCalendar({
              variables: {
                input: {
                  curboSpot: curboSpotId,
                  ...inspectionsSchedule,
                },
              },
            })
            setInspectionSchedules(newSchedules.inspections)
          }
        } catch (error) {
          console.error(error)
          return false
        }
      }
      return true
    } catch (error) {
      show({
        updatedSeverity: 'error',
        message: translation.updateFail,
      })
      return false
    }
  }

  const configurationLoading = {
    calendarLoading,
    submitLoading,
    createTestDriveLoading,
    createInspectionLoading,
  }

  const isLoading =
    curboSpotLoading || stateLoading || testDriveLoading || inspectionLoading

  const schedules: OutOfSpotModel = {
    testDrive: testDriveSchedules,
    inspections: inspectionSchedules,
  }

  useEffect(() => {
    if (history.location.hash) {
      let initialValue = 0
      const thisUrl = history.location.hash.split('#')[1]
      Object.keys(translation.tabs).forEach((key, index) => {
        if (key === thisUrl) {
          initialValue = index
        }
      })
      setTab(initialValue)
    }
  }, [history.location.hash, translation.tabs])

  if (isLoading) return <LoadingAnimation showAnimation={isLoading} />

  if (apolloError) return <Redirect push to={routes.NOT_FOUND_ERROR} />

  return curboSpotData && states.length > 0 ? (
    <Layout>
      <StyledLink to={routes.CURBO_SPOT_LISTING}>
        <Typography
          variant="h3"
          color={colors.blue}
        >{`< ${translation.backButton}`}</Typography>
      </StyledLink>
      <Box display="flex" alignItems="center" paddingTop="0.5rem">
        <Typography variant="h3" color={colors.black} marginRight="1rem">
          {translation.title} #{curboSpotId}
        </Typography>
        <Tag
          backgroundColor={submitLoading ? colors.gray : undefined}
          color={submitLoading ? colors.black : undefined}
          status={status}
        />
        <StatusChangePopper
          loading={submitLoading}
          status={status}
          setStatus={handleStatusChange}
        />
      </Box>
      <DetailSubHeader text={translation.description} />
      <DetailNavTab
        tab={tab}
        handleTabChange={handleTabChange}
        items={initialItems}
      />
      <ContentContainer>
        <TabPanel value={tab} index={0}>
          <Information
            information={curboSpotData}
            states={states}
            handleSave={handleSave}
            loading={submitLoading}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Configuration
            information={curboSpotData}
            schedules={schedules}
            updateSchedules={updateSchedules}
            handleSave={handleSave}
            configurationLoading={configurationLoading}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Location
            id={curboSpotId}
            information={curboSpotData}
            handleSave={handleSave}
            loading={submitLoading}
          />
        </TabPanel>
      </ContentContainer>
    </Layout>
  ) : null
}

export default CurboSpotDetailPage
