import React from 'react'
import { Card, Typography } from '@mui/material'

import { InspectionOriginEnum } from 'constants/service'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { colors, FONT_WEIGHT } from 'styles/theme'

export type InspectionTagProps = {
  type: InspectionOriginEnum | 'NOTES' | 'ABSENCE'
  number?: number
}

const InspectionTag = ({ type, number }: InspectionTagProps) => {
  const { text } = useTranslation(textFiles.INSPECTOR_CALENDAR)
  const { inspectorCard: translation } = text
  const cardInfo: {
    [key in InspectionOriginEnum | 'NOTES' | 'ABSENCE']?: {
      name: string
      color: string
    }
  } = {
    UNKNOWN: { name: 'UNKNOWN', color: colors.gray },
    FTC: { name: 'FTC', color: colors.blue },
    SMC: { name: 'SMC', color: colors.darkAquaBlue },
    DEALER: { name: translation.dealer, color: colors.navyBlue },
    NOTES: { name: translation.notes, color: colors.green },
    ABSENCE: { name: translation.absence, color: colors.pink },
  }

  const message = {
    number: `${number} (${cardInfo[type]?.name})`,
    noNumber: cardInfo[type]?.name,
  }
  return (
    <Card
      sx={{
        backgroundColor: cardInfo[type]?.color,
        width: `${type === 'ABSENCE' ? '100%' : 'fit-content'}`,
        height: '23px',
        paddingX: `${type === 'ABSENCE' ? '0px' : '15px'}`,
      }}
    >
      <Typography
        variant="subtitle1"
        color={colors.commonWhite}
        fontWeight={FONT_WEIGHT.BOLD}
        textAlign="center"
      >
        {number ? message.number : message.noNumber}
      </Typography>
    </Card>
  )
}

export default InspectionTag
