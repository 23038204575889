import React from 'react'
import { checkboxClasses, Typography } from '@mui/material'

import Box from 'components/Common/Box'
import Checkbox from 'components/Common/Checkbox'
import ErrorMessage from 'components/Common/ErrorMessage'

import { colors } from 'styles/theme'

type CheckboxCellProps = {
  label: string
  checked: boolean
  edit?: boolean
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  name?: string
  error?: boolean
  errorText?: string
  labelError?: boolean
}

const CheckboxCell = ({
  label,
  edit,
  onChange,
  name,
  error,
  errorText,
  labelError,
  checked,
}: CheckboxCellProps) => {
  return (
    <Box>
      <Typography
        color={labelError || error ? colors.lightRed : colors.black}
        variant="body1"
      >
        {label}
      </Typography>
      <Checkbox
        label=""
        name={name}
        onChange={onChange}
        checked={checked}
        disabled={!edit}
        sx={{
          [`&.${checkboxClasses.root}`]: {
            padding: '3px 0 0 9px',
          },
        }}
        testId={`${name}-checkbox`}
      />
      {error && errorText ? (
        <ErrorMessage
          sx={{
            paddingLeft: '0',
            marginTop: '0.25rem',
            alignItems: 'flex-start',
          }}
          text={errorText}
        />
      ) : null}
    </Box>
  )
}

export default CheckboxCell
