import React, { FunctionComponent, useState } from 'react'
import { InputBaseComponentProps } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Accordion from 'components/Common/Accordion'
import Box from 'components/Common/Box'
import CheckboxCell from 'components/Inventory/Common/CheckboxCell'
import EditContainer from 'components/Inventory/Common/EditContainer'
import InformationCell from 'components/Inventory/Common/InformationCell'
import NumberValueInput from 'components/Inventory/Common/NumberValueInput'

import { CarProviderEnum } from 'constants/Inventory/detail'
import { textFiles } from 'constants/textFiles'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import { Offer, Price } from 'models/services/car'
import { InventoryCar, UpdateCarInput } from 'models/services/inventory/detail'
import { formatPhoneNumber } from 'utils/phone'

import { StyledGrid } from 'styles/inventory/detail'
import { colors } from 'styles/theme'

import DealerRepresentative from './DealerRepresentative'

const calculateListingPrice = (
  basePrice: number,
  fee: number,
  repairCost: number,
  offer: Offer
): number => {
  const { hasOffer, discount } = offer

  return basePrice + fee + repairCost - (hasOffer && discount ? discount : 0)
}

const calculateTotalPrice = (price: Price, offer: Offer): number => {
  const { basePrice, fee, licensePlate, repairCost, transfer, warranty } = price
  const { hasOffer, discount } = offer
  const totalPrice =
    basePrice +
    fee +
    warranty +
    licensePlate +
    repairCost +
    transfer -
    (hasOffer && discount ? discount : 0)

  return totalPrice
}

type VehicleInformationProps = {
  car: InventoryCar
  isLoading: boolean
  handleUpdate: (update: UpdateCarInput) => Promise<boolean | InventoryCar>
  isEditable: boolean
}

const VehiclePricing = ({
  car,
  handleUpdate,
  isLoading,
  isEditable,
}: VehicleInformationProps) => {
  const { curboSpot, dealer, offerData, owner, priceInfo, provider } = car
  const { licensePlate, repairCost, warranty } = priceInfo
  const { discount, hasOffer } = offerData

  const [edit, setEdit] = useState<boolean>(false)
  const [listingPrice, setListingPrice] = useState<number>(
    calculateListingPrice(
      priceInfo.basePrice,
      priceInfo.fee,
      priceInfo.repairCost,
      offerData
    )
  )
  const [totalPrice, setTotalPrice] = useState<number>(
    calculateTotalPrice(priceInfo, offerData)
  )

  const {
    text: { vehiclePricing: translation },
  } = useTranslation(textFiles.INVENTORY_DETAIL)
  const {
    text: { providerType: generalText },
  } = useTranslation(textFiles.GENERAL)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const appSetting = useSetting()[2]
  const currency = appSetting ? appSetting.currency : null
  const priceCurrency = currency ? `${currency.code}` : ''

  const validationSchema = yup.object().shape({
    basePrice: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    transfer: yup
      .number()
      .required(validationText.fieldRequired)
      .min(0, validationText.greaterOrEqualThanZero),
    fee: yup
      .number()
      .required(validationText.fieldRequired)
      .min(0, validationText.greaterOrEqualThanZero),
    licensePlate: yup
      .number()
      .required(validationText.fieldRequired)
      .min(0, validationText.greaterOrEqualThanZero),
    warranty: yup
      .number()
      .required(validationText.fieldRequired)
      .min(0, validationText.greaterOrEqualThanZero),
    repairCost: yup
      .number()
      .required(validationText.fieldRequired)
      .min(0, validationText.greaterOrEqualThanZero),
  })

  const formik = useFormik<Price>({
    initialValues: priceInfo,
    validationSchema,
    onSubmit: async (values) => {
      // only triggers mutation if form has changed
      const newBasePrice = values.basePrice
      const newFee = values.fee
      const newRepairCost = values.repairCost
      if (formik.dirty) {
        const updateResponse = await handleUpdate({
          vehiclePrice: values,
        })
        // after submit, we need to get the new data and reset form to clean the dirty flag
        if (updateResponse) {
          const newOffer =
            typeof updateResponse !== 'boolean'
              ? updateResponse.offerData
              : offerData
          setListingPrice(
            calculateListingPrice(newBasePrice, newRepairCost, newFee, newOffer)
          )
          setTotalPrice(calculateTotalPrice(values, newOffer))
          formik.resetForm({
            values,
          })
          setEdit(false)
        }
      } else setEdit(false)
    },
  })

  const handleEdit = () => {
    setEdit(true)
  }

  const handleCancelEdit = () => {
    formik.resetForm({
      values: priceInfo,
    })
    setEdit(false)
  }

  const handleSaveEdit = () => {
    formik.handleSubmit()
  }

  const handleCheckChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    formik.setValues({
      ...formik.values,
      customPrice: checked,
      ...(!checked && {
        licensePlate,
        warranty,
        repairCost,
      }),
    })
  }

  const prizeCustomizable = edit && formik.values.customPrice

  return (
    <Box
      minHeight="650px"
      sx={{
        opacity: isLoading ? '0.5' : 'unset',
      }}
    >
      <EditContainer
        edit={edit}
        handleCancelEdit={handleCancelEdit}
        handleSaveEdit={handleSaveEdit}
        handleEdit={handleEdit}
        loading={isLoading}
        isEditable={isEditable}
      />
      <Accordion
        defaultExpanded
        title={translation.providerInformation.label}
        sx={{
          margin: '0px!important',
        }}
      >
        <StyledGrid>
          <InformationCell
            label={translation.providerInformation.typeLabel}
            value={generalText[provider]}
          />
          {provider === CarProviderEnum.DEALER && (
            <>
              <InformationCell
                label={translation.providerInformation.dealerNameLabel}
                value={dealer.name}
              />
              <InformationCell
                label={translation.providerInformation.dealerPhoneNumberLabel}
                value={formatPhoneNumber(dealer.telephoneNumber)}
              />
              <DealerRepresentative
                dealerContacts={dealer.dealerContacts}
                nameLabel={
                  translation.providerInformation.representativeNameLabel
                }
                phoneLabel={
                  translation.providerInformation.representativePhoneNumberLabel
                }
              />
              <InformationCell
                label={translation.providerInformation.dealerLocationLabel}
                value={dealer.address}
              />
            </>
          )}
          {provider === CarProviderEnum.CLIENT && (
            <>
              <InformationCell
                label={translation.providerInformation.clientNameLabel}
                value={owner.name}
              />
              <InformationCell
                label={translation.providerInformation.clientLastNameLabel}
                value={owner.lastName}
              />
              <InformationCell
                label={translation.providerInformation.clientPhoneNumberLabel}
                value={formatPhoneNumber(owner.telephoneNumber)}
              />
              <InformationCell
                label={translation.providerInformation.clientEmailLabel}
                value={owner.email}
              />
              <InformationCell
                label={translation.providerInformation.clientLocationLabel}
                value={owner.address}
              />
            </>
          )}
          <InformationCell
            label={translation.providerInformation.curboSpotLabel}
            value={curboSpot.name}
          />
          {provider === CarProviderEnum.CURBO_SPOT && (
            <>
              <InformationCell
                label={
                  translation.providerInformation.curboSpotPhoneNumberLabel
                }
                value={formatPhoneNumber(curboSpot.telephoneNumber)}
              />
              <InformationCell
                label={translation.providerInformation.curboSpotLocationLabel}
                value={curboSpot.address}
              />
            </>
          )}
        </StyledGrid>
      </Accordion>
      <Accordion defaultExpanded title={translation.pricing.label}>
        <StyledGrid>
          <InformationCell
            label={translation.pricing.basePriceLabel}
            value={formik.values.basePrice}
            edit={edit}
            name="basePrice"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            inputComponent={
              NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>
            }
            thousandSeparator
            error={Boolean(formik.errors.basePrice)}
            errorText={formik.errors.basePrice}
            endAdornment={priceCurrency}
          />
          <InformationCell
            label={translation.pricing.curboCertificationLabel}
            value={formik.values.fee}
            thousandSeparator
            edit={edit}
            name="fee"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            inputComponent={
              NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>
            }
            error={Boolean(formik.errors.fee)}
            errorText={formik.errors.fee}
            endAdornment={priceCurrency}
          />
          <InformationCell
            label={translation.pricing.transferCostLabel}
            value={formik.values.transfer}
            thousandSeparator
            edit={edit}
            name="transfer"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            inputComponent={
              NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>
            }
            error={Boolean(formik.errors.transfer)}
            errorText={formik.errors.transfer}
            endAdornment={priceCurrency}
          />
          <CheckboxCell
            label={translation.pricing.personalizationLabel}
            name="customPrice"
            checked={formik.values.customPrice}
            onChange={handleCheckChange}
            edit={edit}
          />
          <InformationCell
            label={translation.pricing.licensePlateLabel}
            value={formik.values.licensePlate}
            thousandSeparator
            edit={prizeCustomizable}
            name="licensePlate"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            inputComponent={
              NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>
            }
            error={Boolean(formik.errors.licensePlate)}
            errorText={formik.errors.licensePlate}
            endAdornment={priceCurrency}
          />
          <InformationCell
            label={translation.pricing.warrantyPriceLabel}
            value={formik.values.warranty}
            thousandSeparator
            edit={prizeCustomizable}
            name="warranty"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            inputComponent={
              NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>
            }
            error={Boolean(formik.errors.warranty)}
            errorText={formik.errors.warranty}
            endAdornment={priceCurrency}
          />
          <InformationCell
            label={translation.pricing.repairCostsLabel}
            value={formik.values.repairCost}
            thousandSeparator
            edit={prizeCustomizable}
            name="repairCost"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            inputComponent={
              NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>
            }
            error={Boolean(formik.errors.repairCost)}
            errorText={formik.errors.repairCost}
            endAdornment={priceCurrency}
          />
          <InformationCell
            label={translation.pricing.listingPriceLabel}
            value={listingPrice}
            thousandSeparator
            endAdornment={priceCurrency}
          />
          <InformationCell
            label={translation.pricing.totalPriceLabel}
            value={totalPrice}
            thousandSeparator
            endAdornment={priceCurrency}
          />
          <InformationCell
            label={translation.pricing.discountLabel}
            value={hasOffer && discount ? discount : 0}
            thousandSeparator
            valueColor={colors.green}
            endAdornment={priceCurrency}
          />
        </StyledGrid>
      </Accordion>
    </Box>
  )
}

export default VehiclePricing
