import React, { useState } from 'react'
import DateRangeIcon from '@mui/icons-material/DateRange'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MuiPopper from '@mui/material/Popper'

import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import { TabObjects } from 'components/Common/Tabs'
import Title from 'components/Common/Title'
import ExtendedCalendarRange from 'components/General/ExtendedCalendarRange'

import { inspectionTextFile } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useTranslation from 'hooks/useTranslation'
import {
  CalendarRangeType,
  DateRangeCalendarTabType,
  DateRangeTitleTabType,
} from 'models/date'
import {
  formatDateTitle,
  getStartOfCurrentMonth,
  getStartOfCurrentWeek,
  getStartOfCurrentYear,
} from 'utils/date'

import { StyledContainer } from './style'

export type TimeFilterProps = {
  title?: string
  handleChangeDateRange: (newDateRange: CalendarRangeType) => void
  dateRange: CalendarRangeType
}

const TimeFilter = ({
  title = '',
  dateRange,
  handleChangeDateRange,
}: TimeFilterProps) => {
  const [titleTabValue, setTitleTabValue] = useState<
    DateRangeTitleTabType | boolean
  >(DateRangeTitleTabType.TODAY)

  const [calendarTabValue, setCalendarTabValue] = useState<
    DateRangeCalendarTabType | boolean
  >(false)

  const [language] = useLocale()
  const { text } = useTranslation(inspectionTextFile.INSPECTION_DASHBOARD)
  const {
    inspections: { titleTabs: translation, timeFilter: timeFilterTranslation },
  } = text

  // manage popper
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const maintainPopover = () => {
    setAnchorEl(anchorEl)
  }

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopperClose = () => {
    setAnchorEl(null)
  }

  // functions to manage tabs change
  const handleTitleTabChange = (newValue: string | number) => {
    const currentValue = String(newValue) as DateRangeTitleTabType
    setTitleTabValue(currentValue)
    setCalendarTabValue(false)
  }

  const handleCalendarTabChange = (newValue: DateRangeCalendarTabType) => {
    setCalendarTabValue(newValue)
    setTitleTabValue(false)
  }

  //  manage date and tabs
  const handleTodayClick = () => {
    handleChangeDateRange({ fromDate: new Date(), toDate: new Date() })
  }

  const handleWeekClick = () => {
    const startOfWeek = getStartOfCurrentWeek()
    handleChangeDateRange({ fromDate: startOfWeek, toDate: new Date() })
  }

  const handleMonthClick = () => {
    const startOfMonth = getStartOfCurrentMonth()
    handleChangeDateRange({ fromDate: startOfMonth, toDate: new Date() })
  }

  const handleClickYear = () => {
    const startOfMonth = getStartOfCurrentYear()
    handleChangeDateRange({ fromDate: startOfMonth, toDate: new Date() })
  }

  const handleAllClick = () => {
    handleChangeDateRange({ fromDate: null, toDate: null })
  }

  const items: TabObjects[] = [
    {
      id: 1,
      item: {
        label: translation.dayLabel,
        value: DateRangeTitleTabType.TODAY,
        onClick: handleTodayClick,
      },
    },
    {
      id: 2,
      item: {
        label: translation.weekLabel,
        value: DateRangeTitleTabType.WEEK,
        onClick: handleWeekClick,
      },
    },
    {
      id: 3,
      item: {
        label: translation.monthLabel,
        value: DateRangeTitleTabType.MONTH,
        onClick: handleMonthClick,
      },
    },
    {
      id: 4,
      item: {
        label: translation.yearLabel,
        value: DateRangeTitleTabType.YEAR,
        onClick: handleClickYear,
      },
    },
    {
      id: 5,
      item: {
        label: translation.allLabel,
        value: DateRangeTitleTabType.ALL,
        onClick: handleAllClick,
      },
    },
  ]

  return (
    <StyledContainer>
      <Title
        title={title}
        subtitle={formatDateTitle(
          dateRange,
          timeFilterTranslation,
          language.code
        )}
        tabs={items}
        icon={<DateRangeIcon />}
        tabValue={titleTabValue}
        handleTabChange={handleTitleTabChange}
        disableDivider
      />
      <Button
        id="date-range-button"
        endIcon={<ExpandMoreIcon />}
        size="small"
        style={{
          borderRadius: '5px',
          height: '24px',
          padding: '0px 8px',
          marginBottom: '4px',
        }}
        onMouseEnter={handlePopperOpen}
        onMouseLeave={handlePopperClose}
        data-cy="date-range-button"
      >
        {translation.setLabel}
      </Button>
      <MuiPopper
        id="date-range-popover"
        open={open}
        onMouseEnter={maintainPopover}
        onMouseLeave={handlePopperClose}
        anchorEl={anchorEl}
        placement="bottom-end"
        style={{
          zIndex: 1500,
        }}
      >
        <Box>
          <ExtendedCalendarRange
            tabValue={calendarTabValue}
            handleTabChange={handleCalendarTabChange}
            dateRange={dateRange}
            handleChangeDateRange={handleChangeDateRange}
          />
        </Box>
      </MuiPopper>
    </StyledContainer>
  )
}
export default TimeFilter
