import { AutocompleteItem } from 'components/Common/Autocomplete'
import { Image } from 'components/Common/Carousel'

import { VEHICLE_CREATION_OPTION } from 'constants/Inventory/creation'
import { AltCurboSpot } from 'models/curboSpot'
import { Price } from 'models/services/car'

import { BaseEntity } from '../base'
import { TrimDetailDataType } from '../CarSettings/detail'
import { AltDealer } from '../curboSpot'
import { ExtendedStatus } from '../status'

export enum CarProviderEnum {
  CLIENT = 'CLIENT',
  CURBO_SPOT = 'CURBO_SPOT',
  DEALER = 'DEALER',
}

export type FirstStepModel = {
  make: BaseEntity | null
  model: BaseEntity | null
  vehicleYear: BaseEntity | null
  trimLevel: BaseEntity | null
  status: ExtendedStatus | null
  currentModelList: BaseEntity[]
  currentYearList: BaseEntity[]
  currentTrimLevelList: BaseEntity[]
}
export type SecondStepModel = {
  mileage: string
  categories: string[]
  interiorColor: string
  exteriorColor: string
  vinNumber: string
  chassisNumber: string
  countryVersion: string
  licensePlate: string
}

export type ThirdStepModel = {
  transmission: string
  bodyStyle: string
  driveTrain: string
  fuelType: string
  features: string[]
  detailedTrimLevel: TrimDetailDataType | null
}

export type FourthStepModel = {
  frontLegRoom: number
  backLegRoom: number
  frontHeadRoom: number
  backHeadRoom: number
  engineDisplacement: number
  cargoCapacity: number
  cargo: number
}

export type FifthStepModel = {
  length: number
  width: number
  height: number
  seats: number
  doors: number
}

export type SixthStepModel = {
  mpg: number
  mpgCity: number
  mpgHgw: number
  fuelCapacity: number
  cylinders: number
  torque: number
  torqueRpm: number
  horsePower: number
  horsePowerRpm: number
}

export type VehicleInformationModel = {
  firstStep: FirstStepModel
  secondStep: SecondStepModel
  thirdStep: ThirdStepModel | null
  fourthStep: FourthStepModel | null
  fifthStep: FifthStepModel | null
  sixthStep: SixthStepModel | null
}

export type ProviderInformationModel = {
  providerOption: AutocompleteItem
  curboSpot: AltCurboSpot | null
  dealer: AltDealer | null
}

export type VehiclePhotosModel = {
  thumbnailPicture: string
  thumbnail: File
  interiorPictures: File[]
  interiorPictureImages: Image[]
  exteriorPictures: File[]
  exteriorPictureImages: Image[]
}

export type VehicleCreationDataType = {
  vehicleInformation: VehicleInformationModel
  providerInformation: ProviderInformationModel
  vehiclePrice: Price
  vehiclePhotos: VehiclePhotosModel
}

export type VehicleCreationAction =
  | {
      type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION
      payload: VehicleInformationModel
    }
  | {
      type: VEHICLE_CREATION_OPTION.UPDATE_PROVIDER_INFORMATION
      payload: ProviderInformationModel
    }
  | {
      type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_PRICE
      payload: Price
    }
  | {
      type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_PHOTOS
      payload: VehiclePhotosModel
    }
