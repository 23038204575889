import { styled } from '@mui/material'

import Box from 'components/Common/Box'
import Button from 'components/Common/Button'

import { FONT_WEIGHT } from 'styles/theme'

export const StyledButtonContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: 10,
})

export const StyledButton = styled(Button)({
  height: '30px !important',
  fontWeight: `${FONT_WEIGHT.MEDIUM} !important`,
})
