import {
  BaseColumnField,
  BlackListColumnField,
} from 'constants/CarSettings/columnField'
import { SelectItem } from 'models/Select'
import { BlackListTextType } from 'models/text/blackList'

export const createDefaultBlackListFieldSelectItems = (
  text: BlackListTextType,
  isSupervisor: boolean
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: BaseColumnField.ID,
    },
    {
      label: text.entityType,
      value: BlackListColumnField.ENTITY_TYPE,
    },
    {
      label: text.name,
      value: BaseColumnField.NAME,
    },
    {
      label: text.blackListedAt,
      value: BlackListColumnField.BLACK_LISTED_AT,
    },
    {
      label: text.comment,
      value: BlackListColumnField.COMMENT,
    },
    ...(isSupervisor
      ? [
          {
            label: text.actions,
            value: BaseColumnField.ACTIONS,
          },
        ]
      : []),
  ]
}

export const createBlackListModelFieldSelectItems = (
  text: BlackListTextType,
  isSupervisor: boolean
): SelectItem[] => {
  const items = createDefaultBlackListFieldSelectItems(text, isSupervisor)
  return [
    ...items,
    {
      label: text.brandName,
      value: BlackListColumnField.BRAND_NAME,
    },
  ]
}

export const createBlackListTrimFieldSelectItems = (
  text: BlackListTextType,
  isSupervisor: boolean
): SelectItem[] => {
  const items = createDefaultBlackListFieldSelectItems(text, isSupervisor)
  return [
    ...items,
    {
      label: text.carName,
      value: BlackListColumnField.CAR_NAME,
    },
  ]
}

export const createBlackListFieldSelectLabels = (
  text: BlackListTextType
): Record<string, string> => {
  return {
    id: text.id,
    blackListedAt: text.blackListedAt,
    actions: text.actions,
    name: text.name,
    entityType: text.entityType,
    comment: text.comment,
    carName: text.carName,
    brandName: text.brandName,
  }
}

export const blackListMakeDefaultFields = [
  BaseColumnField.ID,
  BlackListColumnField.ENTITY_TYPE,
  BaseColumnField.NAME,
  BlackListColumnField.BLACK_LISTED_AT,
  BlackListColumnField.COMMENT,
  BaseColumnField.ACTIONS,
]

export const blackListModelDefaultFields = [
  BaseColumnField.ID,
  BlackListColumnField.ENTITY_TYPE,
  BaseColumnField.NAME,
  BlackListColumnField.BRAND_NAME,
  BlackListColumnField.BLACK_LISTED_AT,
  BlackListColumnField.COMMENT,
  BaseColumnField.ACTIONS,
]

export const blackListTrimDefaultFields = [
  BaseColumnField.ID,
  BlackListColumnField.ENTITY_TYPE,
  BaseColumnField.NAME,
  BlackListColumnField.CAR_NAME,
  BlackListColumnField.BLACK_LISTED_AT,
  BlackListColumnField.COMMENT,
  BaseColumnField.ACTIONS,
]
