import { gql } from '@apollo/client'

export const UPDATE_INSPECTION = gql`
  mutation updateInspection($input: UpdateInspectionInput!) {
    data: updateInspection(input: $input) {
      id
    }
  }
`

export const SCHEDULE_INSPECTION = gql`
  mutation scheduleInspection($input: ScheduleInspectionInput!) {
    scheduleInspection(input: $input) {
      id
    }
  }
`

export const APPROVE_INSPECTION = gql`
  mutation approveInspection($input: ID!) {
    approveInspection(id: $input) {
      id
    }
  }
`
