import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { StyledButton, StyledButtonContainer } from './style'

type BackForwardStepButtonProps = {
  size?: 'small' | 'medium' | 'large'
  startIcon?: React.ReactNode
  disabled?: boolean
  handleClick: () => void
  label: string
  testId?: string
}

export const BackForwardStepButton = ({
  startIcon,
  size,
  disabled,
  handleClick,
  label,
  testId,
}: BackForwardStepButtonProps) => {
  return (
    <StyledButtonContainer>
      <StyledButton
        startIcon={startIcon || <ArrowForwardIosIcon />}
        size={size || 'small'}
        role="button"
        disabled={disabled}
        onClick={handleClick}
        testId={testId}
      >
        {label}
      </StyledButton>
    </StyledButtonContainer>
  )
}
