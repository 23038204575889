import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import { styled } from '@mui/material/styles'

import Box from 'components/Common/Box'
import Container from 'components/Common/Container'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors } from 'styles/theme'

const ImageBox = styled(Box)({
  backgroundColor: colors.background,
  borderRadius: '10px',
  border: `1px solid ${colors.border}`,
  padding: '11px',
  width: '338px',
})

const PersonBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '65% 35%',
  columnGap: '27px',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '100%',
    columnGap: 0,
  },
})

const StyledBox = styled(Box)({
  borderRadius: '10px',
  backgroundColor: colors.commonWhite,
  border: `1px solid ${colors.border}`,
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 1rem',
  width: 'auto',
})

const StyledContainer = styled(Container)({
  padding: '0px !important',
})

const BlueCarIcon = styled(DirectionsCarIcon)({
  color: colors.blue,
  marginRight: '12px',
})

const contentStyle = {
  padding: '18px',
  border: `1px solid ${colors.border}`,
  borderRadius: '10px',
}

export {
  ImageBox,
  StyledBox,
  StyledContainer,
  contentStyle,
  BlueCarIcon,
  PersonBox,
}
