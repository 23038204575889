import SquareIcon from '@mui/icons-material/Square'
import { styled } from '@mui/material'

import Box from 'components/Common/Box'

import { BREAK_POINTS } from 'styles/breakpoints'

export const StyledColorSquare = styled(SquareIcon)({
  fontSize: '3rem',
})

export const StyledContainer = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  columnGap: '15px',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '100%',
  },
})

export const LogContainer = styled(Box)({
  width: '92%',
})
