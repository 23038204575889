export const inventoryStaticFields = [
  'status',
  'certified',
  'exteriorColor',
  'curboSpot',
  'carModel',
  'brand',
  'bodyStyle',
  'countryVersion',
  'fuelType',
  'transmission',
  'driveTrain',
  'dealer',
  'categories',
  'features',
  'provider',
]
