import { SelectItem } from 'models/Select'

export const createCalendarFieldLabelList = (
  fields: SelectItem[]
): Record<string, string> => {
  let labelList = {}
  fields.forEach((label) => {
    labelList = { ...labelList, [label.value]: label.label }
  })
  return labelList
}
