import React from 'react'
import { SelectChangeEvent, Typography } from '@mui/material'

import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'
import Box from 'components/Common/Box'
import Checkbox from 'components/Common/Checkbox'
import Select from 'components/Common/Select'

import { getDaysOptions } from 'constants/Operation/curboSpot'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { CheckHourType, DaysPair, ScheduleType } from 'models/outOfSpot'

import { colors } from 'styles/theme'

import { ToggleType } from '../index'
import { GridBox } from '../style'

type Props = {
  toggleInformation: ToggleType
  setToggleInformation: React.Dispatch<
    React.SetStateAction<Record<ScheduleType, ToggleType>>
  >
  hours: CheckHourType[]
  selectedDay: DaysPair
  setSelectedDay: React.Dispatch<React.SetStateAction<DaysPair>>
  handleChangeCheckbox: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: ScheduleType
  ) => void
  setHasUnsavedChanges?: React.Dispatch<React.SetStateAction<boolean>>
  type: ScheduleType
  translation: Record<string, string>
  hourContainerSize: number
  testId?: string
}

const ConfigurationItem = ({
  toggleInformation,
  setToggleInformation,
  hours,
  selectedDay,
  setSelectedDay,
  handleChangeCheckbox,
  setHasUnsavedChanges,
  type,
  translation,
  hourContainerSize,
  testId,
}: Props) => {
  const { support, offSiteSupport } = toggleInformation

  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { weekDays } = generalText
  const days = getDaysOptions(weekDays)

  const handleChangeDay = (e: SelectChangeEvent<unknown>) => {
    setSelectedDay({
      ...selectedDay,
      [type]: e.target.value as string,
    })
  }

  const changeToggle = (value: boolean, key: string) => {
    setToggleInformation((prevState) => {
      const toggle = { ...prevState }
      const currentType = { ...toggle[type], [key]: !value }
      toggle[type] = currentType

      return { ...toggle }
    })
    if (setHasUnsavedChanges) setHasUnsavedChanges(true)
  }

  return (
    <Box>
      <ToggleContainer
        title={translation.visibility}
        description={translation.visibilityDescription}
        status={support}
        handleStatus={() => changeToggle(support, 'support')}
        testId={testId}
      />
      <Box display="flex" flexDirection="column" width="100%">
        <Typography variant="body2" color={colors.black} marginBottom="1rem">
          {translation.selectDay}
        </Typography>
        <Select
          options={days}
          variant="outlined"
          name="weekDay"
          value={selectedDay[type]}
          onChange={(e) => handleChangeDay(e)}
          sx={{ fontSize: 12, height: 40, width: '100%' }}
        />
        <Typography variant="body2" color={colors.black} margin="1rem 0">
          {translation.selectHours}
        </Typography>
        <GridBox
          sx={{ gridTemplateRows: `repeat(${hourContainerSize}, 40px)` }}
        >
          {hours.map((hour) => (
            <Checkbox
              key={hour.value}
              value={hour.value}
              label={`${hour.name} ${hour.am ? 'am' : 'pm'}`}
              checked={hour.checked}
              onChange={(e) => handleChangeCheckbox(e, type)}
              testId={testId ? `${testId}-${hour.value}-checkbox` : undefined}
            />
          ))}
        </GridBox>
      </Box>
      <ToggleContainer
        title={translation.userBased}
        description={translation.userBasedDescription}
        status={offSiteSupport}
        handleStatus={() => changeToggle(offSiteSupport, 'offSiteSupport')}
        testId={testId ? `user-based-${testId}` : undefined}
      />
    </Box>
  )
}

export default ConfigurationItem
