import { styled, TextField as MUITextField } from '@mui/material'

import { colors, FONT_WEIGHT } from 'styles/theme'

import Box from '../Box'

export const StyledInputContainer = styled(Box)({
  display: 'flex',
  width: '270px',
  marginLeft: '8px',
  '& > div:nth-of-type(1)': {
    marginRight: '8px',
  },
})

export const StyledTextField = styled(MUITextField)({
  border: `1px solid ${colors.border}`,
  borderRadius: '4px',
  opacity: 1,
  width: '135px',
  height: '38px',

  '& div': {
    color: colors.placeholderGray,
    fontWeight: FONT_WEIGHT.MEDIUM,
    height: '100%',
    fontSize: '12px',
  },
  '& div fieldset': {
    border: 'none',
  },
})
