import { gql } from '@apollo/client'

export const GET_INSPECTOR_BY_ID = gql`
  query getInspectorPersonal($input: ID!) {
    data: getInspectorById(id: $input) {
      address
      email
      id
      lastName
      name
      profilePicture
      telephoneNumber
      profilePicture
      curboSpot {
        id
        name
        slug
      }
      status {
        backgroundColor
        textColor
        id
        name
      }
    }
  }
`

export const GET_INSPECTOR_ABSENCES = gql`
  query getAbsences($input: FilterInput!) {
    data: getAbsences(input: $input) {
      id
      reason {
        name
        slug
        id
      }
      startDate
      endDate
    }
  }
`

export const GET_INSPECTOR_STATS = gql`
  query getInspectorStats($input: ID!) {
    data: getInspectorStats(id: $input) {
      aborted
      approved
      forRepair
      rejected
      scheduled
      total
    }
  }
`
export const GET_INSPECTIONS_DISTRIBUTION_BY_SOURCE_AND_INSPECTOR = gql`
  query getInspectionDistributionBySourceAndInspector(
    $input: FilterByInspectorAndDateRange!
  ) {
    getInspectionDistributionBySourceAndInspector(input: $input) {
      data {
        approved
        name: origin
        rejected
      }
    }
  }
`
export const GET_ABSENCE_REASONS = gql`
  query listReasons {
    data: getAbsenceReasons {
      id
      name
      slug
    }
  }
`
