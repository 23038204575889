import React from 'react'

import { TranslationItemType } from 'components/General/Translation/TranslationListItem'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { StyledItem } from '../style'

type Props = {
  translations: TranslationItemType[]
}

/*
This component is used in any of the summary step of a setting creation process, 
it displays selected translations from previous step
*/

const TranslationItem = ({ translations }: Props) => {
  const { text } = useTranslation(textFiles.FEATURE_CREATION)
  const { summary } = text
  return (
    <StyledItem title={summary.translations}>
      <ul>
        {translations.map((translation) => (
          <li key={translation.id}>
            <span>{translation.name}</span>
            {` - ${translation.description}`}
          </li>
        ))}
      </ul>
    </StyledItem>
  )
}

export default TranslationItem
