import React from 'react'
import { CSSObject, Divider, Typography } from '@mui/material'

import Box from 'components/Common/Box'

import { colors } from 'styles/theme'

export type FilterButtonProps = {
  /**
   * Optional height prop
   * @default
   * '48px'
   */
  height?: number | string
  /**
   * Icon to be rendered on the right
   */
  icon: React.ReactNode

  id?: string
  /**
   * Function triggered when the box is clicked
   */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  /**
   * Text inside the box rendered on the left
   */
  text: string
  /**
   * Optional width prop
   * @default
   * 'auto'
   */
  width?: number | string

  /**
   * Box Custom styling
   */
  sx?: CSSObject

  /**
   * Text Custom styling
   */
  labelSx?: CSSObject

  /**
   * data-cy for cypress
   */
  testId?: string
}

/**
 * This component is not a button per say, rather a component that takes
 * a text and an icon to put a separator between it, this is styled in a way
 * that is widely used in the app for filters
 */
const FilterButton = ({
  height = '48px',
  icon,
  id,
  onClick,
  text,
  width = 'auto',
  sx,
  labelSx,
  testId,
}: FilterButtonProps) => {
  return (
    <Box
      alignItems="center"
      border={`1px solid ${colors.lightGray}`}
      borderRadius="10px"
      boxShadow={2}
      color={colors.gray}
      display="flex"
      height={height}
      id={id}
      justifyContent="space-between"
      onClick={onClick}
      sx={{
        backgroundColor: colors.commonWhite,
        cursor: 'pointer',
        ...sx,
      }}
      width={width}
      data-cy={testId}
    >
      <Typography margin="0 32px 0 16px" variant="body2" sx={labelSx}>
        {text}
      </Typography>
      <Divider orientation="vertical" flexItem />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        sx={{ margin: '0 10px' }}
      >
        {icon}
      </Box>
    </Box>
  )
}

export default FilterButton
