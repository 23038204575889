import {
  Accordion,
  accordionDetailsClasses,
  accordionSummaryClasses,
  styled,
} from '@mui/material'

import { colors } from 'styles/theme'

export const StyledAccordion = styled(Accordion)({
  backgroundColor: colors.transparent,
  boxShadow: 'unset',
  borderRadius: 'unset',
  borderBottom: `1px solid ${colors.border}`,
  '&:before': {
    display: 'none',
  },
  [`& .${accordionSummaryClasses.root}`]: {
    justifyContent: 'flex-start',
    width: 'fit-content',
    padding: 0,
  },
  [`& .${accordionSummaryClasses.root}.${accordionSummaryClasses.expanded}`]: {
    minHeight: '30px',
  },
  [`& .${accordionSummaryClasses.content}`]: {
    flexGrow: 'unset',
  },
  [`& .${accordionSummaryClasses.content}.${accordionSummaryClasses.expanded}`]:
    {
      margin: 0,
    },
  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    marginLeft: '0.25rem',
    color: colors.altBlack,
  },
  [`& .${accordionDetailsClasses.root}`]: {
    padding: 0,
  },
})
