import FileSaver from 'file-saver'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'

export const downloadImagesAsZip = (
  images: string[],
  fileName: string,
  fileExtension: string
) => {
  const zip = new JSZip()
  let count = 0

  images.forEach((imageUrl, index) => {
    JSZipUtils.getBinaryContent(
      imageUrl,
      (error: Error, data: string | ArrayBuffer) => {
        if (error) {
          throw error
        }
        zip.file(
          `${fileName}_${index}.${imageUrl.substring(
            imageUrl.lastIndexOf('.') + 1
          )}`,
          data,
          { binary: true }
        )
        count += 1

        if (count === images.length) {
          zip.generateAsync({ type: 'blob' }).then((zipFile) => {
            FileSaver.saveAs(zipFile, `${fileName}${fileExtension}`)
          })
        }
      }
    )
  })
}
