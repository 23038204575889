import React, { useState } from 'react'
import { Card, ClickAwayListener, Popper } from '@mui/material'

import Box from 'components/Common/Box'
import InspectionTag from 'components/Inspector/DistributionCalendar/InspectionTag'

import Notes from '../Notes'
import {
  CardContainer,
  NoteContainer,
  StyledNoteCard,
  StyledNoteList,
  StyledText,
} from '../Notes/style'

type ClickableTagProps = {
  data: string[] | string
  type: 'NOTES' | 'ABSENCE'
}

export const ClickableTag = ({ data, type }: ClickableTagProps) => {
  // Manage Popper's Status
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [notes, setNotes] = useState<string[]>(data as string[])
  const [motive] = useState<string>(data as string)

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen((prevState) => !prevState)
  }
  const handlePopperClose = () => {
    setOpen(false)
  }

  const handleNotesChange = (newNotes: string[]) => {
    setNotes(newNotes)
  }

  return (
    <>
      <Box onClick={handlePopperOpen} sx={{ cursor: 'pointer' }}>
        <InspectionTag type={type} />
      </Box>
      <Popper
        id="notes_popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handlePopperClose}>
          <Box>
            {type === 'NOTES' ? (
              <Notes data={notes} handleDataChange={handleNotesChange} />
            ) : (
              <CardContainer variant="outlined">
                <Box
                  style={{ padding: '1rem' }}
                  height="fit-content"
                  maxWidth={333}
                >
                  <StyledNoteList subheader={<Card />}>
                    <NoteContainer sx={{ marginBottom: '0 !important' }}>
                      <StyledNoteCard>
                        <StyledText variant="subtitle1">{motive}</StyledText>
                      </StyledNoteCard>
                    </NoteContainer>
                  </StyledNoteList>
                </Box>
              </CardContainer>
            )}
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  )
}
