import React from 'react'
import { Typography } from '@mui/material'

import Box from 'components/Common/Box'
import Step from 'components/Common/Step'
import StepProgressBar from 'components/Common/StepProgressBar'

import { BASE_SMALL_STEP_WIDTH, BASE_STEP_WIDTH } from 'constants/style'
import useWindowDimensions from 'hooks/useWindowDimensions'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors } from 'styles/theme'

export type StepperModel = {
  label: string
  isDynamicStep?: boolean
  /**
   * Optional prop, depicting if a progress bar is going to be rendered on top
   * of this Step. Needs a total substeps number to work properly.
   */
  hasPercentage?: boolean

  /**
   * Optional prop, intended to be used with a hasPercentage boolean (both together)
   */
  totalSubSteps?: number
}

type InspectionCreationStepperProps = {
  /**
   * The index current step of the multi-step wizard. This is 0-index based, meaning first step must start with a `0`
   */
  currentStep: number
  /**
   * The index of the dynamically generated step for the second step of the wizard.
   * This is 0-index based, meaning first step must start with a `0`
   */
  currentSecondaryStep?: number
  /**
   * Optional amount of steps to dynamically rendeer in the second step
   */
  secondaryStepAmount?: number
  /**
   * Array of steps for the stepper, contains a label for each step and an optional
   * dynamic step boolean, which display the dynamic stepper depending on the secondaryStepAmout.
   *
   * Note: it's important that only one item of the array has the `isDynamicStep: true`, value or
   * else multiple steps will highlight
   */
  stepperItems: StepperModel[]
  /**
   * Optional prop, intended to be used as substitute for the progressbar width,
   */
  barWidth?: number | string

  /**
   * Props for the top stepper bar
   */
  topSx?: React.CSSProperties
  optionalWidth?: string | number
}

/**
 * A Stepper that contains the amount of steps inside the `stepperItems`,
 * where the second step will show `x` amount of steps dynamically
 */
const Stepper = ({
  currentStep,
  currentSecondaryStep,
  secondaryStepAmount = 1,
  stepperItems,
  barWidth,
  topSx,
  optionalWidth = barWidth,
}: InspectionCreationStepperProps) => {
  const secondaryStepArray = Array.from(Array(secondaryStepAmount).keys())
  const { width: windowWidth } = useWindowDimensions()
  const baseWidth =
    windowWidth && windowWidth <= BREAK_POINTS.XL
      ? BASE_SMALL_STEP_WIDTH
      : BASE_STEP_WIDTH

  const width = baseWidth / secondaryStepAmount - 5

  return (
    <Box
      margin="2rem 0"
      justifyContent="center"
      display="flex"
      width="100%"
      columnGap="1rem"
      sx={topSx}
    >
      {stepperItems.map((step, idx) => {
        if (step.hasPercentage && currentSecondaryStep !== undefined) {
          return (
            <Box
              key={step.label}
              width={barWidth}
              position="relative"
              sx={{ top: '0.75rem !important' }}
            >
              <StepProgressBar
                currentStep={currentSecondaryStep}
                totalSteps={step.totalSubSteps || stepperItems.length}
                active={currentStep === idx}
                label={step.label}
                width={barWidth || baseWidth}
              />
            </Box>
          )
        }

        if (step.isDynamicStep)
          return (
            <Box key={step.label} display="flex" flexDirection="column">
              <Typography
                color={currentStep === idx ? colors.blue : colors.subtitleGray}
                variant="body2"
              >
                {step.label}
              </Typography>
              <Box
                display="flex"
                sx={{ '& :not(:last-child)': { marginRight: '5px' } }}
              >
                {secondaryStepArray.map((value) => (
                  <Box key={value}>
                    <Step
                      active={
                        currentSecondaryStep === value && currentStep === idx
                      }
                      width={barWidth || width}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          )
        return (
          <Box key={step.label} width={barWidth}>
            <Step
              active={currentStep === idx}
              label={step.label}
              width={barWidth || baseWidth}
              sx={{
                maxWidth: optionalWidth || baseWidth,
                '> div': {
                  maxWidth: optionalWidth,
                },
              }}
            />
          </Box>
        )
      })}
    </Box>
  )
}
export default Stepper
