import React, { useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add'
import HelpIcon from '@mui/icons-material/Help'

import Box from 'components/Common/Box'
import Question from 'components/Template/Detail/TemplateQuestion'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  InspectionFailureReason,
  Question as QuestionType,
} from 'models/services/template/detail'
import { TemplateStatus } from 'models/status'

import { colors } from 'styles/theme'

import { StyledButton, StyledText } from './style'

type CheckpointContentProps = {
  editMode: boolean
  questions: QuestionType[]
  handleQuestionsChange: (
    questionIndex: number,
    updatedQuestion?: QuestionType
  ) => void
  handleDeleteQuestion: (questionIndex: number) => void
  shouldUpdate: boolean
  changeEditMode: (newEditMode: boolean) => void
  failureReason: InspectionFailureReason
  checkpointIndex: number
  setAnswerMissingError: (error: boolean) => void
  setCheckpointFailureReasonError: (error: boolean) => void
  status: TemplateStatus
  saveStateCalled: boolean
  handleAddQuestion: () => void
}

/*
  Component that works as container for the questions inside the checkpoints
*/
const CheckpointContent = ({
  editMode,
  questions,
  handleQuestionsChange,
  handleDeleteQuestion,
  shouldUpdate,
  changeEditMode,
  failureReason,
  checkpointIndex,
  setAnswerMissingError,
  setCheckpointFailureReasonError,
  status,
  saveStateCalled,
  handleAddQuestion,
}: CheckpointContentProps) => {
  const { text } = useTranslation(textFiles.TEMPLATE_DETAIL)

  useEffect(() => {
    /**
     * Here, we listen for changes in the failure reason. If it is still empty after
     * an option was selected, then this checkpointFailureReasonError is toggled on,
     * so that the API is not called with this empty checkpoint
     */

    if (failureReason.id === '') {
      setCheckpointFailureReasonError(true)
    } else {
      setCheckpointFailureReasonError(false)
    }
  }, [failureReason, setCheckpointFailureReasonError])

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0.5em 0 1em 0',
            paddingRight: '0.5em',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <HelpIcon sx={{ color: colors.blue, marginRight: '0.5em' }} />
            <StyledText>{text.general.questions}</StyledText>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '270px',
              paddingRight: '70px',
            }}
          >
            <StyledText>{text.general.requiredField}</StyledText>
            <StyledText>{text.general.requiredPictureField}</StyledText>
            <StyledText>{text.general.valueField}</StyledText>
          </Box>
        </Box>
        {questions.map((question, questionIndex) => (
          <Question
            key={question.id}
            index={questionIndex}
            editMode={editMode}
            question={question}
            shouldUpdate={shouldUpdate}
            handleUpdateQuestion={(newQuestion?: QuestionType) => {
              handleQuestionsChange(questionIndex, newQuestion)
            }}
            handleDeleteQuestion={handleDeleteQuestion}
            setAnswerMissingError={setAnswerMissingError}
            saveStateCalled={saveStateCalled}
          />
        ))}
        {status === TemplateStatus.DRAFT && editMode ? (
          <StyledButton
            sx={{
              height: '20px !important',
              fontSize: '12px !important',
            }}
            variant="text"
            onClick={() => {
              changeEditMode(true)
              handleAddQuestion()
            }}
          >
            <AddIcon />
            {`${text.general.addQuestion}${checkpointIndex + 1}`}
          </StyledButton>
        ) : null}
      </Box>
    </>
  )
}

export default CheckpointContent
