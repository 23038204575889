import { StepperModel } from 'components/General/Stepper'
import OutOfSpotConfiguration from 'components/Operations/OutOfSpot/Configuration'

import { HoursPair, OutOfSpotModel } from 'models/outOfSpot'
import { SingleStepperTextType } from 'models/text/General'

export type OutOfSpotConfigurationProps = {
  schedules?: OutOfSpotModel
  submitLoading: boolean
  updateSchedules: (newSchedules: HoursPair) => void
}
export const OUT_OF_SPOT_CONFIG: Array<React.FC<OutOfSpotConfigurationProps>> =
  [OutOfSpotConfiguration]

export const createOutOfSpotStepperItems = (
  text: SingleStepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep,
    },
  ]

  return stepperItems
}
