import { gql } from '@apollo/client'

export const GET_ANSWERS = gql`
  query getAnswers {
    data: getAnswers {
      id
      correctAnswer
      doesntApply
      name: label
    }
  }
`
