import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import AbsenceMotivesPage from 'pages/GeneralSettings/Absence'
import AnswersConfigurationPage from 'pages/GeneralSettings/Answers/Configuration'
import HoursCreation from 'pages/GeneralSettings/Hour'
import RejectionMotivesPage from 'pages/GeneralSettings/Rejection'
import UserManagemenCreationPage from 'pages/GeneralSettings/UserManagement/Creation'
import UserManagemenDetailPage from 'pages/GeneralSettings/UserManagement/Detail'
import UserManagemenListingPage from 'pages/GeneralSettings/UserManagement/Listing'

import RoleRoute from 'components/Auth/RoleRoute'

import {
  GENERAL_SETTINGS_SUB_ROUTES,
  USER_MANAGEMENT_ROUTE,
} from 'constants/routes'
import { UserRoles } from 'models/role'

export const GeneralSettingsRouter = () => (
  <Switch>
    <RoleRoute
      path={GENERAL_SETTINGS_SUB_ROUTES.ABSENCE_MOTIVES}
      allowedRoles={[
        UserRoles.ADMIN,
        UserRoles.INSPECTOR_SUPERVISOR,
        UserRoles.INVENTORY_SUPERVISOR,
        UserRoles.OPERATION_SUPERVISOR,
      ]}
      component={AbsenceMotivesPage}
    />
    <RoleRoute
      allowedRoles={[UserRoles.ADMIN, UserRoles.INSPECTOR_SUPERVISOR]}
      path={GENERAL_SETTINGS_SUB_ROUTES.REJECTION_MOTIVES}
      component={RejectionMotivesPage}
    />
    <RoleRoute
      path={GENERAL_SETTINGS_SUB_ROUTES.ANSWERS_CONFIGURATION}
      allowedRoles={[UserRoles.ADMIN, UserRoles.INSPECTOR_SUPERVISOR]}
      component={AnswersConfigurationPage}
    />
    <RoleRoute
      path={GENERAL_SETTINGS_SUB_ROUTES.HOURS_CREATION}
      allowedRoles={[UserRoles.ADMIN, UserRoles.OPERATION_SUPERVISOR]}
      component={HoursCreation}
    />

    <RoleRoute allowedRoles={[UserRoles.ADMIN]} path={USER_MANAGEMENT_ROUTE}>
      <Switch>
        <Route
          path={GENERAL_SETTINGS_SUB_ROUTES.USER_MANAGEMENT_DETAIL}
          component={UserManagemenDetailPage}
        />
        <Route
          path={GENERAL_SETTINGS_SUB_ROUTES.USER_MANAGEMENT_LISTING}
          component={UserManagemenListingPage}
        />

        <Route
          path={GENERAL_SETTINGS_SUB_ROUTES.USER_MANAGEMENT_CREATION}
          component={UserManagemenCreationPage}
        />
      </Switch>
    </RoleRoute>
    <Redirect to={GENERAL_SETTINGS_SUB_ROUTES.ABSENCE_MOTIVES} />
  </Switch>
)
