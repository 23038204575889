import { gql } from '@apollo/client'

export const LIST_INSPECTIONS = gql`
  query listInspections($input: FilterInput) {
    listInspections(input: $input) {
      count
      data {
        id
        date
        provider
        providerTelephoneNumber
        origin
        createdAt
        status {
          backgroundColor
          id
          textColor
          name
        }
        car {
          model {
            name
            brand {
              name
            }
          }
          year
        }
      }
    }
  }
`

export const GET_INSPECTION_STATUSES = gql`
  query getInspectionStatuses($input: FilterInput) {
    getInspectionStatuses(input: $input) {
      backgroundColor
      description
      id
      name
      textColor
      slug
    }
  }
`
