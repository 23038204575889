import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { SelectChangeEvent } from '@mui/material'
import {
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid'

import Box from 'components/Common/Box'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import Table from 'components/Common/Table'
import Tooltip from 'components/Common/Table/Tooltip'
import Title from 'components/Common/Title'
import { Filter } from 'components/Inspection/Dashboard/FilterCard'
import DateFilter from 'components/Inspection/Listing/DateFilter'
import FieldFilter from 'components/Inspection/Listing/FieldFilter'
import FilterByCriteria from 'components/Listing/FilterByCriteria'

import { InspectorColumnField } from 'constants/inspector'
import { routes } from 'constants/routes'
import {
  commonListColumns,
  defaultSortModel,
  selectItems,
} from 'constants/table'
import { textFiles } from 'constants/textFiles'
import { UrlParamNames } from 'constants/urlQuery'
import useLocale from 'hooks/useLocale'
import useQueryState from 'hooks/useQueryState'
import useTranslation from 'hooks/useTranslation'
import { CurboSpot } from 'models/curboSpot'
import { CalendarRangeType } from 'models/date'
import {
  FilterInputVariable,
  GenericData,
  ListingFilterType,
} from 'models/services/base'
import { ExtendedListInspector } from 'models/services/inspector'
import { ExtendedStatus } from 'models/services/status'
import { InspectorStatusMap } from 'models/status'
import { getIsoDate } from 'utils/date'
import {
  checkSearchEmptiness,
  cleanFilters,
  generateFilterInput,
} from 'utils/filters'
import {
  createFieldSelectItems,
  createFieldSelectLabels,
  defaultFields,
} from 'utils/Inspector/listing'
import { buildDetailRoute } from 'utils/routes'
import {
  serializeFields,
  serializeFilters,
  serializePage,
  serializePageSize,
  serializeRange,
  serializeSearch,
  serializeSortModel,
} from 'utils/serializers'
import { verifyParam } from 'utils/verifyUrlData'

import { GET_CURBO_SPOTS } from 'graphQL/Common/Dealer/queries'
import {
  GET_INSPECTOR_STATUSES,
  LIST_INSPECTORS,
} from 'graphQL/Inspector/Listing/queries'

import { StyledTextField } from 'styles/inspection/listing'

const InspectorListingPage = () => {
  const defaultSortedFields = [...defaultFields].sort()
  const history = useHistory()
  const location = useLocation()
  const { search } = location

  const { text } = useTranslation(textFiles.INSPECTOR_LISTING)
  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const [deleteItem, setDeleteItem] = useState<GridRowId | null>(null)

  const [selectedFields, setSelectedFields] = useQueryState<string[]>(
    UrlParamNames.FIELDS,
    (verifyParam(UrlParamNames.FIELDS, search) as string[]) ||
      defaultSortedFields,
    serializeFields,
    defaultSortedFields
  )

  const [filtersList, setFiltersList] = useQueryState<Filter[]>(
    UrlParamNames.FILTERS,
    (verifyParam(UrlParamNames.FILTERS, search) as Filter[]) || [],
    serializeFilters
  )

  const [dateRange, setDateRange] = useQueryState<CalendarRangeType>(
    UrlParamNames.DATE,
    (verifyParam(UrlParamNames.DATE, search) as CalendarRangeType) || [],
    serializeRange
  )

  const [searchValue, setSearchValue] = useQueryState<string | undefined>(
    UrlParamNames.SEARCH,
    verifyParam(UrlParamNames.SEARCH, search) as string,
    serializeSearch
  )

  const [searchInput, setSearchInput] = useState<string>(searchValue || '')

  const [pageSize, setPageSize] = useQueryState<number>(
    UrlParamNames.LIMIT,
    (verifyParam(UrlParamNames.LIMIT, search) as number) || 10,
    serializePageSize,
    10
  )

  const [page, setPage] = useQueryState<number>(
    UrlParamNames.PAGE,
    (verifyParam(UrlParamNames.PAGE, search) as number) || 1,
    serializePage
  )

  const [sortModel, setSortModel] = useQueryState<GridSortModel>(
    UrlParamNames.SORT,
    (verifyParam(UrlParamNames.SORT, search) as GridSortModel) ||
      defaultSortModel,
    serializeSortModel,
    defaultSortModel
  )
  const [filterInput, setFiltersInput] = useState<ListingFilterType>(
    generateFilterInput(filtersList)
  )

  const [selectedLanguage] = useLocale()

  const [inspectorCount, setInspectorCount] = useState<number>(0)
  const [curboSpots, setCurboSpots] = useState<CurboSpot[]>([])

  const [inspectors, setInspectors] = useState<GridRowsProp>([])

  const [status, setStatus] = useState<ExtendedStatus[]>([])
  const { fromDate, toDate } = useMemo(() => {
    return {
      fromDate: getIsoDate(dateRange.fromDate),
      toDate: getIsoDate(dateRange.toDate),
    }
  }, [dateRange])

  const fieldSelectItems = createFieldSelectItems(text.fieldSelect)
  const fieldSelectLabels = createFieldSelectLabels(text.fieldSelect)

  const handleFiltersList = (
    newFiltersList: Filter[],
    newFilterInput: ListingFilterType
  ) => {
    setFiltersList(newFiltersList)
    setFiltersInput(newFilterInput)
  }

  const { data: inspectorsData, loading: inspectorsLoading } = useQuery<
    ExtendedListInspector,
    FilterInputVariable
  >(LIST_INSPECTORS, {
    variables: {
      input: {
        limit: pageSize,
        start: (page - 1) * pageSize,
        sort:
          sortModel.length > 0 && sortModel[0].sort
            ? {
                [sortModel[0].field]: sortModel[0].sort,
              }
            : undefined,
        where: {
          text_search: searchValue,
          createdAt_gte: fromDate,
          createdAt_lte: toDate,
          ...cleanFilters(filterInput),
        },
      },
    },
  })

  const { loading: curboSpotsLoading } = useQuery<GenericData<CurboSpot[]>>(
    GET_CURBO_SPOTS,
    {
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
        },
      },
      onCompleted(response) {
        setCurboSpots(response.data)
      },
    }
  )

  const { loading: statusLoading, data: statusResponse } = useQuery<
    GenericData<ExtendedStatus[]>
  >(GET_INSPECTOR_STATUSES, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
  })

  const editInspector = React.useCallback(
    (id: GridCellValue) => {
      history.push(buildDetailRoute(id, routes.INSPECTOR_DETAIL))
    },
    [history]
  )

  const createColumns = useCallback(
    (statuses?: ExtendedStatus[]) => {
      const {
        id: idColumn,
        picture: pictureColumn,
        isoDate: dateColumn,
        standard: standardColumn,
        statusBackend: statusColumn,
        actions: actionsColumn,
      } = commonListColumns(
        InspectorStatusMap,
        selectedLanguage,
        generalText,
        editInspector,
        undefined,
        statuses
      )

      return [
        {
          ...pictureColumn,
          field: InspectorColumnField.PICTURE,
          hide: !selectedFields.includes(pictureColumn.field),
        },
        { ...idColumn, hide: !selectedFields.includes(idColumn.field) },
        {
          ...standardColumn,
          field: InspectorColumnField.INSPECTOR_NAME,
          hide: !selectedFields.includes(InspectorColumnField.INSPECTOR_NAME),
          headerName: text.table.name,
          flex: 2,
          renderCell: (params: GridRenderCellParams) => {
            const { name, lastName } = params.row
            const fullName = `${name} ${lastName}`

            return <Tooltip>{fullName}</Tooltip>
          },
        },
        {
          ...standardColumn,
          field: InspectorColumnField.CURBO_SPOT,
          hide: !selectedFields.includes(InspectorColumnField.CURBO_SPOT),
          headerName: text.table.curboSpot,
          renderCell: (params: GridRenderCellParams) => {
            const { curboSpot } = params.row
            return (
              <Tooltip>
                {curboSpot ? curboSpot.name : generalText.table.notAssigned}
              </Tooltip>
            )
          },
        },
        {
          ...standardColumn,
          field: InspectorColumnField.PROVINCE,
          hide: !selectedFields.includes(InspectorColumnField.PROVINCE),
          headerName: text.table.province,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            const { curboSpot } = params.row

            return (
              <Tooltip>
                {curboSpot ? curboSpot.city : generalText.table.notAssigned}
              </Tooltip>
            )
          },
        },
        {
          ...dateColumn,
          field: InspectorColumnField.CREATED_AT,
          hide: !selectedFields.includes(InspectorColumnField.CREATED_AT),
          headerName: text.table.createdAt,
        },
        { ...statusColumn, hide: !selectedFields.includes(statusColumn.field) },
        {
          ...actionsColumn,
          hide: !selectedFields.includes(actionsColumn.field),
        },
      ]
    },
    [
      editInspector,
      generalText,
      selectedFields,
      selectedLanguage,
      text.table.createdAt,
      text.table.curboSpot,
      text.table.name,
      text.table.province,
    ]
  )

  const [columns, setColumns] = useState<GridColDef[]>(createColumns())

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchInput(event.target.value)
  }

  const submitsearchInput = () => {
    setPage(1)
    const newValue = checkSearchEmptiness(searchInput)
    setSearchValue(newValue)
  }

  const handleSelectItem = (valueInput: string) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        return column.field === valueInput
          ? { ...column, hide: !column.hide }
          : column
      })
    )
    setSelectedFields((prevFields) => {
      if (prevFields.includes(valueInput)) {
        return prevFields.filter((field) => field !== valueInput)
      }
      return [...prevFields, valueInput]
    })
  }

  const handleResetDefault = () => {
    setSelectedFields(defaultFields)
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = defaultFields.some(
          (defaultField) => column.field === defaultField
        )
        return { ...column, hide: !fieldExist }
      })
    )
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangePageSize = (event: SelectChangeEvent<number>) => {
    setPageSize(event.target.value as number)
  }

  const getPageCount = () => {
    return Math.ceil(inspectorCount / pageSize)
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
  }

  const handleChangeDateRange = (newDateRange: CalendarRangeType) => {
    setDateRange(newDateRange)
    setPage(1)
  }

  useEffect(() => {
    if (inspectorsData) {
      const { count, data } = inspectorsData.listInspectors
      setInspectors(data)
      setInspectorCount(count)
    }
  }, [inspectorsData])

  useEffect(() => {
    if (statusResponse) {
      setColumns(createColumns(statusResponse.data))
      setStatus(statusResponse.data)
    }
  }, [statusResponse, createColumns])

  return (
    <Box width="100%" overflow="hidden">
      <Title
        icon={<PlaylistAddCheckIcon />}
        subtitle={`${inspectorCount} ${text.description}`}
        title={text.title}
      />
      {curboSpotsLoading || statusLoading ? (
        <LoadingAnimation showAnimation={curboSpotsLoading || statusLoading} />
      ) : (
        <>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="flex-end"
            marginTop="2rem"
            width="100%"
          >
            <Box display="flex">
              <Box marginRight="1rem" width="430px">
                <StyledTextField
                  placeholder={text.searchPlaceholder}
                  fullWidth
                  value={searchInput}
                  onChange={handleSearchChange}
                  submitFunction={submitsearchInput}
                  name="search"
                />
              </Box>
              <Box marginRight="1rem">
                <FilterByCriteria
                  filtersList={filtersList}
                  handleFiltersList={handleFiltersList}
                  file={textFiles.INSPECTOR_LISTING}
                  filterInput={filterInput}
                  curboSpots={curboSpots}
                  statusList={status}
                  loadingSelect={curboSpotsLoading || statusLoading}
                />
              </Box>
              <Box marginRight="1rem">
                <FieldFilter
                  handleSelectItem={handleSelectItem}
                  items={fieldSelectItems}
                  selectedValues={selectedFields}
                  handleResetDefault={handleResetDefault}
                />
              </Box>
              <Box>
                <DateFilter
                  dateRange={dateRange}
                  handleChangeDateRange={handleChangeDateRange}
                  title={text.dateRangeTitle}
                />
              </Box>
            </Box>
          </Box>
          <Table
            columns={columns}
            data={inspectors}
            // setData={tabValue === 0 ? setActiveInspectors : setInactiveInspectors}
            currentPage={page}
            onPageChange={handleChangePage}
            onSelectChange={handleChangePageSize}
            pageSize={pageSize}
            selectItems={selectItems}
            pageCount={getPageCount()}
            dateRange={dateRange}
            filtersList={filtersList}
            // searchValue={searchValue}
            fields={selectedFields}
            fieldLabels={fieldSelectLabels}
            deletedItemId={deleteItem}
            setDeleteItemId={setDeleteItem}
            route={routes.INSPECTOR_DETAIL}
            filtersFile={textFiles.INSPECTOR_LISTING}
            loading={inspectorsLoading}
            sortModel={sortModel}
            handleSortModelChange={handleSortModelChange}
            checkboxSelection={false}
            curboSpots={curboSpots}
            hideDownloadModal
          />
        </>
      )}
    </Box>
  )
}
export default InspectorListingPage
