import { Box, styled } from '@mui/material'

import { colors } from 'styles/theme'

export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 30px',
})

export const StyledChartContainer = styled(Box)({
  marginTop: '32px',
  marginBottom: '20px',
  display: 'flex',
  width: '450px',
  backgroundColor: colors.background,
  borderRadius: '10px',
  svg: {
    overflow: 'visible',
  },
})
