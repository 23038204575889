import { styled } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

import { colors, FONT_WEIGHT } from 'styles/theme'

import Button from '../Button'

export const StyledDataGrid = styled(DataGrid)({
  border: 0,
  fontSize: '14px',
  color: colors.gray,

  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-row': {
    fontSize: '12px',
    margin: '10px 0',
    borderRadius: '4px',
    backgroundColor: colors.commonWhite,
    color: colors.black,
    '& .MuiDataGrid-cell': {
      cursor: 'pointer',
    },
  },
  '& .MuiDataGrid-row:first-of-type': {
    margin: '0',
  },

  '& .provider-type-classname': {
    color: colors.blue,
  },

  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: 0,
  },

  '& .MuiDataGrid-main': {
    marginBottom: '1rem',
  },
})

export const StyledButton = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  fontSize: '12px',
  width: '75px',
  height: '25px',
  backgroundColor: colors.background,
  cursor: 'pointer',

  ':hover': {
    backgroundColor: colors.gray,
  },

  svg: {
    width: '12px',
    height: '12px',
  },

  '&:hover': {
    backgroundColor: colors.lightBlue,
    color: colors.lightWhite,
  },
})

export const StyledVerticalButton = styled(Button)({
  display: 'flex',
  justifyContent: 'left !important',
  borderRadius: '0px !important',
  alignItems: 'center',
  fontSize: '12px !important',
  fontWeight: `${FONT_WEIGHT.MEDIUM} !important`,
  width: '80px',
  height: '25px !important',
  backgroundColor: colors.background,
  color: `${colors.black} !important`,
  cursor: 'pointer',
  paddingLeft: '0.5rem',
  svg: {
    width: '12px',
    height: '12px',
  },

  '&:hover': {
    backgroundColor: `${colors.lightBlue} !important`,
    color: `${colors.lightWhite} !important`,
  },
})

export const StyledSection = styled('section')({
  display: 'flex',
  alignItems: 'center',
  margin: '0.5rem 0 0',
  height: '17px',
  width: '100%',
  color: colors.black,
  fontSize: '12px',
  fontWeight: FONT_WEIGHT.MEDIUM,
})

export const StyledDeleteButton = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  color: colors.lightRed,
  fontWeight: FONT_WEIGHT.SEMI_BOLD,
  width: '160px',
  backgroundColor: colors.background,
  cursor: 'pointer',

  svg: {
    width: '14px',
    height: '14px',
  },
})
