import React from 'react'
import { ClickAwayListener, Popper, Typography } from '@mui/material'

import Box from 'components/Common/Box'
import Button from 'components/Common/Button'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { colors } from 'styles/theme'

import { StyledBox } from './style'

type AvatarPopperProps = {
  anchorEl: HTMLElement | null
  handleClosePopper: () => void
  handleConfirmRemove: () => void
  open: boolean
}

const AvatarPopper = ({
  anchorEl,
  handleClosePopper,
  handleConfirmRemove,
  open,
}: AvatarPopperProps) => {
  const {
    text: { translationText },
  } = useTranslation(textFiles.GENERAL)

  return (
    <Popper
      id="translation-popper"
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
    >
      <ClickAwayListener onClickAway={handleClosePopper}>
        <StyledBox>
          <Typography>{translationText.popperTitle}</Typography>
          <Box display="flex" justifyContent="space-evenly" marginTop="10px">
            <Button onClick={handleConfirmRemove} size="small">
              {translationText.confirm}
            </Button>
            <Button
              size="small"
              buttonType="info"
              sx={{
                backgroundColor: colors.background,
              }}
              onClick={handleClosePopper}
            >
              {translationText.decline}
            </Button>
          </Box>
        </StyledBox>
      </ClickAwayListener>
    </Popper>
  )
}

export default AvatarPopper
