import { gql } from '@apollo/client'

export const CREATE_TRIM_LEVEL = gql`
  mutation createManyTrimLevelsByYear(
    $input: CreateManyTrimLevelsByYearInput!
  ) {
    data: createManyTrimLevelsByYear(input: $input) {
      id
    }
  }
`
