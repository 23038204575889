import { styled } from '@mui/material'

import Box from 'components/Common/Box'

export const DetailDescriptionBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  padding: '0.75rem 0.25rem',
  width: '30%',
})
