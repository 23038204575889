import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'

// import FlagIcon from '@mui/icons-material/Flag'
import AvatarItem from 'components/CarSettings/Common/Creation/Summary/AvatarItem'
import Skeleton from 'components/CarSettings/Common/Creation/Summary/Skeleton'
import SummaryItem from 'components/Common/SummaryItem'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { BrandCreationProps } from 'utils/CarSettings/Brand/creation'

import { SectionContainer } from 'styles/creation'

const Summary = ({
  handleBack,
  brandData,
  handleSubmit,
  submitLoading,
}: BrandCreationProps) => {
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { text } = useTranslation(textFiles.BRAND_CREATION)
  const { summary } = text
  const { brandInformation } = brandData

  return (
    <Skeleton
      previousFunction={handleBack}
      nextFunction={handleSubmit}
      title={summary.title}
      description={summary.description}
      submitLoading={submitLoading}
    >
      <SectionContainer>
        <SummaryItem
          title={summary.name}
          text={brandInformation.name}
          icon={<DirectionsCarIcon />}
        />
        <SummaryItem
          title={summary.status}
          text={generalText.status[brandInformation.status]}
          icon={<CheckCircleIcon />}
        />
        {/* <SummaryItem
        title={summary.country}
        text={brandInformation.country as string}
        icon={<FlagIcon />}
      /> */}
        <AvatarItem picture={brandInformation.picture} title={summary.logo} />
      </SectionContainer>
    </Skeleton>
  )
}

export default Summary
