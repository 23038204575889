import { SellMyCarType, TIME_TYPE } from 'models/services/inspection/detail'

/**
 * Function that checks if the type of label is month, to call the corresponding
 * plural noun for it. Otherwise, it will just append an S at the end of the
 * singular noun translation
 */
export const getEstimatedSellTimeLabel = (
  carData: SellMyCarType,
  translation: Record<string, string>
) => {
  if (carData.expectedTime.timeType === TIME_TYPE.month) {
    return carData.expectedTime.label.length > 1
      ? translation.months
      : translation.month
  }
  return carData.expectedTime.label.length > 1
    ? translation[carData.expectedTime.timeType].replace('%d', 's')
    : translation[carData.expectedTime.timeType].replace('%d', '')
}
