import ModelInfo from 'components/CarSettings/Model/Creation/ModelInfo'
import ModelSummary from 'components/CarSettings/Model/Creation/Summary'
import { StepperModel } from 'components/General/Stepper'

import { ModelCreationModel } from 'models/modelCreation'
import { Option } from 'models/Select'
import { SimpleStepperTextType } from 'models/text/General'

export type ModelCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  modelData: ModelCreationModel
  submitLoading: boolean
  brands: Option[]
  updateModelData: (newModel: ModelCreationModel) => void
}

export const MODEL_CREATION_STEPS: Array<React.FC<ModelCreationProps>> = [
  ModelInfo,
  ModelSummary,
]

export const createModelStepperItems = (
  text: SimpleStepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep,
    },
    { label: text.secondStep },
  ]

  return stepperItems
}
