import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined'
import { FormControlLabel, styled } from '@mui/material'

import { colors } from 'styles/theme'

export const StyledLabel = styled(FormControlLabel)({
  alignSelf: 'flex-start',
})

export const StyledCheckboxIcon = styled(CheckBoxOutlineBlankOutlinedIcon)({
  color: colors.gray,
})
