export enum InventoryColumnField {
  ID = 'id',
  SLUG = 'slug',
  PICTURE = 'picture',
  VEHICLE = 'vehicle',
  TRIM = 'trim',
  PRICE = 'price',
  MILEAGE = 'mileage',
  CREATED_AT = 'createdAt',
  CERTIFIED = 'certified',
  STATUS = 'status',
  ACTIONS = 'actions',
}

export const unpublishedHash = '#unpublished'
