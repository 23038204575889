import { styled } from '@mui/material'

import Box from 'components/Common/Box'

import { BREAK_POINTS } from 'styles/breakpoints'

export const GridContainer = styled(Box)({
  display: 'grid',
  columnGap: '10px',
  gridTemplateColumns: '24% 24% 24% 24%',
  rowGap: '1.5rem',

  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '33% 33% 33%',
  },

  [`@media only screen and (max-width: ${BREAK_POINTS.MLG}px)`]: {
    gridTemplateColumns: '48% 48%',
  },
})
