import { gql } from '@apollo/client'

export const GET_INSPECTORS_CALENDAR = gql`
  query getInspectorsCalendar($input: InspectorsCalendarFilterInput!) {
    data: getInspectorsCalendar(input: $input) {
      inspectors: data {
        id
        curboSpot {
          slug
          name
        }
        profilePicture
        name
        tags: data {
          isAbsent
          date
          info: data {
            origin
            total
          }
        }
      }
    }
  }
`
