import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import CircleIcon from '@mui/icons-material/Circle'
import HistoryIcon from '@mui/icons-material/History'
import {
  Box,
  Card,
  ListItemIcon,
  selectClasses,
  styled,
  Table,
  tableCellClasses,
  Typography,
} from '@mui/material'

import { boxShadow, colors, FONT_WEIGHT } from 'styles/theme'

export const StyledBox = styled(Box)({
  borderRadius: '10px',
  backgroundColor: colors.backgroundGray,
  paddingTop: '1.6rem',
  paddingLeft: '1.5rem',
  minHeight: '449px',
})

export const StyledText = styled(Typography)({
  overflowWrap: 'break-word',
  fontWeight: FONT_WEIGHT.BOLD,
  letterSpacing: 0,
})

export const AbsenceEditor = styled(Card)({
  width: '576px',
  marginBottom: '1rem',
  borderRadius: '10px',
})

export const CalendarPopper = styled(Card)({
  marginTop: '1rem',
  borderRadius: '10px',
})

export const ListIcon = styled(ListItemIcon)({
  minWidth: '25px',
})

export const ClockIcon = styled(AccessTimeFilledIcon)({
  color: colors.blue,
})

export const RedHistoryIcon = styled(HistoryIcon)({
  color: colors.lightRed,
})

export const ListBullet = styled(CircleIcon)({
  fontSize: '0.5rem',
  color: colors.blue,
})

export const ListContent = styled(Box)({
  marginLeft: '2.5rem',
})

export const ListText = styled(Typography)({
  fontWeight: FONT_WEIGHT.BOLD,
  color: colors.black,
})
export const ClickText = styled(Typography)({
  fontSize: '14px',
  fontWeight: FONT_WEIGHT.BOLD,
  color: colors.blue,
  marginLeft: '3px',
  textDecoration: 'underline',
  '&:hover': {
    cursor: 'pointer',
  },
})

export const StyledTable = styled(Table)({
  marginLeft: '5px',
  width: '250px',
  borderSpacing: '0 5px',
  borderCollapse: 'separate',
  [`& .${tableCellClasses.root}`]: {
    borderBottom: 'none',
  },
  [`& .${tableCellClasses.head}`]: {
    backgroundColor: colors.backgroundGray,
  },
})

export const AbsenceSelectStyle = {
  width: '193px',
  fontSize: '14px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.gray,
  paddingLeft: '12px !important',
  [`& .${selectClasses.select}`]: {
    padding: '7px',
  },
}

export const RecordButtonSx = {
  marginTop: '1rem',
  marginBottom: '1rem',
  marginLeft: '0.3rem',
  color: 'white',
  boxShadow,
}

export const RowItem = {
  backgroundColor: colors.lightWhite,
  boxShadow: 'none',
  paddingTop: '4px',
  paddingBottom: '4px',
  paddingLeft: '6px',
  borderRadius: '4px',
}

export const IconButtonStyle = {
  backgroundColor: colors.lightWhite,
  boxShadow: 'none',
  borderRadius: '4px',
  padding: '6px',
}

export const TableTitle = {
  color: colors.gray,
  paddingLeft: '5px',
}
