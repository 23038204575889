import React, { FunctionComponent } from 'react'
import { InputBaseComponentProps, Slider } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'

import ButtonContainer from 'components/Template/Creation/ButtonContainer'

import { VEHICLE_CREATION_OPTION } from 'constants/Inventory/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { FifthStepModel } from 'models/services/PreInventory/creation'
import { VehicleCreationProps } from 'utils/Inventory/creation'

import {
  BoxContainer,
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

import NumberValueInput, {
  maxValue,
  minValue,
} from '../../Common/NumberValueInput'

type FormikKeys = 'doors' | 'height' | 'length' | 'seats' | 'width'

const initialValue: FifthStepModel = {
  doors: 1,
  height: 1,
  length: 1,
  seats: 1,
  width: 1,
}

const VehicleMeasurements = ({
  vehicleData,
  updateVehicleData,
  handleSecondaryBack,
  handleSecondaryContinue,
}: VehicleCreationProps) => {
  const {
    text: {
      subSteps: { fifthStep: translation },
    },
  } = useTranslation(textFiles.VEHICLE_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const initialData = vehicleData.vehicleInformation.fifthStep || initialValue

  const validationSchema = yup.object().shape({
    doors: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    height: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    length: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    seats: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    width: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
  })

  const formik = useFormik<FifthStepModel>({
    initialValues: initialData,
    validationSchema,
    onSubmit: (values) => {
      updateVehicleData({
        type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION,
        payload: {
          ...vehicleData.vehicleInformation,
          fifthStep: values,
        },
      })
      handleSecondaryContinue()
    },
  })

  const handleSliderChange = (value: number | number[], field: FormikKeys) => {
    formik.setFieldValue(field, Number(value))
  }

  const handleBlur = (field: FormikKeys) => {
    if (formik.values[field] > maxValue) {
      formik.setFieldValue(field, maxValue)
    } else if (!formik.values[field]) formik.setFieldValue(field, minValue)
  }

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <StyledTextFieldContainer title={translation.lengthLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.length}
                onChange={(e, value) => {
                  handleSliderChange(value, 'length')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="length"
                value={formik.values.length}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('length')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.length && formik.errors.length ? (
              <StyledErrorMessage text={formik.errors.length} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.widthLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.width}
                onChange={(e, value) => {
                  handleSliderChange(value, 'width')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="width"
                value={formik.values.width}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('width')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.width && formik.errors.width ? (
              <StyledErrorMessage text={formik.errors.width} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.heightLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.height}
                onChange={(e, value) => {
                  handleSliderChange(value, 'height')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="height"
                value={formik.values.height}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('height')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.height && formik.errors.height ? (
              <StyledErrorMessage text={formik.errors.height} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.seatsLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.seats}
                onChange={(e, value) => {
                  handleSliderChange(value, 'seats')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="seats"
                value={formik.values.seats}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('seats')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.seats && formik.errors.seats ? (
              <StyledErrorMessage text={formik.errors.seats} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.doorsLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.doors}
                onChange={(e, value) => {
                  handleSliderChange(value, 'doors')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="doors"
                value={formik.values.doors}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('doors')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.doors && formik.errors.doors ? (
              <StyledErrorMessage text={formik.errors.doors} />
            ) : null}
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonContainer
        previousFunction={handleSecondaryBack}
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default VehicleMeasurements
