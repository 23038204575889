import { addYears, getYear } from 'date-fns'

import { InspectionWeekCalendar } from 'models/services/curboSpot'

type dayNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6

export type dayName =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'

export enum DAY_ENUM {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
}

export const weekDay: Record<dayNumber, DAY_ENUM> = {
  0: DAY_ENUM.SUNDAY,
  1: DAY_ENUM.MONDAY,
  2: DAY_ENUM.TUESDAY,
  3: DAY_ENUM.WEDNESDAY,
  4: DAY_ENUM.THURSDAY,
  5: DAY_ENUM.FRIDAY,
  6: DAY_ENUM.SATURDAY,
}

export const weekNumber: Record<DAY_ENUM, dayNumber> = {
  [DAY_ENUM.SUNDAY]: 0,
  [DAY_ENUM.MONDAY]: 1,
  [DAY_ENUM.TUESDAY]: 2,
  [DAY_ENUM.WEDNESDAY]: 3,
  [DAY_ENUM.THURSDAY]: 4,
  [DAY_ENUM.FRIDAY]: 5,
  [DAY_ENUM.SATURDAY]: 6,
}

export const emptyWeekCalendar: InspectionWeekCalendar = {
  [DAY_ENUM.SUNDAY]: [],
  [DAY_ENUM.MONDAY]: [],
  [DAY_ENUM.TUESDAY]: [],
  [DAY_ENUM.WEDNESDAY]: [],
  [DAY_ENUM.THURSDAY]: [],
  [DAY_ENUM.FRIDAY]: [],
  [DAY_ENUM.SATURDAY]: [],
}

export const DATAGE_LIMIT = getYear(addYears(new Date(), 2)).toString()
