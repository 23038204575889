/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import { useContext } from 'react'

import { COUNTRY_CODE } from 'constants/environment'

import { LocaleContext } from '../context/LocaleContext'

const useMultiCountryTranslation = (file: string) => {
  const context = useContext(LocaleContext)

  if (!context) {
    throw new Error(
      'useMultiCountryTranslation must be used within a LocaleProvider'
    )
  }

  const { selectedLanguage } = context

  if (selectedLanguage.code === 'en') {
    const text = require(`../locales/en_us/${file}.json`)

    return { text }
  }

  const text = COUNTRY_CODE
    ? require(`../locales/es_${COUNTRY_CODE}/${file}.json`)
    : require(`../locales/es_do/${file}.json`)

  return { text }
}

export default useMultiCountryTranslation
