import React from 'react'
import { ArrayHelpers, FieldArray, FormikValues, getIn } from 'formik'

import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'
import Box from 'components/Common/Box'
import MultipleSelect from 'components/Inventory/Creation/Common/MultipleSelect'
import QuestionStripe from 'components/Template/Creation/Step/Question'

import { defaultQuestion } from 'constants/Template/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Option } from 'models/Select'
import { FormCheckpointType } from 'models/template'

import { StyledErrorMessage } from 'styles/creation'

import {
  CheckpointContent,
  CheckpointTitle,
  QuestionContent,
  SelectContainer,
  StyledButton,
  StyledIndex,
  StyledSelect,
  StyledTextField,
  StyledTextFieldContainer,
} from './style'

type CheckpointProps = {
  stepNumber: number
  checkpoint: FormCheckpointType
  checkpointIndex: number
  formik: FormikValues
  arrayHelpers: ArrayHelpers
  reasons: Option[]
  answers: Option[]
  handleStandardizeAnswer: (isAnswerStandard: boolean, index: number) => void
}

const CheckpointComponent = ({
  checkpoint,
  stepNumber,
  checkpointIndex,
  formik,
  arrayHelpers,
  reasons,
  answers,
  handleStandardizeAnswer,
}: CheckpointProps) => {
  const { text } = useTranslation(textFiles.TEMPLATE_CREATION)
  const { remove } = arrayHelpers
  const {
    step: { checkpoints: checkpointText, placeholders },
  } = text
  let questionHelpers

  const handleDelete = () => {
    remove(checkpointIndex)
  }

  const handleStatusChange = (value: boolean) => {
    handleStandardizeAnswer(value, checkpointIndex)
  }

  const handleAnswerRemove = (e: React.MouseEvent, index: number) => {
    e.preventDefault()
    const newAnswers = [...checkpoint.answers]
    newAnswers.splice(index, 1)
    formik.setFieldValue(`checkpoints.${checkpointIndex}.answers`, newAnswers)
  }

  const hasAnswersError = () => {
    if (
      getIn(formik.touched, `checkpoints.${checkpointIndex}.answers`) &&
      Boolean(getIn(formik.errors, `checkpoints.${checkpointIndex}.answers`))
    )
      return true

    return false
  }

  return (
    <div>
      <Box display="flex" width="100%" marginBottom="20px">
        <StyledIndex>{`${stepNumber + 1}.${checkpointIndex + 1}`}</StyledIndex>
        <CheckpointContent>
          <CheckpointTitle>
            <StyledTextFieldContainer
              title={checkpointText.titleName}
              description={checkpointText.subtitleName}
            >
              <StyledTextField
                variant="outlined"
                placeholder={`${placeholders.checkpoint} ${
                  checkpointIndex + 1
                }`}
                name={`checkpoints.${checkpointIndex}.title`}
                value={checkpoint.title}
                onChange={formik.handleChange}
                error={
                  getIn(
                    formik.touched,
                    `checkpoints.${checkpointIndex}.title`
                  ) &&
                  Boolean(
                    getIn(formik.errors, `checkpoints.${checkpointIndex}.title`)
                  )
                }
                helperText={
                  getIn(
                    formik.touched,
                    `checkpoints.${checkpointIndex}.title`
                  ) &&
                  getIn(formik.errors, `checkpoints.${checkpointIndex}.title`)
                }
                sx={{ height: 38 }}
              />
            </StyledTextFieldContainer>
            <StyledTextFieldContainer
              title={checkpointText.titleReason}
              description={checkpointText.subtitleReason}
            >
              {reasons.length > 0 ? (
                <StyledSelect
                  options={reasons}
                  defaultValue={reasons[0].name}
                  name={`checkpoints.${checkpointIndex}.reason`}
                  value={checkpoint.reason}
                  onChange={formik.handleChange}
                  sx={{ height: 40 }}
                  variant="outlined"
                />
              ) : null}
            </StyledTextFieldContainer>
          </CheckpointTitle>
          <StyledTextFieldContainer
            title={checkpointText.titleDescription}
            description={checkpointText.subtitleDescription}
            width="100%"
          >
            <StyledTextField
              variant="outlined"
              name={`checkpoints.${checkpointIndex}.description`}
              placeholder={placeholders.descriptionMinimun}
              value={checkpoint.description}
              onChange={formik.handleChange}
              error={
                getIn(
                  formik.touched,
                  `checkpoints.${checkpointIndex}.description`
                ) &&
                Boolean(
                  getIn(
                    formik.errors,
                    `checkpoints.${checkpointIndex}.description`
                  )
                )
              }
              helperText={
                getIn(
                  formik.touched,
                  `checkpoints.${checkpointIndex}.description`
                ) &&
                getIn(
                  formik.errors,
                  `checkpoints.${checkpointIndex}.description`
                )
              }
              sx={{ height: 113 }}
              minRows={5}
              multiline
            />
          </StyledTextFieldContainer>
          <ToggleContainer
            title={checkpointText.standardAnswerTitle}
            description={checkpointText.standardAnswerDescription}
            status={checkpoint.standardizeAnswer}
            handleStatus={handleStatusChange}
            testId="standardize-answer"
          />
          {checkpoint.standardizeAnswer ? (
            <SelectContainer
              description={checkpointText.selectDescription}
              width="100%"
            >
              <MultipleSelect
                name={`checkpoints.${checkpointIndex}.answers`}
                values={checkpoint.answers}
                label=""
                options={answers}
                onChange={formik.handleChange}
                onRemove={handleAnswerRemove}
                error={hasAnswersError()}
              />
              {hasAnswersError() ? (
                <StyledErrorMessage
                  text={getIn(
                    formik.errors,
                    `checkpoints.${checkpointIndex}.answers`
                  )}
                  hasIcon={false}
                />
              ) : null}
            </SelectContainer>
          ) : null}
          <StyledTextFieldContainer
            title={checkpointText.titleCheckpoints}
            description={checkpointText.subtitleCheckpoints}
            width="100%"
            marginBottom="0px !important"
          >
            <QuestionContent>
              <FieldArray
                name={`checkpoints.${checkpointIndex}.questions`}
                render={(questionArrayHelpers) => {
                  questionHelpers = questionArrayHelpers
                  return (
                    <Box sx={{ width: '100%' }}>
                      {checkpoint.questions &&
                        checkpoint.questions.length > 0 &&
                        checkpoint.questions.map((question, qIndex) => (
                          <QuestionStripe
                            checkpointIndex={checkpointIndex}
                            key={`question${String(qIndex)}${checkpointIndex}`}
                            questionIndex={qIndex}
                            question={question}
                            formik={formik}
                            arrayHelpers={questionArrayHelpers}
                            answers={answers}
                            standardizeAnswer={checkpoint.standardizeAnswer}
                          />
                        ))}
                    </Box>
                  )
                }}
              />
              <StyledButton
                sx={{
                  height: '20px !important',
                  fontSize: '12px !important',
                }}
                variant="text"
                onClick={() => {
                  questionHelpers.push(defaultQuestion)
                }}
                testId="add-question-button"
              >
                {checkpointText.addQuestion}
              </StyledButton>
            </QuestionContent>
          </StyledTextFieldContainer>
        </CheckpointContent>
      </Box>
      {checkpointIndex !== 0 ? (
        <StyledButton
          sx={{
            height: '20px !important',
            fontSize: '12px !important',
            marginBottom: '1.5em',
          }}
          variant="text"
          onClick={handleDelete}
          testId="delete-checkpoint-button"
        >
          {checkpointText.deleteCheckpoint}
        </StyledButton>
      ) : null}
    </div>
  )
}

export default CheckpointComponent
