import React, { useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import { Checkbox, IconButton } from '@mui/material'

import Box from 'components/Common/Box'
import RemoveItemPopper from 'components/General/Translation/RemoveItemPopper'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Answer } from 'models/services/answers'

import { colors } from 'styles/theme'

import { StyledSquareIcon, StyledStripe, StyledTextField } from './style'

type StripeProps = {
  answer: Answer
  handleRemove: (idx: string | number) => void
  handleEditItem: (answer: Answer) => void
  handleEditMode: React.Dispatch<React.SetStateAction<boolean>>
}

const Stripe = ({
  answer,
  handleRemove,
  handleEditItem,
  handleEditMode,
}: StripeProps) => {
  const { id, name, correctAnswer, doesntApply } = answer

  const [edit, setEdit] = useState<boolean>(false)
  const [newText, setNewText] = useState<string>(name)
  const [newCorrectAnswer, setNewCorrectAnswer] =
    useState<boolean>(correctAnswer)
  const [apply, setApply] = useState<boolean>(doesntApply)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const { text: translation } = useTranslation(textFiles.GENERAL)
  const { answers: answerText } = translation

  const handleEdit = () => {
    if (!edit) {
      setEdit(true)
      handleEditMode(true)
    } else {
      handleEditItem({
        id,
        name: newText,
        correctAnswer: newCorrectAnswer,
        doesntApply: apply,
      })
      setEdit(false)
      handleEditMode(false)
    }
  }

  const handleClosePopper = () => {
    setAnchorEl(null)
    setEdit(false)
    handleEditMode(false)
  }

  const handleDelete = () => {
    handleRemove(id)
    handleClosePopper()
  }

  return (
    <>
      <StyledStripe>
        <section>
          <StyledSquareIcon />
          {!edit ? (
            <p>{name}</p>
          ) : (
            <StyledTextField
              placeholder={answerText.placeholder}
              variant="outlined"
              value={newText}
              onChange={(e) => {
                setNewText(e.target.value)
              }}
              sx={{
                height: 25,
                width: 300,
                marginRight: '5px',
                input: {
                  height: 25,
                  width: 300,
                },
              }}
            />
          )}
          <Box width="200px" display="flex" justifyContent="space-around">
            <Checkbox
              checked={edit ? newCorrectAnswer : correctAnswer}
              inputProps={{ 'aria-label': 'controlled' }}
              name={`correctAnswer.${id}`}
              sx={{ padding: '0 9px' }}
              onChange={(e) =>
                edit ? setNewCorrectAnswer(e.target.checked) : () => undefined
              }
            />
            <Checkbox
              checked={edit ? apply : doesntApply}
              inputProps={{ 'aria-label': 'controlled' }}
              name={`apply.${id}`}
              sx={{ padding: '0 9px' }}
              onChange={(e) =>
                edit ? setApply(e.target.checked) : () => undefined
              }
            />
          </Box>
        </section>
        <section>
          <IconButton onClick={handleEdit}>
            {!edit ? (
              <EditIcon sx={{ color: colors.placeholderGray, fontSize: 14 }} />
            ) : (
              <CheckIcon sx={{ color: colors.placeholderGray, fontSize: 14 }} />
            )}
          </IconButton>
          <IconButton
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              edit
                ? setEdit(false)
                : setAnchorEl(anchorEl ? null : event.currentTarget)
            }
          >
            <ClearIcon sx={{ color: colors.placeholderGray, fontSize: 14 }} />
          </IconButton>
        </section>
      </StyledStripe>
      <RemoveItemPopper
        anchorEl={anchorEl}
        open={open}
        handleClosePopper={handleClosePopper}
        handleConfirmRemove={handleDelete}
      />
    </>
  )
}

export default Stripe
