import React, { useState } from 'react'
import { ColorResult } from 'react-color'
import { useFormik } from 'formik'
import * as yup from 'yup'

import ColorsBoard from 'components/CarSettings/Color/Creation/ColorsBoard'
import ButtonContainer from 'components/CarSettings/Common/Creation/ButtonContainer'
import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'
import { TranslationContainer } from 'components/CarSettings/style'
import Box from 'components/Common/Box'
import ErrorMessage from 'components/Common/ErrorMessage'
import Translation from 'components/General/Translation'
import { TranslationItemType } from 'components/General/Translation/TranslationListItem'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Color } from 'models/car'
import { PublicationStatus } from 'models/status'
import { TranslationChange } from 'models/translations'
import { ColorCreationProps } from 'utils/CarSettings/Color/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'
import { colors } from 'styles/theme'

import {
  ColorPickerContainer,
  StyledHuePicker,
  StyledSketchPicker,
} from './style'

const ColorInfo = ({
  handleContinue,
  colorData,
  updateColorData,
}: ColorCreationProps) => {
  const { colorInformation } = colorData
  const [colorStatus, setColorStatus] = useState<boolean>(
    colorInformation.status === PublicationStatus.PUBLISHED
  )
  const [colorHexCode, setColorHexCode] = useState<string>(
    colorInformation.hexCode || colors.gray
  )
  const [addedTranslations, setAddedTranslations] = useState<
    TranslationItemType[]
  >(colorInformation.translations)

  const { text } = useTranslation(textFiles.COLOR_CREATION)
  const {
    information: {
      fieldRequired,
      isPublishedDescription,
      name,
      gray,
      isPublished,
      colorSubtitle,
      translationsDescription,
    },
  } = text

  const handleChangeTranslation = (
    translations: TranslationItemType[],
    type: TranslationChange
  ) => {
    sessionStorage.setItem('type', type)
    setAddedTranslations(translations)
  }

  const handleColorChange = (color: ColorResult) => {
    setColorHexCode(color.hex)
  }

  const handleColorBoardChange = (color: string) => {
    setColorHexCode(color)
  }

  const validationSchema = yup.object({
    name: yup.string().required(fieldRequired),
  })

  const formik = useFormik<Partial<Color>>({
    initialValues: {
      name: colorInformation.name,
    },
    validationSchema,
    onSubmit: () => {
      if (formik.values.name !== undefined) {
        const customColorInfo = {
          colorInformation: {
            ...colorData.colorInformation,
            name: formik.values.name,
            status: colorStatus
              ? PublicationStatus.PUBLISHED
              : PublicationStatus.UNPUBLISHED,
            hexCode: colorHexCode,
            translations: addedTranslations,
          },
        }
        updateColorData(customColorInfo)
      }

      handleContinue()
    },
  })

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <ToggleContainer
            title={isPublished}
            description={isPublishedDescription}
            status={colorStatus}
            handleStatus={setColorStatus}
            testId="published"
          />

          <StyledTextFieldContainer title={name} description={colorSubtitle}>
            <StyledTextField
              variant="outlined"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder={gray}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
            {formik.touched.name && formik.errors.name ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.name}
              />
            ) : null}
          </StyledTextFieldContainer>
          <Box width="600px !important">
            <ColorPickerContainer>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <StyledSketchPicker
                  color={colorHexCode}
                  onChangeComplete={handleColorChange}
                />
                <StyledHuePicker
                  color={colorHexCode}
                  onChangeComplete={handleColorChange}
                  width="230px"
                />
              </div>
              <ColorsBoard handleColorBoardChange={handleColorBoardChange} />
            </ColorPickerContainer>
          </Box>
          <TranslationContainer>
            <Translation
              addedTranslations={addedTranslations}
              handleChangeTranslation={handleChangeTranslation}
              translationDescription={translationsDescription}
            />
          </TranslationContainer>
        </div>
      </StyledForm>
      <ButtonContainer
        isFirstStep
        confirmButtonType="submit"
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default ColorInfo
