import FeatureInfo from 'components/CarSettings/Feature/Creation/FeatureInfo'
import Summary from 'components/CarSettings/Feature/Creation/Summary'
import { StepperModel } from 'components/General/Stepper'

import {
  FeatureCreationAction,
  FeatureCreationModel,
} from 'models/featureCreation'
import { StepperTextType } from 'models/text/General'

export type FeatureCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  submitLoading: boolean
  featureData: FeatureCreationModel
  updateFeatureData: (action: FeatureCreationAction) => void
}

export const INITIAL_FEATURE_CREATION_STEPS: Array<
  React.FC<FeatureCreationProps>
> = [FeatureInfo, Summary]

export const createFeatureStepperItems = (
  text: StepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep,
    },
    { label: text.secondStep },
  ]

  return stepperItems
}
