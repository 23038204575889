import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Typography } from '@mui/material'

import Avatar from 'components/Common/Avatar'
import Box from 'components/Common/Box'
import { Image } from 'components/Common/Carousel'
import ErrorMessage from 'components/Common/ErrorMessage'
import MultiImageInput from 'components/Common/MultiImageInput'
import ButtonContainer from 'components/Template/Creation/ButtonContainer'

import {
  cypressAddPictureButton,
  cypressAddPictureInput,
} from 'constants/cypress'
import { maxAllowedSizePerFileInKb } from 'constants/fileSizes'
import {
  PHOTOGRAPHY_MANUAL_LINK,
  VEHICLE_CREATION_OPTION,
} from 'constants/Inventory/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { VehicleCreationProps } from 'utils/Inventory/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextFieldContainer,
  ThumbnailButton,
} from 'styles/creation'
import { colors } from 'styles/theme'

type initialErrorsType = {
  thumbnail: boolean
  interior: boolean
  exterior: boolean
  size: boolean
  interiorSize: boolean
  interiorTotalSize: boolean
  exteriorSize: boolean
  exteriorTotalSize: boolean
}
const initialErrors: initialErrorsType = {
  thumbnail: false,
  interior: false,
  exterior: false,
  size: false,
  interiorSize: false,
  interiorTotalSize: false,
  exteriorSize: false,
  exteriorTotalSize: false,
}
const VehiclePhotos = ({
  vehicleData,
  updateVehicleData,
  handleBack,
  handleContinue,
}: VehicleCreationProps) => {
  const [thumbnail, setThumbnail] = useState<string>(
    vehicleData.vehiclePhotos.thumbnailPicture
  )
  const [thumbnailFile, setThumbnailFile] = useState<File>(
    vehicleData.vehiclePhotos.thumbnail
  )
  const [interiorPictures, setInteriorPictures] = useState<Image[]>(
    vehicleData.vehiclePhotos.interiorPictureImages.length < 2
      ? []
      : vehicleData.vehiclePhotos.interiorPictureImages
  )
  const [interiorPictureFiles, setInteriorPictureFiles] = useState<File[]>(
    vehicleData.vehiclePhotos.interiorPictures.length < 2
      ? []
      : vehicleData.vehiclePhotos.interiorPictures
  )
  const [exteriorPictures, setExteriorPictures] = useState<Image[]>(
    vehicleData.vehiclePhotos.exteriorPictureImages.length < 2
      ? []
      : vehicleData.vehiclePhotos.exteriorPictureImages
  )
  const [exteriorPictureFiles, setExteriorPictureFiles] = useState<File[]>(
    vehicleData.vehiclePhotos.exteriorPictures.length < 2
      ? []
      : vehicleData.vehiclePhotos.exteriorPictures
  )
  const [errors, setErrors] = useState<initialErrorsType>(initialErrors)

  const {
    text: {
      keepInMind,
      photographyGuidebook,
      forThisStep,
      vehiclePhotos: {
        thumbnail: thumbnailText,
        interior: interiorText,
        exterior: exteriorText,
        oneFile: oneFileText,
        twoFiles: twoFilesText,
      },
    },
  } = useTranslation(textFiles.VEHICLE_CREATION)

  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const handleUpdateInteriorPictures = (newPictures: Image[]) => {
    setInteriorPictures(newPictures)
  }

  const handleUpdateInteriorPictureFiles = (newFiles: File[]) => {
    setInteriorPictureFiles(newFiles)
  }

  const handleUpdateExteriorPictures = (newPictures: Image[]) => {
    setExteriorPictures(newPictures)
  }

  const handleUpdateExteriorPictureFiles = (newFiles: File[]) => {
    setExteriorPictureFiles(newFiles)
  }

  const setThumbnailErrorStatus = (error: boolean) => {
    setErrors((prev) => {
      return { ...prev, thumbnail: error }
    })
  }

  const setThumbnailSizeErrorStatus = (error: boolean) => {
    setErrors((prev) => {
      return { ...prev, size: error }
    })
  }

  const setInteriorErrorStatus = (error: boolean) => {
    setErrors((prev) => {
      return { ...prev, interior: error }
    })
  }

  const setInteriorSizeErrorStatus = (error: boolean, type?: 'totalSize') => {
    /**
     * Type refers to either fileSize error (a file individually exceeds 3MB) OR totalSize error (Files colectively exceed 20MB).
     */
    setErrors((prev) => {
      if (type) {
        return { ...prev, interiorTotalSize: error }
      }
      return { ...prev, interiorSize: error }
    })
  }

  const setExteriorErrorStatus = (error: boolean) => {
    setErrors((prev) => {
      return { ...prev, exterior: error }
    })
  }

  const setExteriorSizeErrorStatus = (error: boolean, type?: 'totalSize') => {
    /**
     * Type refers to either fileSize error (a file individually exceeds 3MB) OR totalSize error (Files colectively exceed 20MB).
     */
    setErrors((prev) => {
      if (type) {
        return { ...prev, exteriorTotalSize: error }
      }
      return { ...prev, exteriorSize: error }
    })
  }

  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()
    const { files } = e.target
    const file = files && files.length > 0 ? files[0] : null
    /**
     * Max size for the image in bytes. We can change this constant to fit any further preferences
     */

    if (file && file.size <= maxAllowedSizePerFileInKb) {
      setThumbnailSizeErrorStatus(false)
      reader.onload = () => {
        if (reader.readyState === 2) {
          setThumbnail(String(reader.result))
          setThumbnailErrorStatus(false)
        }
      }
      if (file) {
        setThumbnailFile(file)
        reader.readAsDataURL(file)
      }
    } else if (file && file.size > maxAllowedSizePerFileInKb) {
      setThumbnailSizeErrorStatus(true)
    }
  }

  const handleConfirm = () => {
    if (
      thumbnail &&
      interiorPictures.length >= 2 &&
      exteriorPictures.length >= 2
    ) {
      updateVehicleData({
        type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_PHOTOS,
        payload: {
          ...vehicleData.vehiclePhotos,
          thumbnailPicture: thumbnail,
          thumbnail: thumbnailFile,
          exteriorPictures: exteriorPictureFiles,
          exteriorPictureImages: exteriorPictures,
          interiorPictures: interiorPictureFiles,
          interiorPictureImages: interiorPictures,
        },
      })
      handleContinue()
    }
    if (!thumbnail) setThumbnailErrorStatus(true)
    if (interiorPictures.length < 2) setInteriorErrorStatus(true)
    if (exteriorPictures.length < 2) setExteriorErrorStatus(true)
  }

  return (
    <StyledContainer>
      <Typography
        fontSize="18px"
        color={colors.subtitleGray}
        marginBottom="2rem"
        variant="body2"
      >
        {`${keepInMind}`}{' '}
        <a
          href={`${PHOTOGRAPHY_MANUAL_LINK}`}
          style={{ color: colors.blue }}
          target="_blank"
          rel="noreferrer"
        >
          {photographyGuidebook}
        </a>{' '}
        {`${forThisStep}`}
      </Typography>
      <StyledForm
        sx={{
          '> div': {
            columnGap: '1.5rem',
          },
        }}
      >
        <div>
          <Box width="100%">
            <StyledTextFieldContainer title={thumbnailText}>
              {thumbnail ? (
                <Avatar
                  height={196}
                  width={192}
                  image={thumbnail}
                  variant="rounded"
                />
              ) : null}
              <input
                type="file"
                name="input"
                id="thumbnail-input"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={imageHandler}
                data-cy={cypressAddPictureInput}
              />
              <ThumbnailButton
                htmlFor="thumbnail-input"
                sx={{ marginTop: '27px', width: 'auto' }}
                data-cy={cypressAddPictureButton}
              >
                <AddIcon /> <span>{generalText.fileInput.chooseFile}</span>
              </ThumbnailButton>
              <Typography
                variant="body2"
                sx={{ fontSize: '12px', marginTop: '6px' }}
              >
                {thumbnail
                  ? oneFileText
                  : generalText.fileInput.chooseFiles.noFiles}
              </Typography>
            </StyledTextFieldContainer>

            {errors.thumbnail && (
              <ErrorMessage text={oneFileText} sx={{ marginTop: '5px' }} />
            )}
            {errors.size && (
              <ErrorMessage
                text={generalText.fileSize.sizeError.replace('%d', 3)}
                sx={{ marginTop: '5px' }}
              />
            )}
          </Box>
          <Box width="64vw !important">
            <MultiImageInput
              title={interiorText}
              pictures={interiorPictures}
              pictureFiles={interiorPictureFiles}
              updatePictures={handleUpdateInteriorPictures}
              updatePictureFiles={handleUpdateInteriorPictureFiles}
              picturesDeletable
              width="100%"
              setFileAmountError={setInteriorErrorStatus}
              setFileSizeError={setInteriorSizeErrorStatus}
              inputId="interior"
              testId="add-interior-pictures"
            />

            {errors.interior && (
              <ErrorMessage text={twoFilesText} sx={{ marginTop: '5px' }} />
            )}
            {errors.interiorSize && (
              <ErrorMessage
                text={generalText.fileSize.multiSizeError.replace('%d', 3)}
                sx={{ marginTop: '5px' }}
              />
            )}
            {errors.interiorTotalSize && (
              <ErrorMessage
                text={generalText.fileSize.maximumSizeError.replace('%d', 20)}
                sx={{ marginTop: '5px' }}
              />
            )}
          </Box>
          <Box width="64vw !important">
            <MultiImageInput
              title={exteriorText}
              pictures={exteriorPictures}
              pictureFiles={exteriorPictureFiles}
              updatePictures={handleUpdateExteriorPictures}
              updatePictureFiles={handleUpdateExteriorPictureFiles}
              picturesDeletable
              width="100%"
              setFileAmountError={setExteriorErrorStatus}
              setFileSizeError={setExteriorSizeErrorStatus}
              inputId="exterior"
              testId="add-exterior-pictures"
            />

            {errors.exterior && (
              <ErrorMessage text={twoFilesText} sx={{ marginTop: '5px' }} />
            )}
            {errors.exteriorSize && (
              <ErrorMessage
                text={generalText.fileSize.multiSizeError.replace('%d', 3)}
                sx={{ marginTop: '5px' }}
              />
            )}
            {errors.exteriorTotalSize && (
              <ErrorMessage
                text={generalText.fileSize.maximumSizeError.replace('%d', 20)}
                sx={{ marginTop: '5px' }}
              />
            )}
          </Box>
        </div>
      </StyledForm>
      <ButtonContainer
        previousFunction={handleBack}
        nextFunction={handleConfirm}
      />
    </StyledContainer>
  )
}

export default VehiclePhotos
