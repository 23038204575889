import React from 'react'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Divider, Typography } from '@mui/material'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { ScheduleResponse } from 'models/outOfSpot'
import { HourTimeType } from 'models/services/curboSpot'

import {
  GridBox,
  StyledAccordion,
  StyledAccordionDetail,
  StyledAccordionSummary,
  StyledCollapseItem,
} from './style'

type ItemProps = {
  title: string
  text: HourTimeType[]
}

const Item = ({ text, title }: ItemProps) => {
  const { length } = text
  return (
    <StyledCollapseItem title={title}>
      <ul>
        <li>
          {length > 1 ? (
            <span>{`${text[0].name} ${text[0].am === true ? 'am' : 'pm'} - ${
              text[length - 1].name
            } ${text[length - 1].am === true ? 'am' : 'pm'}`}</span>
          ) : (
            <span>
              {`${text[0].name} ${text[0].am === true ? 'am' : 'pm'}`}
            </span>
          )}
        </li>
      </ul>
    </StyledCollapseItem>
  )
}

type Props = {
  elements: ScheduleResponse
  title: string
}

const Collapse = ({ elements, title }: Props) => {
  const {
    text: { weekDays },
  } = useTranslation(textFiles.GENERAL)

  return (
    <StyledAccordion square disableGutters>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
        <Divider />
      </StyledAccordionSummary>
      <StyledAccordionDetail>
        <>
          <AccessTimeFilledIcon />
          <GridBox>
            {elements.monday.length ? (
              <Item title={weekDays.monday} text={elements.monday} />
            ) : null}
            {elements.tuesday.length ? (
              <Item title={weekDays.tuesday} text={elements.tuesday} />
            ) : null}
            {elements.wednesday.length ? (
              <Item title={weekDays.wednesday} text={elements.wednesday} />
            ) : null}
            {elements.thursday.length ? (
              <Item title={weekDays.thursday} text={elements.thursday} />
            ) : null}
            {elements.friday.length ? (
              <Item title={weekDays.friday} text={elements.friday} />
            ) : null}
            {elements.saturday.length ? (
              <Item title={weekDays.saturday} text={elements.saturday} />
            ) : null}
            {elements.sunday.length ? (
              <Item title={weekDays.sunday} text={elements.sunday} />
            ) : null}
          </GridBox>
        </>
      </StyledAccordionDetail>
    </StyledAccordion>
  )
}

export default Collapse
