import React from 'react'

import GoBackButton from 'components/Common/GoBackButton'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import Box from '../Box'

export const BackContainer = () => {
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { backButton } = generalText.buttons
  return (
    <Box justifyContent="left" width="fit-content">
      <GoBackButton>{backButton}</GoBackButton>
    </Box>
  )
}
