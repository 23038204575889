import { TrimColumnField } from 'constants/CarSettings/columnField'
import { SelectItem } from 'models/Select'
import { TrimFieldSelectTextType } from 'models/text/carSettingsListing'

export const createTrimFieldSelectItems = (
  text: TrimFieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: TrimColumnField.ID,
    },
    {
      label: text.name,
      value: TrimColumnField.NAME,
    },
    {
      label: text.carName,
      value: TrimColumnField.CAR_NAME,
    },
    {
      label: text.year,
      value: TrimColumnField.YEAR,
    },
    {
      label: text.createdAt,
      value: TrimColumnField.CREATED_AT,
    },

    {
      label: text.status,
      value: TrimColumnField.STATUS,
    },
    {
      label: text.actions,
      value: TrimColumnField.ACTIONS,
    },
  ]
}

export const createTrimFieldSelectLabels = (
  text: TrimFieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    name: text.name,
    createdAt: text.createdAt,
    status: text.status,
    actions: text.actions,
    carName: text.name,
    year: text.year,
  }
}

export const TrimDefaultFields = [
  TrimColumnField.ID,
  TrimColumnField.NAME,
  TrimColumnField.CAR_NAME,
  TrimColumnField.YEAR,
  TrimColumnField.CREATED_AT,
  TrimColumnField.STATUS,
  TrimColumnField.ACTIONS,
]
