import React from 'react'
import { Grid, Typography } from '@mui/material'

import Container from 'components/Common/Container'
import TimeSensitivePopper from 'components/General/TimeSensitivePopper'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Inspector as InspectorProps } from 'models/inspector'
import { InspectorStatusTextType } from 'models/text/Dashboard'

import { FONT_WEIGHT } from 'styles/theme'

import Inspector from './Inspector'

type InspectorStatusProps = {
  inspectorStatuses: InspectorProps[]
}

const InspectorStatus = ({ inspectorStatuses }: InspectorStatusProps) => {
  const { text } = useTranslation(textFiles.INSPECTION_DASHBOARD)

  const {
    inspections: { inspectorStatus },
  }: { inspections: { inspectorStatus: InspectorStatusTextType } } = text
  return (
    <Container
      height={437}
      width="44%"
      contentStyle={{
        width: 'auto',
        height: 'auto',
      }}
      title={inspectorStatus.title}
      description={`${inspectorStatus.description}`}
      extraText={<TimeSensitivePopper />}
      isEmpty
    >
      <Grid alignItems="flex-start" container marginBottom="1rem" width="100%">
        <Grid item xs={6}>
          <Typography fontWeight={FONT_WEIGHT.SEMI_BOLD} variant="body2">
            {inspectorStatus.tableInspector}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={FONT_WEIGHT.SEMI_BOLD} variant="body2">
            #
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={FONT_WEIGHT.SEMI_BOLD} variant="body2">
            {inspectorStatus.tableGoal}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography fontWeight={FONT_WEIGHT.SEMI_BOLD} variant="body2">
            {inspectorStatus.tableProgress}
          </Typography>
        </Grid>
      </Grid>
      <section
        style={{
          width: 'unset',
          overflowY: 'auto',
          height: '335px',
          paddingLeft: '0.25rem',
        }}
      >
        {inspectorStatuses.map((inspectorStatusItem) => (
          <Inspector key={inspectorStatusItem.id} {...inspectorStatusItem} />
        ))}
      </section>
    </Container>
  )
}

export default InspectorStatus
