import { styled, Tab, tabClasses, Tabs, tabsClasses } from '@mui/material'

import Box from 'components/Common/Box'

import { colors } from 'styles/theme'

export const StyledContainer = styled(Box)({
  backgroundColor: colors.commonWhite,
  boxShadow: `0px 3px 6px 3px ${colors.shadow}`,
  height: '390px',
  width: 'fit-content',
  display: 'flex',
  borderRadius: '10px',
})

export const StyledTabs = styled(Tabs)({
  borderRight: `1px solid ${colors.faintGray}`,
  [`& .${tabsClasses.indicator}`]: {
    backgroundColor: colors.transparent,
  },
  [`& .${tabsClasses.flexContainerVertical}`]: {
    height: '100%',
    justifyContent: 'center',
  },
})

export const StyledTab = styled(Tab)({
  alignItems: 'flex-start',
  color: colors.gray,
  fontSize: '12px',
  margin: '0.5rem 0rem',
  minHeight: '24px',
  padding: '0rem 2rem 0rem 1rem',
  [`&.${tabClasses.selected}`]: {
    backgroundColor: colors.blue,
    color: colors.commonWhite,
  },
})
