import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import AvatarContainer from 'components/CarSettings/Common/Creation/Avatar'
import ButtonContainer from 'components/CarSettings/Common/Creation/ButtonContainer'
import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'
import { TranslationContainer } from 'components/CarSettings/style'
import ErrorMessage from 'components/Common/ErrorMessage'
import Translation from 'components/General/Translation'
import { TranslationItemType } from 'components/General/Translation/TranslationListItem'

import { BODY_STYLE_CREATION_OPTION } from 'constants/bodyStyle'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { BodyStyle } from 'models/car'
import { PublicationStatus } from 'models/status'
import { TranslationChange } from 'models/translations'
import { sleep } from 'utils/BasicUtil'
import { BodyStyleCreationProps } from 'utils/CarSettings/BodyStyle/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const BodyStyleInfo = ({
  handleContinue,
  bodyStyleData,
  updateBodyStyleData,
}: BodyStyleCreationProps) => {
  const { bodyStyleInformation } = bodyStyleData
  const [bodyStyleStatus, setBodyStyleStatus] = useState<boolean>(
    bodyStyleInformation.status === PublicationStatus.PUBLISHED
  )
  const [pictureState, setPictureState] = useState<string>(
    bodyStyleInformation.picture
  )
  const [fileState, setFileState] = useState<File | null>(null)

  const [addedTranslations, setAddedTranslations] = useState<
    TranslationItemType[]
  >(bodyStyleInformation.translations)

  const { text: translation } = useTranslation(textFiles.BODY_STYLE_CREATION)
  const { information } = translation

  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const handleChangeTranslation = (
    translations: TranslationItemType[],
    type: TranslationChange
  ) => {
    sessionStorage.setItem('type', type)
    setAddedTranslations(translations)
  }

  const validationSchema = yup.object({
    name: yup.string().required(validationText.fieldRequired),
  })

  const formik = useFormik<Partial<BodyStyle>>({
    initialValues: {
      name: bodyStyleInformation.name,
    },
    validationSchema,
    onSubmit: async (values) => {
      await sleep(1000)
      updateBodyStyleData({
        type: BODY_STYLE_CREATION_OPTION.UPDATE_BODY_STYLE_INFORMATION,
        payload: {
          name: values.name as string,
          translations: addedTranslations,
          picture: pictureState,
          pictureFile: fileState,
          status: bodyStyleStatus
            ? PublicationStatus.PUBLISHED
            : PublicationStatus.UNPUBLISHED,
        },
      })

      handleContinue()
    },
  })

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <ToggleContainer
            title={information.isPublished}
            description={information.isPublishedDescription}
            status={bodyStyleStatus}
            handleStatus={setBodyStyleStatus}
            testId="published"
            width="320px !important"
          />
          <StyledTextFieldContainer
            title={information.name}
            description={information.nameDescription}
          >
            <StyledTextField
              variant="outlined"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder={information.placeholder}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
            {formik.touched.name && formik.errors.name ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.name}
              />
            ) : null}
          </StyledTextFieldContainer>
          <AvatarContainer
            title={information.logo}
            description={information.logoDescription}
            picture={pictureState}
            handlePictureChange={setPictureState}
            handleFileChange={setFileState}
          />
          <TranslationContainer>
            <Translation
              addedTranslations={addedTranslations}
              handleChangeTranslation={handleChangeTranslation}
              translationDescription={information.translationsDescription}
            />
          </TranslationContainer>
        </div>
      </StyledForm>
      <ButtonContainer
        isFirstStep
        confirmButtonType="submit"
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default BodyStyleInfo
