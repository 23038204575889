import React from 'react'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { Divider, Typography } from '@mui/material'

import Box from 'components/Common/Box'
import { GeneralViewContainer } from 'components/General/GeneralView'

import { createModuleCards } from 'constants/generalView'
import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { formatDateLocalizedTime } from 'utils/date'

import { Layout } from 'styles/inspection/detail'
import { colors } from 'styles/theme'

const Home = () => {
  const { text } = useTranslation(textFiles.GENERAL_VIEW)
  const { general: translation } = text
  const [selectedLanguage] = useLocale()
  const userName = useUser().user?.name
  const today = new Date()
  const todayInLetters = `${formatDateLocalizedTime(
    today,
    'MMMM do yyyy',
    selectedLanguage.code
  )}`

  const modules = createModuleCards(text.modules)

  return (
    <Layout maxWidth="1462px !important">
      <Typography variant="h3">{translation.title}</Typography>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        width="50%"
      >
        <PlaylistAddCheckIcon
          fontSize="medium"
          sx={{ color: colors.gray, marginRight: '0.5rem' }}
        />
        <Typography
          variant="body2"
          color={colors.gray}
          sx={{
            span: {
              color: colors.blue,
            },
          }}
        >
          {translation.welcome} <span>{userName}</span>,{' '}
          {translation.date.replace('%date', todayInLetters)}
        </Typography>
      </Box>
      <Divider
        sx={{
          display: 'flex',
          flexDirection: 'row',
          borderColor: colors.gray,
          marginBottom: '1rem',
        }}
      />
      <GeneralViewContainer modules={modules} />
    </Layout>
  )
}

export default Home
