import { gql } from '@apollo/client'

export const GET_CITIES = gql`
  query getCities($input: FilterInput) {
    data: getCities(input: $input) {
      value: id
      name
      state {
        value: id
        name
      }
    }
  }
`
