import { styled, typographyClasses } from '@mui/material'

import Container from 'components/Common/Container'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors } from 'styles/theme'

export const StyledContainer = styled(Container)({
  width: '200px',
  height: 'auto',
  display: 'flex',
  padding: '1rem',
  marginTop: '1rem',
  order: 1,

  [`@media only screen and (min-width: ${BREAK_POINTS.XXL}px)`]: {
    width: '289px',
    height: '190px',
    padding: '1rem',
    marginTop: '0rem',
    order: 0,
  },

  [`& .${typographyClasses.subtitle2}`]: {
    color: colors.paleGray,
    display: 'flex',
    alignItems: 'center',
    svg: {
      width: '20px',
      height: '20px',
    },
  },

  '> div:nth-of-type(2)': {
    display: 'flex',
    // marginTop: '2rem',
    flexWrap: 'wrap',
    ul: {
      paddingLeft: '1rem',
      margin: '.5rem 0 0',
      li: {
        color: colors.black,
        fontSize: '12px',
        marginBottom: '.5rem',
        '&::marker': {
          color: colors.blue,
        },
      },
    },
    '> div:nth-of-type(1)': {
      width: '100%',
      height: '202px',
      [`@media only screen and (min-width: ${BREAK_POINTS.XXL}px)`]: {
        width: '120px',
        height: '120px',
      },
    },
  },
})

export const StyledSection = styled('section')({
  height: '120px',
  padding: '0',
  width: '80%',
  marginTop: '14px',
  paddingLeft: '1rem',

  [`@media only screen and (min-width: ${BREAK_POINTS.XXL}px)`]: {
    height: '120px',
    padding: '0 1.5rem',
    width: '120px',
    marginTop: '0px',
  },
})
