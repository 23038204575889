import { ClientInfo } from 'components/Inspector/Detail/Personal'

import { SelectItem } from 'models/Select'

export const unknownCurboSpotId = 'unknown-curbo-spot'

export enum InspectorColumnField {
  PICTURE = 'picture',
  INSPECTOR_NAME = 'name',
  CURBO_SPOT = 'curboSpot',
  ID = 'id',
  PROVINCE = 'province',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  ACTIONS = 'actions',
}

export const dummyInspector: ClientInfo[] = [
  {
    name: 'Vanessa Wright',
    address: 'Santo Domingo Oeste, D.R.',
    phone: '1 (809) 568 4157',
    email: 'bulerio@gmail.com',
  },
  {
    name: 'Vanessa Bright',
    address: 'Santo Domingo Oeste, D.R.',
    phone: '1 (809) 568 4157',
    email: 'bright@gmail.com',
  },
]

export const commisionsPieChartData = [
  { id: 1, name: 'Group A', value: 60 },
  { id: 2, name: 'Group B', value: 40 },
]

export const commisionsNotesData = [
  {
    index: 0,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid"u"n"t"',
  },
  {
    index: 1,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid"u"n"t"',
  },
  {
    index: 2,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid"u"n"t"',
  },
]

export const dummyCurboSpots: SelectItem[] = [
  { label: 'La Vega', value: 'lavega' },
  { label: 'Bella Vista', value: 'bellavista' },
  { label: 'Cibao', value: 'cibao' },
  { label: 'Este', value: 'este' },
  { label: 'Piantini', value: 'piantini' },
  { label: 'Norte', value: 'norte' },
]
