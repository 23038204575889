import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import Autocomplete, { AutocompleteItem } from 'components/Common/Autocomplete'
import Box from 'components/Common/Box'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import ButtonContainer from 'components/Template/Creation/ButtonContainer'

import {
  providerOptions,
  VEHICLE_CREATION_OPTION,
} from 'constants/Inventory/creation'
import { CarProviderEnum } from 'constants/Inventory/detail'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { AltCurboSpot } from 'models/curboSpot'
import { FilterInputVariable, GenericData } from 'models/services/base'
import { AltDealer, Dealer } from 'models/services/curboSpot'
import { VehicleCreationProps } from 'utils/Inventory/creation'
import { formatPhoneNumber } from 'utils/phone'

import { GET_ALT_CURBO_SPOTS, GET_DEALERS } from 'graphQL/Common/Dealer/queries'

import {
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const getRepresentativeAgentName = (dealer: AltDealer | null) => {
  if (!dealer) return ''

  const { dealerContacts } = dealer

  return dealerContacts.length > 0 ? dealerContacts[0].agent : 'N/A'
}

const getRepresentativePhoneumber = (dealer: AltDealer | null) => {
  if (!dealer) return ''

  const { dealerContacts } = dealer

  return dealerContacts.length > 0
    ? formatPhoneNumber(dealerContacts[0].telephoneNumber)
    : 'N/A'
}

type ProviderInfoError = {
  dealer: boolean
  curboSpot: boolean
}

const initialErrors: ProviderInfoError = {
  dealer: false,
  curboSpot: false,
}

const Provider = ({
  vehicleData,
  updateVehicleData,
  handleBack,
  handleContinue,
}: VehicleCreationProps) => {
  const {
    text: { providerInformation: translation },
  } = useTranslation(textFiles.VEHICLE_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const { providerInformation } = vehicleData

  const [providerOption, setProviderOption] = useState<AutocompleteItem>(
    providerInformation.providerOption
  )
  const [curboSpot, setCurboSpot] = useState<AltCurboSpot | null>(
    providerInformation.curboSpot
  )
  const [dealer, setDealer] = useState<AltDealer | null>(
    providerInformation.dealer
  )
  const [errors, setErrors] = useState<ProviderInfoError>(initialErrors)

  const [dealers, setDealers] = useState<AltDealer[]>([])
  const [curboSpots, setCurboSpots] = useState<AltCurboSpot[]>([])

  const { loading: dealersLoading } = useQuery<
    GenericData<Dealer[]>,
    FilterInputVariable
  >(GET_DEALERS, {
    variables: {
      input: {
        sort: { name: 'asc' },
      },
    },
    onCompleted(response) {
      setDealers(
        response.data.map((currentDealer) => {
          return {
            ...currentDealer,
            id: currentDealer.value,
          }
        })
      )
    },
  })

  const { loading: curboSpotsLoading } = useQuery<
    GenericData<AltCurboSpot[]>,
    FilterInputVariable
  >(GET_ALT_CURBO_SPOTS, {
    variables: {
      input: {
        sort: { name: 'asc' },
      },
    },
    onCompleted(response) {
      setCurboSpots(response.data)
    },
  })

  const handleChangeProviderType = (value: AutocompleteItem) => {
    setProviderOption(value)
    setCurboSpot(null)
    setDealer(null)
    setErrors(initialErrors)
  }

  const handleChangeCurboSpot = (value: AltCurboSpot) => {
    setCurboSpot(value)
    setErrors((prevError) => {
      return {
        ...prevError,
        curboSpot: false,
      }
    })
  }

  const handleChangeDealer = (value: AltDealer) => {
    setDealer(value)
    setErrors((prevError) => {
      return {
        ...prevError,
        dealer: false,
      }
    })
  }

  const handleConfirm = () => {
    if (providerOption.id === CarProviderEnum.CURBO_SPOT && !curboSpot) {
      setErrors({
        dealer: false,
        curboSpot: true,
      })
      return
    }
    if (
      providerOption.id === CarProviderEnum.DEALER &&
      (!curboSpot || !dealer)
    ) {
      setErrors({
        dealer: !dealer,
        curboSpot: !curboSpot,
      })
      return
    }

    updateVehicleData({
      type: VEHICLE_CREATION_OPTION.UPDATE_PROVIDER_INFORMATION,
      payload: {
        curboSpot,
        dealer,
        providerOption,
      },
    })
    handleContinue()
  }

  if (dealersLoading || curboSpotsLoading)
    return <LoadingAnimation showAnimation />

  return (
    <StyledContainer>
      <StyledForm>
        <Box
          sx={{
            '> div': {
              width: '280px !important',
            },
          }}
        >
          {' '}
          <Box width="100%">
            <StyledTextFieldContainer
              title={translation.typeLabel}
              sx={{ width: '96%' }}
            >
              <Autocomplete
                options={providerOptions}
                onChangeHandler={handleChangeProviderType}
                placeholder={translation.typeLabel}
                value={providerOption}
                disablePortal={false}
                testId="provider-types-autocomplete"
              />
            </StyledTextFieldContainer>
          </Box>
          {providerOption.id === CarProviderEnum.CURBO_SPOT ? (
            <>
              <StyledTextFieldContainer title={translation.curboSpotLabel}>
                <Autocomplete
                  options={curboSpots}
                  onChangeHandler={(value: AutocompleteItem) => {
                    handleChangeCurboSpot(value as AltCurboSpot)
                  }}
                  placeholder={translation.curboSpotLabel}
                  value={curboSpot}
                  disablePortal={false}
                  error={errors.curboSpot}
                  testId="curbo-spots-autocomplete"
                />
                {errors.curboSpot && (
                  <StyledErrorMessage text={validationText.fieldRequired} />
                )}
              </StyledTextFieldContainer>

              <StyledTextFieldContainer
                title={translation.curboSpotPhoneNumberLabel}
              >
                <StyledTextField
                  variant="outlined"
                  value={formatPhoneNumber(
                    curboSpot ? curboSpot.telephoneNumber : ''
                  )}
                  placeholder={translation.curboSpotPhoneNumberLabel}
                  disabled
                />
              </StyledTextFieldContainer>

              <Box width="100%">
                <StyledTextFieldContainer
                  title={translation.curboSpotLocationLabel}
                  sx={{ width: '96%' }}
                >
                  <StyledTextField
                    variant="outlined"
                    value={curboSpot ? curboSpot.address : ''}
                    placeholder={translation.curboSpotLocationLabel}
                    disabled
                  />
                </StyledTextFieldContainer>
              </Box>
            </>
          ) : null}
          {providerOption.id === CarProviderEnum.DEALER ? (
            <>
              <StyledTextFieldContainer title={translation.dealerNameLabel}>
                <Autocomplete
                  options={dealers}
                  onChangeHandler={(value: AutocompleteItem) => {
                    handleChangeDealer(value as AltDealer)
                  }}
                  placeholder={translation.dealerNameLabel}
                  value={dealer}
                  disablePortal={false}
                  error={errors.dealer}
                  testId="dealers-autocomplete"
                />
                {errors.dealer && (
                  <StyledErrorMessage text={validationText.fieldRequired} />
                )}
              </StyledTextFieldContainer>
              <StyledTextFieldContainer
                title={translation.dealerPhoneNumberLabel}
              >
                <StyledTextField
                  variant="outlined"
                  value={formatPhoneNumber(
                    dealer ? dealer.telephoneNumber : ''
                  )}
                  placeholder={translation.dealerPhoneNumberLabel}
                  disabled
                />
              </StyledTextFieldContainer>
              <StyledTextFieldContainer
                title={translation.representativeNameLabel}
              >
                <StyledTextField
                  variant="outlined"
                  value={getRepresentativeAgentName(dealer)}
                  placeholder={translation.representativeNameLabel}
                  disabled
                />
              </StyledTextFieldContainer>
              <StyledTextFieldContainer
                title={translation.representativePhoneNumberLabel}
              >
                <StyledTextField
                  variant="outlined"
                  value={getRepresentativePhoneumber(dealer)}
                  placeholder={translation.representativePhoneNumberLabel}
                  disabled
                />
              </StyledTextFieldContainer>
              <StyledTextFieldContainer title={translation.curboSpotLabel}>
                <Autocomplete
                  options={curboSpots}
                  onChangeHandler={(value: AutocompleteItem) => {
                    handleChangeCurboSpot(value as AltCurboSpot)
                  }}
                  placeholder={translation.curboSpotLabel}
                  value={curboSpot}
                  disablePortal={false}
                  error={errors.curboSpot}
                  testId="curbo-spots-autocomplete"
                />
                {errors.curboSpot && (
                  <StyledErrorMessage text={validationText.fieldRequired} />
                )}
              </StyledTextFieldContainer>
              <StyledTextFieldContainer title={translation.dealerLocationLabel}>
                <StyledTextField
                  variant="outlined"
                  value={dealer ? dealer.address : ''}
                  placeholder={translation.dealerLocationLabel}
                  disabled
                  sx={{
                    minWidth: '280px',
                    width: `${
                      dealer && dealer.address.length * 7
                    }px !important`,
                  }}
                />
              </StyledTextFieldContainer>
            </>
          ) : null}
        </Box>
      </StyledForm>
      <ButtonContainer
        previousFunction={handleBack}
        nextFunction={handleConfirm}
      />
    </StyledContainer>
  )
}

export default Provider
