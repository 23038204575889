import React, { useState } from 'react'

import NameContainer from 'components/CarSettings/Common/Detail/NameContainer'
import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'
import {
  GridBox,
  StyledContainer,
} from 'components/CarSettings/Trim/Detail/Settings/style'
import Box from 'components/Common/Box'

import { textFiles } from 'constants/textFiles'
import { getTrimMileage } from 'constants/trim'
import useTranslation from 'hooks/useTranslation'
import { NameInformation } from 'models/car'
import { TrimDetailDataType } from 'models/services/CarSettings/detail'

type Props = {
  information: TrimDetailDataType
  handleSave: (data: TrimDetailDataType) => Promise<boolean>
  submitLoading: boolean
}

const Mileage = ({ information, handleSave, submitLoading }: Props) => {
  const { text } = useTranslation(textFiles.TRIM_DETAIL)
  const { power: translation } = text

  const initialNameInformation = getTrimMileage(
    translation.torque,
    translation.mileage,
    translation.milesLabel,
    information
  )

  const [torque, setTorque] = useState<NameInformation[]>(
    initialNameInformation.torque
  )
  const [mileage, setMileage] = useState<NameInformation[]>(
    initialNameInformation.mileage
  )
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)

  const resetState = () => {
    setTorque(initialNameInformation.torque)
    setMileage(initialNameInformation.mileage)
    setHasUnsavedChanges(false)
  }

  const handleChangeTorque = (newInformation: NameInformation[]) => {
    setTorque(newInformation)
    setHasUnsavedChanges(true)
  }

  const handleChangeMileage = (newInformation: NameInformation[]) => {
    setMileage(newInformation)
    setHasUnsavedChanges(true)
  }

  const handleSaveChanges = async () => {
    const [
      torqueItem,
      torqueRpm,
      cylinders,
      horsePower,
      horsePowerRpm,
      fuelCapacity,
    ] = (torque as Array<{ name: number }>).map((measure) => measure.name)
    const [mpg, mpgHgw, mpgCity] = (mileage as Array<{ name: number }>).map(
      (measure) => measure.name
    )
    const isUpdateSuccesful = await handleSave({
      ...information,
      torque: torqueItem,
      torqueRpm,
      cylinders,
      horsePower,
      horsePowerRpm,
      fuelCapacity,
      mpg,
      mpgHgw,
      mpgCity,
    })
    if (isUpdateSuccesful) {
      setHasUnsavedChanges(false)
    }
  }

  return (
    <StyledContainer>
      <GridBox>
        <NameContainer
          title={translation.torque.title}
          description={translation.torque.description}
          containerSx={{
            padding: '0px !important',
            paddingTop: '1rem !important',
            maxWidth: '399px',
          }}
          information={torque}
          handleSetInformation={handleChangeTorque}
          loading={submitLoading}
        />
        <NameContainer
          title={translation.mileage.title}
          description={translation.mileage.description}
          containerSx={{
            padding: '0px !important',
            paddingTop: '1rem !important',
            maxWidth: '399px',
          }}
          information={mileage}
          handleSetInformation={handleChangeMileage}
          loading={submitLoading}
        />
      </GridBox>
      <Box paddingTop="1rem">
        <SaveChangesBlock
          handleSaveChanges={handleSaveChanges}
          resetState={resetState}
          submitLoading={submitLoading}
          shouldRender={hasUnsavedChanges}
        />
      </Box>
    </StyledContainer>
  )
}

export default Mileage
