import React from 'react'

import Box from 'components/Common/Box'
import Status from 'components/Listing/Status'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { HistoryLogContentType } from 'models/historyLog'
import {
  InspectionStatusMap,
  InspectorStatusMap,
  PublicationStatusMap,
  TemplateStatusMap,
} from 'models/status'

import { StyledDescription } from './style'

const statusVariant = {
  carSettings: PublicationStatusMap,
  inspections: InspectionStatusMap,
  inspectors: InspectorStatusMap,
  template: TemplateStatusMap,
}

const HistoryLogMessage = ({
  entity,
  valueChanged,
  value,
  file = textFiles.HISTORY_LOG,
}: HistoryLogContentType) => {
  const { text } = useTranslation(file)
  const { statusOptions, entities, valueToChange } = text.historyLogOptions

  const getStatusVariant = (status: string) => {
    const selectedVariant = statusVariant[entity]
    return selectedVariant[status]
  }

  const messageIntro = text.history.change
    .replace('%valueChanged', valueToChange[valueChanged])
    .replace('%entity', entities[entity])

  const Message = () => {
    if (value) {
      if (valueChanged === 'status') {
        return (
          <StyledDescription variant="subtitle1">
            <Box display="flex" flexDirection="row" alignItems="center">
              {`${messageIntro}
            ${text.history.from}`}
              <Box marginX="5px">
                <Status variant={getStatusVariant(value.before)}>
                  {statusOptions[value.before]}
                </Status>
              </Box>
              {`${text.history.to} `}
              <Box marginLeft="5px">
                <Status variant={getStatusVariant(value.after)}>
                  {statusOptions[value.after]}
                </Status>
              </Box>
            </Box>
          </StyledDescription>
        )
      }
      return (
        <StyledDescription variant="subtitle1">
          {`${messageIntro}  ${text.history.from} ${value.before}  ${text.history.to} ${value.after}`}
        </StyledDescription>
      )
    }
    return (
      <StyledDescription variant="subtitle1">{messageIntro}</StyledDescription>
    )
  }

  return <Message />
}

export { HistoryLogMessage }
