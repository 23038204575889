import { gql } from '@apollo/client'

export const UPDATE_TRIM_LEVEL = gql`
  mutation updateTrimLevel($input: UpdateTrimLevelInput!) {
    data: updateTrimLevel(input: $input) {
      id
    }
  }
`

export const PUBLISH_TRIM_LEVEL = gql`
  mutation publishTrimLevel($input: ID!) {
    data: publishTrimLevel(id: $input) {
      id
    }
  }
`

export const UNPUBLISH_TRIM_LEVEL = gql`
  mutation unpublishTrimLevel($input: ID!) {
    data: unpublishTrimLevel(id: $input) {
      id
    }
  }
`
