import { Box, styled } from '@mui/material'

export const StyledCarousel = styled(Box)({
  display: 'flex',
  overflowX: 'scroll',

  msOverflowStyle: 'none' /* Internet Explorer 10+ */,
  scrollbarWidth: 'none' /* Firefox */,

  '&::-webkit-scrollbar': {
    display: 'none' /* Safari and Chrome */,
  },
})
