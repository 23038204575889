import { gql } from '@apollo/client'

// Test Drive fragments
export const TEST_DRIVE_HOUR_TIME_FRAGMENT = gql`
  fragment TestDriveHourTimeFragment on TestDriveHours {
    am
    value: id
    name
  }
`
export const TEST_DRIVE_WEEK_SCHEDULE_FRAGMENT = gql`
  ${TEST_DRIVE_HOUR_TIME_FRAGMENT}
  fragment TestDriveWeekScheduleFragment on TestDriveWeekCalendar {
    id
    monday {
      ...TestDriveHourTimeFragment
    }
    tuesday {
      ...TestDriveHourTimeFragment
    }
    wednesday {
      ...TestDriveHourTimeFragment
    }
    thursday {
      ...TestDriveHourTimeFragment
    }
    friday {
      ...TestDriveHourTimeFragment
    }
    saturday {
      ...TestDriveHourTimeFragment
    }
    sunday {
      ...TestDriveHourTimeFragment
    }
  }
`
// Inspections fragments
export const INSPECTION_HOUR_TIME_FRAGMENT = gql`
  fragment InspectionHourTimeFragment on InspectionHours {
    am
    value: id
    name
    continentalTime
  }
`

export const INSPECTION_WEEK_SCHEDULE_FRAGMENT = gql`
  ${INSPECTION_HOUR_TIME_FRAGMENT}
  fragment InspectionWeekScheduleFragment on InspectionWeekCalendar {
    id
    monday {
      ...InspectionHourTimeFragment
    }
    tuesday {
      ...InspectionHourTimeFragment
    }
    wednesday {
      ...InspectionHourTimeFragment
    }
    thursday {
      ...InspectionHourTimeFragment
    }
    friday {
      ...InspectionHourTimeFragment
    }
    saturday {
      ...InspectionHourTimeFragment
    }
    sunday {
      ...InspectionHourTimeFragment
    }
  }
`

export const GET_TEST_DRIVE_WEEK_CALENDAR_BY_CURBO_SPOT_ID = gql`
  ${TEST_DRIVE_WEEK_SCHEDULE_FRAGMENT}
  query getTestDriveWeekCalendarByCurboSpotId($input: ID) {
    data: getTestDriveWeekCalendarByCurboSpotId(id: $input) {
      ...TestDriveWeekScheduleFragment
    }
  }
`

export const GET_TEST_DRIVE_HOURS = gql`
  ${TEST_DRIVE_HOUR_TIME_FRAGMENT}
  query getTestDriveHours($input: FilterInput) {
    data: getTestDriveHours(input: $input) {
      ...TestDriveHourTimeFragment
    }
  }
`

export const GET_INSPECTION_HOURS = gql`
  ${INSPECTION_HOUR_TIME_FRAGMENT}
  query getInspectionHours($input: FilterInput) {
    data: getInspectionHours(input: $input) {
      ...InspectionHourTimeFragment
    }
  }
`
