import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  Autocomplete,
  inputBaseClasses,
  MenuItem,
  menuItemClasses,
  outlinedInputClasses,
  styled,
  TextField,
} from '@mui/material'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledAutocomplete = styled(Autocomplete)({
  color: colors.gray,
  backgroundColor: colors.transparent,
  height: '38px',
  fontSize: '12px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  letterSpacing: '0.14px',

  '#autocompleteBar': {
    padding: '0 0 0 15px',
    height: '100%',
  },
})

export const StyledTextField = styled(TextField)({
  color: colors.gray,
  backgroundColor: colors.transparent,
  [`& .${inputBaseClasses.input}`]: {
    padding: '0',
    fontSize: '12px',
  },
  [`& .${inputBaseClasses.root}`]: {
    color: colors.gray,
    maxHeight: '100%',
    fontWeight: FONT_WEIGHT.MEDIUM,
    '&:hover > fieldset': {
      borderColor: colors.blue,
    },
  },
  [`& .${outlinedInputClasses.root}`]: {
    height: '100%',
    padding: '0',
    border: 'none',
    backgroundColor: colors.commonWhite,
  },
})

export const StyledMenuItem = styled(MenuItem)({
  color: colors.gray,
  fontSize: '12px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  letterSpacing: '0.12px',
  zIndex: 1600,

  ':hover': {
    backgroundColor: `${colors.background}`,
    color: colors.altBlack,
  },

  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: colors.cyan,
    color: colors.darkBlue,
  },
})

export const ArrowIcon = styled(KeyboardArrowDownIcon)({
  color: `${colors.lightGray} !important`,
})

export const InputContainer = styled('div')({
  display: 'flex',
  position: 'relative',
  height: '38px',
  margin: 0,
  padding: 0,
  '> svg': {
    color: colors.gray,
    position: 'absolute',
    width: '20px',
    height: '20px',
    top: '9px',
    left: '6px',
    zIndex: 1500,
  },
})
