import React, { useState } from 'react'
import { AlertColor } from '@mui/material'

type NotificationProviderProps = { children: React.ReactNode }

type NotificationData = {
  message: string
  severity: AlertColor
}
type handleShowParams = {
  updatedSeverity: AlertColor
  message?: string
}
export type NotificationContextType = {
  show: ({ updatedSeverity, message }: handleShowParams) => void
  close: () => void
  notificationOpen: boolean
  notificationData: NotificationData
}

export const NotificationContext = React.createContext<
  NotificationContextType | undefined
>(undefined)

const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false)
  const [notificationData, setNotificationData] = useState<NotificationData>({
    severity: 'success',
    message: '',
  })
  const handleClose = () => {
    setNotificationOpen(false)
  }

  const handleShow = ({ message, updatedSeverity }: handleShowParams) => {
    setNotificationOpen(true)
    if (message) {
      setNotificationData({
        severity: updatedSeverity,
        message,
      })
    } else {
      setNotificationData({
        severity: updatedSeverity,
        message: '',
      })
    }
  }
  const value = {
    show: handleShow,
    notificationOpen,
    close: handleClose,
    notificationData,
  }

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider
