import React, { useState } from 'react'
import CameraAltIcon from '@mui/icons-material/CameraAlt'

import { StateOption } from 'pages/Operation/CurboSpot/Detail'

import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'
import Avatar from 'components/Common/Avatar'
import Box from 'components/Common/Box'
import ErrorMessage from 'components/Common/ErrorMessage'

import { maxAllowedSizePerFileInKb } from 'constants/fileSizes'
import { initialCurboSpotData } from 'constants/Operation/curboSpot'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { NameInformation } from 'models/car'
import { CurboSpotDetailDataType } from 'models/services/operations/curboSpot'

import placeholderCar from 'images/blueDefaultAvatar.png'

import NameContainer from './NameContainer'
import { ImgContainer, StyledButton, VehicleBox } from './style'

type Props = {
  information: CurboSpotDetailDataType
  states: StateOption[]
  loading: boolean
  handleSave: (spotData: CurboSpotDetailDataType) => Promise<boolean>
}

const Information = ({ information, states, loading, handleSave }: Props) => {
  const { mainPicture } = information

  const { text } = useTranslation(textFiles.CURBO_SPOT_DETAIL)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { curboSpotInformation: translation } = text

  const [pictureSizeErrorStatus, setPictureSizeErrorStatus] =
    useState<boolean>(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)
  const [hasChangedPicture, setHasChangedPicture] = useState<boolean>(false)

  const initialNameInformation = initialCurboSpotData(
    translation,
    information,
    states
  )

  const [picture, setPicture] = useState(
    information.mainPicture || placeholderCar
  )
  const [fileState, setFileState] = useState<File | null>(null)
  const [info, setInfo] = useState<NameInformation[]>(initialNameInformation)

  const resetState = () => {
    setPicture(mainPicture || placeholderCar)
    setInfo(initialNameInformation)
    setHasUnsavedChanges(false)
    setHasChangedPicture(false)
  }

  const handleChangeInformation = (newInformation: NameInformation[]) => {
    setInfo(newInformation)
    setHasUnsavedChanges(true)
  }

  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()
    const { files } = e.target
    const file = files && files.length > 0 ? files[0] : null

    if (file && file.size <= maxAllowedSizePerFileInKb) {
      setPictureSizeErrorStatus(false)
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPicture(String(reader.result))
        }
      }
      if (file) {
        setFileState(file)
        reader.readAsDataURL(file)
      }
      setHasUnsavedChanges(true)
      setHasChangedPicture(true)
    } else if (file && file.size > maxAllowedSizePerFileInKb) {
      setPictureSizeErrorStatus(true)
    }
  }

  const handleSaveChanges = async () => {
    const isUpdateSuccesful = await handleSave({
      ...information,
      name: info[0].name as string,
      telephoneNumber: info[1].name as string,
      city: {
        id: info[2].name as string,
        name: info[2].name as string,
        state: {
          id: info[3].name as string,
          name: info[3].name as string,
        },
      },
      address: info[4].name as string,
      hasChangedPicture,
      fileState,
    })
    if (isUpdateSuccesful) {
      setHasChangedPicture(false)
      setHasUnsavedChanges(false)
    }
  }

  return (
    <Box height="100%">
      <VehicleBox>
        <ImgContainer
          title={translation.images}
          description={translation.imagesDescription}
          contentStyle={{
            overflow: 'auto',
          }}
        >
          <Box
            display="flex"
            borderRadius="10px"
            position="relative"
            sx={{
              height: '322px',
              width: '100%',
              marginBottom: '4px',
            }}
          >
            <Avatar
              image={picture}
              variant="rounded"
              height="100%"
              width="100%"
            />
            <input
              disabled={false}
              type="file"
              name="input"
              id="spot-picture-input"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={imageHandler}
            />
            <StyledButton htmlFor="spot-picture-input">
              <CameraAltIcon />
            </StyledButton>
          </Box>
          {pictureSizeErrorStatus && (
            <ErrorMessage
              text={generalText.fileSize.sizeError.replace('%d', 3)}
              sx={{ marginTop: '5px' }}
            />
          )}
        </ImgContainer>
        <NameContainer
          translation={translation}
          containerSx={{
            padding: '1rem 0 0 !important',
          }}
          information={info}
          handleSetInformation={handleChangeInformation}
          loading={loading}
          states={states}
        />
      </VehicleBox>
      <SaveChangesBlock
        handleSaveChanges={handleSaveChanges}
        resetState={resetState}
        submitLoading={loading}
        shouldRender={hasUnsavedChanges}
      />
    </Box>
  )
}

export default Information
