import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { ApolloError, useMutation, useQuery } from '@apollo/client'

import { DetailHeader } from 'components/CarSettings/Common/Detail/DetailHeader'
import { DetailSubHeader } from 'components/CarSettings/Common/Detail/DetailSubHeader'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import { NoteType } from 'components/Common/Notes'
import DetailNavTab, { NavBarItem } from 'components/General/DetailNavTab'
import Appointment from 'components/Inspection/Detail/Appointment'
import Client from 'components/Inspection/Detail/Client'
import DealerInformation from 'components/Inspection/Detail/DealerInformation'
import Report from 'components/Inspection/Detail/Report'
import TabPanel from 'components/Inspection/Detail/TabPanel'
import VehicleInformation from 'components/Inspection/Detail/VehicleInformation'

import { routes } from 'constants/routes'
import { completedStatus } from 'constants/status'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import {
  BaseIdEntity,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import {
  Inspection,
  ScheduleInspectionInput,
  UpdateInspectionAppointmentType,
  UpdateInspectionInput,
} from 'models/services/inspection/detail'
import { ListStatus } from 'models/services/inspection/listing'
import { ExtendedStatus } from 'models/services/status'
import { InspectionSlugStatus, InspectionStatus } from 'models/status'
import { generateTabItems } from 'utils/tabs'

import {
  APPROVE_INSPECTION,
  SCHEDULE_INSPECTION,
  UPDATE_INSPECTION,
} from 'graphQL/Inspection/Detail/mutation'
import { GET_INSPECTION_BY_ID } from 'graphQL/Inspection/Detail/queries'
import { GET_INSPECTION_STATUSES } from 'graphQL/Inspection/Listing/queries'

import { ContentContainer, Layout } from 'styles/inspection/detail'

const InspectionDetailPage = () => {
  const { inspectionId } = useParams<{ inspectionId: string }>()
  const { text } = useTranslation(textFiles.INSPECTION_DETAIL)
  const { show } = useNotification()
  const history = useHistory()
  const { general: translation } = text
  const [tab, setTab] = useState<number>(0)
  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })

  const [inspectionData, setInspectionData] = useState<Inspection | null>(null)
  const [notes, setNotes] = useState<NoteType[]>([])
  const [updateDisabled, setUpdateDisabled] = useState<boolean>(false)
  const [status, setStatus] = useState<ExtendedStatus | null>(null)
  const [statusList, setStatusList] = useState<ExtendedStatus[]>([])

  const [apolloError, setApolloError] = useState<ApolloError | null>(null)

  const { loading: inspectionLoading } = useQuery<
    GenericData<Inspection>,
    GenericInputVariable<string>
  >(GET_INSPECTION_BY_ID, {
    variables: {
      input: inspectionId,
    },
    onError(error) {
      setApolloError(error)
    },
    onCompleted(response) {
      const { data } = response
      setInspectionData(data)
      setStatus(data.status)
      if (completedStatus.includes(data.status.slug)) setUpdateDisabled(true)
      setNotes(
        data.notes.map((note, index) => {
          return {
            index,
            text: note,
          }
        })
      )
    },
  })

  const { loading: statusLoading } = useQuery<ListStatus, FilterInputVariable>(
    GET_INSPECTION_STATUSES,
    {
      onCompleted(response) {
        setStatusList(response.getInspectionStatuses)
      },
      variables: {
        input: {
          where: {
            slug_in: [InspectionStatus.APPROVED],
          },
        },
      },
    }
  )

  const [updateInspection, { loading: submitLoading }] = useMutation<
    GenericData<Inspection>,
    GenericInputVariable<UpdateInspectionInput>
  >(UPDATE_INSPECTION, {
    onCompleted() {
      show({ updatedSeverity: 'success', message: translation.updateSuccess })
    },
    onError() {
      show({ updatedSeverity: 'error', message: translation.updateFail })
    },
  })

  const [scheduleInsnpection, { loading: scheduleLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<ScheduleInspectionInput>
  >(SCHEDULE_INSPECTION, {
    onCompleted() {
      show({ updatedSeverity: 'success', message: translation.updateSuccess })
    },
    onError() {
      show({ updatedSeverity: 'error', message: translation.updateFail })
    },
  })

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTab(value)
    history.replace(`#${initialItems[value].url}`)
  }

  const [approveInspection, { loading: approveInspectionLoading }] =
    useMutation<GenericData<BaseIdEntity>, GenericInputVariable<string>>(
      APPROVE_INSPECTION,
      {
        variables: {
          input: inspectionId,
        },
      }
    )

  const updateStatusMutations = {
    [InspectionSlugStatus.APPROVED as string]: approveInspection,
  }

  const handleNotesChange = async (newNotes: NoteType[]) => {
    const response = await updateInspection({
      variables: {
        input: {
          where: {
            id: inspectionId,
          },
          data: {
            inspection: {
              notes: newNotes.map((note) => note.text),
            },
          },
        },
      },
    })
    if (response.data) setNotes(newNotes)
  }

  const handleUpdateInspectionAppointment = async (
    appointment: UpdateInspectionAppointmentType
  ) => {
    const { address, longitude, latitude, hour, inspector, curboSpot, date } =
      appointment

    const response = await scheduleInsnpection({
      variables: {
        input: {
          where: {
            id: inspectionId,
          },
          data: {
            address,
            curboSpot,
            date,
            hour,
            inspector,
            latitude,
            longitude,
          },
        },
      },
    })
    if (response.errors) return false
    return true
  }

  const handleUpdateStatus = (newStatus: ExtendedStatus) => {
    const statusSlug = newStatus.slug

    if (statusSlug in updateStatusMutations) {
      updateStatusMutations[statusSlug]({
        onError() {
          show({ updatedSeverity: 'error', message: translation.updateFail })
        },
        onCompleted() {
          show({
            updatedSeverity: 'success',
            message: translation.updateSuccess,
          })
          setStatus(newStatus)
        },
      })
    }
  }

  const handleStatusChange = (newStatus: ExtendedStatus) => {
    if (newStatus !== status) {
      handleUpdateStatus(newStatus)
    }
  }

  useEffect(() => {
    if (history.location.hash) {
      let initialValue = 0
      const thisUrl = history.location.hash.split('#')[1]
      Object.keys(translation.tabs).forEach((key, index) => {
        if (key === thisUrl) {
          initialValue = index
        }
      })

      setTab(initialValue)
    }
  }, [history.location.hash, translation.tabs])

  const isUpdateLoading =
    scheduleLoading || submitLoading || approveInspectionLoading

  if (apolloError) return <Redirect push to={routes.NOT_FOUND_ERROR} />

  if (statusLoading) return <LoadingAnimation showAnimation={statusLoading} />

  return (
    inspectionData &&
    status && (
      <Layout>
        <DetailHeader
          editable={status.slug === InspectionSlugStatus.PATCHING_UP}
          title={`${translation.title} #${inspectionData.id}`}
          backButtonText={text.general.backButton}
          status={inspectionData.status}
          handleStatusChange={handleStatusChange}
          statusList={statusList}
          loading={isUpdateLoading}
        />
        <DetailSubHeader text={translation.description} />

        <DetailNavTab
          tab={tab}
          handleTabChange={handleTabChange}
          items={initialItems}
        />
        <ContentContainer>
          {inspectionLoading ? (
            <LoadingAnimation showAnimation={inspectionLoading} />
          ) : (
            <>
              <TabPanel value={tab} index={0}>
                <VehicleInformation
                  id={inspectionId}
                  notes={notes}
                  handleNotesChange={handleNotesChange}
                  expectedTimeData={inspectionData.sellMyCar}
                />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                {inspectionData.car.owner && (
                  <Client
                    owner={inspectionData.car.owner}
                    notes={notes}
                    handleNotesChange={handleNotesChange}
                  />
                )}
                {inspectionData.car.dealer && (
                  <DealerInformation
                    dealer={inspectionData.car.dealer}
                    notes={notes}
                    handleNotesChange={handleNotesChange}
                  />
                )}
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <Appointment
                  id={inspectionId}
                  updateInspectionAppointment={
                    handleUpdateInspectionAppointment
                  }
                  submitLoading={isUpdateLoading}
                  disabledUpdate={updateDisabled}
                />
              </TabPanel>
              <TabPanel value={tab} index={3}>
                <Report id={inspectionId} disabledUpdated={updateDisabled} />
              </TabPanel>
              {/* <TabPanel value={tab} index={4}>
                <History />
              </TabPanel> */}
            </>
          )}
        </ContentContainer>
      </Layout>
    )
  )
}

export default InspectionDetailPage
