import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import {
  IconButton,
  InputAdornment,
  styled,
  TextField as MUITextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material'

import { colors } from 'styles/theme'

const StyledTextField = styled(MUITextField)<MuiTextFieldProps>(
  ({ error }) => ({
    border: `1px solid ${error ? colors.lightRed : colors.lightGray}`,
    borderRadius: '10px',
    opacity: 1,
    '& div': {
      color: colors.placeholderGray,
    },
    '& div fieldset': {
      border: 'none',
    },
  })
)

/*
 * a Type for declaring an optional icon which is the JSX of the icons imported
 * from MUI, or other sources, as long as they are valid react Nodes
 */

export type CustomTextFieldProps = {
  icon?: React.ReactNode
  submitFunction?: () => void
} & MuiTextFieldProps

const TextField = ({
  icon,
  name,
  inputProps,
  submitFunction,
  ...props
}: CustomTextFieldProps) => {
  const handleSubmit = () => {
    if (submitFunction) {
      submitFunction()
    }
  }

  return (
    <StyledTextField
      {...props}
      color="info"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSubmit()
        }
      }}
      name={name}
      InputProps={{
        ...props.InputProps,
        ...(icon && {
          startAdornment: (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
        }),

        ...(submitFunction && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton type="submit" onClick={handleSubmit}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }),
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        ...inputProps,
        'data-cy': name ? `${name}-input` : undefined,
      }}
    />
  )
}

export default TextField
