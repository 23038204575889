import React from 'react'
import { Divider } from '@mui/material'

import Stepper, { StepperModel } from 'components/General/Stepper'

import { colors } from 'styles/theme'

import Title from '../Title'

import { StyledBody } from './style'

type ContainerWithHeaderProps = {
  title: string
  subtitle: string
  children?: React.ReactNode
  currentStep?: number
  stepperItems?: StepperModel[]
  additionalContainerStyles?: React.CSSProperties
}

const BodyContainerWithHeader = ({
  title,
  subtitle,
  children,
  currentStep,
  stepperItems,
  additionalContainerStyles,
}: ContainerWithHeaderProps) => {
  return (
    <StyledBody sx={{ ...additionalContainerStyles }}>
      <Title
        title={title}
        containerSx={{
          backgroundColor: colors.commonWhite,
        }}
        subtitle={subtitle}
        disableDivider
      />
      <Divider
        sx={{
          display: 'flex',
          flexDirection: 'row',
          borderColor: colors.gray,
          marginTop: '30px',
          width: '100%',
        }}
      />
      {stepperItems ? (
        <Stepper
          currentStep={currentStep || 0}
          stepperItems={stepperItems || []}
          topSx={{
            justifyContent: 'flex-start',
            alignItems: 'end',
          }}
        />
      ) : null}
      {children}
    </StyledBody>
  )
}

export default BodyContainerWithHeader
