import { gql } from '@apollo/client'

export const REPORT_ISSUE = gql`
  mutation createIssue($issueInput: CreateSupportInput!) {
    createSupport(input: $issueInput) {
      createdAt
      email
      id
      issue
      issueOrigin
    }
  }
`
