import { gql } from '@apollo/client'

export const GET_ABSENCE_REASONS = gql`
  query getAbsenceReasons {
    data: getAbsenceReasons {
      id
      name
    }
  }
`

export const GET_REJECTION_MOTIVES = gql`
  query getInspectionFailureReasons {
    data: getInspectionFailureReasons {
      id
      name
      weight
    }
  }
`
