import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import LockIcon from '@mui/icons-material/Lock'
import { Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Button from 'components/Common/Button'
import ErrorMessage from 'components/Common/ErrorMessage'
import TextField from 'components/Common/TextField'

import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import { ResetPasswordModel } from 'models/AuthModel'
import { ChangePasswordData } from 'models/services/user'

import { CHANGE_PASSWORD } from 'graphQL/User/mutations'

import { StyledForm, StyledTextfieldContainer } from 'styles/auth'

const ResetPassword = () => {
  const { token } = useParams<{ token: string }>()
  const history = useHistory()
  const { show } = useNotification()
  const {
    text: {
      settings: { resetPassword: translation },
    },
  } = useTranslation(textFiles.ACCOUNT)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const [changePassword, { loading }] = useMutation<ChangePasswordData>(
    CHANGE_PASSWORD,
    {
      onCompleted({ validateToken }) {
        if (validateToken) {
          history.push(routes.LOGIN)
          show({
            updatedSeverity: 'success',
            message: translation.resetSuccess,
          })
        } else
          show({
            updatedSeverity: 'error',
          })
      },
      onError(error) {
        const errorText: string =
          error.graphQLErrors && error.graphQLErrors.length > 0
            ? error.graphQLErrors[0].message
            : error.message
        console.error('Error', errorText)
        show({
          updatedSeverity: 'error',
        })
      },
    }
  )

  const validationSchema = yup.object({
    password: yup
      .string()
      .min(8, validationText.invalidPassword)
      .required(validationText.fieldRequired),
    confirmPassword: yup
      .string()
      .required(validationText.fieldRequired)
      .oneOf(
        [yup.ref('password'), null],
        validationText.invalidConfirmPassword
      ),
  })

  const formik = useFormik<ResetPasswordModel>({
    initialValues: {
      confirmPassword: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const { password } = values

      changePassword({
        variables: {
          newPasswordInput: {
            origin: 'web',
            password,
            token,
          },
        },
      })
    },
  })

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <Typography variant="h4">{translation.title}</Typography>
      <StyledTextfieldContainer>
        <TextField
          disabled={loading}
          icon={
            <LockIcon
              color={
                formik.touched.password && Boolean(formik.errors.password)
                  ? 'error'
                  : 'inherit'
              }
            />
          }
          fullWidth
          placeholder={translation.passwordPlaceholder}
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          sx={{ marginTop: '2rem' }}
          type="password"
        />
        {formik.touched.password && formik.errors.password ? (
          <ErrorMessage
            sx={{ alignSelf: 'flex-start', position: 'absolute' }}
            text={formik.errors.password}
          />
        ) : null}
      </StyledTextfieldContainer>
      <StyledTextfieldContainer>
        <TextField
          disabled={loading}
          icon={
            <LockIcon
              color={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
                  ? 'error'
                  : 'inherit'
              }
            />
          }
          fullWidth
          placeholder={translation.passwordConfirmationPlaceholder}
          name="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          sx={{ marginTop: '2rem' }}
          type="password"
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
          <ErrorMessage
            sx={{ alignSelf: 'flex-start', position: 'absolute' }}
            text={formik.errors.confirmPassword}
          />
        ) : null}
      </StyledTextfieldContainer>
      <Button
        disabled={loading}
        fullWidth
        size="large"
        type="submit"
        sx={{ marginTop: '2rem' }}
      >
        {translation.signInButton}
      </Button>
    </StyledForm>
  )
}

export default ResetPassword
