import { BrandColumnField } from 'constants/CarSettings/columnField'
import { SelectItem } from 'models/Select'
import { BrandFieldSelectTextType } from 'models/text/carSettingsListing'

export const createBrandFieldSelectItems = (
  text: BrandFieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.picture,
      value: BrandColumnField.PICTURE,
    },
    {
      label: text.id,
      value: BrandColumnField.ID,
    },
    {
      label: text.name,
      value: BrandColumnField.NAME,
    },
    {
      label: text.createdAt,
      value: BrandColumnField.CREATED_AT,
    },

    {
      label: text.status,
      value: BrandColumnField.STATUS,
    },
    {
      label: text.actions,
      value: BrandColumnField.ACTIONS,
    },
  ]
}

export const createBrandFieldSelectLabels = (
  text: BrandFieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    picture: text.picture,
    createdAt: text.createdAt,
    status: text.status,
    actions: text.actions,
    name: text.name,
  }
}

export const brandDefaultFields = [
  BrandColumnField.ID,
  BrandColumnField.PICTURE,
  BrandColumnField.NAME,
  BrandColumnField.CREATED_AT,
  BrandColumnField.STATUS,
  BrandColumnField.ACTIONS,
]
