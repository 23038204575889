import { styled } from '@mui/material'

import Box from 'components/Common/Box'
import Container from 'components/Common/Container'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors } from 'styles/theme'

export const StyledBrandImg = styled('img')({
  alignSelf: 'center',
  objectFit: 'contain',
  height: '100%',
  width: '100%',
})

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
})

export const GridBox = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '31% 34% 34% !important',
  columnGap: '15px',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '50% 50% !important',
    columnGap: '10px',
    rowGap: '0px',
  },
})

export const ImgContainer = styled(Container)({
  width: '100%',
  // height: 600, (height for vertical image carousel to be further implemented)
  padding: '0',
  paddingTop: '1rem !important',
})

export const StyledImg = styled('img')({
  objectFit: 'cover',
  height: '100%',
  width: '100%',
})

export const StyledBorder = styled(Box)({
  backgroundColor: colors.commonWhite,
  border: `1px solid ${colors.border}`,
  borderRadius: '10px',
  textAlign: 'left',
  padding: '18px',
  width: 'auto',
  margin: '16px 0px',

  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    marginTop: '0',
  },
})

export const StyledBox = styled(Box)({
  color: colors.black,
  backgroundColor: colors.background,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'flex-start',
  borderRadius: '10px',
  paddingLeft: '43px',
  height: '100%',
})

export const StyledButton = styled('label')({
  width: '36px',
  height: '37px',
  position: 'absolute',
  bottom: '5%',
  right: '5%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.background,
  borderRadius: '4px',
  cursor: 'pointer',

  '& svg': {
    color: colors.gray,
  },
})
