import React from 'react'
import NumberFormat from 'react-number-format'
import EmailIcon from '@mui/icons-material/Email'
import MapIcon from '@mui/icons-material/Map'
import PersonIcon from '@mui/icons-material/Person'
import PhoneIcon from '@mui/icons-material/Phone'

import Skeleton from 'components/CarSettings/Common/Creation/Summary/Skeleton'
import { StyledItem } from 'components/CarSettings/Common/Creation/Summary/style'
import SummaryItem from 'components/Common/SummaryItem'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Option } from 'models/Select'
import { UserCreationProps } from 'utils/User/creation'

const Summary = ({
  handleBack,
  userData,
  handleSubmit,
  submitLoading,
  areaOptions,
  roleOptions,
}: UserCreationProps) => {
  const { text } = useTranslation(textFiles.USER_MANAGEMENT_CREATION)
  const { summary } = text
  const { userInformation, userRoles } = userData

  const renderValue = (options: Option[], value: string) => {
    return options.find((option) => option.value === value)
  }

  return (
    <Skeleton
      previousFunction={handleBack}
      nextFunction={handleSubmit}
      title={summary.title}
      description={summary.description}
      submitLoading={submitLoading}
    >
      <div>
        <SummaryItem
          title={summary.fullName}
          text={`${userInformation.name} ${userInformation.lastName}`}
          icon={<PersonIcon />}
        />
        <SummaryItem
          title={summary.email}
          text={userInformation.email}
          icon={<EmailIcon />}
        />
        {userInformation.phoneNumber.length > 0 ? (
          <SummaryItem
            title={summary.userPhone}
            text={
              <NumberFormat
                value={userInformation.phoneNumber}
                format="+1 (###) ### ####"
                isNumericString
                displayType="text"
              />
            }
            icon={<PhoneIcon />}
          />
        ) : null}
        {userInformation.address.length > 0 ? (
          <SummaryItem
            title={summary.address}
            text={userInformation.address}
            icon={<MapIcon />}
          />
        ) : null}
      </div>
      <StyledItem title={summary.roles} maxHeight="238px !important">
        <ul style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {userRoles.roles.map((role) => (
            <li key={role.role}>
              <span>{renderValue(areaOptions, role.area)!.name}</span>
              {` - ${renderValue(roleOptions, role.role)!.name}`}
            </li>
          ))}
        </ul>
      </StyledItem>
    </Skeleton>
  )
}

export default Summary
