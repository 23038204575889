import React, { FunctionComponent, useState } from 'react'
import { useQuery } from '@apollo/client'
import { InputBaseComponentProps } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Accordion from 'components/Common/Accordion'
import Box from 'components/Common/Box'
import ColorBox from 'components/Common/ColorBox'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import EditContainer from 'components/Inventory/Common/EditContainer'
import FeatureSetCell from 'components/Inventory/Common/FeatureSetCell'
import InformationCell from 'components/Inventory/Common/InformationCell'
import NumberInput from 'components/Inventory/Common/NumberInput'
import SelectCell from 'components/Inventory/Common/SelectCell'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { GenericData } from 'models/services/base'
import {
  InventoryCar,
  InventoryColor,
  UpdateCarInput,
  VehicleInformationSelectOptions,
} from 'models/services/inventory/detail'
import { InventorySlugStatus } from 'models/status'

import { GET_COLORS } from 'graphQL/Inventory/Detail/queries'

import { StyledGrid } from 'styles/inventory/detail'
import { colors } from 'styles/theme'

type FormikModel = {
  chassisNumber: string
  vinNumber: string
  mileage: string
  licensePlate: string
  categories: string[]
  transmission: string
  fuelType: string
  bodyStyle: string
  driveTrain: string
  features: string[]
  exteriorColor: string
  interiorColor: string
}

const formatIntoFormikModel = (car: InventoryCar): FormikModel => {
  const {
    interiorColor,
    exteriorColor,
    categories,
    bodyStyle,
    driveTrain,
    features,
    fuelType,
    transmission,
  } = car

  return {
    licensePlate: car.licensePlate,
    mileage: String(car.mileage),
    chassisNumber: car.chassisNumber ? car.chassisNumber : '',
    vinNumber: car.vinNumber ? car.vinNumber : '',
    categories: categories.map((category) => category.value),
    bodyStyle: bodyStyle.value,
    driveTrain: driveTrain.value,
    features: features.map((feature) => feature.value),
    fuelType: fuelType.value,
    transmission: transmission.value,
    exteriorColor: exteriorColor.value,
    interiorColor: interiorColor.value,
  }
}

type VehicleInformationProps = {
  car: InventoryCar
  isLoading: boolean
  handleGetOptions: () => Promise<boolean>
  selectOptions: VehicleInformationSelectOptions | null
  handleUpdate: (update: UpdateCarInput) => Promise<boolean | InventoryCar>
  isEditable: boolean
}

const VehicleInformation = ({
  car,
  isLoading,
  handleGetOptions,
  selectOptions,
  handleUpdate,
  isEditable,
}: VehicleInformationProps) => {
  const [colorList, setColorList] = useState<InventoryColor[]>([])
  const [edit, setEdit] = useState<boolean>(false)

  const {
    brand,
    carModel,
    year,
    trimLevel,
    interiorColor,
    exteriorColor,
    countryVersion,
    categories,
    bodyStyle,
    driveTrain,
    features,
    fuelType,
    transmission,
    status,
  } = car
  const carName = `${brand.name} ${carModel.name} ${year} ${trimLevel.name}`
  const {
    text: { vehicleInformation: translation },
  } = useTranslation(textFiles.INVENTORY_DETAIL)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const pendingValidationSchema = yup.object().shape({
    vinNumber: yup
      .string()
      .length(17, validationText.errorMessageRequiredLength.replace('%d', 17)),
    chassisNumber: yup
      .string()
      .min(6, validationText.errorMessageAtleast.replace('%d', 6))
      .max(10, validationText.errorMessageMaxCharacters.replace('%d', 11))
      .when('vinNumber', {
        is: (vinNumber: string) => !vinNumber || vinNumber.length === 0,
        then: yup.string().required(translation.vinChassisValidation),
        otherwise: yup.string(),
      }),
    mileage: yup.string().required(validationText.fieldRequired),
    licensePlate: yup.string().required(validationText.fieldRequired),
  })

  const completedValidationSchema = pendingValidationSchema.shape({
    categories: yup
      .array()
      .of(yup.string())
      .min(1, validationText.errorRequiredItems.replace('%d', 1)),
    features: yup
      .array()
      .of(yup.string())
      .min(2, validationText.errorRequiredItems.replace('%d', 2)),
  })

  const formik = useFormik<FormikModel>({
    initialValues: formatIntoFormikModel(car),
    validationSchema:
      status.slug === InventorySlugStatus.PENDING
        ? pendingValidationSchema
        : completedValidationSchema,
    onSubmit: async (values) => {
      // only triggers mutation if form has changed
      if (formik.dirty) {
        const updateResponse = await handleUpdate({
          vehicleInformation: {
            ...values,
            mileage: parseFloat(values.mileage),
          },
        })
        // after submit, we need to get the new data and reset form to clean the dirty flag
        if (updateResponse) {
          if (typeof updateResponse !== 'boolean')
            formik.resetForm({ values: formatIntoFormikModel(updateResponse) })
          setEdit(false)
        }
      } else setEdit(false)
    },
  })

  const { loading: colorsLoading } = useQuery<GenericData<InventoryColor[]>>(
    GET_COLORS,
    {
      onCompleted(response) {
        setColorList(response.data)
      },
    }
  )

  const handleEdit = async () => {
    const getOptionsSuccesful = await handleGetOptions()
    if (getOptionsSuccesful) setEdit(true)
  }

  const handleCancelEdit = () => {
    formik.resetForm({ values: formatIntoFormikModel(car) })
    setEdit(false)
  }

  const handleSaveEdit = () => {
    formik.handleSubmit()
  }

  const handleCategoryRemove = (e: React.MouseEvent, index: number) => {
    e.preventDefault()
    const newCategories = [...formik.values.categories]
    newCategories.splice(index, 1)
    formik.setFieldValue('categories', newCategories)
  }

  const handleFeatureRemove = (e: React.MouseEvent, index: number) => {
    e.preventDefault()
    const newFeatures = [...formik.values.features]
    newFeatures.splice(index, 1)
    formik.setFieldValue('features', newFeatures)
  }

  if (colorsLoading) return <LoadingAnimation showAnimation={colorsLoading} />

  return (
    <Box
      minHeight="650px"
      sx={{
        opacity: isLoading ? '0.5' : 'unset',
      }}
    >
      <EditContainer
        edit={edit}
        handleCancelEdit={handleCancelEdit}
        handleSaveEdit={handleSaveEdit}
        handleEdit={handleEdit}
        loading={isLoading}
        isEditable={isEditable}
      />
      <Accordion
        defaultExpanded
        title={translation.generalInformation.label}
        description={translation.generalInformation.description}
        sx={{
          margin: '0px!important',
        }}
      >
        <StyledGrid>
          <InformationCell
            label={translation.generalInformation.makeLabel}
            value={brand.name}
          />
          <InformationCell
            label={translation.generalInformation.modelLabel}
            value={carModel.name}
          />
          <InformationCell
            label={translation.generalInformation.yearLabel}
            value={String(year)}
          />
          <InformationCell
            label={translation.generalInformation.trimLabel}
            value={trimLevel.name}
          />
          <InformationCell
            label={translation.generalInformation.nameLabel}
            value={carName}
          />
        </StyledGrid>
      </Accordion>
      <Accordion
        defaultExpanded
        title={translation.vehicleDetails.label}
        description={translation.vehicleDetails.description}
      >
        <StyledGrid>
          <InformationCell
            label={translation.vehicleDetails.mileageLabel}
            value={formik.values.mileage}
            endAdornment={translation.vehicleDetails.mileUnit}
            edit={edit}
            name="mileage"
            onChange={formik.handleChange}
            inputComponent={
              NumberInput as unknown as FunctionComponent<InputBaseComponentProps>
            }
            thousandSeparator
            error={formik.touched.mileage && Boolean(formik.errors.mileage)}
            errorText={formik.errors.mileage}
          />
          <SelectCell
            label={translation.vehicleDetails.interiorColorLabel}
            value={formik.values.interiorColor}
            options={colorList}
            defaultOption={interiorColor}
            name="interiorColor"
            onChange={formik.handleChange}
            edit={edit}
            startAdornment={
              <ColorBox
                height={22}
                width={22}
                styles={{
                  border: `1px solid ${colors.gray}`,
                  boxShadow: 'none',
                  marginRight: '0.5rem',
                }}
                hexCode={
                  colorList.length > 0
                    ? colorList.find(
                        (color) => color.value === formik.values.interiorColor
                      )!.hexCode
                    : interiorColor.hexCode
                }
              />
            }
          />
          <SelectCell
            label={translation.vehicleDetails.exteriorColorLabel}
            value={formik.values.exteriorColor}
            options={colorList}
            defaultOption={exteriorColor}
            name="exteriorColor"
            onChange={formik.handleChange}
            edit={edit}
            startAdornment={
              <ColorBox
                height={22}
                width={22}
                styles={{
                  border: `1px solid ${colors.gray}`,
                  boxShadow: 'none',
                  marginRight: '0.5rem',
                }}
                hexCode={
                  colorList.length > 0
                    ? colorList.find(
                        (color) => color.value === formik.values.exteriorColor
                      )!.hexCode
                    : exteriorColor.hexCode
                }
              />
            }
          />
          <InformationCell
            label={translation.vehicleDetails.chassisLabel}
            value={formik.values.chassisNumber}
            edit={edit}
            onChange={formik.handleChange}
            name="chassisNumber"
            error={
              formik.touched.chassisNumber &&
              Boolean(formik.errors.chassisNumber)
            }
            errorText={formik.errors.chassisNumber}
          />
          <InformationCell
            label={translation.vehicleDetails.vinLabel}
            value={formik.values.vinNumber}
            edit={edit}
            onChange={formik.handleChange}
            name="vinNumber"
            error={formik.touched.vinNumber && Boolean(formik.errors.vinNumber)}
            errorText={formik.errors.vinNumber}
          />

          <InformationCell
            label={translation.vehicleDetails.licensePlateLabel}
            value={formik.values.licensePlate}
            edit={edit}
            onChange={formik.handleChange}
            name="licensePlate"
            error={
              formik.touched.licensePlate && Boolean(formik.errors.licensePlate)
            }
            errorText={formik.errors.licensePlate}
          />
          <InformationCell
            label={translation.vehicleDetails.countryVersionLabel}
            value={countryVersion.name}
          />
        </StyledGrid>
        <Box marginBottom="20px" width="100%">
          <FeatureSetCell
            edit={edit}
            options={selectOptions ? selectOptions.categories : []}
            values={formik.values.categories}
            label={translation.vehicleDetails.categoriesLabel}
            onChange={formik.handleChange}
            onRemove={handleCategoryRemove}
            name="categories"
            defaultOptions={categories}
            error={
              formik.touched.categories && Boolean(formik.errors.categories)
            }
            errorText={
              typeof formik.errors.categories === 'string'
                ? formik.errors.categories
                : undefined
            }
            labelError={formik.values.categories.length === 0}
          />
        </Box>
      </Accordion>
      <Accordion
        defaultExpanded
        title={translation.features.label}
        description={translation.features.description}
      >
        <StyledGrid>
          <SelectCell
            label={translation.features.transmissionLabel}
            value={formik.values.transmission}
            options={selectOptions ? selectOptions.transmissions : []}
            defaultOption={transmission}
            edit={edit}
            onChange={formik.handleChange}
            name="transmission"
          />
          <SelectCell
            label={translation.features.fuelTypeLabel}
            value={formik.values.fuelType}
            options={selectOptions ? selectOptions.fuelTypes : []}
            defaultOption={fuelType}
            edit={edit}
            onChange={formik.handleChange}
            name="fuelType"
          />
          <SelectCell
            label={translation.features.bodyStyleLabel}
            value={formik.values.bodyStyle}
            options={selectOptions ? selectOptions.bodyStyles : []}
            defaultOption={bodyStyle}
            edit={edit}
            onChange={formik.handleChange}
            name="bodyStyle"
          />
          <SelectCell
            label={translation.features.driveTrainLabel}
            value={formik.values.driveTrain}
            options={selectOptions ? selectOptions.driveTrains : []}
            defaultOption={driveTrain}
            edit={edit}
            onChange={formik.handleChange}
            name="driveTrain"
          />
        </StyledGrid>
        <Box marginBottom="20px" width="100%">
          <FeatureSetCell
            edit={edit}
            options={selectOptions ? selectOptions.features : []}
            values={formik.values.features}
            label={translation.features.featuresSetLabel}
            onChange={formik.handleChange}
            onRemove={handleFeatureRemove}
            name="features"
            defaultOptions={features}
            error={formik.touched.features && Boolean(formik.errors.features)}
            errorText={
              typeof formik.errors.features === 'string'
                ? formik.errors.features
                : undefined
            }
            labelError={formik.values.features.length <= 1}
          />
        </Box>
      </Accordion>
      <Accordion defaultExpanded title={translation.spacing.label}>
        <StyledGrid>
          <InformationCell
            label={translation.spacing.frontLegLabel}
            value={car.frontHeadRoom}
            endAdornment="cm"
            thousandSeparator
          />
          <InformationCell
            label={translation.spacing.backLegLabel}
            value={car.backLegRoom}
            endAdornment="cm"
            thousandSeparator
          />
          <InformationCell
            label={translation.spacing.frontHeadLabel}
            value={car.frontHeadRoom}
            endAdornment="cm"
            thousandSeparator
          />
          <InformationCell
            label={translation.spacing.backHeadLabel}
            value={car.backHeadRoom}
            endAdornment="cm"
            thousandSeparator
          />
          <InformationCell
            label={translation.spacing.engineDisplacementLabel}
            value={car.engineDisplacement}
            endAdornment="m"
            thousandSeparator
          />
          <InformationCell
            label={translation.spacing.cargoCapacityLabel}
            value={car.cargoCapacity}
            endAdornment="m^3"
            thousandSeparator
          />
          <InformationCell
            label={translation.spacing.cargoWeightLabel}
            value={car.cargo}
            endAdornment="Kg"
            thousandSeparator
          />
        </StyledGrid>
      </Accordion>
      <Accordion defaultExpanded title={translation.carMeasurements.label}>
        <StyledGrid>
          <InformationCell
            label={translation.carMeasurements.lengthLabel}
            value={car.length}
            endAdornment="m"
            thousandSeparator
          />
          <InformationCell
            label={translation.carMeasurements.widthLabel}
            value={car.width}
            endAdornment="m"
            thousandSeparator
          />
          <InformationCell
            label={translation.carMeasurements.seatsLabel}
            value={car.seats}
          />
          <InformationCell
            label={translation.carMeasurements.doorsLabel}
            value={car.doors}
          />
        </StyledGrid>
      </Accordion>
      <Accordion defaultExpanded title={translation.mileagePower.label}>
        <StyledGrid>
          <InformationCell
            label={translation.mileagePower.mpgLabel}
            value={car.mpg}
            endAdornment="m"
            thousandSeparator
          />
          <InformationCell
            label={translation.mileagePower.cityMpgLabel}
            value={car.mpgCity}
            endAdornment="m"
            thousandSeparator
          />
          <InformationCell
            label={translation.mileagePower.highwayMpgLabel}
            value={car.mpgHgw}
            endAdornment="m"
            thousandSeparator
          />
          <InformationCell
            label={translation.mileagePower.fuelCapacityLabel}
            value={car.fuelCapacity}
            thousandSeparator
          />
          <InformationCell
            label={translation.mileagePower.cylindersLabel}
            value={car.cylinders}
            thousandSeparator
          />
          <InformationCell
            label={translation.mileagePower.torqueLabel}
            value={car.torque}
            thousandSeparator
          />
          <InformationCell
            label={translation.mileagePower.torqueRpmLabel}
            value={car.torqueRpm}
            thousandSeparator
          />
          <InformationCell
            label={translation.mileagePower.horsePowerLabel}
            value={car.horsePower}
            thousandSeparator
          />
          <InformationCell
            label={translation.mileagePower.horsePowerRpmLabel}
            value={car.horsePowerRpm}
            thousandSeparator
          />
        </StyledGrid>
      </Accordion>
    </Box>
  )
}

export default VehicleInformation
