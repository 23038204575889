import React from 'react'
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle'
import AppsIcon from '@mui/icons-material/Apps'
import BuildIcon from '@mui/icons-material/Build'
import CarRepairIcon from '@mui/icons-material/CarRepair'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import ErrorIcon from '@mui/icons-material/Error'
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList'
import FlagIcon from '@mui/icons-material/Flag'
import ListAltIcon from '@mui/icons-material/ListAlt'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import PaletteIcon from '@mui/icons-material/Palette'

import { CAR_SETTINGS_SUB_ROUTES, OPERATION_SUB_ROUTES } from 'constants/routes'
import { BodyStyle, TranslationBase } from 'models/car'
import { PublicationStatus } from 'models/status'

export const carMenuOptions = [
  {
    icon: <DirectionsCarIcon />,
    id: 1,
    translationKey: 'brands',
    url: CAR_SETTINGS_SUB_ROUTES.BRAND_LISTING,
  },
  {
    icon: <AirportShuttleIcon />,
    id: 2,
    translationKey: 'model',
    url: CAR_SETTINGS_SUB_ROUTES.MODEL_LISTING,
  },
  {
    icon: <AppsIcon />,
    id: 3,
    translationKey: 'trim',
    url: CAR_SETTINGS_SUB_ROUTES.TRIM_LISTING,
  },
  {
    icon: <FlagIcon />,
    id: 4,
    translationKey: 'country',
    url: CAR_SETTINGS_SUB_ROUTES.COUNTRY_VERSION_LISTING,
  },
  {
    icon: <ListAltIcon />,
    id: 5,
    translationKey: 'features',
    url: CAR_SETTINGS_SUB_ROUTES.FEATURE_LISTING,
  },
  {
    icon: <PaletteIcon />,
    id: 6,
    translationKey: 'colors',
    url: CAR_SETTINGS_SUB_ROUTES.COLOR_LISTING,
  },
  {
    icon: <AirportShuttleIcon />,
    id: 7,
    translationKey: 'body',
    url: CAR_SETTINGS_SUB_ROUTES.BODY_STYLE_LISTING,
  },
  {
    icon: <BuildIcon />,
    id: 8,
    translationKey: 'transmission',
    url: CAR_SETTINGS_SUB_ROUTES.TRANSMISSION_LISTING,
  },
  {
    icon: <FeaturedPlayListIcon />,
    id: 9,
    translationKey: 'category',
    url: CAR_SETTINGS_SUB_ROUTES.CATEGORY_LISTING,
  },
  {
    icon: <LocalGasStationIcon />,
    id: 10,
    translationKey: 'fuel',
    url: CAR_SETTINGS_SUB_ROUTES.FUEL_TYPE_LISTING,
  },
  {
    icon: <CarRepairIcon />,
    id: 11,
    translationKey: 'drive',
    url: CAR_SETTINGS_SUB_ROUTES.DRIVE_TRAIN_LISTING,
  },
  {
    icon: <ErrorIcon />,
    id: 12,
    translationKey: 'blackList',
    url: OPERATION_SUB_ROUTES.BLACK_LIST_LISTING,
  },
]

export const dummyData: TranslationBase = {
  name: 'Heated seats',
  status: PublicationStatus.UNPUBLISHED,
  translations: [
    {
      id: 1,
      name: 'Español',
      value: 'es',
      description: 'Asientos calientes',
    },
  ],
}

export const dummyBodyStyle: BodyStyle = {
  name: 'Coupe',
  status: PublicationStatus.UNPUBLISHED,
  picture: '',
  pictureFile: null,
  translations: [
    {
      id: 1,
      name: 'Español',
      value: 'es',
      description: 'En español',
    },
  ],
}
