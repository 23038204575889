import { Container, styled } from '@mui/material'
import { ResponsiveContainer } from 'recharts'

const StyledPieLabel = styled(Container)({
  textAlign: 'center',
  position: 'absolute',
  top: '42%',
  bottom: 0,
  left: 0,
  right: 0,
})

const ResponsiveStyledContainer = styled(ResponsiveContainer)({
  position: 'relative',
})
export { StyledPieLabel, ResponsiveStyledContainer }
