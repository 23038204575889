import React from 'react'
import ExpandMoresIcon from '@mui/icons-material/ExpandMore'
import {
  AccordionDetails,
  AccordionProps as MuiAccordionProps,
  AccordionSummary,
  Typography,
} from '@mui/material'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { StyledAccordion } from './style'

type AccordionProps = {
  title: string
  description?: string
  children?: React.ReactNode
  titleStyle?: React.CSSProperties
} & MuiAccordionProps

/**
 * Expansion panel customized to suit our styling
 */
const Accordion = ({
  title,
  description,
  children,
  titleStyle,
  ...props
}: AccordionProps) => {
  return (
    <StyledAccordion {...props}>
      <AccordionSummary expandIcon={<ExpandMoresIcon />}>
        <Typography
          color={colors.black}
          fontWeight={FONT_WEIGHT.BOLD}
          variant="h6"
          sx={titleStyle}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography color={colors.altBlack} variant="body2">
          {description}
        </Typography>
        {children}
      </AccordionDetails>
    </StyledAccordion>
  )
}

export default Accordion
