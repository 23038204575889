import React from 'react'
import SaveIcon from '@mui/icons-material/Save'
import { Box, SxProps } from '@mui/material'

import Button from 'components/Common/Button'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

type SaveButtonProps = {
  handleSaveChanges: () => void
  resetState: () => void
  submitLoading: boolean
  sx?: SxProps
  shouldRender: boolean
}

export const SaveChangesBlock = ({
  handleSaveChanges,
  resetState,
  submitLoading,
  shouldRender,
  sx,
}: SaveButtonProps) => {
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  return (
    <Box sx={{ height: '48px', paddingTop: '2rem', ...sx }}>
      {shouldRender ? (
        <>
          <Button
            startIcon={<SaveIcon />}
            buttonType="primary"
            onClick={handleSaveChanges}
            size="small"
            disabled={submitLoading}
          >
            {generalText.buttons.saveChanges}
          </Button>
          <Button variant="text" onClick={resetState} disabled={submitLoading}>
            {generalText.buttons.cancel}
          </Button>
        </>
      ) : null}
    </Box>
  )
}
