export enum TEMPLATE_CREATION_OPTION {
  UPDATE_IDENTITY = 'updateIdentity',
  UPDATE_STEP = 'updateStep',
}

export const buildStep = (id: string | number, reason: string) => {
  const stepInitialValue = {
    id,
    name: '',
    description: '',
    checkpoints: [
      {
        score: 80,
        title: '',
        standardizeAnswer: true,
        answers: [],
        reason,
        description: '',
        questions: [
          {
            title: '',
            isRequired: true,
            requiresPicture: false,
            score: 20,
            answers: [],
          },
          {
            title: '',
            isRequired: false,
            requiresPicture: false,
            score: 35,
            answers: [],
          },
          {
            title: '',
            isRequired: false,
            requiresPicture: false,
            score: 25,
            answers: [],
          },
        ],
      },
    ],
  }
  return stepInitialValue
}

export const buildCheckpoint = (reason: string) => {
  const defaultCheckpoint = {
    title: '',
    reason,
    description: '',
    standardizeAnswer: true,
    answers: [],
    questions: [
      {
        title: '',
        isRequired: false,
        requiresPicture: false,
        score: 5,
        answers: [],
      },
      {
        title: '',
        isRequired: false,
        requiresPicture: true,
        score: 15,
        answers: [],
      },
    ],
  }
  return defaultCheckpoint
}

export const defaultQuestion = {
  statement: '',
  isRequired: false,
  requiresPicture: true,
  score: 15,
  answers: [],
}

export const noProviderId = 'no-provider'
