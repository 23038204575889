export const BASE_API_URL = process.env.REACT_APP_BASE_APP_URL

export const GMAP_KEY = process.env.REACT_APP_GMAP_KEY
// Something to point out only users with roles: ADMIN/INSPECTOR/CUSTOMER_CAR/MARKETING/UPLOADER/SUPPORT can update stuff here
export const GRAPHQL_CLIENT_URI = `${BASE_API_URL}/graphql`

export const BACKEND_IMAGE_UPLOAD_ENDPOINT = `${BASE_API_URL}/upload/file`

export const BACKEND_IMAGES_UPLOAD_ENDPOINT = `${BASE_API_URL}/upload/pictures`

export const BACKEND_MAIN_PICTURE_UPLOAD_ENDPOINT = `${BASE_API_URL}/upload/mainPicture`

export const COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
