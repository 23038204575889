import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import {
  ClickAwayListener,
  Fade,
  Popper,
  Radio,
  Typography,
} from '@mui/material'

import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import Tag from 'components/Common/Tag'

import { cypressCancelButton, cypressSaveButton } from 'constants/cypress'
import { StatusStyles } from 'constants/status'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { PublicationStatus } from 'models/status'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { StyledDisclaimerText, StyledStatusContainer } from './style'

type StatusChangePopperProps = {
  status: PublicationStatus
  setStatus: (status: PublicationStatus) => void
  loading?: boolean
}

const StatusChangePopper = ({
  status,
  setStatus,
  loading,
}: StatusChangePopperProps) => {
  const { text } = useTranslation(textFiles.TEMPLATE_DETAIL)

  // manage status popper
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)

  const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen((prevState) => !prevState)
  }

  const [radioSelect, setRadioSelect] = useState<PublicationStatus>(status)

  const handleRadioChange = () => {
    setRadioSelect(
      radioSelect === PublicationStatus.UNPUBLISHED
        ? PublicationStatus.PUBLISHED
        : PublicationStatus.UNPUBLISHED
    )
  }

  const handleSave = () => {
    setStatus(radioSelect)
    setOpen(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  useEffect(() => {
    setRadioSelect(status)
  }, [status])

  return (
    <>
      <Box
        sx={{
          width: '42px',
          height: '37px',
          color: loading ? colors.black : StatusStyles[status].color,
          backgroundColor: loading
            ? colors.gray
            : StatusStyles[status].backgroundColor,
          border: `2px solid ${colors.commonWhite}`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onMouseEnter={handleMouseOver}
        onClick={!loading ? handlePopperOpen : undefined}
        data-cy="status-changer"
      >
        <EditIcon />
      </Box>
      <Popper
        id="status-popover"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        style={{
          zIndex: 1500,
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              setOpen(false)
              setRadioSelect(status)
            }}
          >
            <Fade {...TransitionProps} timeout={350}>
              <Box
                sx={{
                  backgroundColor: colors.commonWhite,
                  width: '500px',
                  height: '280px',
                  padding: '1.75em',
                  borderRadius: '4px',
                  boxShadow: `0px 3px 6px ${colors.shadow}`,
                }}
              >
                <Typography variant="body1" color={colors.blue}>
                  {text.modals.statusTitle}
                </Typography>

                <Box
                  sx={{
                    margin: '0.5em 0',
                    fontSize: '14px',
                    fontWeight: FONT_WEIGHT.REGULAR,
                    color: colors.black,
                  }}
                >
                  {text.modals.statusDescriptionFirstPart}{' '}
                  <b>{text.modals.statusDescriptionSecondPart}</b>{' '}
                  {text.modals.statusDescriptionThirdPart}{' '}
                  <b>{text.modals.statusDescriptionFourthPart}</b>{' '}
                  {text.modals.statusDescriptionFifthPart}
                </Box>
                <StyledStatusContainer>
                  <Radio
                    onChange={handleRadioChange}
                    checked={radioSelect === PublicationStatus.PUBLISHED}
                    sx={{
                      color: colors.blue,
                    }}
                    data-cy={`${PublicationStatus.PUBLISHED}-radio-button`}
                  />
                  <Tag status={PublicationStatus.PUBLISHED} />
                  <StyledDisclaimerText>
                    {text.modals.publishedDisclaimer}
                  </StyledDisclaimerText>
                </StyledStatusContainer>
                <StyledStatusContainer>
                  <Radio
                    onChange={handleRadioChange}
                    checked={radioSelect === PublicationStatus.UNPUBLISHED}
                    sx={{
                      color: colors.blue,
                    }}
                    data-cy={`${PublicationStatus.UNPUBLISHED}-radio-button`}
                  />
                  <Tag status={PublicationStatus.UNPUBLISHED} />
                  <StyledDisclaimerText>
                    {text.modals.draftDisclaimer}
                  </StyledDisclaimerText>
                </StyledStatusContainer>
                <Box
                  sx={{
                    margin: '2em 0 0.5em 0',
                    ' > button': {
                      height: '35px !important',
                      minWidth: '80px',
                    },
                  }}
                >
                  <Button
                    type="button"
                    buttonType="primary"
                    startIcon={<SaveIcon />}
                    onClick={handleSave}
                    sx={{ marginRight: '1em' }}
                    testId={cypressSaveButton}
                  >
                    {text.buttons.save}
                  </Button>
                  <Button
                    type="button"
                    buttonType="secondary"
                    onClick={handleCancel}
                    testId={cypressCancelButton}
                  >
                    {text.buttons.cancel}
                  </Button>
                </Box>
              </Box>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  )
}

export default StatusChangePopper
