import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import NotificationCreationPage from 'pages/Marketing/Notification/Creation'
import NotificationDetailPage from 'pages/Marketing/Notification/Detail'
import NotificationListingPage from 'pages/Marketing/Notification/Listing'
import OfferCreationPage from 'pages/Marketing/Offer/Creation'
import OfferDetailPage from 'pages/Marketing/Offer/Detail'
import OfferListingPage from 'pages/Marketing/Offer/Listing'

import { MARKETING_SUB_ROUTES } from 'constants/routes'

export const MarketingRouter = () => (
  <Switch>
    <Route
      exact
      path={MARKETING_SUB_ROUTES.OFFERS_CREATION}
      component={OfferCreationPage}
    />
    <Route
      exact
      path={MARKETING_SUB_ROUTES.OFFERS_DETAIL}
      component={OfferDetailPage}
    />
    <Route
      exact
      path={MARKETING_SUB_ROUTES.OFFERS_LISTING}
      component={OfferListingPage}
    />
    <Route
      exact
      path={MARKETING_SUB_ROUTES.NOTIFICATION_CREATION}
      component={NotificationCreationPage}
    />
    <Route
      exact
      path={MARKETING_SUB_ROUTES.NOTIFICATION_DETAIL}
      component={NotificationDetailPage}
    />
    <Route
      exact
      path={MARKETING_SUB_ROUTES.NOTIFICATION_LISTING}
      component={NotificationListingPage}
    />
    <Redirect to={MARKETING_SUB_ROUTES.OFFERS_LISTING} />
  </Switch>
)
