import { gql } from '@apollo/client'

export const GET_FEATURE_BY_ID = gql`
  query getFeatureById($input: ID!) {
    data: getFeatureById(id: $input) {
      nameTranslations {
        en
        es
      }
      name
      status
    }
  }
`
