import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { ApolloError, useQuery } from '@apollo/client'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { Typography } from '@mui/material'

import Box from 'components/Common/Box'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import Title from 'components/Common/Title'
import Stepper from 'components/General/Stepper'
import VehicleMotives from 'components/Motives/VehicleMotives'

import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Motive } from 'models/motives'
import { GenericData } from 'models/services/base'

import { GET_REJECTION_MOTIVES } from 'graphQL/Motives/queries'

import { colors } from 'styles/theme'

const RejectionMotivesPage = () => {
  const [rejectionMotives, setRejectionMotives] = useState<Motive[] | null>(
    null
  )
  const [apolloError, setApolloError] = useState<ApolloError | null>(null)

  const {
    text: { motives },
  } = useTranslation(textFiles.GENERAL)
  const { rejection } = motives

  const { loading: rejectionLoading } = useQuery<GenericData<Motive[]>>(
    GET_REJECTION_MOTIVES,
    {
      onCompleted(response) {
        setRejectionMotives(response.data)
      },
      onError(error) {
        setApolloError(error)
      },
    }
  )

  if (rejectionLoading)
    return <LoadingAnimation showAnimation={rejectionLoading} />

  if (apolloError) return <Redirect push to={routes.NOT_FOUND_ERROR} />

  return rejectionMotives ? (
    <>
      <Title
        title={rejection.title}
        icon={
          <PlaylistAddCheckIcon fontSize="medium" sx={{ color: colors.gray }} />
        }
        subtitle={`${rejectionMotives.length} ${rejection.description}`}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        sx={{ backgroundColor: colors.commonWhite }}
        marginTop="54px"
        padding="2rem 0"
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
        >
          <Typography variant="h3">{rejection.processTitle}</Typography>
          <Typography
            fontSize="18px"
            color={colors.subtitleGray}
            variant="body2"
          >
            {rejection.processDescription}
          </Typography>
          <Stepper
            currentStep={0}
            barWidth="778px"
            stepperItems={[
              {
                label: rejection.stepper.firstStep as string,
              },
            ]}
          />
          <VehicleMotives rejectionMotives={rejectionMotives} />
        </Box>
      </Box>
    </>
  ) : null
}

export default RejectionMotivesPage
