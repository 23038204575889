import React from 'react'
import { SelectChangeEvent, Typography } from '@mui/material'

import Box from 'components/Common/Box'
import Select from 'components/Common/Select'

import { Option } from 'models/Select'

type TimePickerProps = {
  translation: Record<string, string>
  hourOptions: Option[]
  meridiamOptions: Option[]
  // Necessary for it to run, but should cast the event target value when being used
  hourChange: (event: SelectChangeEvent<unknown>) => void
  hour: unknown
  meridiamChange: (event: SelectChangeEvent<unknown>) => void
  meridiam: unknown
  disabled?: boolean
}

const TimePicker = ({
  translation,
  hourOptions,
  meridiamOptions,
  hourChange,
  hour,
  meridiam,
  meridiamChange,
  disabled,
}: TimePickerProps) => {
  return (
    <Box marginBottom="1em">
      <Typography variant="body1">{translation.timeTitle}</Typography>
      <Typography variant="subtitle1" marginBottom="1em">
        {translation.timeSubtitle}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          '& > div:first-of-type': {
            flexGrow: '2',
            width: '65%',
            marginRight: '5%',
          },
          '& > div:nth-of-type(2)': {
            width: '30%',
          },
        }}
      >
        <Select
          options={hourOptions}
          fullWidth
          value={hour}
          onChange={hourChange}
          sx={{ fontSize: 12 }}
          label={translation.timeTitle}
          disabled={disabled}
          testId="hour-select"
        />
        <Select
          options={meridiamOptions}
          value={meridiam}
          onChange={meridiamChange}
          sx={{ fontSize: 12 }}
          disabled={disabled}
          testId="meridiam-select"
        />
      </Box>
    </Box>
  )
}

export default TimePicker
