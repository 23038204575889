import React from 'react'

import { boxShadow, colors } from 'styles/theme'

import Box from '../Box'

type ColorBoxProps = {
  /**
   * Hex code for the background of the box
   */
  hexCode: string
  /**
   * Height of the box
   * @default 36
   */
  height?: number | string
  /**
   * Width of the box
   * @default 36
   */
  width?: number | string

  /**
   * Additional styling done to the box
   */
  styles?: React.CSSProperties
}

const ColorBox = ({
  hexCode,
  height = 36,
  width = 36,
  styles,
}: ColorBoxProps) => {
  return (
    <Box
      height={height}
      width={width}
      sx={{
        borderRadius: '4px',
        background: hexCode,
        border: `1px solid ${colors.background}`,
        boxShadow,
        ...styles,
      }}
    />
  )
}

export default ColorBox
