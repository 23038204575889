export enum DateRangeTitleTabType {
  TODAY = 'today',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  ALL = 'all',
}

export enum DateRangeCalendarTabType {
  YESTERDAY = 'yesterday',
  LAST_SEVEN_DAYS = 'lastSevenDays',
  LAST_THIRTY_DAYS = 'lastThirtyDays',
  LAST_MONTH = 'lastMonth',
  CUSTOM = 'custom',
  ALL = 'all',
  TODAY = 'TODAY',
  MONTH = 'MONTH',
}

export enum WeeklyRangeCalendarTabType {
  THIS_WEEK = 'thisWeek',
  LAST_WEEK = 'lastWeek',
  NEXT_WEEK = 'nextWeek',
  CUSTOM = 'CUSTOM',
}

export type CalendarRangeType = { fromDate: Date | null; toDate: Date | null }
