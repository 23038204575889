import { styled } from '@mui/material'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors } from 'styles/theme'

export const StyledLayout = styled('div')({
  backgroundColor: colors.background,
  display: 'flex',
  height: '100%',
  width: '100%',
})

export const StyledLeftContainer = styled('div')({
  alignItems: 'center',
  backgroundColor: colors.commonWhite,
  display: 'flex',
  height: '100%',
  justifyContent: 'flex-start',
  left: 0,
  position: 'fixed',
  top: 0,
  width: '50%',
  zIndex: 1,
})

export const StyledRightContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'fixed',
  right: 0,
  top: 0,
  width: '50%',
  zIndex: 1,
})

export const StyledContent = styled('div')({
  padding: '10rem 0rem 0rem 4rem',
  height: '100%',
  width: '100%',
  [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
    padding: '10rem 0rem 0rem 6rem',
  },
})

export const StyledImageContainer = styled('div')({
  position: 'relative',
  marginTop: '2rem',

  '& > img': {
    position: 'absolute',
    maxWidth: '100%',
    left: '1rem',
    top: '2rem',
    [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
      left: '2rem',
    },
  },
})
