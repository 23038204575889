import React from 'react'
import { Typography } from '@mui/material'

import PieChart, {
  DataProps,
  getColors,
} from 'components/Common/Charts/PieChart'
import { pieChartColorType } from 'components/Inspector/Detail/CommissionsDetail'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { FONT_WEIGHT } from 'styles/theme'

import { StyledBox, StyledChartContainer } from './style'

type InspectorProgressChartType = {
  pieChartColor: pieChartColorType
  carsInspected: number
  carsInspectedObjective: number
  pieChartData: DataProps[]
  percentage: string
}
const InspectorProgressChart = ({
  pieChartColor,
  carsInspected,
  carsInspectedObjective,
  pieChartData,
  percentage,
}: InspectorProgressChartType) => {
  /** This returns an array of colors, as defined in the pieChart component.
   * Shades of blue, red and green
   * */
  const overallProgressTextColor = getColors(pieChartColor)
  const { text } = useTranslation(textFiles.INSPECTOR_DETAIL)
  return (
    <StyledChartContainer>
      <PieChart
        data={pieChartData}
        size={{ height: 100, width: 160 }}
        variant="small"
        color={pieChartColor}
        labelData={{
          percentage,
        }}
      />
      <StyledBox>
        <Typography variant="body1" fontSize={18}>
          {text.commissions.inspectorProgressChart.title}
        </Typography>
        <Typography variant="body2">
          {text.commissions.inspectorProgressChart.subtitle}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: FONT_WEIGHT.REGULAR,
            color: overallProgressTextColor[0],
          }}
        >
          {carsInspected}/{carsInspectedObjective}
        </Typography>
      </StyledBox>
    </StyledChartContainer>
  )
}

export default InspectorProgressChart
