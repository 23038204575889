import { IconButton, styled } from '@mui/material'

import { boxShadow, colors } from 'styles/theme'

export const StyledIconButton = styled(IconButton)({
  backgroundColor: colors.commonWhite,
  borderRadius: '4px',
  textAlign: 'left',
  height: '25px',
  width: '25px',
  cursor: 'pointer',
  boxShadow,
})
