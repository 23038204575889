import { StepperModel } from 'components/General/Stepper'
import Information from 'components/Operations/CurboSpot/Creation/Information'
import Location from 'components/Operations/CurboSpot/Creation/Location'
import Schedule from 'components/Operations/CurboSpot/Creation/Schedule'
import Summary from 'components/Operations/CurboSpot/Creation/Summary'

import { ScheduleResponse } from 'models/outOfSpot'
import {
  CityOption,
  SpotCreationAction,
  SpotCreationModel,
} from 'models/services/operations/curboSpot'
import { StepperTextType } from 'models/text/General'

export type SpotCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  spotData: SpotCreationModel
  updateSpotData: (action: SpotCreationAction) => void
  handleSubmit: () => void
  submitLoading: boolean
  cities: CityOption[]
}

export const CURBO_SPOT_CREATION_STEPS: Array<React.FC<SpotCreationProps>> = [
  Information,
  Location,
  Schedule,
  Summary,
]

export const createStepperItems = (
  text: Partial<StepperTextType>
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep as string,
    },
    { label: text.secondStep as string },
    { label: text.thirdStep as string },
    { label: text.fourthStep as string },
  ]

  return stepperItems
}

export const isScheduleEmpty = (schedule: ScheduleResponse) => {
  for (const value of Object.values(schedule)) {
    if (value.length > 0) {
      return true
    }
  }
  return false
}
