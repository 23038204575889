import { gql } from '@apollo/client'

export const GET_PRICE_CONFIG = gql`
  query getPriceConfig {
    data: getPriceConfig {
      warranty
      licensePlate
      repairCost
    }
  }
`
