import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DescriptionIcon from '@mui/icons-material/Description'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { Typography } from '@mui/material'
import { saveAs } from 'file-saver'
import { getInspectionReportPdf } from 'services/getPdf'

import Box from 'components/Common/Box'
import Container from 'components/Common/Container'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import DownloadButton from 'components/Inspection/Detail/DownloadButton'

import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { GenericData, GenericInputVariable } from 'models/services/base'
import {
  Checkpoint,
  InspectionReportInformation,
  Step,
} from 'models/services/inspection/detail'

import { GET_INSPECTION_REPORT_BY_ID } from 'graphQL/Inspection/Detail/queries'

import noDataSvg from 'images/no-data.svg'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors, FONT_WEIGHT } from 'styles/theme'

import PieChart from './PieChart'
import StepComponent from './Step'
import { ChartBox, LayoutContainer, LegendAndNotesContainer } from './styles'

const initialReportInformation: InspectionReportInformation = {
  comment: null,
  steps: null,
  template: null,
}

export type CheckpointWithScore = {
  score: [number, number]
} & Checkpoint

export type StepWithScore = {
  checkpointWithScore: CheckpointWithScore[]
  score: [number, number]
} & Step

type ReportProps = {
  id: string
  disabledUpdated: boolean
}

const Report = ({ id, disabledUpdated }: ReportProps) => {
  const { text } = useTranslation(textFiles.INSPECTION_DETAIL)
  const { report: translation } = text
  const { show } = useNotification()
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false)

  const [reportData, setReportData] = useState<InspectionReportInformation>(
    initialReportInformation
  )
  const { width: windowWidth } = useWindowDimensions()

  const isLayoutXL = windowWidth && windowWidth >= BREAK_POINTS.XL

  const [stepsData, setStepsData] = useState<StepWithScore[]>([])

  const { template, comment, steps } = reportData

  const { loading } = useQuery<
    GenericData<InspectionReportInformation>,
    GenericInputVariable<string>
  >(GET_INSPECTION_REPORT_BY_ID, {
    variables: {
      input: id,
    },
    onCompleted(response) {
      setReportData({ ...response.data })

      if (response.data.steps) {
        const allSteps: StepWithScore[] = response.data.steps.map((step) => {
          const checks: CheckpointWithScore[] = step.checkpoints.map(
            (checkpoint) => {
              return {
                ...checkpoint,
                score: checkpoint.questions.reduce(
                  (prevValue, currentQuestion) => {
                    if (currentQuestion.answer) {
                      if (currentQuestion.answer.doesntApply) return prevValue

                      if (!currentQuestion.answer.correctAnswer)
                        return [
                          prevValue[0],
                          prevValue[1] + currentQuestion.value,
                        ]
                    }
                    return [
                      prevValue[0] + currentQuestion.value,
                      prevValue[1] + currentQuestion.value,
                    ]
                  },
                  [0, 0]
                ),
              }
            }
          )
          return {
            ...step,
            checkpointWithScore: checks,
            score: checks.reduce(
              (prevValue, currentCheck) => {
                return [
                  prevValue[0] + currentCheck.score[0],
                  prevValue[1] + currentCheck.score[1],
                ]
              },
              [0, 0]
            ),
          }
        })
        setStepsData(allSteps)
      }
    },
    onError() {
      show({
        updatedSeverity: 'error',
      })
    },
  })

  const downloadReport = async () => {
    setDownloadLoading(true)
    try {
      const response = await getInspectionReportPdf(id)
      if (response && response.data) {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        saveAs(blob, `${text.general.title} #${id}.pdf`)
      }
    } catch {
      show({
        updatedSeverity: 'error',
        message: translation.downloadFail,
      })
    }
    setDownloadLoading(false)
  }

  if (loading) return <LoadingAnimation showAnimation={loading} />

  if (!template || !steps || !disabledUpdated)
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100%"
        width="100"
        justifyContent="center"
      >
        <img alt="no data" height="100px" width="100px" src={noDataSvg} />
        <Typography
          color={colors.blue}
          fontWeight={FONT_WEIGHT.BOLD}
          variant="h5"
        >
          {translation.notCompletedTitle}
        </Typography>
        <Typography
          color={colors.black}
          fontWeight={FONT_WEIGHT.REGULAR}
          variant="body1"
        >
          {translation.notCompletedDescription}
        </Typography>
      </Box>
    )

  return (
    <Box>
      <Container
        title={translation.template.title}
        description={translation.template.description}
        extraText={
          isLayoutXL ? undefined : (
            <DownloadButton
              downloadReport={downloadReport}
              downloadLoading={downloadLoading}
              text={translation.template.button}
            />
          )
        }
        sx={{
          padding: '1rem 2rem 1rem 0.5rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          {template && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flex: 2.5,
                marginRight: '1rem',
                border: `1px solid ${colors.border}`,
                borderRadius: '6px',
                padding: '0.25rem',
                overflow: 'hidden',
              }}
            >
              <PlaylistAddCheckIcon
                sx={{
                  color: colors.blue,
                  backgroundColor: colors.background,
                  borderRadius: '4px',
                  width: '32px',
                  height: '32px',
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  fontWeight: FONT_WEIGHT.BOLD,
                  color: colors.black,
                  margin: '0 0.5rem',
                }}
              >
                {`${translation.template.label}${template.id} -`}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {template.name}
              </Typography>
            </Box>
          )}

          {isLayoutXL ? (
            <DownloadButton
              downloadReport={downloadReport}
              downloadLoading={downloadLoading}
              text={translation.template.button}
            />
          ) : null}
        </Box>
      </Container>
      <ChartBox>
        <Container
          title={translation.evaluation.title}
          description={translation.evaluation.description}
          sx={{
            flex: '2.5',
            padding: '0.5rem',
          }}
        >
          <Box
            sx={{
              border: `1px solid ${colors.border}`,
              borderRadius: '6px',
              padding: '0.5rem',
              minWidth: '860px',
              overflow: 'hidden auto',
              height: '100%',
              display: 'flex',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '0.5rem',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: FONT_WEIGHT.BOLD,
                  color: colors.black,
                  '&::before': {
                    content: '"• "',
                    color: colors.blue,
                  },
                }}
              >
                {translation.evaluation.totalEvaluation}
              </Typography>
              <PieChart
                size={{ height: 280, width: 280 }}
                score={stepsData.reduce(
                  (prevValue, currentStep) => {
                    return [
                      prevValue[0] + currentStep.score[0],
                      prevValue[1] + currentStep.score[1],
                    ]
                  },
                  [0, 0]
                )}
                color={colors.blue}
                showScore
              />
            </Box>
            <Box
              sx={{
                flexGrow: 2,
                height: '430px',
                padding: '0rem 1rem',
                overflow: 'hidden auto',
              }}
            >
              {stepsData.map((step, index) => (
                <StepComponent
                  key={step.id}
                  index={index + 1}
                  title={step.label}
                  shouldOpen={index === 0}
                  checkpoints={step.checkpointWithScore}
                  score={step.score}
                />
              ))}
            </Box>
          </Box>
        </Container>
        <Container
          title={translation.indicators.title}
          description={translation.indicators.description}
          sx={{
            padding: '0.5rem',
          }}
        >
          <LegendAndNotesContainer>
            <LayoutContainer
              sx={{
                [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
                  marginBottom: '42px',
                  marginTop: '20px',
                },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: colors.black,
                  width: '95%',
                  marginLeft: 'auto',
                  fontWeight: FONT_WEIGHT.BOLD,
                }}
              >
                <EqualizerIcon
                  sx={{ color: colors.commonBlack, marginRight: '0.75rem' }}
                />
                {translation.graphsLegend}
              </Typography>

              <Typography
                color={colors.black}
                fontWeight={FONT_WEIGHT.MEDIUM}
                paddingLeft="45px"
                variant="subtitle1"
                marginTop="1rem"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CheckCircleIcon
                  sx={{ color: colors.green, marginRight: '0.75rem' }}
                />
                {translation.graphs.metCriteria}
              </Typography>

              <Typography
                color={colors.black}
                fontWeight={FONT_WEIGHT.MEDIUM}
                paddingLeft="45px"
                variant="subtitle1"
                marginTop="1rem"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <RemoveCircleIcon
                  sx={{ color: colors.yellow, marginRight: '0.75rem' }}
                />
                {translation.graphs.notApplies}
              </Typography>

              <Typography
                color={colors.black}
                fontWeight={FONT_WEIGHT.MEDIUM}
                paddingLeft="45px"
                variant="subtitle1"
                marginTop="1rem"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CancelIcon
                  sx={{ color: colors.red, marginRight: '0.75rem' }}
                />
                {translation.graphs.missedCriteria}
              </Typography>
            </LayoutContainer>

            <LayoutContainer>
              <Typography
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: colors.black,
                  width: '95%',
                  marginLeft: 'auto',
                  fontWeight: FONT_WEIGHT.BOLD,
                }}
              >
                <DescriptionIcon
                  sx={{ color: colors.commonBlack, marginRight: '0.75rem' }}
                />
                {translation.indicators.finalNotes}
              </Typography>

              <Typography
                color={colors.black}
                fontWeight={FONT_WEIGHT.MEDIUM}
                paddingLeft="45px"
                variant="subtitle1"
                marginTop="1rem"
                sx={{ height: '100px', overflowY: 'auto' }}
              >
                {comment || translation.indicators.noNotes}
              </Typography>
            </LayoutContainer>
          </LegendAndNotesContainer>
        </Container>
      </ChartBox>
    </Box>
  )
}

export default Report
