import DriveTrainInfo from 'components/CarSettings/DriveTrain/Creation/DriveTrainInfo'
import Summary from 'components/CarSettings/DriveTrain/Creation/Summary'
import { StepperModel } from 'components/General/Stepper'

import {
  DriveTrainCreationAction,
  DriveTrainCreationModel,
} from 'models/driveTrainCreation'
import { StepperTextType } from 'models/text/General'

export type DriveTrainCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  submitLoading: boolean
  driveTrainData: DriveTrainCreationModel
  updateDriveTrainData: (action: DriveTrainCreationAction) => void
}

export const INITIAL_DRIVE_TRAIN_CREATION_STEPS: Array<
  React.FC<DriveTrainCreationProps>
> = [DriveTrainInfo, Summary]

export const createDriveTrainStepperItems = (
  text: StepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep,
    },
    { label: text.secondStep },
  ]

  return stepperItems
}
