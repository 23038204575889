export enum BaseColumnField {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  ACTIONS = 'actions',
  PICTURE = 'picture',
}

export enum BrandColumnField {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  ACTIONS = 'actions',
  PICTURE = 'picture',
}

export enum ModelColumnField {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  ACTIONS = 'actions',
  PICTURE = 'picture',
  BRAND_NAME = 'brandName',
}

export enum ColorColumnField {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  ACTIONS = 'actions',
  COLOR = 'color',
  HEX_CODE = 'hexCode',
}

export enum TrimColumnField {
  ID = 'id',
  CAR_NAME = 'Car Name',
  NAME = 'name',
  YEAR = 'year',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
  ACTIONS = 'actions',
}

export enum BlackListColumnField {
  COMMENT = 'comment',
  ENTITY_TYPE = 'entityType',
  BRAND_NAME = 'brandName',
  CAR_NAME = 'carName',
  BLACK_LISTED_AT = 'blackListedAt',
}
