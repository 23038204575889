import { gql } from '@apollo/client'

export const GET_BRANDS = gql`
  query getBrands($input: FilterInput) {
    data: getBrands(input: $input) {
      value: id
      name
      icon
      models {
        value: id
        name
      }
    }
  }
`
