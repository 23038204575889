import React from 'react'

import { elementStyle as defaultStyle } from 'styles/creation'
import { colors } from 'styles/theme'

import { StyledElement, StyledItem } from './style'

type ItemProps = {
  title: string
  text: string | React.ReactNode
  icon: React.ReactNode
  color?: string
  width?: number | string
  elementStyle?: React.CSSProperties
}

const Item = ({
  title,
  text,
  icon,
  color = colors.placeholderGray,
  width = 315,
  elementStyle = defaultStyle,
}: ItemProps): JSX.Element => {
  return (
    <StyledItem title={title} width={width}>
      <StyledElement
        sx={{
          ...elementStyle,
          color,
        }}
      >
        {icon}
        {text}
      </StyledElement>
    </StyledItem>
  )
}

export default Item
