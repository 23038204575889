import React from 'react'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PersonIcon from '@mui/icons-material/Person'

import {
  InformationCard,
  InfoRowType,
} from 'components/Common/InformationCard/InformationCard'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { contentStyle, StyledContainer } from './style'

type PersonalInfoProps = {
  name: string
  address: string | null
  title: string
  description: string
  type: 'dealer' | 'owner'
}

export const PersonalContainer = ({
  name,
  address,
  title,
  description,
  type,
}: PersonalInfoProps) => {
  const { text } = useTranslation(textFiles.INSPECTION_DETAIL)

  const { provider: translation } = text

  const informationRows: InfoRowType[] = [
    {
      icon: type === 'owner' ? <PersonIcon /> : <DirectionsCarIcon />,
      title: name,
      subtitle: translation.personalName,
    },
    ...(address
      ? [
          {
            icon: <LocationOnIcon />,
            title: address,
            subtitle: translation.personalAddress,
          },
        ]
      : []),
  ]

  return (
    <StyledContainer
      title={title}
      description={description}
      contentStyle={contentStyle}
    >
      <InformationCard rows={informationRows} />
    </StyledContainer>
  )
}
