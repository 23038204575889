import React from 'react'
import { Typography } from '@mui/material'

import Autocomplete, { AutocompleteItem } from 'components/Common/Autocomplete'
import Box from 'components/Common/Box'
import ExtendedAvatar from 'components/Common/ExtendedAvatar'

import { Inspector } from 'models/services/inspector'

type InspectorPickerProps = {
  translation: Record<string, string>
  onChange: (value: AutocompleteItem) => void
  value: Inspector | null
  inspectors: Inspector[]
  disabled?: boolean
  loading?: boolean
}

const InspectorPicker = ({
  translation,
  onChange,
  value,
  inspectors,
  disabled,
  loading,
}: InspectorPickerProps) => {
  return (
    <Box marginBottom="1em">
      <Typography variant="body1">{translation.inspectorTitle}</Typography>
      <Typography variant="subtitle1" marginBottom="1em">
        {translation.inspectorSubtitle}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          '& > div:nth-of-type(2)': {
            flexGrow: '2',
            width: '80%',
            marginLeft: '5%',
          },
        }}
      >
        <ExtendedAvatar
          alt="InspectorPicker avatar"
          variant="rounded"
          height={44}
          width={44}
          image={value?.profilePicture}
          sx={{
            width: 'unset',
            height: 'unset',
            marginRight: '10px',
          }}
        />
        <Autocomplete
          options={inspectors}
          onChangeHandler={onChange}
          placeholder={
            !loading ? translation.inspectorTitle : translation.loading
          }
          value={value}
          disabled={disabled || loading}
          noOptionsText={translation.noInspectors}
          testId="inspectors-autocomplete"
        />
      </Box>
    </Box>
  )
}

export default InspectorPicker
