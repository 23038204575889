export enum UserManagementColumnField {
  PICTURE = 'picture',
  ID = 'id',
  NAME = 'name',
  DATE = 'date',
  EMAIL = 'email',
  PHONE = 'telephoneNumber',
  ADDRESS = 'address',
  ROLES = 'roles',
  ACTIONS = 'actions',
}
