import { gql } from '@apollo/client'

export const GET_INSPECTIONS_SUMMARY = gql`
  query getInspectionsSummary {
    getRegisteredTemplateCount
    getInspectorsCountByStatus {
      active
      inactive
    }
  }
`

export const GET_INSPECTIONS_PREVIOUS_CHANGES = gql`
  query getInspectionPreviousChanges($input: FilterByIsoDateRangeInput!) {
    getInspectionPreviousChanges(input: $input) {
      previousChanges {
        approved {
          percentage
          status
        }
        rejected {
          percentage
          status
        }
        total {
          percentage
          status
        }
      }
      total
    }
  }
`

export const GET_INSPECTION_STATUS_DISTRIBUTION = gql`
  query getInspectionStatusDistribution($input: FilterByIsoDateRangeInput!) {
    getInspectionStatusDistribution(input: $input) {
      data {
        count
        id
        label
        textColor
        percentage
      }
    }
  }
`

export const GET_INSPECTION_DISTRIBUTION_BASED_BY_SOURCE = gql`
  query getInspectionsDistributionBasedBySource(
    $input: FilterByIsoDateRangeInput!
  ) {
    getInspectionDistributionBySource(input: $input) {
      data {
        approved
        name: origin
        rejected
      }
    }
  }
`

export const GET_APPROVED_INSPECTIONS_COUNT_BY_YEAR = gql`
  query getApprovedInspectionsCountByYear {
    getApprovedInspectionsCountByYear {
      data {
        approved
        name: month
        rejected
      }
    }
  }
`

export const GET_COMPLETED_INSPECTIONS_COUNT_BY_INSPECTOR = gql`
  query getCompletedInspectionsCountByInspector(
    $input: PaginatedTimeRangeFilterInput!
  ) {
    getCompletedInspectionsCountByInspector(input: $input) {
      data {
        approved
        name
        rejected
      }
      count
    }
  }
`

export const GET_REJECTED_INSPECTIONS_DISTRIBUTION = gql`
  query getRejectedInspectionsDistribution(
    $input: PaginatedTimeRangeFilterInput!
  ) {
    getRejectedInspectionsDistribution(input: $input) {
      data {
        name: label
        percentage
      }
      count
    }
  }
`

export const GET_INSPECTIONS_COUNT_BY_INSPECTOR_AND_ORIGIN = gql`
  query getInspectionsCountByInspectorAndOrigin(
    $input: PaginatedTimeRangeFilterInput!
  ) {
    getInspectionsCountByInspectorAndOrigin(input: $input) {
      data {
        name
        curbo
        dealer
        fmc
        smc
      }
      count
    }
  }
`
