import { gql } from '@apollo/client'

export const GET_CITY_BY_ID = gql`
  query getCityById($input: ID!) {
    data: getCityById(id: $input) {
      id
      name
      status
      latitude
      longitude
      curboSpotCount
      dealerCount
      state {
        id
        name
      }
    }
  }
`
