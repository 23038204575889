import { styled } from '@mui/material'

export const SingleStepperBox = styled('div')({
  width: 'fit-content',
  div: {
    width: '100%',
  },
  '& > div': {
    justifyContent: 'left !important',
  },
})
