import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'

import Box from 'components/Common/Box'
import BarChart from 'components/Common/Charts/BarChart'
import Container from 'components/Common/Container'
import TimeFilter from 'components/Common/TimeFilter'
import TimeSensitivePopper from 'components/General/TimeSensitivePopper'
import Inbox from 'components/Inspection/Dashboard/Inbox'
import InspectionsBasedOnSourceGraph from 'components/Inspection/Dashboard/InspectionBasedOnSource'
import InspectorStatus from 'components/Inspection/Dashboard/InspectorStatus'
import OutstandingInspector from 'components/Inspection/Dashboard/OutstandingInspector'
import ProcessStatus from 'components/Inspection/Dashboard/ProcessStatus'
import {
  InspectionsChange,
  InspectionsTotal,
  SummaryInspections,
} from 'components/Inspection/Dashboard/Statistics'

import {
  completedInspectionsCountByInspectorRows,
  createInspectionApprovementStructure,
  createInspectionSingleBarStructure,
  dummyActivities,
  dummyInspectors,
  getTotalPages,
  inspectionsByInspectorAndOriginRows,
  inspectionsSourceStructure,
  rejectedInspectionsDistributionRows,
} from 'constants/dashboard'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { CalendarRangeType } from 'models/date'
import {
  FilterByDateRangeInput,
  FilterByRangeAndLimit,
} from 'models/services/base'
import {
  ApprovedInspectionByInspectorData,
  ApprovedInspectionByYearData,
  GraphApprovementType,
  GraphPercentageType,
  GraphSourceType,
  InspectionByOriginAndInspector,
  InspectionBySourceData,
  InspectionPreviousChanges,
  InspectionStatisticData,
  InspectionStatusDistributionData,
  InspectionSummary,
  RejectedInspectionsDistributionData,
  StatusDistributionData,
} from 'models/services/inspection/dashboard'
import { InspectionsStatisticsTextType } from 'models/text/Dashboard'
import { getIsoDate } from 'utils/date'

import {
  GET_APPROVED_INSPECTIONS_COUNT_BY_YEAR,
  GET_COMPLETED_INSPECTIONS_COUNT_BY_INSPECTOR,
  GET_INSPECTION_DISTRIBUTION_BASED_BY_SOURCE,
  GET_INSPECTION_STATUS_DISTRIBUTION,
  GET_INSPECTIONS_COUNT_BY_INSPECTOR_AND_ORIGIN,
  GET_INSPECTIONS_PREVIOUS_CHANGES,
  GET_INSPECTIONS_SUMMARY,
  GET_REJECTED_INSPECTIONS_DISTRIBUTION,
} from 'graphQL/Inspection/Dashboard/queries'

import { BREAK_POINTS } from 'styles/breakpoints'
import {
  StyledBottomChartsContainer,
  StyledDashboardBox,
  StyledInspectionChangeContainer,
  StyledInspectionsSummaryBox,
  StyledPagination,
  StyledResponsiveContainer,
  StyledSummaryInspectionsContainer,
  StyledTopBar,
} from 'styles/inspection/dashboard'
import { colors } from 'styles/theme'

const InspectionDashboardPage = () => {
  const { text } = useTranslation(textFiles.INSPECTION_DASHBOARD)
  const window = useWindowDimensions()
  const { statistics }: { statistics: InspectionsStatisticsTextType } =
    text.inspections
  const inspectionApprovementStructure = createInspectionApprovementStructure(
    text.inspections.barChartGraphs.inspectionApprovementText
  )
  const inspectionSingleBarStructure = createInspectionSingleBarStructure(
    text.inspections.barChartGraphs.inspectionSingleBarText
  )

  const [dateRange, setDateRange] = useState<CalendarRangeType>({
    fromDate: new Date(),
    toDate: new Date(),
  })

  const [
    inspectionsByInspectorAndOriginPage,
    setInspectionsByInspectorAndOriginPage,
  ] = useState<number>(1)

  const [
    inspectionsByInspectorAndOriginCount,
    setInspectionsByInspectorAndOriginCount,
  ] = useState<number>(0)

  const inspectionsByInspectorAndOriginPages = getTotalPages(
    inspectionsByInspectorAndOriginCount,
    inspectionsByInspectorAndOriginRows
  )

  const [
    rejectedInspectionsDistributionPage,
    setRejectedInspectionsDistributionPage,
  ] = useState<number>(1)

  const [
    rejectedInspectionsDistributionCount,
    setRejectedInspectionsDistributionCount,
  ] = useState<number>(0)

  const rejectedInspectionsDistributionPages = getTotalPages(
    rejectedInspectionsDistributionCount,
    rejectedInspectionsDistributionRows
  )

  const [
    completedInspectionsCountByInspectorPage,
    setCompletedInspectionsCountByInspectorPage,
  ] = useState<number>(1)

  const [
    completedInspectionsCountByInspectorCount,
    setCompletedInspectionsCountByInspectorCount,
  ] = useState<number>(0)

  const completedInspectionsCountByInspectorPages = getTotalPages(
    completedInspectionsCountByInspectorCount,
    completedInspectionsCountByInspectorRows
  )

  const handleChangeDateRange = (range: CalendarRangeType) => {
    setDateRange(range)
  }

  const [inspectionStatistic, setInspectionStatistic] =
    useState<InspectionPreviousChanges | null>(null)
  const [distributionBySource, setDistributionBySource] = useState<
    GraphApprovementType[]
  >([])
  const [approvedInspectionsByYear, setApprovedInspectionsByYear] = useState<
    GraphApprovementType[]
  >([])
  const [approvedInspectionsByInspector, setApprovedInspectionsByInspector] =
    useState<GraphApprovementType[]>([])
  const [inspectionStatusDistribution, setInspectionStatusDistribution] =
    useState<StatusDistributionData[]>([])
  const [vehicleRejectionDistribution, setVehicleRejectionDistribution] =
    useState<GraphPercentageType[]>([])
  const [inspectionsByInspectorAndOrigin, setInspectionsByInspectorAndOrigin] =
    useState<GraphSourceType[]>([])

  const { fromDate, toDate } = useMemo(() => {
    return {
      fromDate: getIsoDate(dateRange.fromDate),
      toDate: getIsoDate(dateRange.toDate || new Date()),
    }
  }, [dateRange])

  const handleChangeInspectionsByInspectorAndOriginPage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setInspectionsByInspectorAndOriginPage(newPage)
  }
  const handleChangeRejectionsPage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setRejectedInspectionsDistributionPage(newPage)
  }

  const handleChangeCompletedInspectionsPage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCompletedInspectionsCountByInspectorPage(newPage)
  }

  const { data: summaryData } = useQuery<InspectionSummary>(
    GET_INSPECTIONS_SUMMARY
  )

  const { data: inspectionStatisticData } = useQuery<
    InspectionStatisticData,
    FilterByDateRangeInput
  >(GET_INSPECTIONS_PREVIOUS_CHANGES, {
    variables: {
      input: {
        from: fromDate,
        to: toDate,
      },
    },
  })

  const { loading: statusDistributionLoading } = useQuery<
    InspectionStatusDistributionData,
    FilterByDateRangeInput
  >(GET_INSPECTION_STATUS_DISTRIBUTION, {
    variables: {
      input: {
        from: fromDate,
        to: toDate,
      },
    },
    onCompleted(statusDistributionData) {
      setInspectionStatusDistribution(
        statusDistributionData.getInspectionStatusDistribution.data
      )
    },
  })

  const { loading: distributionBySourceLoading } = useQuery<
    InspectionBySourceData,
    FilterByDateRangeInput
  >(GET_INSPECTION_DISTRIBUTION_BASED_BY_SOURCE, {
    variables: {
      input: {
        from: fromDate,
        to: toDate,
      },
    },
    onCompleted(distributionBySourceData) {
      setDistributionBySource(
        distributionBySourceData.getInspectionDistributionBySource.data
      )
    },
  })

  const { loading: approvedInspectionByDataLoading } =
    useQuery<ApprovedInspectionByYearData>(
      GET_APPROVED_INSPECTIONS_COUNT_BY_YEAR,
      {
        onCompleted(approvedInspectionsByYearData) {
          setApprovedInspectionsByYear(
            approvedInspectionsByYearData.getApprovedInspectionsCountByYear.data
          )
        },
      }
    )

  const { loading: approvedInspectionsByInspectorLoading } = useQuery<
    ApprovedInspectionByInspectorData,
    FilterByRangeAndLimit
  >(GET_COMPLETED_INSPECTIONS_COUNT_BY_INSPECTOR, {
    variables: {
      input: {
        limit: completedInspectionsCountByInspectorRows,
        start:
          (completedInspectionsCountByInspectorPage - 1) *
          completedInspectionsCountByInspectorRows,
        from: fromDate,
        to: toDate,
      },
    },
    onCompleted(approvedInspectionsByInspectorData) {
      setApprovedInspectionsByInspector(
        approvedInspectionsByInspectorData
          .getCompletedInspectionsCountByInspector.data
      )
      setCompletedInspectionsCountByInspectorCount(
        approvedInspectionsByInspectorData
          .getCompletedInspectionsCountByInspector.count
      )
    },
  })

  const { loading: vehicleRejectionLoading } = useQuery<
    RejectedInspectionsDistributionData,
    FilterByRangeAndLimit
  >(GET_REJECTED_INSPECTIONS_DISTRIBUTION, {
    variables: {
      input: {
        limit: rejectedInspectionsDistributionRows,
        start:
          (rejectedInspectionsDistributionPage - 1) *
          rejectedInspectionsDistributionRows,
        from: fromDate,
        to: toDate,
      },
    },
    onCompleted(vehicleRejectionData) {
      setVehicleRejectionDistribution(
        vehicleRejectionData.getRejectedInspectionsDistribution.data
      )
      setRejectedInspectionsDistributionCount(
        vehicleRejectionData.getRejectedInspectionsDistribution.count
      )
    },
  })

  const { loading: inspectionByInspectorAndOriginLoading } = useQuery<
    InspectionByOriginAndInspector,
    FilterByRangeAndLimit
  >(GET_INSPECTIONS_COUNT_BY_INSPECTOR_AND_ORIGIN, {
    variables: {
      input: {
        limit: inspectionsByInspectorAndOriginRows,
        start:
          (inspectionsByInspectorAndOriginPage - 1) *
          inspectionsByInspectorAndOriginRows,
        from: fromDate,
        to: toDate,
      },
    },
    onCompleted(inspectionsByInspectorAndOriginData) {
      setInspectionsByInspectorAndOrigin(
        inspectionsByInspectorAndOriginData
          .getInspectionsCountByInspectorAndOrigin.data
      )
      setInspectionsByInspectorAndOriginCount(
        inspectionsByInspectorAndOriginData
          .getInspectionsCountByInspectorAndOrigin.count
      )
    },
  })

  useEffect(() => {
    if (inspectionStatisticData) {
      setInspectionStatistic(
        inspectionStatisticData.getInspectionPreviousChanges
      )
    }
  }, [inspectionStatisticData])

  return (
    <>
      <StyledDashboardBox>
        <TimeFilter
          title={text.title}
          handleChangeDateRange={handleChangeDateRange}
          dateRange={dateRange}
        />

        {summaryData && inspectionStatistic ? (
          <StyledResponsiveContainer>
            <StyledTopBar>
              <Container
                sx={{
                  width: '200px',
                  padding: '1rem',
                  height: '190px',
                }}
                extraText={<TimeSensitivePopper />}
                title={statistics.total}
              >
                <InspectionsTotal
                  color={colors.blue}
                  value={inspectionStatistic.total}
                />
              </Container>
              <StyledInspectionChangeContainer
                extraText={<TimeSensitivePopper />}
                title={statistics.inspectionsPrev}
              >
                <InspectionsChange {...inspectionStatistic.previousChanges} />
              </StyledInspectionChangeContainer>

              <OutstandingInspector
              // name="Carissa Burns"
              // inspections={13}
              // uploadings={14}
              />

              <StyledSummaryInspectionsContainer title="">
                <SummaryInspections
                  active={summaryData.getInspectorsCountByStatus.active}
                  activeLabel={statistics.active}
                  inactive={summaryData.getInspectorsCountByStatus.inactive}
                  inactiveLabel={statistics.inactive}
                  registeredLabel={statistics.registered}
                  registered={summaryData.getRegisteredTemplateCount}
                />
              </StyledSummaryInspectionsContainer>
            </StyledTopBar>

            <StyledInspectionsSummaryBox>
              <Container
                contentStyle={{
                  overflowY: 'auto',
                }}
                description={text.inspections.processStatus.description}
                extraText={<TimeSensitivePopper />}
                sx={{
                  height: 437,
                  width: '44%',
                }}
                title={text.inspections.processStatus.title}
                loading={statusDistributionLoading}
                isEmpty={!inspectionStatusDistribution.length}
              >
                <ProcessStatus processStatuses={inspectionStatusDistribution} />
              </Container>
              <InspectorStatus inspectorStatuses={dummyInspectors} />
              {typeof window.width !== 'undefined' &&
              window.width < BREAK_POINTS.XXL ? (
                <Inbox activities={dummyActivities} />
              ) : null}
            </StyledInspectionsSummaryBox>
          </StyledResponsiveContainer>
        ) : null}

        {typeof window.width !== 'undefined' &&
        window.width >= BREAK_POINTS.XXL ? (
          <Box display="flex" justifyContent="space-between" marginTop="1.5rem">
            <Inbox activities={dummyActivities} />
            <InspectionsBasedOnSourceGraph
              data={distributionBySource}
              structure={inspectionApprovementStructure}
              loading={distributionBySourceLoading}
            />
          </Box>
        ) : null}

        <Box marginTop="1.5rem">
          <Container
            description={
              text.inspections.barChartGraphs.inspectedPerMonth.description
            }
            title={text.inspections.barChartGraphs.inspectedPerMonth.title}
            loading={approvedInspectionByDataLoading}
            isEmpty={!approvedInspectionsByYear.length}
            height={670}
          >
            <BarChart
              data={approvedInspectionsByYear}
              barsStructure={inspectionApprovementStructure}
              layout="vertical"
              size={{ width: '98%', height: 606 }}
              hasLegend
            />
          </Container>
        </Box>
        <StyledBottomChartsContainer>
          <Container
            sx={{
              height: 634,
              width: '44%',
            }}
            description={
              text.inspections.barChartGraphs.vehicleRejection.description
            }
            title={text.inspections.barChartGraphs.vehicleRejection.title}
            marginTop="1rem"
            extraText={<TimeSensitivePopper />}
            loading={vehicleRejectionLoading}
            isEmpty={!vehicleRejectionDistribution.length}
          >
            <BarChart
              data={vehicleRejectionDistribution}
              barsStructure={inspectionSingleBarStructure}
              layout="horizontal"
              size={{ width: '96%', height: 500 }}
              hasPercentage
            />
            {/**
             * Hiding the pagination in case the graphic has just 1 page.
             */}
            {rejectedInspectionsDistributionPages === 1 ? null : (
              <StyledPagination
                count={rejectedInspectionsDistributionPages}
                onChange={handleChangeRejectionsPage}
                page={rejectedInspectionsDistributionPage}
                variant="text"
                size="small"
                shape="rounded"
                data-cy="vehicle-rejection-pagination"
              />
            )}
          </Container>
          <Container
            sx={{
              height: 634,
              width: '44%',
              order: 4,

              [`@media only screen and (min-width: ${BREAK_POINTS.XXL}px)`]: {
                order: 0,
              },
            }}
            description={
              text.inspections.barChartGraphs.basedOnTechnician.description
            }
            title={text.inspections.barChartGraphs.basedOnTechnician.title}
            marginTop="1rem"
            extraText={<TimeSensitivePopper />}
            loading={approvedInspectionsByInspectorLoading}
            isEmpty={!approvedInspectionsByInspector.length}
          >
            <BarChart
              data={approvedInspectionsByInspector}
              barsStructure={inspectionApprovementStructure}
              layout="horizontal"
              size={{ width: '96%', height: 500 }}
              hasLegend
            />

            {completedInspectionsCountByInspectorPages === 1 ? null : (
              <StyledPagination
                count={completedInspectionsCountByInspectorPages}
                onChange={handleChangeCompletedInspectionsPage}
                page={completedInspectionsCountByInspectorPage}
                variant="text"
                size="small"
                shape="rounded"
                data-cy="inspections-based-on-technician-pagination"
              />
            )}
          </Container>

          <Container
            sx={{
              width: '44%',
              height: 634,
            }}
            description={
              text.inspections.barChartGraphs.basedOnTechnicianAndSource
                .description
            }
            title={
              text.inspections.barChartGraphs.basedOnTechnicianAndSource.title
            }
            marginTop="1rem"
            extraText={<TimeSensitivePopper />}
            loading={inspectionByInspectorAndOriginLoading}
            isEmpty={!inspectionsByInspectorAndOrigin.length}
          >
            <BarChart
              data={inspectionsByInspectorAndOrigin}
              barsStructure={inspectionsSourceStructure}
              layout="horizontal"
              size={{ width: '98%', height: 500 }}
              hasLegend
            />
            {inspectionsByInspectorAndOriginPages === 1 ? null : (
              <StyledPagination
                count={inspectionsByInspectorAndOriginPages}
                onChange={handleChangeInspectionsByInspectorAndOriginPage}
                page={inspectionsByInspectorAndOriginPage}
                variant="text"
                size="medium"
                shape="rounded"
                siblingCount={0}
                data-cy="inspections-based-on-technician-and-source-pagination"
              />
            )}
          </Container>
          {typeof window.width !== 'undefined' &&
          window.width < BREAK_POINTS.XXL ? (
            <InspectionsBasedOnSourceGraph
              data={distributionBySource}
              structure={inspectionApprovementStructure}
              loading={distributionBySourceLoading}
              containerHeight={634}
              height={560}
              width="98%"
            />
          ) : null}
        </StyledBottomChartsContainer>
      </StyledDashboardBox>
    </>
  )
}

export default InspectionDashboardPage
