import React from 'react'
import {
  Dashboard,
  FormatListBulleted,
  ManageAccounts,
  MenuBook,
} from '@mui/icons-material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import MapIcon from '@mui/icons-material/Map'
import NotificationsIcon from '@mui/icons-material/Notifications'
import SettingsIcon from '@mui/icons-material/Settings'

import { SidebarItem } from 'components/General/Sidebar'

import { UserRoles } from 'models/role'
import { SidebarMenuTextType } from 'models/text/General'

import { colors } from 'styles/theme'

import { pendingHash } from './inspection'
import {
  BLACK_LIST_SUB_ROUTES,
  CAR_SETTINGS_SUB_ROUTES,
  CITY_SUB_ROUTES,
  CURBO_SPOT_SUB_ROUTES,
  DEALER_SUB_ROUTES,
  GENERAL_SETTINGS_SUB_ROUTES,
  INSPECTION_SUB_ROUTES,
  INSPECTOR_SUB_ROUTES,
  INVENTORY_SUB_ROUTES,
  MARKETING_SUB_ROUTES,
  OUT_OF_SPOT,
  STATE_SUB_ROUTES,
  TEMPLATE_SUB_ROUTES,
} from './routes'

export const createSideBarItems = (
  text: SidebarMenuTextType
): SidebarItem[] => {
  const { inspections, operations, generalSettings, inventory, marketing } =
    text
  return [
    {
      name: inspections.title.toUpperCase(),
      key: 'inspection-module',
      allowedRoles: [
        UserRoles.ADMIN,
        UserRoles.INSPECTOR_SUPERVISOR,
        UserRoles.INSPECTOR_MANAGER,
      ],
      hideInProduction: false,
      children: [
        {
          icon: <Dashboard fontSize="small" />,
          name: inspections.dashboard,
          key: 'inspection-dashboard',
          url: INSPECTION_SUB_ROUTES.INSPECTION_DASHBOARD,
          allowedRoles: [UserRoles.ADMIN, UserRoles.INSPECTOR_SUPERVISOR],
        },
        {
          icon: <FormatListBulleted fontSize="small" />,
          name: inspections.title,
          key: 'inspection-1',
          children: [
            {
              name: inspections.listing,
              key: 'inspection-listing-registered',
              url: INSPECTION_SUB_ROUTES.INSPECTION_LISTING,
            },
            {
              name: inspections.incoming,
              key: 'ispection-listing-pending',
              url: `${INSPECTION_SUB_ROUTES.INSPECTION_LISTING}${pendingHash}`,
            },
            {
              name: inspections.create,
              key: 'inspection-creation',
              url: INSPECTION_SUB_ROUTES.INSPECTION_CREATION,
            },
          ],
        },
        {
          icon: <ManageAccounts fontSize="small" />,
          name: inspections.inspectors.title,
          key: 'inspector-1',
          allowedRoles: [UserRoles.ADMIN, UserRoles.INSPECTOR_SUPERVISOR],
          children: [
            {
              name: inspections.inspectors.inspectorsListing,
              key: 'inspector-listing',
              url: INSPECTOR_SUB_ROUTES.INSPECTOR_DASHBOARD,
            },
            {
              name: inspections.inspectors.calendar,
              key: 'inspector-calendar',
              url: INSPECTOR_SUB_ROUTES.INSPECTOR_CALENDAR,
            },
          ],
        },
        {
          icon: <MenuBook fontSize="small" />,
          name: inspections.templates,
          key: 'template-1',
          url: TEMPLATE_SUB_ROUTES.TEMPLATE_LISTING,
          allowedRoles: [UserRoles.ADMIN, UserRoles.INSPECTOR_SUPERVISOR],
        },
      ],
    },
    {
      name: operations.title.toUpperCase(),
      key: 'operation-module',
      allowedRoles: [
        UserRoles.ADMIN,
        UserRoles.OPERATION_SPECIALIST,
        UserRoles.OPERATION_SUPERVISOR,
      ],
      children: [
        {
          icon: <Dashboard fontSize="small" />,
          name: operations.dashboard,
          key: 'operation-dashboard',
          hideInProduction: true,
        },
        {
          icon: <ManageAccounts fontSize="small" />,
          name: operations.dealers,
          key: 'dealers-listing',
          url: DEALER_SUB_ROUTES.DEALER_LISTING,
        },
        {
          icon: <MenuBook fontSize="small" />,
          name: operations.spots,
          key: 'spots-listing',
          url: CURBO_SPOT_SUB_ROUTES.CURBO_SPOT_LISTING,
        },
        {
          icon: <SettingsIcon fontSize="small" />,
          name: operations.outOfSpot,
          key: 'out-out-spot-1',
          url: OUT_OF_SPOT,
        },
        {
          icon: <MapIcon fontSize="small" />,
          name: operations.states,
          key: 'state-listing',
          url: STATE_SUB_ROUTES.STATE_LISTING,
        },
        {
          icon: <LocationCityIcon fontSize="small" />,
          name: operations.cities,
          key: 'city-listing',
          url: CITY_SUB_ROUTES.CITY_LISTING,
        },
        {
          icon: (
            <SettingsIcon fontSize="small" sx={{ color: colors.altBlack }} />
          ),
          name: operations.carSettings.title,
          key: 'car-settings-1',

          children: [
            {
              name: operations.carSettings.menu,
              key: 'car-menu',
              url: CAR_SETTINGS_SUB_ROUTES.CAR_SETTINGS_MENU,
            },
            {
              name: operations.carSettings.brands,
              key: 'car-brand-listing',
              url: CAR_SETTINGS_SUB_ROUTES.BRAND_LISTING,
            },
            {
              name: operations.carSettings.models,
              key: 'car-model-listing',
              url: CAR_SETTINGS_SUB_ROUTES.MODEL_LISTING,
            },
            {
              name: operations.carSettings.version,
              key: 'car-trim-listing',
              url: CAR_SETTINGS_SUB_ROUTES.TRIM_LISTING,
            },
            {
              name: operations.carSettings.country,
              key: 'car-country-listing',
              url: CAR_SETTINGS_SUB_ROUTES.COUNTRY_VERSION_LISTING,
            },
            {
              name: operations.carSettings.features,
              key: 'car-feature-listing',
              url: CAR_SETTINGS_SUB_ROUTES.FEATURE_LISTING,
            },
            {
              name: operations.carSettings.colors,
              key: 'car-color-listing',
              url: CAR_SETTINGS_SUB_ROUTES.COLOR_LISTING,
            },
            {
              name: operations.carSettings.body,
              key: 'car-body-style-listing',
              url: CAR_SETTINGS_SUB_ROUTES.BODY_STYLE_LISTING,
            },
            {
              name: operations.carSettings.transmission,
              key: 'car-transmission-listing',
              url: CAR_SETTINGS_SUB_ROUTES.TRANSMISSION_LISTING,
            },
            {
              name: operations.carSettings.category,
              key: 'car-category-listing',
              url: CAR_SETTINGS_SUB_ROUTES.CATEGORY_LISTING,
            },
            {
              name: operations.carSettings.fuelType,
              key: 'car-fuel-type-listing',
              url: CAR_SETTINGS_SUB_ROUTES.FUEL_TYPE_LISTING,
            },
            {
              name: operations.carSettings.drive,
              key: 'car-drive-train-listing',
              url: CAR_SETTINGS_SUB_ROUTES.DRIVE_TRAIN_LISTING,
            },
            {
              name: operations.carSettings.blackList,
              key: 'car-black-list-listing',
              url: BLACK_LIST_SUB_ROUTES.BLACK_LIST_LISTING,
            },
          ],
        },
      ],
    },
    {
      name: generalSettings.title.toUpperCase(),
      key: 'general-setting-module',
      allowedRoles: [
        UserRoles.ADMIN,
        UserRoles.INSPECTOR_SUPERVISOR,
        UserRoles.OPERATION_SUPERVISOR,
      ],
      hideInProduction: false,
      children: [
        {
          icon: (
            <SettingsIcon fontSize="small" sx={{ color: colors.altBlack }} />
          ),
          name: generalSettings.motives,
          key: 'absence-motives-1',
          url: GENERAL_SETTINGS_SUB_ROUTES.ABSENCE_MOTIVES,
          allowedRoles: [
            UserRoles.ADMIN,
            UserRoles.INSPECTOR_SUPERVISOR,
            UserRoles.INVENTORY_SUPERVISOR,
            UserRoles.OPERATION_SUPERVISOR,
          ],
        },
        {
          icon: (
            <SettingsIcon fontSize="small" sx={{ color: colors.altBlack }} />
          ),
          name: generalSettings.rejection,
          key: 'rejection-motives-1',
          url: GENERAL_SETTINGS_SUB_ROUTES.REJECTION_MOTIVES,
          allowedRoles: [UserRoles.ADMIN, UserRoles.INSPECTOR_SUPERVISOR],
        },
        {
          icon: (
            <SettingsIcon fontSize="small" sx={{ color: colors.altBlack }} />
          ),
          name: generalSettings.answers,
          key: 'AnswersConfiguration-1',
          url: GENERAL_SETTINGS_SUB_ROUTES.ANSWERS_CONFIGURATION,
          allowedRoles: [UserRoles.ADMIN, UserRoles.INSPECTOR_SUPERVISOR],
        },
        {
          icon: (
            <SettingsIcon fontSize="small" sx={{ color: colors.altBlack }} />
          ),
          name: generalSettings.hours,
          key: 'hours-1',
          url: GENERAL_SETTINGS_SUB_ROUTES.HOURS_CREATION,
          allowedRoles: [UserRoles.ADMIN, UserRoles.OPERATION_SUPERVISOR],
        },
        {
          icon: (
            <SettingsIcon fontSize="small" sx={{ color: colors.altBlack }} />
          ),
          name: generalSettings.userManagement,
          key: 'user-management-listing',
          url: GENERAL_SETTINGS_SUB_ROUTES.USER_MANAGEMENT_LISTING,
          allowedRoles: [UserRoles.ADMIN],
        },
      ],
    },
    {
      name: inventory.title.toUpperCase(),
      key: 'inventory-module',
      allowedRoles: [
        UserRoles.ADMIN,
        UserRoles.INVENTORY_SPECIALIST,
        UserRoles.INVENTORY_SUPERVISOR,
        UserRoles.INVENTORY_READER,
      ],
      children: [
        {
          icon: <Dashboard fontSize="small" />,
          name: 'Dashboard',
          key: 'inventory-dashboard',
          hideInProduction: true,
        },
        {
          icon: <FormatListNumberedIcon fontSize="small" />,
          name: inventory.preInventory,
          key: 'pre-iventory-listing',
          url: INVENTORY_SUB_ROUTES.PRE_INVENTORY_LISTING,
          allowedRoles: [
            UserRoles.ADMIN,
            UserRoles.INVENTORY_SPECIALIST,
            UserRoles.INVENTORY_SUPERVISOR,
          ],
        },
        {
          icon: <FormatListBulletedIcon fontSize="small" />,
          name: inventory.title,
          key: 'inventory-listing',
          url: INVENTORY_SUB_ROUTES.INVENTORY_LISTING,
        },
      ],
    },
    {
      name: marketing.title.toUpperCase(),
      key: 'marketing-module',
      allowedRoles: [UserRoles.ADMIN, UserRoles.MARKETING_SPECIALIST],
      hideInProduction: true,
      children: [
        {
          icon: <LocalOfferIcon fontSize="small" />,
          name: marketing.offer,
          key: 'offer-listing',
          hideInProduction: true,
          url: MARKETING_SUB_ROUTES.OFFERS_LISTING,
        },
        {
          icon: <NotificationsIcon fontSize="small" />,
          name: marketing.notification,
          key: 'notification-listing',
          hideInProduction: true,
          url: MARKETING_SUB_ROUTES.NOTIFICATION_LISTING,
        },
      ],
    },
  ]
}
