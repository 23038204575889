import React, { useState } from 'react'

import FeatureContainer from 'components/CarSettings/Common/Detail/FeatureContainer'
import NameContainer from 'components/CarSettings/Common/Detail/NameContainer'
import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'
import Box from 'components/Common/Box'

import { textFiles } from 'constants/textFiles'
import { getTrimSettings } from 'constants/trim'
import useTranslation from 'hooks/useTranslation'
import { NameInformation } from 'models/car'
import { Option } from 'models/Select'
import { TrimDetailDataType } from 'models/services/CarSettings/detail'

import { SettingBox, StyledContainer } from './style'

type Props = {
  information: TrimDetailDataType
  handleSave: (data: TrimDetailDataType) => Promise<boolean>
  submitLoading: boolean
  options: Record<string, Option[]>
}

const Settings = ({
  information,
  handleSave,
  submitLoading,
  options,
}: Props) => {
  const { features: backendFeatures } = information
  const { featuresList } = options

  const { text } = useTranslation(textFiles.TRIM_DETAIL)
  const { carSettings: translation } = text

  const initialNameInformation = getTrimSettings(
    translation.settings,
    options,
    information
  )

  const [settings, setSettings] = useState<NameInformation[]>(
    initialNameInformation
  )

  const [features, setFeatures] = useState<string[]>(
    backendFeatures.map((item) => item.value as string)
  )
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)

  const resetState = () => {
    setSettings(initialNameInformation)
    setFeatures(backendFeatures.map((item) => item.value as string))
    setHasUnsavedChanges(false)
  }

  const handleChangeInformation = (newInformation: NameInformation[]) => {
    setSettings(newInformation)
    setHasUnsavedChanges(true)
  }

  const handleChangeFeatures = (newInformation: string[]) => {
    setFeatures(newInformation)
    setHasUnsavedChanges(true)
  }

  const handleSaveChanges = async () => {
    const isUpdateSuccesful = await handleSave({
      ...information,
      transmission: {
        value: settings[0].name as string,
        name: settings[0].name as string,
      },
      bodyStyle: {
        value: settings[1].name as string,
        name: settings[1].name as string,
      },
      driveTrain: {
        value: settings[2].name as string,
        name: settings[2].name as string,
      },
      fuelType: {
        value: settings[3].name as string,
        name: settings[3].name as string,
      },
      features: features.map((item) => {
        return { value: item, name: item }
      }),
    })
    if (isUpdateSuccesful) {
      setHasUnsavedChanges(false)
    }
  }

  return (
    <StyledContainer>
      <SettingBox>
        <NameContainer
          title={translation.settings.title}
          description={translation.settings.description}
          containerSx={{
            padding: '0px !important',
            paddingTop: '1rem !important',
            maxWidth: '399px',
          }}
          information={settings}
          handleSetInformation={handleChangeInformation}
          loading={submitLoading}
        />
        <FeatureContainer
          title={translation.featureSet.title}
          description={translation.featureSet.description}
          height="fit-content"
          containerSx={{
            padding: '0px !important',
            paddingTop: '1rem !important',
            width: '771px',
            height: 'fit-content',
          }}
          information={features}
          handleChangeFeatures={handleChangeFeatures}
          options={featuresList}
        />
      </SettingBox>
      <Box paddingTop="1rem">
        <SaveChangesBlock
          handleSaveChanges={handleSaveChanges}
          resetState={resetState}
          submitLoading={submitLoading}
          shouldRender={hasUnsavedChanges}
        />
      </Box>
    </StyledContainer>
  )
}

export default Settings
