import { gql } from '@apollo/client'

export const GET_CATEGORY_BY_ID = gql`
  query getCategoryById($input: ID!) {
    data: getCategoryById(id: $input) {
      icon
      name
      nameTranslations {
        en
        es
      }
      status
    }
  }
`
