import { Box, Pagination, paginationClasses, styled } from '@mui/material'

import Container from 'components/Common/Container'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors } from 'styles/theme'

export const StyledDashboardBox = styled(Box)({
  width: '90%',
  maxWidth: 1380,

  '@media only screen and (min-width: 1310px)': {
    width: '100%',
  },
})

export const StyledInspectionChangeContainer = styled(Container)({
  padding: '1rem',
  height: 'auto',
  width: '200px',
  marginTop: '1rem',
  [`@media only screen and (min-width: ${BREAK_POINTS.XXL}px)`]: {
    width: '100%',
    marginTop: '0px',
    height: '190px',
  },
})

export const StyledResponsiveContainer = styled(Box)({
  display: 'flex',
  marginTop: '1rem',
  justifyContent: 'space-between',

  [`@media only screen and (min-width: ${BREAK_POINTS.XXL}px)`]: {
    flexDirection: 'column',
    justifyContent: 'unset',
  },
})

export const StyledTopBar = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '1rem',
  width: '25%',
  marginTop: '34px',

  '& > div': {
    width: '85%',
  },
  // Styling the H1 and subtitle on the Total inspections box
  '> div:nth-of-type(1)': {
    '> div:nth-of-type(2)': {
      h1: {
        textAlign: 'center',
      },
      p: {
        textAlign: 'center',
      },
    },
  },

  [`@media only screen and (min-width: ${BREAK_POINTS.XXL}px)`]: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '100%',

    '& > div': {
      width: 'initial',
    },

    '> div:nth-of-type(1)': {
      '> div:nth-of-type(2)': {
        h1: {
          textAlign: 'center',
        },
        p: {
          textAlign: 'initial',
        },
      },
    },

    '> div:nth-of-type(2)': {
      width: '583PX',
      marginTop: '0rem',
    },
  },
})

export const StyledSummaryInspectionsContainer = styled(Container)({
  width: '200px',
  padding: '1rem',
  height: '190px',
  marginTop: '1rem',

  '> div:nth-of-type(2)': {
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  [`@media only screen and (min-width: ${BREAK_POINTS.XXL}px)`]: {
    marginTop: 'initial',
  },
})

export const StyledInspectionsSummaryBox = styled(Box)({
  display: 'flex',
  marginTop: '34px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '73%',

  '> div': {
    width: 'unset',
  },

  [`@media only screen and (min-width: ${BREAK_POINTS.XXL}px)`]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1.5rem',
    width: '100%',

    '> div:nth-of-type(1)': {
      width: '44%',
    },
    '> div:nth-of-type(2)': {
      width: '44%',
    },
  },
})

export const StyledBottomChartsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '0.5rem',
  flexWrap: 'wrap',

  '> div': {
    width: '100%',
    marginTop: '1rem',
  },

  [`@media only screen and (min-width: 1350px)`]: {
    '> div': {
      width: '42%',
    },
  },
  [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
    '> div': {
      width: '44%',
    },
  },
})

export const StyledPagination = styled(Pagination)({
  display: 'flex',
  justifyContent: 'center',
  '& .MuiPaginationItem-icon': {
    fontSize: '1.5rem!important',
  },
  [`& .${paginationClasses.ul}`]: {
    height: '35px',

    '& > li': {
      margin: '0px 10px',
    },

    'li:first-of-type': {
      color: colors.background,
      borderRight: `1px solid ${colors.border}`,
      height: '50%',
      marginLeft: 0,
      marginRight: 0,
    },

    'li:last-child': {
      color: colors.background,
      borderLeft: `1px solid ${colors.border}`,
      height: '50%',
      marginLeft: 0,
      marginRight: 0,
    },
    '.Mui-selected::after': {
      content: '""',
      display: 'block',
      width: ' 14px',
      height: '2px',
      borderRadius: ' 6px',
      background: colors.blue,
    },

    '.Mui-selected': {
      flexDirection: 'column',
      paddingTop: '2px',
      backgroundColor: 'transparent',
    },

    '.MuiPaginationItem-previousNext': {
      color: colors.placeholderGray,
      marginTop: '-5px',
    },
  },
})
