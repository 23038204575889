import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { BackContainer } from 'components/Common/BackContainer'
import BodyContainerWithHeader from 'components/Common/BodyContainerWithHeader'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { CAR_SETTINGS_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import {
  ColorCreationModel,
  ColorTranslationsType,
  CreateColorInputType,
} from 'models/colorCreation'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { PublicationStatus } from 'models/status'
import {
  COLOR_CREATION_STEPS as steps,
  createColorStepperItems,
} from 'utils/CarSettings/Color/creation'
import { validateGraphQLErrorCode } from 'utils/error'
import { getTranslationsFilteredByLanguage } from 'utils/translation'

import { CREATE_COLOR } from 'graphQL/Operations/Color/Creation/mutations'

import { StyledBox } from 'styles/operation/creation'

const ColorCreation = () => {
  const initialColorInformationState: ColorTranslationsType = {
    name: '',
    status: PublicationStatus.PUBLISHED,
    hexCode: '',
    translations: [],
  }

  const initialData: ColorCreationModel = {
    colorInformation: initialColorInformationState,
  }
  const [currentStep, setCurrentStep] = useState<number>(0)

  const [colorData, setColorData] = useState<ColorCreationModel>(initialData)

  const { colorInformation } = colorData
  const { translations } = colorInformation

  const history = useHistory()
  const { show } = useNotification()

  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { text } = useTranslation(textFiles.COLOR_CREATION)

  const {
    title,
    processDescription,
    stepper: { firstStep, secondStep },
  } = text

  const colorStepperText = {
    firstStep,
    secondStep,
  }

  const stepperItems = createColorStepperItems(colorStepperText)

  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }

  const updateColorData = (newColor: ColorCreationModel) => {
    setColorData(newColor)
  }

  const [createColor, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateColorInputType>
  >(CREATE_COLOR, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
      history.push(CAR_SETTINGS_SUB_ROUTES.COLOR_LISTING)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
        })
    },
  })

  const handleSubmit = useCallback(() => {
    const { valueEn, valueEs } = getTranslationsFilteredByLanguage(translations)

    createColor({
      variables: {
        input: {
          name: colorInformation.name,
          name_translations: {
            en: valueEn ? valueEn.description : null,
            es: valueEs ? valueEs.description : null,
          },
          status: colorInformation.status
            ? PublicationStatus.PUBLISHED
            : PublicationStatus.UNPUBLISHED,
          hexCode: colorInformation.hexCode,
        },
      },
    })
  }, [colorInformation, createColor, translations])

  return (
    <StyledBox>
      <BackContainer />
      <BodyContainerWithHeader
        title={title}
        subtitle={processDescription}
        currentStep={currentStep}
        stepperItems={stepperItems}
      >
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          handleSubmit,
          submitLoading,
          colorData,
          updateColorData,
        })}
      </BodyContainerWithHeader>
    </StyledBox>
  )
}

export default ColorCreation
