import React from 'react'
import { FormControl, SelectProps as MuiSelectProps } from '@mui/material'

import { Option } from 'models/Select'

import {
  ArrowIcon,
  StyledInputBase,
  StyledInputLabel,
  StyledMenuItem,
  StyledSelect,
} from './style'

const getTestId = (testId?: string, name?: string | number) => {
  if (testId) return testId

  if (name) return `${name}-select`

  return undefined
}

type SelectProps = {
  /**
   *  Appears before the value of select
   */
  icon?: React.ReactNode
  /**
   * Array of selectable items
   */
  options: Option[]

  /**
   * Styling applied to the root of form control
   */
  formControlStyles?: React.CSSProperties
  /**
   * Cypress test id
   */
  testId?: string
} & MuiSelectProps

const Select: React.FC<SelectProps> = ({
  icon,
  options,
  formControlStyles,
  testId,
  ...props
}) => (
  <FormControl sx={formControlStyles}>
    {props.label && !props.value && (
      <StyledInputLabel shrink={false}>{props.label}</StyledInputLabel>
    )}
    <StyledSelect
      IconComponent={ArrowIcon}
      input={<StyledInputBase startAdornment={icon} />}
      inputProps={{
        'data-cy': getTestId(testId, props.name),
      }}
      {...props}
    >
      {options.map((option) => (
        <StyledMenuItem
          disabled={option.disabled}
          key={option.value}
          value={option.value}
          data-cy={`${option.value}-select-item`}
        >
          {option.name}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  </FormControl>
)

export default Select
