import { buttonClasses, Card, styled } from '@mui/material'
import Chip from '@mui/material/Chip'

import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import Container from 'components/Common/Container'
import Select from 'components/Common/Select'

import { BREAK_POINTS } from 'styles/breakpoints'
import { colors, FONT_WEIGHT } from 'styles/theme'

export const GridContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: 30,
  rowGap: '20px',

  '> div': {
    width: '30%',
    [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
      width: '45%',
    },
  },
})

export const FeaturesContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '5px',
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  padding: '0',
})

export const FeatureBox = styled(Chip)({
  backgroundColor: colors.cyan,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'space-evenly',
  width: 'auto',
  padding: '0 0.5rem',
  height: 27,
  borderRadius: 4,
  marginBottom: 1,
})

export const StyledSelect = styled(Select)({
  height: 'unset',
  minHeight: 40,
  maxHeight: 75,
  '> div': {
    overflow: 'auto !important',
    maxHeight: 60,
  },
})

export const AddButton = styled(Button)({
  border: `2px solid ${colors.gray} !important`,
  color: `${colors.gray} !important`,
  height: '40px !important',
  width: '30% !important',
  fontSize: '12px !important',
  borderRadius: '4px !important',
  justifyContent: 'center !important',
})

export const SetButton = styled(Button)({
  border: `2px solid ${colors.lightGray} !important`,
  color: `${colors.lightGray} !important`,
  borderRadius: '4px !important',
  height: '40px !important',
  width: '65% !important',
  display: 'flex',
  justifyContent: 'flex-start !important',
  ':hover': {
    border: `2px solid ${colors.blue} !important`,
  },
  [`.${buttonClasses.endIcon}`]: {
    position: 'absolute',
    right: 10,
  },
})

export const StyledItem = styled(Container)({
  width: '100%',
  maxHeight: 145,
  color: colors.placeholderGray,
  borderRadius: 0,
  padding: 0,
  '& > div:nth-of-type(2)': {
    margin: 0,
  },
  ul: {
    paddingLeft: '35px',
    fontSize: 12,
    fontWeight: FONT_WEIGHT.MEDIUM,
    li: {
      '&::marker': {
        fontSize: 12,
        color: colors.blue,
      },
      span: {
        color: colors.placeholderGray,
      },
      marginBottom: '12px',
    },
  },
})

export const InfoBox = styled(Card)({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  height: '45px',
  maxWidth: '445px',
  marginBottom: 10,
})
