import React from 'react'
import {
  TableCell as MUICell,
  TableCellProps,
  TableContainerProps,
  TableProps,
  TableRowProps,
} from '@mui/material'

import {
  RowBodyCell,
  StyledRow,
  StyledTable,
  StyledTableContainer,
} from './style'

type NativeTableType = {
  maxHeight?: string | number
  tableProps?: TableProps
} & TableContainerProps

type TableBodyRowType = { extraButton?: boolean } & TableRowProps
type TableCellType = { body?: boolean } & TableCellProps

const TableNative = ({ maxHeight, tableProps, ...props }: NativeTableType) => (
  <StyledTableContainer sx={{ maxHeight }} {...props}>
    <StyledTable
      sx={{ marginTop: tableProps?.stickyHeader ? '-5px !important' : 0 }}
      {...tableProps}
    >
      {props.children}
    </StyledTable>
  </StyledTableContainer>
)

const TableBodyRow = ({ extraButton = false, ...props }: TableBodyRowType) => {
  const style = () => {
    if (extraButton) {
      return {
        'td:nth-last-of-type(2)': {
          borderRadius: '0px 4px 4px 0px',
        },
        'td:last-of-type': { borderRadius: '0px' },
      }
    }
    return {
      'td:nth-last-of-type(2)': { borderRadius: '0px 4px 4px 0px' },
      'td:last-of-type': { borderRadius: '0px 4px 4px 0px' },
    }
  }
  return (
    <StyledRow
      {...props}
      sx={{
        ...style(),
        'td:first-of-type': {
          borderRadius: '4px 0px 0px 4px',
        },
      }}
    >
      {props.children}
    </StyledRow>
  )
}

const TableCell = ({ body = false, ...props }: TableCellType) => {
  const style = () => {
    if (body) {
      return RowBodyCell
    }
    return props.sx
  }
  return (
    <MUICell {...props} sx={{ ...style() }}>
      {props.children}
    </MUICell>
  )
}

export { TableNative, TableBodyRow, TableCell }
