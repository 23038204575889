import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import ErrorPage from 'pages/ErrorPage'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import NotFoundErrorImage from 'images/notFoundError.svg'

export const NotFoundErrorPage = () => {
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { text } = useTranslation(textFiles.ERROR)

  const handleGoBack = () => {
    if (history.length > 1) {
      history.go(-2)
    } else history.goBack()
  }
  const handleOpenHelpModal = () => {
    setIsModalOpen(true)
  }
  const handleCloseHelpModal = () => {
    setIsModalOpen(false)
  }

  return (
    <ErrorPage
      text={text.notFound}
      rightButtonProps={{ startIcon: <HelpOutlineIcon /> }}
      handleLeftButtonClick={handleGoBack}
      handleRightButtonClick={handleOpenHelpModal}
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseHelpModal}
      imageSrc={NotFoundErrorImage}
    />
  )
}

export default NotFoundErrorPage
