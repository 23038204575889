import React from 'react'
import { ButtonProps as MuiButtonProps } from '@mui/material'

import { StyledButton } from './style'

// creates a type based on Material UI Button Props, adds another property
// called buttonType and then omits the colors, variant and disabledElevation
// from MUI Button Props
export type ButtonProps = Omit<
  {
    buttonType?: 'primary' | 'secondary' | 'info'
    variant?: 'contained' | 'text'
    testId?: string
  } & MuiButtonProps,
  'color' | 'disableElevation'
>

const Button = ({
  buttonType = 'primary',
  endIcon,
  startIcon,
  variant = 'contained',
  testId,
  ...props
}: ButtonProps) => (
  <StyledButton
    color={buttonType}
    disableElevation
    endIcon={endIcon}
    startIcon={startIcon}
    style={{
      justifyContent: startIcon || endIcon ? 'flex-start' : 'center',
    }}
    variant={variant}
    data-cy={testId}
    {...props}
  />
)

export default Button
