import React from 'react'
import {
  Bar,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { getSelectedColorsPerBar } from 'utils/BarChartUtils'

import { colors } from 'styles/theme'

import { LegendTextFormatter, StyledBarChart } from './style'

const barChartColors = [colors.blue, colors.darkAquaBlue, colors.navyBlue]

export type DataSourceType = {
  name: string
  [keyName: string]: number | string
}

export type BarStructureType = {
  name: string
  stackId?: string
  dataKey: string
}

type BarsStyleType = {
  maxBarSize?: number | undefined
  barSize?: string | number | undefined
  barGap?: string | number | undefined
}

type LayoutType = 'horizontal' | 'vertical'

type sizeType = {
  width: number | string
  height: number | string
}

export type BarChartProps = {
  /**
   * Array containing the information to be rendered, the `name` is used to identify
   * each bar and every other key containing your value must be value inside the
   * `dataKey` of the each object inside barsStructure
   */
  data: DataSourceType[]

  /**
   ** Array containing objects describing each bar:
   ** name of the bar, stackId: (should it stack on top of another?),
   ** which data set does it refer to? (dataKey)
   */
  barsStructure: BarStructureType[]

  /**
   ** Styling for each Bar
   */
  barStyle?: BarsStyleType | undefined

  layout: LayoutType

  size: sizeType

  /**
   * Describes if the bar chart will have a percentage on its values or not
   */
  hasPercentage?: boolean

  /**
   * Describes if the bar chart will have a legend on the bottom
   */

  hasLegend?: boolean
}

const BarChart = ({
  data,
  barsStructure,
  layout,
  size,
  barStyle = { maxBarSize: 48, barGap: '46px', barSize: '48px' },
  hasPercentage = false,
  hasLegend,
}: BarChartProps) => {
  const selectedColorsPerBar = getSelectedColorsPerBar(
    barsStructure,
    barChartColors.length - 1
  )

  const orientation = layout === 'horizontal' ? 'vertical' : 'horizontal'

  return (
    <ResponsiveContainer width={size.width} height={size.height}>
      <StyledBarChart
        data={data}
        margin={{ top: 20, right: 5, bottom: 5, left: 60 }}
        barSize={barStyle.barSize}
        maxBarSize={barStyle.maxBarSize}
        barGap={barStyle.barGap}
        layout={orientation}
        width={100}
      >
        <Tooltip cursor={{ fill: colors.transparent }} />
        <CartesianGrid
          vertical={orientation !== 'horizontal'}
          horizontal={orientation !== 'vertical'}
        />

        <XAxis
          dataKey={orientation === 'horizontal' ? 'name' : undefined}
          type={orientation === 'vertical' ? 'number' : undefined}
          padding={{ left: 0, right: 40 }}
          height={60}
          stroke={colors.commonBlack}
          tickLine={false}
          dy={10}
          axisLine={orientation !== 'vertical'}
          tickFormatter={(value) =>
            hasPercentage && orientation === 'vertical' ? `${value}%` : value
          }
        />
        <YAxis
          dataKey={orientation === 'vertical' ? 'name' : undefined}
          axisLine={orientation === 'horizontal' ? false : undefined}
          tickLine={false}
          stroke={colors.commonBlack}
          type={orientation === 'vertical' ? 'category' : undefined}
          textAnchor="end"
          padding={{ top: 0, bottom: 0 }}
          dx={-10}
          tickFormatter={(value) =>
            hasPercentage && orientation === 'horizontal' ? `${value}%` : value
          }
        />
        {barsStructure.map((bar, barIndex) => {
          return (
            <Bar
              key={bar.dataKey}
              name={bar.name}
              stackId={bar.stackId ? bar.stackId : barIndex}
              dataKey={bar.dataKey}
              fill={barChartColors[selectedColorsPerBar[barIndex]]}
              label={
                barIndex === barsStructure.length - 1
                  ? {
                      fontSize: 12,
                      fill: colors.commonBlack,
                      position: orientation === 'horizontal' ? 'top' : 'right',
                      dx: orientation === 'horizontal' ? undefined : 8,
                      formatter: (value: number) => {
                        return Math.round(value)
                      },
                    }
                  : false
              }
            >
              {orientation === 'horizontal' ? (
                <LabelList
                  dataKey={bar.dataKey}
                  position={barIndex === 0 ? 'insideTop' : 'insideBottom'}
                  style={{ fontSize: 12, fill: colors.commonWhite }}
                  dy={barIndex === 0 ? 10 : -10}
                  formatter={(value: number) => {
                    /*
                     * Removing empty zeroes from columns (causes text overlapping)
                     */
                    if (value === 0) return ''
                    return Math.round(value)
                  }}
                />
              ) : (
                <LabelList
                  dataKey={bar.dataKey}
                  position="center"
                  style={{ fontSize: 12, fill: colors.commonWhite }}
                  dx={0}
                  formatter={(value: number) => {
                    /**
                     * Removing empty (0) value from charts, so it does not collide with
                     * actual important values
                     */

                    if (value === 0) return ''
                    return Math.round(value)
                  }}
                />
              )}
            </Bar>
          )
        })}

        {hasLegend ? (
          <Legend
            iconSize={20}
            iconType="square"
            formatter={LegendTextFormatter}
            align="left"
            height={45}
            wrapperStyle={{
              bottom: '5px',
              width: '100%',
              left: '30px',
              display: 'flex',
              justifyContent: 'center',
            }}
          />
        ) : null}
      </StyledBarChart>
    </ResponsiveContainer>
  )
}

export default BarChart
