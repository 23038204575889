import { gql } from '@apollo/client'

export const UPDATE_DRIVE_TRAIN = gql`
  mutation updateDriveTrain($input: UpdateDriveTrainInput!) {
    data: updateDriveTrain(input: $input) {
      id
      name
    }
  }
`
