import { gql } from '@apollo/client'

export const LIST_BRANDS = gql`
  query listBrands($input: FilterInput) {
    listData: listBrands(input: $input) {
      count
      data {
        picture: icon
        id
        name
        createdAt
        status
      }
    }
  }
`

export const LIST_MODELS = gql`
  query listModels($input: FilterInput) {
    listData: listModels(input: $input) {
      count
      data {
        brand {
          name
          picture: icon
        }
        name
        createdAt
        id
        status
      }
    }
  }
`

export const LIST_TRIM_LEVELS = gql`
  query listTrimLevels($input: FilterInput) {
    listData: listTrimLevels(input: $input) {
      count
      data {
        id
        name
        model {
          id
          name
          brand {
            name
          }
        }
        features {
          id
          name
        }
        bodyStyle {
          id
          name
        }
        year
        status
        createdAt
      }
    }
  }
`

export const LIST_COUNTRY_VERSIONS = gql`
  query listCountryVersion($input: FilterInput) {
    listData: listCountryVersions(input: $input) {
      count
      data {
        id
        name
        createdAt
        status
      }
    }
  }
`

export const LIST_FEATURES = gql`
  query listFeatures($input: FilterInput) {
    listData: listFeatures(input: $input) {
      count
      data {
        id
        name
        createdAt
        status
      }
    }
  }
`

export const LIST_BODY_STYLES = gql`
  query listBodyStyles($input: FilterInput) {
    listData: listBodyStyles(input: $input) {
      count
      data {
        id
        name
        createdAt
        picture: icon
        status
      }
    }
  }
`

export const LIST_TRANSMISSIONS = gql`
  query listTransmissions($input: FilterInput) {
    listData: listTransmissions(input: $input) {
      count
      data {
        id
        name
        createdAt
        status
      }
    }
  }
`

export const LIST_CATEGORIES = gql`
  query listCategories($input: FilterInput) {
    listData: listCategories(input: $input) {
      count
      data {
        id
        name
        createdAt
        picture: icon
        status
      }
    }
  }
`

export const LIST_FUEL_TYPES = gql`
  query listFuelTypes($input: FilterInput) {
    listData: listFuelTypes(input: $input) {
      count
      data {
        id
        name
        createdAt
        status
      }
    }
  }
`

export const LIST_DRIVE_TRAINS = gql`
  query listDriveTrains($input: FilterInput) {
    listData: listDriveTrains(input: $input) {
      count
      data {
        id
        name
        createdAt
        status
      }
    }
  }
`

export const LIST_COLORS = gql`
  query listColors($input: FilterInput) {
    listData: listColors(input: $input) {
      count
      data {
        id
        name
        createdAt
        hexCode
        status
      }
    }
  }
`
