import { gql } from '@apollo/client'

export const LIST_INVENTORY = gql`
  query listInventoryCars($input: FilterInput!) {
    listInventoryCars: listInventoryCars(input: $input) {
      count
      data {
        id
        createdAt
        mainPicture
        listingPrice
        slug
        certified
        year
        mileage
        provider
        status {
          backgroundColor
          description
          id
          name
          textColor
          slug
        }
        curboSpot {
          id
          name
        }
        driveTrain {
          id
          name
        }
        features {
          id
          name
        }
        exteriorColor {
          id
          name
          hexCode
        }
        interiorColor {
          id
          name
          hexCode
        }
        dealer {
          id
          name
        }
        fuelType {
          id
          name
        }
        transmission {
          id
          name
        }
        brand {
          id
          name
        }
        carModel {
          id
          name
        }
        countryVersion {
          id
          name
        }
        categories {
          id
          name
          icon
        }
        bodyStyle {
          id
          name
        }
        trimLevel {
          id
          name
          year
          slug
        }
      }
    }
  }
`

export const GET_CAR_STATUSES = gql`
  query getCarStatuses($input: FilterInput) {
    data: getCarStatuses(input: $input) {
      backgroundColor
      description
      id
      name
      textColor
      slug
    }
  }
`
