import { gql } from '@apollo/client'

export const GET_DRIVE_TRAIN_BY_ID = gql`
  query getDriveTrainById($input: ID!) {
    data: getDriveTrainById(id: $input) {
      nameTranslations {
        en
        es
      }
      name
      status
    }
  }
`
