import { Option } from 'models/Select'

export const getEntityTypes = (text: Record<string, string>): Option[] => {
  return [
    {
      name: text.brand,
      value: 'make',
    },
    {
      name: text.model,
      value: 'model',
    },
    {
      name: text.trim,
      value: 'trim',
    },
  ]
}

export enum ENTITY_CREATION_OPTION {
  UPDATE_ENTITY_INFORMATION = 'updateEntityInformation',
}
