import { gql } from '@apollo/client'

export const GET_STATE_BY_ID = gql`
  query getStateById($input: ID!) {
    data: getStateById(id: $input) {
      id
      name
      status
      latitude
      longitude
      curboSpotCount
      dealerCount
    }
  }
`
