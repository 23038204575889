import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import ButtonContainer from 'components/CarSettings/Common/Creation/ButtonContainer'
import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'
import ErrorMessage from 'components/Common/ErrorMessage'
import Select from 'components/Common/Select'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { City } from 'models/cityCreation'
import { PublicationStatus } from 'models/status'
import { CityCreationProps } from 'utils/City/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

const CityInfo = ({
  cityData,
  handleContinue,
  states,
  updateCityData,
}: CityCreationProps) => {
  const { text } = useTranslation(textFiles.CITY_CREATION)

  const {
    information: {
      name,
      newYork,
      fieldRequired,
      isPublished,
      isPublishedDescription,
      citySubtitle,
      state,
      stateDescription,
    },
  } = text

  const { cityInformation } = cityData

  const [cityStatus, setCityStatus] = useState<boolean>(
    cityInformation.status === PublicationStatus.PUBLISHED
  )

  const validationSchema = yup.object({
    name: yup.string().required(fieldRequired),
    state: yup.string().required(fieldRequired),
  })

  const formik = useFormik<Partial<City>>({
    initialValues: {
      name: cityInformation.name,
      state: cityInformation.state,
    },
    validationSchema,
    onSubmit: () => {
      if (formik.values.name !== undefined && formik.values.state) {
        const customCityInfo = {
          cityInformation: {
            ...cityInformation,
            state: formik.values.state,
            name: formik.values.name,
            status: cityStatus
              ? PublicationStatus.PUBLISHED
              : PublicationStatus.UNPUBLISHED,
          },
        }
        updateCityData(customCityInfo)
      }
      handleContinue()
    },
  })

  return (
    <StyledContainer>
      <StyledForm
        onSubmit={formik.handleSubmit}
        sx={{
          '> div': {
            columnGap: '3rem',
          },
        }}
      >
        <div>
          <StyledTextFieldContainer title={name} description={citySubtitle}>
            <StyledTextField
              variant="outlined"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder={newYork}
              error={formik.touched.name && Boolean(formik.errors.name)}
              inputProps={{ maxLength: 40 }}
            />
            {formik.touched.name && formik.errors.name ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.name}
              />
            ) : null}
          </StyledTextFieldContainer>
          <ToggleContainer
            title={isPublished}
            description={isPublishedDescription}
            status={cityStatus}
            handleStatus={setCityStatus}
            testId="published"
          />

          <StyledTextFieldContainer
            title={state}
            description={stateDescription}
          >
            <Select
              options={states}
              variant="outlined"
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              sx={{ fontSize: 12, height: 40 }}
              label={state}
              error={formik.touched.state && Boolean(formik.errors.state)}
            />
            {formik.touched.state && formik.errors.state ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.state}
              />
            ) : null}
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonContainer
        isFirstStep
        confirmButtonType="submit"
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default CityInfo
