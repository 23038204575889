import React from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { SelectChangeEvent, selectClasses } from '@mui/material'

import Box from 'components/Common/Box'
import Select from 'components/Common/Select'
import { FeatureBox } from 'components/Inventory/Common/FeatureSetCell/styles'

import { Option } from 'models/Select'

type MultipleSelectProps = {
  name: string
  values: string[]
  options: Option[]
  onChange?: (event: SelectChangeEvent<unknown>) => void
  onRemove: (e: React.MouseEvent, id: number) => void
  error?: boolean
  label: string
  extraStyle?: React.CSSProperties
  disabled?: boolean
  disabledStyles?: React.CSSProperties
}

// TODO: Work on styling when overflown
const MultipleSelect = ({
  error,
  name,
  values,
  onChange,
  onRemove,
  label,
  options,
  extraStyle,
  disabled,
  disabledStyles,
}: MultipleSelectProps) => {
  const renderOption = (value: string): string | undefined => {
    /**
     * As of now, it is possible that some questions have answer IDs that are no longer in use.
     * Then, we return undefined for these cases, so that the plain ID is shown and the app does not crash
     */

    const labelText = options.find((option) => option.value === value)
    if (labelText) return labelText.name

    return undefined
  }

  return (
    <Select
      disabled={disabled}
      name={name}
      value={values}
      multiple
      onChange={onChange}
      variant="outlined"
      label={label}
      error={error}
      options={options}
      formControlStyles={{
        width: '100%',
        height: 'auto',
        minHeight: '29px',
      }}
      sx={{
        minHeight: '38px',
        height: 'auto',
        [`.${selectClasses.select}`]: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: '5px',
        },
        [`.${selectClasses.disabled}`]: {
          ...disabledStyles,
        },
        extraStyle,
      }}
      renderValue={(selected: unknown) => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '5px',
            width: '100%',
            minHeight: '23px',
            overflowY: 'auto',
          }}
        >
          {(selected as string[]).map((item, index) => (
            <FeatureBox
              key={item}
              label={renderOption(item) || item}
              // clickable={false}
              // clickable
              onDelete={
                disabled
                  ? undefined
                  : (e: React.MouseEvent) => onRemove(e, index)
              }
              deleteIcon={
                <ClearIcon onMouseDown={(event) => event.stopPropagation()} />
              }
            />
          ))}
        </Box>
      )}
    />
  )
}

export default MultipleSelect
