import axios from 'axios'

import { BASE_API_URL } from 'constants/environment'
import { ACCESS_TOKEN_KEY } from 'constants/localStorage'

export async function getInspectionReportPdf(id: string) {
  const url = `${BASE_API_URL}/inspection/file/inspections/${id}/pdf`

  let token = ''
  if (typeof window !== undefined) {
    token =
      window.localStorage.getItem(ACCESS_TOKEN_KEY)?.replace(/"/g, '') || ''
  }

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'arraybuffer',
  })
}
