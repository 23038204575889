import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import ClearIcon from '@mui/icons-material/Clear'
import {
  ClickAwayListener,
  Fade,
  IconButton,
  Popper,
  SelectChangeEvent,
  Typography,
} from '@mui/material'

import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import TextField from 'components/Common/TextField'
import MultipleSelect from 'components/Inventory/Creation/Common/MultipleSelect'
import TextArea from 'components/Template/Detail/TextArea'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Option } from 'models/Select'
import { BaseEntity, GenericData } from 'models/services/base'
import { Question as QuestionType } from 'models/services/template/detail'

import { GET_ANSWERS } from 'graphQL/Template/Creation/queries'

import { colors, FONT_WEIGHT } from 'styles/theme'

import {
  StyledCheckbox,
  StyledErrorMessage,
  StyledTextFieldContainer,
} from './style'

type QuestionProps = {
  index: number
  editMode: boolean
  question: QuestionType
  shouldUpdate: boolean
  handleUpdateQuestion: (newQuestion?: QuestionType) => void
  handleDeleteQuestion: (questionIndex: number) => void
  setAnswerMissingError: (error: boolean) => void
  saveStateCalled: boolean
}

const Question = ({
  index,
  editMode,
  question,
  shouldUpdate,
  handleUpdateQuestion,
  handleDeleteQuestion,
  setAnswerMissingError,
  saveStateCalled,
}: QuestionProps) => {
  const { text } = useTranslation(textFiles.TEMPLATE_DETAIL)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const [requiredCheck, setRequiredCheck] = useState<boolean>(
    question.isRequired
  )
  const [requiresPictureCheck, setRequirePictureCheck] = useState<boolean>(
    question.isPictureRequired
  )
  const [score, setScore] = useState<number>(question.value)
  const [questionsText, setQuestionsText] = useState<string>(question.label)
  const [answersTouched, setAnswersTouched] = useState<boolean>(false)

  const [answers, setAnswers] = useState<string[]>(
    question.answers.map((ans) => ans.id)
  )
  const [answerOptions, setAnswerOptions] = useState<Option[]>([])

  const wasUpdated = useRef<boolean>(false)

  useQuery<GenericData<BaseEntity[]>>(GET_ANSWERS, {
    onCompleted(response) {
      setAnswerOptions(
        response.data.map((item) => {
          return {
            name: item.name,
            value: item.id,
          }
        })
      )
    },
  })

  const handleAnswerRemove = (e: React.MouseEvent, idx: number) => {
    e.preventDefault()

    const newAnswers = [...answers]
    newAnswers.splice(idx, 1)
    setAnswers(newAnswers)
  }

  const handleRequireCheck = () => {
    if (editMode) {
      setRequiredCheck((prevState) => !prevState)
    }
  }

  const handlePictureCheck = () => {
    if (editMode) {
      setRequirePictureCheck((prevState) => !prevState)
    }
  }

  const handleScoreChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target
    if (value.length > 0) {
      let convertedValue = Number(value)
      if (editMode) {
        if (convertedValue > 100) {
          convertedValue = 100
        } else if (convertedValue < 0) {
          convertedValue = 0
        }
        setScore(convertedValue)
      }
    }
  }

  const handleQuestionChange = (newText: string) => {
    if (editMode) {
      setQuestionsText(newText)
    }
  }

  // Manage status popper
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)

  const handlePopperClose = () => {
    setOpen(false)
  }
  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
    /*
      We stop the propagation because there were cases where
      if the user after opening a popper tries to open another
      one both will be faded and the user will have to
      click again to show the correct one.
      */
    event.stopPropagation()
  }

  const handleDelete = () => {
    /*
      We make sure to hide the popper before removing the 
      current component to it doesn't throws error that 
      anchorEl isn't in the layout anymore.
      */
    handleDeleteQuestion(index)
    setAnchorEl(null)
    setOpen(false)
  }

  const handleAnswersChange = (e: SelectChangeEvent<unknown>) => {
    /**
     * Here it is needed to treat event.target.value as string[] because
     * of the way the MUI multi select works, it brings
     * the event.target.value as a string[], containing
     * all of the previous selected items + the new one at the last index.
     * Example: ["Motor", "Windows", "Paint", "new entry"]
     */

    const eventAnswers = e.target.value as string[]

    setAnswers(eventAnswers)

    setAnswersTouched(true)
  }

  useEffect(() => {
    if (answers.length < 2) {
      setAnswerMissingError(true)
    } else {
      setAnswerMissingError(false)
    }
  }, [answers, answersTouched, setAnswerMissingError])

  /*
  We use the wasUpdated ref to make sure the question is only 
  updated once and we don't enter in a loop while the shouldUpdate
  props stays true
  */
  useEffect(() => {
    if (shouldUpdate && !wasUpdated.current) {
      handleUpdateQuestion({
        answers: answers.map((answerId) => {
          return { id: answerId }
        }),
        id: question.id,
        isRequired: requiredCheck,
        isPictureRequired: requiresPictureCheck,
        value: score,
        label: questionsText,
      })
      wasUpdated.current = true
    }
    if (shouldUpdate === false && wasUpdated.current === true) {
      wasUpdated.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldUpdate,
    handleUpdateQuestion,
    requiredCheck,
    requiresPictureCheck,
    score,
    questionsText,
  ])

  useEffect(() => {
    if (editMode === false) {
      setRequiredCheck(question.isRequired)
      setRequirePictureCheck(question.isPictureRequired)
      setScore(question.value)
      setQuestionsText(question.label)
    }
  }, [editMode, question])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <Box
          sx={{
            flex: '1',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0.5em',
            borderRadius: '4px',
            marginBottom: '1em',
            bgcolor: colors.background,
            '& > div:first-of-type': {
              width: '50%',
              margin: 'auto 0.5em',
              height: '60px',
              maxWidth: '600px',
            },
          }}
        >
          <TextArea
            editMode={editMode}
            rows={1}
            variant="QUESTION"
            initialValue={questionsText}
            updateText={(newTitle) => handleQuestionChange(newTitle)}
            saveStateCalled={saveStateCalled}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '270px',
            }}
          >
            <StyledCheckbox
              checked={requiredCheck}
              disableRipple={!editMode}
              onChange={handleRequireCheck}
            />

            <StyledCheckbox
              checked={requiresPictureCheck}
              disableRipple={!editMode}
              onChange={handlePictureCheck}
            />
            <TextField
              id="outlined-number"
              type="number"
              hiddenLabel
              disabled={!editMode}
              onChange={handleScoreChange}
              value={score}
              sx={{
                width: '67px',
                height: '28px',
                padding: '0.5em',
                textAlign: 'center',
                backgroundColor: editMode
                  ? colors.commonWhite
                  : colors.questionGray,
                border: `1px solid ${colors.border}`,
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              inputProps={{
                style: {
                  padding: '5px',
                  width: '50px',
                },
              }}
            />
          </Box>
        </Box>

        <IconButton
          sx={{
            width: '30px',
            color: colors.questionGray,
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto 0.25em 1.5em 0.25em',
          }}
          onClick={handlePopperOpen}
        >
          {editMode && index !== 0 ? (
            <ClearIcon
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            />
          ) : null}
        </IconButton>

        <Popper
          id="cancel-popper"
          open={open}
          anchorEl={anchorEl}
          placement="right"
          transition
          style={{
            zIndex: 1500,
          }}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handlePopperClose}>
              <Fade {...TransitionProps}>
                <Box
                  sx={{
                    backgroundColor: colors.commonWhite,
                    width: '135px',
                    height: '100px',
                    padding: '0.5em',
                    borderRadius: '4px',
                    boxShadow: `0px 3px 6px ${colors.shadow}`,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: colors.black,
                      margin: '0.5em auto 1em auto',
                    }}
                  >
                    {text.general.deleteTitle}
                  </Typography>
                  <Button
                    buttonType="primary"
                    type="button"
                    size="small"
                    sx={{
                      marginRight: '0.5em',
                    }}
                    onClick={handleDelete}
                  >
                    {text.buttons.deleteConfirmation}
                  </Button>
                  <Button
                    buttonType="info"
                    type="button"
                    size="small"
                    sx={{
                      backgroundColor: colors.questionGray,
                    }}
                    onClick={handlePopperClose}
                  >
                    {text.buttons.deleteCancel}
                  </Button>
                </Box>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      </Box>

      <Box width="100%" marginBottom="1em">
        <StyledTextFieldContainer
          title={text.general.setOfAnswers}
          titleStyle={{
            '& > p': {
              fontSize: '14px',
              fontWeight: FONT_WEIGHT.MEDIUM,
            },
          }}
          sx={{ width: '96%' }}
          contentStyle={{
            '& > div > div': {
              border: !editMode ? 'none' : `1px solid ${colors.lightGray}`,
            },
            '& > div > div:hover': {
              border: !editMode ? 'none' : `1px solid ${colors.blue}`,
            },
          }}
        >
          <MultipleSelect
            disabled={!editMode}
            options={answerOptions}
            onChange={handleAnswersChange}
            label={text.general.answers}
            values={answers}
            name="answers"
            error={answersTouched && answers.length < 2}
            onRemove={handleAnswerRemove}
            disabledStyles={{
              backgroundColor: colors.background,
            }}
          />
          {(answersTouched && answers.length < 2) ||
          (saveStateCalled && answers.length < 2) ? (
            <StyledErrorMessage
              text={validationText.errorRequiredItems.replace('%d', 2)}
            />
          ) : null}
        </StyledTextFieldContainer>
      </Box>
    </>
  )
}

export default Question
