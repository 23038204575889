import { gql } from '@apollo/client'

export const DELETE_DEALER = gql`
  mutation deleteDealer($input: ID!) {
    data: deleteDealer(id: $input) {
      id
      name
    }
  }
`
