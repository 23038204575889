import React, { useState } from 'react'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Typography } from '@mui/material'
import Collapse from '@mui/material/Collapse'

import Box from 'components/Common/Box'
import CheckpointComponent from 'components/Inspection/Detail/Report/Checkpoint'
import PieChart from 'components/Inspection/Detail/Report/PieChart'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { selectColor } from 'utils/ProgressUtils'

import { colors } from 'styles/theme'

import { CheckpointWithScore } from '..'

type StepProps = {
  index: number
  title: string
  checkpoints: CheckpointWithScore[]
  shouldOpen?: boolean
  score: [number, number]
}

const Step = ({
  index,
  title,
  checkpoints,
  shouldOpen = false,
  score,
}: StepProps) => {
  const [show, setShow] = useState<boolean>(shouldOpen)
  const { text } = useTranslation(textFiles.INSPECTION_DETAIL)
  const { report: translation } = text

  return (
    <Box marginBottom="1rem">
      <Box
        onClick={() => setShow(!show)}
        sx={{
          display: 'flex',
          ':hover': {
            cursor: 'pointer',
          },
        }}
        data-cy={`collapse-container-${index}`}
      >
        {show ? (
          <ArrowDropUp sx={{ color: colors.blue }} />
        ) : (
          <ArrowDropDown sx={{ color: colors.blue }} />
        )}
        <Typography variant="body2" color={colors.black}>
          <strong>
            {translation.evaluation.step}
            {index}:
          </strong>{' '}
          {title}
        </Typography>
      </Box>
      <Collapse in={show} timeout="auto">
        <Box sx={{ display: 'flex' }}>
          <PieChart
            size={{ height: 140, width: 140 }}
            score={score}
            color={selectColor(score)}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: '360px',
            }}
          >
            {checkpoints.map((checkpoint, checkpointIndex) => (
              <CheckpointComponent
                key={checkpoint.id}
                index={checkpointIndex + 1}
                checkpoint={checkpoint}
                score={checkpoint.score}
              />
            ))}
          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}

export default Step
