import React, { useState } from 'react'

import { textFiles } from 'constants/textFiles'
import { MILEAGE_OPTION, TRIM_CREATION_OPTION } from 'constants/trim'
import useTranslation from 'hooks/useTranslation'
import { MileageInformationModel } from 'models/trimCreation'
import { TrimCreationProps } from 'utils/CarSettings/Trim/creation'

import Skeleton, { ExtendedSliderProps } from '../Common/Skeleton'

export const Mileage = ({
  handleBack,
  handleContinue,
  trimData,
  updateTrimData,
}: TrimCreationProps) => {
  const { mileageInformation } = trimData
  const [info, setInfo] = useState<MileageInformationModel>(mileageInformation)
  const { text: translation } = useTranslation(textFiles.TRIM_CREATION)

  const { mileage } = translation

  const handleBlur = (map: MILEAGE_OPTION) => {
    if (info[map] > 10) {
      setInfo({ ...info, [map]: 10 })
    }
    if (info[map] < 1) {
      setInfo({ ...info, [map]: 1 })
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    map: MILEAGE_OPTION
  ) => {
    setInfo({
      ...info,
      [map]: Number(event.target.value),
    })
  }

  const handleSubmit = () => {
    updateTrimData({
      type: TRIM_CREATION_OPTION.UPDATE_MILEAGE_INFORMATION,
      payload: {
        mpg: info.mpg,
        cityMpg: info.cityMpg,
        highwayMpg: info.highwayMpg,
        fuelCapacity: info.fuelCapacity,
        cylinders: info.cylinders,
        torque: info.torque,
        torqueRpm: info.torqueRpm,
        horsePower: info.horsePower,
        horsePowerRpm: info.horsePowerRpm,
      },
    })
    handleContinue()
  }

  const SLIDER_ITEMS: ExtendedSliderProps[] = [
    {
      id: 1,
      title: mileage.mpg,
      name: MILEAGE_OPTION.MPG,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, mpg: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, MILEAGE_OPTION.MPG),
      handleBlur: () => handleBlur(MILEAGE_OPTION.MPG),
      max: 5000,
    },
    {
      id: 2,
      title: mileage.cityMpg,
      name: MILEAGE_OPTION.CITY_MPG,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, cityMpg: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, MILEAGE_OPTION.CITY_MPG),
      handleBlur: () => handleBlur(MILEAGE_OPTION.CITY_MPG),
      max: 5000,
    },
    {
      id: 3,
      title: mileage.highwayMpg,
      name: MILEAGE_OPTION.HIGHWAY_MPG,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, highwayMpg: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, MILEAGE_OPTION.HIGHWAY_MPG),
      handleBlur: () => handleBlur(MILEAGE_OPTION.HIGHWAY_MPG),
      max: 5000,
    },
    {
      id: 4,
      title: mileage.fuelCapacity,
      name: MILEAGE_OPTION.FUEL_CAPACITY,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, fuelCapacity: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, MILEAGE_OPTION.FUEL_CAPACITY),
      handleBlur: () => handleBlur(MILEAGE_OPTION.FUEL_CAPACITY),
      max: 100,
    },
    {
      id: 5,
      title: mileage.cylinders,
      name: MILEAGE_OPTION.CYLINDERS,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, cylinders: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, MILEAGE_OPTION.CYLINDERS),
      handleBlur: () => handleBlur(MILEAGE_OPTION.CYLINDERS),
      max: 10,
    },
    {
      id: 6,
      title: mileage.torque,
      name: MILEAGE_OPTION.TORQUE,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, torque: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, MILEAGE_OPTION.TORQUE),
      handleBlur: () => handleBlur(MILEAGE_OPTION.TORQUE),
    },
    {
      id: 7,
      title: mileage.torqueRpm,
      name: MILEAGE_OPTION.TORQUE_RPM,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, torqueRpm: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, MILEAGE_OPTION.TORQUE_RPM),
      handleBlur: () => handleBlur(MILEAGE_OPTION.TORQUE_RPM),
    },
    {
      id: 8,
      title: mileage.horsePower,
      name: MILEAGE_OPTION.HORSE_POWER,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, horsePower: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, MILEAGE_OPTION.HORSE_POWER),
      handleBlur: () => handleBlur(MILEAGE_OPTION.HORSE_POWER),
    },
    {
      id: 9,
      title: mileage.horsePowerRpm,
      name: MILEAGE_OPTION.HORSE_POWER_RPM,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, horsePowerRpm: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, MILEAGE_OPTION.HORSE_POWER_RPM),
      handleBlur: () => handleBlur(MILEAGE_OPTION.HORSE_POWER_RPM),
    },
  ]

  return (
    <Skeleton
      sliderItems={SLIDER_ITEMS}
      handleBack={handleBack}
      handleNext={handleSubmit}
    />
  )
}

export default Mileage
