import { nanoid } from 'nanoid'

import { Image } from 'components/Common/Carousel'

import { InventorySlugStatus } from 'models/status'

const editablePicturesStatus: string[] = [
  InventorySlugStatus.PENDING,
  InventorySlugStatus.AVAILABLE,
  InventorySlugStatus.COMING_SOON,
  InventorySlugStatus.TO_REVIEW,
]

// functions for inventory gallery
export const canDeletePictures = (imageList: Image[], status: string) => {
  if (status === InventorySlugStatus.PENDING) return true

  if (imageList.length > 2 && editablePicturesStatus.includes(status))
    return true

  return false
}

export const canEditPictures = (status: string) => {
  return !!editablePicturesStatus.includes(status)
}

export const createPictureList = (pictureList: string[]): Image[] => {
  return pictureList.map((picture) => {
    return {
      url: picture,
      id: nanoid(),
    }
  })
}
