import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { ApolloError, useMutation, useQuery } from '@apollo/client'

import { DetailHeader } from 'components/CarSettings/Common/Detail/DetailHeader'
import { DetailSubHeader } from 'components/CarSettings/Common/Detail/DetailSubHeader'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import DetailNavTab, { NavBarItem } from 'components/General/DetailNavTab'
import TabPanel from 'components/Inspection/Detail/TabPanel'
import UserInformation from 'components/UserManagement/Detail/UserInfo'

import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import { initialUserData } from 'constants/UserManagement/detail'
import { User } from 'context/UserContext'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import { Role } from 'models/role'
import { Option } from 'models/Select'
import {
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
  GenericUpdateVariable,
} from 'models/services/base'
import { Status } from 'models/services/status'
import {
  handleUpdateUserType,
  UpdateUserPayload,
  UserDetailData,
} from 'models/services/user'
import { generateTabItems } from 'utils/tabs'

import {
  GET_ASSIGNABLE_AREAS,
  GET_ASSIGNABLE_ROLES,
} from 'graphQL/UserManagement/Common/queries'
import { UPDATE_EMPLOYEE_BY_ID } from 'graphQL/UserManagement/Detail/mutations'
import { GET_EMPLOYEE_BY_ID } from 'graphQL/UserManagement/Detail/queries'

import { ContentContainer, Layout } from 'styles/inspection/detail'
import { colors } from 'styles/theme'

const UserManagemenDetailPage = () => {
  const { userId } = useParams<{ userId: string }>()
  const { show } = useNotification()
  const { text } = useTranslation(textFiles.USER_MANAGEMENT_DETAIL)
  const { general: translation, userInformation: userInformationText } = text
  const status: Status = {
    id: '',
    name: translation.status.active,
    backgroundColor: colors.cyan,
    textColor: colors.aquaBlue,
  }
  const [tab, setTab] = useState<number>(0)
  const history = useHistory()
  const [apolloError, setApolloError] = useState<ApolloError | null>(null)
  const [userData, setUserData] = useState<User>()
  const [roles, setRoles] = useState<Role[]>([])
  const [areas, setAreas] = useState<Option[]>([])
  const [initialData, setInitialData] = useState<UserDetailData>()

  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })
  const { loading: rolesLoading } = useQuery<
    GenericData<Role[]>,
    FilterInputVariable
  >(GET_ASSIGNABLE_ROLES, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted: (response) => {
      setRoles(response.data)
    },
  })

  const { loading: areasLoading } = useQuery<
    GenericData<Option[]>,
    FilterInputVariable
  >(GET_ASSIGNABLE_AREAS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setAreas(response.data)
    },
  })

  const { loading: userLoading } = useQuery<
    GenericData<User>,
    GenericInputVariable<string>
  >(GET_EMPLOYEE_BY_ID, {
    variables: {
      input: userId,
    },
    onCompleted(response) {
      setUserData(response.data)
    },
    onError(error) {
      setApolloError(error)
    },
  })

  const [updateUser, { loading: submitLoading }] = useMutation<
    GenericData<User>,
    GenericUpdateVariable<UpdateUserPayload>
  >(UPDATE_EMPLOYEE_BY_ID, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
    },
    onError(error) {
      show({
        message: error.message,
        updatedSeverity: 'error',
      })
    },
    refetchQueries: [GET_EMPLOYEE_BY_ID],
  })

  const handleUserDataChange = ({
    newContact,
    newPersonalInfo,
    newRoles,
  }: handleUpdateUserType) => {
    if (newContact && newPersonalInfo && newRoles) {
      setInitialData((prevState) => {
        if (prevState !== undefined) {
          return {
            ...prevState,
            contact: newContact,
            personalInfo: newPersonalInfo,
            roles: newRoles,
          }
        }
        return undefined
      })
    }

    if (userData) {
      updateUser({
        variables: {
          input: {
            where: { id: userData.id },
            data: {
              name: newPersonalInfo && String(newPersonalInfo[0].name),
              lastName: newPersonalInfo && String(newPersonalInfo[1].name),
              telephoneNumber:
                newContact && String(newContact[0].name)
                  ? String(newContact[0].name)
                  : undefined,
              roles: newRoles && newRoles?.map((role) => role.value),
            },
          },
        },
      })
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTab(value)
    history.replace(`#${initialItems[value].url}`)
  }

  useEffect(() => {
    if (history.location.hash) {
      let initialValue = 0
      const thisUrl = history.location.hash.split('#')[1]
      Object.keys(translation.tabs).forEach((key, index) => {
        if (key === thisUrl) {
          initialValue = index
        }
      })
      setTab(initialValue)
    }
  }, [history.location.hash, translation.tabs])

  useEffect(() => {
    if (userData) {
      setInitialData(initialUserData(userInformationText, userData))
    }
  }, [roles, userData, userInformationText])

  if (userLoading || rolesLoading || areasLoading)
    return (
      <LoadingAnimation
        showAnimation={userLoading || rolesLoading || areasLoading}
      />
    )

  if (apolloError) return <Redirect push to={routes.NOT_FOUND_ERROR} />

  return (
    <Layout>
      <DetailHeader
        title={`${userData?.name} ${userData?.lastName}`}
        backButtonText={translation.backButton}
        editable={false}
        status={status}
      />
      <DetailSubHeader text={translation.description} />
      <DetailNavTab
        tab={tab}
        handleTabChange={handleTabChange}
        items={initialItems}
      />
      {userInformationText && userData && roles && (
        <ContentContainer>
          <TabPanel value={tab} index={0}>
            {initialData && (
              <UserInformation
                loading={rolesLoading || areasLoading}
                userInformation={initialData}
                availableRoles={roles}
                availableAreas={areas}
                handleUpdateUser={handleUserDataChange}
                submitLoading={submitLoading}
              />
            )}
          </TabPanel>
        </ContentContainer>
      )}
    </Layout>
  )
}
export default UserManagemenDetailPage
