import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra'
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle'
import BusinessIcon from '@mui/icons-material/Business'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled'
import FlagIcon from '@mui/icons-material/Flag'
import HandymanIcon from '@mui/icons-material/Handyman'
import ListAltIcon from '@mui/icons-material/ListAlt'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PaymentsIcon from '@mui/icons-material/Payments'
import PersonIcon from '@mui/icons-material/Person'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import SpaceBarIcon from '@mui/icons-material/SpaceBar'
import SpeedIcon from '@mui/icons-material/Speed'
import StraightenIcon from '@mui/icons-material/Straighten'
import { Typography } from '@mui/material'
import { uploadImageService, uploadImagesService } from 'services/uploadImage'

import Skeleton from 'components/CarSettings/Common/Creation/Summary/Skeleton'
import Collapse from 'components/CarSettings/Trim/Creation/Summary/Collapse'
import Avatar from 'components/Common/Avatar'
import Box from 'components/Common/Box'
import ColorBox from 'components/Common/ColorBox'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import SummaryItem from 'components/Common/SummaryItem'
import FeatureList from 'components/Inventory/Common/FeatureSetCell/FeatureList'

import { INVENTORY_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useSetting from 'hooks/useSetting'
import useTranslation from 'hooks/useTranslation'
import { Option } from 'models/Select'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { CreateCarInput } from 'models/services/inventory/creation'
import { InventoryColor } from 'models/services/inventory/detail'
import { CarProviderEnum } from 'models/services/PreInventory/creation'
import { VehicleCreationProps } from 'utils/Inventory/creation'
import { formatPhoneNumber } from 'utils/phone'

import { CREATE_CAR } from 'graphQL/Inventory/Creation/mutation'

import { elementStyle, SectionContainer } from 'styles/creation'
import { colors, FONT_WEIGHT } from 'styles/theme'

import { calculateListingPrice, calculateTotalPrice } from '../Price'

const getColor = (colorList: InventoryColor[], color: string) => {
  const foundColor = colorList.find(
    (currentColor) => currentColor.value === color
  )

  return foundColor
}

const getOptions = (options: Option[], option: string) => {
  return options.find((currentOption) => currentOption.value === option)
}

const Summary = ({
  handleBack,
  colorList,
  vehicleData,
  countryVersionList,
  vehicleSelectOptions,
}: VehicleCreationProps) => {
  const { text } = useTranslation(textFiles.VEHICLE_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { show } = useNotification()
  const history = useHistory()
  const appSetting = useSetting()[2]
  const currency = appSetting ? appSetting.currency : null
  const priceCurrency = currency ? `${currency.code}` : ''

  const [updateLoading, setUpdateLoading] = useState<boolean>(false)

  const {
    summary: translation,
    providerInformation: providerText,
    vehiclePricing: pricingText,
  } = text
  const {
    subSteps: { fifthStep: measurementText },
  } = text
  const {
    vehicleInformation,
    providerInformation,
    vehiclePrice,
    vehiclePhotos,
  } = vehicleData
  const { firstStep, secondStep, thirdStep, fourthStep, fifthStep, sixthStep } =
    vehicleInformation
  const { make, model, trimLevel, vehicleYear, status } = firstStep
  const { mileage, vinNumber, chassisNumber, licensePlate, categories } =
    secondStep
  const { providerOption, curboSpot, dealer } = providerInformation
  const {
    exteriorPictures,
    interiorPictures,
    thumbnail,
    thumbnailPicture,
    exteriorPictureImages,
    interiorPictureImages,
  } = vehiclePhotos

  const [createCar, { loading: mutationLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateCarInput>
  >(CREATE_CAR, {
    onError() {
      show({
        message: translation.vehicleFail,
        updatedSeverity: 'error',
      })
    },
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: translation.vehicleSuccess,
      })
      history.push(INVENTORY_SUB_ROUTES.INVENTORY_LISTING)
    },
  })

  const exteriorColor = getColor(colorList, secondStep.exteriorColor)
  const interiorColor = getColor(colorList, secondStep.interiorColor)
  const countryVersion = getOptions(
    countryVersionList,
    secondStep.countryVersion
  )
  const transmission = getOptions(
    vehicleSelectOptions.transmissions,
    thirdStep?.transmission || ''
  )
  const bodyStyle = getOptions(
    vehicleSelectOptions.bodyStyles,
    thirdStep?.bodyStyle || ''
  )
  const driveTrain = getOptions(
    vehicleSelectOptions.driveTrains,
    thirdStep?.driveTrain || ''
  )
  const fuelType = getOptions(
    vehicleSelectOptions.fuelTypes,
    thirdStep?.fuelType || ''
  )

  const handleSubmit = async () => {
    if (thirdStep && fourthStep && fifthStep && sixthStep) {
      try {
        setUpdateLoading(true)

        const thumbnailImage = await uploadImageService(thumbnail, true)
        const exteriorImages = await uploadImagesService(exteriorPictures)
        const interiorImages = await uploadImagesService(interiorPictures)

        const trimId = firstStep.trimLevel ? String(firstStep.trimLevel.id) : ''

        createCar({
          variables: {
            input: {
              trimLevel: trimId,
              status: firstStep.status?.slug || '',
              mileage: Number(mileage),
              categories,
              interiorColor: secondStep.interiorColor,
              exteriorColor: secondStep.exteriorColor,
              vinNumber: vinNumber || undefined,
              chassisNumber: chassisNumber || undefined,
              licensePlate,
              countryVersion: secondStep.countryVersion,
              transmission: thirdStep.transmission,
              driveTrain: thirdStep.driveTrain,
              fuelType: thirdStep.fuelType,
              features: thirdStep.features,
              frontLegRoom: fourthStep.frontLegRoom,
              backLegRoom: fourthStep.backLegRoom,
              frontHeadRoom: fourthStep.frontHeadRoom,
              backHeadRoom: fourthStep.backHeadRoom,
              engineDisplacement: fourthStep.engineDisplacement,
              cargoCapacity: fourthStep.engineDisplacement,
              cargo: fourthStep.cargo,
              length: fifthStep.length,
              width: fifthStep.width,
              height: fifthStep.height,
              seats: fifthStep.seats,
              doors: fifthStep.doors,
              mpg: sixthStep.mpg,
              mpgCity: sixthStep.mpgCity,
              mpgHgw: sixthStep.mpgHgw,
              fuelCapacity: sixthStep.fuelCapacity,
              cylinders: sixthStep.cylinders,
              torque: sixthStep.torque,
              torqueRpm: sixthStep.torqueRpm,
              horsePower: sixthStep.horsePower,
              horsePowerRpm: sixthStep.horsePowerRpm,
              provider: String(providerOption.id),
              curboSpot: curboSpot?.id || '',
              dealer: dealer ? dealer.id : undefined,
              priceInfo: vehiclePrice,
              exteriorPictures:
                exteriorImages && exteriorImages.data
                  ? exteriorImages.data.urls
                  : [],
              interiorPictures:
                interiorImages && interiorImages.data
                  ? interiorImages.data.urls
                  : [],
              mainPicture: thumbnailImage ? thumbnailImage.data : '',
            },
          },
        })
        setUpdateLoading(false)
      } catch {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.uploadError,
        })
        setUpdateLoading(false)
      }
    }
  }

  const renderCategoryOption = (value: string): string => {
    return vehicleSelectOptions.categories.find(
      (category) => category.value === value
    )!.name
  }

  const renderFeatureOption = (value: string): string => {
    return vehicleSelectOptions.features.find(
      (feature) => feature.value === value
    )!.name
  }

  return (
    <>
      <LoadingAnimation
        showAnimation={updateLoading || mutationLoading}
        styles={{
          zIndex: 2,
          position: 'absolute',
          width: 'unset',
          height: 'unset',
        }}
      />
      <Box
        sx={{
          opacity: updateLoading || mutationLoading ? '0.5' : 'unset',
        }}
      >
        <Skeleton
          title={translation.title}
          description={translation.description}
          previousFunction={handleBack}
          nextFunction={handleSubmit}
          gridContainerStyles={{
            gridTemplateColumns: '100% !important',
            width: '100%',
          }}
          submitLoading={updateLoading || mutationLoading}
        >
          <Collapse
            defaultExpanded
            title={translation.vehicleInformation}
            sx={{ margin: '0px!important' }}
          >
            <SectionContainer>
              {make && (
                <SummaryItem
                  title={translation.make}
                  text={make.name}
                  icon={<DirectionsCarIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              )}
              {model && (
                <SummaryItem
                  title={translation.model}
                  text={model.name}
                  icon={<AirportShuttleIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              )}
              {trimLevel && (
                <SummaryItem
                  title={translation.trimName}
                  text={trimLevel.name}
                  icon={<ListAltIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              )}
              {vehicleYear && (
                <SummaryItem
                  title={translation.year}
                  text={vehicleYear.name}
                  icon={<CalendarMonthIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              )}
              {mileage && (
                <SummaryItem
                  title={translation.mileage}
                  text={mileage}
                  icon={<SpeedIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              )}
              {exteriorColor && (
                <SummaryItem
                  title={translation.exteriorColor}
                  text={exteriorColor.name}
                  icon={
                    <ColorBox
                      height={18}
                      width={18}
                      hexCode={exteriorColor.hexCode}
                      styles={{ marginRight: '0.5rem' }}
                    />
                  }
                  width="auto"
                  elementStyle={elementStyle}
                />
              )}
              {interiorColor && (
                <SummaryItem
                  title={translation.interiorColor}
                  text={interiorColor.name}
                  icon={
                    <ColorBox
                      height={18}
                      width={18}
                      hexCode={interiorColor.hexCode}
                      styles={{ marginRight: '0.5rem' }}
                    />
                  }
                  width="auto"
                  elementStyle={elementStyle}
                />
              )}
              {countryVersion && (
                <SummaryItem
                  title={translation.countryVersion}
                  text={countryVersion.name}
                  icon={<FlagIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              )}
              {chassisNumber && (
                <SummaryItem
                  title={translation.chassisNumber}
                  text={chassisNumber}
                  icon={<DirectionsCarFilledIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              )}
              {vinNumber && (
                <SummaryItem
                  title={translation.vinNumber}
                  text={vinNumber}
                  icon={<DirectionsCarFilledIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              )}
              {licensePlate && (
                <SummaryItem
                  title={translation.licensePlate}
                  text={licensePlate}
                  icon={<DirectionsCarIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              )}
              {status && (
                <SummaryItem
                  title={translation.status}
                  text={status.name}
                  icon={<CheckCircleIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              )}

              <Box marginBottom="1rem">
                <Typography marginBottom="1rem">
                  {translation.categories}
                </Typography>
                <FeatureList
                  renderOption={renderCategoryOption}
                  values={categories}
                />
              </Box>
            </SectionContainer>
          </Collapse>
          <Collapse
            title={translation.carSettings}
            sx={{ margin: '0px!important' }}
          >
            <SectionContainer>
              {transmission && (
                <SummaryItem
                  title={translation.transmission}
                  text={transmission.name}
                  icon={<HandymanIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              )}
              {bodyStyle && (
                <SummaryItem
                  title={translation.bodyStyle}
                  text={bodyStyle.name}
                  icon={<AirportShuttleIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              )}
              {driveTrain && (
                <SummaryItem
                  title={translation.driveTrain}
                  text={driveTrain.name}
                  icon={<DirectionsCarIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              )}
              {fuelType && (
                <SummaryItem
                  title={translation.fuelType}
                  text={fuelType.name}
                  icon={<LocalGasStationIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              )}
              <Box marginBottom="1rem">
                <Typography marginBottom="1rem">
                  {translation.featuresList}
                </Typography>
                <FeatureList
                  renderOption={renderFeatureOption}
                  values={thirdStep?.features || []}
                />
              </Box>
            </SectionContainer>
          </Collapse>
          {fourthStep ? (
            <Collapse
              title={translation.spacing}
              sx={{ margin: '0px!important' }}
            >
              <SectionContainer>
                <SummaryItem
                  title={translation.frontLegLabel}
                  text={fourthStep.frontLegRoom.toLocaleString('en-US')}
                  icon={<AirlineSeatReclineExtraIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={translation.frontHeadLabel}
                  text={fourthStep.frontHeadRoom.toLocaleString('en-US')}
                  icon={<StraightenIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={translation.engineDisplacementLabel}
                  text={fourthStep.engineDisplacement.toLocaleString('en-US')}
                  icon={<DirectionsCarIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={translation.cargoCapacityLabel}
                  text={fourthStep.cargoCapacity.toLocaleString('en-US')}
                  icon={<SpaceBarIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={translation.backLegLabel}
                  text={fourthStep.backLegRoom.toLocaleString('en-US')}
                  icon={<AirlineSeatReclineExtraIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={translation.backHeadLabel}
                  text={fourthStep.backHeadRoom.toLocaleString('en-US')}
                  icon={<StraightenIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={translation.cargoWeightLabel}
                  text={fourthStep.cargo.toLocaleString('en-US')}
                  icon={<DirectionsCarIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              </SectionContainer>
            </Collapse>
          ) : null}
          {fifthStep ? (
            <Collapse
              title={translation.carMeasurements}
              sx={{ margin: '0px!important' }}
            >
              <SectionContainer>
                <SummaryItem
                  title={measurementText.lengthLabel}
                  text={fifthStep.length.toLocaleString('en-US')}
                  icon={<StraightenIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={measurementText.widthLabel}
                  text={fifthStep.width.toLocaleString('en-US')}
                  icon={<StraightenIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={measurementText.heightLabel}
                  text={fifthStep.height.toLocaleString('en-US')}
                  icon={<StraightenIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={measurementText.seatsLabel}
                  text={fifthStep.seats.toLocaleString('en-US')}
                  icon={<AirlineSeatReclineExtraIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={measurementText.doorsLabel}
                  text={fifthStep.doors.toLocaleString('en-US')}
                  icon={<DirectionsCarIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              </SectionContainer>
            </Collapse>
          ) : null}
          {sixthStep ? (
            <Collapse
              title={translation.mileagePower}
              sx={{ margin: '0px!important' }}
            >
              <SectionContainer
                sx={{
                  '> div': {
                    width: '200px',
                  },
                }}
              >
                <SummaryItem
                  title={translation.mpgLabel}
                  text={sixthStep.mpg.toLocaleString('en-US')}
                  icon={<SpeedIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={translation.cityMpgLabel}
                  text={sixthStep.mpgCity.toLocaleString('en-US')}
                  icon={<SpeedIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={translation.highwayMpgLabel}
                  text={sixthStep.mpgHgw.toLocaleString('en-US')}
                  icon={<SpeedIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={translation.fuelCapacityLabel}
                  text={sixthStep.fuelCapacity.toLocaleString('en-US')}
                  icon={<LocalGasStationIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={translation.torqueLabel}
                  text={sixthStep.torque.toLocaleString('en-US')}
                  icon={<DirectionsCarIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={translation.torqueRpmLabel}
                  text={sixthStep.torqueRpm.toLocaleString('en-US')}
                  icon={<DirectionsCarIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={translation.horsePowerLabel}
                  text={sixthStep.horsePower.toLocaleString('en-US')}
                  icon={<DirectionsCarIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={translation.horsePowerRpmLabel}
                  text={sixthStep.horsePowerRpm.toLocaleString('en-US')}
                  icon={<DirectionsCarIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={translation.cylindersLabel}
                  text={sixthStep.cylinders.toLocaleString('en-US')}
                  icon={<DirectionsCarIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
              </SectionContainer>
            </Collapse>
          ) : null}
          {providerOption.id === CarProviderEnum.CURBO_SPOT && curboSpot ? (
            <Collapse
              title={translation.providerInformation}
              sx={{ margin: '0px!important' }}
            >
              <SectionContainer>
                <SummaryItem
                  title={providerText.typeLabel}
                  text={providerOption.name}
                  icon={<BusinessIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={providerText.curboSpotLabel}
                  text={curboSpot.name}
                  icon={<BusinessIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={providerText.curboSpotPhoneNumberLabel}
                  text={formatPhoneNumber(curboSpot.telephoneNumber)}
                  icon={<PhoneEnabledIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={providerText.curboSpotLocationLabel}
                  text={curboSpot.address}
                  icon={<LocationOnIcon />}
                  width="auto"
                  elementStyle={{ ...elementStyle, maxHeight: 'unset' }}
                />
              </SectionContainer>
            </Collapse>
          ) : null}
          {providerOption.id === CarProviderEnum.DEALER &&
          curboSpot &&
          dealer ? (
            <Collapse
              title={translation.providerInformation}
              sx={{ margin: '0px!important' }}
            >
              <SectionContainer>
                <SummaryItem
                  title={providerText.typeLabel}
                  text={providerOption.name}
                  icon={<BusinessIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={providerText.dealerNameLabel}
                  text={dealer.name}
                  icon={<BusinessIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={providerText.dealerPhoneNumberLabel}
                  text={formatPhoneNumber(dealer.telephoneNumber)}
                  icon={<PhoneEnabledIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                {dealer.dealerContacts.length > 0 ? (
                  <>
                    <SummaryItem
                      title={providerText.representativeNameLabel}
                      text={dealer.dealerContacts[0].agent}
                      icon={<PersonIcon />}
                      width="auto"
                      elementStyle={elementStyle}
                    />
                    <SummaryItem
                      title={providerText.representativePhoneNumberLabel}
                      text={formatPhoneNumber(
                        dealer.dealerContacts[0].telephoneNumber
                      )}
                      icon={<PhoneEnabledIcon />}
                      width="auto"
                      elementStyle={elementStyle}
                    />
                  </>
                ) : null}
                <SummaryItem
                  title={providerText.curboSpotLabel}
                  text={curboSpot.name}
                  icon={<BusinessIcon />}
                  width="auto"
                  elementStyle={elementStyle}
                />
                <SummaryItem
                  title={providerText.dealerLocationLabel}
                  text={dealer.address}
                  icon={<LocationOnIcon />}
                  width="auto"
                  elementStyle={{ ...elementStyle, maxHeight: 'unset' }}
                />
              </SectionContainer>
            </Collapse>
          ) : null}

          <Collapse
            title={translation.pricingInformation}
            sx={{ margin: '0px!important' }}
          >
            <SectionContainer>
              <SummaryItem
                title={pricingText.basePriceLabel}
                text={`${vehiclePrice.basePrice.toLocaleString(
                  'en-US'
                )} ${priceCurrency}`}
                icon={<PaymentsIcon />}
                width="auto"
                elementStyle={elementStyle}
              />
              <SummaryItem
                title={pricingText.curboCertificationLabel}
                text={`${vehiclePrice.fee.toLocaleString(
                  'en-US'
                )} ${priceCurrency}`}
                icon={<PaymentsIcon />}
                width="auto"
                elementStyle={elementStyle}
              />
              <SummaryItem
                title={pricingText.transferCostLabel}
                text={`${vehiclePrice.transfer.toLocaleString(
                  'en-US'
                )} ${priceCurrency}`}
                icon={<PaymentsIcon />}
                width="auto"
                elementStyle={elementStyle}
              />
              <SummaryItem
                title={pricingText.licensePlateLabel}
                text={`${vehiclePrice.licensePlate.toLocaleString(
                  'en-US'
                )} ${priceCurrency}`}
                icon={<PaymentsIcon />}
                width="auto"
                elementStyle={elementStyle}
              />
              <SummaryItem
                title={pricingText.warrantyPriceLabel}
                text={`${vehiclePrice.warranty.toLocaleString(
                  'en-US'
                )} ${priceCurrency}`}
                icon={<PaymentsIcon />}
                width="auto"
                elementStyle={elementStyle}
              />
              <SummaryItem
                title={pricingText.repairCostsLabel}
                text={`${vehiclePrice.repairCost.toLocaleString(
                  'en-US'
                )} ${priceCurrency}`}
                icon={<PaymentsIcon />}
                width="auto"
                elementStyle={elementStyle}
              />
              <SummaryItem
                title={pricingText.listingPriceLabel}
                text={`${calculateListingPrice(vehiclePrice).toLocaleString(
                  'en-US'
                )} ${priceCurrency}`}
                icon={<PaymentsIcon />}
                width="auto"
                elementStyle={elementStyle}
              />
              <SummaryItem
                title={pricingText.totalPriceLabel}
                text={`${calculateTotalPrice(vehiclePrice).toLocaleString(
                  'en-US'
                )} ${priceCurrency}`}
                icon={<PaymentsIcon />}
                width="auto"
                elementStyle={elementStyle}
              />
            </SectionContainer>
          </Collapse>

          <Collapse
            title={translation.gallery}
            sx={{ margin: '0px!important' }}
          >
            <SectionContainer>
              <Box marginBottom="1rem">
                <Typography marginBottom="1rem">
                  {translation.thumbnail}
                </Typography>
                <Avatar
                  height={130}
                  width={130}
                  image={thumbnailPicture}
                  variant="rounded"
                />
              </Box>
              <Box marginBottom="1rem">
                <Typography marginBottom="1rem">
                  {translation.pictures}
                </Typography>
                <Box alignItems="flex-end" display="flex">
                  {interiorPictureImages.length > 0 ? (
                    <Avatar
                      height={130}
                      width={130}
                      image={exteriorPictureImages[0].url}
                      variant="rounded"
                    />
                  ) : null}
                  <Box
                    height={120}
                    width={120}
                    sx={{
                      alignItems: 'center',
                      backgroundColor: colors.black,
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      marginLeft: '1rem',
                      opacity: 0.7,
                      backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${interiorPictureImages[1].url})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    <Typography
                      color={colors.commonWhite}
                      fontWeight={FONT_WEIGHT.MEDIUM}
                      variant="h2"
                    >
                      +
                      {interiorPictureImages.length +
                        exteriorPictureImages.length -
                        1}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </SectionContainer>
          </Collapse>
        </Skeleton>
      </Box>
    </>
  )
}

export default Summary
