import React, { FunctionComponent, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, InputBaseComponentProps } from '@mui/material'

import RemoveItemPopper from 'components/General/Translation/RemoveItemPopper'
import NumberValueInput from 'components/Inventory/Common/NumberValueInput'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { colors } from 'styles/theme'

import { StyledSquareIcon, StyledStripe, StyledTextField } from './style'

type StripeProps = {
  name: string
  value?: number
  id: string
  handleRemove: (idx: string) => void
  handleEditItem: (idx: string, text: string, number?: number) => void
  handleEditMode: React.Dispatch<React.SetStateAction<boolean>>
}

const Stripe = ({
  name,
  value,
  id,
  handleRemove,
  handleEditItem,
  handleEditMode,
}: StripeProps) => {
  const [edit, setEdit] = useState<boolean>(false)
  const [newText, setNewText] = useState<string>(name)
  const [newValue, setNewValue] = useState<number>(Number(value))
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const { text: translation } = useTranslation(textFiles.GENERAL)
  const { motives } = translation

  const handleEdit = () => {
    if (!edit) {
      setEdit(true)
      handleEditMode(true)
    } else {
      if (value || value === 0) handleEditItem(id, newText, newValue)
      else handleEditItem(id, newText)
      setEdit(false)
      handleEditMode(false)
    }
  }

  const handleClosePopper = () => {
    setAnchorEl(null)
    setEdit(false)
    handleEditMode(false)
  }

  const handleDelete = () => {
    handleRemove(id)
    handleClosePopper()
  }

  const handleBlur = () => {
    if (!newValue || newValue <= 0) {
      setNewValue(0)
    } else if (newValue > 100) {
      setNewValue(100)
    }
  }

  return (
    <>
      <StyledStripe>
        <section>
          <StyledSquareIcon />
          {!edit ? (
            <p>{name}</p>
          ) : (
            <StyledTextField
              placeholder={motives.vehiclePlaceholder}
              variant="outlined"
              value={newText}
              onChange={(e) => {
                setNewText(e.target.value)
              }}
              sx={{
                height: 25,
                width: value || value === 0 ? 130 : '30rem',
                marginRight: '10px',
                input: {
                  height: 25,
                  width: value || value === 0 ? 130 : '30rem',
                },
              }}
              name={`edit-${id}`}
            />
          )}
          {value || value === 0 ? (
            <>
              {!edit ? (
                <>{` - ${value}`}</>
              ) : (
                <StyledTextField
                  placeholder={motives.valuePlaceholder}
                  variant="outlined"
                  value={newValue}
                  onChange={(e) => {
                    setNewValue(Number(e.target.value))
                  }}
                  onBlur={handleBlur}
                  InputProps={{
                    inputComponent:
                      NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                  }}
                  sx={{
                    height: 25,
                    width: 65,
                    input: {
                      height: 25,
                      width: 65,
                    },
                  }}
                  name={`edit-value-${id}-input`}
                />
              )}
            </>
          ) : null}
        </section>
        <section>
          <IconButton
            onClick={handleEdit}
            data-cy={!edit ? `edit-${id}-button` : `confirm-${id}-button`}
          >
            {!edit ? (
              <EditIcon sx={{ color: colors.placeholderGray, fontSize: 14 }} />
            ) : (
              <CheckIcon sx={{ color: colors.placeholderGray, fontSize: 14 }} />
            )}
          </IconButton>
          <IconButton
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              edit
                ? setEdit(false)
                : setAnchorEl(anchorEl ? null : event.currentTarget)
            }
            data-cy={`delete-${id}-button`}
          >
            <ClearIcon sx={{ color: colors.placeholderGray, fontSize: 14 }} />
          </IconButton>
        </section>
      </StyledStripe>
      <RemoveItemPopper
        anchorEl={anchorEl}
        open={open}
        handleClosePopper={handleClosePopper}
        handleConfirmRemove={handleDelete}
      />
    </>
  )
}

export default Stripe
