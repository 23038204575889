import { gql } from '@apollo/client'

export const LIST_STATES = gql`
  query listStates($input: FilterInput!) {
    listStates(input: $input) {
      count
      data {
        id
        name
        createdAt
        status
      }
    }
  }
`
