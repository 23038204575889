import { Button, buttonClasses, styled } from '@mui/material'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledButton = styled(Button)({
  // buttonType = "info" style
  border: `2px solid ${colors.transparent}`,
  color: colors.commonBlack,
  ':hover': {
    backgroundColor: colors.lightWhite,
    border: `2px solid ${colors.transparent}`,
    color: colors.commonBlack,
  },
  // sizes styles
  [`&.${buttonClasses.sizeLarge}`]: {
    borderRadius: '10px',
    fontSize: '25px',
    fontWeight: FONT_WEIGHT.BOLD,
    height: '88px',
    padding: '0px 24px',
  },
  [`&.${buttonClasses.sizeMedium}`]: {
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.BOLD,
    height: '48px',
    padding: '0px 12px',
  },
  [`&.${buttonClasses.sizeSmall}`]: {
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.REGULAR,
    height: '35px',
    padding: '0px 12px',
  },
  [`&.${buttonClasses.textSizeSmall}`]: {
    fontSize: '12px',
    height: 'auto',
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  // variant = "contained"
  [`&.${buttonClasses.containedPrimary}`]: {
    border: `2px solid ${colors.transparent}`,
    color: colors.commonWhite,
    ':hover': {
      backgroundColor: colors.lightBlue,
      border: `2px solid ${colors.lightBlue}`,
    },
  },
  [`&.${buttonClasses.containedSecondary}`]: {
    border: `2px solid ${colors.blue}`,
    color: colors.blue,
    ':hover': {
      backgroundColor: colors.transparent,
      border: `2px solid ${colors.lightBlue}`,
      color: colors.lightBlue,
    },
  },
  // variant = "text"
  [`&.${buttonClasses.textPrimary}`]: {
    border: 'none',
    color: colors.blue,
    ':hover': {
      backgroundColor: colors.transparent,
      color: colors.lightBlue,
    },
  },
  [`&.${buttonClasses.textSecondary}`]: {
    border: 'none',
    color: colors.blue,
    ':hover': {
      backgroundColor: colors.transparent,
      color: colors.lightBlue,
    },
  },
  // disabled
  ':disabled': {
    border: `2px solid ${colors.transparent}`,
    color: colors.gray,
  },
})
