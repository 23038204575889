import { gql } from '@apollo/client'

export const GET_BRAND_BY_ID = gql`
  query getBrandById($input: ID!) {
    data: getBrandById(id: $input) {
      icon
      name
      status
    }
  }
`
