import {
  Avatar,
  avatarClasses,
  buttonClasses,
  IconButton,
  styled,
} from '@mui/material'

import { colors } from 'styles/theme'

type AvatarSizeProps = {
  width?: string | number
  height?: string | number
}

export const StyledAvatar = styled(Avatar)<AvatarSizeProps>(
  ({ height, width }) => ({
    height: height || '58px',
    width: width || '58px',
    [`&.${avatarClasses.rounded}`]: {
      borderRadius: '8px',
    },
  })
)

export const StyledButton = styled(IconButton)({
  width: '30px',
  height: '30px',
  position: 'absolute',
  minWidth: 'unset',
  display: 'none',
  bottom: '5%',
  right: '5%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.background,
  borderRadius: '4px',
  cursor: 'pointer',
  zIndex: '5',
  border: 'none',
  transition: '.5s',
  ':hover': {
    backgroundColor: colors.lightBlue,
    border: `2px solid ${colors.lightBlue}`,
    '& svg': {
      color: colors.lightWhite,
    },
  },
  '& svg': {
    color: colors.gray,
  },

  [`&.${buttonClasses.containedPrimary}`]: {
    border: `2px solid ${colors.transparent}`,
    color: colors.transparent,
  },
})

export const StyledSection = styled('section')({
  position: 'relative',
  ':hover': {
    a: { display: 'flex' },
    button: { display: 'flex' },
  },
})
