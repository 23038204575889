import { Card, formControlClasses, sliderClasses, styled } from '@mui/material'

import Container from 'components/Common/Container'
import TextField from 'components/Common/TextField'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledContainer = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '332px',
  height: '165px',
})

export const StyledTextFieldContainer = styled(Container)({
  padding: 0,
  borderRadius: 0,
  '> p': {
    fontSize: '12px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
  '> div:nth-of-type(2)': {
    position: 'static',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '0.5rem',
    [`.${sliderClasses.rail}`]: {
      height: 10,
      color: colors.gray,
    },
    [`.${sliderClasses.track}`]: {
      height: 10,
    },
  },
})

export const StyledTextField = styled(TextField)({
  border: `1px solid ${colors.lightGray}`,
  borderRadius: '4px',
  opacity: 1,
  height: '38px',
  width: '92px !important',
  '& div': {
    color: colors.placeholderGray,
    fontWeight: FONT_WEIGHT.BOLD,
    height: '100%',
    fontSize: '24px',
  },
  '& div fieldset': {
    border: 'none',
  },
  input: {
    height: '38px',
    paddingTop: 15,
    '&:-internal-autofill-selected': {
      height: '38px',
      backgroundColor: colors.placeholderGray,
    },
  },
  'input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: '0',
  },
})
