import React from 'react'
import { Typography } from '@mui/material'

import Box from 'components/Common/Box'

import { FONT_WEIGHT } from 'styles/theme'

import Tooltip from '.'

type BiggerFontTooltipProps = { text: string }

const BiggerFontTooltip = ({ text }: BiggerFontTooltipProps) => (
  <Tooltip>
    <Box display="flex" alignItems="center" height="100%">
      <Typography width="auto" fontWeight={FONT_WEIGHT.REGULAR} fontSize="14px">
        {text}
      </Typography>
    </Box>
  </Tooltip>
)

export default BiggerFontTooltip
