import { gql } from '@apollo/client'

export const GET_TRANSMISSION_BY_ID = gql`
  query getTransmissionById($input: ID!) {
    data: getTransmissionById(id: $input) {
      nameTranslations {
        en
        es
      }
      name
      status
    }
  }
`
