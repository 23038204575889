import { HistoryLogType } from 'models/historyLog'
import { PublicationStatus } from 'models/status'
/**
 *
 * This is mockup data, used to simulate real usage:
 * You need an array of objects, each with a name,content, and timestamp property.
 */

const dummyDate = new Date('March 5, 2022 08:24:00')

export const dummyHistoryLogData: HistoryLogType[] = [
  {
    id: 1,
    name: 'John Doe',
    timestamp: dummyDate,
    content: {
      entity: 'carSettings',
      valueChanged: 'status',
      value: {
        before: PublicationStatus.UNPUBLISHED,
        after: PublicationStatus.PUBLISHED,
      },
    },
  },
  {
    id: 2,
    name: 'John Doe',
    timestamp: dummyDate,
    content: {
      entity: 'carSettings',
      valueChanged: 'name',
      value: { before: 'Pablo', after: 'Pedro' },
    },
  },
  {
    id: 3,
    name: 'John Doe',
    timestamp: dummyDate,
    content: {
      entity: 'carSettings',
      valueChanged: 'name',
    },
  },
  {
    id: 4,
    name: 'John Doe',
    timestamp: dummyDate,
    content: {
      entity: 'carSettings',
      valueChanged: 'status',
    },
  },
  {
    id: 5,
    name: 'John Doe',
    timestamp: dummyDate,
    content: {
      entity: 'carSettings',
      valueChanged: 'status',
      value: {
        before: PublicationStatus.UNPUBLISHED,
        after: PublicationStatus.PUBLISHED,
      },
    },
  },
  {
    id: 6,
    name: 'John Doe',
    timestamp: dummyDate,
    content: {
      entity: 'carSettings',
      valueChanged: 'status',
      value: {
        before: PublicationStatus.UNPUBLISHED,
        after: PublicationStatus.PUBLISHED,
      },
    },
  },
  {
    id: 7,
    name: 'John Doe',
    timestamp: dummyDate,
    content: {
      entity: 'carSettings',
      valueChanged: 'status',
      value: {
        before: PublicationStatus.UNPUBLISHED,
        after: PublicationStatus.PUBLISHED,
      },
    },
  },
  {
    id: 8,
    name: 'John Doe',
    timestamp: dummyDate,
    content: {
      entity: 'carSettings',
      valueChanged: 'status',
      value: {
        before: PublicationStatus.UNPUBLISHED,
        after: PublicationStatus.PUBLISHED,
      },
    },
  },
  {
    id: 9,
    name: 'John Doe',
    timestamp: dummyDate,
    content: {
      entity: 'carSettings',
      valueChanged: 'status',
      value: {
        before: PublicationStatus.UNPUBLISHED,
        after: PublicationStatus.PUBLISHED,
      },
    },
  },
]
