import React, { createContext, useEffect, useState } from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import {
  dateLocalesMap,
  getSelectedLanguageOptions,
} from 'constants/dateLocales'
import { LANGUAGE_KEY } from 'constants/language'
import { Language, Locale } from 'models/language'
import { getPreferredLanguage } from 'utils/language'

const initialState: Locale = {
  selectedLanguage: { name: 'Español', code: 'es' },
  updateLanguage: () => null,
}

export const LocaleContext = createContext(initialState)

type LocaleProps = {
  children: React.ReactNode
}

export const LocaleProvider: React.FC<LocaleProps> = ({
  children,
}: LocaleProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    getPreferredLanguage()
  )

  const updateLanguage = (language: Language) => {
    setSelectedLanguage(language)
    localStorage.setItem(LANGUAGE_KEY, JSON.stringify(language))
  }

  useEffect(() => {
    getSelectedLanguageOptions(selectedLanguage)
  }, [selectedLanguage])

  return (
    <LocaleContext.Provider
      value={{
        selectedLanguage,
        updateLanguage,
      }}
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={dateLocalesMap[selectedLanguage.code]}
      >
        {children}
      </LocalizationProvider>
    </LocaleContext.Provider>
  )
}
