import { inputBaseClasses, selectClasses, styled } from '@mui/material'

import TextField from 'components/Common/TextField'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledTextField = styled(TextField)({
  width: '90%',
  height: '26px',
  borderRadius: '4px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.gray,
  div: {
    height: '100%',
    fontSize: '14px',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },

  input: {
    height: '26px',
    fontSize: '14px',
    padding: '0 14px',
  },
})
