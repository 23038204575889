import React from 'react'

import ToggleButton from 'components/Common/ToggleButton'

import { StyledContainer } from '../style'

type ToggleProps = {
  title: string
  description: string
  status: boolean
  handleStatus:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((value: boolean) => void)
  width?: string | number
  /*
   * Adds cypress tag to toggle buttons
   */
  testId?: string
}

/*
This component is used in any of the information step of a setting creation process, 
includes a container with toggle buttons of Yes or No
*/

const ToggleContainer = ({
  title,
  description,
  status,
  width,
  handleStatus,
  testId,
}: ToggleProps) => {
  return (
    <StyledContainer title={title} description={description} width={width}>
      <ToggleButton
        value={status}
        handleValueChange={handleStatus}
        testId={testId}
      />
    </StyledContainer>
  )
}

export default ToggleContainer
