import React, { FunctionComponent } from 'react'
import NumberFormat from 'react-number-format'
import { InputBaseComponentProps } from '@mui/material'
import Slider from '@mui/material/Slider'

import {
  MEASUREMENT_OPTION,
  MILEAGE_OPTION,
  SPACING_OPTION,
} from 'constants/trim'
import {
  MeasurementInformationModel,
  MileageInformationModel,
  SpacingInformationModel,
} from 'models/trimCreation'

import { StyledTextField, StyledTextFieldContainer } from './style'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom = React.forwardRef<NumberFormat<number>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        thousandSeparator
        isNumericString
        allowNegative={false}
      />
    )
  }
)

export type SliderProps = {
  title: string
  name: MILEAGE_OPTION | SPACING_OPTION | MEASUREMENT_OPTION
  info:
    | MileageInformationModel
    | MeasurementInformationModel
    | SpacingInformationModel
  setInfo: (event: Event, newValue: number | number[]) => void
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  handleBlur: () => void
  max?: number
}

const SliderContainer = ({
  title,
  name,
  info,
  max,
  setInfo,
  handleInputChange,
  handleBlur,
}: SliderProps) => {
  const MAX = 1000
  const MIN = 1
  return (
    <StyledTextFieldContainer title={title}>
      <Slider
        value={info[name]}
        onChange={setInfo}
        max={max || MAX}
        min={MIN}
        aria-labelledby="input-slider"
        sx={{
          width: '65%',
          marginLeft: '10px',
        }}
      />
      <StyledTextField
        variant="outlined"
        name={name}
        value={info[name]}
        onChange={handleInputChange}
        onBlur={handleBlur}
        InputProps={{
          inputComponent:
            NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          'data-cy': `${name}-input`,
        }}
        sx={{
          width: '25% !important',
        }}
      />
    </StyledTextFieldContainer>
  )
}

export default SliderContainer
