import { BarStructureType } from 'components/Common/Charts/BarChart'

import { Activity } from 'models/Activity'
import { Inspector } from 'models/inspector'
import {
  InspectionsApprovementTextType,
  InspectionSingleBarTextType,
} from 'models/text/Dashboard'

export const dummyActivities: Activity[] = [
  {
    img: '',
    name: 'John Doe',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel lorem non magna convallis commodo. Aliquam non iaculis urna. Integer sed mollis libero.',
    hour: new Date(512969520900),
    id: '01',
  },
  {
    img: '',
    name: 'John Doe',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel lorem non magna convallis commodo. Aliquam non iaculis urna. Integer sed mollis libero.',
    hour: new Date(),
    id: '02',
  },
  {
    img: '',
    name: 'John Doe',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel lorem non magna convallis commodo. Aliquam non iaculis urna. Integer sed mollis libero.',
    hour: new Date(512979520900),
    id: '03',
  },
  {
    img: '',
    name: 'John Doe',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel lorem non magna convallis commodo. Aliquam non iaculis urna. Integer sed mollis libero.',
    id: '04',
    hour: new Date(512979980900),
  },
  {
    img: '',
    name: 'John Doe',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel lorem non magna convallis commodo. Aliquam non iaculis urna. Integer sed mollis libero.',
    id: '05',
    hour: new Date(51979980900),
  },
  {
    img: '',
    name: 'John Doe',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel lorem non magna convallis commodo. Aliquam non iaculis urna. Integer sed mollis libero.',
    id: '06',
    hour: new Date(),
  },
  {
    img: '',
    name: 'John Doe',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel lorem non magna convallis commodo. Aliquam non iaculis urna. Integer sed mollis libero.',
    id: '07',
    hour: new Date(),
  },
  {
    img: '',
    name: 'John Doe',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel lorem non magna convallis commodo. Aliquam non iaculis urna. Integer sed mollis libero.',
    id: '08',
    hour: new Date(),
  },
  {
    img: '',
    name: 'John Doe',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel lorem non magna convallis commodo. Aliquam non iaculis urna. Integer sed mollis libero.',
    id: '09',
    hour: new Date(),
  },
  {
    img: '',
    name: 'John Doe',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel lorem non magna convallis commodo. Aliquam non iaculis urna. Integer sed mollis libero.',
    id: '001',
    hour: new Date(),
  },
  {
    img: '',
    name: 'John Doe',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel lorem non magna convallis commodo. Aliquam non iaculis urna. Integer sed mollis libero.',
    id: '023',
    hour: new Date(),
  },
  {
    img: '',
    name: 'John Doe',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel lorem non magna convallis commodo. Aliquam non iaculis urna. Integer sed mollis libero.',
    id: '013',
    hour: new Date(),
  },
]

export const dummyInspectors: Inspector[] = [
  {
    id: 'insp-1',
    image: '',
    name: 'Wanderlin',
    lastName: 'Henriquez',
    scores: {
      monthly: [6, 6],
      weekly: [6, 6],
      daily: [6, 6],
      lastMonth: [6, 6],
    },
    inspectionTotal: 19,
  },
  {
    id: 'insp-2',
    image: '',
    name: 'Johan',
    lastName: 'Sebastián',
    scores: {
      monthly: [2, 6],
      weekly: [6, 6],
      daily: [6, 6],
      lastMonth: [6, 6],
    },
    inspectionTotal: 2,
  },
  {
    id: 'insp-3',
    image: '',
    name: 'Wanderlin',
    lastName: 'Henriquez',
    scores: {
      monthly: [3, 6],
      weekly: [6, 6],
      daily: [6, 6],
      lastMonth: [6, 6],
    },
    inspectionTotal: 3,
  },
  {
    id: 'insp-4',
    image: '',
    name: 'Vanessa',
    lastName: 'Wright',
    scores: {
      monthly: [38, 45],
      weekly: [3, 6],
      daily: [0, 1],
      lastMonth: [45, 45],
    },
    inspectionTotal: 3,
  },
]

export const createInspectionApprovementStructure = (
  text: InspectionsApprovementTextType
): BarStructureType[] => {
  return [
    {
      name: text.approvedInspectionsText,
      stackId: '1',
      dataKey: 'approved',
    },
    {
      name: text.rejectedInspectionsText,
      stackId: '1',
      dataKey: 'rejected',
    },
  ]
}

export const inspectionsSourceStructure: BarStructureType[] = [
  {
    name: 'FMC',
    stackId: '1',
    dataKey: 'fmc',
  },
  {
    name: 'SMC',
    stackId: '1',
    dataKey: 'smc',
  },
  {
    name: 'Dealer',
    stackId: '1',
    dataKey: 'dealer',
  },
  {
    name: 'Curbo',
    stackId: '1',
    dataKey: 'curbo',
  },
]

export const createInspectionSingleBarStructure = (
  text: InspectionSingleBarTextType
): BarStructureType[] => {
  return [
    {
      name: text.percentageText,
      dataKey: 'percentage',
    },
  ]
}

/**
 * Constants depicting the amount of rows each graph in the dashboard should have.
 * These are used for pagination purposes, so that we know how many pages we must add
 * when rendering a paginated graph.
 */

export const rejectedInspectionsDistributionRows = 9

export const completedInspectionsCountByInspectorRows = 6

export const inspectionsByInspectorAndOriginRows = 7

export const getTotalPages = (totalCount: number, rows: number) => {
  return Math.ceil(totalCount / rows)
}
