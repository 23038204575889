import { gql } from '@apollo/client'

export const GET_SETTING = gql`
  query getSetting {
    data: getSetting {
      website
      country {
        countryCode
        website
        flag
        name
      }
      currency {
        code
        symbol
        name
      }
    }
  }
`

export const GET_BACK_OFFICE_COUNTRY_CONFIG = gql`
  query getBackOfficeCountryConfig {
    data: getBackOfficeCountryConfig {
      countryCode
      website
      flag
      name
    }
  }
`

export const FULL_TEST_DRIVE_HOUR_TIME_FRAGMENT = gql`
  fragment FullTestDriveHourTimeFragment on TestDriveHours {
    am
    continentalTime
    id
    name
    slug
  }
`

export const FULL_TINSPECTION_HOUR_TIME_FRAGMENT = gql`
  fragment FullInspectionHourTimeFragment on InspectionHours {
    am
    continentalTime
    id
    name
    slug
  }
`

export const GET_TEST_DRIVE_HOURS = gql`
  ${FULL_TEST_DRIVE_HOUR_TIME_FRAGMENT}
  query getTestDriveHours($input: FilterInput) {
    data: getTestDriveHours(input: $input) {
      ...FullTestDriveHourTimeFragment
    }
  }
`

export const GET_INSPECTION_HOURS = gql`
  ${FULL_TINSPECTION_HOUR_TIME_FRAGMENT}
  query getInspectionHours($input: FilterInput) {
    data: getInspectionHours(input: $input) {
      ...FullInspectionHourTimeFragment
    }
  }
`
