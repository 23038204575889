import EntityInfo from 'components/CarSettings/BlackList/Creation/EntityInfo'
import Summary from 'components/CarSettings/BlackList/Creation/Summary'
import { StepperModel } from 'components/General/Stepper'

import {
  EntityCreationAction,
  EntityCreationModel,
} from 'models/blackListCreation'
import { StepperTextType } from 'models/text/General'

export type EntityCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  entityData: EntityCreationModel
  submitLoading: boolean
  updateEntityData: (action: EntityCreationAction) => void
}

export const INITIAL_ENTITY_CREATION_STEPS: Array<
  React.FC<EntityCreationProps>
> = [EntityInfo, Summary]

export const createEntityStepperItems = (
  text: StepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep,
    },
    { label: text.secondStep },
  ]

  return stepperItems
}
