import React, { useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { Typography } from '@mui/material'

import { DetailSubHeader } from 'components/CarSettings/Common/Detail/DetailSubHeader'
import StatusChangePopper from 'components/CarSettings/Common/Detail/StatusChangePopper'
import Box from 'components/Common/Box'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import Tag from 'components/Common/Tag'
import DetailNavTab, { NavBarItem } from 'components/General/DetailNavTab'
import TabPanel from 'components/Inspection/Detail/TabPanel'
import CityInformation from 'components/Operations/City/Detail/CityInfo'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import { Option } from 'models/Select'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import {
  CityDetailDataType,
  UpdateCityInput,
} from 'models/services/operations/city'
import { PublicationStatus } from 'models/status'
import { validateGraphQLErrorCode } from 'utils/error'
import { generateTabItems } from 'utils/tabs'

import { GET_STATES } from 'graphQL/Common/State/queries'
import { UPDATE_CITY } from 'graphQL/Operations/City/Detail/mutations'
import { GET_CITY_BY_ID } from 'graphQL/Operations/City/Detail/queries'

import { ContentContainer, Layout, StyledLink } from 'styles/inspection/detail'
import { colors } from 'styles/theme'

const CityDetailPage = () => {
  const { cityId } = useParams<{ cityId: string }>()

  const [tab] = useState<number>(0)
  const [status, setStatus] = useState<PublicationStatus>(
    PublicationStatus.UNPUBLISHED
  )
  const [city, setCity] = useState<CityDetailDataType | null>(null)
  const [apolloError, setApolloError] = useState<ApolloError | null>(null)
  const [states, setStates] = useState<Option[]>([])

  const { text } = useTranslation(textFiles.CITY_DETAIL)
  const { general: translation } = text
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { show } = useNotification()

  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })

  const { loading: cityLoading } = useQuery<
    GenericData<CityDetailDataType>,
    GenericInputVariable<string>
  >(GET_CITY_BY_ID, {
    variables: {
      input: cityId,
    },
    onCompleted(response) {
      setCity(response.data)
      setStatus(response.data.status)
    },
    onError(error) {
      setApolloError(error)
    },
    context: {
      noTranslation: true,
    },
  })

  const { loading: stateLoading } = useQuery<GenericData<Option[]>>(
    GET_STATES,
    {
      onCompleted(response) {
        setStates(response.data)
      },
    }
  )

  const [updateCity, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<UpdateCityInput>
  >(UPDATE_CITY, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: translation.updateSuccess,
      })
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
          message: translation.updateFail,
        })
    },
    refetchQueries: [GET_CITY_BY_ID],
  })

  const handleUpdateStatus = (newStatus: PublicationStatus) => {
    updateCity({
      variables: {
        input: {
          where: {
            id: cityId,
          },
          data: {
            status: newStatus,
          },
        },
      },
    })
  }

  const handleSave = async (
    name: string | undefined,
    latitude: number | undefined,
    longitude: number | undefined,
    state: string | undefined
  ) => {
    try {
      const response = await updateCity({
        variables: {
          input: {
            where: {
              id: cityId,
            },
            data: {
              name,
              state,
              latitude,
              longitude,
            },
          },
        },
      })
      if (response.errors) return false
      return true
    } catch {
      return false
    }
  }

  const handleStatusChange = (newStatus: PublicationStatus) => {
    if (newStatus !== status) {
      handleUpdateStatus(newStatus)
      setStatus(newStatus)
    }
  }

  if (cityLoading || stateLoading)
    return <LoadingAnimation showAnimation={cityLoading || stateLoading} />

  if (apolloError) return <Redirect push to={routes.NOT_FOUND_ERROR} />

  return city && states.length > 0 ? (
    <Layout>
      <StyledLink to={routes.CITY_LISTING}>
        <Typography
          variant="h3"
          color={colors.blue}
        >{`< ${translation.backButton}`}</Typography>
      </StyledLink>
      <Box display="flex" alignItems="center" paddingTop="0.5rem">
        <Typography variant="h3" color={colors.black} marginRight="1rem">
          {translation.title} #{cityId}
        </Typography>
        <Tag
          backgroundColor={submitLoading ? colors.gray : undefined}
          color={submitLoading ? colors.black : undefined}
          status={status}
        />
        <StatusChangePopper
          loading={submitLoading}
          status={status}
          setStatus={handleStatusChange}
        />
      </Box>
      <DetailSubHeader text={translation.description} />

      <DetailNavTab tab={tab} items={initialItems} />
      <ContentContainer>
        <TabPanel value={tab} index={0}>
          <CityInformation
            information={city}
            states={states}
            handleSave={handleSave}
            loading={submitLoading}
          />
        </TabPanel>
      </ContentContainer>
    </Layout>
  ) : null
}

export default CityDetailPage
