import React from 'react'
import { Grid, Typography } from '@mui/material'

import Box from 'components/Common/Box'
import ProgressBar from 'components/Common/ProgressBar'

import { StatusDistributionData } from 'models/services/inspection/dashboard'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { ProcessNumberContainer } from './style'

type ProcessStatusProps = {
  processStatuses: StatusDistributionData[]
}

const ProcessStatus = ({ processStatuses }: ProcessStatusProps) => (
  <Box width="100%">
    {processStatuses.map((processStatus) => (
      <Grid
        alignItems="center"
        container
        id={processStatus.id}
        justifyContent="space-between"
        key={processStatus.id}
        marginBottom="1rem"
        width="100%"
      >
        <Grid item xs={1}>
          <ProcessNumberContainer>
            <Typography
              color={processStatus.textColor}
              fontWeight={FONT_WEIGHT.BOLD}
              variant="body2"
            >
              {processStatus.count}
            </Typography>
          </ProcessNumberContainer>
        </Grid>
        <Grid item xs={2}>
          <Typography color={colors.black} variant="body2">
            {processStatus.label}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <ProgressBar progress={processStatus.percentage} />
        </Grid>
      </Grid>
    ))}
  </Box>
)

export default ProcessStatus
