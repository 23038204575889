import { styled } from '@mui/material'

import Box from 'components/Common/Box'

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  rowGap: '1.2rem',
})
