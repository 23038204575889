import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { ApolloError, useQuery } from '@apollo/client'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { Typography } from '@mui/material'

import AnswersCreation from 'components/Answers/Creation'
import Box from 'components/Common/Box'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import Title from 'components/Common/Title'
import Stepper from 'components/General/Stepper'

import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Answer } from 'models/services/answers'
import { GenericData } from 'models/services/base'

import { GET_ANSWERS } from 'graphQL/Answers/queries'

import { colors } from 'styles/theme'

const AnswersConfigurationPage = () => {
  const [answers, setAnswers] = useState<Answer[]>([])

  const [apolloError, setApolloError] = useState<ApolloError | null>(null)

  const { text } = useTranslation(textFiles.GENERAL)

  const { loading: answersLoading } = useQuery<GenericData<Answer[]>>(
    GET_ANSWERS,
    {
      onCompleted(response) {
        setAnswers(response.data)
      },
      onError(error) {
        setApolloError(error)
      },
    }
  )

  if (answersLoading) return <LoadingAnimation showAnimation={answersLoading} />

  if (apolloError) return <Redirect push to={routes.NOT_FOUND_ERROR} />

  return answers.length > 0 ? (
    <>
      <Title
        title={text.answers.title}
        icon={
          <PlaylistAddCheckIcon fontSize="medium" sx={{ color: colors.gray }} />
        }
        subtitle={`${answers.length} ${text.answers.description}`}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        sx={{ backgroundColor: colors.commonWhite }}
        marginTop="54px"
        padding="2rem 0"
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
        >
          <Typography variant="h3">{text.answers.processTitle}</Typography>
          <Typography
            fontSize="18px"
            color={colors.subtitleGray}
            variant="body2"
          >
            {text.answers.processDescription}
          </Typography>
          <Stepper
            currentStep={0}
            barWidth="778px"
            stepperItems={[
              {
                label: text.answers.firstStep as string,
              },
            ]}
          />
          <AnswersCreation answers={answers} />
        </Box>
      </Box>
    </>
  ) : null
}

export default AnswersConfigurationPage
