import {
  InspectionSlugStatus,
  InspectionStatus,
  InspectorStatus,
  InventoryStatus,
  PublicationStatus,
  StatusStyling,
  TemplateStatus,
  UserStatus,
} from 'models/status'

import { colors } from 'styles/theme'

export const PENDING_STATUS = 'pending'
export const INACTIVE_STATUS = 'inactive'
export const ACTIVE_STATUS = 'active'

export const StatusStyles: StatusStyling = {
  [InspectionStatus.PENDING]: {
    color: colors.yellow,
    backgroundColor: colors.lightYellow,
  },
  [InspectionStatus.SCHEDULED]: {
    color: colors.aquaBlue,
    backgroundColor: colors.cyan,
  },
  [InspectionStatus.IN_PROCESS]: {
    color: colors.darkBlue,
    backgroundColor: colors.backgroundDarkBlue,
  },
  [InspectionStatus.APPROVED]: {
    color: colors.green,
    backgroundColor: colors.lightGreen,
  },
  [InspectionStatus.REJECTED]: {
    color: colors.red,
    backgroundColor: colors.lightPink,
  },
  [InspectionStatus.FOR_REPAIR]: {
    color: colors.black,
    backgroundColor: colors.backgroundGray,
  },
  [InspectionStatus.ABORTED]: {
    color: colors.pink,
    backgroundColor: colors.lightPink,
  },
  [InspectionStatus.PATCHING_UP]: {
    color: colors.purple,
    backgroundColor: colors.lightPurple,
  },
  [InspectorStatus.ACTIVE]: {
    color: colors.aquaBlue,
    backgroundColor: colors.cyan,
  },
  [InspectorStatus.INACTIVE]: {
    color: colors.red,
    backgroundColor: colors.lightPink,
  },
  [TemplateStatus.PUBLISHED]: {
    color: colors.aquaBlue,
    backgroundColor: colors.cyan,
  },
  [TemplateStatus.DRAFT]: {
    color: colors.red,
    backgroundColor: colors.lightPink,
  },
  [PublicationStatus.PUBLISHED]: {
    color: colors.aquaBlue,
    backgroundColor: colors.cyan,
  },
  [PublicationStatus.UNPUBLISHED]: {
    color: colors.red,
    backgroundColor: colors.backgroundRed,
  },
  [InventoryStatus.AVAILABLE]: {
    color: colors.green,
    backgroundColor: colors.lightGreen,
  },
  [InventoryStatus.NOT_AVAILABLE]: {
    color: colors.red,
    backgroundColor: colors.backgroundRed,
  },
  [InventoryStatus.COMING_SOON]: {
    color: colors.aquaBlue,
    backgroundColor: colors.cyan,
  },
  [InventoryStatus.PENDING]: {
    color: colors.yellow,
    backgroundColor: colors.lightYellow,
  },
  [InventoryStatus.SOLD]: {
    color: colors.darkBlue,
    backgroundColor: colors.backgroundBlue,
  },
  [InventoryStatus.TO_REVIEW]: {
    color: colors.yellow,
    backgroundColor: colors.lightYellow,
  },
  [UserStatus.ACTIVE]: {
    color: colors.aquaBlue,
    backgroundColor: colors.cyan,
  },
  [UserStatus.CANCELLED]: {
    color: colors.red,
    backgroundColor: colors.backgroundRed,
  },
}

export const completedStatus: string[] = [
  InspectionSlugStatus.APPROVED,
  InspectionSlugStatus.ABORTED,
  InspectionSlugStatus.FOR_REPAIR,
  InspectionSlugStatus.REJECTED,
  InspectionSlugStatus.PATCHING_UP,
]
