import { StateColumnField } from 'constants/state'
import { SelectItem } from 'models/Select'
import { FieldSelectTextType } from 'models/text/StateListing'

export const createFieldSelectItems = (
  text: FieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: StateColumnField.ID,
    },
    {
      label: text.name,
      value: StateColumnField.NAME,
    },
    {
      label: text.createdAt,
      value: StateColumnField.CREATED_AT,
    },
    {
      label: text.status,
      value: StateColumnField.STATUS,
    },
    {
      label: text.actions,
      value: StateColumnField.ACTIONS,
    },
  ]
}

export const createFieldSelectLabels = (
  text: FieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    createdAt: text.createdAt,
    status: text.status,
    actions: text.actions,
    name: text.name,
  }
}

export const defaultFields = [
  StateColumnField.ID,
  StateColumnField.NAME,
  StateColumnField.CREATED_AT,
  StateColumnField.STATUS,
  StateColumnField.ACTIONS,
]
