import BrandInfo from 'components/CarSettings/Brand/Creation/BrandInfo'
import Summary from 'components/CarSettings/Brand/Creation/Summary'
import { StepperModel } from 'components/General/Stepper'

import { BrandCreationAction, BrandCreationModel } from 'models/brandCreation'
import { StepperTextType } from 'models/text/General'

export type BrandCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  brandData: BrandCreationModel
  submitLoading: boolean
  updateBrandData: (action: BrandCreationAction) => void
}

export const INITIAL_BRAND_CREATION_STEPS: Array<React.FC<BrandCreationProps>> =
  [BrandInfo, Summary]

export const createBrandStepperItems = (
  text: StepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep,
    },
    { label: text.secondStep },
  ]

  return stepperItems
}
