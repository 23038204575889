import { styled } from '@mui/material'

import { colors } from 'styles/theme'

export const StyledList = styled('ul')({
  maxHeight: '200px',
  overflow: 'auto',
  width: '100%',

  li: {
    color: colors.blue,
  },
})
