import { styled } from '@mui/material'

import Box from 'components/Common/Box'

import { colors } from 'styles/theme'

export const StyledContainer = styled(Box)({
  height: 131,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.commonWhite,
  marginTop: 58,
})
