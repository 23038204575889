import gql from 'graphql-tag'

export const GET_INSPECTION_BRANDS = gql`
  query getInspectionBrands($input: FilterInput) {
    data: getInspectionBrands(input: $input) {
      name
      id
    }
  }
`

export const GET_INSPECTION_MODELS = gql`
  query getInspectionModels($input: FilterInput) {
    data: getInspectionModels(input: $input) {
      id
      name
    }
  }
`

export const GET_INSPECTION_COLORS = gql`
  query getInspectionColors($input: FilterInput) {
    data: getColors(input: $input) {
      id
      name
      hexCode
    }
  }
`
