import { gql } from '@apollo/client'

export const UPDATE_BLACK_LIST_BRAND = gql`
  mutation updateBlacklistBrand($input: UpdateBlacklistBrandInput!) {
    data: updateBlacklistBrand(input: $input) {
      id
    }
  }
`

export const UPDATE_BLACK_LIST_MODEL = gql`
  mutation updateBlacklistModel($input: UpdateBlacklistModelInput!) {
    data: updateBlacklistModel(input: $input) {
      id
    }
  }
`

export const UPDATE_BLACK_LIST_TRIM_LEVEL = gql`
  mutation updateBlacklistTrimLevel($input: UpdateBlacklistTrimLevelInput!) {
    data: updateBlacklistTrimLevel(input: $input) {
      id
    }
  }
`
