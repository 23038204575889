import React, { useState } from 'react'
import Check from '@mui/icons-material/Check'
import LockIcon from '@mui/icons-material/Lock'
import { styled, Typography } from '@mui/material'

import Accordion from 'components/Common/Accordion'
import Autocomplete from 'components/Common/Autocomplete'
import Avatar from 'components/Common/Avatar'
import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import Calendar from 'components/Common/Calendar'
import CalendarRange from 'components/Common/CalendarRange'
import BarChart from 'components/Common/Charts/BarChart'
import PieChart from 'components/Common/Charts/PieChart'
import Container from 'components/Common/Container'
import HistoryLog from 'components/Common/HistoryLog'
import Modal from 'components/Common/Modal'
import { NoteType } from 'components/Common/Notes'
import ProgressBar from 'components/Common/ProgressBar'
import Select from 'components/Common/Select'
import ConfirmationToast from 'components/Common/Toast'
import Translation from 'components/General/Translation'
import { TranslationItemType } from 'components/General/Translation/TranslationListItem'
import Commissions from 'components/Inspector/Detail/Commissions'

import { barChartData, DesiredBarsToRenderOnChart } from 'constants/barChart'
import { sampleInspector, samplePercentage } from 'constants/commissions'
import { LANGUAGES } from 'constants/language'
import { textFiles } from 'constants/textFiles'
import useLocale from 'hooks/useLocale'
import useMultiCountryTranslation from 'hooks/useMultiCountryTranslation'
import { CalendarRangeType } from 'models/date'
import { TranslationChange } from 'models/translations'
import { downloadImagesAsZip } from 'utils/ZipDownloadUtils'

const StyledDiv = styled('div')({
  width: '400px',
  marginBottom: '1rem',
  padding: '1rem',
  display: 'flex',
  justifyContent: 'space-between',
})

const Layout = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const Styles = () => {
  const { text } = useMultiCountryTranslation(textFiles.GENERAL)
  const [addedTranslations, setAddedTranslations] = useState<
    TranslationItemType[]
  >([
    {
      id: 1,
      description: 'Camara reversa en alemán',
      name: 'Deutsch',
      value: 'de',
    },
  ])

  const handleChangeTranslation = (
    translations: TranslationItemType[],
    type: TranslationChange
  ) => {
    localStorage.setItem('translationOperation', type)
    setAddedTranslations(translations)
  }

  const data1 = [
    { id: 1, name: 'Group A', value: 400 },
    { id: 2, name: 'Group B', value: 300 },
    { id: 3, name: 'Group C', value: 300 },
    { id: 4, name: 'Group D', value: 200 },
    { id: 5, name: 'Group E', value: 400 },
    { id: 6, name: 'Group F', value: 300 },
    { id: 7, name: 'Group G', value: 300 },
    { id: 8, name: 'Group H', value: 200 },
  ]
  const data2 = [
    { id: 1, name: 'Group A', value: 60 },
    { id: 2, name: 'Group B', value: 40 },
  ]

  const top100Films = [
    { name: 'The Shawshank Redemption', id: 1994, disabled: true },
    { name: 'The Godfather', id: 1972 },
    { name: 'The Godfather: Part II', id: 1974, disabled: true },
    { name: 'The Dark Knight', id: 2008 },
    { name: '12 Angry Men', id: 1957 },
    { name: "Schindler's List", id: 1993 },
    { name: 'Pulp Fiction', id: 1994, disabled: true },
  ]

  const dummyCarouselImages = [
    'https://m.n.com.do/wp-content/uploads/2020/08/mami-Jordan-1140x694.jpg',
    'https://i.kym-cdn.com/entries/icons/facebook/000/040/219/cover1.jpg',
  ]

  const sampleName = 'CurboDownload'
  const sampleExtension = '.zip'

  const [notes, setNotes] = useState<NoteType[]>([])
  const [showModal, setShowModal] = useState(false)
  const [progress, setProgress] = React.useState(0)

  // Dates state and function handler for the Calendar and CalendarRange components
  const [singleCalendarDate, setSingleCalendarDate] = useState<Date | null>(
    new Date()
  )
  const handleSingleCalendarDate = (newDate: Date | null) => {
    setSingleCalendarDate(newDate)
  }
  const [dateRange, setDateRange] = useState<CalendarRangeType>({
    fromDate: new Date(),
    toDate: new Date(),
  })

  const handleDateRange = (newDateRange: CalendarRangeType) => {
    setDateRange(newDateRange)
  }

  const handleNotesChange = (newNotes: NoteType[]) => {
    setNotes(newNotes)
  }

  const [toast, setToast] = useState<boolean>(true)
  const [, updateLanguage] = useLocale()
  const languages = LANGUAGES.map((language) => language)

  const handleCloseToast = () => {
    setToast(false)
  }
  return (
    <Layout>
      {/* <ImageCarousel
        imageList={[
          ...dummyCarouselImages3,
          ...dummyCarouselImages2,
          ...dummyCarouselImages,
          ...dummyCarouselImages,
        ]}
        height={600}
        imageHeight={200}
        imageWidth={200}
        axis="vertical"
        isDownloadable
        // eslint-disable-next-line no-console
        onDelete={() => console.log('Deleted')}
      /> */}
      <Button
        onClick={() =>
          downloadImagesAsZip(dummyCarouselImages, sampleName, sampleExtension)
        }
        sx={{ width: '250px', marginBottom: '20px' }}
      >
        Download as Zip
      </Button>
      {/* <ImageCarousel
        imageList={[
          ...dummyCarouselImages3,
          ...dummyCarouselImages2,
          ...dummyCarouselImages,
          ...dummyCarouselImages,
        ]}
        width="1400px"
        imageHeight={200}
        imageWidth={200}
        isDownloadable
        axis="horizontal"
        // eslint-disable-next-line no-console
        onDelete={() => console.log('Deleted')}
      /> */}
      <Button
        onClick={() =>
          downloadImagesAsZip(dummyCarouselImages, sampleName, sampleExtension)
        }
        sx={{ width: '250px', marginBottom: '20px' }}
      >
        Download as Zip
      </Button>
      <HistoryLog recent dateFormat="time" />
      {languages.map((language) => (
        <Button
          key={language.code}
          onClick={() => {
            updateLanguage(language)
          }}
        >
          {language.name}
        </Button>
      ))}
      <Typography>{text.title}</Typography>
      <Typography variant="h1">H1 header</Typography>
      <Typography variant="h2">H2 header</Typography>
      <Typography variant="h3">H3 header</Typography>
      <Typography variant="h4">H4 header</Typography>
      <Typography variant="h5">H5 header</Typography>
      <Typography variant="h6">H6 header</Typography>
      <Typography variant="body1">body 1</Typography>
      <Typography variant="body2">body 2</Typography>
      <Typography variant="subtitle1">subtitle1</Typography>
      <Typography variant="subtitle2">subtitle2</Typography>
      <Typography variant="overline">overline</Typography>
      <Typography variant="h1">PieChart</Typography>
      <div style={{ backgroundColor: 'lightgrey', marginLeft: '1rem' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar image="https://www.whatsappprofiledpimages.com/wp-content/uploads/2021/08/Profile-Photo-Wallpaper.jpg" />
          <div style={{ marginLeft: '1rem' }}>
            <Typography variant="body1">Jessica Jess</Typography>
            <Typography variant="subtitle1">Inspections Supervisor</Typography>
          </div>
        </div>
        <Avatar height="3rem" width="3rem" />
        <Avatar
          height={80}
          width={80}
          image="https://www.whatsappprofiledpimages.com/wp-content/uploads/2021/08/Profile-Photo-Wallpaper.jpg"
        />
        <Avatar
          height={80}
          width={80}
          image="https://www.whatsappprofiledpimages.com/wp-content/uploads/2021/08/Profile-Photo-Wallpaper.jpg"
          variant="rounded"
        />
        <Avatar
          height={188}
          image="https://www.whatsappprofiledpimages.com/wp-content/uploads/2021/08/Profile-Photo-Wallpaper.jpg"
          variant="rounded"
          width={300}
        />
        <Avatar height={352} variant="rounded" width={358} />

        <Accordion
          defaultExpanded
          description="Make and model"
          title="General information"
        >
          <h1>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel
            lorem non magna convallis commodo. Aliquam non iaculis urna. Integer
            sed mollis libero.
          </h1>
        </Accordion>
      </div>
      <div
        style={{
          display: 'flex',
        }}
      >
        <PieChart
          data={data1}
          labelData={{
            label: 'Data',
            percentage: '60',
          }}
          size={{ height: 300, width: 300 }}
        />
        <PieChart
          data={data2}
          color="green"
          labelData={{
            label: 'Data',
            percentage: '60',
          }}
          size={{ height: 300, width: 300 }}
        />
        <PieChart
          data={data2}
          color="yellow"
          labelData={{
            label: 'Data',
            percentage: '60',
          }}
          size={{ height: 300, width: 300 }}
        />
        <PieChart
          data={data2}
          color="blue"
          labelData={{
            label: 'Data',
            percentage: '60',
          }}
          size={{ height: 300, width: 300 }}
        />
      </div>
      <Box sx={{ display: 'flex' }}>
        <Autocomplete
          icon={<Check />}
          options={top100Films}
          disabled={false}
          onChangeHandler={(value) => value}
        />
        <Select options={[]} sx={{ width: '300px' }} />
      </Box>

      <Typography variant="h1">Modal</Typography>
      <Button
        onClick={() => {
          setShowModal(!showModal)
        }}
      >
        Open Modal
      </Button>
      <br />
      <Typography variant="h1">Progress Bar</Typography>
      <Button
        onClick={() => {
          if (progress === 100) {
            setProgress(0)
          } else {
            setProgress(progress + 10)
          }
        }}
      >
        sube
      </Button>
      <ProgressBar progress={progress} />
      <Translation
        addedTranslations={addedTranslations}
        handleChangeTranslation={handleChangeTranslation}
        translationDescription="Set the brand name in other languages"
      />
      <Modal
        open={showModal}
        handleClose={() => setShowModal(false)}
        enableBottom={{
          leftButtonText: 'Yes, Draft it',
          rightButtonText: 'Go Back',
          leftIcon: <Check />,
          primaryFunction: () => {
            return null
          },
          secondaryFunction: () => {
            setShowModal(false)
          },
        }}
      >
        <Typography variant="h1">probando</Typography>
      </Modal>
      <Typography variant="h1"> Bar Chart</Typography>

      <Container
        sx={{
          width: 1095,
        }}
        description="This can be a great indicator of how well the company is doing in terms of inspections"
        title="Amount of vehicles inspected per month"
      >
        <BarChart
          data={barChartData}
          barsStructure={DesiredBarsToRenderOnChart}
          layout="horizontal"
          size={{ width: 1000, height: 700 }}
        />
      </Container>
      <Typography variant="h1"> Vertical Bar</Typography>
      <div>
        <BarChart
          data={barChartData}
          barsStructure={DesiredBarsToRenderOnChart}
          layout="vertical"
          size={{ width: 1000, height: 700 }}
        />
      </div>

      <div style={{ display: 'flex' }}>
        <div>
          <StyledDiv>
            <Button buttonType="primary" size="large">
              Large
            </Button>
            <Button buttonType="primary" disabled size="large">
              Disabled
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button
              buttonType="primary"
              startIcon={<LockIcon />}
              size="medium"
              fullWidth
            >
              With Icon
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button buttonType="primary" size="medium" fullWidth>
              Without Icon
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button buttonType="primary" size="small">
              Small
            </Button>
          </StyledDiv>
        </div>
        <div>
          <StyledDiv>
            <Button buttonType="secondary" size="large">
              Large
            </Button>
            <Button buttonType="secondary" disabled size="large">
              Disabled
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button
              buttonType="secondary"
              startIcon={<LockIcon />}
              size="medium"
              fullWidth
            >
              With Icon
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button buttonType="secondary" size="medium" fullWidth>
              Without Icon
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button buttonType="secondary" size="small">
              Small
            </Button>
          </StyledDiv>
        </div>
        <div>
          <StyledDiv>
            <Button buttonType="info" size="large">
              Large
            </Button>
            <Button buttonType="info" disabled size="large">
              Disabled
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button
              buttonType="info"
              startIcon={<LockIcon />}
              size="medium"
              fullWidth
            >
              With Icon
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button buttonType="info" size="medium" fullWidth>
              Without Icon
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button buttonType="info" size="small">
              Small
            </Button>
          </StyledDiv>
        </div>
      </div>

      <div style={{ display: 'flex' }}>
        <div>
          <StyledDiv>
            <Button buttonType="primary" size="large" variant="text">
              Large
            </Button>
            <Button buttonType="primary" disabled size="large" variant="text">
              Disabled
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button
              buttonType="primary"
              startIcon={<LockIcon />}
              size="medium"
              fullWidth
              variant="text"
            >
              With Icon
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button buttonType="primary" size="medium" fullWidth variant="text">
              Without Icon
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button buttonType="primary" size="small" variant="text">
              Small
            </Button>
          </StyledDiv>
        </div>
        <div>
          <StyledDiv>
            <Button buttonType="secondary" size="large" variant="text">
              Large
            </Button>
            <Button buttonType="secondary" disabled size="large" variant="text">
              Disabled
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button
              buttonType="secondary"
              startIcon={<LockIcon />}
              size="medium"
              fullWidth
              variant="text"
            >
              With Icon
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button
              buttonType="secondary"
              size="medium"
              fullWidth
              variant="text"
            >
              Without Icon
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button buttonType="secondary" size="small" variant="text">
              Small
            </Button>
          </StyledDiv>
        </div>
        <div>
          <StyledDiv>
            <Button buttonType="info" size="large" variant="text">
              Large
            </Button>
            <Button buttonType="info" disabled size="large" variant="text">
              Disabled
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button
              buttonType="info"
              startIcon={<LockIcon />}
              size="medium"
              fullWidth
              variant="text"
            >
              With Icon
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button buttonType="info" size="medium" fullWidth variant="text">
              Without Icon
            </Button>
          </StyledDiv>
          <StyledDiv>
            <Button buttonType="info" size="small" variant="text">
              Small
            </Button>
          </StyledDiv>
        </div>
      </div>
      <Typography variant="h2">Single Calendar</Typography>
      <Calendar
        date={singleCalendarDate}
        handleCalendarDate={handleSingleCalendarDate}
      />
      <Typography variant="h2" sx={{ marginBottom: '50px' }}>
        Calendar Range
      </Typography>
      <CalendarRange
        dateRange={dateRange}
        handleChangeCalendarRange={handleDateRange}
        maxDate={new Date()}
      />

      <Typography variant="h2" sx={{ marginBottom: '50px' }}>
        Commissions tab
      </Typography>

      <Commissions
        inspectorData={sampleInspector}
        percentage={samplePercentage.percentage}
        pieChartData={data2}
        carsInspected={30}
        carsInspectedObjective={45}
        notes={notes}
        handleNotesChange={handleNotesChange}
        commission={250}
        commissionCurrency="US$"
      />

      <ConfirmationToast
        showToast={toast}
        handleClose={handleCloseToast}
        title="TITULO DEL TOAST"
        description="DESCRIPCION DEL TOAST TOAST TOAST "
      />
      <Typography>Image Carousel</Typography>
    </Layout>
  )
}

export default Styles
