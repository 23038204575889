import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Alert, Snackbar, Typography } from '@mui/material'
import { AlertColor } from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { colors } from 'styles/theme'

import {
  StyledCloseButton,
  StyledIconContainer,
  StyledMessage,
  StyledSnackbarContent,
} from './style'

type CustomToastType = {
  /**
   * State describing whether or not should toast be open
   */
  showToast: boolean
  /**
   * Function to handle the close function
   */
  handleClose: () => void
  /**
   * Icon to render next to the Toast message JSX element expected
   */
  icon?: React.ReactNode
  /**
   * Prop expecting the primary label
   */
  title?: string

  /**
   * Prop expecting the secondary label
   */
  description: string

  /*
   * Variant of snackbar
   */
  variant?: 'confirmation' | 'alert'
  /*
   * After how many ms should the alert disappear
   */
  autoHide?: number

  /*
   * Severity of the snackbar if using alert variant
   */
  severity?: AlertColor
}

type CloseButtonType = {
  handleClose: () => void
}

export const CloseButton = ({ handleClose }: CloseButtonType) => (
  <StyledCloseButton aria-label="Close" onClick={handleClose}>
    <CloseIcon sx={{ color: colors.gray }} />
  </StyledCloseButton>
)

const Toast = ({
  showToast,
  handleClose,
  icon,
  title,
  description,
  variant = 'alert',
  autoHide = 2500,
  severity = 'success',
}: CustomToastType) => {
  const {
    text: { notificationText: translation },
  } = useTranslation(textFiles.GENERAL)
  const ConfirmationMessage = () => (
    <>
      <CloseButton handleClose={handleClose} />

      {icon ? <StyledIconContainer>{icon}</StyledIconContainer> : null}
      <StyledMessage>
        {title ? (
          <Typography variant="body1" sx={{ marginBottom: '5px' }}>
            {title}
          </Typography>
        ) : null}
        <Typography variant="body2">{description}</Typography>
      </StyledMessage>
    </>
  )

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={showToast}
      onClose={handleClose}
      autoHideDuration={autoHide}
      key="bottom right"
    >
      {variant === 'confirmation' ? (
        <StyledSnackbarContent message={<ConfirmationMessage />} />
      ) : (
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ maxWidth: '340px', height: 'auto' }}
        >
          <AlertTitle>{title || translation[severity]}</AlertTitle>
          {description.length > 0 ? (
            <Typography variant="body2">{description}</Typography>
          ) : null}
        </Alert>
      )}
    </Snackbar>
  )
}

export default Toast
