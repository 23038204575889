import { DRIVE_TRAIN_CREATION_OPTION } from 'constants/driveTrain'
import {
  DriveTrainCreationAction,
  DriveTrainCreationModel,
} from 'models/driveTrainCreation'

const driveTrainCreationReducer = (
  state: DriveTrainCreationModel,
  action: DriveTrainCreationAction
): DriveTrainCreationModel => {
  switch (action.type) {
    case DRIVE_TRAIN_CREATION_OPTION.UPDATE_DRIVE_TRAIN_INFORMATION:
      return { ...state, driveTrainInformation: action.payload }

    default:
      return state
  }
}

export default driveTrainCreationReducer
