import React from 'react'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import PersonIcon from '@mui/icons-material/Person'
import SearchIcon from '@mui/icons-material/Search'

import { ModuleInfoType } from 'components/General/GeneralView'

import { UserRoles } from 'models/role'
import { ModulesText } from 'models/text/home'

import {
  CAR_SETTINGS_ROUTE,
  INSPECTION_ROUTE,
  INVENTORY_ROUTE,
  MARKETING_ROUTE,
} from './routes'

export const createModuleCards = (text: ModulesText): ModuleInfoType[] => {
  const { inspections, operations, inventory, marketing } = text

  return [
    {
      id: 1,
      name: inspections.title,
      subtitle: inspections.subtitle,
      icon: <SearchIcon />,
      pointers: inspections.bulletPoints.map((pointer, index) => {
        return {
          id: index,
          pointer,
        }
      }),
      route: INSPECTION_ROUTE,
      allowedRoles: [
        UserRoles.ADMIN,
        UserRoles.INSPECTOR_SUPERVISOR,
        UserRoles.INSPECTOR_MANAGER,
      ],
    },
    {
      id: 2,
      name: operations.title,
      subtitle: operations.subtitle,
      icon: <AccountTreeIcon />,
      pointers: operations.bulletPoints.map((pointer, index) => {
        return {
          id: index,
          pointer,
        }
      }),
      route: CAR_SETTINGS_ROUTE,
      allowedRoles: [
        UserRoles.ADMIN,
        UserRoles.OPERATION_SPECIALIST,
        UserRoles.OPERATION_SUPERVISOR,
      ],
    },
    {
      id: 3,
      name: 'customerCare',
      subtitle: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
      icon: <PersonIcon />,
      pointers: [
        {
          id: 1,
          pointer: 'Lorem ipsum dolor sit amet, conset.',
        },
        {
          id: 2,
          pointer: 'Lorem ipsum dolor sit amet, conset.',
        },
        {
          id: 3,
          pointer: 'Lorem ipsum dolor sit amet, conset.',
        },
      ],
      route: '',
      allowedRoles: [],
      hideInProduction: true,
    },
    {
      id: 4,
      name: inventory.title,
      subtitle: inventory.subtitle,
      icon: <FormatListBulletedIcon />,
      pointers: inventory.bulletPoints.map((pointer, index) => {
        return {
          id: index,
          pointer,
        }
      }),
      route: INVENTORY_ROUTE,
      allowedRoles: [
        UserRoles.ADMIN,
        UserRoles.INVENTORY_SPECIALIST,
        UserRoles.INVENTORY_SUPERVISOR,
        UserRoles.INVENTORY_READER,
      ],
    },
    {
      id: 5,
      name: marketing.title,
      subtitle: marketing.subtitle,
      icon: <FormatListBulletedIcon />,
      pointers: marketing.bulletPoints.map((pointer, index) => {
        return {
          id: index,
          pointer,
        }
      }),
      route: MARKETING_ROUTE,
      allowedRoles: [UserRoles.ADMIN, UserRoles.MARKETING_SPECIALIST],
      hideInProduction: true,
    },
  ]
}
