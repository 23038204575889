import React, { useEffect, useState } from 'react'
import { CSSObject } from '@mui/system'
import * as yup from 'yup'

import Box from 'components/Common/Box'
import ErrorMessage from 'components/Common/ErrorMessage'
import TextField from 'components/Common/TextField'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { colors, FONT_WEIGHT } from 'styles/theme'

type TextAreaVariant = 'TITLE' | 'NORMAL' | 'NORMAL_BOLD' | 'STEP' | 'QUESTION'

type StylingVariantType = {
  fontWeight: FONT_WEIGHT
  fontSize: string
  height: string
  inputPadding: string
  backgroundColor: string
  editBackgroundColor: string
  borderRadius: string
  width: string
  flex: number
}

const TextAreaStyling: Record<TextAreaVariant, StylingVariantType> = {
  TITLE: {
    fontWeight: FONT_WEIGHT.EXTRA_BOLD,
    fontSize: '30px',
    height: '45px',
    inputPadding: '0 0.25em',
    backgroundColor: colors.transparent,
    editBackgroundColor: colors.commonWhite,
    borderRadius: '0',
    width: '100%',
    flex: 1,
  },
  NORMAL: {
    fontWeight: FONT_WEIGHT.MEDIUM,
    fontSize: '14px',
    height: '35px',
    inputPadding: '0.5em 0',
    backgroundColor: colors.transparent,
    editBackgroundColor: colors.transparent,
    borderRadius: '4px',
    width: '100%',
    flex: 1,
  },
  NORMAL_BOLD: {
    fontWeight: FONT_WEIGHT.BOLD,
    fontSize: '14px',
    height: '35px',
    inputPadding: '0.5em 0',
    backgroundColor: colors.transparent,
    editBackgroundColor: colors.transparent,
    borderRadius: '4px',
    width: '100%',
    flex: 1,
  },
  STEP: {
    fontWeight: FONT_WEIGHT.BOLD,
    fontSize: '16px',
    height: '35px',
    inputPadding: '0.4em 0em',
    backgroundColor: colors.transparent,
    editBackgroundColor: colors.transparent,
    borderRadius: '4px',
    width: '100%',
    flex: 1,
  },
  QUESTION: {
    fontWeight: FONT_WEIGHT.MEDIUM,
    fontSize: '14px',
    height: '35px',
    inputPadding: '0.5em',
    backgroundColor: colors.questionGray,
    editBackgroundColor: colors.commonWhite,
    borderRadius: '4px',
    width: '100%',
    flex: 1,
  },
}

type TextAreaProps = {
  editMode: boolean
  initialValue: string
  updateText: (newText: string) => void
  rows?: number
  variant?: TextAreaVariant
  /*
   * Prop used to pass as the "size" attribute for an HTML input element
   * Note: IT IS DIFFERENT than width and height
   */
  inputSize?: number
  saveStateCalled: boolean
  additionalTextFieldStyles?: CSSObject
}

type ErrorType = {
  name: string
  errors: string[]
}

const TextArea = ({
  editMode,
  rows = 2,
  variant = 'NORMAL',
  initialValue,
  updateText,
  inputSize,
  saveStateCalled,
  additionalTextFieldStyles,
}: TextAreaProps) => {
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const [textContent, setTextContent] = useState<string>(initialValue || '')
  const schema = yup
    .string()
    .required(validationText.fieldRequired)
    .min(5, validationText.errorMessageMinCharacters.replace('%d', 5))
    .max(50, validationText.errorMessageMaxCharacters.replace('%d', 50))

  const [error, setError] = useState<string>('')

  const validateErrors = async (value: string) => {
    try {
      await schema.validate(value)
      updateText(value)
      setError('')
    } catch (err) {
      const yupError = err as ErrorType
      setError(yupError.errors[0] as string)
    }
  }

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (editMode) {
      setTextContent(value)
      validateErrors(value)
    }
  }

  useEffect(() => {
    setTextContent(initialValue)
    setError('')
  }, [initialValue, editMode, setError])

  useEffect(() => {
    if (saveStateCalled) {
      validateErrors(initialValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveStateCalled])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          padding: '0.5em 0.5em 0.5em 0',
          height: 'auto',
          width: TextAreaStyling[variant].width,
          maxHeight: '100px',
          flex: TextAreaStyling[variant].flex,
          overflow: `${rows > 1 ? 'hidden auto' : 'hidden'}`,
          '& > div': {
            height: `${rows > 1 ? 'auto' : TextAreaStyling[variant].height}`,
            borderColor: !editMode ? 'transparent' : colors.border,
            borderRadius: TextAreaStyling[variant].borderRadius,
          },
        }}
      >
        <TextField
          variant="outlined"
          name="content"
          value={textContent}
          onChange={handleChange}
          hiddenLabel
          multiline={rows > 1}
          rows={rows}
          fullWidth
          InputProps={{
            readOnly: !editMode,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            maxLength: 50,
            size: inputSize || 'inherit',
          }}
          sx={{
            flex: '2',
            height: '100%',
            overflow: 'hidden',
            textAlign: 'left',
            backgroundColor: editMode
              ? TextAreaStyling[variant].editBackgroundColor
              : TextAreaStyling[variant].backgroundColor,
            [`& > div`]: {
              padding: `${rows > 1 ? '10px 10px 10px 0' : '0px'}`,
              color: `${colors.black} !important`,
              fontWeight: TextAreaStyling[variant].fontWeight,
              fontSize: TextAreaStyling[variant].fontSize,
              '& > input': {
                padding: TextAreaStyling[variant].inputPadding,
              },
              ...(!editMode && {
                '& > *': {
                  caretColor: 'transparent',
                  '&:focus, &:hover': {
                    cursor: 'default',
                  },
                },
              }),
            },
            ...additionalTextFieldStyles,
          }}
        />
      </Box>
      {error.length > 0 ? (
        <ErrorMessage text={error} sx={{ margin: '0.25em 0 0 0' }} />
      ) : null}
    </Box>
  )
}

export default TextArea
