const carSettings = 'carSettings'
const inventoryRoot = 'inventory'
const userManagementRoot = 'userManagement'
const operationsRoot = 'operations'
const marketingRoot = 'marketing'

export const inspectionTextFile = {
  INSPECTION_DASHBOARD: 'inspectionDashboard',
  INSPECTION_LISTING: 'inspectionListing',
  INSPECTION_DETAIL: 'inspectionDetail',
  INSPECTION_CREATION: 'inspectionCreation',
}

export const inspectorTextFile = {
  INSPECTOR_DETAIL: 'inspectorDetail',
  INSPECTOR_DASHBOARD: 'inspectorDashboard',
  INSPECTOR_LISTING: 'inspectorListing',
  INSPECTOR_CALENDAR: 'inspectorCalendar',
}

export const templateTextFile = {
  TEMPLATE_CREATION: 'templateCreation',
  TEMPLATE_DETAIL: 'templateDetail',
  TEMPLATE_LISTING: 'templateListing',
}

export const cityTextFile = {
  CITY_LISTING: 'cityListing',
  CITY_CREATION: 'cityCreation',
  CITY_DETAIL: 'cityDetail',
}

export const stateTextFile = {
  STATE_LISTING: 'stateListing',
  STATE_DETAIL: 'stateDetail',
  STATE_CREATION: 'stateCreation',
}

export const dealerTextFile = {
  DEALER_LISTING: 'dealerListing',
  DEALER_DETAIL: 'dealerDetail',
  DEALER_CREATION: 'dealerCreation',
}

export const curboSpotTextFile = {
  CURBO_SPOT_LISTING: 'curboSpotListing',
  CURBO_SPOT_DETAIL: 'curboSpotDetail',
  CURBO_SPOT_CREATION: 'curboSpotCreation',
}

export const outOfSpotTextFile = {
  OUT_OF_SPOT: 'outOfSpot',
}

export const carSettingsTextFile = {
  CAR_SETTINGS: 'carSettings',
  BRAND_LISTING: `${carSettings}/brandListing`,
  MODEL_LISTING: `${carSettings}/modelListing`,
  COUNTRY_VERSION_LISTING: `${carSettings}/countryVersionListing`,
  FEATURE_LISTING: `${carSettings}/featureListing`,
  TRANSMISSION_LISTING: `${carSettings}/transmissionListing`,
  DRIVE_TRAIN_LISTING: `${carSettings}/driveTrainListing`,
  FUEL_TYPE_LISTING: `${carSettings}/fuelTypeListing`,
  CATEGORY_LISTING: `${carSettings}/categoryListing`,
  BODY_STYLE_LISTING: `${carSettings}/bodyStyleListing`,
  COLOR_LISTING: `${carSettings}/colorListing`,
  TRIM_LISTING: `${carSettings}/trimListing`,
  TRIM_CREATION: `${carSettings}/Creation/trimCreation`,
  BRAND_CREATION: `${carSettings}/Creation/brandCreation`,
  COUNTRY_VERSION_CREATION: `${carSettings}/Creation/countryVersionCreation`,
  FEATURE_CREATION: `${carSettings}/Creation/featureCreation`,
  CATEGORY_CREATION: `${carSettings}/Creation/categoryCreation`,
  TRANSMISSION_CREATION: `${carSettings}/Creation/transmissionCreation`,
  DRIVE_TRAIN_CREATION: `${carSettings}/Creation/driveTrainCreation`,
  FUEL_TYPE_CREATION: `${carSettings}/Creation/fuelTypeCreation`,
  BODY_STYLE_CREATION: `${carSettings}/Creation/bodyStyleCreation`,
  COLOR_CREATION: `${carSettings}/Creation/colorCreation`,
  MODEL_CREATION: `${carSettings}/Creation/modelCreation`,
  MODEL_DETAIL: `${carSettings}/Detail/modelDetail`,
  BRAND_DETAIL: `${carSettings}/Detail/brandDetail`,
  COLOR_DETAIL: `${carSettings}/Detail/colorDetail`,
  FEATURE_DETAIL: `${carSettings}/Detail/featureDetail`,
  BODY_STYLE_DETAIL: `${carSettings}/Detail/bodyStyleDetail`,
  COUNTRY_VERSION_DETAIL: `${carSettings}/Detail/countryVersionDetail`,
  TRANSMISSION_DETAIL: `${carSettings}/Detail/transmissionDetail`,
  DRIVE_TRAIN_DETAIL: `${carSettings}/Detail/driveTrainDetail`,
  FUEL_TYPE_DETAIL: `${carSettings}/Detail/fuelTypeDetail`,
  CATEGORY_DETAIL: `${carSettings}/Detail/categoryDetail`,
  TRIM_DETAIL: `${carSettings}/Detail/trimDetail`,
}

export const errorPageTextFile = {
  RESOLUTION_ERROR: 'resolutionErrorPage',
  NOT_FOUND_ERROR: 'notFoundErrorPage',
}

export const inventoryTextFile = {
  INVENTORY_DETAIL: `${inventoryRoot}/detail`,
  PRE_INVENTORY_LISTING: `${inventoryRoot}/preInventoryListing`,
  INVENTORY_LISTING: `${inventoryRoot}/inventoryListing`,
  VEHICLE_CREATION: `${inventoryRoot}/vehicleCreation`,
}

export const userManagementTextFile = {
  USER_MANAGEMENT_LISTING: `${userManagementRoot}/listing`,
  USER_MANAGEMENT_DETAIL: `${userManagementRoot}/detail`,
  USER_MANAGEMENT_CREATION: `${userManagementRoot}/creation`,
}

export const operationsTextFile = {
  BLACK_LIST_LISTING: `${operationsRoot}/blackListListing`,
  BLACK_LIST_MAKE_FILTERS: `${operationsRoot}/blackListMakeFilters`,
  BLACK_LIST_MODEL_FILTERS: `${operationsRoot}/blackListModelFilters`,
  BLACK_LIST_TRIM_FILTERS: `${operationsRoot}/blackListTrimFilters`,
  BLACK_LIST_DETAIL: `${operationsRoot}/blackListDetail`,
  BLACK_LIST_CREATION: `${operationsRoot}/blackListCreation`,
}

export const marketingTextFile = {
  NOTIFICATION_CREATION: `${marketingRoot}/notificationCreation`,
  OFFER_LISTING: `${marketingRoot}/offerListing`,
}

export const textFiles = {
  AUTH: 'auth',
  FORGOT_PASSWORD: 'forgotPassword',
  HOURS_CREATION: 'hoursCreation',
  LOGIN: 'login',
  ACCOUNT: 'myAccount',
  VALIDATION: 'validation',
  ASK_FOR_HELP: 'askForHelp',
  HISTORY_LOG: 'historyLog',
  GENERAL: 'general',
  GENERAL_VIEW: 'generalView',
  ERROR: 'errorPages',
  DOWNLOAD_REPORT: 'downloadReport',
  MAP_COORDINATES: 'mapCoordinates',
  ...inspectionTextFile,
  ...inspectorTextFile,
  ...templateTextFile,
  ...cityTextFile,
  ...stateTextFile,
  ...dealerTextFile,
  ...carSettingsTextFile,
  ...errorPageTextFile,
  ...cityTextFile,
  ...curboSpotTextFile,
  ...outOfSpotTextFile,
  ...inventoryTextFile,
  ...userManagementTextFile,
  ...operationsTextFile,
  ...marketingTextFile,
}
