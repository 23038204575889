import React, { useState } from 'react'

import ImageBox from 'components/CarSettings/Common/Detail/ImageBox'
import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'
import {
  GridBox,
  StyledContainer,
} from 'components/CarSettings/Trim/Detail/Settings/style'
import Box from 'components/Common/Box'

import { textFiles } from 'constants/textFiles'
import { getTrimInformation } from 'constants/trim'
import useTranslation from 'hooks/useTranslation'
import { NameInformation } from 'models/car'
import { TrimDetailDataType } from 'models/services/CarSettings/detail'
import { PictureOption } from 'models/trim'

import CarImagePlaceholder from 'images/blueDefaultAvatar.png'

import NameContainer from './NameContainer'

type Props = {
  information: TrimDetailDataType
  handleSave: (data: TrimDetailDataType) => Promise<boolean>
  submitLoading: boolean
  brands: PictureOption[]
}

const Information = ({
  information,
  handleSave,
  submitLoading,
  brands,
}: Props) => {
  const { model } = information

  const { text } = useTranslation(textFiles.TRIM_DETAIL)
  const {
    trim: { information: translation },
  } = text

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)

  const initialNameInformation = getTrimInformation(
    translation,
    brands,
    information
  )

  const [info, setInfo] = useState<NameInformation[]>(initialNameInformation)

  const resetState = () => {
    setInfo(initialNameInformation)
    setHasUnsavedChanges(false)
  }

  const handleChangeInformation = (newInformation: NameInformation[]) => {
    setInfo(newInformation)
    setHasUnsavedChanges(true)
  }

  const handleSaveChanges = async () => {
    const isUpdateSuccesful = await handleSave({
      ...information,
      model: {
        value: info[1].name as string,
        name: info[1].name as string,
        brand: {
          value: info[0].name as string,
          name: info[0].name as string,
          icon: model.brand.icon,
        },
      },
      name: info[2].name as string,
      year: Number(info[3].name),
    })
    if (isUpdateSuccesful) {
      setHasUnsavedChanges(false)
    }
  }

  return (
    <StyledContainer>
      <GridBox>
        <ImageBox
          height="100%"
          width="100%"
          variant="rounded"
          image={model.brand.icon || CarImagePlaceholder}
          containerSx={{
            height: '363px',
            width: '363px',
            marginTop: '1rem',
            padding: '1rem',
          }}
        />
        <NameContainer
          translation={translation}
          containerSx={{
            padding: '1rem 0 0 !important',
            maxWidth: '399px',
          }}
          information={info}
          handleSetInformation={handleChangeInformation}
          loading={submitLoading}
          brands={brands}
        />
      </GridBox>
      <Box paddingTop="1rem">
        <SaveChangesBlock
          handleSaveChanges={handleSaveChanges}
          resetState={resetState}
          submitLoading={submitLoading}
          shouldRender={hasUnsavedChanges}
        />
      </Box>
    </StyledContainer>
  )
}

export default Information
