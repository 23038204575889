import { styled } from '@mui/material'

import Box from 'components/Common/Box'

import { colors } from 'styles/theme'

export const StyledCard = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10px',
  backgroundColor: colors.background,
  boxShadow: 'none',
})
