import { gql } from '@apollo/client'

export const LIST_OFFERS = gql`
  query listOffers($input: FilterInput) {
    listData: listOffers(input: $input) {
      count
      data {
        createdAt
        id
        label
        status {
          backgroundColor
          description
          id
          name
          textColor
          slug
        }
      }
    }
  }
`

export const GET_OFFER_STATUSES = gql`
  query getOfferStatuses($input: FilterInput) {
    data: getOfferStatuses(input: $input) {
      backgroundColor
      description
      id
      name
      textColor
      slug
    }
  }
`
