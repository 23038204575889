import { Badge, badgeClasses, styled } from '@mui/material'

import { colors } from 'styles/theme'

export const StyledBadge = styled(Badge)({
  width: '100%',
  justifyContent: 'center',
  [`.${badgeClasses.badge}`]: {
    position: 'relative',
    marginLeft: '2px',
    height: '15px',
    minWidth: '15px',
    width: '15px',
    borderRadius: '3px',
    color: colors.commonWhite,
    backgroundColor: colors.lightRed,
    top: '50%',
  },
})
