import React from 'react'
import CallIcon from '@mui/icons-material/Call'
import MailIcon from '@mui/icons-material/Mail'
import { CSSObject } from '@mui/material'

import {
  InformationCard,
  InfoRowType,
} from 'components/Common/InformationCard/InformationCard'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { contentStyle, StyledContainer } from './style'

type ContactInfoProps = {
  phone: string
  email: string
  contentSx?: CSSObject
}

export const ContactContainer = ({
  phone,
  email,
  contentSx,
}: ContactInfoProps) => {
  const { text } = useTranslation(textFiles.INSPECTION_DETAIL)

  const { provider: translation } = text

  const informationRows: InfoRowType[] = [
    {
      icon: <CallIcon />,
      title: phone,
      subtitle: translation.contactPhone,
    },
    {
      icon: <MailIcon />,
      title: email,
      subtitle: 'Email',
    },
  ]

  return (
    <StyledContainer
      title={translation.contactTitle}
      description={translation.contactSubtitle}
      contentStyle={contentStyle}
      sx={{ ...contentSx }}
    >
      <InformationCard rows={informationRows} />
    </StyledContainer>
  )
}
