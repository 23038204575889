import React, { useState } from 'react'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { Fade, Popper, Typography } from '@mui/material'

import Box from 'components/Common/Box'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { StyledContainer } from './style'

const TimeSensitivePopper = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const {
    text: { timeSensitivePopper: translation },
  } = useTranslation(textFiles.GENERAL)

  const open = Boolean(anchorEl)
  const maintainPopover = () => {
    setAnchorEl(anchorEl)
  }
  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopperClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        onMouseEnter={handlePopperOpen}
        onMouseLeave={handlePopperClose}
        height="24px"
        width="24px"
      >
        <DateRangeIcon sx={{ color: colors.gray }} />
      </Box>

      <Popper
        open={open}
        onMouseEnter={maintainPopover}
        onMouseLeave={handlePopperClose}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        style={{
          zIndex: 1500,
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <StyledContainer>
              <Typography
                variant="body1"
                sx={{
                  color: colors.gray,
                  fontWeight: FONT_WEIGHT.MEDIUM,

                  span: {
                    color: colors.blue,
                  },
                }}
              >
                {translation.firstPart} <span>{translation.colorText}</span>{' '}
                {translation.secondPart}
              </Typography>
            </StyledContainer>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export default TimeSensitivePopper
