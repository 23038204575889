import { styled } from '@mui/material'

import Box from 'components/Common/Box'

import { boxShadow, colors } from 'styles/theme'

export const StyledContainer = styled(Box)({
  backgroundColor: colors.commonWhite,
  borderRadius: '4px',
  boxShadow,
  height: '135px',
  width: '285px',
  padding: '0.75rem',
  display: 'flex',
  alignItems: 'center',
})
