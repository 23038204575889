import { COUNTRY_VERSION_CREATION_OPTION } from 'constants/countryVersion'
import {
  CountryVersionCreationAction,
  CountryVersionCreationModel,
} from 'models/countryVersionCreation'

const countryVersionCreationReducer = (
  state: CountryVersionCreationModel,
  action: CountryVersionCreationAction
): CountryVersionCreationModel => {
  switch (action.type) {
    case COUNTRY_VERSION_CREATION_OPTION.UPDATE_COUNTRY_VERSION_INFORMATION:
      return { ...state, countryVersionInformation: action.payload }

    default:
      return state
  }
}

export default countryVersionCreationReducer
