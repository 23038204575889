import { gql } from '@apollo/client'

export const LIST_CITIES = gql`
  query listCities($input: FilterInput!) {
    listCities: listCities(input: $input) {
      count
      data {
        id
        name
        slug
        status
        createdAt
        state {
          name
        }
      }
    }
  }
`
