import { Card, Container, CSSObject, styled } from '@mui/material'

import { colors } from 'styles/theme'

const backdropStyle = {
  sx: {
    bgcolor: colors.backdrop,
  },
}

const BottomContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'space-evenly',
  width: '100%',
  textAlign: 'center',
  position: 'absolute',
  bottom: 0,
  background: colors.background,
  height: 90,
  paddingTop: 30,
  paddingBottom: 30,
})

const CardStyler = (
  cardStyle: CSSObject | null,
  size: 'small' | 'medium' | 'large' | 'customDimension',
  customDimension?: { width: number; height: number }
) => {
  const getSize = (inputSize: string) => {
    if (inputSize === 'large') {
      return { height: 609, width: 839 }
    }
    if (inputSize === 'medium') {
      return { height: 309, width: 384 }
    }
    if (inputSize === 'small') {
      return { height: 204, width: 384 }
    }
    return customDimension
  }
  const actualSize = getSize(size)
  return styled(Card)(
    cardStyle || {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: actualSize!.width,
      height: actualSize!.height,
      bgcolor: 'background.paper',
      boxShadow: '1',
      p: 4,
      borderRadius: 10,
    }
  )
}

export { backdropStyle, BottomContainer, CardStyler }
