import { FEATURE_CREATION_OPTION } from 'constants/feature'
import {
  FeatureCreationAction,
  FeatureCreationModel,
} from 'models/featureCreation'

const featureCreationReducer = (
  state: FeatureCreationModel,
  action: FeatureCreationAction
): FeatureCreationModel => {
  switch (action.type) {
    case FEATURE_CREATION_OPTION.UPDATE_FEATURE_INFORMATION:
      return { ...state, featureInformation: action.payload }

    default:
      return state
  }
}

export default featureCreationReducer
