import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { FieldArray, FormikProvider, getIn, useFormik } from 'formik'
import * as yup from 'yup'

/* eslint-disable jsx-a11y/label-has-associated-control */

type QuestionType = {
  statement: string
  isRequired: boolean
  hasPicture: boolean
  score: number
}

type CheckpointType = {
  id: string | number
  title: string
  reason: string
  description: string
  questions: QuestionType[]
}

export type ValuesType = {
  stepName: string
  stepDescription: string
  checkpoints: CheckpointType[]
}

const initialValues: ValuesType = {
  stepName: '',
  stepDescription: '',
  checkpoints: [
    {
      id: 1,
      title: '',
      reason: '',
      description: '',
      questions: [
        {
          statement: '',
          isRequired: false,
          hasPicture: false,
          score: 5,
        },
        {
          statement: '',
          isRequired: false,
          hasPicture: true,
          score: 15,
        },
      ],
    },
  ],
}

const validationSchema = yup.object({
  stepName: yup.string().required(),
  stepDescription: yup.string().required(),
  checkpoints: yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().required(),
        reason: yup.string().required(),
        description: yup.string().required(),
        questions: yup
          .array()
          .of(
            yup.object().shape({
              statement: yup.string().required(),
              isRequired: yup.boolean(),
              hasPicture: yup.boolean(),
              score: yup.number(),
            })
          )
          .min(1, 'At least one question'),
      })
    )
    .min(1, 'At least one checkpoint'),
})

const Test = () => {
  const [stepValues, setStepValues] = useState<ValuesType | null>(null)
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setStepValues(values)
      // console.log(JSON.stringify(values, null, 2))
    },
  })
  if (stepValues !== null) {
    return null
  }
  return (
    <div>
      <h1>Template</h1>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="stepName"
            name="stepName"
            label="Step Name"
            value={formik.values.stepName}
            onChange={formik.handleChange}
            error={formik.touched.stepName && Boolean(formik.errors.stepName)}
            helperText={formik.touched.stepName && formik.errors.stepName}
          />
          <TextField
            fullWidth
            id="stepDescription"
            name="stepDescription"
            label="Step Description"
            value={formik.values.stepDescription}
            onChange={formik.handleChange}
            error={
              formik.touched.stepDescription &&
              Boolean(formik.errors.stepDescription)
            }
            helperText={
              formik.touched.stepDescription && formik.errors.stepDescription
            }
          />
          <FieldArray
            name="checkpoints"
            render={({ remove, push }) => (
              <Box>
                {formik.values.checkpoints.length > 0 &&
                  formik.values.checkpoints.map((checkpoint, index) => (
                    <Box key={checkpoint.id}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          margin: '1em 0',
                        }}
                      >
                        <TextField
                          fullWidth
                          name={`checkpoints.${index}.title`}
                          value={checkpoint.title}
                          onChange={formik.handleChange}
                          placeholder="Checkpoint Title"
                          error={
                            getIn(
                              formik.touched,
                              `checkpoints.${index}.title`
                            ) &&
                            Boolean(
                              getIn(formik.errors, `checkpoints.${index}.title`)
                            )
                          }
                          helperText={
                            getIn(
                              formik.touched,
                              `checkpoints.${index}.title`
                            ) &&
                            getIn(formik.errors, `checkpoints.${index}.title`)
                          }
                        />
                        <TextField
                          fullWidth
                          name={`checkpoints.${index}.reason`}
                          value={checkpoint.reason}
                          onChange={formik.handleChange}
                          placeholder="Checkpoint Reason"
                          error={
                            getIn(
                              formik.touched,
                              `checkpoints.${index}.reason`
                            ) &&
                            Boolean(
                              getIn(
                                formik.errors,
                                `checkpoints.${index}.reason`
                              )
                            )
                          }
                          helperText={
                            getIn(
                              formik.touched,
                              `checkpoints.${index}.reason`
                            ) &&
                            getIn(formik.errors, `checkpoints.${index}.reason`)
                          }
                        />
                      </Box>
                      <TextField
                        fullWidth
                        name={`checkpoints.${index}.description`}
                        value={checkpoint.description}
                        onChange={formik.handleChange}
                        placeholder="Checkpoint Description"
                        multiline
                        minRows={3}
                        maxRows={5}
                        error={
                          getIn(
                            formik.touched,
                            `checkpoints.${index}.description`
                          ) &&
                          Boolean(
                            getIn(
                              formik.errors,
                              `checkpoints.${index}.description`
                            )
                          )
                        }
                        helperText={
                          getIn(
                            formik.touched,
                            `checkpoints.${index}.description`
                          ) &&
                          getIn(
                            formik.errors,
                            `checkpoints.${index}.description`
                          )
                        }
                      />
                      <Box>
                        <FieldArray
                          name={`checkpoints.${index}.questions`}
                          render={({
                            remove: questionRemove,
                            push: questionPush,
                          }) => (
                            <Box>
                              {checkpoint.questions.length > 0 &&
                                checkpoint.questions.map((question, qIndex) => (
                                  <Box key={`Q-X${qIndex.toString()}`}>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        margin: '1em 0',
                                      }}
                                    >
                                      <TextField
                                        fullWidth
                                        name={`checkpoints.${index}.questions.${qIndex}.statement`}
                                        value={question.statement}
                                        onChange={formik.handleChange}
                                        placeholder="Question Statement"
                                        error={
                                          getIn(
                                            formik.touched,
                                            `checkpoints.${index}.questions.${qIndex}.statement`
                                          ) &&
                                          Boolean(
                                            getIn(
                                              formik.errors,
                                              `checkpoints.${index}.questions.${qIndex}.statement`
                                            )
                                          )
                                        }
                                        helperText={
                                          getIn(
                                            formik.touched,
                                            `checkpoints.${index}.questions.${qIndex}.statement`
                                          ) &&
                                          getIn(
                                            formik.errors,
                                            `checkpoints.${index}.questions.${qIndex}.statement`
                                          )
                                        }
                                      />
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <label
                                          htmlFor={`checkpoints.${index}.questions.${qIndex}.isRequired`}
                                        >
                                          Is required?
                                        </label>
                                        <TextField
                                          type="checkbox"
                                          name={`checkpoints.${index}.questions.${qIndex}.isRequired`}
                                          value={question.isRequired}
                                          onChange={formik.handleChange}
                                          sx={{
                                            width: 'auto',
                                            height: 'auto',
                                            border: 'none',
                                            outline: '0px',
                                          }}
                                        />
                                      </Box>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <label>Has Picture?</label>
                                        <TextField
                                          type="checkbox"
                                          name={`checkpoints.${index}.questions.${qIndex}.hasPicture`}
                                          value={question.hasPicture}
                                          onChange={formik.handleChange}
                                          sx={{
                                            width: 'auto',
                                            height: 'auto',
                                            border: 'none',
                                            outline: '0px',
                                          }}
                                        />
                                      </Box>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <label>Value</label>
                                        <TextField
                                          type="number"
                                          name={`checkpoints.${index}.questions.${qIndex}.score`}
                                          value={question.score}
                                          onChange={formik.handleChange}
                                          sx={{
                                            width: 'auto',
                                            height: 'auto',
                                            border: 'none',
                                            outline: '0px',
                                          }}
                                        />
                                      </Box>
                                      <Button
                                        type="button"
                                        className="secondary"
                                        onClick={() => questionRemove(qIndex)}
                                      >
                                        Delete Question
                                      </Button>
                                    </Box>
                                  </Box>
                                ))}
                              <Button
                                type="button"
                                className="secondary"
                                onClick={() =>
                                  questionPush({
                                    statement: '',
                                    isRequired: false,
                                    hasPicture: true,
                                    score: 15,
                                  })
                                }
                              >
                                `Add `another question
                              </Button>
                            </Box>
                          )}
                        />
                      </Box>
                      <Button
                        type="button"
                        className="secondary"
                        onClick={() => remove(index)}
                      >
                        Delete checkpoint
                      </Button>
                    </Box>
                  ))}
                <Button
                  type="button"
                  className="secondary"
                  onClick={() =>
                    push({
                      title: '',
                      reason: '',
                      description: '',
                      questions: [
                        {
                          statement: '',
                          isRequired: false,
                          hasPicture: false,
                          score: 5,
                        },
                        {
                          statement: '',
                          isRequired: false,
                          hasPicture: true,
                          score: 15,
                        },
                      ],
                    })
                  }
                >
                  Add Checkpoint
                </Button>
              </Box>
            )}
          />
          <Button type="submit">Print Result</Button>
        </form>
      </FormikProvider>
    </div>
  )
}

export default Test
