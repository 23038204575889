import { Box, styled } from '@mui/material'

import { colors } from 'styles/theme'

export const StyledBox = styled(Box)({
  borderRadius: '10px',
  backgroundColor: colors.commonWhite,
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 2rem',
})

export const StyledContent = styled(Box)({
  marginTop: '1rem',
})

export const StyledTitleContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})
