import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/client'

import Autocomplete, { AutocompleteItem } from 'components/Common/Autocomplete'
import ButtonContainer from 'components/Template/Creation/ButtonContainer'

import { VEHICLE_CREATION_OPTION } from 'constants/Inventory/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import {
  BaseEntity,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { ModelTrimYears } from 'models/services/car'
import { FirstStepModel } from 'models/services/PreInventory/creation'
import { ExtendedStatus } from 'models/services/status'
import { VehicleCreationProps } from 'utils/Inventory/creation'

import { GET_INSPECTION_MODELS } from 'graphQL/Common/CarFeature/queries'
import {
  GET_INSPECTION_TRIM_LEVELS,
  GET_INSPECTION_TRIM_YEARS,
} from 'graphQL/Inspection/Creation/queries'

import {
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

type VehicleInfoError = {
  make: boolean
  model: boolean
  year: boolean
  trim: boolean
  status: boolean
}

const initialErrors: VehicleInfoError = {
  make: false,
  model: false,
  year: false,
  trim: false,
  status: false,
}

const GeneralInformation = ({
  vehicleData,
  updateVehicleData,
  handleSecondaryContinue,
  brands,
  statusList,
}: VehicleCreationProps) => {
  const {
    vehicleInformation: { firstStep },
  } = vehicleData
  const { currentModelList, currentTrimLevelList, currentYearList } = firstStep

  const [vehicleInfo, setVehicleInfo] = useState<FirstStepModel>(firstStep)
  const [modelsList, setModelsList] = useState<BaseEntity[]>(currentModelList)
  const [trimYearsList, setTrimYearsList] =
    useState<BaseEntity[]>(currentYearList)
  const [trimLevelsList, setTrimLevelsList] =
    useState<BaseEntity[]>(currentTrimLevelList)

  const [errors, setErrors] = useState<VehicleInfoError>(initialErrors)

  const {
    text: {
      subSteps: { firstStep: translation },
    },
  } = useTranslation(textFiles.VEHICLE_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const [fetchModels, { loading: modelsLoading }] = useLazyQuery<
    GenericData<BaseEntity[]>,
    FilterInputVariable
  >(GET_INSPECTION_MODELS, {
    onCompleted(response) {
      setModelsList(response.data)
    },
  })

  const [fetchYears, { loading: yearsLoading }] = useLazyQuery<
    GenericData<ModelTrimYears>,
    GenericInputVariable<string>
  >(GET_INSPECTION_TRIM_YEARS, {
    onCompleted(response) {
      setTrimYearsList(
        response.data.trimYears.map((trimYear) => {
          return {
            name: String(trimYear),
            id: trimYear,
          }
        })
      )
    },
  })

  const [fetchTrims, { loading: trimsLoading }] = useLazyQuery<
    GenericData<BaseEntity[]>,
    FilterInputVariable
  >(GET_INSPECTION_TRIM_LEVELS, {
    onCompleted(response) {
      setTrimLevelsList(response.data)
    },
  })

  const handleSelectBrand = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        make: value,
        model: null,
        trimLevel: null,
        vehicleYear: null,
      }
    })
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        make: false,
      }
    })
    fetchModels({
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
          where: {
            brand_eq: value.id,
          },
        },
      },
    })
  }

  const handleSelectModel = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        model: value,
        vehicleYear: null,
        trimLevel: null,
      }
    })
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        model: false,
      }
    })
    fetchYears({
      variables: {
        input: value.id as string,
      },
    })
  }

  const handleSelectYear = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        vehicleYear: value,
        trimLevel: null,
      }
    })
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        year: false,
      }
    })

    fetchTrims({
      variables: {
        input: {
          sort: {
            name: 'asc',
          },
          where: {
            year: value.id,
            carModel: vehicleInfo.model?.id,
          },
        },
      },
    })
  }

  const handleSelectTrim = (value: AutocompleteItem) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        trimLevel: value,
      }
    })
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        trim: false,
      }
    })

    updateVehicleData({
      type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION,
      payload: {
        ...vehicleData.vehicleInformation,
        thirdStep: null,
      },
    })
  }

  const handleSelectStatus = (value: ExtendedStatus) => {
    setVehicleInfo((prevInfo) => {
      return {
        ...prevInfo,
        status: value,
      }
    })
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        status: false,
      }
    })
  }

  const handleSubmit = () => {
    const { make, model, vehicleYear, trimLevel, status } = vehicleInfo

    if (!make || !model || !trimLevel || !vehicleYear) {
      setErrors({
        make: !make && true,
        model: !model && true,
        year: !vehicleYear && true,
        trim: !trimLevel && true,
        status: !status && true,
      })
      return
    }
    setErrors(initialErrors)
    updateVehicleData({
      type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION,
      payload: {
        ...vehicleData.vehicleInformation,
        firstStep: {
          currentModelList: modelsList,
          currentTrimLevelList: trimLevelsList,
          currentYearList: trimYearsList,
          make,
          model,
          trimLevel,
          vehicleYear,
          status,
        },
      },
    })
    handleSecondaryContinue()
  }

  const getVehicleName = () => {
    const { make, model, trimLevel, vehicleYear } = vehicleInfo

    if (make && model && trimLevel && vehicleYear)
      return `${make.name} ${model.name} ${vehicleYear.name} ${trimLevel.name}`

    return ''
  }

  return (
    <StyledContainer>
      <StyledForm>
        <div>
          <StyledTextFieldContainer title={translation.make}>
            <Autocomplete
              options={brands}
              onChangeHandler={handleSelectBrand}
              placeholder={translation.make}
              value={vehicleInfo.make}
              disablePortal={false}
              error={errors.make}
              testId="makes-autocomplete"
            />
            {errors.make && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={translation.model}>
            <Autocomplete
              options={modelsList}
              onChangeHandler={handleSelectModel}
              placeholder={translation.model}
              value={vehicleInfo.model}
              disabled={modelsLoading || !vehicleInfo.make}
              disablePortal={false}
              error={errors.model}
              testId="models-autocomplete"
            />
            {errors.model && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={translation.vehicleYear}>
            <Autocomplete
              options={trimYearsList}
              onChangeHandler={handleSelectYear}
              placeholder={translation.vehicleYear}
              value={vehicleInfo.vehicleYear}
              disabled={yearsLoading || !vehicleInfo.model}
              disablePortal={false}
              error={errors.year}
              testId="years-autocomplete"
            />
            {errors.year && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={translation.trimLevel}>
            <Autocomplete
              options={trimLevelsList}
              onChangeHandler={handleSelectTrim}
              placeholder={translation.trimLevel}
              value={vehicleInfo.trimLevel}
              disabled={trimsLoading || !vehicleInfo.vehicleYear}
              disablePortal={false}
              error={errors.trim}
              testId="trims-autocomplete"
            />
            {errors.trim && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={translation.vehicleName}>
            <StyledTextField
              variant="outlined"
              name="vehicleName"
              value={getVehicleName()}
              placeholder={translation.vehicleName}
              disabled
            />
          </StyledTextFieldContainer>

          <StyledTextFieldContainer title={translation.status}>
            <Autocomplete
              options={statusList}
              onChangeHandler={(value: AutocompleteItem) => {
                handleSelectStatus(value as ExtendedStatus)
              }}
              placeholder={translation.status}
              value={vehicleInfo.status}
              disablePortal={false}
              error={errors.status}
              testId="statuses-autocomplete"
            />
            {errors.status && (
              <StyledErrorMessage text={validationText.fieldRequired} />
            )}
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonContainer isFirstStep nextFunction={handleSubmit} />
    </StyledContainer>
  )
}

export default GeneralInformation
