import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import {
  ApolloError,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client'
import { capitalize, Typography } from '@mui/material'
import { uploadImageService, uploadImagesService } from 'services/uploadImage'

import { DetailHeader } from 'components/CarSettings/Common/Detail/DetailHeader'
import { DetailSubHeader } from 'components/CarSettings/Common/Detail/DetailSubHeader'
import { Image } from 'components/Common/Carousel'
import CopyToClipboard from 'components/Common/CopyToClipboard'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import DetailNavTab, { NavBarItem } from 'components/General/DetailNavTab'
import TabPanel from 'components/Inspection/Detail/TabPanel'
import InventoryErrorModal from 'components/Inventory/Detail/ErrorModal'
import Gallery, {
  PictureUploadAction,
} from 'components/Inventory/Detail/Gallery'
import RelatedVehicles from 'components/Inventory/Detail/RelatedVehicles'
import VehicleInformation from 'components/Inventory/Detail/VehicleInformation'
import VehiclePricing from 'components/Inventory/Detail/VehiclePricing'
import VehicleSummary from 'components/Inventory/Detail/VehicleSummary'

import { PICTURE_TYPE_OPTION } from 'constants/Inventory/detail'
import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { UserRoles } from 'models/role'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
  GenericUpdateVariable,
} from 'models/services/base'
import {
  InventoryCar,
  UpdateCarInput,
  UpdateCarMutationInput,
  VehicleInformationSelectOptions,
} from 'models/services/inventory/detail'
import { ExtendedStatus } from 'models/services/status'
import { InventorySlugStatus } from 'models/status'
import { validateGraphQLErrorCode } from 'utils/error'
import { createPictureList } from 'utils/Inventory/detail'
import { generateTabItems } from 'utils/tabs'

import { CustomErrorStructure } from 'graphQL/client'
import { GET_VEHICLE_INFORMATION_SELECT_OPTIONS } from 'graphQL/Common/Vehicle/queries'
import {
  FILL_CAR_DATA,
  PUBLISH_CAR,
  REJECT_CAR_REVIEW,
  SET_NOT_AVAILABLE_CAR_STATUS,
  SET_SOLD_CAR_STATUS,
  SET_TO_REVIEW_CAR_STATUS,
  UPDATE_CAR,
} from 'graphQL/Inventory/Detail/mutations'
import { GET_INVENTORY_CAR_BY_ID } from 'graphQL/Inventory/Detail/queries'
import { GET_CAR_STATUSES } from 'graphQL/Inventory/Listing/queries'

import { ContentContainer, Layout } from 'styles/inspection/detail'
import { colors } from 'styles/theme'

export const InventoryDetailPage = () => {
  const { carId } = useParams<{ carId: string }>()
  const history = useHistory()
  const { show } = useNotification()
  const { validateAllowedRoles } = useUser()
  /**
   * Using location to detect if this component was called via PreInventory or Inventory listing
   */
  const location = useLocation()
  const comesFromInventoryListingPage =
    location.state && location.state.comesFromInventoryListingPage
      ? location.state.comesFromInventoryListingPage
      : false

  const {
    text: { general: translation },
  } = useTranslation(textFiles.INVENTORY_DETAIL)
  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const [carData, setCarData] = useState<InventoryCar | null>(null)
  const [selectOptions, setSelectOptions] =
    useState<VehicleInformationSelectOptions | null>(null)
  const [statusList, setStatusList] = useState<ExtendedStatus[]>([])
  const [tab, setTab] = useState<number>(0)
  const [apolloError, setApolloError] = useState<ApolloError | null>(null)
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false)
  const [errorList, setErrorList] = useState<CustomErrorStructure[]>([])
  const [status, setStatus] = useState<ExtendedStatus | null>(null)
  const [exteriorPictures, setExteriorPictures] = useState<Image[]>([])
  const [interiorPictures, setInteriorPictures] = useState<Image[]>([])
  const [imageUploading, setImageUploading] = useState<boolean>(false)

  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })

  const filterStatus = (filterStatusList: string[]) => {
    return statusList.filter((statusItem) =>
      filterStatusList.includes(statusItem.slug)
    )
  }

  const getStatusList = (currentStatus: ExtendedStatus): ExtendedStatus[] => {
    if (currentStatus.slug === InventorySlugStatus.PENDING) {
      if (
        validateAllowedRoles([UserRoles.ADMIN, UserRoles.INVENTORY_SUPERVISOR])
      )
        return filterStatus([
          InventorySlugStatus.TO_REVIEW,
          InventorySlugStatus.AVAILABLE,
        ])

      return filterStatus([InventorySlugStatus.TO_REVIEW])
    }
    if (currentStatus.slug === InventorySlugStatus.TO_REVIEW) {
      return filterStatus([
        InventorySlugStatus.PENDING,
        InventorySlugStatus.AVAILABLE,
        InventorySlugStatus.NOT_AVAILABLE,
      ])
    }
    if (currentStatus.slug === InventorySlugStatus.AVAILABLE) {
      return filterStatus([
        InventorySlugStatus.NOT_AVAILABLE,
        InventorySlugStatus.SOLD,
      ])
    }
    return []
  }

  const { loading: statusLoading } = useQuery<GenericData<ExtendedStatus[]>>(
    GET_CAR_STATUSES,
    {
      onCompleted(response) {
        setStatusList(response.data)
      },
    }
  )

  const { loading: carLoading, refetch: refetchInventoryCar } = useQuery<
    GenericData<InventoryCar>,
    GenericInputVariable<string>
  >(GET_INVENTORY_CAR_BY_ID, {
    variables: {
      input: carId,
    },
    onError(error) {
      setApolloError(error)
    },
    onCompleted(response) {
      const { data } = response
      setCarData(data)
      setStatus(data.status)
      setExteriorPictures(createPictureList(data.exteriorPictures))
      setInteriorPictures(createPictureList(data.interiorPictures))
    },
  })

  const [
    getVehicleSelectOptions,
    { loading: optionsLoading, called: getOptionsCalled },
  ] = useLazyQuery<VehicleInformationSelectOptions>(
    GET_VEHICLE_INFORMATION_SELECT_OPTIONS,
    {
      onCompleted(response) {
        setSelectOptions(response)
      },
      onError() {
        show({
          updatedSeverity: 'error',
          message: translation.getOptionsFail,
        })
      },
    }
  )

  const [updateCar, { loading: updateLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericUpdateVariable<UpdateCarMutationInput>
  >(UPDATE_CAR, {
    onError() {
      show({
        updatedSeverity: 'error',
        message: translation.updateFail,
      })
    },
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: translation.updateSuccess,
      })
    },
  })

  const [fillCarData, { loading: fillDataLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericUpdateVariable<UpdateCarMutationInput>
  >(FILL_CAR_DATA, {
    onError() {
      show({
        updatedSeverity: 'error',
        message: translation.updateFail,
      })
    },
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: translation.updateSuccess,
      })
    },
  })

  const [setToReviewCar, { loading: toReviewLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<string>
  >(SET_TO_REVIEW_CAR_STATUS, {
    variables: {
      input: carId,
    },
  })

  const [rejectCarReview, { loading: rejectCarReviewLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<string>
  >(REJECT_CAR_REVIEW, {
    variables: {
      input: carId,
    },
  })

  const [publishCar, { loading: publishCarLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<string>
  >(PUBLISH_CAR, {
    variables: {
      input: carId,
    },
  })

  const [
    setNotAvailableCarStatus,
    { loading: setNotAvailableCarStatusLoading },
  ] = useMutation<GenericData<BaseIdEntity>, GenericInputVariable<string>>(
    SET_NOT_AVAILABLE_CAR_STATUS,
    {
      variables: {
        input: carId,
      },
    }
  )

  const [setSoldCarStatus, { loading: setSoldCarStatusLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<string>
  >(SET_SOLD_CAR_STATUS, {
    variables: {
      input: carId,
    },
  })

  const updateStatusMutations = {
    [InventorySlugStatus.TO_REVIEW as string]: setToReviewCar,
    [InventorySlugStatus.PENDING as string]: rejectCarReview,
    [InventorySlugStatus.AVAILABLE as string]: publishCar,
    [InventorySlugStatus.NOT_AVAILABLE as string]: setNotAvailableCarStatus,
    [InventorySlugStatus.SOLD as string]: setSoldCarStatus,
  }

  const carName = carData
    ? `${carData.trimLevel.name} ${carData.year} (${carData.brand.name} ${carData.carModel.name})`
    : ''

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTab(value)
    history.replace(`#${initialItems[value].url}`)
  }

  const handleGetOptions = async () => {
    try {
      if (!getOptionsCalled || !selectOptions) {
        const response = await getVehicleSelectOptions()
        if (response.error) return false
      }
      return true
    } catch {
      return false
    }
  }

  const handleUpdate = async (updateData: UpdateCarInput) => {
    const { vehicleInformation, vehiclePrice, vehicleImage } = updateData
    const data: UpdateCarMutationInput = {
      ...vehicleInformation,
      ...vehicleImage,
      chassisNumber: vehicleInformation?.chassisNumber
        ? vehicleInformation.chassisNumber
        : undefined,
      vinNumber: vehicleInformation?.vinNumber
        ? vehicleInformation.vinNumber
        : undefined,
      priceInfo: vehiclePrice
        ? {
            basePrice: vehiclePrice.basePrice,
            fee: vehiclePrice.fee,
            transfer: vehiclePrice.transfer,
            customPrice: vehiclePrice.customPrice,
            licensePlate: vehiclePrice.licensePlate,
            repairCost: vehiclePrice.repairCost,
            warranty: vehiclePrice.warranty,
          }
        : undefined,
    }

    try {
      const response = validateAllowedRoles([
        UserRoles.ADMIN,
        UserRoles.INVENTORY_SUPERVISOR,
      ])
        ? await updateCar({
            variables: {
              input: {
                where: {
                  id: carId,
                },
                data: {
                  ...data,
                },
              },
            },
          })
        : await fillCarData({
            variables: {
              input: {
                where: {
                  id: carId,
                },
                data: {
                  ...data,
                },
              },
            },
          })
      if (response.errors) return false
      const refetchResponse = await refetchInventoryCar()

      if (refetchResponse.error) return true
      return refetchResponse.data.data
    } catch {
      return false
    }
  }

  const handleUpdateStatus = (newStatus: ExtendedStatus) => {
    const statusSlug = newStatus.slug

    if (statusSlug in updateStatusMutations) {
      updateStatusMutations[statusSlug]({
        onError(error) {
          const { filteredErrors } = validateGraphQLErrorCode(
            error,
            'ENTITY_NOT_COMPLETED'
          )
          if (filteredErrors.length > 0) {
            setErrorModalOpen(true)
            const cleanedErrors = filteredErrors[0].errors.map(
              (currentError) => {
                const splitField = currentError.field.split('.')
                if (splitField.length > 1) {
                  return { ...currentError, field: splitField[1] }
                }
                return currentError
              }
            )
            setErrorList(
              cleanedErrors.map((currentError) => {
                const { field } = currentError
                const newField = capitalize(field)
                return { ...currentError, field: newField }
              })
            )
          }
        },
        onCompleted() {
          refetchInventoryCar()
          show({
            updatedSeverity: 'success',
            message: translation.updateSuccess,
          })
        },
      })
    }
  }

  const isEditable = (): boolean => {
    const isAdminUser = validateAllowedRoles([
      UserRoles.ADMIN,
      UserRoles.INVENTORY_SUPERVISOR,
    ])
    const editableStatus: string[] = [
      InventorySlugStatus.AVAILABLE,
      InventorySlugStatus.PENDING,
      InventorySlugStatus.TO_REVIEW,
    ]

    if (isAdminUser && status && editableStatus.includes(status.slug))
      return true

    const isSpecialistUser = validateAllowedRoles([
      UserRoles.INVENTORY_SPECIALIST,
    ])
    if (
      isSpecialistUser &&
      status &&
      status.slug === InventorySlugStatus.PENDING
    )
      return true

    return false
  }

  const handleStatusChange = (newStatus: ExtendedStatus) => {
    if (newStatus !== status) {
      handleUpdateStatus(newStatus)
    }
  }

  const handleUploadMainPicture = async (file: File) => {
    setImageUploading(true)
    try {
      const image = await uploadImageService(file, true)
      const response = await handleUpdate({
        vehicleImage: {
          mainPicture: image.data,
        },
      })
      setImageUploading(false)
      if (!response) return false
      return true
    } catch {
      show({
        updatedSeverity: 'error',
        message: generalText.notificationText.uploadError,
      })
      setImageUploading(false)
      return false
    }
  }

  const handleUploadMultiplePictures = async (
    files: File[],
    uploadAction: PictureUploadAction
  ) => {
    setImageUploading(true)
    try {
      const images = await uploadImagesService(files)

      if (!images || !images.data) {
        setImageUploading(false)
        return false
      }

      let newImages: string[] = []
      const { type } = uploadAction

      newImages = uploadAction.payload.map((currentImage) => currentImage.url)
      newImages = [...newImages, ...images.data.urls]

      const response = await handleUpdate({
        vehicleImage: {
          exteriorPictures:
            type === PICTURE_TYPE_OPTION.EXTERIOR ? newImages : undefined,
          interiorPictures:
            type === PICTURE_TYPE_OPTION.INTERIOR ? newImages : undefined,
        },
      })
      setImageUploading(false)
      if (!response) return false
      return true
    } catch {
      show({
        updatedSeverity: 'error',
        message: generalText.notificationText.uploadError,
      })
      setImageUploading(false)
      return false
    }
  }

  const handleDeletePicture = async (id: string, type: PICTURE_TYPE_OPTION) => {
    setImageUploading(true)
    try {
      let newImages: string[] = []

      if (type === PICTURE_TYPE_OPTION.EXTERIOR) {
        newImages = exteriorPictures
          .filter((currentImage) => currentImage.id !== id)
          .map((currentImage) => currentImage.url)
      } else if (type === PICTURE_TYPE_OPTION.INTERIOR) {
        newImages = interiorPictures
          .filter((currentImage) => currentImage.id !== id)
          .map((currentImage) => currentImage.url)
      }
      const response = await handleUpdate({
        vehicleImage: {
          exteriorPictures:
            type === PICTURE_TYPE_OPTION.EXTERIOR ? newImages : undefined,
          interiorPictures:
            type === PICTURE_TYPE_OPTION.INTERIOR ? newImages : undefined,
        },
      })
      setImageUploading(false)
      if (!response) return false
      return true
    } catch {
      setImageUploading(false)
      return false
    }
  }

  useEffect(() => {
    if (history.location.hash) {
      let initialValue = 0
      const thisUrl = history.location.hash.split('#')[1]
      Object.keys(translation.tabs).forEach((key, index) => {
        if (key === thisUrl) {
          initialValue = index
        }
      })
      setTab(initialValue)
    }
  }, [history.location.hash, translation.tabs])

  const isUpdateLoading =
    updateLoading ||
    fillDataLoading ||
    toReviewLoading ||
    rejectCarReviewLoading ||
    publishCarLoading ||
    setNotAvailableCarStatusLoading ||
    imageUploading ||
    setSoldCarStatusLoading

  if (carLoading || statusLoading)
    return <LoadingAnimation showAnimation={carLoading || statusLoading} />

  if (apolloError) return <Redirect to={routes.NOT_FOUND_ERROR} />

  return carData && status ? (
    <Layout>
      <DetailHeader
        editable
        title={carName}
        backButtonText={translation.backButton}
        handleStatusChange={handleStatusChange}
        loading={isUpdateLoading}
        statusList={getStatusList(status)}
        status={status}
      />
      <DetailSubHeader
        component={
          <Typography variant="body2" color={colors.blue} marginLeft="0.5rem">
            {'ID: '} <CopyToClipboard textToCopy={carData.id} />
          </Typography>
        }
      />
      <DetailNavTab
        tab={tab}
        handleTabChange={handleTabChange}
        items={initialItems}
      />

      <ContentContainer
        sx={{
          overflow: optionsLoading ? 'hidden' : 'auto',
          position: optionsLoading ? 'relative' : 'unset',
        }}
      >
        <LoadingAnimation
          showAnimation={optionsLoading || isUpdateLoading}
          styles={{ position: 'absolute', zIndex: 100 }}
        />
        <TabPanel value={tab} index={0}>
          <VehicleSummary
            car={carData}
            comesFromInventoryListingPage={comesFromInventoryListingPage}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <VehicleInformation
            car={carData}
            isLoading={optionsLoading || isUpdateLoading}
            handleGetOptions={handleGetOptions}
            selectOptions={selectOptions}
            handleUpdate={handleUpdate}
            isEditable={isEditable()}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <VehiclePricing
            car={carData}
            handleUpdate={handleUpdate}
            isLoading={isUpdateLoading}
            isEditable={isEditable()}
          />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <Gallery
            exteriorPictures={exteriorPictures}
            interiorPictures={interiorPictures}
            isLoading={isUpdateLoading}
            uploadMultiplePictures={handleUploadMultiplePictures}
            uploadMainPicture={handleUploadMainPicture}
            mainPicture={carData.mainPicture}
            status={status.slug}
            deletePicture={handleDeletePicture}
            isEditable={isEditable()}
          />
        </TabPanel>
        <TabPanel value={tab} index={4}>
          <RelatedVehicles relatedCars={carData.relatedCars} />
        </TabPanel>
      </ContentContainer>
      <InventoryErrorModal
        open={errorModalOpen}
        onConfirmHandler={() => {
          setErrorModalOpen(false)
        }}
        errorList={errorList}
        header={translation.errorHeader}
        confirmButtonText={translation.errorGoBackButton}
      />
    </Layout>
  ) : null
}

export default InventoryDetailPage
