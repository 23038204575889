import { styled } from '@mui/material'

import Box from 'components/Common/Box'

import { BREAK_POINTS } from 'styles/breakpoints'

export const GridBox = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'grid',
  columnGap: '40px !important',
  gridTemplateColumns: '27% 32% 34% !important',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '50% 45% !important',
    columnGap: '10px',
    rowGap: '0px',
  },
})
