import { gql } from '@apollo/client'

import {
  INSPECTION_WEEK_SCHEDULE_FRAGMENT,
  TEST_DRIVE_WEEK_SCHEDULE_FRAGMENT,
} from './queries'

export const UPDATE_TEST_DRIVE_AND_INSPECTIONS_WEEK_CALENDAR = gql`
  ${INSPECTION_WEEK_SCHEDULE_FRAGMENT}
  ${TEST_DRIVE_WEEK_SCHEDULE_FRAGMENT}
  mutation updateTestDriveWeekCalendar(
    $testDrive: UpdateTestDriveWeekCalendarInput!
    $inspections: UpdateInspectionWeekCalendarInput!
  ) {
    testDrive: updateTestDriveWeekCalendar(input: $testDrive) {
      ...TestDriveWeekScheduleFragment
    }
    inspections: updateInspectionWeekCalendar(input: $inspections) {
      ...InspectionWeekScheduleFragment
    }
  }
`
