import { DealerColumnField } from 'constants/dealer'
import { SelectItem } from 'models/Select'
import { FieldSelectTextType } from 'models/text/DealerListing'

export const createFieldSelectItems = (
  text: FieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: DealerColumnField.ID,
    },
    {
      label: text.name,
      value: DealerColumnField.NAME,
    },
    {
      label: text.contactName,
      value: DealerColumnField.CONTACT_NAME,
    },
    {
      label: text.phone,
      value: DealerColumnField.DEALER_PHONE,
    },
    {
      label: text.stateName,
      value: DealerColumnField.STATE_NAME,
    },
    {
      label: text.cityName,
      value: DealerColumnField.CITY_NAME,
    },
    {
      label: text.createdAt,
      value: DealerColumnField.CREATED_AT,
    },
    {
      label: text.status,
      value: DealerColumnField.STATUS,
    },
    {
      label: text.actions,
      value: DealerColumnField.ACTIONS,
    },
  ]
}

export const createFieldSelectLabels = (
  text: FieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    createdAt: text.createdAt,
    status: text.status,
    actions: text.actions,
    name: text.name,
    contactName: text.contactName,
    phone: text.phone,
    stateName: text.stateName,
  }
}

export const defaultFields = [
  DealerColumnField.ID,
  DealerColumnField.NAME,
  DealerColumnField.CONTACT_NAME,
  DealerColumnField.DEALER_PHONE,
  DealerColumnField.STATE_NAME,
  DealerColumnField.CITY_NAME,
  DealerColumnField.CREATED_AT,
  DealerColumnField.STATUS,
  DealerColumnField.ACTIONS,
]
