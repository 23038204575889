import { gql } from '@apollo/client'

export const LIST_INSPECTORS = gql`
  query listInspectors($input: FilterInput) {
    listInspectors(input: $input) {
      count
      data {
        id
        name
        picture: profilePicture
        lastName
        createdAt
        curboSpot {
          id
          name
          city
        }
        status {
          id
          name
          backgroundColor
          textColor
        }
      }
    }
  }
`

export const GET_INSPECTOR_STATUSES = gql`
  query geInspectorStatuses($input: FilterInput) {
    data: getInspectorStatuses(input: $input) {
      backgroundColor
      description
      id
      name
      textColor
      slug
    }
  }
`
