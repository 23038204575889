import { gql } from '@apollo/client'

export const GET_ASSIGNABLE_ROLES = gql`
  query getAssignableRoles($input: FilterInput) {
    data: getAssignableRoles(input: $input) {
      area
      value: id
      name
      slug
    }
  }
`
export const GET_ASSIGNABLE_AREAS = gql`
  query getAssignableRoleAreas {
    data: getAssignableRoleAreas {
      name
      value: slug
    }
  }
`
