import {
  Box,
  Card,
  List,
  styled,
  TextField,
  textFieldClasses,
  Typography,
} from '@mui/material'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledNoteList = styled(List)({
  bgcolor: 'background.paper',
  scrollbarWidth: 'thin',
  overflowY: 'scroll',
  '& ul': { padding: 0 },
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: colors.blue,
    borderRadius: '5px',
  },
})

export const StyledText = styled(Typography)({
  overflowWrap: 'break-word',
  fontWeight: FONT_WEIGHT.MEDIUM,
  letterSpacing: 0,
  color: colors.commonBlack,
})

export const StyledTextInput = styled(TextField)({
  [`${textFieldClasses.root}`]: {
    maxLength: 63,
    innerHeight: 72,
    padding: 0,
    fontSize: '12px',
    fontWeight: FONT_WEIGHT.BOLD,
  },
})

export const StyledNoteCard = styled(Card)({
  width: '100%',
  background: colors.background,
  height: 'auto',
  paddingLeft: '12px',
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingRight: '10px',
  borderRadius: '10px',
  boxShadow: 'none',
})

export const NoteContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 20,
})

export const AddContainer = styled(Box)({
  textAlign: 'center',
  borderTop: `1px solid ${colors.border}`,
  padding: '12px',
})
