import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { Typography } from '@mui/material'

import { DetailSubHeader } from 'components/CarSettings/Common/Detail/DetailSubHeader'
import StatusChangePopper from 'components/CarSettings/Common/Detail/StatusChangePopper'
import Box from 'components/Common/Box'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import Tag from 'components/Common/Tag'
import DetailNavTab, { NavBarItem } from 'components/General/DetailNavTab'
import TabPanel from 'components/Inspection/Detail/TabPanel'
import DealerInformation from 'components/Operations/Dealer/Detail/DealerInfo'
import DealerLocation from 'components/Operations/Dealer/Detail/DealerLocation'

import { initialDealerData } from 'constants/dealer'
import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import { DealerDetailData } from 'models/dealer'
import { GenericData, GenericInputVariable } from 'models/services/base'
import {
  CityDetail,
  DealerDetailDataType,
  handleUpdateDealerType,
  UpdateDealerInputType,
  UpdateDealerType,
} from 'models/services/operations/dealer'
import { PublicationStatus } from 'models/status'
import { validateGraphQLErrorCode } from 'utils/error'
import { generateTabItems } from 'utils/tabs'

import { UPDATE_DEALER } from 'graphQL/Operations/Dealer/Detail/mutations'
import {
  GET_CITIES,
  GET_DEALER_BY_ID,
} from 'graphQL/Operations/Dealer/Detail/queries'

import { ContentContainer, Layout, StyledLink } from 'styles/inspection/detail'
import { colors } from 'styles/theme'

const DealerDetailPage = () => {
  const { dealerId } = useParams<{ dealerId: string }>()
  const { text } = useTranslation(textFiles.DEALER_DETAIL)
  const { notificationText } = text
  const { general: translation, dealerInformation: dealerInformationText } =
    text
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const [tab, setTab] = useState<number>(0)
  const [initialData, setInitialData] = useState<DealerDetailData>()
  const history = useHistory()
  const { show } = useNotification()
  const [apolloError, setApolloError] = useState<ApolloError | null>(null)

  const [status, setStatus] = useState<PublicationStatus>(
    PublicationStatus.UNPUBLISHED
  )
  const [cities, setCities] = useState<CityDetail[]>()
  const [dealerData, setDealerData] = useState<DealerDetailDataType>()
  const [isDealerContactEmpty, setIsDealerContactEmpty] =
    useState<boolean>(false)

  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })

  const { loading: citiesLoading } = useQuery<GenericData<CityDetail[]>>(
    GET_CITIES,
    {
      onCompleted(response) {
        setCities(response.data)
      },
      onError() {
        setCities([])
      },
    }
  )

  const { loading: dealerLoading } = useQuery<
    GenericData<DealerDetailDataType>,
    GenericInputVariable<string>
  >(GET_DEALER_BY_ID, {
    variables: {
      input: dealerId,
    },
    onCompleted(response) {
      setDealerData(response.data)

      setStatus(response.data.status)
    },
    onError(error) {
      setApolloError(error)
    },
  })

  const [updateDealer, { loading: submitLoading }] = useMutation<
    GenericData<UpdateDealerType>,
    GenericInputVariable<UpdateDealerInputType>
  >(UPDATE_DEALER, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: notificationText.updateSuccess,
      })
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )

      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else {
        show({
          updatedSeverity: 'error',
          message: notificationText.updateFail,
        })
      }
    },
    refetchQueries: [GET_DEALER_BY_ID],
  })

  const getStateNameByCityId = (cityId: string) =>
    cities?.find((city) => city.value === cityId)?.state.name || ''

  /*
   * Mutation to set this Dealer's state to published / unpublished
   */

  const updateDealerStatus = (newStatus: PublicationStatus) => {
    updateDealer({
      variables: {
        input: {
          data: {
            status: newStatus,
          },
          where: {
            id: dealerId,
          },
        },
      },
    })
  }

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTab(value)
    history.replace(`#${initialItems[value].url}`)
  }

  const handleStatusChange = (newStatus: PublicationStatus) => {
    if (newStatus !== status) {
      setStatus(newStatus)
      updateDealerStatus(newStatus)
    }
  }

  const handleUpdateDealer = ({
    newMainPicture,
    newSpecifications,
    newContact,
    newLocation,
  }: handleUpdateDealerType) => {
    if (newSpecifications && newContact) {
      const backendDealerContacts = [...dealerData!.dealerContacts]

      /**
       * Replacing the first dealer contact we had, with the newer one
       */

      setInitialData((prevState) => {
        if (prevState !== undefined) {
          return {
            ...prevState,
            dealerInfo: {
              ...prevState.dealerInfo,
              specifications: newSpecifications,
            },
          }
        }
        return undefined
      })
      setInitialData((prevState) => {
        if (prevState !== undefined) {
          return {
            ...prevState,
            dealerInfo: {
              ...prevState.dealerInfo,
              contact: newContact,
            },
          }
        }
        return undefined
      })

      if (dealerData) {
        backendDealerContacts[0] = {
          name: String(newContact[1].name),
          phone: String(newContact[0].name),
          email:
            dealerData.dealerContacts.length > 0
              ? dealerData.dealerContacts[0].email
              : '',
        }
        updateDealer({
          variables: {
            input: {
              data: {
                mainPicture: newMainPicture || undefined,
                name: String(newSpecifications[0].name),
                telephoneNumber: String(newSpecifications[1].name),
                city: String(newSpecifications[2].name),
                dealerContacts:
                  dealerData.dealerContacts.length === 0
                    ? undefined
                    : backendDealerContacts.map((contact) => {
                        return {
                          agent: contact.name,
                          telephoneNumber: contact.phone,
                          email: contact.email,
                        }
                      }),
              },
              where: {
                id: dealerId,
              },
            },
          },
        })
      }
    }

    if (newLocation) {
      setInitialData((prevState) => {
        if (prevState !== undefined) {
          return {
            ...prevState,
            dealerLocation: newLocation,
          }
        }
        return undefined
      })

      updateDealer({
        variables: {
          input: {
            data: {
              address: newLocation.address,
              latitude: newLocation.lat,
              longitude: newLocation.lng,
            },
            where: {
              id: dealerId,
            },
          },
        },
      })
    }
  }

  useEffect(() => {
    if (history.location.hash) {
      let initialValue = 0
      const thisUrl = history.location.hash.split('#')[1]
      Object.keys(translation.tabs).forEach((key, index) => {
        if (key === thisUrl) {
          initialValue = index
        }
      })
      setTab(initialValue)
    }
  }, [history.location.hash, translation.tabs])

  useEffect(() => {
    /**
     * Here, we generate the dealer information fields
     */
    if (dealerData && cities) {
      setInitialData(
        initialDealerData(dealerInformationText, dealerData, cities)
      )
      /**
       * Since, an empty array is the default value when query for cities fail,
       * then we set the city's options array to just one value: the current one
       */
      if (cities.length === 0) {
        setInitialData(
          initialDealerData(dealerInformationText, dealerData, [
            {
              name: dealerData.city.name!,
              value: dealerData.city.id!,
              state: { id: '', name: '' },
            },
          ])
        )
      }

      /**
       * Check if there is no contact information available for this dealer
       */

      if (dealerData.dealerContacts.length === 0) {
        /**
         * In case, there is no dealer contact information available, then we will omit the
         * box containing this in the UI
         */
        setIsDealerContactEmpty(true)
      }
    }
  }, [cities, dealerData, dealerInformationText])

  if (dealerLoading || citiesLoading)
    return <LoadingAnimation showAnimation={dealerLoading || citiesLoading} />

  if (apolloError) return <Redirect push to={routes.NOT_FOUND_ERROR} />

  return initialData ? (
    <Layout>
      <StyledLink to={routes.DEALER_LISTING}>
        <Typography
          variant="h3"
          color={colors.blue}
        >{`< ${translation.backButton}`}</Typography>
      </StyledLink>
      <Box display="flex" alignItems="center" paddingTop="0.5rem">
        <Typography variant="h3" color={colors.black} marginRight="1rem">
          {translation.title} #{dealerId}
        </Typography>
        <Tag status={status} />
        <StatusChangePopper status={status} setStatus={handleStatusChange} />
      </Box>
      <DetailSubHeader text={translation.description} />
      <DetailNavTab
        tab={tab}
        handleTabChange={handleTabChange}
        items={initialItems}
      />
      <ContentContainer>
        <TabPanel value={tab} index={0}>
          <DealerInformation
            information={initialData.dealerInfo}
            handleUpdateDealer={handleUpdateDealer}
            handleOptionsChange={getStateNameByCityId}
            isDealerContactEmpty={isDealerContactEmpty}
            submitLoading={submitLoading}
          />
        </TabPanel>

        <TabPanel value={tab} index={1}>
          <DealerLocation
            address={initialData.dealerLocation}
            handleUpdateDealer={handleUpdateDealer}
          />
        </TabPanel>
        {/* <TabPanel value={tab} index={2}>
          <HistoryLog height="667px" dateFormat="time" />
        </TabPanel> */}
      </ContentContainer>
    </Layout>
  ) : null
}

export default DealerDetailPage
