import React, { useCallback, useState } from 'react'
import { Typography } from '@mui/material'

import AvatarContainer from 'components/CarSettings/Common/Creation/Avatar'
import Accordion from 'components/Common/Accordion'
import Box from 'components/Common/Box'
import Carousel, { Image } from 'components/Common/Carousel'
import ErrorMessage from 'components/Common/ErrorMessage'
import ExtendedAvatar from 'components/Common/ExtendedAvatar'
import ImageFilesInput from 'components/Inventory/Common/ImageFilesInput'

import { PICTURE_TYPE_OPTION } from 'constants/Inventory/detail'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { canDeletePictures, canEditPictures } from 'utils/Inventory/detail'

import { colors } from 'styles/theme'

export type PictureUploadAction = {
  type: PICTURE_TYPE_OPTION.EXTERIOR | PICTURE_TYPE_OPTION.INTERIOR
  payload: Image[]
}

type initialErrorsType = {
  interiorSize: boolean
  interiorTotalSize: boolean
  exteriorSize: boolean
  exteriorTotalSize: boolean
}
const initialErrors: initialErrorsType = {
  interiorSize: false,
  interiorTotalSize: false,
  exteriorSize: false,
  exteriorTotalSize: false,
}

type GalleryProps = {
  isLoading: boolean
  exteriorPictures: Image[]
  interiorPictures: Image[]
  uploadMainPicture: (file: File) => Promise<boolean>
  uploadMultiplePictures: (
    files: File[],
    uploadAction: PictureUploadAction
  ) => Promise<boolean>
  deletePicture: (id: string, type: PICTURE_TYPE_OPTION) => Promise<boolean>
  mainPicture: string | null
  status: string
  isEditable: boolean
}

const Gallery = ({
  exteriorPictures,
  interiorPictures,
  isLoading,
  uploadMainPicture,
  uploadMultiplePictures,
  mainPicture,
  status,
  deletePicture,
  isEditable,
}: GalleryProps) => {
  const [, setPictureState] = useState<string>('')
  const {
    text: { gallery: translation },
  } = useTranslation(textFiles.INVENTORY_DETAIL)

  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const [errors, setErrors] = useState<initialErrorsType>(initialErrors)

  const canEditPicture = canEditPictures(status) && isEditable
  const exteriorPictureDeletable =
    canDeletePictures(exteriorPictures, status) && isEditable
  const interiorPicturesDeletable =
    canDeletePictures(interiorPictures, status) && isEditable

  const handleDelete = async (id: string, type: PICTURE_TYPE_OPTION) => {
    await deletePicture(id, type)
  }

  const handleUploadPicture = useCallback(
    async (files: File[], type: PICTURE_TYPE_OPTION) => {
      const currentList =
        type === PICTURE_TYPE_OPTION.EXTERIOR
          ? exteriorPictures
          : interiorPictures
      await uploadMultiplePictures(files, { type, payload: currentList })
    },
    [uploadMultiplePictures, exteriorPictures, interiorPictures]
  )

  const setInteriorSizeErrorStatus = (error: boolean, type?: 'totalSize') => {
    /**
     * Type refers to either fileSize error (a file individually exceeds 3MB) OR totalSize error (Files colectively exceed 20MB).
     */
    setErrors((prev) => {
      if (type) {
        return { ...prev, interiorTotalSize: error }
      }
      return { ...prev, interiorSize: error }
    })
  }

  const setExteriorSizeErrorStatus = (error: boolean, type?: 'totalSize') => {
    /**
     * Type refers to either fileSize error (a file individually exceeds 3MB) OR totalSize error (Files colectively exceed 20MB).
     */
    setErrors((prev) => {
      if (type) {
        return { ...prev, exteriorTotalSize: error }
      }
      return { ...prev, exteriorSize: error }
    })
  }

  return (
    <Box
      minHeight="650px"
      sx={{
        opacity: isLoading ? '0.5' : 'unset',
      }}
    >
      <Accordion
        defaultExpanded
        title={translation.vehicleThumbnailLabel}
        sx={{
          margin: '0px!important',
        }}
      >
        <Box>
          {mainPicture ? (
            <Box
              height="215px"
              width="250px"
              sx={{
                border: `2px solid ${colors.background}`,
                borderRadius: '8px',
              }}
              marginTop="1rem"
            >
              <ExtendedAvatar
                image={mainPicture}
                height="100%"
                width="100%"
                sx={{ height: '100%', width: '100%' }}
                variant="rounded"
                isDownloadable
              />
            </Box>
          ) : null}
          <AvatarContainer
            picture=""
            handlePictureChange={setPictureState}
            handleFileStateChange={uploadMainPicture}
            title=""
            size="small"
            buttonText={
              mainPicture
                ? translation.changeThumbnailButton
                : translation.addThumbnailButton
            }
            disabled={!canEditPicture}
            id="thumbnail"
          />
        </Box>
      </Accordion>
      <Accordion defaultExpanded title={translation.picturesLabel}>
        <Box margin="10px 0">
          <Box>
            <Typography marginBottom="10px" variant="body2">
              {translation.interiorLabel}
            </Typography>
            <Box width="100%">
              <Carousel
                imageList={interiorPictures}
                width="68vw"
                imageHeight={120}
                imageWidth={120}
                onDelete={
                  interiorPicturesDeletable
                    ? (id) => {
                        handleDelete(id, PICTURE_TYPE_OPTION.INTERIOR)
                      }
                    : undefined
                }
                isDownloadable
              />
            </Box>

            <ImageFilesInput
              handleFileStateChange={(files) => {
                handleUploadPicture(files, PICTURE_TYPE_OPTION.INTERIOR)
              }}
              size="small"
              buttonText={translation.addPictureButton}
              disabled={!canEditPicture}
              id="interior-pictures"
              imagesList={interiorPictures}
              setFileSizeError={setInteriorSizeErrorStatus}
              testId="add-interior-pictures"
            />
          </Box>

          {errors.interiorSize && (
            <ErrorMessage
              text={generalText.fileSize.multiSizeError.replace('%d', 3)}
              sx={{ marginTop: '5px' }}
            />
          )}
          {errors.interiorTotalSize && (
            <ErrorMessage
              text={generalText.fileSize.maximumSizeError.replace('%d', 20)}
              sx={{ marginTop: '5px' }}
            />
          )}
          <Box marginTop="25px">
            <Typography marginBottom="10px" variant="body2">
              {translation.exteriorLabel}
            </Typography>
            <Box width="100%">
              <Carousel
                imageList={exteriorPictures}
                width="68vw"
                imageHeight={120}
                imageWidth={120}
                onDelete={
                  exteriorPictureDeletable
                    ? (id) => {
                        handleDelete(id, PICTURE_TYPE_OPTION.EXTERIOR)
                      }
                    : undefined
                }
                isDownloadable
              />
            </Box>
            <ImageFilesInput
              handleFileStateChange={(files) => {
                handleUploadPicture(files, PICTURE_TYPE_OPTION.EXTERIOR)
              }}
              size="small"
              buttonText={translation.addPictureButton}
              disabled={!canEditPicture}
              id="exterior-pictures"
              imagesList={exteriorPictures}
              setFileSizeError={setExteriorSizeErrorStatus}
              testId="add-exterior-pictures"
            />

            {errors.exteriorSize && (
              <ErrorMessage
                text={generalText.fileSize.multiSizeError.replace('%d', 3)}
                sx={{ marginTop: '5px' }}
              />
            )}
            {errors.exteriorTotalSize && (
              <ErrorMessage
                text={generalText.fileSize.maximumSizeError.replace('%d', 20)}
                sx={{ marginTop: '5px' }}
              />
            )}
          </Box>
        </Box>
      </Accordion>
    </Box>
  )
}

export default Gallery
