/* eslint-disable import/no-duplicates */
import enLocale from 'date-fns/locale/en-US'
import esLocale from 'date-fns/locale/es'

import { Language } from 'models/language'

type dateLocalMapType = {
  [key: string]: Locale
}

export const dateLocalesMap: dateLocalMapType = {
  en: enLocale,
  es: esLocale,
}

export const getSelectedLanguageOptions = (selectedLanguage: Language) => {
  if (
    dateLocalesMap[selectedLanguage.code] &&
    dateLocalesMap[selectedLanguage.code].options
  ) {
    dateLocalesMap[selectedLanguage.code].options!.weekStartsOn = 1
  }
}
