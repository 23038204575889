import { formControlClasses, styled } from '@mui/material'

import Button from 'components/Common/Button'
import Container from 'components/Common/Container'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledContainer = styled(Container)({
  padding: 0,
  borderRadius: 0,
  marginBottom: '20px',
  '> p': {
    fontSize: '14px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
})

export const StyledButtonContainer = styled('section')({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: 10,
})

export const StyledButton = styled(Button)({
  height: '30px !important',
  fontWeight: `${FONT_WEIGHT.MEDIUM} !important`,
})

export const StyledItem = styled(Container)({
  width: 315,
  maxHeight: 145,
  color: colors.placeholderGray,
  borderRadius: 0,
  padding: 0,
  '& > div:nth-of-type(2)': {
    margin: 0,
  },
  ul: {
    paddingLeft: '35px',
    fontSize: 14,
    fontWeight: FONT_WEIGHT.MEDIUM,
    li: {
      '&::marker': {
        fontSize: 16,
        color: colors.blue,
      },
      span: {
        color: colors.black,
      },
    },
  },
})
