import { StatusStyles } from 'constants/status'
import { ExtendedStatus } from 'models/services/status'
import { PublicationStatus } from 'models/status'
import { StatusText } from 'models/text/status'

type generalTextType = {
  status: StatusText
}

export const getCarSettingsStatusList = (
  generalText: generalTextType
): ExtendedStatus[] => [
  {
    backgroundColor: StatusStyles[PublicationStatus.PUBLISHED].backgroundColor,
    textColor: StatusStyles[PublicationStatus.PUBLISHED].color,
    id: PublicationStatus.PUBLISHED,
    slug: PublicationStatus.PUBLISHED,
    description: generalText.status.carSettingPublishedDescription,
    name: generalText.status[PublicationStatus.PUBLISHED],
  },
  {
    backgroundColor:
      StatusStyles[PublicationStatus.UNPUBLISHED].backgroundColor,
    textColor: StatusStyles[PublicationStatus.UNPUBLISHED].color,
    id: PublicationStatus.UNPUBLISHED,
    slug: PublicationStatus.UNPUBLISHED,
    description: generalText.status.carSettingUnpublishedDescription,
    name: generalText.status[PublicationStatus.UNPUBLISHED],
  },
]
