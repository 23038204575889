import { inputBaseClasses, styled } from '@mui/material'

import TextField from 'components/Common/TextField'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledList = styled('ul')({
  listStyle: 'none',
  textAlign: 'start',
  margin: 0,
  padding: 0,

  li: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.border}`,
    margin: '6px 0',
  },

  'li::before': {
    content: "''",
    background: colors.blue,
    borderRadius: '2px',
    height: '10px',
    marginRight: '9px',
    marginTop: '2px',
    width: '10px',
  },
})

export const StyledTextField = styled(TextField)({
  height: '31px',
  borderRadius: '4px',
  flexGrow: 1,
  color: colors.gray,
  div: {
    height: '100%',
    fontSize: '14px',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },

  input: {
    height: '33px',
    fontSize: '14px',
    padding: '0 14px',
  },
})
