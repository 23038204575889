import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import DateRangeIcon from '@mui/icons-material/DateRange'
import InfoIcon from '@mui/icons-material/Info'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ClickAwayListener, SelectChangeEvent, Typography } from '@mui/material'
import Popper from '@mui/material/Popper'
import { useFormik } from 'formik'
import * as yup from 'yup'

import ToggleContainer from 'components/CarSettings/Common/Creation/Toggle'
import Box from 'components/Common/Box'
import ErrorMessage from 'components/Common/ErrorMessage'
import Select from 'components/Common/Select'
import ButtonContainer from 'components/Template/Creation/ButtonContainer'

import { cypressAddButton } from 'constants/cypress'
import { textFiles } from 'constants/textFiles'
import { INFORMATION_OPTION, TRIM_CREATION_OPTION } from 'constants/trim'
import useTranslation from 'hooks/useTranslation'
import { Option } from 'models/Select'
import { PublicationStatus } from 'models/status'
import { BasicInformationModel } from 'models/trimCreation'
import {
  getRangesAsString,
  TrimCreationProps,
} from 'utils/CarSettings/Trim/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'
import { colors } from 'styles/theme'

import { AddButton, InfoBox, SetButton, StyledItem } from '../Measurement/style'

import TimePicker from './TimePicker'

const BasicInfo = ({
  handleBack,
  handleContinue,
  trimData,
  brands,
  updateTrimData,
}: TrimCreationProps) => {
  const { basicInformation } = trimData
  const [info, setInfo] = useState<BasicInformationModel>(basicInformation)
  const [modelOps, setModelOps] = useState<Option[]>([])
  const [status, setStatus] = useState<boolean>(
    basicInformation.status === PublicationStatus.PUBLISHED
  )
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [infoAnchorEl, setInfoAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [infoOpen, setInfoOpen] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [range, setRange] = useState<number[]>([])

  const { text: translation } = useTranslation(textFiles.TRIM_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { information } = translation

  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const handleInfoOpen = (event: React.MouseEvent<HTMLElement>) => {
    setInfoAnchorEl(event.currentTarget)
    setInfoOpen(true)
  }

  const handleInfoClose = () => {
    setInfoAnchorEl(null)
    setInfoOpen(false)
  }

  const handleCheck = () => {
    if (range[0] !== undefined && info.datage.length < 8) {
      setInfo((prevState) => {
        const items = [...prevState.datage]
        if (range.length > 1) {
          items.push([range[0], range[1]])
        } else items.push([range[0]])
        return { ...prevState, datage: items }
      })
      setError('')
    }
  }

  const handleClear = () => {
    setInfo((prevState) => {
      return { ...prevState, datage: [] }
    })
    setError('')
  }

  const validationSchema = yup.object({
    trim: yup.string().required(validationText.fieldRequired),
    brand: yup.string().required(validationText.fieldRequired),
    model: yup.string().required(validationText.fieldRequired),
  })

  const formik = useFormik<Partial<BasicInformationModel>>({
    initialValues: {
      trim: info.trim,
      brand: info.brand,
      model: info.model,
    },
    validationSchema,
    onSubmit: (values) => {
      setInfo({
        ...info,
        trim: values.trim as string,
      })
      updateTrimData({
        type: TRIM_CREATION_OPTION.UPDATE_BASIC_INFORMATION,
        payload: {
          status: status
            ? PublicationStatus.PUBLISHED
            : PublicationStatus.UNPUBLISHED,
          trim: values.trim as string,
          brand: info.brand,
          model: info.model,
          datage: info.datage,
        },
      })
      handleContinue()
    },
  })

  const handleSelect = (
    event: SelectChangeEvent<unknown>,
    map: INFORMATION_OPTION
  ) => {
    const {
      target: { value },
    } = event
    formik.setFieldValue(map, value as string)
    formik.setFieldTouched(map, false)
    if (map === 'brand') {
      formik.setFieldValue('model', value as string)
      formik.setFieldTouched('model', false)
    }
    setInfo({
      ...info,
      [map]: value,
    })
  }

  useEffect(() => {
    const currentBrand = brands.find((brand) => brand.value === info.brand)
    if (currentBrand) {
      setModelOps(currentBrand.models)
      setInfo((prevState) => {
        const newInfo = {
          ...prevState,
          model: currentBrand.models[0].value as string,
        }
        return newInfo
      })
    }
  }, [brands, info.brand])

  useEffect(() => {
    if (!open) {
      setRange([])
      setError('')
    }
  }, [open])

  useEffect(() => {
    if (error.length > 1) setRange([])
  }, [error])

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <ToggleContainer
            title={information.isPublished}
            description={information.isPublishedDescription}
            status={status}
            handleStatus={setStatus}
            testId="published"
          />
          <StyledTextFieldContainer
            title={information.trim}
            description={information.trimDescription}
            contentStyle={{
              flexDirection: 'column',
            }}
          >
            <StyledTextField
              variant="outlined"
              name="trim"
              value={formik.values.trim}
              onChange={formik.handleChange}
              placeholder={information.trim}
              inputProps={{ maxLength: 25 }}
              error={formik.touched.trim && Boolean(formik.errors.trim)}
            />
            {formik.touched.trim && formik.errors.trim ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.trim}
              />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={information.datage}
            description={information.datageDescription}
            contentStyle={{ display: 'flex', flexDirection: 'column' }}
          >
            <Box
              display="flex"
              marginBottom="15px"
              width="100%"
              justifyContent="space-between"
            >
              <SetButton
                buttonType="secondary"
                endIcon={<KeyboardArrowDownIcon />}
                startIcon={<DateRangeIcon />}
                onClick={handlePopperOpen}
                testId="set-date-button"
              >
                <Typography variant="body2">
                  {open ? (
                    <>
                      {range.length > 1
                        ? `${range[0]} - ${range[1]}`
                        : range[0]}
                    </>
                  ) : (
                    information.setButton
                  )}
                </Typography>
              </SetButton>
              <AddButton
                buttonType="secondary"
                startIcon={
                  open || !info.datage.length ? <AddIcon /> : <ClearIcon />
                }
                onClick={open ? handleCheck : handleClear}
                onMouseEnter={(e) => {
                  handleInfoClose()
                  handleInfoOpen(e)
                }}
                onMouseLeave={() => {
                  handleInfoClose()
                }}
                testId={cypressAddButton}
              >
                {open || !info.datage.length
                  ? information.addButton
                  : information.clearButton}
              </AddButton>
            </Box>
            {info.datage.length > 0 && (
              <StyledItem title={information.addedYears} titleSize="12px">
                <ul>
                  <li>
                    <span>{getRangesAsString(info.datage)}</span>
                  </li>
                </ul>
              </StyledItem>
            )}
            <>
              <Popper
                id="picker-popper"
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
              >
                <ClickAwayListener
                  onClickAway={() => {
                    setAnchorEl(null)
                    setOpen(false)
                  }}
                >
                  <Box>
                    <TimePicker
                      data={info.datage}
                      errorMessage={error}
                      handleSetRange={setRange}
                      handleSetError={setError}
                    />
                  </Box>
                </ClickAwayListener>
              </Popper>
            </>
            <Popper
              id="info-popper"
              open={infoOpen}
              anchorEl={infoAnchorEl}
              placement="top-end"
            >
              <InfoBox>
                <InfoIcon sx={{ color: colors.blue, marginLeft: '15px' }} />
                <Box margin="0 15px">
                  {open || !info.datage.length
                    ? information.timePicker.info
                    : information.timePicker.clear}
                </Box>
              </InfoBox>
            </Popper>
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={information.brand}
            description={information.brandDescription}
            contentStyle={{
              flexDirection: 'column',
            }}
          >
            <Select
              options={brands}
              variant="outlined"
              name="brand"
              value={info.brand}
              label={information.brand}
              onChange={(e) => handleSelect(e, INFORMATION_OPTION.BRAND)}
              sx={{ fontSize: 12, height: 40 }}
            />
            {formik.touched.brand && formik.errors.brand ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.brand}
              />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={information.model}
            description={information.modelDescription}
            contentStyle={{
              flexDirection: 'column',
            }}
          >
            <Select
              options={modelOps}
              variant="outlined"
              name="model"
              value={info.model}
              label={information.model}
              onChange={(e) => handleSelect(e, INFORMATION_OPTION.MODEL)}
              sx={{ fontSize: 12, height: 40 }}
            />
            {formik.touched.model && formik.errors.model ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.model}
              />
            ) : null}
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonContainer
        previousFunction={handleBack}
        confirmButtonType="submit"
        isFirstStep
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default BasicInfo
