import React from 'react'
import { SxProps, Typography } from '@mui/material'

import Box from 'components/Common/Box'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import noDataSvg from 'images/no-data.svg'

import { colors, FONT_WEIGHT } from 'styles/theme'

type NoDataBoxProps = {
  /**
   * Height of the container
   */
  height?: number | string

  /**
   * Width of the container
   */
  width?: number | string

  /**
   * Style for the box
   */
  boxSx?: SxProps
}

/**
 * Component that displays whenever no data is shown in a dataset
 */
const NoDataBox = ({ height = 162, width = 162, boxSx }: NoDataBoxProps) => {
  const {
    text: { noDataBox: translation },
  } = useTranslation(textFiles.GENERAL)
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      sx={boxSx}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height={height}
        width={width}
        sx={{
          backgroundColor: colors.background,
          border: `2px solid ${colors.border}`,
          borderRadius: '8px',
        }}
      >
        <img alt="no data" src={noDataSvg} />
        <Typography
          color={colors.altBlack2}
          fontWeight={FONT_WEIGHT.BOLD}
          marginTop="7px"
          variant="body2"
          textAlign="center"
        >
          {translation.firstPart}
        </Typography>
        <Typography fontWeight={FONT_WEIGHT.MEDIUM} variant="subtitle1">
          {translation.secondPart}
        </Typography>
      </Box>
    </Box>
  )
}

export default NoDataBox
