import FuelTypeInfo from 'components/CarSettings/FuelType/Creation/FuelTypeInfo'
import Summary from 'components/CarSettings/FuelType/Creation/Summary'
import { StepperModel } from 'components/General/Stepper'

import {
  FuelTypeCreationAction,
  FuelTypeCreationModel,
} from 'models/fuelTypeCreation'
import { StepperTextType } from 'models/text/General'

export type FuelTypeCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  submitLoading: boolean
  fuelTypeData: FuelTypeCreationModel
  updateFuelTypeData: (action: FuelTypeCreationAction) => void
}

export const INITIAL_FUEL_TYPE_CREATION_STEPS: Array<
  React.FC<FuelTypeCreationProps>
> = [FuelTypeInfo, Summary]

export const createFuelTypeStepperItems = (
  text: StepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep,
    },
    { label: text.secondStep },
  ]

  return stepperItems
}
