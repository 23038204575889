import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import TemplateCreationPage from 'pages/Template/Creation'
import TemplateDetailPage from 'pages/Template/Detail'
import TemplateListingPage from 'pages/Template/Listing'

import { TEMPLATE_SUB_ROUTES } from 'constants/routes'

export const TemplateRouter = () => (
  <Switch>
    <Route
      path={TEMPLATE_SUB_ROUTES.TEMPLATE_LISTING}
      component={TemplateListingPage}
    />
    <Route
      path={TEMPLATE_SUB_ROUTES.TEMPLATE_CREATION}
      component={TemplateCreationPage}
    />
    <Route
      path={TEMPLATE_SUB_ROUTES.TEMPLATE_DETAIL}
      component={TemplateDetailPage}
    />
    <Route
      path={TEMPLATE_SUB_ROUTES.TEMPLATE_CREATION}
      component={TemplateCreationPage}
    />
    <Redirect to={TEMPLATE_SUB_ROUTES.TEMPLATE_LISTING} />
  </Switch>
)
