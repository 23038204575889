import React, { useState } from 'react'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'

import {
  GridBox,
  StyledContainer,
} from 'components/CarSettings/Brand/Detail/Information/style'
import ImageBox from 'components/CarSettings/Common/Detail/ImageBox'
import NameContainer from 'components/CarSettings/Common/Detail/NameContainer'
import PaginationList from 'components/General/PaginationList'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { NameInformation, Trim } from 'models/car'
import { Option } from 'models/Select'
import {
  ModelDetailDataType,
  UpdateModelDataType,
} from 'models/services/CarSettings/detail'

import CarImagePlaceholder from 'images/blueDefaultAvatar.png'

type ModelInformationProps = {
  brands: Option[]
  handleUpdateModel: (newModel: UpdateModelDataType) => void
  loading?: boolean
  model: ModelDetailDataType
  handlePageChange: (event: React.ChangeEvent<unknown>, newPage: number) => void
  pageCount: number
  totalCount: number
  trimList: Trim[]
  trimsLoading: boolean
  actualPage: number
  pageSize: number
}

const ModelInformation = ({
  brands,
  handleUpdateModel,
  loading,
  model,
  totalCount,
  pageCount,
  trimList,
  trimsLoading,
  actualPage,
  pageSize,
  handlePageChange,
}: ModelInformationProps) => {
  const { text } = useTranslation(textFiles.MODEL_DETAIL)
  const { modelInformation: translation } = text
  const { name, brand } = model

  const [informationRows, setInformationRows] = useState<NameInformation[]>([
    {
      id: '1',
      icon: <DirectionsCarIcon />,
      name,
      label: translation.nameLabel,
    },
    {
      id: '2',
      icon: <DirectionsCarIcon />,
      name: brand.id,
      label: translation.brandLabel,
      options: brands,
    },
  ])

  const handleSetInformation = (newInformation: NameInformation[]) => {
    const nameInformation =
      newInformation.length > 0 ? newInformation[0] : undefined
    const brandInformation =
      newInformation.length > 1 ? newInformation[1] : undefined

    if (
      nameInformation?.name !== informationRows[0].name ||
      brandInformation?.name !== informationRows[1].name
    ) {
      handleUpdateModel({
        brand: brandInformation && String(brandInformation.name),
        name: nameInformation && String(nameInformation.name),
      })
    }
    setInformationRows(newInformation)
  }

  return (
    <StyledContainer>
      <GridBox>
        <ImageBox
          height="100%"
          width="100%"
          variant="rounded"
          image={brand.icon || CarImagePlaceholder}
          containerSx={{
            height: '363px',
            width: '363px',
            marginTop: '1rem',
            padding: '1rem',
          }}
        />
        <NameContainer
          title={translation.information}
          description={translation.informationDescription}
          information={informationRows}
          handleSetInformation={handleSetInformation}
          width={399}
          containerSx={{
            padding: '1rem 0 0 !important',
          }}
          loading={loading}
        />
        <PaginationList
          actualPage={actualPage}
          handlePageChange={handlePageChange}
          items={trimList.map((trim) => {
            return {
              ...trim,
              extraContent: trim.year,
            }
          })}
          pageCount={pageCount}
          totalCount={totalCount}
          loading={trimsLoading}
          pageSize={pageSize}
          title={translation.paginationTitle}
          description={translation.paginationDescription}
          countText={translation.paginationCount}
        />
      </GridBox>
      {/* <Box display="flex" flexDirection="column" marginTop="40px">
        <LogContainer>
          <HistoryLog
            height="200px"
            dateFormat="time"
            hasPadding={!!(width && width <= BREAK_POINTS.XL)}
          />
        </LogContainer>
      </Box> */}
    </StyledContainer>
  )
}

export default ModelInformation
