import { gql } from '@apollo/client'

export const LIST_CURBO_SPOTS = gql`
  query listSpots($input: FilterInput!) {
    listCurboSpots(input: $input) {
      count
      data {
        id
        name
        city {
          id
          state {
            name
          }
          name
          state {
            id
            name
          }
        }
        phone: telephoneNumber
        createdAt
        status
      }
    }
  }
`
