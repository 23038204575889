import { styled } from '@mui/material'

import Link from 'components/Common/Link'

import { boxShadow, colors } from 'styles/theme'

export const StyledForm = styled('form')({
  alignItems: 'center',
  backgroundColor: colors.commonWhite,
  borderRadius: '10px',
  boxShadow,
  display: 'flex',
  flexDirection: 'column',
  padding: '3rem 4rem',
  width: '60%',
})

export const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  marginLeft: '5em',
})

export const StyledLink = styled(Link)({
  fontSize: '14px',
  fontWeight: 'normal',
  marginTop: '2rem',
})

export const StyledTextfieldContainer = styled('div')({
  width: '100%',
  height: '92px',
  position: 'relative',
})
