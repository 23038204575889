import React from 'react'

import StatusTag from 'components/Common/StatusTag'

import { StatusStyles } from 'constants/status'
import { PublicationStatus } from 'models/status'

type PublicationStatusTagProps = {
  published: boolean
  text: string
}

const PublicationStatusTag = ({
  published,
  text,
}: PublicationStatusTagProps) => {
  const { backgroundColor, color } =
    StatusStyles[
      published ? PublicationStatus.PUBLISHED : PublicationStatus.UNPUBLISHED
    ]

  return (
    <StatusTag backgroundColor={backgroundColor} color={color}>
      {text}
    </StatusTag>
  )
}

export default PublicationStatusTag
