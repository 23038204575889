import React, { createContext, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'

import { GenericData } from 'models/services/base'

import {
  GET_BACK_OFFICE_COUNTRY_CONFIG,
  GET_SETTING,
} from 'graphQL/Setting/queries'

export type Country = {
  flag: string
  website: string
  name: string
  countryCode: string
}

export type Currency = {
  code: string
  symbol: string
  name: string
}

export type AppSetting = {
  website: string
  country: Country
  currency: Currency
}

type Setting = {
  countries: Country[]
  currentCountry: Country | null
  appSetting: AppSetting | null
}

const initialState: Setting = {
  countries: [],
  currentCountry: null,
  appSetting: null,
}

export const SettingContext = createContext(initialState)

type SettingProps = {
  children: React.ReactNode
}

export const SettingProvider: React.FC<SettingProps> = ({
  children,
}: SettingProps) => {
  const [appSetting, setAppSetting] = useState<AppSetting | null>(null)
  const [currentCountry, setCurrentCountry] = useState<Country | null>(null)

  const [countries, setCountries] = useState<Country[]>([])

  useQuery<GenericData<AppSetting>>(GET_SETTING, {
    onCompleted(response) {
      setAppSetting(response.data)
      setCurrentCountry(response.data.country)
    },
  })

  useQuery<GenericData<Country[]>>(GET_BACK_OFFICE_COUNTRY_CONFIG, {
    onCompleted(response) {
      setCountries(response.data)
    },
  })

  const settingData: Setting = useMemo(
    () => ({ countries, currentCountry, appSetting }),
    [appSetting, countries, currentCountry]
  )

  return (
    <SettingContext.Provider value={settingData}>
      {children}
    </SettingContext.Provider>
  )
}
