import React, { useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { ClickAwayListener, IconButton, Typography } from '@mui/material'

import Box from 'components/Common/Box'

import { Option } from 'models/Select'

import { colors } from 'styles/theme'

import RemoveItemPopper from '../RemoveItemPopper'
import { StyledTextField } from '../style'

export type TranslationItemType = {
  id: number
  description: string
} & Option

type TranslationListItemProps = {
  translationItem: TranslationItemType
  handleRemoveTranslation: (value: string) => void
  handleEditTranslation: (value: string, description: string) => void
  placeholderText: string
}

const TranslationListItem = ({
  handleRemoveTranslation,
  translationItem,
  handleEditTranslation,
  placeholderText,
}: TranslationListItemProps) => {
  const { description, name, value } = translationItem
  const [edit, setEdit] = useState<boolean>(false)
  const [textValue, setTextValue] = useState<string>(description)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleCleanUp = () => {
    setEdit(false)
    setTextValue(description)
  }

  const handleEdit = () => {
    if (!edit) setEdit(true)
    else {
      if (textValue) handleEditTranslation(value as string, textValue)
      else setTextValue(description)
      setEdit(false)
    }
  }

  const handleRemove = (event: React.MouseEvent<HTMLElement>) => {
    if (edit) handleCleanUp()
    else {
      setAnchorEl(anchorEl ? null : event.currentTarget)
    }
  }

  const handleTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTextValue(event.target.value)
  }

  const handleClosePopper = () => {
    setAnchorEl(null)
  }

  const handleConfirmRemove = () => {
    handleRemoveTranslation(value as string)
  }

  return (
    <ClickAwayListener onClickAway={handleCleanUp}>
      <li>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          <Box alignItems="center" display="flex">
            <Typography
              color={colors.black}
              variant="body2"
              component="span"
              width="61px"
            >
              {name}
            </Typography>
            {!edit ? (
              <Typography variant="body2" component="span">
                {' - '}
                {description}
              </Typography>
            ) : (
              <StyledTextField
                placeholder={`${placeholderText} ${name}`}
                variant="outlined"
                value={textValue}
                onChange={handleTextChange}
                sx={{
                  height: 28,
                  input: {
                    height: 28,
                  },
                }}
              />
            )}
          </Box>
          <Box>
            <IconButton
              onClick={handleEdit}
              data-cy={
                !edit
                  ? `edit-${translationItem.value}-button`
                  : `confirm-${translationItem.value}-button`
              }
            >
              {!edit ? (
                <EditIcon fontSize="small" />
              ) : (
                <CheckIcon fontSize="small" />
              )}
            </IconButton>
            <IconButton onClick={handleRemove}>
              <CloseIcon fontSize="small" />
            </IconButton>
            <RemoveItemPopper
              anchorEl={anchorEl}
              handleClosePopper={handleClosePopper}
              handleConfirmRemove={handleConfirmRemove}
              open={open}
            />
          </Box>
        </Box>
      </li>
    </ClickAwayListener>
  )
}

export default TranslationListItem
