import React from 'react'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import {
  HighlightedSpan,
  StyledBox,
  StyledCardBackground,
} from '../CarsInspectedCard/style'

type CarsInspectedCarProps = {
  name: string
  carsInspected: number
  carsInspectedObjective: number
  commission: number
  commissionCurrency: string
}

const CommissionAmountCard = ({
  name,
  carsInspected,
  carsInspectedObjective,
  commission,
  commissionCurrency,
}: CarsInspectedCarProps) => {
  const { text } = useTranslation(textFiles.INSPECTOR_DETAIL)
  const {
    firstPart,
    secondPart,
    timeGoal,
    thirdPart,
    inspections,
    outOfWhich,
    areComplete,
    inspectorCommission,
  } = text.commissions.commissionAmountCard

  return (
    <StyledBox>
      <StyledCardBackground>
        <span>{firstPart}</span>
        <HighlightedSpan>{name}</HighlightedSpan>
        <span>{secondPart}</span>
        <HighlightedSpan>{timeGoal}</HighlightedSpan>
        <span>{thirdPart}</span>
        <HighlightedSpan>
          {carsInspected}
          <span>{inspections}</span>
        </HighlightedSpan>
        <span>{outOfWhich}</span>
        <HighlightedSpan>{carsInspectedObjective}</HighlightedSpan>
        <span>{areComplete}</span>
        <span>{inspectorCommission}</span>
        <HighlightedSpan>
          {commission} {commissionCurrency}
        </HighlightedSpan>
      </StyledCardBackground>
    </StyledBox>
  )
}

export default CommissionAmountCard
