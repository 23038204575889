import {
  formControlClasses,
  inputBaseClasses,
  selectClasses,
  styled,
  typographyClasses,
} from '@mui/material'

import Box from 'components/Common/Box'
import Container from 'components/Common/Container'
import ErrorMessage from 'components/Common/ErrorMessage'
import TextField from 'components/Common/TextField'

import { colors, FONT_WEIGHT } from 'styles/theme'

// Step related styles
export const sliderFormProps = {
  '> div': {
    '> div': {
      width: '285px',
    },
  },
}

export const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'left',
  width: '100%',
  backgroundColor: colors.commonWhite,
})

export const StyledForm = styled('form')({
  justifyContent: 'flex-start',
  alignItems: 'left',
  width: '100%',
  minHeight: '305px',
  marginBottom: '2rem',
  '> div': {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '1.5rem',
    rowGap: '2rem',
    '> div': {
      width: '290px',
    },
  },
})

export const StyledTextFieldContainer = styled(Container)({
  padding: 0,
  borderRadius: 0,
  minWidth: '280px',
  width: 'auto !important',
  maxWidth: '98% !important',
  '> p': {
    fontSize: '12px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
})

export const StyledTextField = styled(TextField)({
  width: '100%',
  height: '38px',
  borderRadius: '4px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.placeholderGray,
  div: {
    height: '100%',
    fontSize: '12px',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },

  input: {
    height: '38px',
    fontSize: '12px',
    padding: '0 0 0 14px',
    '&:-internal-autofill-selected': {
      backgroundColor: colors.commonWhite,
    },
  },
})

export const BoxContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const StyledTitleSection = styled('section')({
  width: 'max-content',
  marginBottom: 25,
  div: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: colors.blue,
    color: colors.commonWhite,
    fontSize: 25,
    height: '30px',
    width: '30px',
    fontWeight: FONT_WEIGHT.BOLD,
    borderRadius: '4px',
  },
})

// Summary related styles
export const SummaryContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'left',
  padding: '0rem 0rem 1rem',
  width: '100%',
  minHeight: '300px',
  backgroundColor: colors.commonWhite,
  '> div:first-of-type': {
    [`& .${typographyClasses.body1}`]: {
      fontSize: 24,
      fontWeight: FONT_WEIGHT.BOLD,
    },
  },

  '> p': {
    fontSize: 12,
  },
})

export const SectionContainer = styled(Box)({
  marginBottom: '5px',
  marginTop: '10px',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  columnGap: '1.5rem',
  width: '100%',
  '> div': {
    width: 'fit-content',
    minWidth: '200px',
  },
})

export const elementStyle: React.CSSProperties = {
  alignItems: 'flex-start',
  marginTop: '1rem',
}

// Picture related styles

export const ThumbnailButton = styled('label')({
  width: '144px',
  height: '35px',
  borderRadius: '4px',
  border: `2px solid ${colors.blue}`,
  color: colors.blue,
  fontSize: '14px',
  fontWeight: FONT_WEIGHT.BOLD,
  padding: '0px 12px',
  display: 'inline-flex',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  outline: 0,
  margin: 0,
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  textDecoration: 'none',
  textTransform: 'none',
  lineHeight: '1.75',
  alignItems: 'center',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  backgroundColor: colors.commonWhite,
  boxShadow: 'none',
})

// Styled Error Message
export const StyledErrorMessage = styled(ErrorMessage)({
  alignSelf: 'flex-start',
  position: 'static',
  marginTop: '0.5rem',
})
