import React, { useEffect, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { SelectChangeEvent } from '@mui/material'
import { getDay, getHours, getMinutes, isToday } from 'date-fns'

import { AutocompleteItem } from 'components/Common/Autocomplete'
import Box from 'components/Common/Box'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import LocationSection from 'components/Common/Location'
import DatePicker from 'components/Common/Scheduling/DatePicker'
import InspectorPicker from 'components/Common/Scheduling/InspectorPicker'
import TimePicker from 'components/Common/Scheduling/TimePicker'
import ButtonContainer from 'components/Template/Creation/ButtonContainer'

import { DAY_ENUM, emptyWeekCalendar, weekDay } from 'constants/date'
import { ENTITY_NOT_FOUND_ERROR } from 'constants/error'
import { meridiamOptions } from 'constants/inspection'
import { INSPECTION_CREATION_OPTION } from 'constants/Inspection/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Address } from 'models/map'
import { GenericData, GenericInputVariable } from 'models/services/base'
import { InspectionWeekCalendar } from 'models/services/curboSpot'
import { AvailableInspectorInput, Inspector } from 'models/services/inspector'
import { getDisabledDayNumbers } from 'utils/CalendarUtils'
import { getIsoDate } from 'utils/date'
import { validateGraphQLErrorCode } from 'utils/error'
import { InspectionCreationProps } from 'utils/Inspection/creation'

import { GET_INSPECTION_WEEK_CALENDAR_BY_CURBO_SPOT_ID } from 'graphQL/Common/Dealer/queries'
import { GET_AVAILABLE_INSPECTORS } from 'graphQL/Inspection/Creation/queries'

import { StyledErrorMessage } from 'styles/creation'

import { StyledBox, StyledContainer, StyledContent } from './style'

export type SchedulingError = {
  calendar: boolean
  time: boolean
  inspector: boolean
  address: boolean
}

const initialErrors: SchedulingError = {
  address: false,
  calendar: false,
  inspector: false,
  time: false,
}

const InspectionScheduling = ({
  handleBack,
  handleContinue,
  inspectionData,
  updateInspectionData,
  curboSpots,
}: InspectionCreationProps) => {
  const { schedulingInformation } = inspectionData
  const [errors, setErrors] = useState<SchedulingError>(initialErrors)
  const [appointmentDate, setAppointmentDate] = useState<Date | null>(
    schedulingInformation.appointmentDate || new Date()
  )
  const [inspector, setInspector] = useState<Inspector | null>(
    schedulingInformation.inspector
  )
  const [time, setTime] = useState<string>(schedulingInformation.time)
  const [meridiam, setMeridiam] = useState<string>(
    schedulingInformation.meridiam
  )
  const [address, setAddress] = useState<Address | undefined>(
    schedulingInformation.address
  )
  const [dateKey, setDateKey] = useState<DAY_ENUM>(
    schedulingInformation.currentDayKey
  )
  const [disabledDays, setDisabledDays] = useState<number[]>([])
  const [inspectors, setInspectors] = useState<Inspector[]>([])

  const [weekCalendarData, setWeekCalendarData] =
    useState<InspectionWeekCalendar | null>(schedulingInformation.weekCalendar)

  const { text } = useTranslation(textFiles.INSPECTION_DETAIL)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { appointment: translation } = text

  useQuery<
    GenericData<InspectionWeekCalendar>,
    GenericInputVariable<string | null>
  >(GET_INSPECTION_WEEK_CALENDAR_BY_CURBO_SPOT_ID, {
    variables: {
      input: address && address.originFromSpot ? address.id : null,
    },
    onCompleted(response) {
      const responseWeekCalendar = { ...response.data }
      setWeekCalendarData(responseWeekCalendar)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        ENTITY_NOT_FOUND_ERROR
      )

      if (errorExists) {
        setWeekCalendarData(emptyWeekCalendar)
      }
    },
  })

  const [getInspectors, { loading: inspectorLoading }] = useLazyQuery<
    GenericData<Inspector[]> | null,
    GenericInputVariable<AvailableInspectorInput>
  >(GET_AVAILABLE_INSPECTORS, {
    onCompleted(response) {
      if (response)
        setInspectors(
          response.data.map((listInspector) => {
            return {
              ...listInspector,
              name: `${listInspector.name} ${listInspector.lastName}`,
            }
          })
        )
      else setInspectors([])
    },
  })

  const handleSchedulingDateChange = (date: Date | null) => {
    if (date) {
      const dateNumber = getDay(date)
      const dayKey = weekDay[dateNumber]
      setDateKey(dayKey)
      const storedDate = new Date(date.setHours(0, 0, 0, 0))
      setAppointmentDate(storedDate)
    }
    setTime('')
    setInspector(null)
  }

  const handleInspectorChange = (value: AutocompleteItem) => {
    setInspector(value as Inspector)
  }
  const handleHourChange = (event: SelectChangeEvent<unknown>) => {
    const selectedHour = event.target.value as string
    setTime(selectedHour)
    getInspectors({
      variables: {
        input: {
          curboSpot: address?.originFromSpot ? address.id : undefined,
          date: getIsoDate(appointmentDate || new Date())!,
          inspectionHour: selectedHour,
          latitude:
            address && !address.originFromSpot ? address.lat : undefined,
          longitude:
            address && !address.originFromSpot ? address.lng : undefined,
        },
      },
    })
  }
  const handleMeridiamChange = (event: SelectChangeEvent<unknown>) => {
    const selectedMeridiam = event.target.value as string
    setMeridiam(selectedMeridiam)
    setTime('')
    setInspector(null)
  }

  const handleAddressChange = (newAddress: Address | undefined) => {
    setAddress(newAddress)
    setAppointmentDate(null)
    setTime('')
    setInspector(null)
  }

  const handleSubmit = () => {
    if (!address || !inspector || !appointmentDate || !time) {
      setErrors({
        address: !address && true,
        calendar: !appointmentDate && true,
        inspector: !inspector && true,
        time: !time && true,
      })
    } else {
      setErrors(initialErrors)
      updateInspectionData({
        type: INSPECTION_CREATION_OPTION.UPDATE_SCHEDULING_INFORMATION,
        payload: {
          address,
          appointmentDate,
          inspector,
          meridiam,
          time,
          inspectionDay: weekCalendarData
            ? weekCalendarData[dateKey].find(
                (weekCalendar) => weekCalendar.value === time
              )
            : null,
          weekCalendar: weekCalendarData,
          currentDayKey: dateKey,
        },
      })
      handleContinue()
    }
  }

  useEffect(() => {
    if (weekCalendarData) {
      // here we are trying to get the number of the days that doesn't have any schedule
      // to disable them in the calendar
      const disabledDayNumbers: number[] =
        getDisabledDayNumbers(weekCalendarData)
      setDisabledDays(disabledDayNumbers)
    }
  }, [weekCalendarData])

  const curHour = getHours(new Date()) * 100
  const curMinutes = getMinutes(new Date())
  const curContinentalTime = curHour + curMinutes
  const isSameDay = appointmentDate && isToday(appointmentDate)
  return (
    <StyledBox>
      {!weekCalendarData ? (
        <LoadingAnimation showAnimation={!weekCalendarData} />
      ) : (
        <StyledContent>
          <StyledContainer>
            <LocationSection
              translation={translation}
              address={address}
              showError={errors.address}
              handleAddressChange={handleAddressChange}
              containerStyling={{
                width: 'inherit',
                maxWidth: '600px',
                height: '600px',
                marginBottom: '1em',
                paddingRight: '3rem',
              }}
              circleCenter={{
                lat: address?.lat || 0,
                lng: address?.lng || 0,
              }}
              curboSpots={curboSpots}
            />
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              columnGap="3rem"
              justifyContent="left"
              paddingRight="1rem"
            >
              <Box>
                <DatePicker
                  value={appointmentDate}
                  translation={translation}
                  onChange={handleSchedulingDateChange}
                  disabled={!address}
                  shouldDisableDate={(disabledDate) =>
                    disabledDays.includes(disabledDate.getDay())
                  }
                  startSameDay
                />
                {errors.calendar && (
                  <StyledErrorMessage text={validationText.fieldRequired} />
                )}
                <TimePicker
                  translation={translation}
                  hourOptions={weekCalendarData[dateKey]
                    .filter((day) => (meridiam === 'AM' ? day.am : !day.am))
                    .filter((hour) => {
                      if (!isSameDay) {
                        return true
                      }
                      const { continentalTime } = hour
                      return continentalTime! > curContinentalTime
                    })}
                  hour={time}
                  hourChange={handleHourChange}
                  meridiamOptions={meridiamOptions}
                  meridiam={meridiam}
                  meridiamChange={handleMeridiamChange}
                  disabled={!appointmentDate}
                />
                {errors.time && (
                  <StyledErrorMessage
                    sx={{ marginBottom: '0.5rem' }}
                    text={validationText.fieldRequired}
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <InspectorPicker
                  translation={translation}
                  onChange={handleInspectorChange}
                  value={inspector}
                  inspectors={inspectors}
                  disabled={!time}
                  loading={inspectorLoading}
                />
                {errors.inspector && (
                  <StyledErrorMessage text={validationText.fieldRequired} />
                )}
              </Box>
            </Box>
          </StyledContainer>
          <ButtonContainer
            previousFunction={handleBack}
            nextFunction={handleSubmit}
            confirmButtonType="submit"
          />
        </StyledContent>
      )}
    </StyledBox>
  )
}

export default InspectionScheduling
