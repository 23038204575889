import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Divider, Typography } from '@mui/material'
import { AccordionProps } from '@mui/material/Accordion'

import {
  StyledAccordion,
  StyledAccordionDetail,
  StyledAccordionSummary,
} from './style'

type Props = {
  children: JSX.Element
  title: string
  columnLayout?: string
} & AccordionProps

const Collapse: React.FC<Props> = ({ children, title, ...props }: Props) => {
  return (
    <StyledAccordion {...props} square disableGutters>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
        <Divider />
      </StyledAccordionSummary>
      <StyledAccordionDetail>{children}</StyledAccordionDetail>
    </StyledAccordion>
  )
}

export default Collapse
