import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import BodyStyleCreationPage from 'pages/CarSettings/BodyStyle/Creation'
import BodyStyleDetailPage from 'pages/CarSettings/BodyStyle/Detail'
import BrandCreationPage from 'pages/CarSettings/Brand/Creation'
import BrandDetailPage from 'pages/CarSettings/Brand/Detail'
import CategoryCreationPage from 'pages/CarSettings/Category/Creation'
import CategoryDetailPage from 'pages/CarSettings/Category/Detail'
import ColorCreationPage from 'pages/CarSettings/Color/Creation'
import ColorDetailPage from 'pages/CarSettings/Color/Detail'
import CountryVersionCreationPage from 'pages/CarSettings/CountryVersion/Creation'
import CountryVersionDetailPage from 'pages/CarSettings/CountryVersion/Detail'
import DriveTrainCreationPage from 'pages/CarSettings/DriveTrain/Creation'
import DriveTrainDetailPage from 'pages/CarSettings/DriveTrain/Detail'
import FeatureCreationPage from 'pages/CarSettings/Feature/Creation'
import FeatureDetailPage from 'pages/CarSettings/Feature/Detail'
import FuelTypeCreationPage from 'pages/CarSettings/FuelType/Creation'
import FuelTypeDetailPage from 'pages/CarSettings/FuelType/Detail'
import CarSettingsPage from 'pages/CarSettings/Home'
import CarSettingsListingWrapper from 'pages/CarSettings/Listing/Wrapper'
import ModelCreationPage from 'pages/CarSettings/Model/Creation/index'
import ModelDetailPage from 'pages/CarSettings/Model/Detail'
import TransmissionCreationPage from 'pages/CarSettings/Transmission/Creation'
import TransmissionDetailPage from 'pages/CarSettings/Transmission/Detail'
import TrimCreationPage from 'pages/CarSettings/Trim/Creation'
import TrimDetailPage from 'pages/CarSettings/Trim/Detail'

import { CAR_SETTINGS_SUB_ROUTES } from 'constants/routes'

export const CarSettingsRouter = () => (
  <Switch>
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.BRAND_CREATION}
      component={BrandCreationPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.BRAND_DETAIL}
      component={BrandDetailPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.COUNTRY_VERSION_CREATION}
      component={CountryVersionCreationPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.BODY_STYLE_CREATION}
      component={BodyStyleCreationPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.CAR_SETTINGS_LISTING}
      component={CarSettingsListingWrapper}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.CAR_SETTINGS_MENU}
      component={CarSettingsPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.CATEGORY_CREATION}
      component={CategoryCreationPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.COLOR_CREATION}
      component={ColorCreationPage}
    />

    <Route
      path={CAR_SETTINGS_SUB_ROUTES.TRANSMISSION_CREATION}
      component={TransmissionCreationPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.CATEGORY_DETAIL}
      component={CategoryDetailPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.COUNTRY_VERSION_CREATION}
      component={CountryVersionCreationPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.DRIVE_TRAIN_CREATION}
      component={DriveTrainCreationPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.FEATURE_CREATION}
      component={FeatureCreationPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.FEATURE_DETAIL}
      component={FeatureDetailPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.COUNTRY_VERSION_DETAIL}
      component={CountryVersionDetailPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.FUEL_TYPE_CREATION}
      component={FuelTypeCreationPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.MODEL_DETAIL}
      component={ModelDetailPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.BODY_STYLE_DETAIL}
      component={BodyStyleDetailPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.MODEL_CREATION}
      component={ModelCreationPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.BODY_STYLE_CREATION}
      component={BodyStyleCreationPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.TRANSMISSION_CREATION}
      component={TransmissionCreationPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.TRIM_CREATION}
      component={TrimCreationPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.TRANSMISSION_DETAIL}
      component={TransmissionDetailPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.DRIVE_TRAIN_DETAIL}
      component={DriveTrainDetailPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.FUEL_TYPE_DETAIL}
      component={FuelTypeDetailPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.COLOR_DETAIL}
      component={ColorDetailPage}
    />
    <Route
      path={CAR_SETTINGS_SUB_ROUTES.TRIM_DETAIL}
      component={TrimDetailPage}
    />
    <Redirect to={CAR_SETTINGS_SUB_ROUTES.CAR_SETTINGS_MENU} />
  </Switch>
)
