import React, { useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { Typography } from '@mui/material'

import { DetailSubHeader } from 'components/CarSettings/Common/Detail/DetailSubHeader'
import StatusChangePopper from 'components/CarSettings/Common/Detail/StatusChangePopper'
import Information from 'components/CarSettings/DriveTrain/Detail/Information'
import Box from 'components/Common/Box'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import Tag from 'components/Common/Tag'
import DetailNavTab, { NavBarItem } from 'components/General/DetailNavTab'
import { TranslationItemType } from 'components/General/Translation/TranslationListItem'
import TabPanel from 'components/Inspection/Detail/TabPanel'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { CAR_SETTINGS_SUB_ROUTES, routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import {
  BaseEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import {
  TranslationDetailDataType,
  UpdateGenericInput,
  UpdateTranslationInputType,
} from 'models/services/CarSettings/detail'
import { PublicationStatus } from 'models/status'
import { validateGraphQLErrorCode } from 'utils/error'
import { generateTabItems } from 'utils/tabs'
import { getTranslationsFilteredByLanguage } from 'utils/translation'

import { UPDATE_DRIVE_TRAIN } from 'graphQL/Operations/DriveTrain/Detail/mutations'
import { GET_DRIVE_TRAIN_BY_ID } from 'graphQL/Operations/DriveTrain/Detail/queries'

import { ContentContainer, Layout, StyledLink } from 'styles/inspection/detail'
import { colors } from 'styles/theme'

const DriveTrainDetailPage = () => {
  const { carSettingsId } = useParams<{ carSettingsId: string }>()
  const [tab] = useState<number>(0)
  const [status, setStatus] = useState<PublicationStatus>(
    PublicationStatus.UNPUBLISHED
  )

  const [driveTrain, setDriveTrain] =
    useState<TranslationDetailDataType | null>(null)
  const [apolloError, setApolloError] = useState<ApolloError | null>(null)

  const { text } = useTranslation(textFiles.DRIVE_TRAIN_DETAIL)
  const { general: translation } = text
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { show } = useNotification()

  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })

  const { loading: driveTrainLoading } = useQuery<
    GenericData<TranslationDetailDataType>,
    GenericInputVariable<string>
  >(GET_DRIVE_TRAIN_BY_ID, {
    variables: {
      input: carSettingsId,
    },
    onCompleted(response) {
      setDriveTrain(response.data)
      setStatus(response.data.status)
    },
    onError(error) {
      setApolloError(error)
    },
    context: {
      noTranslation: true,
    },
  })

  const [updateDriveTrain, { loading: submitLoading }] = useMutation<
    GenericData<BaseEntity>,
    GenericInputVariable<UpdateGenericInput<UpdateTranslationInputType>>
  >(UPDATE_DRIVE_TRAIN, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: translation.updateSuccess,
      })
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
          message: translation.updateFail,
        })
    },
    refetchQueries: [GET_DRIVE_TRAIN_BY_ID],
  })

  const handleUpdateStatus = (newStatus: PublicationStatus) => {
    updateDriveTrain({
      variables: {
        input: {
          where: {
            id: carSettingsId,
          },
          data: {
            status: newStatus,
          },
        },
      },
    })
  }

  const handleSave = async (
    name: string | undefined,
    translations: TranslationItemType[]
  ) => {
    const { valueEn, valueEs } = getTranslationsFilteredByLanguage(translations)

    try {
      const response = await updateDriveTrain({
        variables: {
          input: {
            where: {
              id: carSettingsId,
            },
            data: {
              name,
              name_translations: {
                en: valueEn ? valueEn.description : null,
                es: valueEs ? valueEs.description : null,
              },
            },
          },
        },
      })
      if (response.errors) return false
      return true
    } catch {
      return false
    }
  }

  const handleStatusChange = (newStatus: PublicationStatus) => {
    if (newStatus !== status) {
      handleUpdateStatus(newStatus)
      setStatus(newStatus)
    }
  }

  if (driveTrainLoading)
    return <LoadingAnimation showAnimation={driveTrainLoading} />

  if (apolloError) return <Redirect push to={routes.NOT_FOUND_ERROR} />

  return driveTrain ? (
    <Layout>
      <StyledLink to={CAR_SETTINGS_SUB_ROUTES.DRIVE_TRAIN_LISTING}>
        <Typography
          variant="h3"
          color={colors.blue}
        >{`< ${translation.backButton}`}</Typography>
      </StyledLink>
      <Box display="flex" alignItems="center" paddingTop="0.5rem">
        <Typography variant="h3" color={colors.black} marginRight="1rem">
          {translation.title} #{carSettingsId}
        </Typography>
        <Tag
          backgroundColor={submitLoading ? colors.gray : undefined}
          color={submitLoading ? colors.black : undefined}
          status={status}
        />
        <StatusChangePopper
          status={status}
          setStatus={handleStatusChange}
          loading={submitLoading}
        />
      </Box>
      <DetailSubHeader text={translation.description} />
      <DetailNavTab tab={tab} items={initialItems} />
      <ContentContainer>
        <TabPanel value={tab} index={0}>
          <Information
            information={driveTrain}
            handleSave={handleSave}
            loading={submitLoading}
          />
        </TabPanel>
      </ContentContainer>
    </Layout>
  ) : null
}

export default DriveTrainDetailPage
