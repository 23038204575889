import { gql } from '@apollo/client'

export const UPDATE_FEATURE = gql`
  mutation updateFeature($input: UpdateFeatureInput!) {
    data: updateFeature(input: $input) {
      id
      name
    }
  }
`
