import BodyStyleInfo from 'components/CarSettings/BodyStyle/Creation/BodyStyleInfo'
import Summary from 'components/CarSettings/BodyStyle/Creation/Summary'
import { StepperModel } from 'components/General/Stepper'

import {
  BodyStyleCreationAction,
  BodyStyleCreationModel,
} from 'models/bodyStyleCreation'
import { StepperTextType } from 'models/text/General'

export type BodyStyleCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  submitLoading: boolean
  bodyStyleData: BodyStyleCreationModel
  updateBodyStyleData: (action: BodyStyleCreationAction) => void
}

export const INITIAL_BODY_STYLE_CREATION_STEPS: Array<
  React.FC<BodyStyleCreationProps>
> = [BodyStyleInfo, Summary]

export const createBodyStyleStepperItems = (
  text: StepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep,
    },
    { label: text.secondStep },
  ]

  return stepperItems
}
