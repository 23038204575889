import { TEMPLATE_CREATION_OPTION } from 'constants/Template/creation'
import {
  TemplateCreationAction,
  TemplateCreationModel,
} from 'models/templateCreation'

const templateCreationReducer = (
  state: TemplateCreationModel,
  action: TemplateCreationAction
): TemplateCreationModel => {
  switch (action.type) {
    case TEMPLATE_CREATION_OPTION.UPDATE_IDENTITY:
      return { ...state, identity: action.payload }

    case TEMPLATE_CREATION_OPTION.UPDATE_STEP: {
      const templateInfo = [...state.stepCreation]
      const { payload, index } = action
      templateInfo.splice(index, 1, payload)
      const slicedInfo = templateInfo.slice(0, state.identity.stepsNumber)

      return { ...state, stepCreation: slicedInfo }
    }

    default:
      return state
  }
}

export default templateCreationReducer
