import React from 'react'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { Typography } from '@mui/material'

import Title from 'components/Common/Title'

import { carMenuOptions } from 'constants/carSettings'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { colors, FONT_WEIGHT } from 'styles/theme'

import {
  StyledBox,
  StyledCard,
  StyledCardsBox,
  StyledIconContainer,
  StyledLink,
  StyledOptionDetail,
} from './style'

const CarSettingsHome = () => {
  const { text } = useTranslation(textFiles.CAR_SETTINGS)
  return (
    <StyledBox>
      <Title
        title={text.title}
        subtitle={text.subtitle}
        icon={<PlaylistAddCheckIcon />}
      />
      <StyledCardsBox>
        {carMenuOptions.map((option) => (
          <StyledLink
            to={option.url}
            style={{ textDecoration: 'none' }}
            key={option.id}
          >
            <StyledCard key={option.id}>
              <StyledIconContainer>{option.icon}</StyledIconContainer>
              <StyledOptionDetail>
                <Typography
                  variant="h5"
                  color={colors.altBlack}
                  fontWeight={FONT_WEIGHT.BOLD}
                >
                  {text[option.translationKey].name}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color={colors.black}
                  fontWeight={FONT_WEIGHT.MEDIUM}
                >
                  {text[option.translationKey].description}
                </Typography>
              </StyledOptionDetail>
            </StyledCard>
          </StyledLink>
        ))}
      </StyledCardsBox>
    </StyledBox>
  )
}

export default CarSettingsHome
