import React from 'react'
import {
  InputAdornment,
  InputBaseComponentProps,
  Typography,
} from '@mui/material'

import Box from 'components/Common/Box'
import ErrorMessage from 'components/Common/ErrorMessage'

import { colors } from 'styles/theme'

import { StyledTextField } from './styles'

const formatValue = (
  value?: string | null | number,
  endAdornment?: string,
  thousandSeparator?: boolean
) => {
  if (value || value === 0) {
    const returnValue = thousandSeparator
      ? parseFloat(String(value)).toLocaleString('en')
      : value

    return endAdornment ? `${returnValue} ${endAdornment}` : returnValue
  }

  return '-'
}

type InformationCellProps = {
  label: string
  value?: string | null | number
  endAdornment?: string
  edit?: boolean
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  name?: string
  inputComponent?: React.ElementType<InputBaseComponentProps> | undefined
  thousandSeparator?: boolean
  error?: boolean
  errorText?: string
  labelError?: boolean
  valueColor?: string
  onBlur?:
    | React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined
}

const InformationCell = ({
  label,
  value,
  endAdornment,
  edit,
  onChange,
  name,
  inputComponent,
  thousandSeparator,
  error,
  errorText,
  labelError,
  valueColor,
  onBlur,
}: InformationCellProps) => {
  const formattedValue = formatValue(value, endAdornment, thousandSeparator)

  return (
    <Box>
      <Typography
        color={labelError || error ? colors.lightRed : colors.black}
        variant="body1"
      >
        {label}
      </Typography>
      {!edit ? (
        <Typography color={valueColor} variant="body2">
          {formattedValue}
        </Typography>
      ) : (
        <>
          <StyledTextField
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="body2">{endAdornment}</Typography>
                </InputAdornment>
              ),
              inputComponent,
            }}
            error={error}
          />
          {error && errorText ? (
            <ErrorMessage
              sx={{
                paddingLeft: '0',
                marginTop: '0.25rem',
                alignItems: 'flex-start',
              }}
              text={errorText}
            />
          ) : null}
        </>
      )}
    </Box>
  )
}

export default InformationCell
