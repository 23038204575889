import { gql } from '@apollo/client'

export const CREATE_TEST_DRIVE_HOUR = gql`
  mutation createTestDriveHour($input: CreateTestDriveHoursInput!) {
    createTestDriveHour(input: $input) {
      id
    }
  }
`

export const CREATE_INSPECTION_HOUR = gql`
  mutation createInspectionHour($input: CreateInspectionHoursInput!) {
    createInspectionHour(input: $input) {
      id
    }
  }
`
