import React from 'react'

import Box from 'components/Common/Box'

import { ColorBoxContainer } from './style'

type ColorBoxProps = {
  code?: string
}

const ColorBox = ({ code }: ColorBoxProps): JSX.Element => {
  return (
    <ColorBoxContainer>
      <Box
        sx={{
          backgroundColor: code,
        }}
      />
    </ColorBoxContainer>
  )
}

export default ColorBox
