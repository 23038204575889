import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { SelectChangeEvent, Typography } from '@mui/material'
import {
  GridCellValue,
  GridColDef,
  GridRowId,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid'

import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import Table from 'components/Common/Table'
import { TabObjects } from 'components/Common/Tabs'
import Title from 'components/Common/Title'
import { Filter } from 'components/Inspection/Dashboard/FilterCard'
import FieldFilter from 'components/Inspection/Listing/FieldFilter'
import FilterByCriteria from 'components/Listing/FilterByCriteria'

import { cypressBackLink } from 'constants/cypress'
import { FilteringOption } from 'constants/Inspection/filterByCriteria'
import {
  BLACK_LIST_STATUS,
  BlackListEntityKey,
  blackListFields,
  createBlackListColumns,
} from 'constants/Operation/blackList'
import {
  BLACK_LIST_ROUTE,
  CAR_SETTINGS_SUB_ROUTES,
  routes,
} from 'constants/routes'
import { defaultSortModel, selectItems } from 'constants/table'
import { textFiles } from 'constants/textFiles'
import { UrlParamNames } from 'constants/urlQuery'
import useLocale from 'hooks/useLocale'
import useNotification from 'hooks/useNotification'
import useQueryState from 'hooks/useQueryState'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { UserRoles } from 'models/role'
import {
  BaseIdEntity,
  FilterInputVariable,
  GenericData,
  GenericUpdateVariable,
  ListingFilterType,
} from 'models/services/base'
import { ListSettingType } from 'models/services/CarSettings/listing'
import { UpdateBlackListPayload } from 'models/services/operations/blackList'
import {
  checkSearchEmptiness,
  cleanFilters,
  generateFilterInput,
} from 'utils/filters'
import {
  serializeFields,
  serializeFilters,
  serializePage,
  serializePageSize,
  serializeSearch,
  serializeSortModel,
} from 'utils/serializers'
import { verifyParam } from 'utils/verifyUrlData'

import { GET_BLACK_LIST_BRANDS } from 'graphQL/Operations/BlackList/Listing/queries'
import { GET_MODELS } from 'graphQL/Operations/Trim/Detail/queries'

import { StyledLink, StyledTextField } from 'styles/inspection/listing'
import { colors } from 'styles/theme'

const modelHash = '#model'
const trimHash = '#trim'

const blackListStaticFields = ['brand', 'carModel']

const getInitialTabValue = (hash?: string): BlackListEntityKey => {
  if (hash === modelHash) return 'model'
  if (hash === trimHash) return 'trim'

  return 'make'
}

const BlackListListingPage = () => {
  const { show } = useNotification()
  const location = useLocation()
  const { search } = location

  const { validateAllowedRoles } = useUser()

  const isSupervisor = validateAllowedRoles([
    UserRoles.ADMIN,
    UserRoles.OPERATION_SUPERVISOR,
  ])

  const [tabValue, setTabValue] = useState<BlackListEntityKey>(
    getInitialTabValue(location && location.hash)
  )

  const {
    createFieldSelectItems,
    createFieldSelectLabels,
    query,
    selectFields,
    mutation,
    filtersTextfile,
  } = blackListFields[tabValue]

  const defaultSortedFields = [...selectFields].sort()

  const [selectedFields, setSelectedFields] = useQueryState<string[]>(
    UrlParamNames.FIELDS,
    (verifyParam(UrlParamNames.FIELDS, search) as string[]) ||
      defaultSortedFields,
    serializeFields,
    defaultSortedFields
  )

  const [filtersList, setFiltersList] = useQueryState<Filter[]>(
    UrlParamNames.FILTERS,
    (verifyParam(UrlParamNames.FILTERS, search) as Filter[]) || [],
    serializeFilters
  )

  const [searchValue, setSearchValue] = useQueryState<string | undefined>(
    UrlParamNames.SEARCH,
    verifyParam(UrlParamNames.SEARCH, search) as string,
    serializeSearch
  )

  const [searchInput, setSearchInput] = useState<string>(searchValue || '')

  const [pageSize, setPageSize] = useQueryState<number>(
    UrlParamNames.LIMIT,
    (verifyParam(UrlParamNames.LIMIT, search) as number) || 10,
    serializePageSize,
    10
  )
  const [page, setPage] = useQueryState<number>(
    UrlParamNames.PAGE,
    (verifyParam(UrlParamNames.PAGE, search) as number) || 1,
    serializePage
  )

  const [sortModel, setSortModel] = useQueryState<GridSortModel>(
    UrlParamNames.SORT,
    (verifyParam(UrlParamNames.SORT, search) as GridSortModel) ||
      defaultSortModel,
    serializeSortModel,
    defaultSortModel
  )

  const [filterInput, setFiltersInput] = useState<ListingFilterType>(
    generateFilterInput(filtersList)
  )

  const [selectedLanguage] = useLocale()
  const history = useHistory()

  const [deleteItem, setDeleteItem] = useState<GridRowId | null>(null)
  const { text } = useTranslation(textFiles.BLACK_LIST_LISTING)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const [data, setData] = useState<GridRowsProp>([])
  const [inspectionCount, setInspectionCount] = useState<number>(0)

  const [modelsList, setModelsList] = useState<FilteringOption[]>([])
  const [brandsList, setBrandsList] = useState<FilteringOption[]>([])

  const fieldSelectItems = createFieldSelectItems(
    text.fieldSelect,
    isSupervisor
  )
  const fieldSelectLabels = createFieldSelectLabels(text.fieldSelect)

  const { loading: blackListLoading, refetch: refetchBlackList } = useQuery<
    ListSettingType,
    FilterInputVariable
  >(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        limit: pageSize,
        start: (page - 1) * pageSize,
        where: {
          text_search: searchValue,
          blacklistStatus: BLACK_LIST_STATUS.ACTIVE,
          ...cleanFilters(filterInput),
        },
        sort:
          sortModel.length > 0 && sortModel[0].sort
            ? {
                [sortModel[0].field]: sortModel[0].sort,
              }
            : undefined,
      },
    },
    onError() {
      show({
        updatedSeverity: 'error',
      })
    },
    onCompleted(response) {
      const { count, data: currentData } = response.listData
      setData(currentData)
      setInspectionCount(count)
    },
  })

  const [getBrands, { loading: brandsLoading, called: brandsCalled }] =
    useLazyQuery<GenericData<FilteringOption[]>, FilterInputVariable>(
      GET_BLACK_LIST_BRANDS,
      {
        variables: {
          input: {
            sort: {
              name: 'asc',
            },
          },
        },
        onCompleted(response) {
          if (response.data) {
            setBrandsList(response.data)
          }
        },
      }
    )

  const [getModels, { loading: modelsLoading, called: modelsCalled }] =
    useLazyQuery<GenericData<FilteringOption[]>, FilterInputVariable>(
      GET_MODELS,
      {
        variables: {
          input: {
            sort: {
              name: 'asc',
            },
          },
        },
        onCompleted(response) {
          if (response.data) {
            setModelsList(response.data)
          }
        },
      }
    )

  const handleShowError = () => {
    show({
      updatedSeverity: 'error',
      message: generalText.notificationText.deleteFail,
    })
  }

  const handleRefetchBlackList = async () => {
    try {
      const response = await refetchBlackList()

      if (response) {
        const { listData } = response.data
        if (listData.data.length === 0 && listData.count > 0) {
          setPage(1)
        }
      }
      show({
        updatedSeverity: 'success',
        message: generalText.notificationText.deleteSuccess,
      })
    } catch {
      handleShowError()
    }
  }

  const submitSearchValue = () => {
    setPage(1)
    const newValue = checkSearchEmptiness(searchInput)
    setSearchValue(newValue)
  }

  const [removeBlackList] = useMutation<
    GenericData<BaseIdEntity>,
    GenericUpdateVariable<UpdateBlackListPayload>
  >(mutation, {
    onCompleted() {
      handleRefetchBlackList()
    },
    onError() {
      handleShowError()
    },
  })

  const deleteBlackListEntity = useCallback((id: GridRowId) => {
    setDeleteItem(id)
  }, [])

  const editBlackListEntity = useCallback(
    (id: GridCellValue) => {
      history.push(`${BLACK_LIST_ROUTE}/detail/${tabValue}/${id}`)
    },
    [history, tabValue]
  )

  const createBlackList = () => {
    history.push(`${BLACK_LIST_ROUTE}/creation/${tabValue}`)
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
  }

  const handleRemoveBlackListEntity = async (id: string) => {
    removeBlackList({
      variables: {
        input: {
          where: {
            id,
          },
          data: {
            blacklistStatus: BLACK_LIST_STATUS.INACTIVE,
          },
        },
      },
    })
  }

  const [columns, setColumns] = useState<GridColDef[]>([])

  const tabs: TabObjects[] = [
    {
      id: 1,
      item: {
        label: text.makeTab,
        value: 'make',
      },
    },
    {
      id: 2,
      item: {
        label: text.modelTab,
        value: 'model',
      },
    },
    {
      id: 3,
      item: {
        label: text.trimTab,
        value: 'trim',
      },
    },
  ]

  const handleFiltersList = (
    newFiltersList: Filter[],
    newFilterInput: ListingFilterType
  ) => {
    setFiltersList(newFiltersList)
    setFiltersInput(newFilterInput)
    setPage(1)
  }

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchInput(event.target.value)
  }

  const handleTabChange = (newValue: string | number) => {
    if (newValue === 'model') history.replace({ ...location, hash: modelHash })
    else if (newValue === 'trim')
      history.replace({ ...location, hash: trimHash })
    else history.replace({ ...location, hash: '#' })
    setPage(1)
  }

  const handleSelectItem = (valueInput: string) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        return column.field === valueInput
          ? { ...column, hide: !column.hide }
          : column
      })
    )
    setSelectedFields((prevFields) => {
      if (prevFields.includes(valueInput)) {
        return prevFields.filter((field) => field !== valueInput)
      }
      return [...prevFields, valueInput]
    })
  }

  const handleResetDefault = () => {
    setSelectedFields(defaultSortedFields)
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = defaultSortedFields.some(
          (defaultField) => column.field === defaultField
        )
        return { ...column, hide: !fieldExist }
      })
    )
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangePageSize = (event: SelectChangeEvent<number>) => {
    setPageSize(event.target.value as number)
    setPage(1)
  }

  const getPageCount = () => {
    return Math.ceil(inspectionCount / pageSize)
  }

  const handleFieldEntrySelect = (field: string) => {
    switch (field) {
      case 'carModel':
        if (!modelsCalled) {
          getModels()
        }
        break
      case 'brand':
        if (!brandsCalled) {
          getBrands()
        }
        break
      default:
        break
    }
  }

  useEffect(() => {
    setColumns(
      createBlackListColumns(
        tabValue,
        selectedLanguage,
        generalText,
        text.table,
        selectedFields,
        isSupervisor ? editBlackListEntity : undefined,
        isSupervisor ? deleteBlackListEntity : undefined
      )
    )
  }, [
    deleteBlackListEntity,
    editBlackListEntity,
    generalText,
    isSupervisor,
    selectedLanguage,
    setSortModel,
    tabValue,
    text.table,
    selectedFields,
  ])

  useEffect(() => {
    if (location) {
      if (location.hash === modelHash) setTabValue('model')
      else if (location.hash === trimHash) setTabValue('trim')
      else setTabValue('make')
    } else {
      setTabValue('make')
    }
    setPage(1)
  }, [location, setPage])

  const filtersLoading = modelsLoading || brandsLoading

  return (
    <Box width="100%">
      <StyledLink
        testId={cypressBackLink}
        to={CAR_SETTINGS_SUB_ROUTES.CAR_SETTINGS_MENU}
      >
        <Typography
          variant="h3"
          color={colors.blue}
        >{`< ${generalText.buttons.back}`}</Typography>
      </StyledLink>
      <Title
        icon={<PlaylistAddCheckIcon />}
        handleTabChange={handleTabChange}
        subtitle={`${inspectionCount} ${text.description}`}
        tabs={tabs}
        tabValue={tabValue}
        title={text.title}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent={isSupervisor ? 'space-between' : 'flex-end'}
        marginTop="2rem"
        width="100%"
      >
        {isSupervisor ? (
          <Button
            startIcon={<AddIcon />}
            size="medium"
            onClick={createBlackList}
          >
            {text.newBlackListButton}
          </Button>
        ) : null}

        <Box display="flex">
          <Box marginRight="1rem" width="430px">
            <StyledTextField
              placeholder={text.searchPlaceholder}
              fullWidth
              value={searchInput}
              onChange={handleSearchChange}
              submitFunction={submitSearchValue}
            />
          </Box>
          <Box marginRight="1rem">
            <FilterByCriteria
              filtersList={filtersList}
              handleFiltersList={handleFiltersList}
              file={filtersTextfile}
              filterInput={filterInput}
              handleFieldEntrySelect={handleFieldEntrySelect}
              filterTypes={tabValue === 'trim' ? { year: 'number' } : undefined}
              loadingSelect={filtersLoading}
              options={{
                carModel: modelsList,
                brand: brandsList,
              }}
              staticFields={blackListStaticFields}
            />
          </Box>
          <Box marginRight="1rem">
            <FieldFilter
              handleSelectItem={handleSelectItem}
              items={fieldSelectItems}
              selectedValues={selectedFields}
              handleResetDefault={handleResetDefault}
            />
          </Box>
        </Box>
      </Box>
      <Table
        columns={columns}
        searchValue={searchValue}
        data={data}
        currentPage={page}
        onPageChange={handleChangePage}
        onSelectChange={handleChangePageSize}
        pageSize={pageSize}
        selectItems={selectItems}
        pageCount={getPageCount()}
        filtersList={filtersList}
        fields={selectedFields}
        fieldLabels={fieldSelectLabels}
        deletedItemId={deleteItem}
        setDeleteItemId={setDeleteItem}
        route={routes.BLACK_LIST_DETAIL}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
        loading={blackListLoading}
        handleRemoveItem={handleRemoveBlackListEntity}
        hideDownloadModal
        checkboxSelection={false}
        buildCustomDetail={editBlackListEntity}
      />
    </Box>
  )
}

export default BlackListListingPage
