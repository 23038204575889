import React, { useCallback, useEffect, useState } from 'react'
import { Box, SelectChangeEvent } from '@mui/material'

import Select from 'components/Common/Select'

import { Role } from 'models/role'
import { Option } from 'models/Select'

import { colors } from 'styles/theme'

import { RoleAndAreasListType } from './RoleField'
import { StaticRoleFieldProps } from './StaticRoleField'
import { boxStyle, flexStyle, StyledSquareIcon } from './style'

type SelectRoleFieldProps = RoleAndAreasListType &
  StaticRoleFieldProps & {
    handleChangeRole: (newRole: Role, index: number) => void
    index: number
  }

export const SelectRoleField = ({
  index,
  role,
  availableAreas,
  availableRoles,
  handleChangeRole,
}: SelectRoleFieldProps) => {
  const [selectedArea, setSelectedArea] = useState<Option | undefined>(
    undefined
  )
  const [selectedRole, setSelectedRole] = useState<Option | undefined>(
    undefined
  )
  const [filteredRoles, setFilteredRoles] = useState<Option[]>([])

  const findArea = useCallback(
    (areaValue: string) => {
      return availableAreas.find((singleArea) => singleArea.value === areaValue)
    },
    [availableAreas]
  )

  const filterRoles = useCallback(
    (foundArea: Option) => {
      setFilteredRoles(
        availableRoles
          .filter((singleRole) => singleRole.area === foundArea.value)
          .map((singleRole) => {
            return {
              value: singleRole.value,
              name: singleRole.name,
            }
          })
      )
    },
    [availableRoles]
  )
  const handleSelectArea = (event: SelectChangeEvent<unknown>) => {
    const foundArea = findArea(String(event.target.value))

    if (foundArea) {
      setSelectedArea(foundArea)
      filterRoles(foundArea)
    }
  }

  const handleSelectRole = (event: SelectChangeEvent<unknown>) => {
    const foundRole = availableRoles.find(
      (singleArea) => singleArea.value === event.target.value
    )
    setSelectedRole(
      filteredRoles.find(
        (singleArea) => singleArea.value === event.target.value
      )
    )
    if (foundRole) {
      handleChangeRole(foundRole, index)
    }
  }

  useEffect(() => {
    if (role) {
      setSelectedArea(findArea(role.area))
      setSelectedRole({
        name: role.name,
        value: role.value,
      })
    }
  }, [availableAreas, findArea, role])

  useEffect(() => {
    if (role) {
      const foundArea = findArea(role?.area)

      if (foundArea) {
        filterRoles(foundArea)
      }
    }
  }, [availableAreas, availableRoles, filterRoles, findArea, role])

  return (
    <Box key={`${role?.value}-option`}>
      <Box sx={flexStyle}>
        <StyledSquareIcon sx={{ fontSize: '12px !important' }} />
        <Select
          sx={{
            ...boxStyle,
            color: colors.darkGray,
            fontWeight: 'bold',
            marginRight: '10px',
          }}
          options={availableAreas}
          variant="outlined"
          value={selectedArea?.value || ''}
          onChange={handleSelectArea}
        />
        <Select
          key={`${role?.value}-option-${index}`}
          disabled={!selectedArea}
          sx={{ ...boxStyle, color: colors.placeholderGray }}
          options={filteredRoles}
          variant="outlined"
          value={selectedRole?.value || ''}
          onChange={handleSelectRole}
        />
      </Box>
    </Box>
  )
}
