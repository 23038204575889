import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import SearchIcon from '@mui/icons-material/Search'
import { SelectChangeEvent } from '@mui/material'
import {
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid'

import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import Table from 'components/Common/Table'
import Tooltip from 'components/Common/Table/Tooltip'
import Title from 'components/Common/Title'
import { Filter } from 'components/Inspection/Dashboard/FilterCard'
import FieldFilter from 'components/Inspection/Listing/FieldFilter'
import FilterByCriteria from 'components/Listing/FilterByCriteria'
import RolesTooltip from 'components/UserManagement/Listing/RolesTooltip'

import { cypressAddButton } from 'constants/cypress'
import { FilteringOption } from 'constants/Inspection/filterByCriteria'
import { routes } from 'constants/routes'
import { commonListColumns, selectItems } from 'constants/table'
import { textFiles } from 'constants/textFiles'
import { UserManagementColumnField } from 'constants/UserManagement/listing'
import useDebounce from 'hooks/useDebounce'
import useLocale from 'hooks/useLocale'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import { Option } from 'models/Select'
import {
  BaseListData,
  FilterInputVariable,
  GenericData,
  ListingFilterType,
} from 'models/services/base'
import { Employee } from 'models/services/General/UserManagement/listing'
import { PublicationStatusMap } from 'models/status'
import {
  cleanFilters,
  formatOptionsIntoFilteringOptions,
  onlySpacesText,
} from 'utils/filters'
import { formatPhoneNumber } from 'utils/phone'
import { buildDetailRoute } from 'utils/routes'
import {
  createFieldSelectItems,
  createFieldSelectLabels,
  defaultFields,
} from 'utils/UserManagement/listing'

import { GET_ASSIGNABLE_ROLES } from 'graphQL/UserManagement/Common/queries'
import { LIST_EMPLOYEES } from 'graphQL/UserManagement/Listing/queries'

import { StyledTextField } from 'styles/inspection/listing'

type CustomFilterOption = {
  roles: FilteringOption[]
}

const UserManagemenListingPage = () => {
  const { show } = useNotification()

  const [selectedFields, setSelectedFields] = useState<string[]>(defaultFields)
  const [filtersList, setFiltersList] = useState<Filter[]>([])

  const [searchValue, setSearchValue] = useState<string>('')

  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(
    selectItems[0].value as number
  )

  const [selectedLanguage] = useLocale()
  const history = useHistory()

  const [deleteItem, setDeleteItem] = useState<GridRowId | null>(null)
  const { text } = useTranslation(textFiles.USER_MANAGEMENT_LISTING)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const [data, setData] = useState<GridRowsProp>([])
  const [usersCount, setUsersCount] = useState<number>(0)
  const [filterInput, setFiltersInput] = useState<ListingFilterType>({})
  const [sortModel, setSortModel] = useState<GridSortModel>([])
  const [customFilterOptions, setCustomFilterOptions] =
    useState<CustomFilterOption>({ roles: [] })

  const fieldSelectItems = createFieldSelectItems(text.fieldSelect)
  const fieldSelectLabels = createFieldSelectLabels(text.fieldSelect)

  const debouncer = useDebounce(
    onlySpacesText(searchValue) ? '' : searchValue,
    500
  )

  const { loading: usersLoading } = useQuery<
    BaseListData<Employee[]>,
    FilterInputVariable
  >(LIST_EMPLOYEES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        limit: pageSize,
        start: (page - 1) * pageSize,
        where: {
          text_search: debouncer.length === 0 ? undefined : debouncer,
          ...cleanFilters(filterInput),
        },
        sort:
          sortModel.length > 0 && sortModel[0].sort
            ? {
                [sortModel[0].field]: sortModel[0].sort,
              }
            : undefined,
      },
    },
    onError() {
      show({
        updatedSeverity: 'error',
      })
    },
    onCompleted(response) {
      setData(
        response.listData.data.map((employee) => {
          return {
            ...employee,
            phone:
              !employee.phone || employee.phone.length < 10
                ? generalText.table.notRegistered
                : formatPhoneNumber(employee.phone),
          }
        })
      )
      setUsersCount(response.listData.count)
    },
  })

  const { loading: rolesLoading } = useQuery<
    GenericData<Option[]>,
    FilterInputVariable
  >(GET_ASSIGNABLE_ROLES, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setCustomFilterOptions((prevCustomFilter) => {
        return {
          ...prevCustomFilter,
          roles: formatOptionsIntoFilteringOptions(response.data),
        }
      })
    },
  })

  const editUser = React.useCallback(
    (id: GridCellValue) => {
      history.push(buildDetailRoute(id, routes.USER_MANAGEMENT_DETAIL))
    },
    [history]
  )

  const createUser = () => {
    history.push(routes.USER_MANAGEMENT_CREATION)
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
    setPage(1)
  }

  const {
    picture: pictureColumn,
    id: idColumn,
    standard: standardColumn,
    actions: actionsColumn,
  } = commonListColumns(
    PublicationStatusMap,
    selectedLanguage,
    generalText,
    editUser
  )

  const defaultColumns = [
    {
      ...pictureColumn,
      field: UserManagementColumnField.PICTURE,
    },
    idColumn,
    {
      ...standardColumn,
      field: UserManagementColumnField.NAME,
      headerName: text.table.name,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const { name, lastName } = params.row
        const fullName = `${name} ${lastName}`

        return <Tooltip>{fullName}</Tooltip>
      },
    },
    {
      ...standardColumn,
      field: UserManagementColumnField.EMAIL,
      headerName: text.table.email,
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip>{params.row.email}</Tooltip>
      },
    },
    {
      ...standardColumn,
      field: UserManagementColumnField.PHONE,
      headerName: text.table.phone,
      renderCell: (params: GridRenderCellParams) => {
        return <Tooltip>{params.row.phone}</Tooltip>
      },
    },
    {
      ...standardColumn,
      field: UserManagementColumnField.ADDRESS,
      headerName: text.table.address,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip>
            {params.row.address || generalText.table.notRegistered}
          </Tooltip>
        )
      },
    },
    {
      ...standardColumn,
      field: UserManagementColumnField.ROLES,
      headerName: text.table.roles,
      sortable: false,
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.roles && params.row.roles.length > 0) {
          if (params.row.roles.length > 1) {
            return (
              <Tooltip
                customPopper={<RolesTooltip roles={params.row.roles} />}
              >{`${params.row.roles[0].name}...`}</Tooltip>
            )
          }
          return <Tooltip>{params.row.roles[0].name}</Tooltip>
        }
        return 'N/A'
      },
    },
    actionsColumn,
  ]

  const [columns, setColumns] = useState<GridColDef[]>(defaultColumns)

  const handleFiltersList = (
    newFiltersList: Filter[],
    newFilterInput: ListingFilterType
  ) => {
    setFiltersList(newFiltersList)
    setFiltersInput(newFilterInput)
    setPage(1)
  }

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchValue(event.target.value)
  }

  const handleSelectItem = (valueInput: string) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        return column.field === valueInput
          ? { ...column, hide: !column.hide }
          : column
      })
    )
    setSelectedFields((prevFields) => {
      if (prevFields.includes(valueInput)) {
        return prevFields.filter((field) => field !== valueInput)
      }
      return [...prevFields, valueInput]
    })
  }

  const handleResetDefault = () => {
    setSelectedFields(defaultFields)
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = defaultFields.some(
          (defaultField) => column.field === defaultField
        )
        return { ...column, hide: !fieldExist }
      })
    )
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangePageSize = (event: SelectChangeEvent<number>) => {
    setPageSize(event.target.value as number)
    setPage(1)
  }

  const getPageCount = () => {
    return Math.ceil(usersCount / pageSize)
  }

  useEffect(() => {
    setPage(1)
  }, [debouncer])

  if (rolesLoading) return <LoadingAnimation showAnimation={rolesLoading} />

  return (
    <Box width="100%">
      <Title
        icon={<PlaylistAddCheckIcon />}
        subtitle={`${usersCount} ${text.description}`}
        title={text.title}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        marginTop="2rem"
        width="100%"
      >
        <Button
          startIcon={<AddIcon />}
          size="medium"
          onClick={createUser}
          data-cy={cypressAddButton}
        >
          {text.newUserButton}
        </Button>

        <Box display="flex">
          <Box marginRight="1rem" width="430px">
            <StyledTextField
              icon={<SearchIcon fontSize="small" />}
              placeholder={text.searchPlaceholder}
              fullWidth
              value={searchValue}
              onChange={handleSearchChange}
              name="search"
            />
          </Box>
          <Box marginRight="1rem">
            <FilterByCriteria
              filtersList={filtersList}
              handleFiltersList={handleFiltersList}
              file={textFiles.USER_MANAGEMENT_LISTING}
              filterInput={filterInput}
              options={customFilterOptions}
              staticFields={['roles']}
            />
          </Box>
          <Box marginRight="1rem">
            <FieldFilter
              handleSelectItem={handleSelectItem}
              items={fieldSelectItems}
              selectedValues={selectedFields}
              handleResetDefault={handleResetDefault}
            />
          </Box>
        </Box>
      </Box>
      <Table
        columns={columns}
        searchValue={searchValue}
        data={data}
        currentPage={page}
        onPageChange={handleChangePage}
        onSelectChange={handleChangePageSize}
        pageSize={pageSize}
        selectItems={selectItems}
        pageCount={getPageCount()}
        filtersList={filtersList}
        fields={selectedFields}
        fieldLabels={fieldSelectLabels}
        deletedItemId={deleteItem}
        setDeleteItemId={setDeleteItem}
        route={routes.USER_MANAGEMENT_DETAIL}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
        loading={usersLoading}
        hideDownloadModal
        checkboxSelection={false}
      />
    </Box>
  )
}
export default UserManagemenListingPage
