import React, { useState } from 'react'
import { Comment, DirectionsCar } from '@mui/icons-material'

import NameContainer from 'components/CarSettings/Common/Detail/NameContainer'
import { SaveChangesBlock } from 'components/CarSettings/SaveChangesBlock'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { NameInformation } from 'models/car'
import { BlackListDetailType } from 'models/services/operations/blackList'

import { GridBox, StyledContainer } from './style'

type Props = {
  blackListData: BlackListDetailType
  submitLoading: boolean
  handleSave: (comment?: string | null) => Promise<boolean>
  entityType: string
  editDisabled: boolean
}

const GeneralInformation = ({
  blackListData,
  submitLoading,
  handleSave,
  entityType,
  editDisabled,
}: Props) => {
  const { name, comment } = blackListData

  const { text } = useTranslation(textFiles.BLACK_LIST_DETAIL)
  const { generalInformation: translation } = text
  const {
    text: { table: generalText },
  } = useTranslation(textFiles.GENERAL)

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)

  const initialNameInformation: NameInformation[] = [
    {
      id: 1,
      icon: <DirectionsCar />,
      label: translation.entityLabel,
      name,
      disabled: true,
    },
    {
      id: 2,
      icon: <DirectionsCar />,
      label: translation.entityTypeLabel,
      name: translation[entityType],
      disabled: true,
    },
    {
      id: 3,
      icon: <Comment />,
      label: translation.commentsLabel,
      name: comment || '',
      emptyNameText: generalText.noComment,
      inverted: true,
    },
  ]

  const [informationRows, setInformationRows] = useState<NameInformation[]>(
    initialNameInformation
  )

  const resetState = () => {
    setHasUnsavedChanges(false)
    setInformationRows(initialNameInformation)
  }

  const handleSaveChanges = async () => {
    const isUpdateSuccessful = await handleSave(
      informationRows[2].name as string
    )

    if (isUpdateSuccessful) {
      setHasUnsavedChanges(false)
    }
  }

  const handleChangeInformation = (newInformation: NameInformation[]) => {
    setInformationRows(newInformation)
    setHasUnsavedChanges(true)
  }

  return (
    <StyledContainer>
      <GridBox>
        <NameContainer
          title={translation.title}
          description={translation.description}
          width={399}
          containerSx={{
            padding: '1rem 0 0 !important',
          }}
          handleSetInformation={handleChangeInformation}
          information={informationRows}
          loading={submitLoading}
          editDisabled={editDisabled}
        />
      </GridBox>
      <SaveChangesBlock
        handleSaveChanges={handleSaveChanges}
        resetState={resetState}
        submitLoading={submitLoading}
        shouldRender={hasUnsavedChanges}
      />
    </StyledContainer>
  )
}

export default GeneralInformation
