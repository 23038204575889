import React from 'react'
import { CSSObject, List, Typography } from '@mui/material'

import Box from 'components/Common/Box'
import Container from 'components/Common/Container'

import { dummyHistoryLogData } from 'constants/historyLogs'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { HistoryLogType } from 'models/historyLog'
import { formatDateLocalizedTime } from 'utils/date'

import { HistoryRowItem } from './HistoryRowItem'
import { ScrollCard } from './style'

/**
 * A container that allows to wrap history logs data
 * about changes made by inspectors and users
 */
export const formatTimeStamp = (
  date: Date,
  format: 'full' | 'day' | 'time'
) => {
  const formatTime = `${date.getHours()}:${date.getMinutes()}`
  if (format === 'full') {
    return `${formatDateLocalizedTime(date)}, ${formatTime}`
  }
  if (format === 'time') {
    return `${formatTime}`
  }
  return `${formatDateLocalizedTime(date)}`
}

type HistoryLogProps = {
  height?: string
  dateFormat?: 'full' | 'day' | 'time'
  recent?: boolean
  hasPadding?: boolean
  logs?: HistoryLogType[]
  file?: string
  sx?: CSSObject
  title?: string
  description?: string
}

const HistoryLog = ({
  height,
  logs = dummyHistoryLogData,
  dateFormat = 'full',
  recent = false,
  hasPadding,
  file = textFiles.HISTORY_LOG,
  sx,
  title,
  description,
}: HistoryLogProps) => {
  const { text } = useTranslation(file)
  const today = formatTimeStamp(new Date(), 'day')
  const recentDescription = (
    <Box display="flex" flexDirection="row">
      <Typography variant="body2">{`${today}`}</Typography>
      <Typography variant="body2" paddingLeft="5px" paddingRight="5px">
        -
      </Typography>
      <Typography color="primary" variant="body2">
        {`${text.recent.today}`}
      </Typography>
    </Box>
  )
  return (
    <>
      {recent ? (
        <Container
          title={title || text.recent.title}
          description={description || recentDescription}
          extraText={text.recent.extraText}
          sx={{ ...sx }}
        >
          <ScrollCard variant="outlined" sx={{ height, border: 'none' }}>
            <List>
              {dummyHistoryLogData.map((log) => (
                <HistoryRowItem
                  recent={recent}
                  key={log.id}
                  {...log}
                  dateFormat={dateFormat}
                />
              ))}
            </List>
          </ScrollCard>
        </Container>
      ) : (
        <Container
          title={text.history.title}
          description={text.history.description}
          sx={{
            ...(!hasPadding && { padding: '0px' }),
            ...sx,
          }}
        >
          <ScrollCard variant="outlined" sx={{ height }}>
            <List>
              {logs.map((log) => (
                <HistoryRowItem
                  key={log.id}
                  {...log}
                  dateFormat={dateFormat}
                  file={file}
                />
              ))}
            </List>
          </ScrollCard>
        </Container>
      )}
    </>
  )
}

export default HistoryLog
