import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HelpIcon from '@mui/icons-material/Help'
import PublishIcon from '@mui/icons-material/Publish'
import SaveIcon from '@mui/icons-material/Save'
import { Typography } from '@mui/material'

import {
  cypressAddButton,
  cypressBackButton,
  cypressSaveDraftButton,
  cypressSubmitButton,
} from 'constants/cypress'
import { TEMPLATE_CREATION_OPTION } from 'constants/Template/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { TemplateStatus } from 'models/services/template/creation'
import { TemplateCheckpoint } from 'models/template'
import { TemplateCreationProps } from 'utils/Template/creation'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { ContentBox, StyledContainer, StyledForm } from '../Identity/style'

import {
  StyledButton,
  StyledButtonContainer,
  StyledElement,
  StyledItem,
  StyledNameSection,
} from './style'

const TemplateSummary = ({
  handleBack,
  templateData,
  updateTemplateData,
  setSecondaryBack,
  handleSubmit,
  submitLoading,
}: TemplateCreationProps) => {
  const { identity, stepCreation } = templateData
  const { text: translation } = useTranslation(textFiles.TEMPLATE_CREATION)
  const { summary, buttons } = translation
  const [clickCount, setClickCount] = useState<number>(0)

  const reduceFun = (total: number, questionLen: TemplateCheckpoint) => {
    return total + questionLen.questions.length
  }

  return (
    <StyledContainer>
      <StyledForm>
        <ContentBox
          sx={{
            flexDirection: 'column',
            overflowY: 'auto',
            marginBottom: 0,
            flexWrap: 'unset',
            gap: '0px',
            ul: {
              fontSize: 14,
              padding: '0 1.2rem',
              li: {
                '&::marker': {
                  color: colors.blue,
                },
              },
            },
          }}
        >
          <Typography
            variant="h5"
            color={colors.black}
            fontWeight={FONT_WEIGHT.BOLD}
          >
            {summary.title}
          </Typography>
          <Typography
            variant="subtitle1"
            color={colors.placeholderGray}
            fontWeight={FONT_WEIGHT.MEDIUM}
          >
            {summary.description}
          </Typography>
          <StyledNameSection>
            <Typography variant="body1">{summary.templateName} </Typography>
            <Typography variant="body2" color={colors.black}>
              {identity.templateName}
            </Typography>
          </StyledNameSection>
          <ul>
            {stepCreation.map((step, idx) => (
              <li key={step.id}>
                <StyledItem title={`${summary.step}${idx + 1}: ${step.name}`}>
                  <StyledElement>
                    <CheckCircleIcon />
                    {summary.stepHas}
                    <span>
                      {step.checkpoints.length}
                      {step.checkpoints.length > 1
                        ? summary.checkpoints
                        : summary.checkpoint}
                    </span>
                  </StyledElement>
                  <StyledElement>
                    <HelpIcon />
                    {summary.stepHas}
                    <span>
                      {step.checkpoints.reduce(reduceFun, 0)}
                      {step.checkpoints.reduce(reduceFun, 0) > 1
                        ? summary.questions
                        : summary.question}
                    </span>
                  </StyledElement>
                </StyledItem>
              </li>
            ))}
          </ul>
        </ContentBox>
        <StyledButtonContainer>
          <StyledButton
            variant="text"
            onClick={() => {
              if (clickCount > 0)
                setSecondaryBack(identity.stepsNumber - clickCount)
              handleBack()
            }}
            testId={cypressBackButton}
          >
            {buttons.previous}
          </StyledButton>
          <StyledButton
            startIcon={<AddIcon />}
            onClick={() => {
              setClickCount((prev) => prev + 1)
              updateTemplateData({
                type: TEMPLATE_CREATION_OPTION.UPDATE_IDENTITY,
                payload: {
                  ...identity,
                  stepsNumber: identity.stepsNumber + 1,
                },
              })
            }}
            size="small"
            testId={cypressAddButton}
          >
            {buttons.addStep}
          </StyledButton>
          <StyledButton
            startIcon={<SaveIcon />}
            disabled={submitLoading}
            onClick={() => {
              updateTemplateData({
                type: TEMPLATE_CREATION_OPTION.UPDATE_IDENTITY,
                payload: {
                  ...identity,
                  visibility: false,
                },
              })
              handleSubmit(TemplateStatus.DRAFT)
            }}
            size="small"
            testId={cypressSaveDraftButton}
          >
            {buttons.saveDraft}
          </StyledButton>
          {identity.visibility ? (
            <StyledButton
              startIcon={<PublishIcon />}
              disabled={submitLoading}
              onClick={() => handleSubmit(TemplateStatus.PUBLISHED)}
              size="small"
              testId={cypressSubmitButton}
            >
              {buttons.publish}
            </StyledButton>
          ) : null}
        </StyledButtonContainer>
      </StyledForm>
    </StyledContainer>
  )
}

export default TemplateSummary
