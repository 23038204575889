import CountryVersionInfo from 'components/CarSettings/CountryVersion/Creation/CountryVersionInfo'
import Summary from 'components/CarSettings/CountryVersion/Creation/Summary'
import { StepperModel } from 'components/General/Stepper'

import {
  CountryVersionCreationAction,
  CountryVersionCreationModel,
} from 'models/countryVersionCreation'
import { StepperTextType } from 'models/text/General'

export type CountryVersionCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  submitLoading: boolean
  countryVersionData: CountryVersionCreationModel
  updateCountryVersionData: (action: CountryVersionCreationAction) => void
}

export const INITIAL_COUNTRY_VERSION_CREATION_STEPS: Array<
  React.FC<CountryVersionCreationProps>
> = [CountryVersionInfo, Summary]

export const createCountryVersionStepperItems = (
  text: StepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep,
    },
    { label: text.secondStep },
  ]

  return stepperItems
}
