import React, { useEffect, useState } from 'react'
import { CSSObject } from '@mui/material'

import Box from 'components/Common/Box'
import Container from 'components/Common/Container'

import { NameInformation } from 'models/car'

import EditButtons from './Items/EditButtons'
import ItemContainer from './ItemContainer'
import { StyledBox, StyledContainer } from './style'

type Props = {
  title?: string
  description?: string
  height?: number | string
  width?: number | string
  containerSx?: CSSObject
  information: NameInformation[]
  handleSetInformation?: (information: NameInformation[]) => void
  loading?: boolean
  handleOptionsChange?: (cityId: string) => string
  editDisabled?: boolean
}

const NameContainer = ({
  title,
  description,
  height,
  width,
  containerSx,
  information,
  loading,
  handleOptionsChange,
  handleSetInformation,
  editDisabled,
}: Props) => {
  const [edit, setEdit] = useState<boolean>(false)
  const [info, setInfo] = useState<NameInformation[]>(information)

  const handleCancelEdit = () => {
    setEdit(false)
  }

  const handleEdit = () => {
    if (!edit) {
      setEdit(true)
    } else if (handleSetInformation) {
      const isEmpty = info.some((item) => String(item.name).length < 1)
      if (!isEmpty) {
        handleSetInformation(info)
      }
      handleCancelEdit()
    }
  }

  useEffect(() => {
    setInfo(information)
  }, [edit, information])

  return (
    <Container
      title={title}
      description={description}
      sx={containerSx}
      width={width}
    >
      <StyledBox height={height}>
        <StyledContainer height={height}>
          <EditButtons
            edit={edit}
            handleEdit={handleEdit}
            handleCancelEdit={handleCancelEdit}
            disabled={loading}
            editDisabled={editDisabled}
          />
          <Box>
            {info.map((item, index) => (
              <Box
                key={item.id}
                display="flex"
                width="auto"
                marginBottom={index !== info.length - 1 ? '12px' : 0}
              >
                <Box
                  justifyContent="center"
                  paddingTop="4px"
                  marginRight="24px"
                >
                  {item.icon}
                </Box>

                <ItemContainer
                  item={item}
                  handleInfo={setInfo}
                  edit={edit}
                  index={index}
                  handleOptionsChange={handleOptionsChange}
                />
              </Box>
            ))}
          </Box>
        </StyledContainer>
      </StyledBox>
    </Container>
  )
}

export default NameContainer
