import React from 'react'

import { StyledTab, TabContainer } from './styles'

export type NavBarItem = {
  label: string
  url: string
}

type DetailNavTabProps = {
  tab: number
  handleTabChange?: (event: React.SyntheticEvent, value: number) => void
  items: NavBarItem[]
}

const DetailNavTab = ({ tab, handleTabChange, items }: DetailNavTabProps) => {
  return (
    <>
      <TabContainer
        value={tab}
        onChange={handleTabChange}
        aria-label="inspection detail navigation"
        TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
      >
        {items.map((item) => {
          const { label, url } = item
          return (
            <StyledTab
              wrapped
              label={label}
              key={url}
              data-cy={`${url}-tab-button`}
            />
          )
        })}
      </TabContainer>
    </>
  )
}

export default DetailNavTab
