import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { ApolloError, useQuery } from '@apollo/client'

import { DetailHeader } from 'components/CarSettings/Common/Detail/DetailHeader'
import { DetailSubHeader } from 'components/CarSettings/Common/Detail/DetailSubHeader'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import DetailNavTab, { NavBarItem } from 'components/General/DetailNavTab'
import TabPanel from 'components/Inspection/Detail/TabPanel'
// import InspectorMilestones from 'components/Inspector/Detail/Milestones'
import InspectorPerformance from 'components/Inspector/Detail/Performance'
import PersonalInfoTab from 'components/Inspector/Detail/Personal'

import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { GenericData, GenericInputVariable } from 'models/services/base'
import { InspectorPersonalInformation } from 'models/services/inspector/detail'
import { formatFullName } from 'utils/BasicUtil'
import { generateTabItems } from 'utils/tabs'

import { GET_INSPECTOR_BY_ID } from 'graphQL/Inspector/Detail/queries'

import { ContentContainer, Layout } from 'styles/inspection/detail'

const InspectorDetailPage = () => {
  const { inspectorId } = useParams<{ inspectorId: string }>()
  const { text } = useTranslation(textFiles.INSPECTOR_DETAIL)
  const { general: translation } = text
  const [tab, setTab] = useState<number>(0)

  const [inspectorData, setInspectorData] = useState<
    InspectorPersonalInformation | undefined
  >()
  const [apolloError, setApolloError] = useState<ApolloError | null>(null)

  const history = useHistory()
  const initialItems: NavBarItem[] = generateTabItems({
    tabs: { ...translation.tabs },
  })

  // const [notes, setNotes] = useState<NoteType[]>(commisionsNotesData)

  const { loading: loadingInspectorData } = useQuery<
    GenericData<InspectorPersonalInformation>,
    GenericInputVariable<string>
  >(GET_INSPECTOR_BY_ID, {
    variables: { input: inspectorId },
    onCompleted(response) {
      setInspectorData(response.data)
    },
    onError(error) {
      setApolloError(error)
    },
  })

  const handleInspectorDataChange = (data: InspectorPersonalInformation) => {
    setInspectorData((prevData) => {
      return {
        ...prevData,
        ...data,
      }
    })
  }

  // const handleNotesChange = (newNotes: NoteType[]) => {
  //   setNotes(newNotes)
  // }

  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    setTab(value)
    history.replace(`#${initialItems[value].url}`)
  }

  useEffect(() => {
    if (history.location.hash) {
      let initialValue = 0
      const thisUrl = history.location.hash.split('#')[1]
      Object.keys(translation.tabs).forEach((key, index) => {
        if (key === thisUrl) {
          initialValue = index
        }
      })
      setTab(initialValue)
    }
  }, [history.location.hash, translation.tabs])

  if (loadingInspectorData)
    return <LoadingAnimation showAnimation={loadingInspectorData} />

  if (apolloError) return <Redirect to={routes.NOT_FOUND_ERROR} />

  return inspectorData ? (
    <Layout>
      <DetailHeader
        title={`${translation.title} ${formatFullName(
          inspectorData?.name,
          inspectorData?.lastName
        )}`}
        backButtonText={translation.backButton}
        editable={false}
        status={inspectorData.status}
      />
      <DetailSubHeader text={translation.description} />
      <DetailNavTab
        tab={tab}
        handleTabChange={handleTabChange}
        items={initialItems}
      />
      <ContentContainer>
        <TabPanel value={tab} index={0}>
          <PersonalInfoTab
            inspectorData={inspectorData!}
            handleInspectorData={handleInspectorDataChange}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <InspectorPerformance id={inspectorId} />
        </TabPanel>
        {/* <TabPanel value={tab} index={1}>
          <InspectorMilestones />
        </TabPanel> */}
        {/* <TabPanel value={tab} index={3}>
          <Commisions
            inspectorData={sampleInspector}
            percentage={samplePercentage.percentage}
            pieChartData={commisionsPieChartData}
            carsInspected={30}
            carsInspectedObjective={45}
            notes={notes}
            handleNotesChange={handleNotesChange}
            commission={250}
            commissionCurrency="US$"
          />
        </TabPanel> */}
      </ContentContainer>
    </Layout>
  ) : null
}

export default InspectorDetailPage
