import { CurboSpot } from 'models/curboSpot'

import { BaseEntity, BaseIdEntity } from '../base'
import { ListCar } from '../car'
import { HourTimeType } from '../curboSpot'
import { Inspector } from '../inspector'
import { ExtendedStatus } from '../status'

export enum TIME_TYPE {
  days = 'days',
  month = 'month',
  week = 'week',
}

type ExpectedTimeType = {
  label: string
  timeType: TIME_TYPE
}

export type SellMyCarType = {
  expectedTime: ExpectedTimeType
}

export type Inspection = {
  id: string
  status: ExtendedStatus
  notes: string[]
  car: Pick<ListCar, 'dealer' | 'owner'>
  sellMyCar?: SellMyCarType
}

export type Answer = {
  correctAnswer: boolean
  doesntApply: boolean
  id: string
  label: string
}

export type Question = {
  pictures: string[]
  value: number
  label: string
  id: string
  answer: Answer | null
}

export type Checkpoint = {
  questions: Question[]
  label: string
  id: string
}

export type Step = {
  checkpoints: Checkpoint[]
  label: string
  id: string
}

export type InspectionVehicleInformation = {
  car: Pick<ListCar, 'model' | 'year' | 'trim'>
  currencyCode: string | null
  price: number | null
  steps: Step[] | null
}

export type InspectionReportInformation = {
  steps: Step[] | null
  comment: string | null
  template: BaseEntity | null
}

export type InspectionAppointmentInformation = {
  address: string | null
  latitude: number | null
  longitude: number | null
  date: string
  hour: HourTimeType
  inspector: Inspector | null
  curboSpot: CurboSpot | null
}

export type UpdateInspectionAppointmentType = {
  address: string
  latitude: number
  longitude: number
  date: string
  hour: string
  inspector: string
  curboSpot?: string | null
}

type UpdateInspectionDataType = {
  inspection:
    | {
        notes?: string[]
      }
    | UpdateInspectionAppointmentType
}

export type UpdateInspectionInput = {
  where: {
    id: string
  }
  data: UpdateInspectionDataType
}

export type ScheduleInspectionInput = {
  where: BaseIdEntity
  data: UpdateInspectionAppointmentType
}
