import React from 'react'
import { Typography } from '@mui/material'

import Avatar from 'components/Common/Avatar'
import TimeSensitivePopper from 'components/General/TimeSensitivePopper'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { OutstandingInspectorTextType } from 'models/text/Dashboard'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { StyledContainer, StyledSection } from './style'

type OutstandingInspectorProps = {
  img?: string
  // name: string
  // inspections: number
  // uploadings: number
}

const OutstandingInspector = ({
  img,
}: // name,
// inspections,
// uploadings,
OutstandingInspectorProps) => {
  const { text } = useTranslation(textFiles.INSPECTION_DASHBOARD)
  const {
    inspections: { outstandingInspector },
  }: { inspections: { outstandingInspector: OutstandingInspectorTextType } } =
    text

  return (
    <StyledContainer
      extraText={<TimeSensitivePopper />}
      title={outstandingInspector.title}
    >
      <Avatar image={img} height="120px" width="120px" variant="rounded" />
      <StyledSection>
        <Typography
          variant="subtitle1"
          style={{ color: colors.black, fontWeight: FONT_WEIGHT.SEMI_BOLD }}
        >
          {outstandingInspector.temporalTitle}
        </Typography>
        {/* <ul>
          <li>{`${inspections} ${outstandingInspector.inspections}`}</li>
          <li>{`${uploadings} ${outstandingInspector.uploadings}`}</li>
        </ul> */}
      </StyledSection>
    </StyledContainer>
  )
}

export default OutstandingInspector
