import React from 'react'
import { Typography } from '@mui/material'
import { Cell, Pie, PieChart as RcPieChart, Tooltip } from 'recharts'

import { selectColor } from 'utils/ProgressUtils'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { ResponsiveStyledContainer, StyledPieLabel } from './style'

/**
 ** Size of the Pie Chart
 */
type SizeProps = {
  height: number
  width: number
}

type PieChartProps = {
  /**
   ** Width and Height of the Pie Chart
   */
  size: SizeProps

  /*
   * Score of the graph
   */
  score: [number, number]

  /*
   * Should show the score ?
   */
  showScore?: boolean
  /*
   * Color to use
   */
  color?: string
}

const PieChart = ({ showScore = false, size, score, color }: PieChartProps) => {
  const { width, height } = size
  const selectedColor = color || selectColor(score)
  const percentage = Math.floor((score[0] / score[1]) * 100)
  const coloredCells = score[0]
  const data = [
    { id: 1, name: 'Colored cells', value: coloredCells },
    { id: 2, name: 'Non colored cells', value: score[1] - coloredCells },
  ]
  return (
    <div
      style={{
        width,
        height,
        margin: '1rem auto auto auto',
      }}
    >
      <ResponsiveStyledContainer>
        <>
          <StyledPieLabel sx={{ top: width > 200 ? '40%' : '36%' }}>
            <Typography
              variant={width > 200 ? 'h1' : 'h4'}
              color={selectedColor}
              fontWeight={FONT_WEIGHT.EXTRA_BOLD}
            >
              {score[1] === 0 ? 'N/A' : `${percentage}%`}
            </Typography>
            {showScore && score[1] !== 0 ? (
              <Typography
                variant="body1"
                fontWeight={FONT_WEIGHT.EXTRA_BOLD}
                color={selectedColor}
              >
                {score[0]}/{score[1]}
              </Typography>
            ) : null}
          </StyledPieLabel>
          <RcPieChart width={width} height={height}>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              cx="50%"
              cy="50%"
              data={data}
              innerRadius={width > 200 ? 90 : 50}
              outerRadius={width > 200 ? 110 : 70}
            >
              <Cell key="cell-colored" fill={selectedColor} />
              <Cell key="cell" fill={colors.lightGray} />
            </Pie>
            <Tooltip />
          </RcPieChart>
        </>
      </ResponsiveStyledContainer>
    </div>
  )
}

export default PieChart
