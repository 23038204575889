import { StepperModel } from 'components/General/Stepper'
import CityInfo from 'components/Operations/City/Creation/CityInfo'
import CityLocation from 'components/Operations/City/Creation/CityLocation'
import CitySummary from 'components/Operations/City/Creation/Summary'

import { CityCreationModel } from 'models/cityCreation'
import { Option } from 'models/Select'
import { ThreeStepperTextType } from 'models/text/General'

export type CityCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  cityData: CityCreationModel
  updateCityData: (newCity: CityCreationModel) => void
  handleSubmit: () => void
  submitLoading: boolean
  states: Option[]
}

export const CITY_CREATION_STEPS: Array<React.FC<CityCreationProps>> = [
  CityInfo,
  CityLocation,
  CitySummary,
]

export const createCityStepperItems = (
  text: ThreeStepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep,
    },
    { label: text.secondStep },
    { label: text.thirdStep },
  ]

  return stepperItems
}
