import React from 'react'

import { ItemProps } from '../ItemContainer'

import EditField from './field'
import TextName from './name'

const TextItem = ({
  item,
  handleInfo,
  edit,
  index,
  handleOptionsChange,
}: ItemProps) => {
  return (
    <>
      {!edit || item.disabled ? (
        <TextName item={item} />
      ) : (
        <EditField
          item={item}
          handleInfo={handleInfo}
          index={index}
          handleOptionsChange={handleOptionsChange}
        />
      )}
    </>
  )
}

export default TextItem
