import { TemplateColumnField } from 'constants/template'
import { SelectItem } from 'models/Select'
import { FieldSelectTextType } from 'models/text/TemplateListing'

export const createFieldSelectItems = (
  text: FieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: TemplateColumnField.ID,
    },
    {
      label: text.templateName,
      value: TemplateColumnField.TEMPLATE_NAME,
    },
    {
      label: text.version,
      value: TemplateColumnField.VERSION,
    },
    {
      label: text.provider,
      value: TemplateColumnField.PROVIDER,
    },
    {
      label: text.stepsNumber,
      value: TemplateColumnField.STEPS,
    },
    {
      label: text.createdAt,
      value: TemplateColumnField.CREATED_AT,
    },
    {
      label: text.status,
      value: TemplateColumnField.STATUS,
    },
    {
      label: text.actions,
      value: TemplateColumnField.ACTIONS,
    },
  ]
}

export const createFieldSelectLabels = (
  text: FieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    templateName: text.templateName,
    version: text.version,
    provider: text.provider,
    stepsNumber: text.stepsNumber,
    createdAt: text.createdAt,
    status: text.status,
    actions: text.actions,
  }
}

export const defaultFields = [
  TemplateColumnField.ID,
  TemplateColumnField.TEMPLATE_NAME,
  TemplateColumnField.PROVIDER,
  TemplateColumnField.STEPS,
  TemplateColumnField.VERSION,
  TemplateColumnField.CREATED_AT,
  TemplateColumnField.STATUS,
  TemplateColumnField.ACTIONS,
]
