import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ColorLensIcon from '@mui/icons-material/ColorLens'
import Grid3x3Icon from '@mui/icons-material/Grid3x3'

import Skeleton from 'components/CarSettings/Common/Creation/Summary/Skeleton'
import TranslationItem from 'components/CarSettings/Common/Creation/Summary/TranslationItem'
import ColorBox from 'components/Common/ColorBox'
import SummaryItem from 'components/Common/SummaryItem'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { ColorCreationProps } from 'utils/CarSettings/Color/creation'

import { SectionContainer } from 'styles/creation'

const ColorSummary = ({
  handleBack,
  colorData,
  handleSubmit,
  submitLoading,
}: ColorCreationProps) => {
  const { colorInformation } = colorData
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { text } = useTranslation(textFiles.COLOR_CREATION)
  const { translations } = colorInformation
  const {
    summary: { title, description, name, status, hexCode },
  } = text
  return (
    <Skeleton
      previousFunction={handleBack}
      nextFunction={handleSubmit}
      submitLoading={submitLoading}
      title={title}
      description={description}
    >
      <SectionContainer>
        <SummaryItem
          title={name}
          text={colorInformation.name}
          icon={<ColorLensIcon />}
        />
        <SummaryItem
          title={status}
          text={generalText.status[colorInformation.status]}
          icon={<CheckCircleIcon />}
        />
        {translations.length > 0 ? (
          <TranslationItem translations={translations} />
        ) : null}
        <section>
          <SummaryItem
            title={hexCode}
            text={colorInformation.hexCode.toUpperCase()}
            icon={<Grid3x3Icon />}
          />
          <ColorBox hexCode={colorInformation.hexCode} width={80} height={80} />
        </section>
      </SectionContainer>
    </Skeleton>
  )
}

export default ColorSummary
