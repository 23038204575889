import React from 'react'

import Box from 'components/Common/Box'
import Notes, { NoteType } from 'components/Common/Notes'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Owner } from 'models/services/car'

// import { InsightsContainer } from '../InsightsContainer'
import { PersonalContainer } from '../PersonalContainer'

import { ContactContainer } from './ContactContainer'
import { ClientBox } from './style'

export type InfoRowType = {
  title: string
  subtitle: string
  icon: JSX.Element
}

export type InfoCardType = {
  firstRow: InfoRowType
  secondRow?: InfoRowType
}

type ClientProps = {
  notes: NoteType[]
  handleNotesChange: (newNotes: NoteType[]) => void
  owner: Owner
}

const Client = ({ notes, handleNotesChange, owner }: ClientProps) => {
  const { text } = useTranslation(textFiles.INSPECTION_DETAIL)

  const { provider: translation } = text

  return (
    <ClientBox>
      <Box display="flex" flexDirection="column" width={493}>
        <PersonalContainer
          name={`${owner.name} ${owner.lastName}`}
          address={owner.address}
          title={translation.personalTitle}
          description={translation.personalSubtitle}
          type="owner"
        />
        <ContactContainer phone={owner.telephoneNumber} email={owner.email} />
      </Box>

      {/* <Box display="flex" flexDirection="column" width={333}>
        <InsightsContainer
          carsSold={dummyClientDetail.carsSold}
          carsBought={dummyClientDetail.carsBought}
          lastTransaction={dummyClientDetail.lastTransaction}
        />
      </Box> */}
      <Notes
        data={notes}
        handleDataChange={handleNotesChange}
        height="391px"
        width="333px"
        containerProps={{ sx: { padding: '0.5rem 1.2rem !important' } }}
      />
    </ClientBox>
  )
}

export default Client
