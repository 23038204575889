import { ModelColumnField } from 'constants/CarSettings/columnField'
import { SelectItem } from 'models/Select'
import { ModelFieldSelectTextType } from 'models/text/carSettingsListing'

export const createModelFieldSelectItems = (
  text: ModelFieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.picture,
      value: ModelColumnField.PICTURE,
    },
    {
      label: text.id,
      value: ModelColumnField.ID,
    },
    {
      label: text.brandName,
      value: ModelColumnField.BRAND_NAME,
    },
    {
      label: text.name,
      value: ModelColumnField.NAME,
    },
    {
      label: text.createdAt,
      value: ModelColumnField.CREATED_AT,
    },

    {
      label: text.status,
      value: ModelColumnField.STATUS,
    },
    {
      label: text.actions,
      value: ModelColumnField.ACTIONS,
    },
  ]
}

export const createModelFieldSelectLabels = (
  text: ModelFieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    picture: text.picture,
    createdAt: text.createdAt,
    status: text.status,
    actions: text.actions,
    name: text.name,
    brandName: text.brandName,
  }
}

export const ModelDefaultFields = [
  ModelColumnField.ID,
  ModelColumnField.PICTURE,
  ModelColumnField.NAME,
  ModelColumnField.CREATED_AT,
  ModelColumnField.STATUS,
  ModelColumnField.ACTIONS,
  ModelColumnField.BRAND_NAME,
]
