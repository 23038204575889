import { gql } from '@apollo/client'

export const GET_COLOR_BY_ID = gql`
  query getColorById($input: ID!) {
    data: getColorById(id: $input) {
      nameTranslations {
        en
        es
      }
      name
      status
      hexCode
    }
  }
`
