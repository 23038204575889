import React from 'react'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import { Typography } from '@mui/material'

import { CHANGES_TYPE_ENUM } from 'constants/Inspection/dashboard'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { PreviousChanges } from 'models/services/inspection/dashboard'
import { InspectionsStatisticsTextType } from 'models/text/Dashboard'

import { colors } from 'styles/theme'

import {
  IconContainer,
  InspectionsChangeText,
  InspectorStatsBox,
  StyledBoxContainer,
} from './style'

const ChangeIcons: Record<CHANGES_TYPE_ENUM, React.ReactNode> = {
  [CHANGES_TYPE_ENUM.POSITIVE]: (
    <KeyboardArrowUpRoundedIcon sx={{ fontSize: 60 }} />
  ),
  [CHANGES_TYPE_ENUM.NEGATIVE]: (
    <KeyboardArrowDownRoundedIcon sx={{ fontSize: 60 }} />
  ),
  [CHANGES_TYPE_ENUM.NEUTRAL]: <RemoveRoundedIcon sx={{ fontSize: 60 }} />,
}

const StatisticColors: Record<CHANGES_TYPE_ENUM, string> = {
  [CHANGES_TYPE_ENUM.POSITIVE]: colors.green,
  [CHANGES_TYPE_ENUM.NEGATIVE]: colors.red,
  [CHANGES_TYPE_ENUM.NEUTRAL]: colors.yellow,
}
/**
 ** The Inspections on Change container with the three boxes.
 */
const InspectionsChange = ({ approved, rejected, total }: PreviousChanges) => {
  const { text } = useTranslation(textFiles.INSPECTION_DASHBOARD)
  const { statistics }: { statistics: InspectionsStatisticsTextType } =
    text.inspections

  return (
    <StyledBoxContainer>
      <InspectorStatsBox width="155px" paddingTop="25px">
        <Typography
          variant="h2"
          color={StatisticColors[total.status]}
          fontSize="32px"
        >
          {parseFloat(total.percentage.toFixed(2))}%
          <IconContainer>{ChangeIcons[total.status]}</IconContainer>
        </Typography>
        <InspectionsChangeText
          variant="body2"
          color={StatisticColors[total.status]}
        >
          {statistics[total.status]}
        </InspectionsChangeText>
        <Typography variant="body2" marginTop="5px" marginBottom="10px">
          {statistics.total}
        </Typography>
      </InspectorStatsBox>
      <InspectorStatsBox width="155px" paddingTop="25px">
        <Typography
          variant="h2"
          color={StatisticColors[approved.status]}
          fontSize="32px"
        >
          {parseFloat(approved.percentage.toFixed(2))}%
          <IconContainer>{ChangeIcons[approved.status]}</IconContainer>
        </Typography>
        <InspectionsChangeText
          variant="body2"
          color={StatisticColors[approved.status]}
        >
          {statistics[approved.status]}
        </InspectionsChangeText>
        <Typography variant="body2" marginTop="5px" marginBottom="10px">
          {statistics.approved}
        </Typography>
      </InspectorStatsBox>
      <InspectorStatsBox width="155px" paddingTop="25px">
        <Typography
          variant="h2"
          color={StatisticColors[rejected.status]}
          fontSize="32px"
        >
          {parseFloat(rejected.percentage.toFixed(2))}%
          <IconContainer>{ChangeIcons[rejected.status]}</IconContainer>
        </Typography>
        <InspectionsChangeText
          variant="body2"
          color={StatisticColors[rejected.status]}
        >
          {statistics[rejected.status]}
        </InspectionsChangeText>
        <Typography variant="body2" marginTop="5px" marginBottom="10px">
          {statistics.rejected}
        </Typography>
      </InspectorStatsBox>
    </StyledBoxContainer>
  )
}

export default InspectionsChange
