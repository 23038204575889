import React, { useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'

import { BackForwardStepButton } from 'components/General/Stepper/BackForwardStepButton'
import {
  StyledContainer,
  StyledForm,
} from 'components/Motives/VehicleMotives/style'

import { cypressNextStepButton } from 'constants/cypress'
import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import { Hour } from 'models/hours'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { CreateHourInput } from 'models/services/hour'
import { validateGraphQLErrorCode } from 'utils/error'
import { HourCreationProps } from 'utils/Hour'

import { CREATE_INSPECTION_HOUR } from 'graphQL/Setting/mutations'
import { GET_INSPECTION_HOURS } from 'graphQL/Setting/queries'

import { HourContentBox } from '../ContentBox'

const InspectionHours = ({ handleContinue, hoursData }: HourCreationProps) => {
  const { text } = useTranslation(textFiles.HOURS_CREATION)
  const { general: translation } = text
  const { show } = useNotification()

  const [editHour, setEditHour] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const { inspection: inspectionHours } = hoursData
  const [singleHour, setSingleHour] = useState<Hour>()

  const [createInspectionHour, { loading: createInspectionLoading }] =
    useMutation<
      GenericData<BaseIdEntity>,
      GenericInputVariable<CreateHourInput>
    >(CREATE_INSPECTION_HOUR, {
      refetchQueries: [GET_INSPECTION_HOURS],
      onCompleted() {
        show({
          updatedSeverity: 'success',
          message: translation.createSuccess,
        })
      },
      onError(error) {
        const { errorExists } = validateGraphQLErrorCode(
          error,
          DUPLICATE_KEY_ERROR
        )
        show({
          updatedSeverity: 'error',
          message: errorExists ? translation.duplicate : translation.createFail,
        })
      },
    })

  const duplicate =
    singleHour &&
    hoursData.inspection.find(
      (hour: Hour) => hour.continentalTime === singleHour.continentalTime
    )

  const handleAdding = () => {
    if (singleHour && !duplicate) {
      createInspectionHour({
        variables: {
          input: {
            continentalTime: singleHour.continentalTime,
            visible: true,
          },
        },
      })
      setSingleHour(undefined)
    } else {
      show({
        updatedSeverity: 'error',
        message: translation.duplicate,
      })
    }
  }

  const handleChangeSingleHour = useCallback((value: Hour) => {
    setSingleHour(value)
  }, [])

  return (
    <StyledContainer>
      <StyledForm>
        <HourContentBox
          loading={createInspectionLoading}
          hours={inspectionHours}
          editHour={editHour}
          singleHour={singleHour}
          editMode={editMode}
          handleChangeSingleHour={handleChangeSingleHour}
          handleAdding={handleAdding}
          handleEditHour={setEditHour}
          handleEditMode={setEditMode}
        />
        <BackForwardStepButton
          disabled={editMode}
          handleClick={handleContinue}
          label={translation.buttons.testDrive}
          testId={cypressNextStepButton}
        />
      </StyledForm>
    </StyledContainer>
  )
}

export default InspectionHours
