import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@mui/material'

import { LocaleProvider } from 'context/LocaleContext'
import NotificationProvider from 'context/NotificationContext'
import { SettingProvider } from 'context/SettingContext'
import { AppRouter } from 'router/AppRouter'

import { graphqlClient } from 'graphQL/client'

import globalTheme from './styles/theme'

const App = () => {
  return (
    <ApolloProvider client={graphqlClient}>
      <ThemeProvider theme={globalTheme}>
        <LocaleProvider>
          <SettingProvider>
            <NotificationProvider>
              <Router>
                <Route path="/">
                  <AppRouter />
                </Route>
              </Router>
            </NotificationProvider>
          </SettingProvider>
        </LocaleProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default App
