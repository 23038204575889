import SquareIcon from '@mui/icons-material/Square'
import { inputBaseClasses, selectClasses, styled } from '@mui/material'

import Box from 'components/Common/Box'
import TextField from 'components/Common/TextField'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledStripe = styled(Box)({
  color: colors.placeholderGray,
  backgroundColor: colors.commonWhite,
  fontWeight: FONT_WEIGHT.MEDIUM,
  fontSize: 14,
  height: 30,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${colors.lightGray} !important`,
  section: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: {
      color: colors.black,
      marginRight: '7px',
    },
  },
})

export const StyledTextField = styled(TextField)({
  width: '100%',
  height: '38px',
  borderRadius: '4px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.gray,
  div: {
    height: '100%',
    fontSize: '14px',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },

  input: {
    height: '38px',
    fontSize: '14px',
    padding: '0 0 0 12px',
    '&:-internal-autofill-selected': {
      backgroundColor: colors.commonWhite,
    },
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
})

export const StyledSquareIcon = styled(SquareIcon)({
  color: colors.blue,
  borderRadius: '50px',
  marginRight: '8px',
  fontSize: 20,
})
