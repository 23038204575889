import React, { useEffect, useRef, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'
import ErrorIcon from '@mui/icons-material/Error'
import SaveIcon from '@mui/icons-material/Save'
import * as material from '@mui/material'
import { Divider, SelectChangeEvent } from '@mui/material'
import { nanoid } from 'nanoid'

import * as DetailSubHeader from 'components/CarSettings/Common/Detail/DetailSubHeader'
import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import CopyToClipboard from 'components/Common/CopyToClipboard'
import GoBackButton from 'components/Common/GoBackButton'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import Select from 'components/Common/Select'
import Tag from 'components/Common/Tag'
import ConfirmationModal, {
  ModalText,
} from 'components/General/ConfirmationModal'
import DetailNavTab from 'components/General/DetailNavTab'
import TabPanel from 'components/Inspection/Detail/TabPanel'
import CheckpointContent from 'components/Template/Detail/CheckpointContent'
import { StyledButton } from 'components/Template/Detail/CheckpointContent/style'
import RouteLeavingGuard from 'components/Template/Detail/RouteGuard'
import StatusChangePopper from 'components/Template/Detail/StatusChangePopper'
import {
  StyledErrorMessage,
  StyledTextFieldContainer,
} from 'components/Template/Detail/TemplateQuestion/style'
import TextArea from 'components/Template/Detail/TextArea'

import { routes } from 'constants/routes'
import { defaultCheckpoint, defaultQuestion } from 'constants/Template/detail'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import { Option } from 'models/Select'
import { GenericData, GenericInputVariable } from 'models/services/base'
import {
  Checkpoint,
  InspectionFailureReason,
  Question,
  Step,
  TemplateDetail,
  UpdateTemplateInputType,
  UpdateTemplateType,
} from 'models/services/template/detail'
import { backendTemplateStatus, TemplateStatus } from 'models/status'

import { GET_INSPECTION_FAILURES_REASONS } from 'graphQL/Template/Creation/queries'
import { UPDATE_TEMPLATE } from 'graphQL/Template/Detail/mutations'
import { GET_TEMPLATE_BY_ID } from 'graphQL/Template/Detail/queries'

import { ContentContainer, Layout } from 'styles/inspection/detail'
import {
  CheckpointItem,
  CheckpointList,
  NavContainer,
} from 'styles/template/detail'
import { colors, FONT_WEIGHT } from 'styles/theme'

const TemplateDetailPage = () => {
  const { text } = useTranslation(textFiles.TEMPLATE_DETAIL)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { notificationText } = text
  const { templateId } = useParams<{ templateId: string }>()
  const history = useHistory()

  const [tab, setTab] = useState<number>(
    history.location.hash ? Number(history.location.hash.split('#')[1]) : 0
  )

  const [reasonOptions, setReasonOptions] = useState<Option[]>([])
  const [editMode, setEditMode] = useState<boolean>(false)
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const [templateStatus, setTemplateStatus] = useState<TemplateStatus>(
    TemplateStatus.DRAFT
  )
  /**
   * Boolean used as a flag to know if the Save All Changes button was pressed on edit mode.
   * It is used as a guideline for rendering error messages in the TextArea, CheckpointContent and TemplateQuestion components
   */
  const [saveStateCalled, setSaveStateCalled] = useState<boolean>(false)

  /*
    In this ref we store the original template so if the
    user cancel changes we return to this point in time
  */
  const originalTemplate = useRef<TemplateDetail | null>(null)

  const [apolloError, setApolloError] = useState<ApolloError | null>(null)
  /*
    Template state used when editing the form.
  */
  const [editTemplate, setEditTemplate] = useState<TemplateDetail | null>(null)

  const { show } = useNotification()

  useQuery<GenericData<Option[]>>(GET_INSPECTION_FAILURES_REASONS, {
    onCompleted(response) {
      setReasonOptions(response.data)
    },
  })

  const { loading: loadingTemplateData } = useQuery<
    GenericData<TemplateDetail>,
    GenericInputVariable<string>
  >(GET_TEMPLATE_BY_ID, {
    variables: {
      input: templateId,
    },
    onCompleted(queryData) {
      const getTemplateByIdData: TemplateDetail = queryData.data

      /**
       * Setting local states with Backend data.
       * Template Data (True backend data)
       * TemplateStatus (Status variable of the template)
       * EditTemplate (Template value used to represent the edited values)
       * OriginalTemplate (as a copy of the original data. In case we need to rollback to default values)
       */

      setTemplateStatus(TemplateStatus[getTemplateByIdData.status])
      setEditTemplate(getTemplateByIdData)
      originalTemplate.current = getTemplateByIdData
    },
    onError(getTemplateByIdError) {
      show({
        updatedSeverity: 'error',
        message: notificationText.errorNotFound,
      })
      setApolloError(getTemplateByIdError)
    },
  })

  /*
   * Query to update data in the backend
   */

  const [updateTemplate] = useMutation<
    GenericData<UpdateTemplateType>,
    GenericInputVariable<UpdateTemplateInputType>
  >(UPDATE_TEMPLATE, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
        message: notificationText.editSuccess,
      })
    },
    onError() {
      show({
        updatedSeverity: 'error',
        message: notificationText.editFailed,
      })
    },
    refetchQueries: [GET_TEMPLATE_BY_ID],
  })

  /*
    Sends a 'signal' to the questions component to update
    the state with their changes
  */
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false)
  /*
    We use this state to update the ref after the editTemplate
    has been edited
  */
  const [shouldUpdateRef, setShouldUpdateRef] = useState<boolean>(false)

  /**
   * We use this state to keep tracking if one of the checkpoints has the
   * failureReason attribute missing, since its completely required by the backend
   */
  const [checkpointFailureReasonMissing, setCheckpointFailureReasonMissing] =
    useState<boolean>(false)

  const setCheckpointFailureReasonError = (error: boolean) => {
    setCheckpointFailureReasonMissing(error)
  }

  /**
   * State to keep track if any of the answers is missing on the questions. If so, then API call is not fired, until all of them are filled
   */
  const [answerMissing, setAnswerMissing] = useState<boolean>(false)

  const setAnswerMissingError = (error: boolean) => {
    setAnswerMissing(error)
  }

  /* 
  Handler for tab change, if is in editMode it doesn't go anywhere
  */
  const handleTabChange = (event: React.SyntheticEvent, value: number) => {
    if (!editMode) {
      setTab(value)
      history.replace(`#${value}`)
    }
  }

  // Handlers for the different changes of the template

  // Starting with the title
  const handleTitleChange = (newName: string) => {
    setEditTemplate((prevTemplate) => {
      if (prevTemplate && prevTemplate.status)
        return {
          ...prevTemplate,
          name: newName,
        }
      return prevTemplate
    })
  }
  /*
    To change the description field of the step, we start by 
    making a copy of the template steps, we copy the step that
    will be changed, made the change, update the template steps
    copy and finally update the template state with this 'new' steps
  */
  const handleStepLabelChange = (newStepLabel: string) => {
    setEditTemplate((prevTemplate) => {
      if (prevTemplate !== null) {
        const previousSteps = [...prevTemplate.steps]
        const selectedStep = previousSteps[tab]
        const newStep = {
          ...selectedStep,
          label: newStepLabel,
        }
        previousSteps.splice(tab, 1, newStep)
        if (prevTemplate && prevTemplate.status)
          return {
            ...prevTemplate,
            steps: previousSteps,
          }
      }

      return prevTemplate
    })
  }

  /*
    We follow a similar mechanic just that in here we also copy the
    current step checkpoints, make the changes and afterwards update
    the checkpoints, the steps and finally the template state
  */
  const handleCheckpointDescriptionChange = (
    newCheckpointDescription: string,
    checkpointIndex: number
  ) => {
    setEditTemplate((prevTemplate) => {
      if (prevTemplate) {
        const previousSteps = [...prevTemplate.steps]
        const currentStep = previousSteps[tab]
        const currentCheckpoints = [...currentStep.checkpoints]
        const newCheckpoint = {
          ...currentCheckpoints[checkpointIndex],
          description: newCheckpointDescription,
        }
        currentCheckpoints.splice(checkpointIndex, 1, newCheckpoint)
        previousSteps.splice(tab, 1, {
          ...prevTemplate.steps[tab],
          checkpoints: currentCheckpoints,
        })
        return {
          ...prevTemplate,
          steps: previousSteps,
        }
      }
      return prevTemplate
    })
  }

  const handleCheckpointLabelChange = (
    newCheckpointLabel: string,
    checkpointIndex: number
  ) => {
    setEditTemplate((prevTemplate) => {
      if (prevTemplate) {
        const previousSteps = [...prevTemplate.steps]
        const currentStep = previousSteps[tab]
        const currentCheckpoints = [...currentStep.checkpoints]
        const newCheckpoint = {
          ...currentCheckpoints[checkpointIndex],
          label: newCheckpointLabel,
        }
        currentCheckpoints.splice(checkpointIndex, 1, newCheckpoint)
        previousSteps.splice(tab, 1, {
          ...prevTemplate.steps[tab],
          checkpoints: currentCheckpoints,
        })
        return {
          ...prevTemplate,
          steps: previousSteps,
        }
      }
      return prevTemplate
    })
  }

  const handleCancelChanges = () => {
    if (editMode === true) {
      /* 
        If we cancel changes return editTemplate to original state
      */
      if (originalTemplate.current && originalTemplate.current !== null) {
        setEditTemplate(originalTemplate.current)
      }
    }
    /* 
      This callback is executed by the cancel all changes 
      button and enter edit mode so we always have 
      to switch the mode.
    */
    setEditMode((prevMode) => !prevMode)
  }

  const openConfirmationModal = () => {
    setConfirmationModal(true)
  }
  const closeConfirmationModal = () => {
    setConfirmationModal(false)
  }

  /*
    We send the signal so the questions components update
    the editTemplate state, we give it a few ms for it to
    work and then turn off the signal. When the timeout
    finished we assume the editTemplate has been update so
    we update the originalTemplate ref. Is necessary to do
    it like this because if we try to update the ref here
    it would have the editTemplate version without the latest
    changes.
  */
  const handleSaveState = () => {
    setShouldUpdate(true)
    setSaveStateCalled(true)
    closeConfirmationModal()
    setTimeout(() => {
      setShouldUpdate(false)
      if (!checkpointFailureReasonMissing && !answerMissing) {
        setEditMode(false)
      }
      setShouldUpdateRef(true)
    })
  }

  const handleLeaveConfirm = () => {
    setEditMode(false)
  }

  const handleStatusChange = (newStatus: TemplateStatus) => {
    setTemplateStatus(newStatus)
    setEditTemplate((prevTemplate) => {
      if (prevTemplate && prevTemplate.status)
        return {
          ...prevTemplate,
          status: backendTemplateStatus[newStatus],
        }
      return prevTemplate
    })
  }

  const handleQuestionChange = (
    checkpointIndex: number,
    questionIndex: number,
    updatedQuestion?: Question
  ) => {
    if (updatedQuestion) {
      setEditTemplate((prevTemplate) => {
        if (prevTemplate && prevTemplate.status) {
          const previousSteps = [...prevTemplate.steps]
          const currentStep = previousSteps[tab]
          const currentCheckpoints = [...currentStep.checkpoints]
          const currentQuestions = [
            ...currentCheckpoints[checkpointIndex].questions,
          ]
          currentQuestions.splice(questionIndex, 1, updatedQuestion)
          const newCheckpoint = {
            ...currentCheckpoints[checkpointIndex],
            questions: currentQuestions,
          }
          currentCheckpoints.splice(checkpointIndex, 1, newCheckpoint)
          previousSteps.splice(tab, 1, {
            ...prevTemplate.steps[tab],
            checkpoints: currentCheckpoints,
          })
          return {
            ...prevTemplate,
            steps: previousSteps,
          }
        }
        return prevTemplate
      })
    }
  }

  const handleAddQuestion = (checkpointIndex: number) => {
    setEditTemplate((prevTemplate) => {
      if (prevTemplate && prevTemplate.status) {
        const newTemplate = { ...prevTemplate }

        const newSteps = [...newTemplate.steps]

        const newCheckpoints = [...newSteps[tab].checkpoints]

        const newQuestions = [...newCheckpoints[checkpointIndex].questions]

        newQuestions.push({ ...defaultQuestion, id: nanoid(24) })

        newCheckpoints[checkpointIndex] = {
          ...newCheckpoints[checkpointIndex],
          questions: newQuestions,
        }

        newSteps[tab] = {
          ...newSteps[tab],
          checkpoints: newCheckpoints,
        }

        return {
          ...newTemplate,
          steps: newSteps,
        }
      }
      return prevTemplate
    })
  }

  const handleDeleteQuestion = (
    checkpointIndex: number,
    questionIndex: number
  ) => {
    setEditTemplate((prevTemplate) => {
      if (prevTemplate && prevTemplate.status) {
        const newTemplate = { ...prevTemplate }

        const newSteps = [...newTemplate.steps]

        const newCheckpoints = [...newSteps[tab].checkpoints]

        const newQuestions = [...newCheckpoints[checkpointIndex].questions]

        newQuestions.splice(questionIndex, 1)

        newCheckpoints[checkpointIndex] = {
          ...newCheckpoints[checkpointIndex],
          questions: newQuestions,
        }

        newSteps[tab] = {
          ...newSteps[tab],
          checkpoints: newCheckpoints,
        }

        return {
          ...newTemplate,
          steps: newSteps,
        }
      }
      return prevTemplate
    })
  }

  const handleAddCheckpoint = () => {
    setEditMode(true)
    setEditTemplate((prevTemplate) => {
      if (prevTemplate) {
        const previousSteps = [...prevTemplate.steps]
        const previousCheckPoints = [...previousSteps[tab].checkpoints]

        /**
         * Adds a default checkpoint below the existing ones,
         * so that it can be edited
         */

        const newCheckpoint: Checkpoint = {
          ...defaultCheckpoint,
          id: nanoid(),
          questions: [{ ...defaultQuestion, id: nanoid() }],
        }
        const newStep: Step = {
          ...previousSteps[tab],
          checkpoints: [...previousCheckPoints, newCheckpoint],
        }

        previousSteps.splice(tab, 1, newStep)

        return {
          ...prevTemplate,
          steps: [...previousSteps],
        }
      }
      return prevTemplate
    })
  }

  const handleEditCheckpointFailureReason = (
    checkpointIndex: number,
    newFailureReason: InspectionFailureReason
  ) => {
    setEditTemplate((prevTemplate) => {
      if (prevTemplate) {
        const previousSteps = [...prevTemplate.steps]
        const previousCheckPoints = [...previousSteps[tab].checkpoints]

        /**
         * Edits the current selected checkpoint to add the failureReason
         */

        const newCheckpoint: Checkpoint = {
          ...previousCheckPoints[checkpointIndex],
          failureReason: newFailureReason,
        }

        previousCheckPoints.splice(checkpointIndex, 1, newCheckpoint)

        const newStep: Step = {
          ...previousSteps[tab],
          checkpoints: previousCheckPoints,
        }

        previousSteps.splice(tab, 1, newStep)

        return {
          ...prevTemplate,
          steps: [...previousSteps],
        }
      }
      return prevTemplate
    })
  }

  const handleDeleteCheckpoint = (indexToReplace: number) => {
    setEditMode(true)
    setEditTemplate((prevTemplate) => {
      if (prevTemplate) {
        const previousSteps = [...prevTemplate.steps]
        const newCheckPoints = [...previousSteps[tab].checkpoints]

        newCheckPoints.splice(indexToReplace, 1)

        const newStep: Step = {
          ...previousSteps[tab],
          checkpoints: [...newCheckPoints],
        }

        previousSteps.splice(tab, 1, newStep)

        return {
          ...prevTemplate,
          steps: [...previousSteps],
        }
      }
      return prevTemplate
    })
  }

  // Steps labels

  const initialItems = Array.from(
    { length: editTemplate?.steps?.length || 0 },
    (_, index) => {
      return {
        label: `${text.general.step}${index + 1}`,
        url: `${index}`,
      }
    }
  )

  const confirmationModalText: ModalText = {
    header: text.modals.confirmChangesTitle,
    confirmButton: text.buttons.applyChanges,
    cancelButton: text.buttons.cancel,
  }

  const routeLeavingModalText: ModalText = {
    header: text.modals.unsavedChangesTitle,
    confirmButton: text.buttons.leavePage,
    cancelButton: text.buttons.cancel,
  }

  useEffect(() => {
    if (shouldUpdateRef) {
      originalTemplate.current = editTemplate
      setShouldUpdateRef(false)

      if (!checkpointFailureReasonMissing && !answerMissing) {
        updateTemplate({
          variables: {
            input: {
              data: {
                name: editTemplate?.name,
                provider: editTemplate?.provider
                  ? String(editTemplate?.provider?.id)
                  : undefined,
                steps: editTemplate?.steps.map((templateStep) => {
                  return {
                    checkpoints: templateStep.checkpoints.map((checkPoint) => {
                      return {
                        description: checkPoint.description,
                        failureReason: checkPoint.failureReason.id,
                        label: checkPoint.label,
                        questions: checkPoint.questions.map((question) => {
                          return {
                            answers: question.answers.map(
                              (answer) => answer.id
                            ),
                            isPictureRequired: question.isPictureRequired,
                            isRequired: question.isRequired,
                            label: question.label,
                            value: question.value,
                          }
                        }),
                      }
                    }),
                    label: templateStep.label,
                  }
                }),
              },
              where: {
                id: templateId,
              },
            },
          },
        })
      }
    }
  }, [
    setShouldUpdateRef,
    editTemplate,
    originalTemplate,
    shouldUpdateRef,
    templateId,
    updateTemplate,
    answerMissing,
    checkpointFailureReasonMissing,
  ])

  if (apolloError) return <Redirect push to={routes.NOT_FOUND_ERROR} />

  return (
    <Layout>
      <GoBackButton>{`< ${text.general.backButton}`}</GoBackButton>
      {loadingTemplateData ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <LoadingAnimation
            showAnimation={loadingTemplateData}
            height="400px"
          />
        </Box>
      ) : (
        <>
          {editTemplate ? (
            <Box
              display="flex"
              alignItems="center"
              paddingTop="0.5rem"
              sx={{
                width: '100%',
                [`input`]: {
                  textOverflow: 'ellipsis',
                },
                [`& > div:nth-of-type(1)`]: {
                  maxWidth: '80%',
                },
              }}
            >
              <TextArea
                editMode={editMode}
                rows={1}
                variant="TITLE"
                initialValue={editTemplate.name}
                inputSize={editTemplate.name.length}
                updateText={handleTitleChange}
                saveStateCalled={saveStateCalled}
              />

              <Box display="flex">
                <Tag status={TemplateStatus[editTemplate.status]} />

                {editTemplate.status === 'DRAFT' ? (
                  <StatusChangePopper
                    status={templateStatus}
                    setStatus={handleStatusChange}
                    templateId={templateId}
                  />
                ) : null}
              </Box>
            </Box>
          ) : null}

          <DetailSubHeader.DetailSubHeader
            component={
              <material.Typography
                variant="body2"
                color={colors.blue}
                marginLeft="0.5rem"
              >
                {'ID: '} <CopyToClipboard textToCopy={`${templateId}`} />
              </material.Typography>
            }
          />

          <NavContainer>
            <DetailNavTab
              tab={tab}
              handleTabChange={handleTabChange}
              items={initialItems}
            />
            {editTemplate && editTemplate.status === 'DRAFT' ? (
              <Box
                sx={{
                  marginBottom: '0.5em',
                  display: 'flex',
                }}
              >
                <Button
                  variant="text"
                  onClick={handleCancelChanges}
                  disableRipple
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '30px !important',
                    [`&:hover`]: {
                      cursor: 'pointer',
                    },
                  }}
                >
                  {editMode ? (
                    <CancelIcon
                      sx={{
                        width: '14px',
                        height: '14px',
                        marginRight: '5px',
                      }}
                    />
                  ) : (
                    <EditIcon
                      sx={{
                        width: '14px',
                        height: '14px',
                        marginRight: '5px',
                      }}
                    />
                  )}
                  {`${editMode ? text.buttons.cancelEdit : text.buttons.edit}`}
                </Button>
                {editMode ? (
                  <Button
                    type="button"
                    buttonType="primary"
                    size="small"
                    startIcon={<SaveIcon />}
                    onClick={openConfirmationModal}
                    sx={{
                      marginLeft: '1em',
                    }}
                  >
                    <material.Typography
                      variant="body2"
                      color={colors.commonWhite}
                    >
                      {text.buttons.saveChanges}
                    </material.Typography>
                  </Button>
                ) : null}
              </Box>
            ) : null}
          </NavContainer>
          <ContentContainer>
            {initialItems.map((item, index) => {
              const selectedStep: Step | undefined = editTemplate?.steps[index]
              return (
                <TabPanel value={tab} index={index} key={selectedStep?.id}>
                  {selectedStep ? (
                    <>
                      <Box display="flex" alignItems="center">
                        <material.Typography
                          variant="body1"
                          sx={{
                            marginRight: '2px',
                            paddingBottom: '1px',
                          }}
                        >
                          {item.label} -{' '}
                        </material.Typography>
                        <TextArea
                          editMode={editMode}
                          rows={1}
                          variant="STEP"
                          initialValue={selectedStep.label}
                          updateText={handleStepLabelChange}
                          saveStateCalled={saveStateCalled}
                          additionalTextFieldStyles={{
                            padding: '0px 0px 0px 4px',
                          }}
                        />
                      </Box>

                      <CheckpointList>
                        {selectedStep.checkpoints.map(
                          (check: Checkpoint, checkIndex) => {
                            return (
                              <>
                                <CheckpointItem key={check.id}>
                                  <Box display="flex" alignItems="center">
                                    <material.Typography
                                      variant="body2"
                                      sx={{
                                        fontWeight: FONT_WEIGHT.BOLD,
                                        color: colors.black,
                                        marginRight: '2px',
                                        paddingBottom: '1px',
                                      }}
                                    >
                                      {`${text.general.checkpoint}${
                                        checkIndex + 1
                                      }:`}
                                    </material.Typography>
                                    <TextArea
                                      editMode={editMode}
                                      rows={1}
                                      variant="NORMAL_BOLD"
                                      initialValue={check.label}
                                      updateText={(newText) =>
                                        handleCheckpointLabelChange(
                                          newText,
                                          checkIndex
                                        )
                                      }
                                      saveStateCalled={saveStateCalled}
                                      additionalTextFieldStyles={{
                                        padding: '0px 0px 0px 4px',
                                      }}
                                    />

                                    {selectedStep.checkpoints.length > 1 &&
                                    editMode ? (
                                      <>
                                        <Divider
                                          sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            borderColor: colors.gray,
                                            width: '30px',
                                            flex: '0.90',
                                            marginLeft: '10px',
                                          }}
                                        />
                                        <StyledButton
                                          sx={{
                                            height: '20px !important',
                                            fontSize: '12px !important',
                                          }}
                                          variant="text"
                                          onClick={() =>
                                            handleDeleteCheckpoint(checkIndex)
                                          }
                                        >
                                          {text.general.deleteCheckpoint}
                                        </StyledButton>
                                      </>
                                    ) : null}
                                  </Box>

                                  <Box
                                    width="100%"
                                    marginBottom="1em"
                                    marginTop="1em"
                                  >
                                    <StyledTextFieldContainer
                                      title={
                                        editMode
                                          ? text.general.subtitleReason
                                          : `${text.general.subtitleReason}: ${check.failureReason.name}`
                                      }
                                      titleStyle={{
                                        '& > p': {
                                          fontSize: '14px',
                                        },
                                      }}
                                      sx={{ width: '96%' }}
                                    >
                                      {editMode ? (
                                        <>
                                          <Select
                                            options={reasonOptions}
                                            variant="standard"
                                            value={check.failureReason.id}
                                            onChange={(
                                              e: SelectChangeEvent<unknown>
                                            ) => {
                                              handleEditCheckpointFailureReason(
                                                checkIndex,
                                                {
                                                  id: e.target.value as string,
                                                  name:
                                                    reasonOptions.find(
                                                      (option) =>
                                                        option.value ===
                                                        (e.target
                                                          .value as string)
                                                    )?.name || '',
                                                }
                                              )
                                            }}
                                            sx={{
                                              fontSize: 12,
                                              height: 40,
                                              width: '350px',
                                            }}
                                          />

                                          {saveStateCalled &&
                                          check.failureReason.id === '' ? (
                                            <StyledErrorMessage
                                              text={
                                                validationText.fieldRequired
                                              }
                                            />
                                          ) : null}
                                        </>
                                      ) : null}
                                    </StyledTextFieldContainer>
                                  </Box>

                                  <TextArea
                                    editMode={editMode}
                                    variant="NORMAL"
                                    initialValue={check.description}
                                    updateText={(newText) =>
                                      handleCheckpointDescriptionChange(
                                        newText,
                                        checkIndex
                                      )
                                    }
                                    saveStateCalled={saveStateCalled}
                                    additionalTextFieldStyles={{
                                      padding: '0px 0px 0px 6px',
                                    }}
                                  />

                                  {/* Workaround made in order to make the width of the labels
                            in the Checkpoints. If this weren't made, then
                            their length would be just about 100px */}
                                  <Box
                                    sx={{
                                      [`& > div > div:nth-of-type(2) > div:nth-of-type(1) > div:nth-of-type(1) > div > div`]:
                                        {
                                          width: '100%',
                                        },
                                    }}
                                  >
                                    <CheckpointContent
                                      editMode={editMode}
                                      shouldUpdate={shouldUpdate}
                                      questions={check.questions}
                                      checkpointIndex={checkIndex}
                                      failureReason={check.failureReason}
                                      status={templateStatus}
                                      saveStateCalled={saveStateCalled}
                                      setAnswerMissingError={
                                        setAnswerMissingError
                                      }
                                      setCheckpointFailureReasonError={
                                        setCheckpointFailureReasonError
                                      }
                                      changeEditMode={(newEditMode) =>
                                        setEditMode(newEditMode)
                                      }
                                      handleQuestionsChange={(
                                        questionIndex: number,
                                        updatedQuestion?: Question
                                      ) => {
                                        handleQuestionChange(
                                          checkIndex,
                                          questionIndex,
                                          updatedQuestion
                                        )
                                      }}
                                      handleDeleteQuestion={(
                                        questionIndex: number
                                      ) => {
                                        handleDeleteQuestion(
                                          checkIndex,
                                          questionIndex
                                        )
                                      }}
                                      handleAddQuestion={() => {
                                        handleAddQuestion(checkIndex)
                                      }}
                                    />
                                  </Box>
                                </CheckpointItem>
                              </>
                            )
                          }
                        )}
                      </CheckpointList>
                      {templateStatus === TemplateStatus.DRAFT && editMode ? (
                        <StyledButton
                          sx={{
                            height: '20px !important',
                            fontSize: '12px !important',
                            marginLeft: '3em',
                          }}
                          variant="text"
                          onClick={handleAddCheckpoint}
                        >
                          <AddIcon />
                          {text.general.addCheckpoint}
                        </StyledButton>
                      ) : null}
                    </>
                  ) : null}
                </TabPanel>
              )
            })}
          </ContentContainer>
          <ConfirmationModal
            headerIcon={<ErrorIcon />}
            open={confirmationModal}
            text={confirmationModalText}
            onConfirmHandler={handleSaveState}
            onCancelHandler={closeConfirmationModal}
          />
          <RouteLeavingGuard
            when={editMode}
            navigate={(path) => history.push(path)}
            headerIcon={<ErrorIcon />}
            onConfirmHandler={handleLeaveConfirm}
            text={routeLeavingModalText}
          />
        </>
      )}
    </Layout>
  )
}

export default TemplateDetailPage
