import { inputBaseClasses, styled, textFieldClasses } from '@mui/material'

import { colors } from 'styles/theme'

export const StyledForm = styled('form')({
  alignItems: 'center',
  backgroundColor: colors.commonWhite,
  borderRadius: '0px',
  display: 'flex',
  flexDirection: 'column',
  padding: '2rem 3rem',
  width: 'unset',
  height: '219px',
})

export const StyledTextfieldContainer = styled('div')({
  width: '100%',
  position: 'relative',
  [`.${textFieldClasses.root}`]: {
    height: '33px',
    borderRadius: '4px',
    [`.${inputBaseClasses.root}`]: {
      height: '33px',
      fontSize: '14px',
    },
  },
})
