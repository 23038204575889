import React from 'react'

import { SummaryBox, SummaryLabel, SummaryNumber } from './style'
import { SummaryStatisticsProps } from '.'

const SummaryInspections = ({
  registered,
  registeredLabel,
  active,
  activeLabel,
  inactive,
  inactiveLabel,
}: SummaryStatisticsProps) => (
  <div style={{ marginTop: '-1rem' }}>
    <SummaryBox display="flex">
      <SummaryNumber variant="h3">{registered}</SummaryNumber>
      <SummaryLabel variant="body2">{registeredLabel}</SummaryLabel>
    </SummaryBox>
    <SummaryBox display="flex">
      <SummaryNumber variant="h3">{active}</SummaryNumber>
      <SummaryLabel variant="body2">{activeLabel}</SummaryLabel>
    </SummaryBox>
    <SummaryBox>
      <SummaryNumber variant="h3">{inactive}</SummaryNumber>
      <SummaryLabel variant="body2">{inactiveLabel}</SummaryLabel>
    </SummaryBox>
  </div>
)

export default SummaryInspections
