import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import { SelectChangeEvent } from '@mui/material'
import {
  GridCellValue,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowsProp,
  GridSortModel,
} from '@mui/x-data-grid'

import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import Table from 'components/Common/Table'
import Tooltip from 'components/Common/Table/Tooltip'
import { TabObjects } from 'components/Common/Tabs'
import Title from 'components/Common/Title'
import { Filter } from 'components/Inspection/Dashboard/FilterCard'
import FieldFilter from 'components/Inspection/Listing/FieldFilter'
import FilterByCriteria from 'components/Listing/FilterByCriteria'

import { cypressAddButton } from 'constants/cypress'
import { routes } from 'constants/routes'
import {
  commonListColumns,
  defaultSortModel,
  selectItems,
} from 'constants/table'
import { draftHash, TemplateColumnField } from 'constants/template'
import { textFiles } from 'constants/textFiles'
import { UrlParamNames } from 'constants/urlQuery'
import useLocale from 'hooks/useLocale'
import useNotification from 'hooks/useNotification'
import useQueryState from 'hooks/useQueryState'
import useTranslation from 'hooks/useTranslation'
import {
  FilterInputVariable,
  GenericData,
  ListingFilterType,
} from 'models/services/base'
import { Provider } from 'models/services/provider'
import { ListTemplate, Template } from 'models/services/template/listing'
import { TemplateStatusMap } from 'models/status'
import { cleanFilters, generateFilterInput } from 'utils/filters'
import { buildDetailRoute } from 'utils/routes'
import {
  serializeFields,
  serializeFilters,
  serializePage,
  serializePageSize,
  serializeSortModel,
} from 'utils/serializers'
import {
  createFieldSelectItems,
  createFieldSelectLabels,
  defaultFields,
} from 'utils/Template/template'
import { verifyParam } from 'utils/verifyUrlData'

import {
  LIST_PROVIDERS,
  LIST_TEMPLATES,
} from 'graphQL/Template/Listing/queries'

const TemplateListingPage = () => {
  const { show } = useNotification()
  const location = useLocation()
  const { search } = location

  const defaultSortedFields = [...defaultFields].sort()

  const [tabValue, setTabValue] = useState<string | number>(
    location && location.hash === draftHash ? 1 : 0
  )

  const [selectedFields, setSelectedFields] = useQueryState<string[]>(
    UrlParamNames.FIELDS,
    (verifyParam(UrlParamNames.FIELDS, search) as string[]) ||
      defaultSortedFields,
    serializeFields,
    defaultSortedFields
  )

  const [filtersList, setFiltersList] = useQueryState<Filter[]>(
    UrlParamNames.FILTERS,
    (verifyParam(UrlParamNames.FILTERS, search) as Filter[]) || [],
    serializeFilters
  )

  const [pageSize, setPageSize] = useQueryState<number>(
    UrlParamNames.LIMIT,
    (verifyParam(UrlParamNames.LIMIT, search) as number) || 10,
    serializePageSize,
    10
  )
  const [page, setPage] = useQueryState<number>(
    UrlParamNames.PAGE,
    (verifyParam(UrlParamNames.PAGE, search) as number) || 1,
    serializePage
  )

  const [sortModel, setSortModel] = useQueryState<GridSortModel>(
    UrlParamNames.SORT,
    (verifyParam(UrlParamNames.SORT, search) as GridSortModel) ||
      defaultSortModel,
    serializeSortModel,
    defaultSortModel
  )
  const [filterInput, setFiltersInput] = useState<ListingFilterType>(
    generateFilterInput(filtersList)
  )

  const [templatesQuantity, setTemplatesQuantity] = useState<number>(0)
  const [templatesData, setTemplatesData] = useState<GridRowsProp>([])
  const [providers, setProviders] = useState<Provider[]>([])
  const [deleteItem, setDeleteItem] = useState<GridRowId | null>(null)
  const { text } = useTranslation(textFiles.TEMPLATE_LISTING)
  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const handleShowError = () => {
    show({
      updatedSeverity: 'error',
      message: generalText.notificationText.error,
    })
  }
  const formatTemplates = (templates: Template[]) => {
    return templates.map((template) => {
      return {
        id: template.id,
        templateName: template.name === null ? 'N/A' : template.name,
        version: template.version as number,
        provider: template.provider === null ? 'N/A' : template.provider.name,
        stepsNumber: template.steps.length,
        createdAt: template.createdAt,
        status: template.status.toLowerCase(),
        visibility: template.visible,
      }
    })
  }

  const tabs: TabObjects[] = [
    {
      id: 1,
      item: {
        label: text.publishedTab,
        value: 0,
        badge: 0,
      },
    },
    {
      id: 2,
      item: {
        label: text.draftTab,
        value: 1,
        badge: 0,
      },
    },
  ]

  const { loading: templateLoading } = useQuery<
    ListTemplate,
    FilterInputVariable
  >(LIST_TEMPLATES, {
    variables: {
      input: {
        limit: pageSize,
        start: (page - 1) * pageSize,
        where: {
          status: tabValue === 0 ? 'published' : 'draft',
          ...cleanFilters(filterInput),
        },
        sort:
          sortModel.length > 0 && sortModel[0].sort
            ? {
                [sortModel[0].field]: sortModel[0].sort,
              }
            : undefined,
      },
    },
    onCompleted(response) {
      setTemplatesQuantity(response.listTemplates.count)
      setTemplatesData(formatTemplates(response.listTemplates.data))
    },
    onError() {
      handleShowError()
    },
  })

  const { loading: providersLoading } = useQuery<GenericData<Provider[]>>(
    LIST_PROVIDERS,
    {
      variables: {
        input: {
          sort: {
            slug: 'asc',
          },
        },
      },
      onCompleted(response) {
        setProviders(response.data)
      },
    }
  )

  const fieldSelectItems = createFieldSelectItems(text.fieldSelect)

  const fieldSelectLabels = createFieldSelectLabels(text.fieldSelect)

  const [selectedLanguage] = useLocale()

  const history = useHistory()

  const editTemplate = React.useCallback(
    (id: GridCellValue) => {
      history.push(buildDetailRoute(id, routes.TEMPLATE_DETAIL))
    },
    [history]
  )

  const createTemplate = () => {
    history.push(routes.TEMPLATE_CREATION)
  }

  const {
    id: idColumn,
    isoDate: dateColumn,
    status: statusColumn,
    standard: standardColumn,
    provider: providerColumn,
    actions: actionsColumn,
  } = commonListColumns(TemplateStatusMap, selectedLanguage, text, editTemplate)

  const [columns, setColumns] = useState<GridColDef[]>([
    { ...idColumn, hide: !selectedFields.includes(idColumn.field) },
    {
      ...standardColumn,
      field: TemplateColumnField.TEMPLATE_NAME,
      headerName: text.table.templateName,
      flex: 2,
      sortable: true,
      hide: !selectedFields.includes(TemplateColumnField.TEMPLATE_NAME),
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip>{params.row.templateName}</Tooltip>
      ),
    },
    {
      ...standardColumn,
      field: TemplateColumnField.VERSION,
      headerName: text.table.version,
      sortable: true,
      hide: !selectedFields.includes(TemplateColumnField.VERSION),
    },
    {
      ...providerColumn,
      hide: !selectedFields.includes(TemplateColumnField.VERSION),
    },
    {
      ...standardColumn,
      field: TemplateColumnField.STEPS,
      headerName: text.table.stepsNumber,
      sortable: false,
      hide: !selectedFields.includes(TemplateColumnField.STEPS),
    },
    {
      ...dateColumn,
      field: TemplateColumnField.CREATED_AT,
      headerName: text.table.createdAt,
      hide: !selectedFields.includes(TemplateColumnField.CREATED_AT),
    },
    { ...statusColumn, hide: !selectedFields.includes(statusColumn.field) },
    {
      ...actionsColumn,
      width: 100,
      hide: !selectedFields.includes(actionsColumn.field),
    },
  ])

  const handleFiltersList = (
    newFiltersList: Filter[],
    newFilterInput: ListingFilterType
  ) => {
    setFiltersList(newFiltersList)
    setFiltersInput(newFilterInput)
    setPage(1)
  }

  // const handleSearchChange = (
  //   event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  // ) => {
  //   setSearchValue(event.target.value)
  // }

  const handleTabChange = (newValue: string | number) => {
    if (newValue === 1) history.replace(draftHash)
    setTabValue(newValue)
    setPage(1)
  }

  const handleSelectItem = (valueInput: string) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        return column.field === valueInput
          ? { ...column, hide: !column.hide }
          : column
      })
    )
    setSelectedFields((prevFields) => {
      if (prevFields.includes(valueInput)) {
        return prevFields.filter((field) => field !== valueInput)
      }
      return [...prevFields, valueInput]
    })
  }

  const handleResetDefault = () => {
    setSelectedFields(defaultFields)
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        const fieldExist = defaultFields.some(
          (defaultField) => column.field === defaultField
        )
        return { ...column, hide: !fieldExist }
      })
    )
  }

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model)
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangePageSize = (event: SelectChangeEvent<number>) => {
    setPageSize(event.target.value as number)
  }

  const getPageCount = () => {
    return Math.ceil(templatesQuantity / pageSize)
  }

  if (providersLoading)
    return <LoadingAnimation showAnimation={providersLoading} />

  return (
    <Box width="100%">
      <Title
        title={text.title}
        subtitle={`${templatesQuantity} ${text.description}`}
        icon={<PlaylistAddCheckIcon />}
        handleTabChange={handleTabChange}
        tabs={tabs}
        tabValue={tabValue}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        marginTop="2rem"
        width="100%"
      >
        <Button
          startIcon={<AddIcon />}
          size="medium"
          onClick={createTemplate}
          data-cy={cypressAddButton}
        >
          {text.newTemplateButton}
        </Button>
        <Box display="flex">
          {/* <Box marginRight="1rem" width="370px">
            <StyledTextField
              icon={<SearchIcon fontSize="small" />}
              placeholder={text.searchPlaceholder}
              fullWidth
              value={searchValue}
              onChange={handleSearchChange}
            />
          </Box> */}
          <Box marginRight="1rem">
            <FilterByCriteria
              filtersList={filtersList}
              handleFiltersList={handleFiltersList}
              filterTypes={{
                version: 'number',
              }}
              file={textFiles.TEMPLATE_LISTING}
              filterInput={filterInput}
              providerList={providers}
            />
          </Box>
          <Box marginRight="1rem">
            <FieldFilter
              handleSelectItem={handleSelectItem}
              items={fieldSelectItems}
              selectedValues={selectedFields}
              handleResetDefault={handleResetDefault}
            />
          </Box>
        </Box>
      </Box>
      <Table
        // searchValue={searchValue}
        columns={columns}
        data={templatesData}
        currentPage={page}
        onPageChange={handleChangePage}
        onSelectChange={handleChangePageSize}
        pageSize={pageSize}
        selectItems={selectItems}
        pageCount={getPageCount()}
        loading={templateLoading}
        route={routes.TEMPLATE_DETAIL}
        filtersList={filtersList}
        deletedItemId={deleteItem}
        setDeleteItemId={setDeleteItem}
        fields={selectedFields}
        fieldLabels={fieldSelectLabels}
        file={textFiles.TEMPLATE_LISTING}
        filtersFile={textFiles.TEMPLATE_LISTING}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
        checkboxSelection={false}
        hideDownloadModal
      />
    </Box>
  )
}

export default TemplateListingPage
