import { CarTrimPopoverProps } from 'components/Inventory/Common/CarTrimPopover'

import { InventoryColumnField } from 'constants/inventory'
import { SelectItem } from 'models/Select'
import { BaseIdEntity, ListingFilterType } from 'models/services/base'
import { Car } from 'models/services/inventory/listing'
import { FieldSelectTextType } from 'models/text/InventoryListing'

export const createFieldSelectItems = (
  text: FieldSelectTextType
): SelectItem[] => {
  return [
    {
      label: text.id,
      value: InventoryColumnField.ID,
    },
    {
      label: text.slug,
      value: InventoryColumnField.SLUG,
    },
    {
      label: text.picture,
      value: InventoryColumnField.PICTURE,
    },
    {
      label: text.vehicle,
      value: InventoryColumnField.VEHICLE,
    },
    {
      label: text.trim,
      value: InventoryColumnField.TRIM,
    },
    {
      label: text.price,
      value: InventoryColumnField.PRICE,
    },
    {
      label: text.mileage,
      value: InventoryColumnField.MILEAGE,
    },
    {
      label: text.createdAt,
      value: InventoryColumnField.CREATED_AT,
    },
    {
      label: text.certified,
      value: InventoryColumnField.CERTIFIED,
    },
    {
      label: text.status,
      value: InventoryColumnField.STATUS,
    },
    {
      label: text.actions,
      value: InventoryColumnField.ACTIONS,
    },
  ]
}

export const createFieldSelectLabels = (
  text: FieldSelectTextType
): Record<string, string> => {
  return {
    id: text.id,
    slug: text.slug,
    picture: text.picture,
    vehicle: text.vehicle,
    trim: text.trim,
    price: text.price,
    mileage: text.mileage,
    certified: text.certified,
    status: text.status,
    actions: text.actions,
  }
}

export const defaultFields = [
  InventoryColumnField.PICTURE,
  InventoryColumnField.VEHICLE,
  InventoryColumnField.TRIM,
  InventoryColumnField.PRICE,
  InventoryColumnField.MILEAGE,
  InventoryColumnField.CERTIFIED,
  InventoryColumnField.STATUS,
  InventoryColumnField.ACTIONS,
]

export const addCommas = (number: number) => number.toLocaleString('en-US')

export const formatFiltersWithCertification = (data: ListingFilterType) => {
  // Here we change certified to true or false
  // This is because the backend takes a boolean value for input

  const mapCertification = (array: (string | number | boolean)[]) =>
    array
      ? array.map((value) => {
          if (value === 'certified') {
            return true
          }
          return false
        })
      : []

  return {
    ...data,
    certified_in: data.certified_in ? mapCertification(data.certified_in) : [],
    certified_nin: data.certified_nin
      ? mapCertification(data.certified_nin)
      : [],
  }
}

export const formatInventories = (cars: Car[]) =>
  cars.map((car) => ({
    id: car.id,
    slug: car.slug,
    createdAt: car.createdAt,
    picture: car.mainPicture,
    vehicle: `${car.brand.name} ${car.carModel.name} ${car.year}`,
    trim: car.trimLevel.slug,
    price: car.listingPrice,
    mileage: car.mileage,
    certified: car.certified,
    status: car.status.slug.toLowerCase(),
    year: car.year,
    carModel: car.carModel.id,
    brand: car.brand.id,
    countryVersion: car.countryVersion.id,
    bodyStyle: car.bodyStyle.id,
    categories: [...car.categories],
    transmission: car.transmission.id,
    fuelType: car.fuelType.id,
    driveTrain: car.driveTrain.id,
    features: [...car.features],
    exteriorColor: car.exteriorColor.id,
    interiorColor: car.interiorColor.id,
    dealer: car.dealer ? car.dealer.id : '',
    curboSpot: car.curboSpot.id,
    provider: String(car.provider).toLowerCase(),
    trimBox: {
      bodyStyle: car.bodyStyle.name,
      category: car.categories.length > 0 ? car.categories[0].name : '',
      fuelType: car.fuelType.name,
      exteriorColor: car.exteriorColor.hexCode,
      interiorColor: car.interiorColor.hexCode,
      countryVersion: car.countryVersion.name,
      curboSpot: car.curboSpot.name,
    },
  }))

export type CarTrimModel = CarTrimPopoverProps & BaseIdEntity
