import React from 'react'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import HandymanIcon from '@mui/icons-material/Handyman'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import PaletteIcon from '@mui/icons-material/Palette'
import { Typography } from '@mui/material'

import Avatar from 'components/Common/Avatar'
import Tag from 'components/Common/Tag'

import { RelatedCar } from 'models/services/inventory/detail'

import { colors, FONT_WEIGHT } from 'styles/theme'

import {
  CardContainer,
  CarTitle,
  ItemContainer,
  ItemText,
  StyledLink,
} from './style'

type RelatedVehicleCardProps = {
  car: RelatedCar
  priceCurrency: string
  linkText: string
}

const RelatedVehicleCard = ({
  car,
  priceCurrency,
  linkText,
}: RelatedVehicleCardProps) => {
  const {
    bodyStyle,
    brand,
    carModel,
    exteriorColor,
    fuelType,
    id,
    listingPrice,
    mainPicture,
    status,
    transmission,
    year,
  } = car

  return (
    <CardContainer>
      <Tag
        backgroundColor={status.backgroundColor}
        color={status.textColor}
        text={status.name}
        textStyle={{
          width: 'auto',
          height: 20,
          padding: '0 0.5rem',
        }}
        boxStyle={{
          transform: 'translatey(-60%)',
          width: 'max-content',
        }}
      />
      <Avatar
        image={mainPicture}
        height={93}
        width={228}
        style={{ alignSelf: 'center' }}
        variant="rounded"
      />
      <CarTitle variant="body2">{`${brand.name} ${carModel.name} ${year}`}</CarTitle>

      <Typography
        color={colors.blue}
        fontWeight={FONT_WEIGHT.BOLD}
        variant="body2"
      >{`${priceCurrency} ${listingPrice.toLocaleString('en-US')}`}</Typography>

      <ItemContainer>
        <DirectionsCarIcon />
        <ItemText>{bodyStyle.name}</ItemText>
      </ItemContainer>

      <ItemContainer>
        <LocalGasStationIcon />
        <ItemText>{fuelType.name}</ItemText>
      </ItemContainer>

      <ItemContainer>
        <HandymanIcon />
        <ItemText>{transmission.name}</ItemText>
      </ItemContainer>

      <ItemContainer justifyContent="space-between">
        <ItemContainer marginTop="0px!important">
          <PaletteIcon />
          <ItemText>{exteriorColor.name}</ItemText>
        </ItemContainer>
        <StyledLink to={`/inventory/detail/${id}#vehicle-summary`}>
          {linkText}
        </StyledLink>
      </ItemContainer>
    </CardContainer>
  )
}

export default RelatedVehicleCard
