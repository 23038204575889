import { formControlClasses, styled } from '@mui/material'
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox'

import Container from 'components/Common/Container'
import ErrorMessage from 'components/Common/ErrorMessage'

import { colors } from 'styles/theme'

export const StyledCheckbox = styled(Checkbox)({
  [checkboxClasses.disabled]: {
    color: `${colors.blue} !important`,
  },
})

export const StyledTextFieldContainer = styled(Container)({
  padding: 0,
  borderRadius: 0,
  '> p': {
    fontSize: '12px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
})

export const StyledErrorMessage = styled(ErrorMessage)({
  alignSelf: 'flex-start',
  position: 'static',
  marginTop: '0.5rem',
})
