import React from 'react'
import { Typography } from '@mui/material'

import Avatar from 'components/Common/Avatar'

import useLocale from 'hooks/useLocale'
import { formatDateLocalizedTime } from 'utils/date'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { StyledBox, StyledHour, StyledText } from './style'

type ActivityProps = {
  img?: string
  name: string
  description: string
  hour: Date
}

const Activity = ({ img, name, description, hour }: ActivityProps) => {
  const [selectedLanguage] = useLocale()

  return (
    <StyledBox>
      <Avatar image={img} height="48px" width="48px" />
      <StyledText>
        <Typography
          variant="subtitle1"
          style={{ color: colors.black, fontWeight: FONT_WEIGHT.SEMI_BOLD }}
        >
          {name}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{
            color: colors.gray,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {description}
        </Typography>
      </StyledText>
      <StyledHour>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: FONT_WEIGHT.SEMI_BOLD, paddingBottom: '.5rem' }}
        >
          {formatDateLocalizedTime(hour, 'p', selectedLanguage.code)}
        </Typography>
      </StyledHour>
    </StyledBox>
  )
}

export default Activity
