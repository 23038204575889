import React, { FunctionComponent } from 'react'
import { InputBaseComponentProps } from '@mui/material'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'

import ButtonContainer from 'components/CarSettings/Common/Creation/ButtonContainer'
import ErrorMessage from 'components/Common/ErrorMessage'
import { NumberFormatCustom } from 'components/General/PhoneNumberFormat'

import { placeholderPhone } from 'constants/dealer'
import { textFiles } from 'constants/textFiles'
import { USER_CREATION_OPTION } from 'constants/UserManagement/creation'
import useTranslation from 'hooks/useTranslation'
import { UserInformation } from 'models/userCreation'
import { UserCreationProps } from 'utils/User/creation'

import {
  FormContent,
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from './style'

const UserInfo = ({
  userData,
  handleContinue,
  updateUserData,
}: UserCreationProps) => {
  const { text } = useTranslation(textFiles.USER_MANAGEMENT_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const {
    userInformation: { name, lastName, email, phoneNumber, password, address },
  } = userData

  const {
    information: {
      userName,
      userPhone,
      lastName: lastNameLabel,
      email: emailLabel,
      address: addressLabel,
      password: passwordLabel,
    },
  } = text
  const phoneValidation = validationText.errorMessagePhoneNumberDigits.replace(
    '%d',
    '10'
  )

  const validationSchema = yup.object({
    name: yup.string().required(validationText.fieldRequired),
    lastName: yup.string().required(validationText.fieldRequired),
    email: yup
      .string()
      .email(validationText.invalidEmail)
      .required(validationText.fieldRequired),
    address: yup.string(),
    password: yup.string().required(validationText.fieldRequired),
    phoneNumber: yup.string().length(10, phoneValidation),
  })

  const formik = useFormik<UserInformation>({
    initialValues: {
      name,
      lastName,
      email,
      address,
      password,
      phoneNumber,
    },
    validationSchema,
    onSubmit: (values) => {
      updateUserData({
        type: USER_CREATION_OPTION.UPDATE_USER_INFORMATION,
        payload: {
          ...values,
        },
      })
      handleContinue()
    },
  })

  return (
    <FormikProvider value={formik}>
      <StyledContainer>
        <StyledForm onSubmit={formik.handleSubmit}>
          <FormContent>
            <section>
              <StyledTextFieldContainer title={`${userName}*`}>
                <StyledTextField
                  variant="outlined"
                  name="name"
                  autoComplete="off"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  placeholder={userName}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  inputProps={{ maxLength: 40 }}
                />
                {formik.touched.name && formik.errors.name ? (
                  <ErrorMessage
                    sx={{
                      alignSelf: 'flex-start',
                      position: 'static',
                      marginTop: '0.5rem',
                    }}
                    text={formik.errors.name}
                  />
                ) : null}
              </StyledTextFieldContainer>
              <StyledTextFieldContainer title={`${lastNameLabel}*`}>
                <StyledTextField
                  variant="outlined"
                  name="lastName"
                  autoComplete="off"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  placeholder={lastNameLabel}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  inputProps={{ maxLength: 40 }}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <ErrorMessage
                    sx={{
                      alignSelf: 'flex-start',
                      position: 'static',
                      marginTop: '0.5rem',
                    }}
                    text={formik.errors.lastName}
                  />
                ) : null}
              </StyledTextFieldContainer>
              <StyledTextFieldContainer title={`${emailLabel}*`}>
                <StyledTextField
                  variant="outlined"
                  name="email"
                  type="text"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  placeholder="john@doe.com"
                  inputProps={{
                    maxLength: 40,
                    autocomplete: 'off',
                  }}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                />
                {formik.touched.email && formik.errors.email ? (
                  <ErrorMessage
                    sx={{
                      alignSelf: 'flex-start',
                      position: 'static',
                      marginTop: '0.5rem',
                    }}
                    text={formik.errors.email}
                  />
                ) : null}
              </StyledTextFieldContainer>
              <StyledTextFieldContainer title={`${passwordLabel}*`}>
                <StyledTextField
                  id="new-password"
                  name="password"
                  variant="outlined"
                  autoComplete="new-password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  placeholder={passwordLabel}
                  inputProps={{
                    maxLength: 40,
                    autocomplete: 'new-password',
                  }}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                />

                {formik.touched.password && formik.errors.password ? (
                  <ErrorMessage
                    sx={{
                      alignSelf: 'flex-start',
                      position: 'static',
                      marginTop: '0.5rem',
                    }}
                    text={formik.errors.password}
                  />
                ) : null}
              </StyledTextFieldContainer>
              <StyledTextFieldContainer title={userPhone}>
                <StyledTextField
                  variant="outlined"
                  name="phoneNumber"
                  autoComplete="off"
                  placeholder={placeholderPhone}
                  value={formik.values.phoneNumber}
                  InputProps={{
                    inputComponent:
                      NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
                  }}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  onChange={formik.handleChange}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <ErrorMessage
                    sx={{
                      alignSelf: 'flex-start',
                      position: 'static',
                      marginTop: '0.5rem',
                    }}
                    text={formik.errors.phoneNumber}
                  />
                ) : null}
              </StyledTextFieldContainer>
              <StyledTextFieldContainer title={addressLabel}>
                <StyledTextField
                  variant="outlined"
                  name="address"
                  autoComplete="off"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  placeholder={addressLabel}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  inputProps={{ maxLength: 40 }}
                />
                {formik.touched.address && formik.errors.address ? (
                  <ErrorMessage
                    sx={{
                      alignSelf: 'flex-start',
                      position: 'static',
                      marginTop: '0.5rem',
                    }}
                    text={formik.errors.address}
                  />
                ) : null}
              </StyledTextFieldContainer>
            </section>
          </FormContent>
          <ButtonContainer isFirstStep confirmButtonType="submit" />
        </StyledForm>
      </StyledContainer>
    </FormikProvider>
  )
}

export default UserInfo
