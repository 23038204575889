import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { routes } from 'constants/routes'
import useUser from 'hooks/useUser'

type ProtectedRouteProps = {
  children: React.ReactNode
} & RouteProps

const ProtectedRoute = ({ children, ...rest }: ProtectedRouteProps) => {
  const { user } = useUser()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user !== undefined ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.LOGIN,
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default ProtectedRoute
