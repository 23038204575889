import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { InputAdornment } from '@mui/material'
import Autocomplete, {
  AutocompleteChangeReason,
} from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  Suggestion,
} from 'use-places-autocomplete'

import { Address } from 'models/map'
import { getAddressFromLatLng } from 'utils/mapUtils'

import { StyledTextField } from './style'

interface PlaceType {
  id: string
  description: string
}

type AutoCompleteProps = {
  isLoaded: boolean
  address: Address | undefined
  setAddress: React.Dispatch<Address | undefined>
  translation: Record<string, string>
  disabled?: boolean
}

const AutoComplete = ({
  isLoaded,
  address,
  setAddress,
  translation,
  disabled,
}: AutoCompleteProps) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [reset, setReset] = useState<boolean>(false)
  const [options, setOptions] = useState<PlaceType[]>([])
  const {
    ready,
    init,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
  })

  useEffect(() => {
    if (isLoaded) {
      init()
    }
  }, [isLoaded, init])

  useEffect(() => {
    if (isLoaded) {
      const obtainOptions =
        status === 'OK'
          ? data.map((suggestion: Suggestion) => {
              const {
                description,
                structured_formatting: structuredFormatting,
                place_id: placeId,
              } = suggestion
              return {
                id: placeId,
                description,
                structured_formatting: structuredFormatting,
              }
            })
          : []
      setOptions(obtainOptions)
    }
  }, [status, data, isLoaded])

  useEffect(() => {
    if (address?.originFromSpot || address === undefined) {
      setReset(true)
      clearSuggestions()
      setInputValue('')
      setOptions([])
    }
  }, [address, clearSuggestions])

  useEffect(() => {
    if (address && address.address.length > 0) {
      setInputValue(address.address)
      setValue(address.address, false)
      setReset(true)
    } else if (address === undefined) {
      setInputValue('')
      setReset(true)
    }
  }, [address, setValue])

  return (
    <Autocomplete
      id="google-map-autocomplete"
      disabled={(!ready && !isLoaded) || disabled}
      sx={{ width: 'max(300px, 100%)' }}
      getOptionLabel={(option: PlaceType) => option.description}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) =>
        option.description === value.description
      }
      options={options}
      inputValue={inputValue}
      onFocus={() => {
        setReset(false)
      }}
      noOptionsText={translation.noOptionsText}
      autoComplete
      includeInputInList
      onChange={async (
        event: React.SyntheticEvent,
        newValue: PlaceType | null,
        reason: AutocompleteChangeReason
      ) => {
        if (reason === 'clear') {
          setAddress(undefined)
        } else if (newValue) {
          setValue(newValue.description, false)
          setInputValue(newValue.description)
          try {
            const results = await getGeocode({ address: newValue.description })
            const { lat, lng } = await getLatLng(results[0])
            const fetchAddress = await getAddressFromLatLng(lat, lng)
            setAddress({
              id: fetchAddress.place_id,
              lat: fetchAddress.geometry.location.lat,
              lng: fetchAddress.geometry.location.lng,
              address: fetchAddress.formatted_address,
              name: newValue.description,
              originFromSpot: false,
            })
          } catch (error) {
            // Temporary until is implemented error handling
            console.error('😱 Error: ', error)
          }
          clearSuggestions()
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (!reset) {
          setValue(newInputValue)
          setInputValue(newInputValue)
          setReset(false)
        }
      }}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          fullWidth
          placeholder={translation.searchPlaceholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="disabled" />
              </InputAdornment>
            ),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...params.inputProps,
            'data-cy': 'map-autocomplete',
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="body2" color="text.secondary">
                  {option.description}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}

export default AutoComplete
