/* eslint-disable no-debugger */
import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

import ForbiddenErrorPage from 'pages/ErrorPage/Forbidden'
import NotFoundErrorPage from 'pages/ErrorPage/NotFound'
import ResolutionErrorPage from 'pages/ErrorPage/ResolutionError'
import Home from 'pages/Home'
import Styles from 'pages/Styles'
import TestPage from 'pages/Test'

import RoleRoute from 'components/Auth/RoleRoute'
import Box from 'components/Common/Box'
import Toast from 'components/Common/Toast'
import Sidebar from 'components/General/Sidebar'

import {
  ACCOUNT_ROUTE,
  AUTH_ROUTE,
  CAR_SETTINGS_ROUTE,
  GENERAL_SETTINGS_ROUTE,
  INSPECTION_ROUTE,
  INSPECTOR_ROUTE,
  INVENTORY_ROUTE,
  MARKETING_ROUTE,
  OPERATION_ROUTE,
  routes,
  TEMPLATE_ROUTE,
} from 'constants/routes'
import { createSideBarItems } from 'constants/sidebar'
import { textFiles } from 'constants/textFiles'
import UserProvider from 'context/UserContext'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { UserRoles } from 'models/role'
import { filterSidebarItems } from 'utils/BasicUtil'

import { StyledLayout } from 'styles/protected'

import { AccountRouter } from './AccountRouter'
import { AuthRouter } from './AuthRouter'
import { CarSettingsRouter } from './CarSettingsRouter'
import { GeneralSettingsRouter } from './GeneralSettingsRouter'
import { InspectionRouter } from './InspectionRouter'
import { InspectorRouter } from './InspectorRouter'
import { InventoryRouter } from './InventoryRouter'
import { MarketingRouter } from './MarketingRouter'
import { OperationRouter } from './OperationRouter'
import ProtectedRoute from './ProtectedRoute'
import { TemplateRouter } from './TemplateRouter'

const ProtectedRoutes = () => {
  const { userRoles } = useUser()
  const { text } = useTranslation(textFiles.GENERAL)
  const { sidebar: translation } = text
  const sidebarElements = filterSidebarItems(
    createSideBarItems(translation),
    userRoles
  )

  return (
    <StyledLayout>
      <Sidebar items={sidebarElements} />
      <Box padding="130px 100px 50px 0px" width="100%">
        <Switch>
          <Route path={ACCOUNT_ROUTE}>
            <AccountRouter />
          </Route>
          <Route path={INVENTORY_ROUTE}>
            <InventoryRouter />
          </Route>
          <RoleRoute
            path={INSPECTION_ROUTE}
            allowedRoles={[
              UserRoles.ADMIN,
              UserRoles.INSPECTOR_SUPERVISOR,
              UserRoles.INSPECTOR_MANAGER,
            ]}
          >
            <InspectionRouter />
          </RoleRoute>
          <RoleRoute
            path={INSPECTOR_ROUTE}
            allowedRoles={[UserRoles.ADMIN, UserRoles.INSPECTOR_SUPERVISOR]}
          >
            <InspectorRouter />
          </RoleRoute>
          <RoleRoute
            path={TEMPLATE_ROUTE}
            allowedRoles={[UserRoles.ADMIN, UserRoles.INSPECTOR_SUPERVISOR]}
          >
            <TemplateRouter />
          </RoleRoute>

          <RoleRoute
            path={OPERATION_ROUTE}
            allowedRoles={[
              UserRoles.ADMIN,
              UserRoles.OPERATION_SPECIALIST,
              UserRoles.OPERATION_SUPERVISOR,
            ]}
          >
            <OperationRouter />
          </RoleRoute>

          <RoleRoute
            path={CAR_SETTINGS_ROUTE}
            allowedRoles={[
              UserRoles.ADMIN,
              UserRoles.OPERATION_SPECIALIST,
              UserRoles.OPERATION_SUPERVISOR,
            ]}
          >
            <CarSettingsRouter />
          </RoleRoute>

          <RoleRoute
            path={GENERAL_SETTINGS_ROUTE}
            allowedRoles={[
              UserRoles.ADMIN,
              UserRoles.INSPECTOR_SUPERVISOR,
              UserRoles.OPERATION_SUPERVISOR,
            ]}
          >
            <GeneralSettingsRouter />
          </RoleRoute>

          <RoleRoute
            path={INVENTORY_ROUTE}
            allowedRoles={[
              UserRoles.ADMIN,
              UserRoles.INVENTORY_SPECIALIST,
              UserRoles.INVENTORY_SUPERVISOR,
              UserRoles.INVENTORY_READER,
            ]}
          >
            <InventoryRouter />
          </RoleRoute>

          <RoleRoute
            path={MARKETING_ROUTE}
            allowedRoles={[UserRoles.ADMIN, UserRoles.MARKETING_SPECIALIST]}
          >
            <MarketingRouter />
          </RoleRoute>

          <Route exact path={routes.THEMING}>
            <Styles />
          </Route>
          <Route exact path={routes.HOME}>
            <Home />
          </Route>
          <Redirect to={routes.NOT_FOUND_ERROR} />
        </Switch>
      </Box>
    </StyledLayout>
  )
}

export const AppRouter = () => {
  const window = useWindowDimensions()
  const { close, notificationOpen, notificationData } = useNotification()
  const isSmallWindow =
    typeof window.width !== 'undefined' && window.width < 1200

  return (
    <Router>
      <UserProvider>
        <Toast
          showToast={notificationOpen}
          handleClose={close}
          variant="alert"
          severity={notificationData.severity}
          description={notificationData.message}
        />
        <Switch>
          <Route path={AUTH_ROUTE} component={AuthRouter} />
          <Route exact path={routes.RESOLUTION_ERROR}>
            <ResolutionErrorPage />
          </Route>
          <Route path="/test" component={TestPage} />

          {isSmallWindow ? (
            <Redirect push to={routes.RESOLUTION_ERROR} />
          ) : null}

          <Route exact path={routes.NOT_FOUND_ERROR}>
            <NotFoundErrorPage />
          </Route>

          <Route exact path={routes.UNAUTHORIZED_ERROR}>
            <ForbiddenErrorPage />
          </Route>

          <ProtectedRoute>
            <ProtectedRoutes />
          </ProtectedRoute>
        </Switch>
      </UserProvider>
    </Router>
  )
}
