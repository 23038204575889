import React from 'react'

import { presetColorsList } from 'constants/color'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import {
  ColorBox,
  ColorItem,
  StyledColorText,
  StyledContainer,
  StyledTitle,
} from './style'

type ColorBoardProps = {
  handleColorBoardChange: (color: string) => void
}

const ColorBoard = ({ handleColorBoardChange }: ColorBoardProps) => {
  const { text } = useTranslation(textFiles.COLOR_CREATION)

  const {
    information: { colorSelectorTitle, colorsList },
  } = text

  return (
    <StyledContainer>
      <StyledTitle variant="body2">{colorSelectorTitle}</StyledTitle>
      {presetColorsList.map((color) => (
        <ColorItem key={`id-${color.id}`}>
          <ColorBox
            sx={{ backgroundColor: color.code }}
            onClick={() => {
              handleColorBoardChange(color.code)
            }}
          />
          <StyledColorText variant="body2">
            {colorsList[color.name]}
          </StyledColorText>
        </ColorItem>
      ))}
    </StyledContainer>
  )
}

export default ColorBoard
