import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { SelectChangeEvent } from '@mui/material'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Box from 'components/Common/Box'
import ErrorMessage from 'components/Common/ErrorMessage'
import Select from 'components/Common/Select'
import ButtonContainer from 'components/Template/Creation/ButtonContainer'

import {
  cypressDecreaseCounterButton,
  cypressIncreaseCounterButton,
  cypressToggleButtonFalse,
  cypressToggleButtonTrue,
} from 'constants/cypress'
import {
  noProviderId,
  TEMPLATE_CREATION_OPTION,
} from 'constants/Template/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Option } from 'models/Select'
import { GenericData } from 'models/services/base'
import { IdentityModel } from 'models/template'
import { sleep } from 'utils/BasicUtil'
import { TemplateCreationProps } from 'utils/Template/creation'

import { GET_ALL_PROVIDERS } from 'graphQL/Template/Creation/queries'

import {
  ContentBox,
  CounterButton,
  CounterDiv,
  StyledContainer,
  StyledCounterField,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
  StyledToggleButton,
} from './style'

const Identity = ({
  handleContinue,
  templateData,
  updateTemplateData,
}: TemplateCreationProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [providerOptions, setProviderOptions] = useState<Option[]>([])
  const [template, setTemplate] = useState<IdentityModel>(templateData.identity)
  const { text: translation } = useTranslation(textFiles.TEMPLATE_CREATION)
  const { identity, buttons } = translation
  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: boolean
  ) => {
    if (newAlignment !== null) {
      setTemplate({
        ...template,
        visibility: newAlignment,
      })
    }
  }
  const handleCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTemplate({ ...template, stepsNumber: Number(event.target.value) })
  }
  const handleBlur = () => {
    if (!template.stepsNumber) {
      setTemplate({ ...template, stepsNumber: 1 })
    }
  }
  const handleSelect = (event: SelectChangeEvent<unknown>) => {
    setTemplate({
      ...template,
      providerName: event.target.value as string,
    })
  }

  useQuery<GenericData<Option[]>>(GET_ALL_PROVIDERS, {
    onCompleted(response) {
      setProviderOptions([
        { name: 'N/A', value: noProviderId },
        ...response.data,
      ])
    },
  })

  const validationSchema = yup.object({
    templateName: yup.string().required(validationText.fieldRequired),
  })

  const formik = useFormik<Partial<IdentityModel>>({
    initialValues: {
      templateName: template.templateName,
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      setTemplate({
        ...template,
        templateName: String(values.templateName),
      })
      updateTemplateData({
        type: TEMPLATE_CREATION_OPTION.UPDATE_IDENTITY,
        payload: {
          ...template,
          visibility: template.visibility,
          stepsNumber: template.stepsNumber,
          templateName: String(values.templateName),
          providerName: template.providerName,
        },
      })
      await sleep(1000)
      setLoading(false)
      handleContinue()
    },
  })
  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <ContentBox>
          <StyledTextFieldContainer
            title={identity.templateName}
            description={identity.nameDescription}
          >
            <StyledTextField
              variant="outlined"
              name="templateName"
              value={formik.values.templateName}
              onChange={formik.handleChange}
              placeholder={identity.namePlaceholder}
              error={
                formik.touched.templateName &&
                Boolean(formik.errors.templateName)
              }
            />
            {formik.touched.templateName && formik.errors.templateName ? (
              <ErrorMessage
                sx={{
                  alignSelf: 'flex-start',
                  position: 'static',
                  marginTop: '0.5rem',
                }}
                text={formik.errors.templateName}
              />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={identity.visibility}
            description={identity.visibilityDescription}
          >
            <ToggleButtonGroup
              value={template.visibility}
              exclusive
              onChange={handleAlignment}
            >
              <StyledToggleButton value data-cy={cypressToggleButtonTrue}>
                {buttons.on}
              </StyledToggleButton>
              <StyledToggleButton
                value={false}
                data-cy={cypressToggleButtonFalse}
              >
                {buttons.off}
              </StyledToggleButton>
            </ToggleButtonGroup>
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={identity.stepsNumber}
            description={identity.stepsDescription}
          >
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <StyledCounterField
                variant="outlined"
                value={template.stepsNumber}
                style={{ width: '87px' }}
                onChange={handleCountChange}
                onBlur={handleBlur}
                type="number"
                name="counter"
              />
              <CounterDiv>
                <CounterButton
                  onClick={() => {
                    setTemplate({
                      ...template,
                      stepsNumber: template.stepsNumber + 1,
                    })
                  }}
                  data-cy={cypressIncreaseCounterButton}
                >
                  <ArrowDropUpIcon />
                </CounterButton>
                <CounterButton
                  style={{ justifyContent: 'flex-end' }}
                  onClick={() => {
                    if (template.stepsNumber > 1)
                      setTemplate({
                        ...template,
                        stepsNumber: template.stepsNumber - 1,
                      })
                  }}
                  data-cy={cypressDecreaseCounterButton}
                >
                  <ArrowDropDownIcon />
                </CounterButton>
              </CounterDiv>
            </Box>
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={identity.provider}
            description={identity.providerDescription}
            width={641}
          >
            <Select
              options={providerOptions}
              variant="outlined"
              name="providerName"
              value={template.providerName}
              onChange={handleSelect}
              style={{ fontSize: 12, height: 40 }}
              testId="provider-select"
            />
          </StyledTextFieldContainer>
        </ContentBox>
        <ButtonContainer
          isFirstStep
          confirmButtonType="submit"
          loading={loading}
          additionalContainerStyles={{
            marginTop: 'auto',
          }}
        />
      </StyledForm>
    </StyledContainer>
  )
}
export default Identity
