import React, { useEffect, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import SaveIcon from '@mui/icons-material/Save'
import { SelectChangeEvent } from '@mui/material'
import { getDay, parseISO } from 'date-fns'

import { AutocompleteItem } from 'components/Common/Autocomplete'
import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import LocationSection from 'components/Common/Location'
import DatePicker from 'components/Common/Scheduling/DatePicker'
import InspectorPicker from 'components/Common/Scheduling/InspectorPicker'
import TimePicker from 'components/Common/Scheduling/TimePicker'
import { SchedulingError } from 'components/Inspection/Creation/Scheduling'

import { DAY_ENUM, emptyWeekCalendar, weekDay } from 'constants/date'
import { ENTITY_NOT_FOUND_ERROR } from 'constants/error'
import { meridiamOptions } from 'constants/inspection'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { CurboSpot } from 'models/curboSpot'
import { Address } from 'models/map'
import { GenericData, GenericInputVariable } from 'models/services/base'
import { InspectionWeekCalendar } from 'models/services/curboSpot'
import {
  InspectionAppointmentInformation,
  UpdateInspectionAppointmentType,
} from 'models/services/inspection/detail'
import { AvailableInspectorInput, Inspector } from 'models/services/inspector'
import { getDisabledDayNumbers } from 'utils/CalendarUtils'
import { getIsoDate } from 'utils/date'
import { validateGraphQLErrorCode } from 'utils/error'

import {
  GET_CURBO_SPOTS,
  GET_INSPECTION_WEEK_CALENDAR_BY_CURBO_SPOT_ID,
} from 'graphQL/Common/Dealer/queries'
import { GET_AVAILABLE_INSPECTORS } from 'graphQL/Inspection/Creation/queries'
import { GET_INSPECTION_APPOINTMENT_BY_ID } from 'graphQL/Inspection/Detail/queries'

import { StyledErrorMessage } from 'styles/creation'

const initialErrors: SchedulingError = {
  address: false,
  calendar: false,
  inspector: false,
  time: false,
}

type AppointmentProps = {
  id: string
  updateInspectionAppointment: (
    appointment: UpdateInspectionAppointmentType
  ) => Promise<boolean>
  submitLoading: boolean
  disabledUpdate: boolean
}

const Appointment = ({
  id,
  updateInspectionAppointment,
  submitLoading,
  disabledUpdate,
}: AppointmentProps) => {
  const [appointmentDate, setAppointmentDate] = useState<Date | null>(null)
  const [errors, setErrors] = useState<SchedulingError>(initialErrors)

  const [inspector, setInspector] = useState<Inspector | null>(null)
  const [time, setTime] = useState<string>('')
  const [meridiam, setMeridiam] = useState<string>(meridiamOptions[0].value)
  const [address, setAddress] = useState<Address | undefined>(undefined)
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false)
  const [curboSpots, setCurboSpots] = useState<CurboSpot[]>([])
  const [inspectors, setInspectors] = useState<Inspector[]>([])

  const [disabledDays, setDisabledDays] = useState<number[]>([])
  const [weekCalendarData, setWeekCalendarData] =
    useState<InspectionWeekCalendar | null>(null)
  const [dateKey, setDateKey] = useState<DAY_ENUM>(DAY_ENUM.MONDAY)

  const { text } = useTranslation(textFiles.INSPECTION_DETAIL)
  const { appointment: translation } = text
  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const [getInspectors, { loading: inspectorsLoading }] = useLazyQuery<
    GenericData<Inspector[]> | null,
    GenericInputVariable<AvailableInspectorInput>
  >(GET_AVAILABLE_INSPECTORS, {
    onCompleted(response) {
      if (response)
        setInspectors(
          response.data.map((listInspector) => {
            return {
              ...listInspector,
              name: `${listInspector.name} ${listInspector.lastName}`,
            }
          })
        )
      else setInspectors([])
    },
  })

  const { data: appointmentData, loading } = useQuery<
    GenericData<InspectionAppointmentInformation>,
    GenericInputVariable<string>
  >(GET_INSPECTION_APPOINTMENT_BY_ID, {
    variables: {
      input: id,
    },
    onCompleted(response) {
      const {
        date,
        inspector: responseInspector,
        hour,
        curboSpot,
        latitude,
        longitude,
        address: responseAddress,
      } = response.data
      setAppointmentDate(parseISO(date))
      setInspector(
        responseInspector
          ? {
              ...responseInspector,
              name: `${responseInspector.name} ${responseInspector.lastName}`,
            }
          : responseInspector
      )
      setTime(hour.value)
      setMeridiam(hour.am ? 'AM' : 'PM')
      if (latitude && longitude && responseAddress) {
        setAddress({
          address: responseAddress,
          id: curboSpot ? curboSpot.id : '',
          lat: latitude,
          lng: longitude,
          name: curboSpot ? curboSpot.name : '',
          originFromSpot: !!curboSpot,
        })
        getInspectors({
          variables: {
            input: {
              curboSpot: curboSpot ? curboSpot.id : undefined,
              date,
              inspectionHour: hour.value,
              latitude: !curboSpot ? latitude : undefined,
              longitude: !curboSpot ? longitude : undefined,
            },
          },
        })
      }
    },
  })

  const { loading: curboSpotsLoading } = useQuery<GenericData<CurboSpot[]>>(
    GET_CURBO_SPOTS,
    {
      onCompleted(response) {
        setCurboSpots(response.data)
      },
    }
  )

  useQuery<
    GenericData<InspectionWeekCalendar>,
    GenericInputVariable<string | null>
  >(GET_INSPECTION_WEEK_CALENDAR_BY_CURBO_SPOT_ID, {
    variables: {
      input: address && address.originFromSpot ? address.id : null,
    },
    onCompleted(response) {
      const responseWeekCalendar = { ...response.data }
      setWeekCalendarData(responseWeekCalendar)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        ENTITY_NOT_FOUND_ERROR
      )

      if (errorExists) {
        setWeekCalendarData(emptyWeekCalendar)
      }
    },
  })

  const handleSaveData = async () => {
    if (!address || !inspector || !appointmentDate || !time) {
      setErrors({
        address: !address && true,
        calendar: !appointmentDate && true,
        inspector: !inspector && true,
        time: !time && true,
      })
    } else {
      const isUpdateSuccesful = await updateInspectionAppointment({
        address: address.address,
        curboSpot: address.originFromSpot ? address.id : null,
        date: getIsoDate(appointmentDate) || '',
        hour: time,
        inspector: String(inspector.id),
        latitude: address.lat,
        longitude: address.lng,
      })
      setErrors(initialErrors)
      setShowSaveButton(!isUpdateSuccesful)
    }
  }

  const handleAppointmentDateChange = (date: Date | null) => {
    if (date) {
      const dateNumber = getDay(date)
      const dayKey = weekDay[dateNumber]
      setDateKey(dayKey)
      const storedDate = new Date(date.setHours(0, 0, 0, 0))
      setAppointmentDate(storedDate)
    }
  }

  const onDateAccept = () => {
    setTime('')
    setInspector(null)
    setShowSaveButton(true)
  }

  const handleInspectorChange = (value: AutocompleteItem) => {
    setInspector(value as Inspector)
    setShowSaveButton(true)
  }

  const handleGetInspectors = (selectedHour: string) => {
    getInspectors({
      variables: {
        input: {
          curboSpot: address?.originFromSpot ? address.id : undefined,
          date: getIsoDate(appointmentDate || new Date())!,
          inspectionHour: selectedHour,
          latitude:
            address && !address.originFromSpot ? address.lat : undefined,
          longitude:
            address && !address.originFromSpot ? address.lng : undefined,
        },
      },
    })
  }

  const handleHourChange = (event: SelectChangeEvent<unknown>) => {
    const selectedHour = event.target.value as string
    setTime(selectedHour)
    setShowSaveButton(true)
    setInspector(null)
    handleGetInspectors(selectedHour)
  }
  const handleMeridiamChange = (event: SelectChangeEvent<unknown>) => {
    const selectedMeridiam = event.target.value as string
    setMeridiam(selectedMeridiam)
    setTime('')
    setShowSaveButton(true)
  }

  const handleAddressChange = (newAddress: Address | undefined) => {
    setAddress(newAddress)
    setAppointmentDate(null)
    setTime('')
    setShowSaveButton(true)
    setInspector(null)
  }

  const handleReset = () => {
    const {
      date,
      inspector: responseInspector,
      hour,
      curboSpot,
      latitude,
      longitude,
      address: responseAddress,
    } = appointmentData!.data
    setErrors(initialErrors)

    setAppointmentDate(parseISO(date))
    setInspector(
      responseInspector
        ? {
            ...responseInspector,
            name: `${responseInspector.name} ${responseInspector.lastName}`,
          }
        : responseInspector
    )
    setTime(hour.value)
    setMeridiam(hour.am ? 'AM' : 'PM')
    handleGetInspectors(hour.value)
    if (latitude && longitude && responseAddress) {
      setAddress({
        address: responseAddress,
        id: curboSpot ? curboSpot.id : '',
        lat: latitude,
        lng: longitude,
        name: curboSpot ? curboSpot.name : '',
        originFromSpot: !!curboSpot,
      })
    }
    setShowSaveButton(false)
  }
  useEffect(() => {
    if (weekCalendarData) {
      // here we are trying to get the number of the days that doesn't have any schedule
      // to disable them in the calendar
      const disabledDayNumbers: number[] =
        getDisabledDayNumbers(weekCalendarData)
      setDisabledDays(disabledDayNumbers)
    }
  }, [weekCalendarData])

  if (loading || curboSpotsLoading || !weekCalendarData)
    return (
      <LoadingAnimation
        showAnimation={loading || curboSpotsLoading || !weekCalendarData}
      />
    )

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        gridTemplateRows: '9fr 1fr',
        rowGap: '10px',
        justifyItems: 'end',
      }}
    >
      <Box
        width="95%"
        sx={{
          gridColumn: '1',
          gridRow: '1 / span 2',
        }}
      >
        <DatePicker
          value={appointmentDate}
          translation={translation}
          onChange={handleAppointmentDateChange}
          disabled={!address || disabledUpdate}
          shouldDisableDate={(disabledDate) =>
            disabledDays.includes(disabledDate.getDay())
          }
          onAccept={onDateAccept}
          startSameDay
        />
        {errors.calendar && (
          <StyledErrorMessage
            sx={{ marginBottom: '0.5rem' }}
            text={validationText.fieldRequired}
          />
        )}
        <TimePicker
          translation={translation}
          hourOptions={weekCalendarData[dateKey].filter((day) =>
            meridiam === 'AM' ? day.am : !day.am
          )}
          hour={time}
          hourChange={handleHourChange}
          meridiamOptions={meridiamOptions}
          meridiam={meridiam}
          meridiamChange={handleMeridiamChange}
          disabled={!appointmentDate || disabledUpdate}
        />
        {errors.time && (
          <StyledErrorMessage
            sx={{ marginBottom: '0.5rem' }}
            text={validationText.fieldRequired}
          />
        )}
        <InspectorPicker
          translation={translation}
          onChange={handleInspectorChange}
          value={inspector}
          inspectors={inspectors}
          disabled={disabledUpdate || !time}
          loading={inspectorsLoading}
        />
        {errors.inspector && (
          <StyledErrorMessage text={validationText.fieldRequired} />
        )}
      </Box>
      <LocationSection
        showError={errors.address}
        translation={translation}
        address={address}
        handleAddressChange={handleAddressChange}
        containerStyling={{
          width: '95%',
          gridColumn: '2',
          gridRow: '1',
        }}
        curboSpots={curboSpots}
        disabled={disabledUpdate}
        circleCenter={
          address ? { lat: address.lat, lng: address.lng } : undefined
        }
      />
      {showSaveButton ? (
        <Box flexDirection="row" display="flex" width="100%">
          <Button
            startIcon={<SaveIcon />}
            buttonType="primary"
            onClick={handleSaveData}
            sx={{
              gridColumn: '2',
              gridRow: '2',
              justifySelf: 'start',
              marginLeft: '5%',
            }}
            disabled={submitLoading}
          >
            {translation.saveButton}
          </Button>

          <Button variant="text" onClick={handleReset} disabled={submitLoading}>
            {translation.resetButton}
          </Button>
        </Box>
      ) : null}
    </Box>
  )
}

export default Appointment
