import { common } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

export const colors = {
  blue: '#07C8E8',
  darkBlue: '#05356C',
  navyBlue: '#2F5157',
  lightBlue: '#46DAF2',
  aquaBlue: '#4AAFD5',
  darkAquaBlue: '#0094AC',
  backgroundBlue: '#F2F5F9',
  altBlue: '#DAEAF7',
  questionGray: '#EEEEEE',
  lightGray: '#D6D6D6',
  darkGray: '#54535F',
  paleGray: '#F2F2F2',
  gray: '#A5A5A5',
  grayLight: '#F8F8F8',
  border: '#E8E4E4',
  background: '#F5F5F5',
  backdrop: '#7070705',
  scroll: '#ADA9A9',
  shadow: '#00000029',
  black: '#201F2C',
  altBlack: '#585858',
  altBlack2: '#36334A',
  red: '#FF0000',
  lightRed: '#E58585',
  backgroundRed: '#FFEBEB',
  pink: '#D0607E',
  lightPink: '#FEF3F7',
  green: '#4DB08E',
  lime: '#6DC51F',
  lightGreen: '#EBF9F5',
  lightBrown: '#968686',
  yellow: '#F7AE14',
  lightYellow: '#FFF7E6',
  cyan: '#E6F4FF',
  commonWhite: common.white,
  commonBlack: common.black,
  lightWhite: '#FBFBFB',
  transparent: '#ffffff00',
  placeholderGray: '#A5A5A5',
  backgroundGray: '#F7F7F7',
  backgroundDarkBlue: '#F2F5F9',
  faintGray: '#EDEDED',
  altGray: '#F0F0F0',
  subtitleGray: '#CCCCCC',
  orange: '#FF9300',
  purple: '#8E55A3',
  lightPurple: '#EEE5F1',
}
export enum FONT_WEIGHT {
  LIGHT = 300,
  REGULAR = 400,
  MEDIUM = 500,
  SEMI_BOLD = 600,
  BOLD = 700,
  EXTRA_BOLD = 800,
}

const globalTheme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: colors.darkGray,
          fontSize: '14px',
          fontWeight: 'normal',
          letterSpacing: '0.14px',
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: colors.commonWhite,
          backgroundColor: colors.blue,
        },
        arrow: {
          color: colors.blue,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        menuItem: {
          fontWeight: FONT_WEIGHT.MEDIUM,
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.blue,
      light: colors.lightBlue,
      dark: colors.darkBlue,
      contrastText: colors.commonWhite,
    },
    secondary: {
      main: colors.transparent,
      light: colors.lightBlue,
      dark: colors.darkBlue,
      contrastText: colors.blue,
    },
    info: {
      main: colors.commonWhite,
      light: colors.lightWhite,
      dark: colors.darkGray,
      contrastText: colors.black,
    },
    error: { main: colors.lightRed, light: colors.red },
    warning: { main: colors.yellow },
    success: { main: colors.green },
  },
  typography: {
    fontFamily: ['Open Sans', 'Montserrat', 'sans-serif', 'Arial'].join(','),
    h1: {
      fontSize: '42px',
      fontWeight: FONT_WEIGHT.EXTRA_BOLD,
      color: colors.blue,
    },
    h2: {
      fontSize: '32px',
      fontWeight: FONT_WEIGHT.EXTRA_BOLD,
      color: colors.blue,
    },
    h3: {
      fontSize: '30px',
      fontStretch: '0.32px',
      fontWeight: FONT_WEIGHT.EXTRA_BOLD,
      color: colors.black,
    },
    h4: {
      fontSize: '28px',
      fontStretch: '0.28px',
      fontWeight: FONT_WEIGHT.REGULAR,
      color: colors.darkGray,
    },
    h5: {
      fontSize: '24px',
      fontStretch: '0.24px',
      fontWeight: FONT_WEIGHT.REGULAR,
      color: colors.darkGray,
    },
    h6: {
      fontSize: '20px',
      fontStretch: '0.2px',
      fontWeight: FONT_WEIGHT.REGULAR,
      color: colors.darkGray,
    },
    body1: {
      fontSize: '16px',
      fontWeight: FONT_WEIGHT.BOLD,
      color: colors.black,
    },
    body2: {
      fontSize: '14px',
      fontWeight: FONT_WEIGHT.MEDIUM,
      color: colors.gray,
    },
    subtitle1: {
      fontSize: '12px',
      fontWeight: FONT_WEIGHT.REGULAR,
      color: colors.darkGray,
    },
    subtitle2: {
      fontSize: '10px',
      fontWeight: FONT_WEIGHT.REGULAR,
      color: colors.black,
    },
    button: {
      textTransform: 'none',
    },
  },
})

export const boxShadow = `0px 3px 6px ${colors.shadow}`
export const inputBoxShadow = `0px 3px 4px ${colors.shadow}`
export const smallBoxShadow = `0px 1px 2px ${colors.shadow}`

// Shadow box used for the modal and some cards is #1 of the array
// Box shadow used for some of the inputs its #2 of the array
// You can use it via box-shadow: '1' or '2';
globalTheme.shadows[1] = boxShadow
globalTheme.shadows[2] = inputBoxShadow

export default globalTheme
