import { gql } from '@apollo/client'

export const GET_COUNTRY_VERSION_BY_ID = gql`
  query GetCountryVersionById($input: ID!) {
    data: getCountryVersionById(id: $input) {
      name
      nameTranslations {
        en
        es
      }
      status
    }
  }
`
