import { Box, List, styled, TextField, Typography } from '@mui/material'

import Button from 'components/Common/Button'

import { BREAK_POINTS } from 'styles/breakpoints'
import { boxShadow, colors } from 'styles/theme'

export const StyledBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '40% 30% 30%',
  padding: '1.2rem 0rem',
  '> div:nth-of-type(3)': {
    padding: '0 !important',
  },
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '55% 40%',
    columnGap: '10px',
    rowGap: '20px',
    '> div:nth-of-type(3)': {
      padding: '0 2rem !important',
    },
  },
})

export const StyledButton = styled(Button)({
  marginTop: '14px',
  width: '180px',
  height: '35px',
})

export const RowTitle = styled(Typography)({
  display: 'inline',
  fontSize: '14px',
})

export const StyledAmountEditContainer = styled(Box)({
  borderRadius: '10px',
  boxShadow,
})

export const StyledList = styled(List)({
  'li::before': {
    content: '"•"',
    color: colors.blue,
    fontWeight: 'bold',
    display: 'inline-block',
    width: '1em',
  },
})

export const CommissionAmountContainer = styled(Box)({
  paddingLeft: '35px',
  display: 'flex',
  flexDirection: 'column',
})

export const StyledTextField = styled(TextField)({
  width: '82px',
  color: colors.border,
  backgroundColor: colors.commonWhite,
  borderColor: 'transparent',
  marginRight: '4px',
  input: {
    color: colors.gray,
    fontWeight: 'normal',
  },
  'input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: '0',
  },
})
