import React from 'react'
import NumberFormat from 'react-number-format'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

export const NumberFormatCustom = React.forwardRef<
  NumberFormat<string>,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      format="+1 (###) ### ####"
      mask=" "
      isNumericString
    />
  )
})
