import React, { useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import MapIcon from '@mui/icons-material/Map'
import { Typography } from '@mui/material'

import Skeleton from 'components/CarSettings/Common/Creation/Summary/Skeleton'
import Box from 'components/Common/Box'
import SummaryItem from 'components/Common/SummaryItem'
import Map from 'components/General/Map'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { Address } from 'models/map'
import { CityCreationProps } from 'utils/City/creation'

const CitySummary = ({
  cityData,
  handleBack,
  handleSubmit,
  submitLoading,
  states,
}: CityCreationProps) => {
  const { text } = useTranslation(textFiles.CITY_CREATION)

  const { cityInformation } = cityData

  const {
    summary: { title, description, status, name, state, map },
  } = text
  const { text: generalText } = useTranslation(textFiles.GENERAL)

  const [, setApiLoaded] = useState<boolean>(false)
  const [address, setAddress] = useState<Address | undefined>(
    cityInformation.address
  )

  const stateOption = states.find(
    (currentState) => currentState.value === cityInformation.state
  )

  const stateName = stateOption ? stateOption.name : cityInformation.state

  return (
    <Skeleton
      previousFunction={handleBack}
      title={title}
      description={description}
      nextFunction={handleSubmit}
      submitLoading={submitLoading}
      gridContainerStyles={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
      containerStyles={{ height: '100%' }}
      formStyles={{
        height: '100%',
      }}
      bodyStyles={{
        height: '100%',
        overflowY: 'inherit',
      }}
    >
      <SummaryItem
        title={name}
        text={cityInformation.name}
        icon={<LocationCityIcon />}
        width="310px"
      />

      <SummaryItem
        title={status}
        text={generalText.status[cityInformation.status]}
        icon={<CheckCircleIcon />}
        width="310px"
      />

      <SummaryItem
        title={state}
        text={stateName}
        icon={<MapIcon />}
        width="310px"
      />
      <Box width="100%" height="353px" sx={{ marginBottom: '5rem' }}>
        <Typography variant="body1" marginBottom="12px">
          {map}
        </Typography>
        <Map
          disabled
          setIsLoaded={setApiLoaded}
          curboSpots={[]}
          address={address}
          setAddress={setAddress}
          circleCenter={
            address ? { lat: address.lat, lng: address.lng } : undefined
          }
        />
      </Box>
    </Skeleton>
  )
}

export default CitySummary
