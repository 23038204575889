import { styled } from '@mui/material'

import Box from 'components/Common/Box'

import { colors } from 'styles/theme'

export const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'start',
  width: '100%',
  backgroundColor: colors.commonWhite,
})

export const StyledContainer = styled(Box)({
  width: '100%',
  minHeight: '330px',
  display: 'grid',
})
