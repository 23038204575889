import React from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'
import { Link as MUILink } from '@mui/material'

type CustomLinkProps = {
  testId?: string
} & LinkProps

const Link: React.FC<CustomLinkProps> = ({ testId, ...props }) => (
  <MUILink component={RouterLink} underline="hover" {...props} data-cy={testId}>
    {props.children}
  </MUILink>
)

export default Link
