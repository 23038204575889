import { gql } from '@apollo/client'

export const GET_BLACK_LIST_BRAND_BY_ID = gql`
  query getBlacklistBrandById($input: ID!) {
    data: getBlacklistBrandById(id: $input) {
      id
      name
      comment
      blacklistStatus
    }
  }
`

export const GET_BLACK_LIST_MODEL_BY_ID = gql`
  query getBlacklistModelById($input: ID!) {
    data: getBlacklistModelById(id: $input) {
      id
      name
      comment
      blacklistStatus
    }
  }
`

export const GET_BLACK_LIST_TRIM_LEVEL_BY_ID = gql`
  query getBlacklistTrimLevelById($input: ID!) {
    data: getBlacklistTrimLevelById(id: $input) {
      id
      name
      comment
      blacklistStatus
    }
  }
`
