import React, { FunctionComponent } from 'react'
import { InputBaseComponentProps, Slider } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'

import ButtonContainer from 'components/Template/Creation/ButtonContainer'

import { VEHICLE_CREATION_OPTION } from 'constants/Inventory/creation'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { FourthStepModel } from 'models/services/PreInventory/creation'
import { VehicleCreationProps } from 'utils/Inventory/creation'

import {
  BoxContainer,
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'

import NumberValueInput, {
  maxValue,
  minValue,
} from '../../Common/NumberValueInput'

type FormikKeys =
  | 'frontLegRoom'
  | 'backLegRoom'
  | 'frontHeadRoom'
  | 'backHeadRoom'
  | 'engineDisplacement'
  | 'cargoCapacity'
  | 'cargo'

const initialValue: FourthStepModel = {
  frontLegRoom: 1,
  backLegRoom: 1,
  frontHeadRoom: 1,
  backHeadRoom: 1,
  engineDisplacement: 1,
  cargoCapacity: 1,
  cargo: 1,
}

const VehicleSpacing = ({
  vehicleData,
  updateVehicleData,
  handleSecondaryBack,
  handleSecondaryContinue,
}: VehicleCreationProps) => {
  const {
    text: {
      subSteps: { fourthStep: translation },
    },
  } = useTranslation(textFiles.VEHICLE_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const initialData = vehicleData.vehicleInformation.fourthStep || initialValue

  const validationSchema = yup.object().shape({
    frontLegRoom: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    backLegRoom: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    frontHeadRoom: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    backHeadRoom: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    engineDisplacement: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    cargoCapacity: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
    cargo: yup
      .number()
      .required(validationText.fieldRequired)
      .positive(validationText.positiveNumber),
  })

  const formik = useFormik<FourthStepModel>({
    initialValues: initialData,
    validationSchema,
    onSubmit: (values) => {
      updateVehicleData({
        type: VEHICLE_CREATION_OPTION.UPDATE_VEHICLE_INFORMATION,
        payload: {
          ...vehicleData.vehicleInformation,
          fourthStep: values,
        },
      })
      handleSecondaryContinue()
    },
  })

  const handleSliderChange = (value: number | number[], field: FormikKeys) => {
    formik.setFieldValue(field, Number(value))
  }

  const handleBlur = (field: FormikKeys) => {
    if (formik.values[field] > maxValue) {
      formik.setFieldValue(field, maxValue)
    } else if (!formik.values[field]) formik.setFieldValue(field, minValue)
  }

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <StyledTextFieldContainer title={translation.frontLegLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.frontLegRoom}
                onChange={(e, value) => {
                  handleSliderChange(value, 'frontLegRoom')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="frontLegRoom"
                value={formik.values.frontLegRoom}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('frontLegRoom')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.frontLegRoom && formik.errors.frontLegRoom ? (
              <StyledErrorMessage text={formik.errors.frontLegRoom} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.backLegLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.backLegRoom}
                onChange={(e, value) => {
                  handleSliderChange(value, 'backLegRoom')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="backLegRoom"
                value={formik.values.backLegRoom}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('backLegRoom')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.backLegRoom && formik.errors.backLegRoom ? (
              <StyledErrorMessage text={formik.errors.backLegRoom} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.frontHeadLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.frontHeadRoom}
                onChange={(e, value) => {
                  handleSliderChange(value, 'frontHeadRoom')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="frontHeadRoom"
                value={formik.values.frontHeadRoom}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('frontHeadRoom')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.frontHeadRoom && formik.errors.frontHeadRoom ? (
              <StyledErrorMessage text={formik.errors.frontHeadRoom} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.backHeadLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.backHeadRoom}
                onChange={(e, value) => {
                  handleSliderChange(value, 'backHeadRoom')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="backHeadRoom"
                value={formik.values.backHeadRoom}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('backHeadRoom')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.backHeadRoom && formik.errors.backHeadRoom ? (
              <StyledErrorMessage text={formik.errors.backHeadRoom} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.engineDisplacementLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.engineDisplacement}
                onChange={(e, value) => {
                  handleSliderChange(value, 'engineDisplacement')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="engineDisplacement"
                value={formik.values.engineDisplacement}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('engineDisplacement')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.engineDisplacement &&
            formik.errors.engineDisplacement ? (
              <StyledErrorMessage text={formik.errors.engineDisplacement} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.cargoCapacityLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.cargoCapacity}
                onChange={(e, value) => {
                  handleSliderChange(value, 'cargoCapacity')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="cargoCapacity"
                value={formik.values.cargoCapacity}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('cargoCapacity')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.cargoCapacity && formik.errors.cargoCapacity ? (
              <StyledErrorMessage text={formik.errors.cargoCapacity} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer title={translation.cargoWeightLabel}>
            <BoxContainer>
              <Slider
                value={formik.values.cargo}
                onChange={(e, value) => {
                  handleSliderChange(value, 'cargo')
                }}
                max={maxValue}
                min={minValue}
                step={100}
                aria-labelledby="input-slider"
                sx={{
                  width: '65%',
                  marginLeft: '13px',
                }}
              />
              <StyledTextField
                variant="outlined"
                name="cargo"
                value={formik.values.cargo}
                onChange={formik.handleChange}
                onBlur={() => {
                  handleBlur('cargo')
                }}
                InputProps={{
                  inputComponent:
                    NumberValueInput as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                sx={{
                  width: '25% !important',
                }}
              />
            </BoxContainer>
            {formik.touched.cargo && formik.errors.cargo ? (
              <StyledErrorMessage text={formik.errors.cargo} />
            ) : null}
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonContainer
        previousFunction={handleSecondaryBack}
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default VehicleSpacing
