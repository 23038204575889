import React, { useState } from 'react'
import { Tab as MuiTab } from '@mui/material'

import { StyledBadge } from './style'

type TabProps = {
  label: string
  badge?: number
  value: number | string
  handleTabChange: (value: number | string) => void
  onClick?: () => void
}

const Tab: React.FC<TabProps> = ({
  badge = 0,
  value,
  label,
  handleTabChange,
  onClick,
}) => {
  const [badgeValue, setBadgeValue] = useState(badge)
  const handleClearBadge = () => {
    if (onClick) onClick()
    handleTabChange(value)
    setBadgeValue(0)
  }
  return (
    <MuiTab
      value={value}
      label={
        badgeValue && badgeValue > 0 ? (
          <StyledBadge badgeContent={badgeValue} color="error">
            {label}
          </StyledBadge>
        ) : (
          label
        )
      }
      onClick={handleClearBadge || (() => 0)}
      data-cy={`tab-${value}-button`}
    />
  )
}

export default Tab
