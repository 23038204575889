import { weekDay } from 'constants/date'
import {
  CheckedWeekCalendar,
  OutOfSpotModel,
  ScheduleUpdateInput,
} from 'models/outOfSpot'

export const initialOutOfSpotState: OutOfSpotModel = {
  inspections: {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  },
  testDrive: {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  },
}

export const emptyCheckedWeekCalendar = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
} as CheckedWeekCalendar

export const emptyInputWeekCalendar = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
} as ScheduleUpdateInput

export const arrayOfNamedDays = Object.values(weekDay)
