export const cypressAddButton = 'add-button'
export const cypressAddPictureButton = 'add-picture-button'
export const cypressAddPictureInput = 'add-picture-input'
export const cypressEditButton = 'edit-button'
export const cypressBackLink = 'back-link'
export const cypressBackButton = 'back-button'
export const cypressSaveButton = 'save-button'
export const cypressSaveDraftButton = 'save-draft-button'
export const cypressCancelButton = 'cancel-button'
export const cypressSubmitButton = 'submit-button'
export const cypressConfirmButton = 'confirm-button'
export const cypressPreviousStepButton = 'previous-step-button'
export const cypressNextStepButton = 'next-step-button'
export const cypressToggleButtonTrue = 'toggle-button-true'
export const cypressToggleButtonFalse = 'toggle-button-false'
export const cypressIncreaseCounterButton = 'increase-counter-button'
export const cypressDecreaseCounterButton = 'decrease-counter-button'
