import { gql } from '@apollo/client'

export const CREATE_ANSWER = gql`
  mutation createAnswer($input: CreateAnswerInput!) {
    data: createAnswer(input: $input) {
      id
    }
  }
`

export const DELETE_ANSWER = gql`
  mutation deleteAnswer($input: ID!) {
    data: deleteAnswer(id: $input) {
      id
    }
  }
`

export const UPDATE_ANSWER = gql`
  mutation updateAnswer($input: UpdateAnswerInput!) {
    data: updateAnswer(input: $input) {
      id
    }
  }
`
