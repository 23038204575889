import React, { useCallback, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { BackContainer } from 'components/Common/BackContainer'
import BodyContainerWithHeader from 'components/Common/BodyContainerWithHeader'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { CAR_SETTINGS_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import { FuelType } from 'models/car'
import {
  FuelTypeCreationAction,
  FuelTypeCreationModel,
} from 'models/fuelTypeCreation'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { CreateCarSettingGenericInputType } from 'models/services/CarSettings/creation'
import { PublicationStatus } from 'models/status'
import fuelTypeCreationReducer from 'reducers/fuelTypeCreationReducer'
import {
  createFuelTypeStepperItems,
  INITIAL_FUEL_TYPE_CREATION_STEPS as steps,
} from 'utils/CarSettings/FuelType/creation'
import { validateGraphQLErrorCode } from 'utils/error'
import { getTranslationsFilteredByLanguage } from 'utils/translation'

import { CREATE_FUEL_TYPE } from 'graphQL/Operations/FuelType/Creation/mutations'

import { StyledBox } from 'styles/operation/creation'

const initialFuelTypeInformationState: FuelType = {
  name: '',
  status: PublicationStatus.PUBLISHED,
  translations: [],
}

const initialData: FuelTypeCreationModel = {
  fuelTypeInformation: initialFuelTypeInformationState,
}

const FuelTypeCreationPage = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)

  const [fuelTypeData, dispatch] = useReducer(
    fuelTypeCreationReducer,
    initialData
  )

  const { fuelTypeInformation } = fuelTypeData
  const { translations } = fuelTypeInformation

  const history = useHistory()
  const { show } = useNotification()

  const { text } = useTranslation(textFiles.FUEL_TYPE_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { stepper } = text

  const stepperItems = createFuelTypeStepperItems(stepper)

  const updateFuelTypeData = (action: FuelTypeCreationAction) => {
    dispatch(action)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }
  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const [createFuelType, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateCarSettingGenericInputType>
  >(CREATE_FUEL_TYPE, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
      history.push(CAR_SETTINGS_SUB_ROUTES.FUEL_TYPE_LISTING)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
        })
    },
  })

  const handleSubmit = useCallback(() => {
    const { valueEn, valueEs } = getTranslationsFilteredByLanguage(translations)

    createFuelType({
      variables: {
        input: {
          name: fuelTypeInformation.name,
          name_translations: {
            en: valueEn ? valueEn.description : null,
            es: valueEs ? valueEs.description : null,
          },
          status: fuelTypeInformation.status
            ? PublicationStatus.PUBLISHED
            : PublicationStatus.UNPUBLISHED,
        },
      },
    })
  }, [fuelTypeInformation, createFuelType, translations])

  return (
    <StyledBox>
      <BackContainer />
      <BodyContainerWithHeader
        title={text.title}
        subtitle={text.processDescription}
        stepperItems={stepperItems}
        currentStep={currentStep}
      >
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          handleSubmit,
          fuelTypeData,
          submitLoading,
          updateFuelTypeData,
        })}
      </BodyContainerWithHeader>
    </StyledBox>
  )
}

export default FuelTypeCreationPage
