import { DEALER_CREATION_OPTION } from 'constants/Operation/dealer'
import { DealerCreationAction, DealerCreationModel } from 'models/dealer'

const dealerCreationReducer = (
  state: DealerCreationModel,
  action: DealerCreationAction
): DealerCreationModel => {
  switch (action.type) {
    case DEALER_CREATION_OPTION.UPDATE_DEALER_INFORMATION:
      return { ...state, dealerInformation: action.payload }

    case DEALER_CREATION_OPTION.UPDATE_DEALER_LOCATION:
      return { ...state, dealerLocation: action.payload }

    default:
      return state
  }
}

export default dealerCreationReducer
