import React from 'react'
import { Box, TableCell } from '@mui/material'

import { Day } from 'models/calendar'

import InspectionTag from '../InspectionTag'
import { ClickableTag } from '../InspectionTag/ClickableTag'

export const CalendarCell = ({ data, isAbsent, absenceMotive }: Day) => (
  <TableCell
    align="left"
    sx={{ minWidth: '128px', height: '116px', padding: '10px' }}
  >
    {isAbsent ? (
      <Box key="absence_tag" marginBottom="4px">
        <ClickableTag type="ABSENCE" data={absenceMotive!} />
      </Box>
    ) : (
      data.length > 0 &&
      data?.map((inspection) => (
        <Box key={inspection.origin}>
          {inspection.origin !== 'UNKNOWN' ? (
            <>
              {inspection.notes && inspection.notes.length > 0 ? (
                <ClickableTag type="NOTES" data={inspection.notes!} />
              ) : (
                <Box marginBottom="4px">
                  <InspectionTag
                    number={inspection.total}
                    type={inspection.origin}
                  />
                </Box>
              )}
            </>
          ) : null}
        </Box>
      ))
    )}
  </TableCell>
)
