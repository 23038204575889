import { LANGUAGE_KEY, LANGUAGES_JSON } from 'constants/language'
import { Language } from 'models/language'

// this function checks if the user has selected a preferred language if not it will
// take it from the browser preferred languages, if the languages does not exist it
// fall back to english as default
export const getPreferredLanguage = (): Language => {
  const defaultLanguage: Language = { name: 'Español', code: 'es' }
  const localStorageExist = localStorage.getItem(LANGUAGE_KEY)

  if (localStorageExist) {
    const lang: Language = JSON.parse(localStorageExist)
    return lang
  }
  const browserLocale = navigator.languages
    ? navigator.languages[0]
    : navigator.language

  if (!browserLocale) {
    localStorage.setItem(LANGUAGE_KEY, JSON.stringify(defaultLanguage))
    return defaultLanguage
  }

  const language = browserLocale.split('-')[0]

  if (language in LANGUAGES_JSON) {
    const prefLanguage = Object.entries(LANGUAGES_JSON).find(
      (languageObject) => languageObject[0] === language
    )

    if (prefLanguage) {
      localStorage.setItem(LANGUAGE_KEY, JSON.stringify(prefLanguage[1]))
      return prefLanguage[1]
    }

    localStorage.setItem(LANGUAGE_KEY, JSON.stringify(defaultLanguage))
    return defaultLanguage
  }

  localStorage.setItem(LANGUAGE_KEY, JSON.stringify(defaultLanguage))
  return defaultLanguage
}
