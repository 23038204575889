import { useContext } from 'react'

import { AppSetting, Country, SettingContext } from '../context/SettingContext'

const useSetting = (): [Country | null, Country[], AppSetting | null] => {
  const context = useContext(SettingContext)

  if (!context) {
    throw new Error('useSetting cannot be used without declaring the provider')
  }

  const { appSetting, countries, currentCountry } = context

  return [currentCountry, countries, appSetting]
}

export default useSetting
