import React, { FunctionComponent, useState } from 'react'
import {
  InputBaseComponentProps,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { FieldArray, FormikProvider, getIn, useFormik } from 'formik'
import * as yup from 'yup'

import AvatarContainer from 'components/CarSettings/Common/Creation/Avatar'
import ButtonContainer from 'components/CarSettings/Common/Creation/ButtonContainer'
import Button from 'components/Common/Button'
import ErrorMessage from 'components/Common/ErrorMessage'
import Select from 'components/Common/Select'
import { NumberFormatCustom } from 'components/General/PhoneNumberFormat'

import { cypressAddButton } from 'constants/cypress'
import { placeholderPhone } from 'constants/dealer'
import { DEALER_CREATION_OPTION } from 'constants/Operation/dealer'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { DealerInformation } from 'models/dealer'
import { DealerCreationProps } from 'utils/Dealer/creation'

import {
  StyledContainer,
  StyledForm,
  StyledTextField,
  StyledTextFieldContainer,
} from 'styles/creation'
import { colors, FONT_WEIGHT } from 'styles/theme'

import {
  AddRepresentativeButton,
  Divider,
  RepresentativeNumberBox,
  StyledRepresentativeContainer,
} from './style'

const DealerInfo = ({
  cities,
  dealerData,
  handleContinue,
  updateDealerData,
}: DealerCreationProps) => {
  const { text } = useTranslation(textFiles.DEALER_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const {
    dealerInformation: {
      city,
      name,
      phoneNumber,
      state,
      dealerContacts,
      picture,
      pictureFile,
    },
  } = dealerData

  const [pictureState, setPictureState] = useState<string>(picture)
  const [fileState, setFileState] = useState<File | null>(null)

  const {
    information: {
      dealerName,
      dealerPhone,
      cityName,
      stateName,
      representativeName,
      representativePhone,
      representativeEmail,
      thumbnail,
      addRepresentative,
      deleteRepresentative,
      representativeNumber,
    },
  } = text
  const phoneValidation = validationText.errorMessagePhoneNumberDigits.replace(
    '%d',
    '10'
  )

  const validationSchema = yup.object({
    name: yup.string().required(validationText.fieldRequired),
    city: yup.string().required(validationText.fieldRequired),
    state: yup.string().required(validationText.fieldRequired),
    phoneNumber: yup
      .string()
      .required(validationText.fieldRequired)
      .length(10, phoneValidation),
    dealerContacts: yup.array().of(
      yup.object().shape({
        telephoneNumber: yup
          .string()
          .length(10, phoneValidation)
          .required(validationText.fieldRequired),
        agent: yup.string().required(validationText.fieldRequired),
        email: yup.string().email(validationText.invalidEmail).optional(),
      })
    ),
  })

  const formik = useFormik<DealerInformation>({
    initialValues: {
      name,
      state,
      phoneNumber,
      city,
      dealerContacts,
      picture,
      pictureFile,
    },
    validationSchema,
    onSubmit: (values) => {
      updateDealerData({
        type: DEALER_CREATION_OPTION.UPDATE_DEALER_INFORMATION,
        payload: {
          ...values,
          picture: pictureState,
          pictureFile: fileState,
        },
      })
      handleContinue()
    },
  })

  const onSelectChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event
    formik.setFieldValue('city', value as string)
    formik.setFieldTouched('city', false)
    const selectedCity = cities.find(
      (currentCity) => currentCity.value === value
    )
    if (selectedCity) formik.setFieldValue('state', selectedCity.state.name)
  }

  const dealerContactsErrorExists = (index: number, key: string) => {
    if (
      getIn(formik.touched.dealerContacts?.[index], key) &&
      Boolean(getIn(formik.errors.dealerContacts?.[index], key))
    )
      return true

    return false
  }

  return (
    <FormikProvider value={formik}>
      <StyledContainer>
        <StyledForm onSubmit={formik.handleSubmit} id="dealer-form">
          <div>
            <StyledTextFieldContainer title={`${dealerName}*`}>
              <StyledTextField
                variant="outlined"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                placeholder="Dealer Comercial"
                error={formik.touched.name && Boolean(formik.errors.name)}
                inputProps={{ maxLength: 40 }}
              />
              {formik.touched.name && formik.errors.name ? (
                <ErrorMessage
                  sx={{
                    alignSelf: 'flex-start',
                    position: 'static',
                    marginTop: '0.5rem',
                  }}
                  text={formik.errors.name}
                />
              ) : null}
            </StyledTextFieldContainer>
            <StyledTextFieldContainer title={`${dealerPhone}*`}>
              <StyledTextField
                variant="outlined"
                name="phoneNumber"
                placeholder={placeholderPhone}
                value={formik.values.phoneNumber}
                InputProps={{
                  inputComponent:
                    NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
                }}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                onChange={formik.handleChange}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <ErrorMessage
                  sx={{
                    alignSelf: 'flex-start',
                    position: 'static',
                    marginTop: '0.5rem',
                  }}
                  text={formik.errors.phoneNumber}
                />
              ) : null}
            </StyledTextFieldContainer>

            <StyledTextFieldContainer title={`${cityName}*`}>
              <Select
                options={cities}
                variant="outlined"
                name="city"
                value={formik.values.city}
                onChange={onSelectChange}
                sx={{ fontSize: 12, height: 40 }}
                label="Santo Domingo"
                error={formik.touched.city && Boolean(formik.errors.city)}
              />
              {formik.touched.city && formik.errors.city ? (
                <ErrorMessage
                  sx={{
                    alignSelf: 'flex-start',
                    position: 'static',
                    marginTop: '0.5rem',
                  }}
                  text={formik.errors.city}
                />
              ) : null}
            </StyledTextFieldContainer>
            <StyledTextFieldContainer title={`${stateName}*`}>
              <StyledTextField
                variant="outlined"
                name="state"
                value={formik.values.state}
                placeholder="Santo Domingo Este"
                error={formik.touched.state && Boolean(formik.errors.state)}
                disabled
              />
              {formik.touched.state && formik.errors.state ? (
                <ErrorMessage
                  sx={{
                    alignSelf: 'flex-start',
                    position: 'static',
                    marginTop: '0.5rem',
                  }}
                  text={formik.errors.state}
                />
              ) : null}
            </StyledTextFieldContainer>

            <FieldArray
              name="dealerContacts"
              render={(arrayHelpers) => (
                <>
                  {formik.values.dealerContacts.map((_, index) => (
                    // taken from https://formik.org/docs/examples/field-arrays, formik uses
                    // index in their examples
                    <StyledRepresentativeContainer
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      sx={{ columnGap: '1.5rem', rowGap: '1rem' }}
                    >
                      <RepresentativeNumberBox>
                        <Typography
                          color={colors.altBlack}
                          fontWeight={FONT_WEIGHT.MEDIUM}
                          variant="subtitle1"
                        >
                          {`#${index + 1} ${representativeNumber}`}
                        </Typography>
                        <Divider />
                        <Button
                          size="small"
                          variant="text"
                          onClick={() => {
                            arrayHelpers.remove(index)
                          }}
                          testId={`delete-contact-${index}-button`}
                        >
                          {deleteRepresentative}
                        </Button>
                      </RepresentativeNumberBox>

                      <StyledTextFieldContainer
                        title={`${representativeName}*`}
                      >
                        <StyledTextField
                          variant="outlined"
                          name={`dealerContacts[${index}].agent`}
                          value={formik.values.dealerContacts[index].agent}
                          onChange={formik.handleChange}
                          placeholder="Jason Guo"
                          inputProps={{ maxLength: 40 }}
                          error={dealerContactsErrorExists(index, 'agent')}
                        />
                        {dealerContactsErrorExists(index, 'agent') ? (
                          <ErrorMessage
                            sx={{
                              alignSelf: 'flex-start',
                              position: 'static',
                              marginTop: '0.5rem',
                            }}
                            text={
                              getIn(
                                formik.errors.dealerContacts?.[index],
                                'agent'
                              ) || ''
                            }
                          />
                        ) : null}
                      </StyledTextFieldContainer>

                      <StyledTextFieldContainer
                        title={`${representativePhone}*`}
                      >
                        <StyledTextField
                          variant="outlined"
                          name={`dealerContacts[${index}].telephoneNumber`}
                          placeholder={placeholderPhone}
                          value={
                            formik.values.dealerContacts[index].telephoneNumber
                          }
                          InputProps={{
                            inputComponent:
                              NumberFormatCustom as unknown as FunctionComponent<InputBaseComponentProps>,
                          }}
                          onChange={formik.handleChange}
                          error={dealerContactsErrorExists(
                            index,
                            'telephoneNumber'
                          )}
                        />
                        {dealerContactsErrorExists(index, 'telephoneNumber') ? (
                          <ErrorMessage
                            sx={{
                              alignSelf: 'flex-start',
                              position: 'static',
                              marginTop: '0.5rem',
                            }}
                            text={
                              getIn(
                                formik.errors.dealerContacts?.[index],
                                'telephoneNumber'
                              ) || ''
                            }
                          />
                        ) : null}
                      </StyledTextFieldContainer>

                      <StyledTextFieldContainer title={representativeEmail}>
                        <StyledTextField
                          variant="outlined"
                          name={`dealerContacts[${index}].email`}
                          value={formik.values.dealerContacts[index].email}
                          onChange={formik.handleChange}
                          placeholder="john@doe.com"
                          inputProps={{ maxLength: 40 }}
                          error={dealerContactsErrorExists(index, 'email')}
                        />
                        {dealerContactsErrorExists(index, 'email') ? (
                          <ErrorMessage
                            sx={{
                              alignSelf: 'flex-start',
                              position: 'static',
                              marginTop: '0.5rem',
                            }}
                            text={
                              getIn(
                                formik.errors.dealerContacts?.[index],
                                'email'
                              ) || ''
                            }
                          />
                        ) : null}
                      </StyledTextFieldContainer>
                    </StyledRepresentativeContainer>
                  ))}
                  <AddRepresentativeButton
                    size="small"
                    variant="text"
                    onClick={() => {
                      arrayHelpers.push({
                        agent: '',
                        telephoneNumber: '',
                      })
                    }}
                    sx={{
                      padding: '0!important',
                      marginBottom: '0.5rem',
                      width: '100%',
                    }}
                    testId={cypressAddButton}
                  >
                    {addRepresentative}
                  </AddRepresentativeButton>
                </>
              )}
            />

            <AvatarContainer
              title={thumbnail}
              picture={pictureState}
              handlePictureChange={setPictureState}
              handleFileChange={setFileState}
            />
          </div>
        </StyledForm>
        <ButtonContainer
          isFirstStep
          confirmButtonType="submit"
          additionalButtonStyles={{ marginTop: 'auto' }}
          formId="dealer-form"
        />
      </StyledContainer>
    </FormikProvider>
  )
}

export default DealerInfo
