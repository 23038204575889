import React from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Box from 'components/Common/Box'
import Select from 'components/Common/Select'
import ButtonContainer from 'components/Template/Creation/ButtonContainer'

import { textFiles } from 'constants/textFiles'
import { TRIM_CREATION_OPTION } from 'constants/trim'
import useTranslation from 'hooks/useTranslation'
import { SettingsInformationModel } from 'models/trimCreation'
import { TrimCreationProps } from 'utils/CarSettings/Trim/creation'

import {
  StyledContainer,
  StyledErrorMessage,
  StyledForm,
  StyledTextFieldContainer,
} from 'styles/creation'

import { FeatureBox, StyledSelect } from '../Measurement/style'

const Settings = ({
  handleBack,
  handleContinue,
  trimData,
  vehicleSelectOptions,
  updateTrimData,
}: TrimCreationProps) => {
  const { text: translation } = useTranslation(textFiles.TRIM_CREATION)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)

  const { settings } = translation

  const renderOption = (value: string): string => {
    return vehicleSelectOptions.features.find(
      (featureOp) => featureOp.value === value
    )!.name
  }

  const validationSchema = yup.object().shape({
    features: yup
      .array()
      .of(yup.string())
      .min(1, validationText.errorRequiredItems.replace('%d', 1)),
    transmission: yup.string().required(validationText.fieldRequired),
    bodyStyle: yup.string().required(validationText.fieldRequired),
    driveTrain: yup.string().required(validationText.fieldRequired),
    fuelType: yup.string().required(validationText.fieldRequired),
  })

  const formik = useFormik<SettingsInformationModel>({
    initialValues: trimData.settingsInformation,
    validationSchema,
    onSubmit: (values) => {
      updateTrimData({
        type: TRIM_CREATION_OPTION.UPDATE_SETTINGS_INFORMATION,
        payload: {
          transmission: values.transmission,
          bodyStyle: values.bodyStyle,
          driveTrain: values.driveTrain,
          fuelType: values.fuelType,
          features: values.features,
        },
      })
      handleContinue()
    },
  })

  const handleRemove = (e: React.MouseEvent, id: number) => {
    e.preventDefault()
    const newFeatures = [...formik.values.features]
    newFeatures.splice(id, 1)
    formik.setFieldValue('features', newFeatures)
  }

  return (
    <StyledContainer>
      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <StyledTextFieldContainer
            title={settings.transmission}
            contentStyle={{
              flexDirection: 'column',
            }}
          >
            <Select
              options={vehicleSelectOptions.transmissions}
              variant="outlined"
              name="transmission"
              value={formik.values.transmission}
              label={settings.transmission}
              onChange={formik.handleChange}
              sx={{ fontSize: 12, height: 40 }}
              error={
                formik.touched.transmission &&
                Boolean(formik.errors.transmission)
              }
            />
            {formik.touched.transmission && formik.errors.transmission ? (
              <StyledErrorMessage text={formik.errors.transmission} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={settings.bodyStyle}
            contentStyle={{
              flexDirection: 'column',
            }}
          >
            <Select
              options={vehicleSelectOptions.bodyStyles}
              variant="outlined"
              name="bodyStyle"
              value={formik.values.bodyStyle}
              label={settings.bodyStyle}
              onChange={formik.handleChange}
              sx={{ fontSize: 12, height: 40 }}
              error={
                formik.touched.bodyStyle && Boolean(formik.errors.bodyStyle)
              }
            />
            {formik.touched.bodyStyle && formik.errors.bodyStyle ? (
              <StyledErrorMessage text={formik.errors.bodyStyle} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={settings.driveTrain}
            contentStyle={{
              flexDirection: 'column',
            }}
          >
            <Select
              options={vehicleSelectOptions.driveTrains}
              variant="outlined"
              name="driveTrain"
              value={formik.values.driveTrain}
              label={settings.driveTrain}
              onChange={formik.handleChange}
              sx={{ fontSize: 12, height: 40 }}
              error={
                formik.touched.driveTrain && Boolean(formik.errors.driveTrain)
              }
            />
            {formik.touched.driveTrain && formik.errors.driveTrain ? (
              <StyledErrorMessage text={formik.errors.driveTrain} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={settings.fuelType}
            contentStyle={{
              flexDirection: 'column',
            }}
          >
            <Select
              options={vehicleSelectOptions.fuelTypes}
              variant="outlined"
              name="fuelType"
              value={formik.values.fuelType}
              label={settings.fuelType}
              onChange={formik.handleChange}
              sx={{ fontSize: 12, height: 40 }}
              error={formik.touched.fuelType && Boolean(formik.errors.fuelType)}
            />
            {formik.touched.fuelType && formik.errors.fuelType ? (
              <StyledErrorMessage text={formik.errors.fuelType} />
            ) : null}
          </StyledTextFieldContainer>
          <StyledTextFieldContainer
            title={settings.features}
            sx={{ width: '100%' }}
            contentStyle={{
              flexDirection: 'column',
            }}
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              sx={{
                '> div': {
                  width: '100%',
                },
              }}
            >
              <StyledSelect
                options={vehicleSelectOptions.features}
                label={settings.features}
                value={formik.values.features}
                name="features"
                multiple
                variant="outlined"
                onChange={formik.handleChange}
                error={
                  formik.touched.features && Boolean(formik.errors.features)
                }
                renderValue={(selected: unknown) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '7px',
                      width: '100%',
                      minHeight: '23px',
                      overflowY: 'auto',
                    }}
                  >
                    {(selected as string[]).map((value, id) => (
                      <FeatureBox
                        key={value}
                        label={renderOption(value) || value}
                        clickable
                        onDelete={(e: React.MouseEvent) => handleRemove(e, id)}
                        deleteIcon={
                          <ClearIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                      />
                    ))}
                  </Box>
                )}
              />
              {formik.touched.features && formik.errors.features ? (
                <StyledErrorMessage
                  text={
                    typeof formik.errors.features === 'string'
                      ? formik.errors.features
                      : ''
                  }
                />
              ) : null}
            </Box>
          </StyledTextFieldContainer>
        </div>
      </StyledForm>
      <ButtonContainer
        previousFunction={handleBack}
        confirmButtonType="submit"
        isFirstStep
        nextFunction={formik.handleSubmit}
      />
    </StyledContainer>
  )
}

export default Settings
