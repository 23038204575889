import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import EmailIcon from '@mui/icons-material/Email'
import { Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Button from 'components/Common/Button'
import ErrorMessage from 'components/Common/ErrorMessage'
import TextField from 'components/Common/TextField'
import HelpModal from 'components/General/HelpModal'

import { REMEMBER_EMAIL_KEY } from 'constants/localStorage'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import { ForgotPasswordModel } from 'models/AuthModel'

import { RESET_PASSWORD } from 'graphQL/User/mutations'

import {
  ContentContainer,
  StyledForm,
  StyledTextfieldContainer,
} from 'styles/auth'
import { colors, FONT_WEIGHT } from 'styles/theme'

type ResetPasswordResult = {
  resetUserPassword: boolean
}

type ResetPasswordVariables = {
  resetPasswordInput: {
    email: string
    origin: string
  }
}

const ForgotPasswordPage = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const savedEmail = localStorage.getItem(REMEMBER_EMAIL_KEY)?.replace(/"/g, '')
  const history = useHistory()
  const { text } = useTranslation(textFiles.FORGOT_PASSWORD)
  const { show } = useNotification()
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const [resetPassword, { loading }] = useMutation<
    ResetPasswordResult,
    ResetPasswordVariables
  >(RESET_PASSWORD, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
    },
    onError(error) {
      const errorText: string =
        error.graphQLErrors && error.graphQLErrors.length > 0
          ? error.graphQLErrors[0].message
          : error.message
      console.error('Error', errorText)
      show({
        updatedSeverity: 'error',
      })
    },
  })
  const validationSchema = yup.object({
    email: yup
      .string()
      .email(validationText.invalidEmail)
      .required(validationText.fieldRequired),
  })

  const handleGoBack = () => {
    history.goBack()
  }
  const formik = useFormik<ForgotPasswordModel>({
    initialValues: {
      email: savedEmail && savedEmail !== 'undefined' ? savedEmail : '',
    },
    validationSchema,
    onSubmit: (values) => {
      const { email } = values
      resetPassword({
        variables: {
          resetPasswordInput: {
            email,
            origin: 'web',
          },
        },
      })
    },
  })

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <ContentContainer>
      <Button
        variant="text"
        onClick={handleGoBack}
        size="large"
        sx={{
          fontWeight: `${FONT_WEIGHT.EXTRA_BOLD} !important`,
          height: '50px !important',
          paddingLeft: '0px !important',
        }}
      >
        {`< ${text.backButton}`}
      </Button>
      <StyledForm onSubmit={formik.handleSubmit}>
        <Typography variant="h4">{text.title}</Typography>
        <Typography color={colors.gray} marginTop="3rem" variant="body1">
          {text.subtitle1}
        </Typography>
        <Typography color={colors.gray} marginTop="1rem" variant="body1">
          {text.subtitle2}
        </Typography>
        <StyledTextfieldContainer>
          <TextField
            disabled={loading}
            icon={
              <EmailIcon
                color={
                  formik.touched.email && Boolean(formik.errors.email)
                    ? 'error'
                    : 'inherit'
                }
              />
            }
            fullWidth
            placeholder={text.emailPlaceholder}
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            sx={{ marginTop: '2rem' }}
          />
          {formik.touched.email && formik.errors.email ? (
            <ErrorMessage
              sx={{ alignSelf: 'flex-start', position: 'absolute' }}
              text={formik.errors.email}
            />
          ) : null}
        </StyledTextfieldContainer>

        <Button
          disabled={loading}
          fullWidth
          size="large"
          sx={{ marginTop: '2.5rem' }}
          type="submit"
        >
          {text.submitButton}
        </Button>
        <Button
          buttonType="secondary"
          size="small"
          sx={{ marginTop: '2rem' }}
          onClick={handleOpenModal}
          variant="text"
        >
          {text.askForHelpText}
        </Button>
        <HelpModal
          show={isModalOpen}
          handleClose={handleCloseModal}
          originPage="forgot-password"
        />
      </StyledForm>
    </ContentContainer>
  )
}

export default ForgotPasswordPage
