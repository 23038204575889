import {
  formControlClasses,
  inputBaseClasses,
  selectClasses,
  styled,
} from '@mui/material'

import Box from 'components/Common/Box'
import Container from 'components/Common/Container'
import TextField from 'components/Common/TextField'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '778px',
  height: '444px',
  border: `1px solid ${colors.border}`,
  borderRadius: '8px',
  backgroundColor: colors.commonWhite,
})

export const StyledForm = styled('form')({
  borderRadius: '0px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  height: '383px',
  width: '693px',

  '> div': {
    '> div': {
      width: '100%',
      padding: '0',
    },
  },
})

export const FormContent = styled(Box)({
  height: '330px',
  width: '100%',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  section: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '45% 45%',
    columnGap: '30px',
    rowGap: '20px',
  },
})

export const StyledTextFieldContainer = styled(Container)({
  width: '100%',
  padding: 0,
  borderRadius: 0,
  marginBottom: '20px',
  '> p': {
    fontSize: '14px',
  },
  [`.${formControlClasses.root}`]: {
    width: '100%',
  },
})

export const StyledTextField = styled(TextField)({
  width: '100% !important',
  height: '38px',
  borderRadius: '4px',
  fontWeight: FONT_WEIGHT.MEDIUM,
  color: colors.placeholderGray,
  div: {
    height: '100%',
    fontSize: '12px',
  },

  [`.${inputBaseClasses.input}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },
  [`.${selectClasses.select}`]: {
    fontWeight: FONT_WEIGHT.MEDIUM,
  },

  input: {
    height: '38px',
    fontSize: '12px',
    padding: '0 0 0 14px',
    '&:-internal-autofill-selected': {
      backgroundColor: colors.commonWhite,
    },
  },
})

export const RoleContent = styled(Box)({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '45% 45%',
  columnGap: '30px',
})
