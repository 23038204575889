import { styled } from '@mui/material'

import { colors } from 'styles/theme'

export const StyledButton = styled('label')({
  width: '30px',
  height: '30px',
  position: 'absolute',
  display: 'flex',
  left: '85px',
  bottom: '5%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.background,
  borderRadius: '4px',
  cursor: 'pointer',

  '& svg': {
    color: colors.gray,
  },
})
