import React from 'react'
import EmailIcon from '@mui/icons-material/Email'
import LockIcon from '@mui/icons-material/Lock'
import { Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'

import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import Checkbox from 'components/Common/Checkbox'
import ErrorMessage from 'components/Common/ErrorMessage'
import TextField from 'components/Common/TextField'
import CountryMenu from 'components/General/CountryMenu'

import { REMEMBER_EMAIL_KEY } from 'constants/localStorage'
import { routes } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useLocalStorage from 'hooks/useLocalStorage'
import useTranslation from 'hooks/useTranslation'
import useUser from 'hooks/useUser'
import { LoginModel } from 'models/AuthModel'

import { StyledForm, StyledLink, StyledTextfieldContainer } from 'styles/auth'

const Login = () => {
  const [savedEmail, setSavedEmail] = useLocalStorage<string | undefined>(
    REMEMBER_EMAIL_KEY,
    ''
  )
  const { text } = useTranslation(textFiles.LOGIN)
  const { text: validationText } = useTranslation(textFiles.VALIDATION)
  const { handleSignIn, userSignInLoading, errorMessage } = useUser()

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(validationText.invalidEmail)
      .required(validationText.fieldRequired),
    password: yup
      .string()
      .min(8, validationText.invalidPassword)
      .required(validationText.fieldRequired),
  })

  const formik = useFormik<LoginModel>({
    initialValues: {
      email: savedEmail || '',
      password: '',
      checked: Boolean(savedEmail),
    },
    validationSchema,
    onSubmit: async (values) => {
      const { email, password, checked } = values

      if (checked) {
        setSavedEmail(email)
      } else {
        setSavedEmail(undefined)
      }
      handleSignIn({ email, password })
    },
  })

  return (
    <>
      <CountryMenu />
      <Box display="flex" height="80%" width="100%">
        <StyledForm onSubmit={formik.handleSubmit} sx={{ margin: 'auto' }}>
          <Typography variant="h4">Curbo Back-Office</Typography>
          <StyledTextfieldContainer>
            <TextField
              disabled={userSignInLoading}
              icon={
                <EmailIcon
                  color={
                    formik.touched.email && Boolean(formik.errors.email)
                      ? 'error'
                      : 'inherit'
                  }
                />
              }
              fullWidth
              placeholder={text.emailPlaceholder}
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              sx={{ marginTop: '2rem' }}
            />
            {formik.touched.email && formik.errors.email ? (
              <ErrorMessage text={formik.errors.email} />
            ) : null}
          </StyledTextfieldContainer>
          <StyledTextfieldContainer>
            <TextField
              disabled={userSignInLoading}
              icon={
                <LockIcon
                  color={
                    formik.touched.password && Boolean(formik.errors.password)
                      ? 'error'
                      : 'inherit'
                  }
                />
              }
              fullWidth
              placeholder={text.passwordPlaceholder}
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              sx={{ marginTop: '2rem' }}
              type="password"
            />
            {(formik.touched.password && formik.errors.password) ||
            errorMessage ? (
              <ErrorMessage text={formik.errors.password || errorMessage!} />
            ) : null}
          </StyledTextfieldContainer>
          <Checkbox
            checked={formik.values.checked}
            disabled={userSignInLoading}
            name="checked"
            label={text.rememberEmailCheck}
            onChange={formik.handleChange}
            sx={{ margin: '1.25rem 0' }}
            testId="remember-email-checkbox"
          />
          <Button
            disabled={userSignInLoading}
            fullWidth
            size="large"
            type="submit"
            testId="sign-in-button"
          >
            {text.signInButton}
          </Button>
          <StyledLink to={routes.FORGOT_PASSWORD} testId="forgot-password-link">
            {text.forgetPasswordLink}
          </StyledLink>
        </StyledForm>
      </Box>
    </>
  )
}

export default Login
