import { gql } from '@apollo/client'

export const LIST_EMPLOYEES = gql`
  query listEmployees($input: FilterInput) {
    listData: listEmployees(input: $input) {
      count
      data {
        picture: profilePicture
        id
        name
        lastName
        email
        phone: telephoneNumber
        address
        roles {
          id
          name
        }
      }
    }
  }
`
