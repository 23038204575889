import React from 'react'
import { Typography } from '@mui/material'

import StatusTag from 'components/Common/StatusTag'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { ExtendedStatus } from 'models/services/status'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { StyledDiv } from './style'

type TooltipContentProps = {
  statusList: ExtendedStatus[]
}

const TooltipContent = ({ statusList }: TooltipContentProps) => {
  const { text } = useTranslation(textFiles.GENERAL)

  return (
    <StyledDiv>
      <div>
        <Typography
          color={colors.black}
          variant="body2"
          margin="0"
          style={{ width: '100%', fontWeight: FONT_WEIGHT.SEMI_BOLD }}
        >
          {text.status.title}
        </Typography>
        <section>
          {statusList.map((status) => (
            <span key={status.id}>
              <StatusTag
                backgroundColor={status.backgroundColor}
                color={status.textColor}
              >
                {status.name}
              </StatusTag>
              {status.description && <p>{status.description}</p>}
            </span>
          ))}
        </section>
      </div>
    </StyledDiv>
  )
}

export default TooltipContent
