import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  InputBase,
  inputBaseClasses,
  InputLabel,
  MenuItem,
  menuItemClasses,
  popoverClasses,
  Select,
  styled,
} from '@mui/material'

import { colors } from 'styles/theme'

export const StyledSelect = styled(Select)({
  color: colors.gray,
  height: '38px',
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: '0.14px',
  [`.${popoverClasses.paper}`]: {
    zIndex: 1500,
  },
})

export const StyledInputBase = styled(InputBase)(
  ({ startAdornment, error }) => ({
    border: `1px solid ${error ? colors.lightRed : colors.lightGray}`,
    borderRadius: '4px',
    [`& .${inputBaseClasses.input}`]: {
      paddingLeft: startAdornment ? '10px' : '16px',
    },
    ':hover': {
      border: `1px solid ${colors.blue}`,
    },
    [`&.${inputBaseClasses.focused}`]: {
      border: `1px solid ${colors.blue}`,
    },
  })
)

export const StyledMenuItem = styled(MenuItem)({
  color: `${colors.gray}`,
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: '0.12px',
  zIndex: 1600,

  ':hover': {
    backgroundColor: `${colors.background}`,
    color: `${colors.altBlack}`,
  },

  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: `${colors.cyan}`,
    color: `${colors.darkBlue}`,
  },
})

export const StyledInputLabel = styled(InputLabel)({
  color: `${colors.gray}`,
  fontSize: '12px',
  fontWeight: 500,
  letterSpacing: '0.12px',
  top: '-5px',
  opacity: '0.5',
})

export const ArrowIcon = styled(KeyboardArrowDownIcon)({
  color: `${colors.lightGray} !important`,
})
