import React from 'react'
import { Typography } from '@mui/material'
import { isSameDay } from 'date-fns'

import { CalendarRangeType } from 'models/date'
import { formatDateLocalizedTime } from 'utils/date'

import { StyledRowBox } from './style'

const DateRangeRow = ({ fromDate, toDate }: CalendarRangeType) => {
  const formatDate = (date: Date | null) => {
    return date ? formatDateLocalizedTime(date, 'dd/MM/yyyy') : 'n/a'
  }

  return (
    <StyledRowBox display="flex">
      {toDate && fromDate && isSameDay(fromDate, toDate) ? (
        <Typography
          variant="body2"
          sx={{ marginLeft: '5px', marginRight: '5px' }}
        >
          {formatDateLocalizedTime(toDate, 'PPP')}
        </Typography>
      ) : (
        <>
          <Typography variant="body2">{formatDate(fromDate)}</Typography>
          <Typography
            variant="body2"
            color="primary"
            sx={{ marginLeft: '5px', marginRight: '5px' }}
          >
            -
          </Typography>
          <Typography variant="body2">{formatDate(toDate)}</Typography>
        </>
      )}
    </StyledRowBox>
  )
}
export default DateRangeRow
