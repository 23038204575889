import { gql } from '@apollo/client'

export const LIST_TEMPLATES = gql`
  query listTemplates($input: FilterInput) {
    listTemplates(input: $input) {
      count
      data {
        id
        name
        provider {
          name
        }
        visible
        status
        steps {
          id
        }
        version
        createdAt
      }
    }
  }
`

export const LIST_PROVIDERS = gql`
  query listProviders {
    data: getProviders {
      id
      name
      slug
    }
  }
`
