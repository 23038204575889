import React, { useCallback, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { Typography } from '@mui/material'

import { BackContainer } from 'components/Common/BackContainer'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import Title from 'components/Common/Title'
import Stepper from 'components/General/Stepper'

import { DUPLICATE_EMAIL_ERROR } from 'constants/error'
import { GENERAL_SETTINGS_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import { USER_CREATION_OPTION } from 'constants/UserManagement/creation'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import { Option } from 'models/Select'
import {
  BaseIdEntity,
  FilterInputVariable,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { CreateUserInput } from 'models/services/General/UserManagement/creation'
import {
  RolesOption,
  UserCreationAction,
  UserCreationModel,
} from 'models/userCreation'
import userCreationReducer from 'reducers/User/userCreationReducer'
import { validateGraphQLErrorCode } from 'utils/error'
import {
  createStateStepperItems,
  USER_CREATION_STEPS as steps,
} from 'utils/User/creation'

import {
  GET_ASSIGNABLE_AREAS,
  GET_ASSIGNABLE_ROLES,
} from 'graphQL/UserManagement/Common/queries'
import { CREATE_USER } from 'graphQL/UserManagement/Creation/mutations'

import { StyledBody, StyledBox } from 'styles/operation/creation'
import { colors } from 'styles/theme'

const initialData: UserCreationModel = {
  userInformation: {
    name: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    address: '',
  },
  userRoles: {
    roles: [],
    options: [],
  },
}

const UserCreation = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [userData, dispatch] = useReducer(userCreationReducer, initialData)
  const [roles, setRoles] = useState<RolesOption[]>([])
  const [areas, setAreas] = useState<Option[]>([])
  const history = useHistory()

  const { text } = useTranslation(textFiles.USER_MANAGEMENT_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { show } = useNotification()

  const { title, processTitle, processDescription } = text

  const stepperItems = createStateStepperItems(text.stepper)

  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }

  const handleGoListing = () => {
    history.push(GENERAL_SETTINGS_SUB_ROUTES.USER_MANAGEMENT_LISTING)
  }

  const updateUserData = (action: UserCreationAction) => {
    dispatch(action)
  }

  const { loading: rolesLoading } = useQuery<
    GenericData<RolesOption[]>,
    FilterInputVariable
  >(GET_ASSIGNABLE_ROLES, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setRoles(response.data)
      updateUserData({
        type: USER_CREATION_OPTION.UPDATE_USER_ROLES,
        payload: {
          roles: [{ area: '', role: '' }],
          options: [[...response.data]],
        },
      })
    },
  })

  const { loading: areasLoading } = useQuery<
    GenericData<Option[]>,
    FilterInputVariable
  >(GET_ASSIGNABLE_AREAS, {
    variables: {
      input: {
        sort: {
          name: 'asc',
        },
      },
    },
    onCompleted(response) {
      setAreas(response.data)
    },
  })

  const [createUser, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateUserInput>
  >(CREATE_USER, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
      handleGoListing()
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_EMAIL_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateEmail,
        })
      } else
        show({
          updatedSeverity: 'error',
        })
    },
  })

  const handleSubmit = useCallback(async () => {
    const { userInformation, userRoles } = userData

    const { name, phoneNumber, lastName, address, email, password } =
      userInformation

    createUser({
      variables: {
        input: {
          name,
          lastName,
          telephoneNumber: phoneNumber || undefined,
          address: address || undefined,
          email,
          password,
          roles: userRoles.roles.map((role) => role.role),
          deviceTokens: [],
        },
      },
    })
  }, [createUser, userData])

  if (rolesLoading || areasLoading)
    return <LoadingAnimation showAnimation={rolesLoading || areasLoading} />

  return (
    <StyledBox>
      <BackContainer />
      <Title title={title} />
      <StyledBody>
        <Typography variant="h3">{processTitle}</Typography>
        <Typography variant="body2" fontSize="18px" color={colors.subtitleGray}>
          {processDescription}
        </Typography>
        <Stepper currentStep={currentStep} stepperItems={stepperItems} />
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          updateUserData,
          userData,
          handleSubmit,
          submitLoading,
          roleOptions: roles,
          areaOptions: areas,
        })}
      </StyledBody>
    </StyledBox>
  )
}

export default UserCreation
