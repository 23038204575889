import React from 'react'

import BarChart, { BarStructureType } from 'components/Common/Charts/BarChart'
import Container from 'components/Common/Container'
import TimeSensitivePopper from 'components/General/TimeSensitivePopper'

import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { GraphApprovementType } from 'models/services/inspection/dashboard'

import { BREAK_POINTS } from 'styles/breakpoints'

type InspectionsBasedOnSourceGraphProps = {
  structure: BarStructureType[]
  data: GraphApprovementType[]
  loading?: boolean
  containerHeight?: number
  height?: number
  width?: string
}

const InspectionsBasedOnSourceGraph = ({
  structure,
  data,
  loading,
  containerHeight,
  height,
  width,
}: InspectionsBasedOnSourceGraphProps) => {
  const { text } = useTranslation(textFiles.INSPECTION_DASHBOARD)

  return (
    <Container
      height={containerHeight || 437}
      width="44%"
      description={text.inspections.barChartGraphs.basedOnSource.description}
      title={text.inspections.barChartGraphs.basedOnSource.title}
      extraText={<TimeSensitivePopper />}
      sx={{
        marginTop: '1rem',
        [`@media only screen and (min-width: ${BREAK_POINTS.XL}px)`]: {
          marginTop: '0rem',
        },
      }}
      loading={loading}
      isEmpty={!data.length}
    >
      <BarChart
        data={data}
        barsStructure={structure}
        layout="horizontal"
        size={{ width: width || '80%', height: height || 390 }}
        hasLegend
      />
    </Container>
  )
}

export default InspectionsBasedOnSourceGraph
