import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
  ClickAwayListener,
  inputBaseClasses,
  outlinedInputClasses,
  Typography,
} from '@mui/material'
import MuiPopper from '@mui/material/Popper'
import { SelectChangeEvent } from '@mui/material/Select'

import Autocomplete, { AutocompleteItem } from 'components/Common/Autocomplete'
import Box from 'components/Common/Box'
import Button from 'components/Common/Button'
import Container from 'components/Common/Container'
import ErrorMessage from 'components/Common/ErrorMessage'
import FilterButton from 'components/Common/FilterButton'
import Select from 'components/Common/Select'
import FilterCard, { Filter } from 'components/Inspection/Dashboard/FilterCard'

import {
  FilteringOption,
  getFilteringOptions,
} from 'constants/Inspection/filterByCriteria'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'
import { CurboSpot } from 'models/curboSpot'
import {
  FiltersByCriteriaCategoryType,
  FiltersByCriteriaTranslationType,
} from 'models/filtersByCriteria'
import { ListingFilterType } from 'models/services/base'
import { Provider } from 'models/services/provider'
import { ExtendedStatus } from 'models/services/status'
import { formatFiltersTypes } from 'utils/filters'

import { boxShadow, colors } from 'styles/theme'

import {
  StyledButtonsContainer,
  StyledFiltersBackground,
  StyledFiltersContainer,
  StyledForm,
  StyledSelectorContainer,
  StyledTextField,
} from './style'

export type staticFieldsType = {
  /**
   * This type refers to the fields that have values we already know, such as Curbo Spot, Province, Status (pending, approved, rejected).
   * This type serves with keyof operator to specify the type for fieldOrEntry on a dinamic array value below
   */
  province: string
  curboSpot: string
  status: string
  provider: string
}

export type FilterByCriteriaProps = {
  /**
   * Array containing each of the filters selected by the user
   */
  filtersList: Filter[]
  /**
   * Function to update the value of the filtersList stored in state
   */
  handleFiltersList: (
    newFiltersList: Filter[],
    filterInput: ListingFilterType
  ) => void
  /*
   * Text file to get filters
   */
  file: string
  /*
   * Text file to get filters
   */
  filterTypes?: FiltersByCriteriaCategoryType
  /**
   *  Object containing the variable to send to the query
   */
  filterInput: ListingFilterType

  statusList?: ExtendedStatus[]

  providerList?: Provider[]

  curboSpots?: CurboSpot[]

  options?: Record<string, FilteringOption[]>

  staticFields?: string[]

  handleFieldEntrySelect?: (fieldEntry: string) => void

  loadingSelect?: boolean
}

const FilterByCriteria = ({
  filtersList,
  handleFiltersList,
  file,
  filterTypes,
  filterInput,
  statusList,
  providerList,
  curboSpots,
  options,
  loadingSelect = false,
  handleFieldEntrySelect,
  staticFields = ['province', 'curboSpot', 'status', 'provider'],
}: FilterByCriteriaProps) => {
  const { text: filtersTextFile } = useTranslation(textFiles.DOWNLOAD_REPORT)
  const {
    text: { filterByCriteria: generalText },
  } = useTranslation(textFiles.GENERAL)
  const {
    filtersByCriteria,
  }: { filtersByCriteria: FiltersByCriteriaTranslationType } =
    useTranslation(file).text

  const [fieldOrEntry, setFieldOrEntry] = useState<string>('')
  const [newFiltersList, setNewFiltersList] = useState<Filter[]>([
    ...filtersList,
  ])
  const [newFilterInput, setNewFilterInput] = useState<ListingFilterType>({
    ...filterInput,
  })
  const [operator, setOperator] = useState<string>('')
  const [valueToCompare, setValueToCompare] = useState<string | number>('')
  const [selectAutoComplete, setSelectAutoComplete] =
    useState<AutocompleteItem>()
  const [hasError, setHasError] = useState<boolean>(false)
  const [editing, setEditing] = useState<boolean>(false)

  const {
    fieldOrEntryOptions,
    operatorOptions,
    provinceOptions,
    curboSpotOptions,
    providerOptions,
    statusOptions,
    filtersButton,
  } = getFilteringOptions(
    formatFiltersTypes(filtersByCriteria, filterTypes),
    statusList,
    curboSpots,
    providerList
  )

  const staticFieldsMap = {
    province: provinceOptions,
    curboSpot: curboSpotOptions,
    provider: providerOptions,
    status: statusOptions,
    ...options,
  }
  // manage popper
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setNewFiltersList(filtersList)
    setAnchorEl(anchorEl || event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setNewFilterInput({ ...filterInput })
  }

  const handleStartEditing = () => {
    setEditing(true)
  }

  const handleStopEditing = () => {
    setEditing(false)
  }

  const handleFieldChange = (event: SelectChangeEvent<unknown>) => {
    if (handleFieldEntrySelect) {
      handleFieldEntrySelect(event.target.value as string)
    }
    setFieldOrEntry(event.target.value as string)
  }
  const handleOperatorChange = (event: SelectChangeEvent<unknown>) => {
    setOperator(event.target.value as string)
  }

  const handleAutoCompleteChange = (value: AutocompleteItem) => {
    setSelectAutoComplete(value)
    setValueToCompare(value.id as string)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueToCompare(event.target.value)
  }
  const handleNewFiltersList = (
    newFilters: Filter[],
    changedInputFilter: ListingFilterType
  ) => {
    setNewFiltersList(newFilters)
    setNewFilterInput(changedInputFilter)
  }

  /**
   * Function called to add Filters.
   * First checks for duplicates inside the filtersList
   * Then checks if all of the fields are filled before pushing
   */
  const handleAddFilter = () => {
    const formattedValueToCompare =
      fieldOrEntryOptions.find((field) => field.value === fieldOrEntry)
        ?.type === 'number'
        ? parseInt(valueToCompare as string, 10)
        : valueToCompare

    const newFilter = {
      fieldOrEntry,
      operator,
      valueToCompare: formattedValueToCompare,
    } as Filter

    if (
      !newFiltersList.find(
        (oldFilter) =>
          oldFilter.fieldOrEntry === newFilter.fieldOrEntry &&
          oldFilter.operator === newFilter.operator &&
          oldFilter.valueToCompare === newFilter.valueToCompare
      ) &&
      newFilter.fieldOrEntry &&
      newFilter.operator &&
      newFilter.valueToCompare !== null
    ) {
      const newState = [...newFiltersList, newFilter]

      setNewFiltersList(newState)

      setHasError(false)

      const key = `${fieldOrEntry}_${operator}`

      setNewFilterInput((prevFilters) => {
        if (key in prevFilters) {
          const value = [...prevFilters[key], formattedValueToCompare]
          return {
            ...prevFilters,
            [key]: value,
          }
        }
        return {
          ...prevFilters,
          [key]: [formattedValueToCompare],
        }
      })

      setFieldOrEntry('')
      setOperator('')
      setValueToCompare('')
    } else setHasError(true)
  }

  const handleClear = () => {
    setFieldOrEntry('')
    setOperator('')
    setValueToCompare('')
    setSelectAutoComplete(undefined)
  }

  const handleApplyFilters = () => {
    handleFiltersList(newFiltersList, newFilterInput)
    setOpen(false)
  }

  useEffect(() => {
    setNewFilterInput(filterInput)
    setNewFiltersList(filtersList)
    setSelectAutoComplete(undefined)
  }, [filterInput, filtersList])

  return (
    <>
      <FilterButton
        id="fields-filter-popper"
        onClick={handleClick}
        text={filtersButton}
        icon={<FilterListIcon />}
        testId="filters-button"
      />
      <MuiPopper
        id="fields-filter-popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        sx={{
          zIndex: 1201,
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            if (editing) return
            handleClose()
          }}
        >
          <Box marginTop="5px">
            <Container sx={{ width: '600px', boxShadow }}>
              <Typography variant="body1">
                {filtersTextFile.titles.addFilter}
              </Typography>
              <StyledForm>
                <StyledSelectorContainer>
                  <Select
                    options={fieldOrEntryOptions}
                    label={generalText.fieldLabel}
                    sx={{ width: '400px' }}
                    name="fieldOrEntry"
                    value={fieldOrEntry}
                    onChange={handleFieldChange}
                    MenuProps={{
                      disableScrollLock: true,
                      TransitionProps: {
                        onExited: handleStopEditing,
                      },
                    }}
                    onOpen={handleStartEditing}
                  />
                  <Select
                    options={operatorOptions}
                    label={generalText.operatorLabel}
                    sx={{ width: '400px' }}
                    name="operator"
                    value={operator}
                    onChange={handleOperatorChange}
                    MenuProps={{
                      disableScrollLock: true,
                      TransitionProps: {
                        onExited: handleStopEditing,
                      },
                    }}
                    onOpen={handleStartEditing}
                  />
                  {staticFields.find(
                    (staticField) => staticField === fieldOrEntry
                  ) ? (
                    <Autocomplete
                      width="230px"
                      inputSx={{
                        paddingLeft: '0px',
                        [`& .${inputBaseClasses.root}`]: {
                          color: colors.commonBlack,
                        },
                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                          border: 'none',
                        },
                      }}
                      options={staticFieldsMap[
                        fieldOrEntry as keyof staticFieldsType
                      ].map((option) => {
                        return {
                          name: option.name,
                          id: option.value,
                        }
                      })}
                      value={selectAutoComplete}
                      placeholder={
                        loadingSelect
                          ? generalText.loading
                          : generalText.valueLabel
                      }
                      onChangeHandler={handleAutoCompleteChange}
                    />
                  ) : (
                    <StyledTextField
                      placeholder={generalText.valueLabel}
                      name="valueToCompare"
                      type={
                        fieldOrEntryOptions.find(
                          (field) => field.value === fieldOrEntry
                        )?.type === 'number'
                          ? 'number'
                          : undefined
                      }
                      value={valueToCompare}
                      onChange={handleInputChange}
                    />
                  )}
                </StyledSelectorContainer>
                {hasError ? (
                  <ErrorMessage
                    text={filtersTextFile.missingValue.label}
                    sx={{ margin: '20px 0px' }}
                  />
                ) : null}
                <StyledButtonsContainer>
                  <Button
                    disabled={
                      fieldOrEntry === '' ||
                      operator === '' ||
                      valueToCompare === ''
                    }
                    startIcon={<AddIcon sx={{ fontSize: '24px !important' }} />}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleAddFilter()
                    }}
                  >
                    {filtersTextFile.buttons.addFilter}
                  </Button>
                  <Button
                    buttonType="info"
                    type="reset"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleClear()
                    }}
                  >
                    {filtersTextFile.buttons.clear}
                  </Button>
                </StyledButtonsContainer>
              </StyledForm>

              {/* Filters */}
              <StyledFiltersContainer>
                <Typography variant="body1">
                  {filtersTextFile.titles.currentFilters}
                </Typography>
                <StyledFiltersBackground>
                  {newFiltersList
                    ? newFiltersList.map((filter, filterIdx) => (
                        <FilterCard
                          key={`${filter.fieldOrEntry}_${filter.operator}:${filter.valueToCompare}`}
                          filterKey={`${filter.fieldOrEntry}_${filter.operator}:${filter.valueToCompare}`}
                          file={file}
                          filter={filter}
                          filterIdx={filterIdx}
                          newFiltersList={newFiltersList}
                          handleNewFiltersList={handleNewFiltersList}
                          filterInput={newFilterInput}
                          statusList={statusList}
                          curboSpots={curboSpots}
                          providerList={providerList}
                          options={options}
                        />
                      ))
                    : null}
                </StyledFiltersBackground>
                <StyledButtonsContainer>
                  <Button
                    startIcon={
                      <CheckIcon sx={{ fontSize: '24px !important' }} />
                    }
                    onClick={handleApplyFilters}
                  >
                    {filtersTextFile.buttons.applyFilters}
                  </Button>
                  <Button buttonType="info" onClick={handleClose}>
                    {filtersTextFile.buttons.cancel}
                  </Button>
                </StyledButtonsContainer>
              </StyledFiltersContainer>
            </Container>
          </Box>
        </ClickAwayListener>
      </MuiPopper>
    </>
  )
}

export default FilterByCriteria
