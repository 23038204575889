/**
 *
 * This is mockup data, used to simulate real usage:
 * You need an array of objects, each with a name property and the other 2 values you want to use. Example: uv, pv
 * These property names are used on the bar chart elements as props
 */

export const barChartData = [
  {
    name: 'Jan',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Feb',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Mar',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Apr',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'May',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Jun',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Jul',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: 'Aug',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Sept',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Oct',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Nov',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Dec',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
]

export const DesiredBarsToRenderOnChart = [
  {
    name: 'Test 1',
    stackId: '1',
    dataKey: 'uv',
  },
  {
    name: 'Test 2',
    stackId: '1',
    dataKey: 'pv',
  },
  {
    name: 'Test 3',
    stackId: '1',
    dataKey: 'amt',
  },
]
