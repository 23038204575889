import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import { styled } from '@mui/material'

import Box from 'components/Common/Box'

import { colors, FONT_WEIGHT } from 'styles/theme'

export const StyledContainer = styled(Box)({
  backgroundColor: colors.commonWhite,
  borderRadius: '10px',
  height: '422px',
  textAlign: 'center',
  padding: '2rem',
  width: 'auto',
})

export const StyledContent = styled(Box)({
  height: '100%',
  overflowY: 'auto',
  width: '100%',
})

export const StyledIcon = styled(SupervisorAccountIcon)({
  fontSize: '68px',
})

export const StyledList = styled('ol')({
  counterReset: 'roles-counter',
  listStyle: 'none',
  textAlign: 'start',

  li: {
    counterIncrement: 'roles-counter',
    marginBottom: '23px',
  },

  'li::before': {
    background: colors.paleGray,
    borderRadius: '4px',
    color: colors.gray,
    content: 'counter(roles-counter)',
    display: 'inline-block',
    fontWeight: FONT_WEIGHT.BOLD,
    height: '30px',
    lineHeight: '30px',
    marginRight: '1.5rem',
    textAlign: 'center',
    width: '30px',
  },
})
