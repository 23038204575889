import { styled, Typography } from '@mui/material'

import Box from 'components/Common/Box'
import Link from 'components/Common/Link'

import { boxShadow, colors, FONT_WEIGHT } from 'styles/theme'

export const StyledLink = styled(Link)({
  color: colors.blue,
  fontSize: '12px',
  textDecoration: 'underline',
})

export const CardContainer = styled(Box)({
  borderRadius: '6px',
  boxShadow,
  display: 'flex',
  flexDirection: 'column',
  width: 256,
  height: 283,
  padding: '0.5rem',
})

export const ItemContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  marginTop: '5px',
})

export const ItemText = styled(Typography)({
  color: colors.altBlack,
  fontSize: '12px',
  fontWeight: FONT_WEIGHT.REGULAR,
  marginLeft: '0.5rem',
})

export const CarTitle = styled(Typography)({
  color: colors.black,
  fontWeight: FONT_WEIGHT.BOLD,
  marginTop: '0.5rem',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})
