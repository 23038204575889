import React, { useState } from 'react'

import { textFiles } from 'constants/textFiles'
import { SPACING_OPTION, TRIM_CREATION_OPTION } from 'constants/trim'
import useTranslation from 'hooks/useTranslation'
import { SpacingInformationModel } from 'models/trimCreation'
import { TrimCreationProps } from 'utils/CarSettings/Trim/creation'

import Skeleton, { ExtendedSliderProps } from '../Common/Skeleton'

const Spacing = ({
  handleBack,
  handleContinue,
  trimData,
  updateTrimData,
}: TrimCreationProps) => {
  const { spacingInformation } = trimData
  const [info, setInfo] = useState<SpacingInformationModel>(spacingInformation)
  const { text: translation } = useTranslation(textFiles.TRIM_CREATION)

  const { spacing } = translation

  const handleBlur = (map: SPACING_OPTION) => {
    if (info[map] > 10) {
      setInfo({ ...info, [map]: 10 })
    }
    if (info[map] < 1) {
      setInfo({ ...info, [map]: 1 })
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    map: SPACING_OPTION
  ) => {
    setInfo({
      ...info,
      [map]: Number(event.target.value),
    })
  }

  const handleSubmit = () => {
    updateTrimData({
      type: TRIM_CREATION_OPTION.UPDATE_SPACING_INFORMATION,
      payload: {
        frontLegRoom: info.frontLegRoom,
        backLegRoom: info.backLegRoom,
        frontHeadRoom: info.frontHeadRoom,
        backHeadRoom: info.backHeadRoom,
        engineDisplacement: info.engineDisplacement,
        cargoCapacity: info.cargoCapacity,
        cargoWeightCapacity: info.cargoWeightCapacity,
      },
    })
    handleContinue()
  }

  const SLIDER_ITEMS: ExtendedSliderProps[] = [
    {
      id: 1,
      title: spacing.frontLegRoom,
      name: SPACING_OPTION.FRONT_LEG_ROOM,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, frontLegRoom: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, SPACING_OPTION.FRONT_LEG_ROOM),
      handleBlur: () => handleBlur(SPACING_OPTION.FRONT_LEG_ROOM),
    },
    {
      id: 2,
      title: spacing.backLegRoom,
      name: SPACING_OPTION.BACK_LEG_ROOM,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, backLegRoom: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, SPACING_OPTION.BACK_LEG_ROOM),
      handleBlur: () => handleBlur(SPACING_OPTION.BACK_LEG_ROOM),
    },
    {
      id: 3,
      title: spacing.frontHeadRoom,
      name: SPACING_OPTION.FRONT_HEAD_ROOM,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, frontHeadRoom: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, SPACING_OPTION.FRONT_HEAD_ROOM),
      handleBlur: () => handleBlur(SPACING_OPTION.FRONT_HEAD_ROOM),
    },
    {
      id: 4,
      title: spacing.backHeadRoom,
      name: SPACING_OPTION.BACK_HEAD_ROOM,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, backHeadRoom: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, SPACING_OPTION.BACK_HEAD_ROOM),
      handleBlur: () => handleBlur(SPACING_OPTION.BACK_HEAD_ROOM),
    },
    {
      id: 5,
      title: spacing.engineDisplacement,
      name: SPACING_OPTION.ENGINE_DISPLACEMENT,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, engineDisplacement: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, SPACING_OPTION.ENGINE_DISPLACEMENT),
      handleBlur: () => handleBlur(SPACING_OPTION.ENGINE_DISPLACEMENT),
      max: 10,
    },
    {
      id: 6,
      title: spacing.cargoCapacity,
      name: SPACING_OPTION.CARGO_DISPLACEMENT,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, cargoCapacity: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, SPACING_OPTION.CARGO_DISPLACEMENT),
      handleBlur: () => handleBlur(SPACING_OPTION.CARGO_DISPLACEMENT),
      max: 10,
    },
    {
      id: 7,
      title: spacing.cargoWeightCapacity,
      name: SPACING_OPTION.CARGO_WEIGHT_DISPLACEMENT,
      info,
      setInfo: (event: Event, newValue: number | number[]) =>
        setInfo({ ...info, cargoWeightCapacity: newValue as number }),
      handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => handleInputChange(event, SPACING_OPTION.CARGO_WEIGHT_DISPLACEMENT),
      handleBlur: () => handleBlur(SPACING_OPTION.CARGO_WEIGHT_DISPLACEMENT),
    },
  ]
  return (
    <Skeleton
      sliderItems={SLIDER_ITEMS}
      handleBack={handleBack}
      handleNext={handleSubmit}
    />
  )
}

export default Spacing
