import { BODY_STYLE_CREATION_OPTION } from 'constants/bodyStyle'
import {
  BodyStyleCreationAction,
  BodyStyleCreationModel,
} from 'models/bodyStyleCreation'

const bodyStyleCreationReducer = (
  state: BodyStyleCreationModel,
  action: BodyStyleCreationAction
): BodyStyleCreationModel => {
  switch (action.type) {
    case BODY_STYLE_CREATION_OPTION.UPDATE_BODY_STYLE_INFORMATION:
      return { ...state, bodyStyleInformation: action.payload }

    default:
      return state
  }
}

export default bodyStyleCreationReducer
