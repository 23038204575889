import React, { ChangeEvent } from 'react'
import { PlaylistAddCheck } from '@mui/icons-material'
import { Typography } from '@mui/material'

import Box from 'components/Common/Box'
import Container from 'components/Common/Container'
import LoadingAnimation from 'components/Common/LoadingAnimation'
import { StyledSquareIcon, StyledStripe } from 'components/Motives/Stripe/style'

import { BaseEntity } from 'models/services/base'

import { colors } from 'styles/theme'

import { StyledBox, StyledPagination } from './style'

export type PaginationBaseEntity = {
  extraContent?: string
} & BaseEntity

type Props = {
  actualPage: number
  handlePageChange: (event: React.ChangeEvent<unknown>, newPage: number) => void
  pageCount: number
  totalCount: number
  items: PaginationBaseEntity[]
  loading: boolean
  pageSize: number
  title: string
  description: string
  countText: string
}

/**
 * A box containing a list of paginated items usually belonging in the detail
 * of an entity
 */
const PaginationList = ({
  actualPage,
  pageCount,
  totalCount,
  handlePageChange,
  items,
  loading,
  pageSize,
  title,
  description,
  countText,
}: Props) => {
  return (
    <Container
      title={title}
      description={description}
      width="363px"
      padding="0px !important"
      paddingTop="1rem !important"
    >
      <StyledBox>
        <Box display="flex" flexDirection="column" justifyContent="flex-start">
          <Box display="flex" justifyContent="flex-start" paddingY="0.2rem">
            <PlaylistAddCheck fontSize="medium" sx={{ color: colors.gray }} />
            <Typography variant="body2" color={colors.gray} marginLeft="0.5rem">
              {totalCount} {countText}
            </Typography>
          </Box>
          {loading ? (
            <LoadingAnimation
              showAnimation={loading}
              styles={{ height: '280px' }}
            />
          ) : (
            <Box sx={{ overflowY: 'auto', height: '280px' }}>
              {items &&
                items.map((item) => (
                  <StyledStripe key={item.id} sx={{ marginY: '2px' }}>
                    <section>
                      <StyledSquareIcon />
                      <Typography variant="body2">{`${item.name}${
                        item.extraContent ? ` - ${item.extraContent}` : ''
                      }`}</Typography>
                    </section>
                  </StyledStripe>
                ))}
            </Box>
          )}
          {totalCount > pageSize ? (
            <StyledPagination
              count={pageCount}
              page={actualPage}
              variant="text"
              size="small"
              shape="rounded"
              onChange={handlePageChange}
              siblingCount={0}
            />
          ) : null}
        </Box>
      </StyledBox>
    </Container>
  )
}

export default PaginationList
