import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import {
  cypressNextStepButton,
  cypressPreviousStepButton,
  cypressSubmitButton,
} from 'constants/cypress'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { StyledButton, StyledButtonContainer } from './style'

type ButtonContainerProps = {
  isFirstStep?: boolean
  isLastStep?: boolean
  previousFunction?: () => void
  nextFunction?: () => void
  confirmButtonType?: 'button' | 'submit' | 'reset'
  loading?: boolean
  width?: string | number
  additionalContainerStyles?: React.CSSProperties
}

const ButtonContainer = ({
  isFirstStep = false,
  isLastStep = false,
  previousFunction,
  nextFunction,
  confirmButtonType = 'button',
  loading = false,
  width,
  additionalContainerStyles,
}: ButtonContainerProps) => {
  const { text } = useTranslation(textFiles.TEMPLATE_CREATION)
  const { buttons } = text
  return (
    <StyledButtonContainer
      component="section"
      width={width}
      sx={{ ...additionalContainerStyles }}
    >
      {isFirstStep ? null : (
        <StyledButton
          variant="text"
          onClick={previousFunction}
          data-cy={cypressPreviousStepButton}
          disabled={loading}
        >
          {buttons.previous}
        </StyledButton>
      )}
      <StyledButton
        disabled={loading}
        startIcon={<ArrowForwardIosIcon />}
        size="small"
        type={confirmButtonType}
        role="button"
        onClick={nextFunction}
        data-cy={isLastStep ? cypressSubmitButton : cypressNextStepButton}
      >
        {isLastStep ? buttons.finishCreation : buttons.next}
      </StyledButton>
    </StyledButtonContainer>
  )
}

export default ButtonContainer
