import ColorInfo from 'components/CarSettings/Color/Creation/ColorInfo'
import ColorSummary from 'components/CarSettings/Color/Creation/Summary'
import { StepperModel } from 'components/General/Stepper'

import { ColorCreationModel } from 'models/colorCreation'
import { SimpleStepperTextType } from 'models/text/General'

export type ColorCreationProps = {
  handleBack: () => void
  handleContinue: () => void
  handleSubmit: () => void
  submitLoading: boolean
  colorData: ColorCreationModel
  updateColorData: (newColor: ColorCreationModel) => void
}

export const COLOR_CREATION_STEPS: Array<React.FC<ColorCreationProps>> = [
  ColorInfo,
  ColorSummary,
]

export const createColorStepperItems = (
  text: SimpleStepperTextType
): StepperModel[] => {
  const stepperItems: StepperModel[] = [
    {
      label: text.firstStep,
    },
    { label: text.secondStep },
  ]

  return stepperItems
}
