import React, { useCallback, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { uploadImageService } from 'services/uploadImage'

import { BackContainer } from 'components/Common/BackContainer'
import BodyContainerWithHeader from 'components/Common/BodyContainerWithHeader'

import { DUPLICATE_KEY_ERROR } from 'constants/error'
import { CAR_SETTINGS_SUB_ROUTES } from 'constants/routes'
import { textFiles } from 'constants/textFiles'
import useNotification from 'hooks/useNotification'
import useTranslation from 'hooks/useTranslation'
import { Category } from 'models/car'
import {
  CategoryCreationAction,
  CategoryCreationModel,
} from 'models/categoryCreation'
import {
  BaseIdEntity,
  GenericData,
  GenericInputVariable,
} from 'models/services/base'
import { CreateCarSettingPictureInputType } from 'models/services/CarSettings/creation'
import { PublicationStatus } from 'models/status'
import categoryCreationReducer from 'reducers/categoryCreationReducer'
import {
  createCategoryStepperItems,
  INITIAL_CATEGORY_CREATION_STEPS as steps,
} from 'utils/CarSettings/Category/creation'
import { validateGraphQLErrorCode } from 'utils/error'
import { getTranslationsFilteredByLanguage } from 'utils/translation'

import { CREATE_CATEGORY } from 'graphQL/Operations/Category/Creation/mutations'

import { StyledBox } from 'styles/operation/creation'

const initialCategoryInformationState: Category = {
  name: '',
  picture: '',
  pictureFile: null,
  status: PublicationStatus.PUBLISHED,
  translations: [],
}

const initialData: CategoryCreationModel = {
  categoryInformation: initialCategoryInformationState,
}

const CategoryCreationPage = () => {
  const [currentStep, setCurrentStep] = useState<number>(0)

  const [categoryData, dispatch] = useReducer(
    categoryCreationReducer,
    initialData
  )

  const { categoryInformation } = categoryData
  const { translations } = categoryInformation

  const history = useHistory()
  const { show } = useNotification()

  const { text } = useTranslation(textFiles.CATEGORY_CREATION)
  const { text: generalText } = useTranslation(textFiles.GENERAL)
  const { stepper } = text

  const stepperItems = createCategoryStepperItems(stepper)

  const updateCategoryData = (action: CategoryCreationAction) => {
    dispatch(action)
  }

  const handleBack = () => {
    if (currentStep - 1 >= 0) setCurrentStep((step) => step - 1)
  }
  const handleContinue = () => {
    setCurrentStep((step) => step + 1)
  }

  const [createCategory, { loading: submitLoading }] = useMutation<
    GenericData<BaseIdEntity>,
    GenericInputVariable<CreateCarSettingPictureInputType>
  >(CREATE_CATEGORY, {
    onCompleted() {
      show({
        updatedSeverity: 'success',
      })
      history.push(CAR_SETTINGS_SUB_ROUTES.CATEGORY_LISTING)
    },
    onError(error) {
      const { errorExists } = validateGraphQLErrorCode(
        error,
        DUPLICATE_KEY_ERROR
      )
      if (errorExists) {
        show({
          updatedSeverity: 'error',
          message: generalText.notificationText.duplicateName,
        })
      } else
        show({
          updatedSeverity: 'error',
        })
    },
  })

  const handleSubmit = useCallback(async () => {
    const { valueEn, valueEs } = getTranslationsFilteredByLanguage(translations)

    const { pictureFile } = categoryInformation

    try {
      const response = pictureFile
        ? await uploadImageService(pictureFile)
        : undefined
      createCategory({
        variables: {
          input: {
            icon: response ? response.data : undefined,
            name: categoryInformation.name,
            name_translations: {
              en: valueEn ? valueEn.description : null,
              es: valueEs ? valueEs.description : null,
            },
            status: categoryInformation.status
              ? PublicationStatus.PUBLISHED
              : PublicationStatus.UNPUBLISHED,
          },
        },
      })
    } catch (e) {
      show({
        updatedSeverity: 'error',
        message: generalText.notificationText.uploadError,
      })
    }
  }, [categoryInformation, createCategory, generalText, show, translations])

  return (
    <StyledBox>
      <BackContainer />
      <BodyContainerWithHeader
        title={text.title}
        subtitle={text.processDescription}
        stepperItems={stepperItems}
        currentStep={currentStep}
      >
        {React.createElement(steps[currentStep], {
          handleContinue,
          handleBack,
          categoryData,
          handleSubmit,
          submitLoading,
          updateCategoryData,
        })}
      </BodyContainerWithHeader>
    </StyledBox>
  )
}

export default CategoryCreationPage
