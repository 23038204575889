import React from 'react'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import {
  cypressToggleButtonFalse,
  cypressToggleButtonTrue,
} from 'constants/cypress'
import { textFiles } from 'constants/textFiles'
import useTranslation from 'hooks/useTranslation'

import { StyledToggleButton } from './style'

type ToggleButtonProps = {
  /**
   *current toggle value
   */
  value: boolean
  /**
   *function to toggle current value
   */
  handleValueChange:
    | ((value: boolean) => void)
    | React.Dispatch<React.SetStateAction<boolean>>
  /*
   * ToggleButton width
   */
  width?: number | string
  /*
   * ToggleButton height
   */
  height?: number | string

  /*
   * Adds cypress tag to toggle buttons
   */
  testId?: string
}

const ToggleButton = ({
  value,
  width = '76px',
  height = '38px',
  handleValueChange,
  testId,
}: ToggleButtonProps) => {
  const { text: translation } = useTranslation(textFiles.GENERAL)
  const { buttons } = translation

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: boolean
  ) => {
    if (newStatus !== null) {
      handleValueChange(newStatus)
    }
  }

  return (
    <ToggleButtonGroup value={value} exclusive onChange={handleChange}>
      <StyledToggleButton
        sx={{ width, height }}
        value
        data-cy={testId ? `${testId}-${cypressToggleButtonTrue}` : undefined}
      >
        {buttons.yes}
      </StyledToggleButton>
      <StyledToggleButton
        sx={{ width, height }}
        value={false}
        data-cy={testId ? `${testId}-${cypressToggleButtonFalse}` : undefined}
      >
        {buttons.no}
      </StyledToggleButton>
    </ToggleButtonGroup>
  )
}

export default ToggleButton
