import { styled } from '@mui/material'

import Box from 'components/Common/Box'

import { BREAK_POINTS } from 'styles/breakpoints'

export const StyledGrid = styled(Box)({
  marginBottom: '20px',
  marginTop: '25px',
  display: 'grid',
  gridTemplateColumns: '20% 20% 20% 20% 20%',
  rowGap: '30px',
  [`@media only screen and (max-width: ${BREAK_POINTS.XL}px)`]: {
    gridTemplateColumns: '25% 25% 25% 25%',
  },
})
