import React from 'react'
import {
  CardContent,
  CardMedia,
  List,
  ListItem,
  SvgIcon,
  Typography,
} from '@mui/material'

import Box from 'components/Common/Box'
import Link from 'components/Common/Link'
import { ModuleInfoType } from 'components/General/GeneralView/index'
import { StyledSquareIcon } from 'components/Motives/Stripe/style'

import { colors, FONT_WEIGHT } from 'styles/theme'

import { StyledCard } from './style'

type ModuleCardProps = {
  moduleInfo: ModuleInfoType
}

const iconSx = {
  width: '250px',
  height: '250px',
  paddingTop: '50px',
}

const ModuleCard = ({ moduleInfo }: ModuleCardProps) => {
  return (
    <Link
      to={moduleInfo.route}
      style={{
        textDecoration: 'none',
        maxWidth: '460px',
        width: '45%',
      }}
    >
      <StyledCard
        sx={{
          display: 'block',
          minHeight: '316px',
        }}
      >
        <CardMedia
          component={Box}
          sx={{
            display: 'flex',
            height: '133px',
            backgroundColor: colors.cyan,
            justifyContent: 'left',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <SvgIcon htmlColor={colors.altBlue} sx={iconSx}>
            {moduleInfo.icon}
          </SvgIcon>
        </CardMedia>
        <CardContent
          sx={{
            position: 'relative',
            backgroundColor: colors.commonWhite,
          }}
        >
          <Typography fontWeight={FONT_WEIGHT.BOLD} variant="h5">
            {moduleInfo.name}
          </Typography>
          <Typography marginBottom="10px" variant="body2" color="text.primary">
            {moduleInfo.subtitle}
          </Typography>
          <List disablePadding>
            {moduleInfo.pointers.map((row) => (
              <ListItem
                key={`${moduleInfo.id}-${row.id}`}
                sx={{ fontSize: '14px' }}
                disablePadding
              >
                <StyledSquareIcon
                  sx={{
                    width: '16px !important',
                    marginTop: '0.5px',
                  }}
                />
                <Typography
                  variant="body2"
                  color="text.primary"
                  margin="auto"
                  marginLeft="0px"
                >
                  {row.pointer}
                </Typography>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </StyledCard>
    </Link>
  )
}

export default ModuleCard
