import { TRANSMISSION_CREATION_OPTION } from 'constants/transmission'
import {
  TransmissionCreationAction,
  TransmissionCreationModel,
} from 'models/transmissionCreation'

const transmissionCreationReducer = (
  state: TransmissionCreationModel,
  action: TransmissionCreationAction
): TransmissionCreationModel => {
  switch (action.type) {
    case TRANSMISSION_CREATION_OPTION.UPDATE_TRANSMISSION_INFORMATION:
      return { ...state, transmissionInformation: action.payload }

    default:
      return state
  }
}

export default transmissionCreationReducer
